import { Injectable } from '@angular/core';
import {
	BKCommonCopyUtilities,
	BKDeliveryModeEnum,
	BKDiscountInOrderDataImpl,
	BKOrderSourceEnum,
	BKTableAllocationLocationSpace,
	IBKDiscountData,
	IBKItemInOrderBase,
	IBKKingdomRebootCoupon,
	IBKOrderEventData,
	IBKProductBase,
} from '@bk/jscommondatas';
import { UPDATE_TYPE } from '@libs/shared/models';
import { generateNewOrder, IGenerateNewOrder, productToProductInOrder } from '@libs/shared/utils';

import { select, Store } from '@ngrx/store';
import { Shar3dUtils } from '@shar3d/shar3d-utils';
import { IOrderCompleteData, IOrderFiscalCorePrices, IOrderLocation, IOrderUpdatePartial, IOrderUsedPayments } from '@shared/models';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { IOrderState } from '../interfaces';
import {
	AddDiscountToOrder,
	AddOrderEvents,
	AddProductToOrder,
	ChangeProductQuantity,
	CleanOrderState,
	CreateNewOrder,
	UpdateFidelityRewards,
	UpdateOrderLocation,
	UpdatePartialOrder,
	UpdateUsedPaymentMethods,
	UpsertProductInOrder,
} from './actions';
import {
	fidelityRewardsUsed,
	getCurrentOrder,
	getCurrentOrderContent,
	getCurrentOrderDeliveryMode,
	getCurrentOrderFiscalCorePrices,
	getCurrentOrderLocation,
	getCurrentOrderPayments,
	getCurrentOrderSource,
	getOrderDiscounts,
	isFiscalCorePriceLoading,
	kioskPaymentEnabled,
} from './selectors';

@Injectable()
export class OrderFacade {
	readonly ngUnsubscribe$: Subject<void> = new Subject<void>();

	constructor(private store: Store<IOrderState>) {}

	createNewOrder(mandatoryOrderData: IGenerateNewOrder) {
		const data = { ...generateNewOrder(mandatoryOrderData) };
		this.store.dispatch(CreateNewOrder({ data }));
	}

	cleanOrder(): void {
		this.store.dispatch(CleanOrderState());
	}

	updateOrder(order: Partial<IOrderCompleteData>, updateOrderPrice: boolean = false): void {
		this.store.dispatch(UpdatePartialOrder({ order, updateOrderPrice }));
	}

	updatePaymentMethodsForOrder(paymentMethod, amount: number): void {
		this.store.dispatch(UpdateUsedPaymentMethods({ data: { paymentMethod, amount } }));
	}

	updateProductInOrder(updateOrderContent: IOrderUpdatePartial): void {
		this.store.dispatch(UpsertProductInOrder({ data: updateOrderContent }));
	}

	changeProductQuantity(lineUuid: string, quantity: number, isItemRemoved: boolean, id: number): void {
		this.store.dispatch(ChangeProductQuantity({ lineUuid, quantity, isItemRemoved, id }));
	}

	addOrderEvents(events: IBKOrderEventData[]): void {
		this.store.dispatch(AddOrderEvents({ data: events }));
	}

	addOrderEvent(event: IBKOrderEventData): void {
		this.addOrderEvents([event]);
	}

	getCurrentOrder$(): Observable<IOrderCompleteData> {
		return this.store.pipe(select(getCurrentOrder), takeUntil(this.ngUnsubscribe$));
	}

	getCurrentOrderPayments$(): Observable<IOrderUsedPayments[]> {
		return this.store.pipe(select(getCurrentOrderPayments), takeUntil(this.ngUnsubscribe$));
	}

	updateFidelityRewards(data: IBKKingdomRebootCoupon, updateType: UPDATE_TYPE): void {
		this.store.dispatch(UpdateFidelityRewards({ data, updateType }));
	}

	updateOrderLocationType(locationType: BKTableAllocationLocationSpace = BKTableAllocationLocationSpace.INDOORS, easel: string): void {
		this.store.dispatch(UpdateOrderLocation({ location: { locationType, easelNum: easel } }));
	}

	getOrderLocationType(): Observable<IOrderLocation> {
		return this.store.pipe(select(getCurrentOrderLocation), takeUntil(this.ngUnsubscribe$));
	}

	getOrderDeliveryMode(): Observable<BKDeliveryModeEnum> {
		return this.store.pipe(select(getCurrentOrderDeliveryMode), takeUntil(this.ngUnsubscribe$));
	}

	getOrderSource(): Observable<BKOrderSourceEnum> {
		return this.store.pipe(select(getCurrentOrderSource), takeUntil(this.ngUnsubscribe$));
	}

	addProduct(product: IBKProductBase, quantity = 1): void {
		const productInOrder: IBKItemInOrderBase = productToProductInOrder(product, quantity);
		this.addProductInOrder(productInOrder);
	}

	addProductInOrder(productInOrder: IBKItemInOrderBase) {
		this.store.dispatch(AddProductToOrder({ productInOrder: productInOrder }));
	}

	addMenu(menuInOrder: IBKItemInOrderBase) {
		this.store.dispatch(AddProductToOrder({ productInOrder: menuInOrder }));
	}

	getOrderContent(): Observable<IBKItemInOrderBase[]> {
		return this.store.pipe(select(getCurrentOrderContent), takeUntil(this.ngUnsubscribe$));
	}

	getOrderItemBasedByIndex(idx: number): Observable<IBKItemInOrderBase | null> {
		return this.store.pipe(
			select(getCurrentOrderContent),
			map((item: IBKItemInOrderBase[]) => item[idx] || null),
			takeUntil(this.ngUnsubscribe$)
		);
	}

	getOrderFiscalCorePrices(): Observable<IOrderFiscalCorePrices> {
		return this.store.pipe(select(getCurrentOrderFiscalCorePrices), takeUntil(this.ngUnsubscribe$));
	}

	isFiscalCorePriceLoading(): Observable<boolean> {
		return this.store.pipe(select(isFiscalCorePriceLoading), takeUntil(this.ngUnsubscribe$));
	}

	getFidelityRewardsUsed(): Observable<IBKKingdomRebootCoupon[]> {
		return this.store.pipe(select(fidelityRewardsUsed), takeUntil(this.ngUnsubscribe$));
	}

	addDiscount(discount: IBKDiscountData) {
		const discountInOrder = new BKDiscountInOrderDataImpl(null);
		BKCommonCopyUtilities.copyIBKDiscountData(discountInOrder, discount);
		discountInOrder.uuid = Shar3dUtils.generateUUID();
		this.store.dispatch(AddDiscountToOrder({ discountInOrder }));
	}

	getOrderDiscounts(): Observable<IBKDiscountData[]> {
		return this.store.pipe(select(getOrderDiscounts), takeUntil(this.ngUnsubscribe$));
	}

	getKioskPaymentEnabled(): Observable<boolean> {
		return this.store.pipe(select(kioskPaymentEnabled), takeUntil(this.ngUnsubscribe$));
	}

	unsubscribe(): void {
		this.ngUnsubscribe$.next();
		this.ngUnsubscribe$.complete();
	}
}
