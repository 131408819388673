export interface IDiscountResult {
	result: boolean;
	reason: BKDiscountsCompatibilityEnum;
}

export enum BKDiscountsCompatibilityEnum {
	UNKNOWN = 0,
	OK = 1,
	DISCOUNT_IN_MENU_ERROR = -1,
	DISCOUNT_IN_PRODUCT_ERROR = -2,
	DISCOUNT_IN_ORDER_ERROR = -3,
	DISCOUNT_IN_EXISTING = -4,
	MISSING_REQUIRED_ERROR = -5,
	ALREADY_INCLUDED_ERROR = -6,
	ALREADY_INVOLVED_ERROR = -7,
	GAME_IN_ORDER_ERROR = -8,
	DISCOUNT_NOT_APPLICABLE_TO_ITEM = -9,
}