/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PmsAvailableSingleProductDto { 
    /**
     * Standalone device API properties
     */
    id: number;
    /**
     * Is product selected for prediction
     */
    selected: boolean;
    /**
     * Is product available to be selected
     */
    available: boolean;
    /**
     * Product family ID
     */
    productFamilyId: number;
}

