/**
 * DKI-BE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AmountWithLabel } from './amount-with-label';
import { PosAmountWithGap } from './pos-amount-with-gap';
import { StaffData } from './staff-data';


export interface SynthesisReport {
	by_label: Array<AmountWithLabel>;
	by_staff: StaffData;
	total_collected: PosAmountWithGap;
	total: PosAmountWithGap;
	total_with_employee_meals: PosAmountWithGap;
	cancelled: number;
	refunded: number;
	discounted: number;
	withdrawn: number;
}

