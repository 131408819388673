export function Retry(maxRetries: number, delayMs = 1000): MethodDecorator {

	return function (target: any, key: string | symbol, descriptor: PropertyDescriptor) {
		const originalMethod = descriptor.value;

		descriptor.value = function (...args: any[]): Promise<any> {
			let retries = 0;

			const executeMethod = async () => {
				try {
					return await originalMethod.apply(this, args);
				} catch (error) {
					if (++retries >= maxRetries) {
						throw error;
					}
					await new Promise(resolve => setTimeout(resolve, delayMs));
					return executeMethod();
				}
			};

			return executeMethod();
		};

		return descriptor;
	};
}
