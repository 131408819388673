/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GlobalPriceDefinitionDto } from './global-price-definition-dto';
import { VatValueDto } from './vat-value-dto';


export interface ProductAndMenuBaseDto { 
    /**
     * Id of the item.
     */
    id: number;
    /**
     * System name of the item.
     */
    sysName: string;
    /**
     * Short name used for employee facing displays.
     */
    shortName: string;
    /**
     * Dictionary (associative array) of names where key is the language.
     */
    _name: { [key: string]: string; };
    /**
     * Dictionary (associative array) of descriptions where key is the language.
     */
    _description: { [key: string]: string; };
    /**
     * Ids of tags associated with the item.
     */
    xtag: Array<number>;
    /**
     * Flag indicating if the item is temporary and must be referenced in an active campaign to be enabled.
     */
    campaignItem: boolean;
    /**
     * Flag indicating if the item is in an active campaign.
     */
    inActiveCampaign?: boolean;
    /**
     * Id of the export group the item belongs to.
     */
    _groupId?: number;
    /**
     * Flag indicating if the item is active and can be displayed to customers. Used for \"soft delete\". Everything included in BigData must be presumed active, because initial filtering of active items is performed when BigData are generated on the global level.
     */
    active?: boolean;
    /**
     * New price definitions for price management.
     */
    globalPriceDefinitions?: Array<GlobalPriceDefinitionDto>;
    /**
     * New VAT values for price management.
     */
    vatValues?: Array<VatValueDto>;
    /**
     * Alternative name to be printed on the ticket.
     */
    _altName?: string;
    /**
     * Flag indicating if the item is available or not. Should not be used, availability is determined by local \"available.json\" file (raw availability).
     */
    available?: boolean;
    /**
     * Flag indicating if the item is not to be displayed on kiosk.
     */
    noKiosk: boolean;
    /**
     * Flag indicating if the item is not to be displayed on web.
     */
    noWeb: boolean;
    /**
     * Flag indicating if the item is not a food item.
     */
    nonFood?: boolean;
    /**
     * Flag indicating if the item is only available to members of fidelity program.
     */
    exclusiveToFidelity?: boolean;
    /**
     * Flag indicating if the item requires manager validation prior to being added to the order.
     */
    requireValidation?: boolean;
    /**
     * Weight of the item (for logictic purposes).
     */
    _weight?: number;
    /**
     * Index on the POS/DRIVEPAY/DRIVECO.
     */
    _indexPOS: number;
    /**
     * Index on the KIOSK.
     */
    _indexKiosk: number;
    /**
     * If the item has main image.
     */
    _hasImageMain?: boolean;
    /**
     * If the item has system image.
     */
    _hasImageSys?: boolean;
    /**
     * If the item should be excluded from order discount.
     */
    _excludeFromGlobalDiscount?: boolean;
}

