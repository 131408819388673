export interface IRestoConfig {
	restaurant: Restaurant;
	kitchenConfigs: KitchenConfig;
	kitchenConfigsDefault: number;
	driveConfigs: DriveConfig;
	driveConfigsDefault: number;
	orbpConfigs: OrbConfig;
	orbConfigsDefault: number;
	codConfigs: any;
	assemblyAreas: Record<string, AssemblyArea>;
	kioskScreens: Record<string, NavigationScreen>;
	kioskConfigs: Record<string, KioskConfigurationData>;
	kioskConfigsDefault: number;
	posScreens: Record<string, DeviceScreen>;
	posConfigs: Record<string, DeviceConfig>;
	posConfigsDefault: number;
	salesChannels: Record<string, IRestaurantSalesChannel>;
	dmbConfig: Record<string, DeviceConfig>;
	xmbConfig: Record<string, DeviceConfig>;
}

export interface Restaurant {
	name: string;
	uuid: string;
	nbPOS: number;
	nbDMB: number;
	nbKDS: number;
	nbKIOSK: number;
	nbORBp: number;
	nbORBs: number;
	nbDrive: number;
	restoState: number;
	number_fr: string;
	number_bk: number;
	publish: boolean;
	enableMultipayOnKiosk: boolean;
	enableKingdom: boolean;
	enableORBDigital: boolean;
	enableDeferredPay: boolean;
	enableICouponPay: boolean;
	enablePaymentCarteGR: boolean;
	tvaSet: number;
	priceSet: number;
	xtag: number[];
	assemblyAreas: number[];
	enabledSources: number;
	mainDTTGroup: number;
	secondDTTGroup: number;
	features: string[];
	restaurantsGroups: boolean[];
	tpe_id_merchant: string;
	url_nepting: string;
	installator: string;
	adr_adresse: string;
	adr_complement: string;
	adr_postal_code: number;
	adr_city: string;
	adr_country: string;
	contact_name: string;
	contact_telephone: string;
	contact_mobile: string;
	contact_email: string;
	id: number;
}

export interface KitchenConfig {
	name: string;
	description: string;
	colorordersource_kiosk: string;
	colorordersource_cash_machine: string;
	colorordersource_drive: string;
	colorordersource_website: string;
	showRightColumn: boolean;
	alerttimer: number;
	infostosumup: number;
	screens: KitchenConfigScreen[];
	applicableToPattern: KitchenConfigPattern[];
	active: boolean;
	id: number;
}

export interface KitchenConfigScreen {
	screenIdx: number;
	orderSource: number[];
	orderFilter: number;
	displayPayingPOS: boolean;
	displayPayingDrive: boolean;
	displayPayingKiosk: boolean;
	displayPayingWeb: boolean;
	productPattern: KitchenConfigProductPattern[];
}

export interface KitchenConfigProductPattern {
	target: number;
	family: number;
	familyPropagation: number;
	label: string;
	withAllTags: boolean;
	withTags: number[];
	withoutAllTags: boolean;
	withoutTags: number[];
	selected: number[];
	excluded: number[];
	excludeFilter: boolean;
}

export interface KitchenConfigPattern {
	target: number;
	family: number;
	familyPropagation: number;
	label: string;
	withAllTags: boolean;
	withTags: number[];
	withoutAllTags: boolean;
	withoutTags: number[];
	selected: number[];
	excluded: number[];
	excludeFilter: boolean;
}

export interface DriveConfig {
	name: string;
	description: string;
	autoPrintOrders: boolean;
	autoDisplayNotPaid: boolean;
	autoDisplayNotFinished: boolean;
	displayOrderPrices: boolean;
	alertTimer: number;
	screens: DriveConfigScreen[];
	applicableToPattern: DriveConfigPattern[];
	active: boolean;
	id: number;
}

export interface DriveConfigScreen {
	screenIdx: number;
	isSlave: boolean;
	parentIdx: number;
	displayPayingDrive: boolean;
	displayPayingWeb: boolean;
	displayPayingKiosk: boolean;
	displayPayingPOS: boolean;
	displayType: number;
	displayPayingCard: boolean;
	filterTypes: number[];
}

export interface DriveConfigPattern {
	target: number;
	family: number;
	familyPropagation: number;
	label: string;
	withAllTags: boolean;
	withTags: number[];
	withoutAllTags: boolean;
	withoutTags: number[];
	selected: number[];
	excluded: number[];
	excludeFilter: boolean;
}

export interface OrbConfig {
	name: string;
	description: string;
	autoPrintOrders: boolean;
	autoDisplayNotPaid: boolean;
	autoDisplayNotFinished: boolean;
	alertTimer: number;
	screens: OrbScreen[];
	applicableToPattern: ApplicablePattern[];
	active: boolean;
	id: number;
}

export interface OrbScreen {
	screenIdx: number;
	isSlave: boolean;
	parentIdx: number;
	assemblyAreas: AssemblyArea[];
	dispatchStrategy: number;
	specificKiosks: any[]; // Replace "any" with appropriate type
	sourceMask: number;
	orbIdx: number[];
}

export interface AssemblyArea {
	// Define properties for assembly area if available
	// Example: areaId: number; areaName: string;
	name: string;
	description: string;
	productPattern: ApplicablePattern[];
	active: boolean;
	id: number;
}

export interface ApplicablePattern {
	target: number;
	family: number;
	familyPropagation: number;
	label: string;
	withAllTags: boolean;
	withTags: number[];
	withoutAllTags: boolean;
	withoutTags: number[];
	selected: number[];
	excluded: number[];
	excludeFilter: boolean;
}

export interface IRestaurantSalesChannel {
	enabled: boolean;
	price_level: string;
}

export interface DeviceConfig {
	name: string;
	description: string;
	autoPrintOrders: boolean;
	autoDisplayNotPaid: boolean;
	autoDisplayNotFinished: boolean;
	alertTimer: number;
	screens: DeviceScreen[];
	applicableToPattern: ApplicablePattern[];
	active: boolean;
	id: number;
}

export interface DeviceScreen {
	screenIdx: number;
	isSlave: boolean;
	parentIdx: number;
	assemblyAreas: AssemblyArea[];
	dispatchStrategy: number;
	specificKiosks: any[]; // Replace "any" with appropriate type
	sourceMask: number;
	orbIdx: number[];
}

export enum NAVIGATION_SCREEN_LAYOUT {
	ONE_ZONE_CENTRAL = 0,
	FIVE_ZONE_IN_CROSS = 1,
	ONE_ZONE_CENTRAL_WITH_FREE_ELEMENTS = 2,
	ONE_ZONE_CENTRAL_WITH_DESCRIPTION = 3,
}

export interface NavigationScreen {
	id: number;
	sysName: string;
	name: { [lang: string]: string };
	description?: { [lang: string]: string };
	color1: string;
	color2: string;
	layout: NAVIGATION_SCREEN_LAYOUT;
	area0: ApplicablePattern[];
	area1: ApplicablePattern[];
	area2: ApplicablePattern[];
	area3: ApplicablePattern[];
	area4: ApplicablePattern[];
	_hasImageOn?: boolean;
	_hasImageOff?: boolean;
	_hasImagePromote?: boolean;
	_hasImageAlt?: boolean;
	xtag: Array<number>;
	campaignScreen?: boolean;
	extraFilter?: number;
}

export interface KioskConfigurationData {
	id: number;
	name: string;
	welcomeMessage?: { [lang: string]: string };
	screens: number[];
	associatedTargetsForLoop: number[];
	associatedTargetsForTransition: number[];
	associatedTargetsForHome: number[];
	applicableToPattern: ApplicablePattern[];
	campaignConfig?: boolean;
}
