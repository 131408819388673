import { GlobalPriceDefinition } from '../types';
import { LocalPriceDefinitionPerSalesChannel } from '../types/local-price-definition-per-sales-channel';

/**
 * Updates .referencePrice with current value of matching global price.
 */
// PriceDefinition is identified by rowIdentifier (menuSize, productPlacement) and salesChannelOption
// (Similar function exists in RAP's PriceManagement Store but without globalPriceDefinitions)
export function mergeLocalPriceDefinitions(
	currentValues: LocalPriceDefinitionPerSalesChannel[],
	newValues: LocalPriceDefinitionPerSalesChannel[],
	globalPriceDefinitions: GlobalPriceDefinition[]
): LocalPriceDefinitionPerSalesChannel[] {
	const resultValues = [...currentValues];

	newValues.forEach((newValue) => {
		// Check if it exist in globalPriceDefinitions and if it should be disabled
		const globalPriceMatch = globalPriceDefinitions.find((gp) =>
			gp.rowIdentifier === newValue.rowIdentifier
			&& gp.salesChannelOption === newValue.salesChannelOption);

		const referencePrice = globalPriceMatch?.price ?? 0;
		const isPriceDisabledInGlobal = globalPriceMatch?.isDisabled ?? true;
		newValue.isDisabled = isPriceDisabledInGlobal;

		// Check if there already is a matching local record
		const index = resultValues.findIndex((r) =>
			r.rowIdentifier === newValue.rowIdentifier
			&& r.salesChannelOption === newValue.salesChannelOption);

		const doesItExist = index !== -1;

		if (doesItExist) {
			// update if possible
			const currentValue = resultValues[index];
			if (isPriceDisabledInGlobal) {
				newValue.localPrice = currentValue.localPrice;
			}

			const updatedValue = {...currentValue, ...newValue, ...{referencePrice, isDisabled: isPriceDisabledInGlobal}};
			resultValues[index] = updatedValue;
		} else {
			// add new record
			newValue.referencePrice = referencePrice;
			resultValues.push(newValue);
		}
	});

	return resultValues;
}
