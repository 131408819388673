import { getUniqueValues } from '@merim/utils';

import { LoadDistribution, OrbpRole } from '../../enums';
import { OrbpDetail } from '../../types';

/**
 * Dependent ORBp is valid when all his Masters have equal LoadDistribution.
 * The "Empty" rule is ignored in this case, because it can be combined with any load balacing rule.
 *
 * (works also for single-master Dependent)
 */
export function isDependentWithMultipleMastersValid(dependentOrbp: OrbpDetail, allProgramOrbps: OrbpDetail[]): boolean {
	// Sanity check
	if (dependentOrbp.role !== OrbpRole.Dependent) {
		const errMessage = `isDependentWithMultipleMastersValid() - ORBp must be in Role Dependent, but was ${dependentOrbp.role}`;
		console.error(errMessage);
		throw new Error(errMessage);
	}

	const masters = allProgramOrbps.filter((o) => (dependentOrbp.masterIds || []).includes(o.id) && o.id !== dependentOrbp.id);

	const loadDistributions = getUniqueValues(
		masters.map((m) => m.loadDistribution)
			.filter(ld => ld !== LoadDistribution.EMPTY));

	return loadDistributions.length === 0 || loadDistributions.length === 1;
}
