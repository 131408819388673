export interface IBKDeliveryOptions {
	/**
	 * Deliveroo, UberEats, justEat, ecocea or BK Delivery in lowercase
	 */
	webordersource: string | null;
	/**
	 * Primary Order ID to display to user
	 */
	weborderdisplay: string | null;
	/**
	 * Fallback Order ID to display to user.
	 * Used when weborderdisplay is null or empty
	 */
	weborderid: string | null;
}
