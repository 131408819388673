import { OrbpDetail } from "../../types";
import { ORBP_CRITICAL_PROPERTIES } from "../../types/orbp_critical_properties";

export function hasChangeInCriticalProperties(oldValues: OrbpDetail, newValues: OrbpDetail): boolean {
	const hasChanged = ORBP_CRITICAL_PROPERTIES.some(prop => {
		const oldValue = JSON.stringify(oldValues[prop]);
		const newValue = JSON.stringify(newValues[prop]);
		return oldValue !== newValue;
	})

	return hasChanged;
}
