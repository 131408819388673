import { AfterViewInit, Directive, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Subject } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';

@Directive({
	selector: '[translate]',
})
export class TranslateDirective implements OnInit, AfterViewInit, OnChanges {
	private key: string = '';
	private params: Record<string, string | number> = {};
	private readonly ngUnsubscribe$: Subject<void> = new Subject<void>();

	constructor(
		private el: ElementRef,
		private translateService: TranslocoService
	) {}

	@Input('translate')
	set translate(key: string) {
		this.key = key;
	}

	@Input('translateParams')
	set translateParams(params: Record<string, string | number>) {
		this.params = params;
	}

	ngOnInit() {
		if (this.key) {
			this.performTranslation(this.key, this.params);
		}

		this.translateService.events$
			.pipe(
				filter((e) => e.type === 'translationLoadSuccess' || e.type === 'langChanged'),
				debounceTime(200),
				takeUntil(this.ngUnsubscribe$)
			)
			.subscribe((item) => {
				this.performTranslation(this.key, this.params);
			});
	}

	checkForLanguageChange = () => {};

	ngAfterViewInit() {
		if (!this.key) {
			this.performTranslation(this.el.nativeElement.textContent);
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		const translate = changes['translate'];
		const translateParams = changes['translateParams'];

		const keyCondition = translate?.previousValue !== translate?.currentValue;
		const paramsCondition = translateParams?.previousValue !== translateParams?.currentValue;

		if (keyCondition || paramsCondition) {
			this.key = keyCondition ? translate.currentValue : this.key;
			this.params = paramsCondition ? translateParams.currentValue : this.params;

			this.performTranslation(this.key, this.params);
		}
	}

	private performTranslation(key: string, params: Record<string, string | number> = {}) {
		this.el.nativeElement.textContent = this.translateService.translate(key, params);
	}

	ngOnDestroy() {
		this.ngUnsubscribe$.next();
		this.ngUnsubscribe$.complete();
	}
}
