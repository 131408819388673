/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface WeborderChannelConfigurationDto { 
    /**
     * Key of the weborder channel to be utilized in the code.
     */
    channel: WeborderChannelConfigurationDto.ChannelEnum;
    /**
     * Name of the weborder channel to be displayed to employees and customers.
     */
    name: string;
    /**
     * Id of payment method to be utilized for fiscalization of order within the weborder channel. For Plutus fiscal core equals to id of payment method, for other fiscal cores equals to \"mdmid\".
     */
    paymentMethodId: number;
    /**
     * Url from which logo of the weborder channel may be retrieved.
     */
    logoUrl?: string;
    /**
     * Sales channel option utilized for the weborder channel to select correct price levels. Mandatory for all weborder channels but CLICK_COLLECT.
     */
    salesChannelOption?: WeborderChannelConfigurationDto.SalesChannelOptionEnum;
}
export namespace WeborderChannelConfigurationDto {
    export type ChannelEnum = 'CLICK_COLLECT' | 'DELIVEROO' | 'DELIVERY_BK' | 'JUST_EAT' | 'UBER_EATS' | 'ZOT' | 'PLEASE' | 'LYVEAT';
    export const ChannelEnum = {
        ClickCollect: 'CLICK_COLLECT' as ChannelEnum,
        Deliveroo: 'DELIVEROO' as ChannelEnum,
        DeliveryBk: 'DELIVERY_BK' as ChannelEnum,
        JustEat: 'JUST_EAT' as ChannelEnum,
        UberEats: 'UBER_EATS' as ChannelEnum,
        Zot: 'ZOT' as ChannelEnum,
        Please: 'PLEASE' as ChannelEnum,
        Lyveat: 'LYVEAT' as ChannelEnum
    };
    export type SalesChannelOptionEnum = 'DELIVEROO' | 'DELIVERY_BK' | 'EAT_IN' | 'JUST_EAT' | 'TAKE_AWAY' | 'UBER_EATS' | 'ZOT' | 'PLEASE' | 'LYVEAT';
    export const SalesChannelOptionEnum = {
        Deliveroo: 'DELIVEROO' as SalesChannelOptionEnum,
        DeliveryBk: 'DELIVERY_BK' as SalesChannelOptionEnum,
        EatIn: 'EAT_IN' as SalesChannelOptionEnum,
        JustEat: 'JUST_EAT' as SalesChannelOptionEnum,
        TakeAway: 'TAKE_AWAY' as SalesChannelOptionEnum,
        UberEats: 'UBER_EATS' as SalesChannelOptionEnum,
        Zot: 'ZOT' as SalesChannelOptionEnum,
        Please: 'PLEASE' as SalesChannelOptionEnum,
        Lyveat: 'LYVEAT' as SalesChannelOptionEnum
    };
}


