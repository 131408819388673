import { WeatherInfo } from './weather-info';

export type PredictedDay = {
	/**
	 * Date also servers as identificator
	 */
	date: Date;
	/**
	 * Weather info is not available in "too future" days
	 */
	weatherInfo?: WeatherInfo;
	previousYearRevenue: number; // CA N-1
	previousWeekRevenue: number; // CA S-1
	revenuePredictedByAI?: number;
	revenuePredictedByManager?: number;
	/**
	 * Historical revenue actually received on that day
	 */
	actualRevenue?: number;
};
