import { ERROR_STORE_ORIGINATORS, ERROR_STORE_SEVERITY_TYPES } from '@libs/shared/models';

export interface IStoreErrorData {
	id: string;
	message: string;
	severity: ERROR_STORE_SEVERITY_TYPES;
	originator: ERROR_STORE_ORIGINATORS;
	seen: boolean;
	data: any;
}
