/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SelectionPatternDto } from './selection-pattern-dto';


export interface MenuStepProductGroupDto { 
    /**
     * Id of the product group for menu step.
     */
    id: number;
    /**
     * Dictionary (associative array) of names displayed on the step where key is the language.
     */
    _name: { [key: string]: string; };
    /**
     * Dictionary (associative array) of descriptions where key is the language.
     */
    _description: { [key: string]: string; };
    /**
     * If the product group has main image.
     */
    _hasImageMain?: boolean;
    /**
     * If the product group has system image.
     */
    _hasImageSys?: boolean;
    /**
     * Simple sorting index.
     */
    sortIndex?: number;
    /**
     * Patterns for selection of the menus this product group is applicable to.
     */
    applicableToMenuPattern?: Array<SelectionPatternDto>;
    /**
     * Configuration flags, currently unused.
     */
    flags: number;
    /**
     * Patterns for the selection of products for the product group.
     */
    products: Array<SelectionPatternDto>;
}

