import { Channel } from '../../constants';

export interface ChannelOrdersWithAverages {
	channel: Channel;
	count: number;
	mean_count: number;
	trend: number;
	mean_count_global: number;
	trend_global: number;
}
