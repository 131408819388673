import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'merim-info-icon',
	templateUrl: './info-icon.component.html',
	styleUrls: ['./info-icon.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoIconComponent {
}
