/********************************************************
 * The application-wide API structures.
 ********************************************************/


/**
 * This export interface defines the data of a restaurant
 */
export interface IBKAPIResto {
	/**
	 * UUID of the restaurant
	 */
	id: string;

	/**
	 * BK number of the restaurant
	 */
	bknb: string;

	/**
	 * FR number of the restaurant
	 */
	frnb: string;

	/**
	 * Name of the restaurant
	 */
	name: string;
}


/**
 * Will be replaced by CustomerDetailDto
 */
interface CustomerDetail {
	id?: string;
	vip?: boolean;
	name?: string;
	companyName?: string;
	telephone?: string;
	email?: string;
	note?: string;
}

/**
 * Will be replaced by DeliveryDetailDto
 */
interface DeliveryDetail {
	address?: string;
	addressExtra?: string;
	city?: string;
	zipCode?: string;
	note?: string;
}


/**
 * This structure defines an exchanged order
 * It either describes an existing order published from the system
 * Or an order received from an outside request
 * @bkCopyAndCloneInterface
 */
export interface IBKExchangedOrder {
	/**
	 * Id of the order
	 * Internal in the case of a publication
	 * External in the case of a remote order
	 */
	order_id: string;

	/**
	 * Alien display ID
	 * Short ID to be show on ORBs/ORBp, in the app etc.
	 */
	display_id?: string;

	/**
	 * Order source (enumeration, same values as BKOrderSourceEnum)
	 */
	order_source?: number /*BKOrderSourceEnum*/
	;

	/**
	 * Unix timestamp in seconds when the order has been created
	 */
	order_creation_datetime_s?: number;

	/**
	 * Unix timestamp in seconds when the first product has been put in the order
	 * Optional
	 */
	order_firstproduct_datetime_s?: number;

	/**
	 * Unix timestamp in seconds when the order has been sent in production
	 * Optional, only sent for an existing order
	 */
	order_prod_datetime_s?: number;

	/**
	 * Unix timestamp in seconds when the order has been or will be delivered
	 * Optional
	 * In the case of an external order, if set, this timestamp defines the due date
	 */
	order_delivery_datetime_s?: number;

	/**
	 * Unix timestamp in seconds when the user scanned his card
	 */
	order_scan_datetime_s?: number;

	/**
	 * Price of the order or expected price of the order
	 */
	order_total_ttc: number;

	/**
	 * Price excluding VAT
	 * Only provided for an existing order
	 */
	order_total_ht?: number;

	/**
	 * Id of the client if identified
	 */
	client_id?: string;

	/**
	 * Discounts applied to the order level
	 *
	 * When applying discounts at this level, the order_total_ttc should have the discounts already applied
	 */
	discounts?: IBKExchangedOrderDiscount[];

	events?: IBKExchangedOrderEvent[];

	/**
	 * Content of the order
	 */
	content: IBKExchangedOrderContent[];

	/**
	 * Whether or not the customer requires cutlery.
	 *
	 * False by default (cutlery will be included).
	 */
	no_cutlery?: boolean;

	/**
	 * Whether there are multiple orders within the same car on DRIVE.
	 *
	 * 0 by default (same car annotation will not be added).
	 */
	same_car?: number;

	/**
	 * Value is a query string (var1=val1&var2=val2)
	 *
	 */
	metas?: string;

	/**
	 * Whether the fidelity client has a VIP status. 
	 */
	vip?: boolean;

	customer?: CustomerDetail;
	deliveryDetail?: DeliveryDetail;
	note?: string;
}

export interface IBKExchangedOrderEvent {
	timestamp: number;
	eventtype: number;
	arg: string;
}

/**
 * This sub-content is used in menu content
 * It is used to prevent over-nested structures
 *
 * @example IBKExchangedOrderSubContent with metadata in id
 *
 *       {
 *           "id": "12+from_suggestion=12&in_campaign=45"
 *       }
 *
 * @example IBKExchangedOrderSubContent with metadata in meta field
 *
 *       {
 *           "id": "12",
 *           "meta": "from_suggestion=12&in_campaign=45"
 *       }
 *
 *
 * @bkCopyAndCloneInterface
 */
export interface IBKExchangedOrderSubContent {
	/**
	 * Id of the sub content
	 * May be a menu or a product
	 *
	 * An extra metadata field can be appended after the id, using "<numerical_id>+<metadata_querystring>" format, eg:
	 *
	 * Here the meta key from_suggestion takes the value 12 and in_campaign the value 45 for this item
	 *
	 * @see meta field
	 */
	id: string;

	/**
	 * Value is a query string (var1=val1&var2=val2)
	 *
	 * Available meta keys :
	 * * **from_suggestion** informs that the product was selected from a suggestion.
	 *  * value: id of the suggestion.
	 */
	metas?: string;

	/**
	 * Recipe of a product if changed
	 * This field can only be present
	 */
	recipe?: IBKExchangedOrderContentIngredient[];

	/**
	 * If true, ice should be removed from the product
	 * Optional
	 */
	no_ice?: boolean;

	/**
	 * If true, the product should be picked up later by the customer
	 * Optional (false by default)
	 * Utilized only for products.
	 */
	pick_up_later?: boolean;
}

/**
 * @bkCopyAndCloneInterface
 */
export interface IBKExchangedOrderContent extends IBKExchangedOrderSubContent {
	/**
	 * Quantity
	 */
	qty: number;

	/**
	 * Size
	 */
	large?: boolean;

	/**
	 * Content of a menu
	 * Only available in the context of a menu
	 * Do not provide it in the case of a product
	 */
	menu_content?: IBKExchangedOrderSubContent[];

	/**
	 * Free items
	 */
	free_items?: IBKExchangedOrderContent[];

	/**
	 * Price of the item
	 */
	price_ttc: number;

	/**
	 * Ids of applied discounts
	 *
	 * When a product is holding a discount, its price_ttc value must be already taking discount into account
	 * The order total (order_total_ttc) have to match the final value with discount applied as well.
	 */
	discounts?: IBKExchangedOrderDiscount[];
}

/**
 * Ingredient in the Order
 * @bkCopyAndCloneInterface
 */
export interface IBKExchangedOrderContentIngredient {
	/**
	 * Id of the selected ingredient
	 */
	selected: string;

	/**
	 * Initial selected ingredient
	 */
	initial: string;

	/**
	 * Amount
	 */
	amount?: number;

	/**
	 * Initial amount
	 */
	amount_init?: number;

	/**
	 * Quantity of the ingredient
	 */
	qty?: number;

	/**
	 * Initial quantity
	 */
	qty_init?: number;

	/**
	 * Price of the item
	 */
	price_ttc: number;
}

/**
 * Describes a discount in an order
 * @bkCopyAndCloneInterface
 */
export interface IBKExchangedOrderDiscount {
	id: string;
}

/**
 * Payload for an order submission
 */
export interface IBKExchangedOrderSubmission {
	/**
	 * UUID of the resto to submit the order to
	 */
	resto: string;

	/**
	 * Content of the order
	 * If not set, the replace parameter MUST be set in order to cancel a previous submission
	 */
	order?: IBKExchangedOrder;

	/**
	 * If present, contains the id of an order to replace
	 * The order id MUST NOT be the same as the previous
	 */
	replace?: string;

	/**
	 * Callback to be called on submission
	 * It may be called with either a validation or a rejection
	 * Optional
	 */
	ackCallback?: string;

	/**
	 * Callback to be called on progression of the order
	 * Optional
	 */
	progressCallback?: string;
}

/*******************************
 * ORB Subscription interfaces
 *******************************/

export interface IBKAPIORBSubscriptionResponse {
	/**
	 * ORB Number
	 */
	orbNb: string;

	/**
	 * Restaurant BK Number
	 */
	restoNb: string;
}

export interface IBKAPIORBCallbackParameters extends IBKAPIORBSubscriptionResponse {
	/**
	 * State of the order :
	 *
	 * SUBSCRIPTION_ACCEPTED = 1
	 * WAITING_FOR_PAYMENT = 2
	 * PREPARING = 3
	 * READY_TO_DELIVER = 4
	 * DELIVERED = 5
	 * DISMISSED = 6
	 */
	state: number /*BKORBSubscriptionOrderState*/
	;
}

export interface IORBSubscriptionReport {
	/**
	 * Restaurant UUID.
	 */
	restoid: string;

	/**
	 * Restaurant Name.
	 */
	name: string;

	/**
	 * Restaurant FR Number.
	 */
	frnb: string;

	/**
	 * Restaurant BK Number.
	 */
	bknb: string;

	/**
	 * Businessday of this report.
	 */
	businessday: number;

	/**
	 * Date when the last subscription was received.
	 */
	lastupdate: number;

	/**
	 * ORB Subscription count.
	 */
	count: number;
}

