/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface MediaPlaylistContentDto { 
    /**
     * Id of the media.
     */
    mediaId: number;
    /**
     * Priority of a media in the playlist.   Values:   NORMAL = 0,   HIGH = 1,   VERY_HIGH = 2
     */
    priority: number;
}

