import { Component, Input } from '@angular/core';

@Component({
	selector: 'indicator-card',
	templateUrl: './indicator.component.html',
	styleUrls: ['./indicator.component.scss'],
})
export class IndicatorComponent {
	@Input() label: string;
	@Input() objective: number;
	@Input() percent: number;

	colors = {
		red: {
			foreground: '#EF9FAC',
			background: '#F4C3CB',
		},
		green: {
			foreground: '#93E1B5',
			background: '#BBDBC9',
		},
		blue: {
			foreground: '#A9D3FF',
			background: '#CCE5FF',
		},
	};

	get classColor(): 'green' | 'red' | 'blue' | 'gray' {
		if (this.percent === 0) {
			return 'gray';
		} else if (this.percent > 1) {
			return 'red';
		} else if (Math.abs(this.percent - 1) <= Number.EPSILON) {
			return 'blue';
		} else {
			return 'green';
		}
	}

	get icon(): 'arrow_downward' | 'drag_handle' | 'arrow_upward' | 'visibility_off' {
		return this.classColor == 'red'
			? 'arrow_upward'
			: this.classColor == 'green'
				? 'arrow_downward'
				: this.classColor == 'gray'
					? 'visibility_off'
					: 'drag_handle';
	}
}
