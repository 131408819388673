/**
 * DKI-BE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PriceDefinition } from './price-definition';
import { VatValue } from './vat-value';


export interface ItemDetail {
	id: number;
	short_name: string;
	sys_name: string;
	price_definitions?: Array<PriceDefinition>;
	/**
	 * Expected values: \'EatIn\', \'TakeAway\', \'Deliveroo\', \'DeliveryBK\', \'JustEat\', \'UberEats\'
	 */
	disabled_sales_channel_options?: Array<string>;
	vat_values?: Array<VatValue>;
}

