import { arrayToEnumMap, EnumMap, getTruthyEnumMapKeys } from '@merim/utils';
import * as api from '@rap/api-client/models';

import { AssignedOrbpPrinterType, LoadDistribution, OrbpRole, OrbStatus, SalesChannel } from '../enums';
import { SortingOrdersAlgorithm } from '../enums/sorting-orders-algorithm';
import { OrbpDetail, SalesChannelConfiguration } from '../types';

export function toOrbpDetail(dto: api.OrbpConfigurationBaseDto | api.OrbpDetailDto, foodCategories: string[]): OrbpDetail {
	const status = dto.status ? toOrbpStatus(dto.status) : undefined;
	const role = toOrbpRole(dto.role);
	const loadDistribution = toLoadDistribution(dto.loadDistribution);
	const sortingOrdersAlgorithm = dto.sortingOrdersAlgorithm
		? toSortingOrdersAlgorithm(dto.sortingOrdersAlgorithm)
		: undefined;
	const salesChannelConfigurations = dto.salesChannelConfigurations ? dto.salesChannelConfigurations.map((conf) => toSalesChannelsConfiguration(conf)) : undefined;
	const foodCategoryConfiguration = toFoodCategoryConfiguration(dto.foodCategoryConfiguration, foodCategories);
	const assignedOrbpPrinterType = toOrbpPrinterType(dto.assignedOrbpPrinterType);

	const detail: OrbpDetail = {
		id: dto.id,
		status,
		name: dto.name,
		role,
		masterIds: dto.masterIds,
		isDefaultForSalesChannel: dto.isDefaultForSalesChannel,
		salesChannelConfigurations,
		foodCategoryConfiguration,
		loadDistribution,
		alertTime: dto.alertTime,
		clientScreenOrbIds: dto.clientScreenOrbIds,
		assignedOrbpPrinterId: dto.assignedOrbpPrinterId,
		assignedOrbpPrinterType,
		bumpbarEnabled: dto.bumpbarEnabled,
		canParkOrders: dto.canParkOrders,
		requireConfirmationOfIncompleteOrder: dto.requireConfirmationOfIncompleteOrder,
		simplificationOrders: dto.simplificationOrders,
		splitRowsConfiguration: dto.splitRowsConfiguration,
		sortingOrdersAlgorithm
	};

	return detail;
}

export function fromOrbpDetail(orbpDetail: Partial<OrbpDetail>, foodCategories: string[]): api.OrbpDetailDto {
	const status = fromOrbpStatus(orbpDetail.status);
	const role = fromOrbpRole(orbpDetail.role);
	const loadDistribution = fromLoadDistribution(orbpDetail.loadDistribution);
	const sortingOrdersAlgorithm = fromSortingOrdersAlgorithm(orbpDetail.sortingOrdersAlgorithm);
	const salesChannelConfigurations = orbpDetail.salesChannelConfigurations ? orbpDetail.salesChannelConfigurations.map((sc) => fromSalesChannelsConfigurationDto(sc)) : undefined;
	const foodCategoryConfiguration = orbpDetail.foodCategoryConfiguration ? fromFoodCategoryConfiguration(orbpDetail.foodCategoryConfiguration, foodCategories) : undefined;

	const dto: api.OrbpDetailDto = {
		id: orbpDetail.id,
		status,
		name: orbpDetail.name,
		role,
		sortingOrdersAlgorithm,
		masterIds: orbpDetail.masterIds,
		isDefaultForSalesChannel: orbpDetail.isDefaultForSalesChannel,
		foodCategoryConfiguration: {...foodCategoryConfiguration},
		salesChannelConfigurations,
		loadDistribution,
		alertTime: orbpDetail.alertTime,
		clientScreenOrbIds: orbpDetail.clientScreenOrbIds,
		assignedOrbpPrinterId: orbpDetail.assignedOrbpPrinterId,
		assignedOrbpPrinterType: orbpDetail.assignedOrbpPrinterType,
		bumpbarEnabled: orbpDetail.bumpbarEnabled,
		canParkOrders: orbpDetail.canParkOrders,
		requireConfirmationOfIncompleteOrder: orbpDetail.requireConfirmationOfIncompleteOrder,
		simplificationOrders: orbpDetail.simplificationOrders,
		splitRowsConfiguration: orbpDetail.splitRowsConfiguration
	};

	// Sanity check - this maybe missing the input object
	if (dto.status === api.OrbpDetailDto.StatusEnum.Unknown) {
		delete dto.status;
	}

	return dto;
}

export function toSalesChannel(dtoSalesChannel: api.SalesChannelConfigurationDto.SalesChannelEnum): SalesChannel {
	switch (dtoSalesChannel) {
		case api.SalesChannelConfigurationDto.SalesChannelEnum.CashMachine:
			return SalesChannel.CASH_MACHINE;
		case api.SalesChannelConfigurationDto.SalesChannelEnum.Kiosk:
			return SalesChannel.KIOSK;
		case api.SalesChannelConfigurationDto.SalesChannelEnum.DriveWindow:
			return SalesChannel.DRIVE_WINDOW;
		case api.SalesChannelConfigurationDto.SalesChannelEnum.Walk:
			return SalesChannel.WALK;
		case api.SalesChannelConfigurationDto.SalesChannelEnum.ClickAndCollect:
			return SalesChannel.CLICK_AND_COLLECT;
		case api.SalesChannelConfigurationDto.SalesChannelEnum.HomeDelivery:
			return SalesChannel.HOME_DELIVERY;
		case api.SalesChannelConfigurationDto.SalesChannelEnum.ThirdPartyDelivery:
			return SalesChannel.THIRD_PARTY_DELIVERY;
		default:
			return SalesChannel.UNKNOWN;
	}
}

export function fromSalesChannel(salesChannel: SalesChannel): api.SalesChannelConfigurationDto.SalesChannelEnum {
	switch (salesChannel) {
		case SalesChannel.CASH_MACHINE:
			return api.SalesChannelConfigurationDto.SalesChannelEnum.CashMachine;
		case SalesChannel.KIOSK:
			return api.SalesChannelConfigurationDto.SalesChannelEnum.Kiosk;
		case SalesChannel.DRIVE_WINDOW:
			return api.SalesChannelConfigurationDto.SalesChannelEnum.DriveWindow;
		case SalesChannel.WALK:
			return api.SalesChannelConfigurationDto.SalesChannelEnum.Walk;
		case SalesChannel.CLICK_AND_COLLECT:
			return api.SalesChannelConfigurationDto.SalesChannelEnum.ClickAndCollect;
		case SalesChannel.HOME_DELIVERY:
			return api.SalesChannelConfigurationDto.SalesChannelEnum.HomeDelivery;
		case SalesChannel.THIRD_PARTY_DELIVERY:
			return api.SalesChannelConfigurationDto.SalesChannelEnum.ThirdPartyDelivery;
		default:
			return api.SalesChannelConfigurationDto.SalesChannelEnum.Unknown;
	}
}

const toOrbpPrinterType = (dto: api.OrbpDetailDto.AssignedOrbpPrinterTypeEnum): AssignedOrbpPrinterType => {
	switch (dto) {
		case 'grouped':
			return AssignedOrbpPrinterType.Grouped;
		case 'separated':
			return AssignedOrbpPrinterType.Separated;
		case 'simplified':
			return AssignedOrbpPrinterType.Simplified;
	}
};

const toOrbpStatus = (dtoStatus: api.OrbpDetailDto.StatusEnum): OrbStatus => {
	switch (dtoStatus) {
		case api.OrbpDetailDto.StatusEnum.Unknown:
			return OrbStatus.Unknown;
		case api.OrbpDetailDto.StatusEnum.Off:
			return OrbStatus.Off;
		case api.OrbpDetailDto.StatusEnum.On:
			return OrbStatus.On;
		default:
			return OrbStatus.Unknown;
	}
};

export const fromOrbpStatus = (status: OrbStatus): api.OrbpDetailDto.StatusEnum => {
	switch (status) {
		case OrbStatus.Off:
			return api.OrbpDetailDto.StatusEnum.Off;
		case OrbStatus.On:
			return api.OrbpDetailDto.StatusEnum.On;
		default:
			return api.OrbpDetailDto.StatusEnum.Unknown;
	}
};

const toOrbpRole = (dtoRole: api.OrbpDetailDto.RoleEnum): OrbpRole => {
	switch (dtoRole) {
		case api.OrbpDetailDto.RoleEnum.Master:
			return OrbpRole.Master;
		case api.OrbpDetailDto.RoleEnum.Mirror:
			return OrbpRole.Mirror;
		case api.OrbpDetailDto.RoleEnum.Dependent:
			return OrbpRole.Dependent;
		default:
			return OrbpRole.Unknown;
	}
};

const fromOrbpRole = (role: OrbpRole): api.OrbpDetailDto.RoleEnum => {
	switch (role) {
		case OrbpRole.Master:
			return api.OrbpDetailDto.RoleEnum.Master;
		case OrbpRole.Mirror:
			return api.OrbpDetailDto.RoleEnum.Mirror;
		case OrbpRole.Dependent:
			return api.OrbpDetailDto.RoleEnum.Dependent;
		default:
			return api.OrbpDetailDto.RoleEnum.Master;
	}
};

const toLoadDistribution = (dtoLoad: api.OrbpDetailDto.LoadDistributionEnum): LoadDistribution => {
	switch (dtoLoad) {
		case api.OrbpDetailDto.LoadDistributionEnum.RoundRobin:
			return LoadDistribution.ROUND_ROBIN;
		case api.OrbpDetailDto.LoadDistributionEnum.DynamicDistribution:
			return LoadDistribution.DYNAMIC_DISTRIBUTION;
		case api.OrbpDetailDto.LoadDistributionEnum.ProductDynamicDistribution:
			return LoadDistribution.PRODUCT_DYNAMIC_DISTRIBUTION;
		case api.OrbpDetailDto.LoadDistributionEnum.Empty:
			return LoadDistribution.EMPTY;
		default: {
			console.warn(`orbpMapper - toLoadDistribution(): Unexpected loadDistribution: ${dtoLoad}`);
			return LoadDistribution.Unknown;
		}
	}
};

const fromLoadDistribution = (loadDistribution: LoadDistribution): api.OrbpDetailDto.LoadDistributionEnum => {
	switch (loadDistribution) {
		case LoadDistribution.ROUND_ROBIN:
			return api.OrbpDetailDto.LoadDistributionEnum.RoundRobin;
		case LoadDistribution.EMPTY:
			return api.OrbpDetailDto.LoadDistributionEnum.Empty;
		case LoadDistribution.DYNAMIC_DISTRIBUTION:
			return api.OrbpDetailDto.LoadDistributionEnum.DynamicDistribution;
		case LoadDistribution.PRODUCT_DYNAMIC_DISTRIBUTION:
			return api.OrbpDetailDto.LoadDistributionEnum.ProductDynamicDistribution;
		default: {
			console.warn(`orbpMapper - fromLoadDistribution(): Unexpected loadDistribution: ${loadDistribution}`);
			return api.OrbpDetailDto.LoadDistributionEnum.Empty;
		}
	}
};

const toSortingOrdersAlgorithm = (dtoLoad: api.OrbpDetailDto.SortingOrdersAlgorithmEnum): SortingOrdersAlgorithm => {
	switch (dtoLoad) {
		case api.OrbpDetailDto.SortingOrdersAlgorithmEnum.ByValidationTime:
			return SortingOrdersAlgorithm.BY_VALIDATION_TIME;
		case api.OrbpDetailDto.SortingOrdersAlgorithmEnum.ByPaidTime:
			return SortingOrdersAlgorithm.BY_PAID_TIME;
		case api.OrbpDetailDto.SortingOrdersAlgorithmEnum.ByCreationTime:
			return SortingOrdersAlgorithm.BY_CREATION_TIME;
		case api.OrbpDetailDto.SortingOrdersAlgorithmEnum.Unknown:
			return SortingOrdersAlgorithm.UNKNOWN;
		default: {
			console.warn(`orbpMapper - toSortingOrdersAlgorithm(): Unexpected enum value: ${dtoLoad}. Using this default value instead: ${SortingOrdersAlgorithm.BY_VALIDATION_TIME}.`);
			return SortingOrdersAlgorithm.BY_VALIDATION_TIME;
		}
	}
};

const fromSortingOrdersAlgorithm = (sortingOrdersAlgo: SortingOrdersAlgorithm): api.OrbpDetailDto.SortingOrdersAlgorithmEnum => {
	switch (sortingOrdersAlgo) {
		case SortingOrdersAlgorithm.BY_VALIDATION_TIME:
			return api.OrbpDetailDto.SortingOrdersAlgorithmEnum.ByValidationTime;
		case SortingOrdersAlgorithm.BY_CREATION_TIME:
			return api.OrbpDetailDto.SortingOrdersAlgorithmEnum.ByCreationTime;
		case SortingOrdersAlgorithm.BY_PAID_TIME:
			return api.OrbpDetailDto.SortingOrdersAlgorithmEnum.ByPaidTime;
		case SortingOrdersAlgorithm.UNKNOWN:
			return api.OrbpDetailDto.SortingOrdersAlgorithmEnum.Unknown;
		default: {
			console.warn(`orbpMapper - fromSortingOrdersAlgorithm(): Unexpected sortingOrdersAlgo: ${sortingOrdersAlgo}`);
			return api.OrbpDetailDto.SortingOrdersAlgorithmEnum.ByValidationTime;
		}
	}
};


const toSalesChannelsConfiguration = (dto: api.SalesChannelConfigurationDto): SalesChannelConfiguration => {
	const salesChannel = toSalesChannel(dto.salesChannel);
	const configuration = toSalesChannelOptionsConfiguration(dto.configuration);
	const deviceSpecificConfiguration = dto.deviceSpecificConfiguration;

	const salesChannelConfiguration: SalesChannelConfiguration = {
		salesChannel,
		configuration,
		deviceSpecificConfiguration
	};

	return salesChannelConfiguration;
};

export const fromSalesChannelsConfigurationDto = (scConfiguration: SalesChannelConfiguration): api.SalesChannelConfigurationDto => {
	const salesChannel = fromSalesChannel(scConfiguration.salesChannel);
	const configuration = fromSalesChannelOptionsConfiguration(scConfiguration.configuration);
	const deviceSpecificConfiguration = scConfiguration.deviceSpecificConfiguration as { [key: string]: boolean; };

	const salesChannelConfiguration: api.SalesChannelConfigurationDto = {
		salesChannel,
		configuration: {...configuration},
		deviceSpecificConfiguration
	};

	return salesChannelConfiguration;
};

// eslint-disable-next-line @typescript-eslint/ban-types
const toSalesChannelOptionsConfiguration = (dto: object): EnumMap => {
	const result: EnumMap = dto as EnumMap;
	return result;
};

// eslint-disable-next-line @typescript-eslint/ban-types
const fromSalesChannelOptionsConfiguration = (salesChannelConf: EnumMap): object => {
	// Get only the truthy values (those which user selected)
	const allKeys = getTruthyEnumMapKeys(salesChannelConf);
	const result: EnumMap = arrayToEnumMap(allKeys);
	return result;
};

// eslint-disable-next-line @typescript-eslint/ban-types
const toFoodCategoryConfiguration = (dto: object, foodCategories: string[]): EnumMap => {
	const result: EnumMap = {};

	foodCategories.forEach((foodCategoryKey) => {
		const value: boolean = dto[foodCategoryKey] || false;
		result[foodCategoryKey] = value;
	});

	return result;
};

const fromFoodCategoryConfiguration = (foodCategoryConfiguration: EnumMap, foodCategories: string[]): Record<string, boolean> => {
	const result = {};

	foodCategories.forEach((foodCategoryKey) => {
		const value: boolean = foodCategoryConfiguration[foodCategoryKey] || false;
		result[foodCategoryKey] = value;
	});

	return result;
};
