/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ORBScreenConfigDataDto } from './orb-screen-config-data-dto';
import { SelectionPatternDto } from './selection-pattern-dto';


export interface ORBConfigDataDto { 
    /**
     * Id of the item reference in the DB
     */
    id: number;
    /**
     * Name of the config
     */
    name: string;
    /**
     * Description of the config
     */
    description: string;
    autoPrintOrders: boolean;
    autoDisplayNotPaid: boolean;
    autoDisplayNotFinished: boolean;
    alertTimer: number;
    screens: Array<ORBScreenConfigDataDto>;
    applicableToPattern: Array<SelectionPatternDto>;
}

