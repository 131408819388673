import { Injectable } from '@angular/core';
import { NEW_UI_ROUTE } from '@libs/dash/core/entity';

@Injectable({
	providedIn: 'root',
})
export class UiVersionDetectorService {
	isNewUi(): boolean {
		return window.location.pathname.includes(NEW_UI_ROUTE);
	}
}