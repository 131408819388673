import { LocalPriceDefinitionPerSalesChannel, MenuSize, ProductPriceType } from '@bk/price-management-common';

import { PriceRowMode } from './price-row-mode';
import { MenuBaseSizeEnum } from '@bk/jscommondatas';

/** Represents the whole Product/Menu/Ingredient price definition */
export type PriceRowModel = {
	id: string;
	name: string;
	baseSize?: MenuBaseSizeEnum;
	mode: PriceRowMode;
	rows: PriceNestedRowModel[];
	sysName: string;
};

/** Represents the single line of Product/Menu/Ingredient price definition identified by RowIdentifier */
export type PriceNestedRowModel = {
	rowIdentifier: MenuSize | ProductPriceType;
	cells: {
		[salesChannelOption: string]: LocalPriceDefinitionPerSalesChannel;
	};
};

/**
 * Model is editable, if at least 1 cell is editable
 */
export function isSomeCellEditable(rowModel: PriceRowModel): boolean {
	return rowModel.rows.some(isRowEditable);
}

function isRowEditable(row: PriceNestedRowModel): boolean {
	const salesChannelOptions = Object.keys(row.cells);
	return salesChannelOptions.some((sc) => row.cells[sc].isDisabled === false);
}
