/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TimePlanningDto { 
    /**
     * Flag indicating if the event is hourly limited during the day.
     */
    hourlyLimited?: boolean;
    /**
     * Start hour during the day if the event is \"hourlyLimited\". Must be lower than \"endHour\".
     */
    startHour: number;
    /**
     * Start minute during the day if the event is \"hourlyLimited\".
     */
    startMinute: number;
    /**
     * End hour during the day if the event is \"hourlyLimited\". Must be higher than \"startHour\".
     */
    endHour: number;
    /**
     * End minute during the day if the event is \"hourlyLimited\".
     */
    endMinute: number;
    /**
     * Mask for the days of the week when the event is active.         Values (DayOfWeekMask):         MONDAY = 1,         TUESDAY = 2,         WEDNESDAY = 4,         THURSDAY = 8,         FRIDAY = 16,         SATURDAY = 32,         SUNDAY = 64
     */
    dayOfWeekMask?: number;
}

