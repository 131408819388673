import { PredictionDetail } from '@bk/fullstack-common';

export type WrappedPredictionDetail = {
	/**
	 * The official prediction
	 */
	data: PredictionDetail;

	/**
	 * The preview of prediction.
	 * Can be reset any moment without any request to the server
	 */
	preview?: PredictionDetail;

	isSaving: boolean;
};
