// Note there is already number-based PickupType enum by ABProd
// The numeric values makes things harder to debug
// so I prefer this approach, even if that means translating those enum values
export enum PickupType {
	TakeAway = 'Take away',
	EatIn = 'Eat in',
	/**
	 * Customer picks up the order at drive window
	 */
	DriveIn = 'Drive in',
	/**
	 * Delivered to parking place in front of restaurant
	 */
	Parking = 'Parking',
}
