/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface GlobalAppsConfigurationDto { 
    /**
     * accessToken. Corresponds to GAP-BE access token.
     */
    accessToken: string;
    /**
     * Base URL of GAP-BE.
     */
    gapBeUrl: string;
    /**
     * Base URL of DASH-BE.
     */
    dashBeUrl: string;
    /**
     * Base URL of DASH.
     */
    dashUrl: string;
    /**
     * Restaurant\'s UUID.
     */
    uuid: string;
}

