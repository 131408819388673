/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type WebhookEvent = 'restaurant_feature_update' | 'restaurant_state_update' | 'restaurant_weborder_channel_update' | 'availability_update';

export const WebhookEvent = {
    RestaurantFeatureUpdate: 'restaurant_feature_update' as WebhookEvent,
    RestaurantStateUpdate: 'restaurant_state_update' as WebhookEvent,
    RestaurantWeborderChannelUpdate: 'restaurant_weborder_channel_update' as WebhookEvent,
    AvailabilityUpdate: 'availability_update' as WebhookEvent
};

