/**
 * Unknown can be used in other places as well.
 */
export const UNKNOWN = 'UNKNOWN';

export const PRODUCT = 'PRODUCT';
export const INGREDIENT = 'INGREDIENT';
export const MENU = 'MENU';
export type PriceDefinitionType = typeof UNKNOWN | typeof PRODUCT | typeof INGREDIENT | typeof MENU;

export enum PRICE_MANAGEMENT_DATA_TYPES {
	MENU = 'MENU',
	PRODUCT = 'PRODUCT',
	INGREDIENT = 'INGREDIENT',
	UNKNOWN = 'UNKNOWN',
}
