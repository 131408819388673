/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BrandConfigurationWebhook } from './brand-configuration-webhook';


export interface BrandConfigurationWebhookManagement { 
    /**
     * Configuration of URLs and events that should be notified. Utilized by BO-GLOBAL-MANAGER.
     */
    webhooks?: Array<BrandConfigurationWebhook>;
}

