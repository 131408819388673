import { MenuBaseSizeEnum } from '@bk/jscommondatas';
import { LocalPriceDefinitionPerSalesChannel } from './local-price-definition-per-sales-channel';

export type LocalPriceDefinition = {
	/**
	 * ID of Product, Menu or Ingredient
	 */
	id: string;
	/**
	 * Short name of the Product, Menu or Ingredient
	 */
	name: string;
	/**
	 * System name of the Product, Menu or Ingredient
	 */
	sysName: string;
	values: LocalPriceDefinitionPerSalesChannel[];
	baseSize?: MenuBaseSizeEnum;
};
