import { EnumMap, flattenArray, getUniqueValues } from '@merim/utils';
import { SalesChannel } from "../../enums";


import { OrbpDetail } from '../../types/orbp-detail';
import { SalesChannelConfiguration } from '../../types/sales-channel-configuration';

type SalesChannelWithOptions = {
	salesChannel: SalesChannel;
	availableOptions: string[];
};

/**
 * If any of the ORBps has SalesChannel Options enabled, then it will true / enabled.
 * Otherwise false / disabled.
 */
export function getMergedSalesChannels(orbps: OrbpDetail[]): SalesChannelConfiguration[] {
	// Algo:
	//
	// 1) First take all SalesChannelOptions for each SalesChannel
	// 2) Then for each SalesChannel and their Options calculate which Options are enabled
	// in at least one ORBp

	const allSalesChannelsConfigs: SalesChannelConfiguration[] = flattenArray(orbps.map((o) => o.salesChannelConfigurations || []));

	const allSalesChannels: SalesChannel[] = getUniqueValues(allSalesChannelsConfigs.map((c) => c.salesChannel));

	// Step #1) Take all SalesChannelOptions for each SalesChannel
	const optionsPerChannel: SalesChannelWithOptions[] = allSalesChannels.map((salesChannel) => {
		const matchingConfigs: EnumMap[] = allSalesChannelsConfigs.filter((x) => x.salesChannel === salesChannel).map((x) => x.configuration);

		const availableSalesChannelOptions: string[] = getUniqueValues(flattenArray(matchingConfigs.map((c) => Object.keys(c))));
		return {
			salesChannel,
			availableOptions: availableSalesChannelOptions
		};
	});

	// Step #2: Calculate which Option is enabled in at least one ORBp
	const result: SalesChannelConfiguration[] = allSalesChannels.map((salesChannel) => {
		const options: string[] = getSalesChannelOptions(salesChannel, optionsPerChannel);

		const matchingConfigs = allSalesChannelsConfigs.filter((x) => x.salesChannel === salesChannel);

		const res: SalesChannelConfiguration = {
			salesChannel,
			configuration: {}
		};

		options.forEach((scOption) => {
			const isEnabled = matchingConfigs.some((x) => x.configuration[scOption] === true);
			res.configuration[scOption] = isEnabled;
		});

		return res;
	});

	return result;
}

function getSalesChannelOptions(salesChannel: SalesChannel, optionsPerChannel: SalesChannelWithOptions[]): string[] {
	const match = optionsPerChannel.find((x) => x.salesChannel === salesChannel);
	return match?.availableOptions || [];
}
