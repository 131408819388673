export enum Range {
	Today = 'today',
	Day = 'day',
	Week = 'week',
	Month = 'month',
	Period = 'period',
	Second = 'second',
	Next = 'next',
	Prev = 'prev',
	Date = 'date',
}
