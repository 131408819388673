import { BKDeliveryModeEnum, IBKItemPriceInfo } from "@bk/jscommondatas";


export function getItemVat(itemPrice: IBKItemPriceInfo, deliveryMode: BKDeliveryModeEnum): number {
	if (deliveryMode === BKDeliveryModeEnum.DELIVERY_LOCAL || !itemPrice?.pcto || itemPrice?.pcto <= 0) {
		return itemPrice.pc;
	} else {
		return  itemPrice.pcto;
	}
}

