import { getDateAs_YYYY_MM_DD_HHmm } from '@merim/utils';
import { USER_DEFINED_PROGRAMS_FILE } from "../../models/index";

export function getUserDefinedProgramsFileNameWithTimestamp(): string {
	const timestamp = getDateAs_YYYY_MM_DD_HHmm(new Date());
	const originalFileNameArr = USER_DEFINED_PROGRAMS_FILE.split('.');
	const name = originalFileNameArr[0];
	const extension = originalFileNameArr[1];

	const filename = `${name}__${timestamp}.${extension}`;
	return filename;
}
