/**
 * DKI-BE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 */
export type Role = 'DRIVE' | 'KIOSK' | 'POS' | 'WEB';

export const Role = {
	Drive: 'DRIVE' as Role,
	Kiosk: 'KIOSK' as Role,
	Pos: 'POS' as Role,
	Web: 'WEB' as Role
};

