import { IBKPublishedOrderData } from "@bk/jscommondatas";

/**
 * Expects device name to be in format "OSMDM-KIOSK1 or KIOSK1".
 * Returns "KIOSK1" from it.
 */
export function getOrderSourceDeviceName(order: IBKPublishedOrderData): string {
	const sourceDevice = order.machine.name || '';
	return sourceDevice.split('-')[1] || sourceDevice;
}
