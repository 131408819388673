/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface RestaurantSettingsDto { 
    /**
     * Flag indicating if ORBps should run in LiveBasketMode and react to LiveBasketUpdate messages in real-time.
     */
    liveBasketModeEnabled?: boolean;
    /**
     * List of available Kiosk devices for the \'device specific configuration of SalesChannels in ORBp\'.
     */
    kioskList?: Array<string>;
}

