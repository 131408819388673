/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DriveScreenConfigDataDto } from './drive-screen-config-data-dto';
import { SelectionPatternDto } from './selection-pattern-dto';


export interface DriveConfigDataDto { 
    /**
     * Id of the item reference in the db
     */
    id: number;
    /**
     * Name of the config
     */
    name: string;
    /**
     * Description of the config
     */
    description: string;
    autoPrintOrders: boolean;
    autoDisplayNotPaid: boolean;
    autoDisplayNotFinished: boolean;
    displayOrderPrices?: boolean;
    alertTimer: number;
    /**
     * Ingredient selection pattern
     */
    screens: Array<DriveScreenConfigDataDto>;
    /**
     * Pattern selecting to which restaurant this config is applicable to
     */
    applicableToPattern: Array<SelectionPatternDto>;
}

