/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CODConfigDrivePayDataDto { 
    /**
     * Index of the drivepay currently configured
     */
    drivePayIdx: number;
    /**
     * List of the COD that can display the order
     */
    targetCOD: Array<number>;
}

