/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { WeborderChannel } from './weborder-channel';
import { ConfigurationRestaurantWeborderChannel } from './configuration-restaurant-weborder-channel';


export interface ConfigurationRestaurantWeborders { 
    /**
     * Weborder channels that are available for the restaurant. Require activation in the restaurant. (DEPRECATED, will be fully replaced by \'channels\' property with extra details)
     */
    allowedChannels: Array<WeborderChannel>;
    /**
     * Weborder channels that are available for the restaurant with their configuration. Require activation in the restaurant.
     */
    channels: Array<ConfigurationRestaurantWeborderChannel>;
}

