import { ItemPrice } from "@bk/fullstack-common";
import { round5 } from "@merim/utils";
import { SalesChannelOption } from "../enums";

import { VATValue } from "../types";

/**
 * Patches properties:
 * ttc, tva, ht, pc and pcto.
 * Also updates the VAT properties (pc and pcto).
 * Those properties are used in both Kiosk and POS.
 *
 * The ItemPrice is used in both Legacy and new PriceManagement.
 */
export function updatePriceTTC(
	salesChannelOption: SalesChannelOption,
	vatValues: VATValue[],
	target: ItemPrice,
	newTTC: number): void {

	let percentVat = target.pc || 0;

	// Find VAT percent based on EatIn or TakeAway
	const isEatIn = salesChannelOption === SalesChannelOption.EatIn;
	const vatSalesChannel = isEatIn ? SalesChannelOption.EatIn : SalesChannelOption.TakeAway;

	const vatValueFind: VATValue | undefined = findVatBySalesChannelOption(vatSalesChannel, vatValues);
	if (vatValueFind !== undefined) {
		percentVat = vatValueFind.percent;
	}

	// TTC Toutes Taxes Comprises - all taxes included
	target.ttc = newTTC;
	target.tva = round5((newTTC / (100 + percentVat)) * percentVat);
	target.ht = target.ttc - target.tva;

	const vatValueForEatIn: VATValue | undefined = findVatBySalesChannelOption(SalesChannelOption.EatIn, vatValues);
	if (!vatValueForEatIn) {
		const errMessage = `updatePriceTTC(): VatValue for ${SalesChannelOption.EatIn} not found. The target.pc will be unchanged, therefore values from LEGACY PriceManagement will be used as a fallback.`;
		console.warn(errMessage);
	}
	target.pc = vatValueForEatIn?.percent ?? target.pc;

	const vatValueForTakeAway: VATValue | undefined = findVatBySalesChannelOption(SalesChannelOption.TakeAway, vatValues);
	if (!vatValueForTakeAway) {
		const errMessage = `updatePriceTTC(): VatValue for ${SalesChannelOption.TakeAway} not found. The target.pcto will be unchanged, therefore values from LEGACY PriceManagement will be used as a fallback.`;
		console.warn(errMessage);
	}
	target.pcto = vatValueForTakeAway?.percent ?? target.pcto;
}

/*
 Find VAT by sales channel option in vatValues.
 Example data in vatValues:
 "vatValues": [
 {
 "value": "Normal",
 "tvaName": "EatIn",
 "percent": 10
 },
 {
 "value": "Alcohol",
 "tvaName": "TakeAway",
 "percent": 20
 }]
 */

// We currently have VAT defined for "Normal" which is EatIn, or for TakeAway, which includes all 3rd party deliveries
function findVatBySalesChannelOption(salesChannelOption: SalesChannelOption, vatValues: VATValue[]): VATValue | undefined {
	return vatValues.find(vatValue => vatValue.tvaName === salesChannelOption);
}
