/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PaymentMethodDto { 
    /**
     * ID of the payment method. Can be referenced by \'changeId\'.
     */
    id: number;
    /**
     * Label for payment method. Will be printed on the ticket.
     */
    label: string;
    /**
     * Whether or not the overpayment is allowed on the payment method. E.g. when paying by cash, you may pay price of 8€ with 10€ note and you should get change back (more on \'allowChange\' property). When paying by bank card, you can pay the exact sum.
     */
    allowOverpayment: boolean;
    /**
     * Whether or not the customer may receive a change when overpayment is allowed. If true, \'changeId\' property must be set.
     */
    allowChange: boolean;
    /**
     * Id of the payment method that will be used to return change to customer. Typically when customer pays with meal voucher and is entitled to get change back in cash. Required when \'allowChange\' property is set to \'true\'.
     */
    changeId?: number;
    /**
     * Maximal value that may be paid by this payment method. Typically in cash you can pay max 10.000,--€ per day.
     */
    maxAmount?: number;
    /**
     * Additional data specific to payment method.
     */
    metadata?: { [key: string]: string; };
}

