export interface IErrorValidation {
	detail: IErrorItemValidation[];
}

export interface IErrorAdditionalError {
	[key: string]: string;
}

export interface IErrorItemValidation {
	loc: string[];
	msg: string;
	type: string;
}
