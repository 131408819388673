/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AvailabilityCrownsDto { 
    /**
     * Dictionary of products\' prices by ID.
     */
    products: { [key: string]: number; };
    /**
     * Dictionary of MEDIUM (regular size) menus\' prices by ID.
     */
    menus: { [key: string]: number; };
    /**
     * Dictionary of LARGE menus\' prices by ID.
     */
    menusL: { [key: string]: number; };
}

