export enum BKOrderPayLineResultEnum {
	OK_NOT_COMPLETE,
	OK_COMPLETE,
	ERROR,
	PAYMENT_CAPTURE_ERROR,
	PRINTING_ERROR,
}

export enum BKOrderNewReglementUserMessageStatus {
	USER_MESSAGE = 'user-message'
}


export enum BKOrderNewReglementCustomDefinedStatus {
	SERVER_ERROR = 'server-error'
}