/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RestaurantFeature } from './restaurant-feature';


export interface ConfigurationRestaurantFeatureConfiguration { 
    feature: RestaurantFeature;
    /**
     * Flag indicating if the feature is enabled by default if enabled globally. The sole presence of the feature states that it has been enabled in GAP.
     */
    enabled?: boolean;
    /**
     * Flag indicating if the feature may be modified in restaurant.
     */
    modifiable?: boolean;
}

