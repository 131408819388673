import { BKOrderSourceEnum } from '@bk/jscommondatas';
import { SalesChannel } from '../../enums/sales-channel';


export function mapOrderSourceToSalesChannel(orderSource: BKOrderSourceEnum): SalesChannel {
	switch (orderSource) {
		case BKOrderSourceEnum.ORDER_SOURCE_CASH_MACHINE:
			return SalesChannel.CASH_MACHINE;
		case BKOrderSourceEnum.ORDER_SOURCE_CLICK_AND_COLLECT:
			return SalesChannel.CLICK_AND_COLLECT;
		case BKOrderSourceEnum.ORDER_SOURCE_DRIVE:
			return SalesChannel.DRIVE_WINDOW;
		case BKOrderSourceEnum.ORDER_SOURCE_HOME_DELIVERY:
		case BKOrderSourceEnum.ORDER_SOURCE_THIRD_PARTY_DELIVERY:
			return SalesChannel.THIRD_PARTY_DELIVERY;
		case BKOrderSourceEnum.ORDER_SOURCE_KIOSK:
			return SalesChannel.KIOSK;
		case BKOrderSourceEnum.ORDER_SOURCE_WALK:
			return SalesChannel.WALK;
		default:
			return SalesChannel.UNKNOWN;
	}
}
