import { NavigationTileAction } from './navigation-tile-action.interface';
import { NavigationTileHeaderIcon } from './navigation-tile-header-icon.interface';

export interface NavigationTileItem {
	headerIcon: NavigationTileHeaderIcon;
	title: string;
	description: string;
	actions: NavigationTileAction[];
	metadata?: any;
}
