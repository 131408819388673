/**
 * Represents single configuration option in the feature-flags.json (it contains Array).
 */
export type FeatureFlag = {
	key: string;
	active: boolean;
	data?: Record<string, string> | Array<unknown>;
	name?: string;
	description?: string;
};

export type FeatureFlagFile = FeatureFlag[];
