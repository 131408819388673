/**
 * Type defining a promise chain entry
 */
import Shar3dUtils from './shar3d-utils';

export type Shar3dPromiseChainEntry<T, U> = (context: U) => Promise<T>;

/**
 * This object is used in order to execute a promise chain
 */
export class Shar3dPromiseChain {
	/**
	 * Execute a chain of promise
	 * @param {((context:U)=>Promise<T>)[]} chain The chain to execute
	 * @param {U} context The context to pass to the chain items
	 * @param {T} defaultResult The default result to return on empty chain
	 * @param {(t:T)=>boolean} continueFunction The continue function, returns true to continue, false to return the intermediary result
	 */
	public static async executeConditionnalChain<T, U>(
		chain: Shar3dPromiseChainEntry<T, U>[],
		context: U,
		defaultResult: T | null = null,
		continueFunction: (t: T) => boolean = (t: T) => true
	): Promise<IShar3dPromiseChainResult<T>> {
		//  Check for an empty chain
		if (chain === null || chain.length <= 0) {
			return {remaining: 0, result: defaultResult};
		}
		//  Start the process
		return Shar3dPromiseChain.executeConditionnalChainItem(0, chain, context, continueFunction);
	}

	/**
	 * Execute one single item in the chain
	 */
	private static async executeConditionnalChainItem<T, U>(
		index: number,
		chain: Shar3dPromiseChainEntry<T, U>[],
		context: U,
		continueFunction: (t: T) => boolean = (t: T) => true
	): Promise<IShar3dPromiseChainResult<T>> {
		//  Get the chain item
		const chainItem: Shar3dPromiseChainEntry<T, U> | undefined = chain[index];
		//  Compute the remaining
		const remaining: number = chain.length - index - 1;
		//  Call
		const t: T = await chainItem(context);
		// Check for continue
		if (continueFunction(t)) {
			//  We must continue, so chesk for the end of the chain
			if (remaining <= 0) {
				//  End of the chain, resolve
				return {
					remaining: 0,
					result: t
				};
			} else {
				//  Next element
				return Shar3dPromiseChain.executeConditionnalChainItem(index + 1, chain, context, continueFunction);
			}
		} else {
			//  Do not continue
			return {
				remaining: remaining,
				result: t
			};
		}
		//  Now defer
		//        return new Promise<{remaining: number, result: T}>((resolve, reject) => {
		//            //  Call the chain item
		//            chainItem(context).then((t: T) => {
		//                // Check for continue
		//                if (continueFunction(t)) {
		//                    //  We must continue, so chesk for the end of the chain
		//                    if (remaining <= 0) {
		//                        //  End of the chain, resolve
		//                        resolve({
		//                            remaining: 0,
		//                            result: t
		//                        });
		//                    } else {
		//                        //  Next element
		//                        resolve(Shar3dPromiseChain.executeConditionnalChainItem(index + 1, chain, context, continueFunction));
		//                    }
		//                } else {
		//                    //  Do not continue
		//                    resolve({
		//                        remaining: remaining,
		//                        result: t
		//                    });
		//                }
		//            }).catch(() => reject());
		//        });
	}
}

export interface IShar3dPromiseChainResult<T> {
	remaining: number;
	result: T | null;
}

/**
 * Typed from https://developer.mozilla.org/en-US/docs/Mozilla/JavaScript_code_modules/Promise.jsm/Deferred#backwards_forwards_compatible
 */
export class DeferredPromise<T> {
	resolve!: (v: T | PromiseLike<T>) => void;

	/**
	 * spec compatible, but please reject only Error object
	 */
	reject!: (reason?: any) => void;

	promise!: Promise<T>;

	/**
	 * defer.state.resolved is true once the promise is complete or rejected
	 */
	state: {
		resolved: boolean;
	} = {
		resolved: false
	};

	// prevent instantiation
	private constructor() {
	}

	static create<T>() {
		const defer = new DeferredPromise<T>();
		/* A method to resolve the associated Promise with the value passed.
		 * If the promise is already settled it does nothing.
		 *
		 * @param {anything} value : This value is used to resolve the promise
		 * If the value is a Promise then the associated promise assumes the state
		 * of Promise passed as value.
		 */
		defer.resolve = function () {
			throw new Error('Deffered not bound yet');
		};

		/* A method to reject the assocaited Promise with the value passed.
		 * If the promise is already settled it does nothing.
		 *
		 * @param {anything} reason: The reason for the rejection of the Promise.
		 * Generally its an Error object. If however a Promise is passed, then the Promise
		 * itself will be the reason for rejection no matter the state of the Promise.
		 */
		defer.reject = function () {
			throw new Error('Deffered not bound yet');
		};

		defer.state = {
			resolved: false
		};

		/* A newly created Promise object.
		 * Initially in pending state.
		 */
		defer.promise = new Promise<T>((resolve, reject) => {
			defer.resolve = resolve;
			defer.reject = reject;
		})
			.then((r: T) => {
				defer.state.resolved = true;

				return r;
			})
			.catch((e) => {
				defer.state.resolved = true;

				return Promise.reject(e);
			});

		Object.freeze(defer);

		return defer;
	}
}

export class Shar3dUtilsPromise {
	/**
	 * Factory for wait promises that can be easily embedded in promise chain.
	 *
	 *      const waiter = Shar3dUtilsPromise.waitDeferred(500);
	 *      const promise = requestA.then(waiter.promise).then(requestB);
	 *
	 * you can interrupt running wait :
	 *
	 * waiter.resolve();
	 *
	 * waiter.reject(new Error('App interrupt');
	 *
	 * @param ms # of ms to wait before resolving promise (starts when promise is called)
	 * @return an object containing the promise factory along with interrupt functions
	 */
	public static waitDeferred(ms: number): DeferredPromise<void> {
		const returnDefer: DeferredPromise<void> = DeferredPromise.create();
		const runDefer: DeferredPromise<void> = DeferredPromise.create();
		Promise.race([Shar3dUtils.wait(ms), runDefer.promise])
			.then(returnDefer.resolve)
			.catch(returnDefer.reject);

		return returnDefer;
	}
}
