import { BKOrderEventType, IBKOrderEventData } from '@bk/jscommondatas';

import { IBKDeliveryOptions } from '../models/ibk-delivery-options';

export function getDeliveryOptionsFromEvents(events: IBKOrderEventData[]): IBKDeliveryOptions {
	const deliveryOptions = {
		webordersource: null,
		weborderid: null,
		weborderdisplay: null
	};
	(events || []).forEach((ev) => {
		switch (ev.eventType) {
			case BKOrderEventType.WEBORDER_SANDBOX_NAME:
				deliveryOptions.webordersource = ev.arg;
				break;
			case BKOrderEventType.WEBORDER_ALIEN_ORDER_ID:
				deliveryOptions.weborderid = ev.arg;
				break;
			case BKOrderEventType.WEBORDER_ALIEN_ORDER_ID_DISPLAY:
				deliveryOptions.weborderdisplay = ev.arg;
				break;
		}
	});
	return deliveryOptions;
}
