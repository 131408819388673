export * from './auth-api-client.service';
import { AuthApiClient } from './auth-api-client.service';
export * from './configurations-api-client.service';
import { ConfigurationsApiClient } from './configurations-api-client.service';
export * from './coupons-api-client.service';
import { CouponsApiClient } from './coupons-api-client.service';
export * from './dss-api-client.service';
import { DSSApiClient } from './dss-api-client.service';
export * from './devices-api-client.service';
import { DevicesApiClient } from './devices-api-client.service';
export * from './drink-fountain-api-client.service';
import { DrinkFountainApiClient } from './drink-fountain-api-client.service';
export * from './features-api-client.service';
import { FeaturesApiClient } from './features-api-client.service';
export * from './kds-api-client.service';
import { KdsApiClient } from './kds-api-client.service';
export * from './my-api-client.service';
import { MyApiClient } from './my-api-client.service';
export * from './orbp-api-client.service';
import { ORBpApiClient } from './orbp-api-client.service';
export * from './pms-api-client.service';
import { PMSApiClient } from './pms-api-client.service';
export * from './pos-api-client.service';
import { POSApiClient } from './pos-api-client.service';
export * from './price-management-api-client.service';
import { PriceManagementApiClient } from './price-management-api-client.service';
export * from './printers-api-client.service';
import { PrintersApiClient } from './printers-api-client.service';
export * from './profiler-api-client.service';
import { ProfilerApiClient } from './profiler-api-client.service';
export * from './programs-api-client.service';
import { ProgramsApiClient } from './programs-api-client.service';
export * from './restaurant-api-client.service';
import { RestaurantApiClient } from './restaurant-api-client.service';
export * from './revenue-api-client.service';
import { RevenueApiClient } from './revenue-api-client.service';
export * from './users-api-client.service';
import { UsersApiClient } from './users-api-client.service';
export * from './vouchers-api-client.service';
import { VouchersApiClient } from './vouchers-api-client.service';
export * from './weborders-api-client.service';
import { WebordersApiClient } from './weborders-api-client.service';
export const APIS = [AuthApiClient, ConfigurationsApiClient, CouponsApiClient, DSSApiClient, DevicesApiClient, DrinkFountainApiClient, FeaturesApiClient, KdsApiClient, MyApiClient, ORBpApiClient, PMSApiClient, POSApiClient, PriceManagementApiClient, PrintersApiClient, ProfilerApiClient, ProgramsApiClient, RestaurantApiClient, RevenueApiClient, UsersApiClient, VouchersApiClient, WebordersApiClient];
