import { EnumMap } from '@merim/utils';

import { LoadDistribution, OrbpRole, OrbStatus } from "../enums/index";
import { OrbpSplitRowsConfiguration } from "./orbp-split-rows-configuration";
import { SalesChannelConfiguration } from "./sales-channel-configuration";

/**
 * Contains basic configuration needed to calculate which ORBp displays which Orders
 * based on their Status, Role, LoadBalacing, masters they follow, and DSS Filters.
 *
 * Dependent has additional property "foodCategoryConfiguration".
 */
export type LoadBalancingConfigurations = {
	[orbpId: string]: LoadBalancingConfiguration;
};

export type LoadBalancingConfiguration =
	| ({
	role: OrbpRole.Master;
	/**
	 * If no other ORBp/Printer is configured to display that SalesChannel, it will be displayed on this ORBp/Printer
	 */
	isDefaultForSalesChannel: boolean;
} & _LoadBalancingBaseConfiguration)
	| ({
	role: OrbpRole.Mirror | OrbpRole.Unknown;
} & _LoadBalancingBaseConfiguration)
	| ({
	role: OrbpRole.Dependent;
	foodCategoryConfiguration: EnumMap;
	splitRowsConfiguration: OrbpSplitRowsConfiguration[];
} & _LoadBalancingBaseConfiguration);

type _LoadBalancingBaseConfiguration = {
	id: string;
	name: string;
	status: OrbStatus;
	loadDistribution: LoadDistribution;
	masterIds: string[];
	salesChannelConfigurations: SalesChannelConfiguration[];
};
