import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AuthApiClient } from './api/auth-api-client.service';
import { ConfigurationsApiClient } from './api/configurations-api-client.service';
import { CouponsApiClient } from './api/coupons-api-client.service';
import { DSSApiClient } from './api/dss-api-client.service';
import { DevicesApiClient } from './api/devices-api-client.service';
import { DrinkFountainApiClient } from './api/drink-fountain-api-client.service';
import { FeaturesApiClient } from './api/features-api-client.service';
import { KdsApiClient } from './api/kds-api-client.service';
import { MyApiClient } from './api/my-api-client.service';
import { ORBpApiClient } from './api/orbp-api-client.service';
import { PMSApiClient } from './api/pms-api-client.service';
import { POSApiClient } from './api/pos-api-client.service';
import { PriceManagementApiClient } from './api/price-management-api-client.service';
import { PrintersApiClient } from './api/printers-api-client.service';
import { ProfilerApiClient } from './api/profiler-api-client.service';
import { ProgramsApiClient } from './api/programs-api-client.service';
import { RestaurantApiClient } from './api/restaurant-api-client.service';
import { RevenueApiClient } from './api/revenue-api-client.service';
import { UsersApiClient } from './api/users-api-client.service';
import { VouchersApiClient } from './api/vouchers-api-client.service';
import { WebordersApiClient } from './api/weborders-api-client.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AuthApiClient,
    ConfigurationsApiClient,
    CouponsApiClient,
    DSSApiClient,
    DevicesApiClient,
    DrinkFountainApiClient,
    FeaturesApiClient,
    KdsApiClient,
    MyApiClient,
    ORBpApiClient,
    PMSApiClient,
    POSApiClient,
    PriceManagementApiClient,
    PrintersApiClient,
    ProfilerApiClient,
    ProgramsApiClient,
    RestaurantApiClient,
    RevenueApiClient,
    UsersApiClient,
    VouchersApiClient,
    WebordersApiClient ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
