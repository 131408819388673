import { AvailableJson, Menu } from "@bk/fullstack-common";
import { BKDeliveryModeEnum } from "@bk/jscommondatas";

import { MenuSize } from "../enums";
import { GlobalPriceDefinition, LocalPriceDefinitionPerSalesChannel } from "../types";
import { convertGlobalPriceToLocalPrice } from "./convert-global-price-to-local-price";
import { getApplicablePriceForSalesChannel } from "./get-price-for-sales-channel";
import { mapDeliveryModeToSalesChannelOption } from "./map-delivery-mode-to-sales-channel-option";
import { mergeLocalPriceDefinitions } from "./merge-local-price-definitions";
import { getLocalPriceDefinitions } from "./get-local-price-definitions";
import { updatePriceTTC } from "./update-price-ttc";

/**
 * Used in POS and KIOSK.
 *
 * Patch prices for given Menu based on currectly selected DeliveryMode/SalesChannelOption.
 * The price definitions are read and combined from both bigDatas.json and available.json
 */
export function patchMenuPrices(
	menu: Menu,
	deliveryMode: BKDeliveryModeEnum,
	availableJson: AvailableJson,
	globalPrices: GlobalPriceDefinition[]): Menu {

	const salesChannelOption = mapDeliveryModeToSalesChannelOption(deliveryMode);

	const localPrices = getLocalPriceDefinitions('MENU', salesChannelOption, availableJson, menu.id);
	const defaultPrices = globalPrices
		.filter(gp => gp.salesChannelOption === salesChannelOption)
		.map(gp => convertGlobalPriceToLocalPrice(gp));
	const mergedPriceDefinitions: LocalPriceDefinitionPerSalesChannel[] = mergeLocalPriceDefinitions(defaultPrices, localPrices, globalPrices);

	const mediumPortionPrices = mergedPriceDefinitions.filter(p => p.rowIdentifier === MenuSize.M);
	const largePortionPrices = mergedPriceDefinitions.filter(p => p.rowIdentifier === MenuSize.L);

	// Sanity check - There should be only 1 price definition for given "SalesChannelOption and rowIdentifier"
	if (mediumPortionPrices.length > 1 || largePortionPrices.length > 1) {
		const errMessage = `patchMenuPrices(): Duplicated price definitions found.`;
		console.error(errMessage);
		throw new Error(errMessage);
	}

	const applicablePriceForMedium = getApplicablePriceForSalesChannel(mediumPortionPrices[0]);
	const applicablePriceForLarge = getApplicablePriceForSalesChannel(largePortionPrices[0]);

	// Handle Medium portion prices
	if (applicablePriceForMedium === undefined) {
		// This Menu is disabled globally. Can not be sold.
		menu.isDisabled = true;
	} else {
		updatePriceTTC(salesChannelOption, menu.vatValues, menu._priceM, applicablePriceForMedium);
		menu.isDisabled = false;
	}

	// Handle Large portion prices
	if (applicablePriceForLarge === undefined) {
		// This Menu is disabled globally. Can not be sold.
		// TODO DEV-2029 - Will be addressed by https://reboot-qsr.atlassian.net/browse/DEV-2029
		// TODO: The item needs to be disabled.
	} else {
		updatePriceTTC(salesChannelOption, menu.vatValues, menu._priceL, applicablePriceForLarge);
	}

	return menu;
}
