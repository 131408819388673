import { Inject, Injectable } from '@angular/core';
import type { IHotkeyOptions } from 'angular2-hotkeys';
import { Hotkey, HotkeyOptions, HotkeysService as OriginalHotkeysService } from 'angular2-hotkeys';

@Injectable({
	providedIn: 'root',
})
/**
 * Wrapper around angular2-hotkeys, which provides automatic unsubscring when consuming component is destroyed.
 * It might not be needed, when we upgrade to angular2-hotkeys v13.
 *
 * The angular2-hotkeys has big version gap. There is:
 * v2.4.0 - for Angular 11 (That's what we are using)
 * v13 - for Angular 13 and above (That's not compatible with our Angular, and breaks the build. It is Ivy only).
 * See details in https://github.com/brtnshrdr/angular2-hotkeys#readme
 */
export class HotkeysService extends OriginalHotkeysService {
	protected userDefinedHotkeys: Hotkey[] = [];

	constructor(@Inject(HotkeyOptions) options: IHotkeyOptions) {
		super(options);
		this.setUserDefinedHotkeys();
	}

	public onComponentDestroy(): Hotkey | Hotkey[] {
		const removed = super.remove(this.userDefinedHotkeys);
		this.userDefinedHotkeys = [];
		return removed;
	}

	public override add(hotkey: Hotkey | Hotkey[], specificEvent?: string): Hotkey | Hotkey[] {
		this.setUserDefinedHotkeys();

		const result = super.add(hotkey, specificEvent);
		if (Array.isArray(result)) {
			this.userDefinedHotkeys.push(...result);
		} else {
			this.userDefinedHotkeys.push(result);
		}

		return result;
	}

	public override remove(hotkey?: Hotkey | Hotkey[], specificEvent?: string): Hotkey | Hotkey[] {
		this.setUserDefinedHotkeys();
		const result = super.remove(hotkey, specificEvent);

		if (result === null) {
			return result;
		}

		if (Array.isArray(result)) {
			const combosToRemove = result.map((hotkey) => hotkey.combo);
			this.hotkeys = this.userDefinedHotkeys.filter((hotkey) => combosToRemove.includes(hotkey.combo) === false);
		} else {
			this.hotkeys = this.userDefinedHotkeys.filter((hotkey) => hotkey.combo !== hotkey.combo);
		}

		return result;
	}

	private setUserDefinedHotkeys() {
		// userDefinedHotkeys is initially undefined. That is caused by internal implementation of OriginalHotkeysService
		// which calls .remove() and .add() internally in the constructor.
		// And because my code runs after call to super(), then this property is not yet defined
		// when those methods are called.
		if (!this.userDefinedHotkeys) {
			this.userDefinedHotkeys = [];
		}
	}
}
