/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SalesChannelConfigurationDto } from './sales-channel-configuration-dto';
import { OrbpSplitRowConfigurationDto } from './orbp-split-row-configuration-dto';


export interface LoadBalancingConfigurationDto { 
    /**
     * Index of the ORBp (stringified integer).
     */
    id: string;
    /**
     * Name of the ORBp.
     */
    name: string;
    /**
     * Current ORBp status. Critial property. See ORBP_CRITICAL_PROPERTIES.
     */
    status: LoadBalancingConfigurationDto.StatusEnum;
    /**
     * Current load distribution configuration. Mirror has the same value as his Master. Critial property. See ORBP_CRITICAL_PROPERTIES.
     */
    loadDistribution: LoadBalancingConfigurationDto.LoadDistributionEnum;
    /**
     * Ids of ORBp devices acting as a Master to this ORBp.         Points to another OrbpDetail.id, from which the Dependent or Slave Orb inherits the settings.      Dependent may have multiple Master.      Slave only 1.
     */
    masterIds: Array<string>;
    /**
     * Configuration of sales channels and sales channel options for filtering.
     */
    salesChannelConfigurations: Array<SalesChannelConfigurationDto>;
    /**
     * ORBp role.
     */
    role: LoadBalancingConfigurationDto.RoleEnum;
    /**
     * For \"Dependent\" only. Configuration of sales channel options within the sales channel. Keys are enum values (enum.value.toString()) of sales channel option enum.
     */
    foodCategoryConfiguration?: { [key: string]: boolean; };
    /**
     * For \"Master\" only. Flag indicating if this ORBp acts as a default ORBp for selected SalesChannel.         If no other ORBp/Printer is configured to display that SalesChannel, it will be displayed on this ORBp/Printer.         Only for Master ORBp.         Only 1 ORBp can be set as default.
     */
    isDefaultForSalesChannel?: boolean;
    /**
     * Dependants may have split screens for each of the master(s), indicating position of the order on the screen. This settings is for dependents only.
     */
    splitRowsConfiguration?: Array<OrbpSplitRowConfigurationDto>;
}
export namespace LoadBalancingConfigurationDto {
    export type StatusEnum = 'Unknown' | 'Off' | 'ShuttingDown' | 'On';
    export const StatusEnum = {
        Unknown: 'Unknown' as StatusEnum,
        Off: 'Off' as StatusEnum,
        ShuttingDown: 'ShuttingDown' as StatusEnum,
        On: 'On' as StatusEnum
    };
    export type LoadDistributionEnum = 'Unknown' | 'EMPTY' | 'ROUND_ROBIN' | 'DYNAMIC_DISTRIBUTION' | 'PRODUCT_DYNAMIC_DISTRIBUTION';
    export const LoadDistributionEnum = {
        Unknown: 'Unknown' as LoadDistributionEnum,
        Empty: 'EMPTY' as LoadDistributionEnum,
        RoundRobin: 'ROUND_ROBIN' as LoadDistributionEnum,
        DynamicDistribution: 'DYNAMIC_DISTRIBUTION' as LoadDistributionEnum,
        ProductDynamicDistribution: 'PRODUCT_DYNAMIC_DISTRIBUTION' as LoadDistributionEnum
    };
    export type RoleEnum = 'Unknown' | 'Master' | 'Dependent' | 'Mirror';
    export const RoleEnum = {
        Unknown: 'Unknown' as RoleEnum,
        Master: 'Master' as RoleEnum,
        Dependent: 'Dependent' as RoleEnum,
        Mirror: 'Mirror' as RoleEnum
    };
}


