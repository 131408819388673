import { Injectable } from '@angular/core';
import { Shar3dClientService } from '@libs/shared/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { DeviceInformationLoad, DeviceInformationLoadSuccess, DeviceInformationLoadType } from './actions';

@Injectable()
export class AppEffects {
	constructor(
		private actions$: Actions,
		private readonly shar3dClientService: Shar3dClientService<any>
	) {
		//TODO: change any for a generic type for all app effects??
	}

	getDeviceInformation$: Observable<Action> = createEffect(() =>
		this.actions$.pipe(
			ofType(DeviceInformationLoad),
			switchMap((inData: DeviceInformationLoadType) => {
				return this.shar3dClientService.getDeviceInformation().pipe(
					map((data) => {
						return DeviceInformationLoadSuccess({ information: data });
					}),
					catchError(() => {
						console.warn('[APP]: Unable to load device information');
						return of(DeviceInformationLoadSuccess({ information: inData.defaultDeviceInfo }));
					})
				);
			})
		)
	);
}
