/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrbpDetailDto } from './orbp-detail-dto';


export interface DssProgramDto { 
    /**
     * Id of the DSS print server program.
     */
    id: string;
    /**
     * Name of the DSS print server program.
     */
    name: string;
    /**
     * Description of the DSS print server program.
     */
    description: string;
    /**
     * From what time in hh:mm the program is running.
     */
    fromTime: string;
    /**
     * Until what time in hh:mm the program is running.
     */
    toTime: string;
    /**
     * Flag indicating if the program is running.
     */
    isActive: boolean;
    /**
     * ORBp configurations in the program.
     */
    orbpConfigurations?: Array<OrbpDetailDto>;
    /**
     * Flag indicating if the program is valid. Validated on server side upon client request.
     */
    isValid?: boolean;
    /**
     * Background CSS color name or HEX code.
     */
    color: string;
    /**
     * Contrasting text color is calculated. Based on background color.
     */
    textColor?: string;
    /**
     * Flag indicating if the program can be deleted or only reset to predefine settings.
     */
    canBeResetToFactoryDefault: boolean;
}

