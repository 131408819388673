export enum LoadDistribution {
	/**
	 * SHOULD BE NEVER USED. FALLBACK FOR WHEN SERVER AND FRONTEND DATA DOES NOT MATCH
	 */
	Unknown = 'Unknown',
	/**
	 * Default value. Each Master accepts ALL Orders, so all ORBps show the same Orders.
	 * Can be combined with any other LoadDistribution rule.
	 */
	EMPTY = 'EMPTY',
	/**
	 * ModN algoritm. Each new Order is assigned to next Master ORBp in row.
	 * Can NOT be combined with DYNAMIC_DISTRIBUTION.
	 */
	ROUND_ROBIN = 'ROUND_ROBIN',
	/**
	 * Respects speed of delivery of ORBp. The Master ORBp with shortest queue will accept the Order.
	 *
	 * Validation rule:
	 * At least 2 Master ORBps have to have DYNAMIC_DISTRIBUTION
	 * and there must be no other Master with different LoadDistribution,
	 * otherwise it would create conflicts between algoritms.
	 */
	DYNAMIC_DISTRIBUTION = 'DYNAMIC_DISTRIBUTION',
	/**
	 * https://reboot-qsr.atlassian.net/browse/DEV-2559
	 * Validation rule:
	 * At least 2 Master ORBps have to have PRODUCT_DYNAMIC_DISTRIBUTION
	 * and there must be no other Master with different LoadDistribution,
	 * otherwise it would create conflicts between algoritms.
	 */
	PRODUCT_DYNAMIC_DISTRIBUTION = 'PRODUCT_DYNAMIC_DISTRIBUTION',
}
