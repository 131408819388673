import { Injectable, OnDestroy } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { OrderEventsUtils } from '@bk/fullstack-common';
import {
	BKOrderEventArgumentKeys,
	BKOrderEventType,
	BKPatternUtilities,
	IBKItemInOrderBase,
	IBKProductIngredientData,
	IBKProductIngredientSelectedData,
} from '@bk/jscommondatas';
import { mapDeliveryModeToSalesChannelOption, SalesChannelOption } from '@bk/price-management-common';
import { IIngredientAndAlternatives, ISectionAndIngredient } from '@libs/shared/interfaces';
import { AVAILABLE_JSON, IBKBigData, Ingredient } from '@libs/shared/models';
import { LanguageService } from '@libs/shared/modules/i18n';
import { ConfigurationFacade } from '@libs/shared/store/configuration';
import { OrderFacade } from '@libs/shared/store/order';
import { generateUUID, isIngredientAvailable } from '@libs/shared/utils';
import { Shar3dUtils } from '@shar3d/shar3d-utils';
import { values } from 'ramda';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FileStorageService } from './file-storage.service';
import { PriceService } from './price-service';

@Injectable({ providedIn: 'root' })
export class PersonalizationService implements OnDestroy {
	currentSectionMap: ISectionAndIngredient[];
	private bigData: IBKBigData;
	private availability: AVAILABLE_JSON;
	private salesChannel: SalesChannelOption;
	private selectedProduct$: BehaviorSubject<IBKItemInOrderBase> = new BehaviorSubject(undefined);
	// used for temporary updates of recipe attribute
	private temporaryIngredientsList$: BehaviorSubject<IIngredientAndAlternatives[]> = new BehaviorSubject([]);

	constructor(
		private readonly configurationFacade: ConfigurationFacade,
		private readonly languageService: LanguageService,
		private readonly orderFacade: OrderFacade,
		private readonly fileStorageService: FileStorageService,
		private readonly priceService: PriceService
	) {
		this.bigData = toSignal(this.configurationFacade.getBigData())();
		this.salesChannel = mapDeliveryModeToSalesChannelOption(toSignal(this.orderFacade.getCurrentOrder$())().deliveryMode);
		this.availability = toSignal(this.configurationFacade.getAvailability())();
	}

	get temporaryIngredientList(): IIngredientAndAlternatives[] {
		return this.temporaryIngredientsList$.getValue();
	}

	set temporaryIngredientList(ingredientList: IIngredientAndAlternatives[]) {
		this.temporaryIngredientsList$.next(ingredientList);
	}

	get selectedProduct(): IBKItemInOrderBase {
		return this.selectedProduct$.getValue();
	}

	/**
	 * Called when new product personalization is opened
	 * Checks for recipe, if the recipe is present, it takes the information from the recipe itself, otherwise it copies default ingrediences
	 * @param {IBKItemInOrderBase} product
	 */
	set selectedProduct(product: IBKItemInOrderBase) {
		this.selectedProduct$.next(product);
		if (product.recipe.length === 0) {
			this.temporaryIngredientList = this.getCurrentIngrediencesFromBigData
				.filter((item) => isIngredientAvailable(this.availability, item.ingredientDefault))
				.map((ingredient) => {
					return {
						alternatives: this.alternativeIngrediences(ingredient),
						ingredient: new Ingredient(
							{
								...this.bigData.ingredients[ingredient.ingredientDefault],
								...ingredient,
								priceTTC: this.priceService.getIngredientPrice(this.bigData.ingredients[ingredient.ingredientDefault]),
							},
							this.languageService.currentLangCodeShort,
							(ingredient) => this.fileStorageService.getIngredientAltImage(ingredient)
						),
					};
				});
		} else {
			const defaultIngredientList = this.getCurrentIngrediencesFromBigData;
			this.temporaryIngredientList = product.recipe.map((recipeItem, idx) => {
				const itemIngredient = defaultIngredientList[idx];
				const sameIngredient = itemIngredient.ingredientDefault === recipeItem.selected;

				const commonData = {
					...itemIngredient,
					initialQty: itemIngredient.initialQty,
				};

				const dataForIngredient = sameIngredient
					? {
							...this.bigData.ingredients[itemIngredient.ingredientDefault],
							...commonData,
							priceTTC: this.priceService.getIngredientPrice(this.bigData.ingredients[itemIngredient.ingredientDefault]),
						}
					: {
							...this.bigData.ingredients[recipeItem.selected],
							...commonData,
							ingredientDefault: itemIngredient.ingredientDefault,
							priceTTC: this.priceService.getIngredientPrice(this.bigData.ingredients[recipeItem.selected]),
						};

				return {
					alternatives: this.alternativeIngrediences(itemIngredient),
					ingredient: new Ingredient(
						dataForIngredient,
						this.languageService.currentLangCodeShort,
						(ingredient) => this.fileStorageService.getIngredientAltImage(ingredient),
						recipeItem.qty
					),
				};
			});
		}
	}

	get getCurrentIngrediencesFromBigData(): IBKProductIngredientData[] {
		return this.bigData.products[this.selectedProduct.id]?._ingredients;
	}

	getSpecificIngredienceFromBigData(id: number): IBKProductIngredientData {
		return this.getCurrentIngrediencesFromBigData.find((item) => item.ingredientDefault === id);
	}

	cleanPersonalizationService(): void {
		const numberOfModifiedLines = this.getUpdatedRecipe().filter((item) => item.modified).length;

		if (numberOfModifiedLines) {
			this.orderFacade.addOrderEvents([
				OrderEventsUtils.createEvent(BKOrderEventType.PERSO_FROM_POPUP, {
					[BKOrderEventArgumentKeys.PRODUCT]: this.selectedProduct.id,
					[BKOrderEventArgumentKeys.LINES]: numberOfModifiedLines,
				}),
			]);
		}

		this.temporaryIngredientsList$.next([]);
		this.selectedProduct$.next(undefined);
	}

	/**
	 * Updates quantity of the ingrediences
	 * @param {string} id
	 * @param {number} diff
	 */
	updateIngredientsQuantity(id: string, diff: number): void {
		const selectedIngredient = this.temporaryIngredientList.find((item) => item.ingredient.id === id);

		if (selectedIngredient) {
			if (diff >= 0) {
				selectedIngredient.ingredient.incrementQty(diff);
			} else {
				selectedIngredient.ingredient.decrementQty(diff);
			}
		}

		this.temporaryIngredientList = this.temporaryIngredientList;
	}

	getSectionsAndIngredients$(): Observable<ISectionAndIngredient[]> {
		return this.temporaryIngredientsList$.pipe(
			map((temporaryIngredients: IIngredientAndAlternatives[]) => {
				const sectionsMap = temporaryIngredients.reduce((map, item) => {
					const ingredientId = item.ingredient.ingredientId;

					let _familyId;
					if (ingredientId != null) {
						_familyId = this.bigData.ingredients[ingredientId]._familyId;
					} else {
						_familyId = item.alternatives?.[0]?.ingredientData['_familyId'];
					}

					if (!_familyId) {
						return map;
					}

					const section = this.bigData.ingredientFamilies[_familyId].name[this.languageService.currentLangCodeShort];

					// If section not already exist, create it. This way there is no duplicates
					if (!map.has(section)) {
						map.set(section, []);
					}

					// Add the ingredient to its section
					map.get(section).push(item);

					return map;
				}, new Map<string, IIngredientAndAlternatives[]>());

				this.currentSectionMap = Array.from(sectionsMap, ([section, ingredients]) => ({
					section,
					ingredients: ingredients,
					expanded: true,
				}));
				return this.currentSectionMap;
			})
		);
	}

	getUpdatedRecipe(): IBKProductIngredientSelectedData[] {
		return this.temporaryIngredientList.map((item, idx) => {
			const originalIngredient = this.getCurrentIngrediencesFromBigData[idx];

			const itemIngredient = item.ingredient;
			const selected = itemIngredient.ingredientId || -1;
			const modified = !(
				originalIngredient.initialQty === itemIngredient.qty && originalIngredient.ingredientDefault === itemIngredient.ingredientId
			);
			return {
				selected: selected,
				initial: originalIngredient.ingredientDefault || -1,
				amount: 1,
				initAmount: originalIngredient.initialAmount,
				qty: itemIngredient.qty,
				initQty: originalIngredient.initialQty,
				priceTTC: itemIngredient.price,
				sysName: itemIngredient.name,
				modified: modified,
				line: idx,
				lineuuid: generateUUID(),
				timestamp: Shar3dUtils.now(),
			};
		});
	}

	alternativeIngrediences(ingredient: IBKProductIngredientData): Ingredient[] {
		const availableIngredience = BKPatternUtilities.filterIngredientsMulti(
			ingredient.ingredientPattern,
			values(this.bigData.ingredients),
			this.bigData.ingredients
		);
		return availableIngredience.map((item) => {
			return new Ingredient(
				{ ...ingredient, ...item, priceTTC: this.priceService.getIngredientPrice(item) },
				this.languageService.currentLangCodeShort,
				(ingredient) => this.fileStorageService.getIngredientAltImage(ingredient)
			);
		});
	}

	replaceIngredient(original: Ingredient, replacement: Ingredient): void {
		this.temporaryIngredientList = this.temporaryIngredientList.map((item) => {
			if (item.ingredient.id === original.id && replacement) {
				replacement.qty = replacement.qty || 1;
				return { ingredient: replacement, alternatives: item.alternatives };
			} else {
				return item;
			}
		});
	}

	ngOnDestroy() {}
}
