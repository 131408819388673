/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OpeningTimes } from './opening-times';
import { RestaurantContactInfo } from './restaurant-contact-info';
import { RestaurantFiscalInfo } from './restaurant-fiscal-info';
import { ZeltyUpdateInput } from './zelty-update-input';
import { RestaurantState } from './restaurant-state';
import { RestaurantFeature } from './restaurant-feature';
import { RestaurantLegalInfoUpdateInput } from './restaurant-legal-info-update-input';
import { WeborderChannel } from './weborder-channel';
import { RestaurantRemoteIdentificationsUpdateInput } from './restaurant-remote-identifications-update-input';
import { RestaurantPriceLevelUpdateInput } from './restaurant-price-level-update-input';


export interface UpdateRestaurantInfo { 
    name?: string;
    address?: string;
    address_extra?: string;
    zip_code?: number;
    city?: string;
    country?: string;
    opening_times?: OpeningTimes;
    remote_identifications?: RestaurantRemoteIdentificationsUpdateInput;
    /**
     * Must reference existing vat_group ID.
     */
    vat_group?: number;
    publication?: boolean;
    state?: RestaurantState;
    features?: Array<RestaurantFeature>;
    tags?: Array<number>;
    dtt_group_primary?: number;
    dtt_group_secondary?: number;
    contact?: RestaurantContactInfo;
    platform_id?: number;
    fiscal?: RestaurantFiscalInfo;
    legal?: RestaurantLegalInfoUpdateInput;
    latitude?: number;
    longitude?: number;
    zelty?: ZeltyUpdateInput;
    last_dash_transaction_at?: string;
    weborder_channels?: Array<WeborderChannel>;
    price_levels?: Array<RestaurantPriceLevelUpdateInput>;
}

