/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IntervalPredictionDto } from './interval-prediction-dto';


export interface PredictionRevenueDto { 
    /**
     * Current revenue prediction.
     */
    current: IntervalPredictionDto;
    /**
     * Actual revenue in previous week. Retrieved from DASH-BE /v1/reports/daily-sales (DailySales total.total.gross_value). Will return 0 when 0 is returned from reporting and will be undefined if the target business day has not ended yet (e.g. when we attempt to retrieve previous week revenue for the day which starts 2 weeks from today).
     */
    previousWeek?: number;
    /**
     * Actual revenue on the same day of the same week in previous year (not the same calendar date). Retrieved from DASH-BE /v1/reports/daily-sales (DailySales total.total.gross_value). Will return 0 when 0 is returned from reporting and will be undefined if the target business day has not ended yet.
     */
    previousYear?: number;
    /**
     * Actual current revenue, if available.
     */
    actual?: number;
}

