// For some reason, bubbleSort was about 2000x times faster then quickSort
// So I am keeping only bubbleSort

import { BrandName } from "../../enums/brand-name";

export function bubbleSort<T>(arr: T[], brandName: BrandName, getPropertyToCompare?: (a: T, b: T, brandName: BrandName) => number): T[] {
	let i, j: number;
	const len = arr.length;
	let isSwapped = false;

	for (i = 0; i < len; i++) {
		isSwapped = false;

		for (j = 0; j < len; j++) {
			// This is comparer
			const itemA: T = arr[j];
			const itemB: T | undefined = arr[j + 1];
			const comparedProperties = getPropertyToCompare !== undefined
				? getPropertyToCompare(itemA, itemB, brandName)
				: +itemA - +itemB;

			if (comparedProperties > 0) {
				const temp = arr[j];
				arr[j] = arr[j + 1];
				arr[j + 1] = temp;
				isSwapped = true;
			}
		}

		// If no two elements were swapped by inner loop, then break
		if (!isSwapped) {
			break;
		}
	}

	return arr;
}
