import { SalesChannelOption } from "../enums/index";

/**
 * Describes additional dependency of SalesChannelOptions.
 *
 * for example:
 * when TableServiceInside depends on EatIn,
 * then it can not be enabled unless EatIn is first enabled.
 */
export type SalesChannelOptionDependency = {
	optionName: SalesChannelOption;
	/**
	 * This SalesChannelOption must be first enabled, before "optionName" can be enabled.
	 * Also when the "dependsOn" is disabled, the dependends will be also automatically disabled.
	 */
	dependsOn: SalesChannelOption;
}
