export enum VoiceChatEvent {
	// TODO: DEV-4098 - This will be extended, we need something more robust.
	// Contant person is Jarda Vazny pro Gauss Algo...
	// Or Ondrej Borkovec, or Nikola Groverova
	SHOW_BURGER = "show_burger",
	SHOW_DRINK = "show_drink",

	ORDER_DONE = "order_done",
	CANCEL = "cancelled",
	ERROR = "error",
}

export type VoiceChatOrder = {
	order: VoiceChatOrderItem[]
}

/**
 * VoiceChat BE sends data in this format, which is very simplified, and needs to be converted to common Merim format.
 */
export type VoiceChatOrderItem = {
	/**
	 * Name of the Product. Eg: Hamburger.
	 * Should be then converted to Product ID, for a active Product with matching name.
	 */
	item: string;

	/**
	 * TODO: DEV-4098 - This is still in POC phase. VoiceChat AI is not very good at calculating product quantity. So we are trying to send the current total amount of given product. The quantity is not delta, but current quantity in basket. But this may change when AI BE becomes more robust.
	 */
	quantity: number;
};
