/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface MediaActionDto { 
    /**
     * Action to be performed when clicked on media.   Values:   DEFAULT = 0,   ADD_PRODUCT = 1,   ADD_MENU = 2,   ADD_GAME = 3,   ADD_DISCOUNT = 4,   YES = 100,   NO = 101,   CLOSE = 102
     */
    action: number;
    /**
     * Argument related to the action (e.g. product id, discount id etc.).
     */
    arg: number;
}

