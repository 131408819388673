/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { WebhookEvent } from './webhook-event';
import { RestaurantFeature } from './restaurant-feature';
import { WeborderChannel } from './weborder-channel';


export interface BrandConfigurationWebhookEvent { 
    /**
     * Event for which the webhook should be sent to given URL.
     */
    event: WebhookEvent;
    /**
     * Restaurant features of which changes should be notified. If empty list, all features will be notified. Utilized for **[\'restaurant_feature_update\']**.
     */
    features?: Array<RestaurantFeature>;
    /**
     * Weborder channels of which changes should be notified. If empty list, all weborder channels will be notified. Utilized for **[\'availability_update\', \'restaurant_weborder_channel_update\']**.
     */
    weborder_channels?: Array<WeborderChannel>;
}

