/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DssProgramPrintServerDto } from './dss-program-print-server-dto';
import { PrinterConfigurationDto } from './printer-configuration-dto';


export interface PrinterConfigurationResponseDto { 
    /**
     * Printer configurations.
     */
    configuration: Array<PrinterConfigurationDto>;
    /**
     * Printer DSS program.
     */
    program: Array<DssProgramPrintServerDto>;
}

