import { BK_CASH, DRIVE_CO, DRIVE_PAY, getApplicationName, ORBp } from '@merim/utils';
import { QueryVariableNames } from '../constants';
import { getQueryParameter } from './query-params-utils';

export function getDeviceId(): string {
	const appName = getApplicationName();
	switch (appName) {
		case ORBp: {
			const orbpId = getQueryParameter(QueryVariableNames.Orb);
			return orbpId;
		}
		case BK_CASH: {
			// See Confluence https://reboot-qsr.atlassian.net/wiki/spaces/RV/pages/91783618/BK+Cash+DriveCo+DrivePay
			const drive = getQueryParameter(QueryVariableNames.Drive);
			const driveMaster = getQueryParameter(QueryVariableNames.DriveMaster);

			const isDriveOn = !!drive;
			const isDrivePay = isDriveOn && !!driveMaster;

			if (isDrivePay) {
				return DRIVE_PAY;
			}

			if (isDriveOn) {
				return DRIVE_CO;
			}

			return '';
		}
		default:
			return '';
	}
}
