/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface FoodCategoryDto { 
    /**
     * Name of the food category.
     */
    name: string;
    /**
     * Icon for the food category in DSS.
     */
    icon: FoodCategoryDto.IconEnum;
    /**
     * Ids of product families defined on global level included in food category.
     */
    productFamilyIds: Array<number>;
    /**
     * Background hexadecimal color utilized for food category on ORBp. If no color is provided then ORBp will automatically alternate between two basic colors. Background color of food category.
     */
    color?: string;
}
export namespace FoodCategoryDto {
    export type IconEnum = 'UNKNOWN' | 'HOT_DRINKS' | 'COLD_DRINKS' | 'REFRIGERATED_DRINKS' | 'BURGERS' | 'FRIED_PRODUCTS' | 'FRIES' | 'HOT_DISH_BOWL' | 'HOT_SALAD_BOWL' | 'COLD_SALAD_BOWL' | 'HOT_DESSERTS' | 'COLD_DESSERTS' | 'DESSERT_IN_GLASS' | 'ICE_CREAMS' | 'DONUT' | 'FRUITS' | 'PASTRIES' | 'HOT_CAKE' | 'MILK_SHAKE' | 'DRESSINGS' | 'SAUCES' | 'TOYS' | 'TICKETS';
    export const IconEnum = {
        Unknown: 'UNKNOWN' as IconEnum,
        HotDrinks: 'HOT_DRINKS' as IconEnum,
        ColdDrinks: 'COLD_DRINKS' as IconEnum,
        RefrigeratedDrinks: 'REFRIGERATED_DRINKS' as IconEnum,
        Burgers: 'BURGERS' as IconEnum,
        FriedProducts: 'FRIED_PRODUCTS' as IconEnum,
        Fries: 'FRIES' as IconEnum,
        HotDishBowl: 'HOT_DISH_BOWL' as IconEnum,
        HotSaladBowl: 'HOT_SALAD_BOWL' as IconEnum,
        ColdSaladBowl: 'COLD_SALAD_BOWL' as IconEnum,
        HotDesserts: 'HOT_DESSERTS' as IconEnum,
        ColdDesserts: 'COLD_DESSERTS' as IconEnum,
        DessertInGlass: 'DESSERT_IN_GLASS' as IconEnum,
        IceCreams: 'ICE_CREAMS' as IconEnum,
        Donut: 'DONUT' as IconEnum,
        Fruits: 'FRUITS' as IconEnum,
        Pastries: 'PASTRIES' as IconEnum,
        HotCake: 'HOT_CAKE' as IconEnum,
        MilkShake: 'MILK_SHAKE' as IconEnum,
        Dressings: 'DRESSINGS' as IconEnum,
        Sauces: 'SAUCES' as IconEnum,
        Toys: 'TOYS' as IconEnum,
        Tickets: 'TICKETS' as IconEnum
    };
}


