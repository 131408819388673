import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
	name: 'highlight'
})
export class HighlightPipe implements PipeTransform {

	transform(text: string = '', search: string = '', cls = 'search-ui-match', caseSensitive = false): any {

		const replaceAll = (str: string, mapObj: any): string => {
			const escapeRegExp = (string) => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
			const keys = Object.keys(mapObj).sort((a, b) => a.length >= b.length ? -1 : 1);
			const re = new RegExp(keys.map((item) => escapeRegExp(item)).join('|'), 'gi');

			return str.replace(re, (matched) => {
				return mapObj[matched.toLowerCase()](matched);
			});
		};

		const convText = `${text}`;

		if (convText && search && search.trim()) {
			if (caseSensitive) {
				return convText.split(search).join(`<span class="${cls}">${search}</span>`);
			}

			let _text = convText;
			const _search = search.trim();

			const parsedSearch = _search.split(' ').filter((item) => item !== '').sort((a, b) => a.length > b.length ? -1 : 1);

			const mapObj = {};

			parsedSearch.forEach((item) => {
				mapObj[item.toLowerCase()] = (str) => `<span class="${cls}">${str}</span>`;
			});
			_text = replaceAll(_text, mapObj);

			return _text;
		}

		return text;
	}
}
