/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface IntervalFilterDefinitionDto { 
    /**
     * Name. Corresponds to enum IntervalGroupName.
     */
    name: IntervalFilterDefinitionDto.NameEnum;
    from: number;
    to: number;
}
export namespace IntervalFilterDefinitionDto {
    export type NameEnum = 'Morning' | 'Midday' | 'Afternoon' | 'Evening' | 'LateEvening' | 'Night';
    export const NameEnum = {
        Morning: 'Morning' as NameEnum,
        Midday: 'Midday' as NameEnum,
        Afternoon: 'Afternoon' as NameEnum,
        Evening: 'Evening' as NameEnum,
        LateEvening: 'LateEvening' as NameEnum,
        Night: 'Night' as NameEnum
    };
}


