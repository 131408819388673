/**
 * DKI-BE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PermissionLevelReadWrite } from './permission-level-read-write';


export interface PermissionInfoReadWrite {
	name: PermissionInfoReadWrite.NameEnum;
	level?: PermissionLevelReadWrite;
}

export namespace PermissionInfoReadWrite {
	export type NameEnum = 'reporting' | 'price-management' | 'user-management' | 'restaurant-management' | 'monitoring';
	export const NameEnum = {
		Reporting: 'reporting' as NameEnum,
		PriceManagement: 'price-management' as NameEnum,
		UserManagement: 'user-management' as NameEnum,
		RestaurantManagement: 'restaurant-management' as NameEnum,
		Monitoring: 'monitoring' as NameEnum
	};
}


