import { OrbpRole } from '../../enums/orbp-role';
import { OrbpDetail } from '../../types';

/**
 * Returns a list of all ORBp affected by the change. Both 1st and 2nd level of dependence.
 * Irrelevant of their Status.
 */
export function getAffectedOrbps(updatedOrbp: OrbpDetail, allOrbpsInProgram: OrbpDetail[]): OrbpDetail[] {
	const isMirror = updatedOrbp.role === OrbpRole.Mirror;
	if (isMirror) {
		return [];
	}

	// Get every Dependent/Mirror of the Orbp
	const affectedOrbps: OrbpDetail[] = allOrbpsInProgram.filter((depOrpb) => isSomehowDependent(depOrpb, updatedOrbp.id));

	const result = [...affectedOrbps];

	affectedOrbps.forEach((affectedOrbp) => {
		// Check if there is 2nd level dependency. Dependent can be mirrored.
		if (affectedOrbp.role === OrbpRole.Dependent) {
			const mirrors = allOrbpsInProgram.filter((o) => isSomehowDependent(o, affectedOrbp.id));
			result.push(...mirrors);
		}
	});

	return result;
}


function isSomehowDependent(couldBeDependent: OrbpDetail, masterOrbpId: string): boolean {
	const directDependence = (couldBeDependent.masterIds || []).includes(masterOrbpId);
	const splitRowDependence = (couldBeDependent.splitRowsConfiguration || []).some(conf => conf.masterIds.includes(masterOrbpId));

	return directDependence || splitRowDependence;
}
