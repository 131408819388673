<div class="hourly-report">
	@if (viewData$ | async; as viewData) {
		<dk-filter (filter)="handleFilterChange($event)" [salesChannels]="salesChannels"></dk-filter>
		<div class="dk-picker" [class.single-display]="singleDaySelection()">
			<mat-form-field appearance="fill" subscriptSizing="dynamic">
				<mat-label>{{ viewData.i18n.chooseDates }}</mat-label>
				<mat-date-range-input [formGroup]="dateRange" [rangePicker]="picker">
					<input [placeholder]="viewData.i18n.startDate" formControlName="from" matStartDate [hidden]="singleDaySelection()"/>
					<input [placeholder]="viewData.i18n.endDate" formControlName="to" matEndDate [class.single-display]="singleDaySelection()"/>
				</mat-date-range-input>
				<mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
				<mat-date-range-picker #picker displayMode="single">
					<mat-date-range-picker-actions>
						<button mat-button matDateRangePickerCancel>{{ viewData.i18n.cancel }}</button>
						<button (click)="setPeriod('period')" color="primary" mat-raised-button matDateRangePickerApply>
							{{ viewData.i18n.apply }}
						</button>
					</mat-date-range-picker-actions>
				</mat-date-range-picker>
			</mat-form-field>
			<mat-icon (click)="setPeriod('prevDay')">navigate_before</mat-icon>
			<mat-icon (click)="setPeriod('nextDay')">navigate_next</mat-icon>
			@if (isLoading | async) {
				<mat-spinner class="d-inline" diameter="20"></mat-spinner>
			}
			<button mat-button [matMenuTriggerFor]="WeeklyReport" class="export-label">{{ viewData.i18n.export }}</button>
			<mat-menu #WeeklyReport="matMenu" yPosition="above" xPosition="before">
				<button mat-menu-item (click)="downloadCsv()">{{ viewData.i18n.exportCsv }}</button>
				<button mat-menu-item (click)="downloadPDF()">{{ viewData.i18n.exportPdf }}</button>
				<button mat-menu-item (click)="downloadXlsx()">{{ viewData.i18n.exportXLSX }}</button>
			</mat-menu>
		</div>
		<div class="dk-mat-container fixTableHead">
			@if (viewData.dataTable) {
				<table class="dki-data-table" id="hourly-report">
					<thead class="sticky">
					<tr class="channel-tr borderless">
						<th class="op"></th>
						<th></th>
						@for (key of viewData.dataTable.columns; track key; let index = $index) {
							<th [attr.colspan]="singleDaySelection() ? 4 : 3">{{ salesChannelheader(viewData.i18n.channels[key], key) }}</th>
						}
					</tr>
					<tr class="channel-label-th borderless">
						<th class="op bg-white"></th>
						<th class="bg-white"></th>
						@for (key of viewData.dataTable.columns; track key) {
							<th [attr.colspan]="singleDaySelection() ? 4 : 3" style="border-left: 1px solid">{{ viewData.i18n.channels[key] || key }}</th>
						}
					</tr>
					<tr class="values-header">
						<th class="op"></th>
						<th>{{ viewData.i18n.time }}</th>
						@for (key of viewData.dataTable.columns; track key) {
							@if (singleDaySelection()) {
								<th class="bg-light-blue">CUMUL</th>
							}
							<th>CA</th>
							<th>NB</th>
							<th>MOY</th>
						}
					</tr>
					</thead>
					<tbody>
						@for (rowGroup of viewData.dataTable.rowGroups | pairs | sortHourlySales; track rowGroup) {
							@for (row of rowGroup[1] | values; track row; let first = $first) {
								<tr
									[class.child-row]="!row.isParent"
									[class.hidden]="!row.isParent && !expanded[rowGroup[0]]"
									[class.expanded]="expanded[rowGroup[0]] && first"
									[class.expandable]="row.isParent"
								>
									@if (row.isParent) {
										<td class="op">
											@if (row.isParent) {
												<mat-icon
													(click)="hourRowClick(rowGroup[0])"
													aria-hidden="false"
													aria-label="Expand"
													class="clickable"
												>
													{{ rowGroup[0] ? 'chevron_right' : 'expand_more' }}
												</mat-icon>
											}
										</td>
									}
									<td
										(click)="hourRowClick(rowGroup[0])"
										[class.clickable]="row.isParent"
										[class.tl]="row.isParent"
										[attr.colspan]="row.isParent ? 1 : 2"
									>
										{{ row.hourFrom }}-{{ row.hourTo }} {{ row.isParent ? 'h' : '' }}
									</td>
									@for (value of row.values; track value) {
										@if (singleDaySelection()) {
											<td class="bg-light-blue">
												{{
													value.cumulative_value === 0 && value.count === 0
														? '-'
														: (value.cumulative_value | currency: 'EUR' : 'symbol' : '1.2-2')
												}}
											</td>
										}
										<td>{{ value.value === 0 && value.count === 0 ? '-' : (value.value | currency: 'EUR' : 'symbol' : '1.2-2') }}</td>
										<td class="text-center">{{ value.count }}</td>
										<td>{{ value.mean_value === 0 ? '-' : (value.mean_value | currency: 'EUR' : 'symbol' : '1.2-2') }}</td>
									}
								</tr>
							}
						}
					</tbody>
					<tfoot>
					<tr>
						<td></td>
						<td>{{ viewData.i18n.total }}</td>
						@for (item of viewData.dataTable.totals; track item) {
							@if (singleDaySelection()) {
								<td>{{ item.cumulative_value | currency: 'EUR' : 'symbol' : '1.2-2' }}</td>
							}
							<td>{{ item.value === 0 ? '-' : (item.value | currency: 'EUR' : 'symbol' : '1.2-2') }}</td>
							<td>{{ item.count }}</td>
							<td>{{ item.mean_value === 0 ? '-' : (item.mean_value | currency: 'EUR' : 'symbol' : '1.2-2') }}</td>
						}
					</tr>
					</tfoot>
				</table>
			}
		</div>
	} @else {
		<div class="dk-in-progress-spinner-container">
			<mat-spinner></mat-spinner>
		</div>
	}

</div>
