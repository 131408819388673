import { TranslatedValidationMessage } from "@merim/utils";
import { getDefaultTranslatedDssProgramValidation, TranslatedDssProgramValidation } from "../../models";

/**
 * Merge results from different Validators into single Validation object
 */
export function mergeDssProgramsValidations(...args: TranslatedDssProgramValidation[]): TranslatedDssProgramValidation {
	let result = getDefaultTranslatedDssProgramValidation();

	// Merge all general validation messages
	const allGenerals = args.map(x => x.general);
	allGenerals.forEach(x => {
		result.general.push(...x);
	});

	// Merge all orbp-specific validation messages
	args.forEach(x => {
		result = mergeOrbpValidationMessages(x, result);
	})

	return result;
}


function mergeOrbpValidationMessages(validationToMerge: TranslatedDssProgramValidation, resultValidation: TranslatedDssProgramValidation): TranslatedDssProgramValidation {
	const result = {...resultValidation};
	const orbpIds = Object.keys(validationToMerge.orbp);

	orbpIds.forEach(orbpId => {
		const doesExist = !!result.orbp[orbpId];
		if (!doesExist) {
			result.orbp[orbpId] = [];
		}

		const validationMsgs: TranslatedValidationMessage[] = validationToMerge.orbp[orbpId];
		result.orbp[orbpId].push(...validationMsgs);
	});

	return result;
}
