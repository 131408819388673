/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PredictionDetailEntity } from './prediction-detail-entity';
import { PredictionWeatherDto } from './prediction-weather-dto';
import { PredictionRevenueDto } from './prediction-revenue-dto';


export interface PredictionEntity { 
    /**
     * Date of the prediction.
     */
    date: string;
    /**
     * Information about the revenue (predicted, modified, previous etc.)
     */
    revenue: PredictionRevenueDto;
    /**
     * Weather for the whole day of the prediction.
     */
    weather: PredictionWeatherDto;
    /**
     * Prediction details by set interval.
     */
    details: Array<PredictionDetailEntity>;
}

