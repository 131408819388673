/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BrandConfigurationPaymentMethods } from './brand-configuration-payment-methods';
import { FiscalProvider } from './fiscal-provider';


export interface BrandConfigurationFiscalManagement { 
    fiscal_provider?: FiscalProvider;
    /**
     * Flag indicating if input field for TPE ID merchant should be available on GAP.
     */
    configure_tpe_id_merchant?: boolean;
    /**
     * Configuration for payment methods.
     */
    payment_methods?: BrandConfigurationPaymentMethods;
    /**
     * Flag indicating if legal address in ConfigurationGlobal is OWNER or RESTAURANT address.
     */
    print_ticket_use_owner_address?: boolean;
}

