/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BrandConfigurationPriceLevel } from './brand-configuration-price-level';
import { VatRateConfiguration } from './vat-rate-configuration';
import { SalesChannelOption } from './sales-channel-option';


export interface BrandConfigurationPriceManagement { 
    /**
     * Toggle for module in GAP.
     */
    enabled?: boolean;
    /**
     * Flag indicating if new price management is utilized.
     */
    new_price_management?: boolean;
    /**
     * Map of price id group to \'Grille\'. Utilized for prices import/export with old price management.
     */
    prices_id_groups?: { [key: string]: number; };
    /**
     * Map of daypart campaign id (with delivery prices) to \'Grille\'. Utilized for prices import/export with old price management.
     */
    delivery_prices_campaigns?: { [key: string]: number; };
    /**
     * Prefix for price table name. Utilized for prices import/export.
     */
    price_level_prefix?: string;
    /**
     * Prefix for price name. Utilized for prices import/export.
     */
    price_level_name_prefix?: string;
    /**
     * Price levels for EAT_IN and TAKE_AWAY prices.
     */
    local_price_levels?: Array<BrandConfigurationPriceLevel>;
    /**
     * Price levels for EAT_IN and TAKE_AWAY prices.
     */
    delivery_price_levels?: Array<BrandConfigurationPriceLevel>;
    /**
     * List of allowed sales channel options.
     */
    sales_channel_options?: Array<SalesChannelOption>;
    /**
     * Configuration of VAT rates with ids (idtva) and names used in MySQL
     */
    vat_rate_configuration?: Array<VatRateConfiguration>;
    /**
     * Flag indicating if \'EAT_IN\' and \'TAKE_AWAY\' sales channel options should be merged, i.e. should have the same prices set.
     */
    merge_eat_in_and_take_away?: boolean;
}

