/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PropertyType } from './property-type';


export interface RestaurantRemoteIdentificationConfiguration { 
    /**
     * Flag indicating if property must be set.
     */
    required?: boolean;
    /**
     * Enumeration indicating what is the type of expected value for frontend validation.
     */
    property_type?: PropertyType;
    /**
     * Name of the property to be displayed on frontend.
     */
    display_name: string;
    /**
     * Maximal length of expected value for frontend validation.
     */
    max_length?: number;
}

