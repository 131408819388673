import {
	BKTableAllocationLocationSpace,
	IBKItemInOrderBase,
	IBKOrderElementWithCsiInfos,
	IBKPublishedOrderBase,
	IBKPublishedOrderData,
	ICsiLineGenerator,
	ISalesServerModeReglement,
} from "@bk/jscommondatas";
import { DeclaredOrderDetail } from "@bk/price-management-common";

export interface IOrderFiscalData extends IBKOrderElementWithCsiInfos, IBKPublishedOrderData, ICsiLineGenerator {}

export interface IOrderCompleteData extends IOrderFiscalData, IBKPublishedOrderBase {}

export interface IOrderLocation {
	locationType: BKTableAllocationLocationSpace;
	easelNum: string;
}

export interface IOrderFiscalCorePrices {
	isFiscalCorePriceLoading: boolean;
	orderDetail: DeclaredOrderDetail;
	itemsWithoutFiscalPrice: IBKItemInOrderBase[];
	lastFiscalizedItems: IBKItemInOrderBase[];
}

export interface IOrderUpdatePartial {
	lineUuid: string;
	data: IBKItemInOrderBase;
}

export interface IOrderUsedPayments {
	paymentMethod: ISalesServerModeReglement;
	amount: number;
}
