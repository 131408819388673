/**
 * DKI-BE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 */
export type WeatherOverallStatus =
	'clear_sky'
	| 'few_clouds'
	| 'scattered_clouds'
	| 'broken_clouds'
	| 'overcast_clouds'
	| 'shower_rain'
	| 'light_rain'
	| 'rain'
	| 'thunderstorm'
	| 'snow';

export const WeatherOverallStatus = {
	ClearSky: 'clear_sky' as WeatherOverallStatus,
	FewClouds: 'few_clouds' as WeatherOverallStatus,
	ScatteredClouds: 'scattered_clouds' as WeatherOverallStatus,
	BrokenClouds: 'broken_clouds' as WeatherOverallStatus,
	OvercastClouds: 'overcast_clouds' as WeatherOverallStatus,
	ShowerRain: 'shower_rain' as WeatherOverallStatus,
	LightRain: 'light_rain' as WeatherOverallStatus,
	Rain: 'rain' as WeatherOverallStatus,
	Thunderstorm: 'thunderstorm' as WeatherOverallStatus,
	Snow: 'snow' as WeatherOverallStatus
};

