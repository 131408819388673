import { BKOrderSourceEnum } from '@bk/jscommondatas';
import { isDefined, isUndefined } from '@merim/utils';

import { getOrderSourcePrefix } from './get-order-source-prefix';

export function getOrderNumberForORBp(order: any): string {
	if (order === false || order === null || isUndefined(order)) {
		return '#';
	}
	if (isUndefined(order.orb)) {
		return 'X';
	}
	if (parseInt(order.orb) <= 0) {
		return '-';
	}

	const source = isDefined(order.source) ? parseInt(order.source) : BKOrderSourceEnum.ORDER_SOURCE_UNKNOWN;
	let prefix = getOrderSourcePrefix(source);

	// Orders from Kiosk should have only number, without 1-letter prefix
	if (prefix === 'K') {
		prefix = '';
	}

	return prefix + (parseInt(order.orb) % 1000);
}
