import { getTruthyEnumMapKeys, isEmptyObject } from "@merim/utils";
import { SalesChannelConfiguration } from "../../types";
import { canHaveDeviceSpecificConfiguration } from "./can-have-device-specific-configuration";
import { isSalesChannelOn } from "./is-sales-channel-on";

/**
 * Checks if given ORBp config is using some specifig devices,
 * eg: only KIOSK1 and KIOSK2.
 *
 * If the SalesChannel is turned OFF, then returns false, regardless of assigned devices.
 */
export function isUsingDeviceSpecificConfiguration(config: SalesChannelConfiguration): boolean {
	if (!config || !config.deviceSpecificConfiguration || isEmptyObject(config.deviceSpecificConfiguration)) {
		return false;
	}

	if (!isSalesChannelOn(config)) {
		return false;
	}

	// Sanity check
	// Is using deviceSpecific config for not allowed SalesChannel?
	if (canHaveDeviceSpecificConfiguration(config.salesChannel) === false) {
		console.warn(`isUsingDeviceSpecificConfiguration() - SalesChannel ${config.salesChannel} should not have device specific configuration ...`);
		return false;
	}

	// Check if there is actually any device allowed.
	const hasAnyDevicesAssigned = getTruthyEnumMapKeys(config.deviceSpecificConfiguration).length > 0;
	return hasAnyDevicesAssigned;
}
