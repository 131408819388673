/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 */

export enum SalesChannelOption {
    Deliveroo = 'DELIVEROO',
    DeliveryBk = 'DELIVERY_BK',
    EatIn = 'EAT_IN',
    JustEat = 'JUST_EAT',
    TakeAway = 'TAKE_AWAY',
    UberEats = 'UBER_EATS',
    Zot = 'ZOT',
    Please = 'PLEASE',
    Lyveat = 'LYVEAT'
}


