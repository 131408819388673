/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TimerDto { 
    /**
     * Timer to wash hands in seconds
     */
    washHands: number;
    /**
     * Timer for cards to turn into unattanded in seconds
     */
    unattendedProducts: number;
}

