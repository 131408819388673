export enum OrbStatus {
	Unknown = 'Unknown',
	/**
	 * Device is OFF. It does not display any Orders.
	 */
	Off = 'Off',
	/**
	 * This status should not be stored in .json.
	 *
	 * Device will be OFF soon.
	 * It displays current Orders, and when all Orders are processed, it will be OFF.
	 */
	ShuttingDown = 'ShuttingDown',
	/**
	 * Device is ON. It accepts Orders matching the filtering rules and load balancing.
	 */
	On = 'On',
}
