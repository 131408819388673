import { flattenArray, getUniqueValues } from "@merim/utils";

import { OrbpRole, OrbStatus, SalesChannel } from "../../enums";
import { OrbpDetail } from "../../types";
import { getSalesChannelsTurnedOn } from "../sales-channels/index";

// All SalesChannels have to be assigned to at least 1 active ORBp Master.
// The only exception is POS, because POS Orders are handled on POS when no ORBp handles them.
export function areAllSalesChannelsAssignedToSomeMaster(masterOrbps: OrbpDetail[]): boolean {
	// If there are no Active Masters at all, then all ORBps are turned OFF, so we can skip this rule
	const areAllOff = masterOrbps.filter(o => o.role === OrbpRole.Master).every(o => o.status === OrbStatus.Off);
	if (areAllOff) {
		return true;
	}

	const possibleSalesChannels = getUniqueValues(flattenArray(masterOrbps.map(o =>
		o.salesChannelConfigurations.map(x => x.salesChannel))));
	const salesChannelsToValidate = possibleSalesChannels.filter(sc => sc !== SalesChannel.CASH_MACHINE);

	const areAllAssigned = salesChannelsToValidate.every(sc => {
		return masterOrbps.some(orbp => {
			return orbp.status === OrbStatus.On
				&& orbp.role === OrbpRole.Master
				&& getSalesChannelsTurnedOn(orbp.salesChannelConfigurations).map(x => x.salesChannel).includes(sc);
		})
	});

	return areAllAssigned;
}
