import {
	BKDeliveryModeEnum,
	BKOrderSourceEnum,
	IBKGameData,
	IBKMenuBase,
	IBKNavigationScreenData,
	IBKProductBase,
} from '@bk/jscommondatas';
import { MenuSize, ProductPriceType } from '@bk/price-management-common';

export type NavScreenContent = {
	products: IBKProductBase[];
	menus: IBKMenuBase[];
	navigationScreens: Array<IBKNavigationScreenData & { active?: boolean }>;
	games: IBKGameData[];
};

export enum NAV_SCREEN_ITEM_TYPES {
	PRODUCT = 'PRODUCT',
	MENU = 'MENU',
	NAV_SCREEN = 'NAV_SCREEN',
	GAME = 'GAME',
	MENU_SIZE = 'MENU_SIZE',
	MENU_PRODUCT = 'MENU_PRODUCT',
}

export type NavScreenContentOptions = {
	deliveryMode?: BKDeliveryModeEnum;
	productPriceType?: ProductPriceType[];
	menuSize?: MenuSize[];
	orderSource?: BKOrderSourceEnum;
};
