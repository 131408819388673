import { Component, Input } from '@angular/core';

@Component({
	selector: 'dk-vertical-treshold-bar',
	templateUrl: './vertical-treshold-bar.component.html',
	styleUrls: ['./vertical-treshold-bar.component.scss'],
})
export class VerticalTresholdBarComponent {
	private _defaults = { steps: [3, 2, 1, 0], tresholds: [1, 2], value: 0 };

	@Input() params: { steps: number[]; tresholds: number[]; value: number } = this._defaults;

	public get horizontalBreaks(): number[] {
		return this.params ? this.params.steps : this._defaults.steps;
	}

	public get barHeight(): string {
		const value = this.params ? this.params.value : this._defaults.value;
		return `${value * 20 - 4}px`;
	}

	public isActiveTreshold(horizontalBreakValue: number): boolean {
		return this.params ? this.params.tresholds.includes(horizontalBreakValue) : false;
	}

	public tLineNumber(horizontalBreakValue: number): number {
		return horizontalBreakValue !== Math.max(...this.params.tresholds) ? 1 : 2;
	}
}
