/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ItemPriceDto { 
    /**
     * Base price of the item without VAT.
     */
    ht: number;
    /**
     * Value of the item\'s VAT.
     */
    tva: number;
    /**
     * Full price of the item with VAT (\"ht\" + \"tva\").
     */
    ttc: number;
    /**
     * VAT rate applicable on the item in percentage.
     */
    pc: number;
    /**
     * VAT rate applicable on the item in percentage for TAKE OUT when different.
     */
    pcto?: number;
    /**
     * Base price of the item withou VAT for TAKE OUT when different.
     */
    htto?: number;
}

