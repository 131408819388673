export const SNACKBAR_TIMEOUT_5000 = 5000;
/**
 * show MatTooltip after X miliseconds
 */
export const TOOLTIP_SHOW_DELAY = 500;

/** currencies keys in config-env file */
export const CURRENCY_KEY = 'currency';
export const CURRENCY_CODE_KEY = 'code';
export const CURRENCY_SYMBOL_KEY = 'symbol';

export const BRAND_NAME_KEY = 'brand';

/**
 * Validation warning is displayed when there is some SalesChannel not assigned to any ORBp.
 * In that case one ORBp should be marked as isDefault, and automatically accept those SalesChannels.
 *
 * When validation is On, then HIDE_COLUMN_IS_DEFAULT_ORBP should be false.
 */
export const VALIDATE_DEFAULT_ORBP = 'validateDefaultOrbp';

/**
 * Because not every Brand is interested in having default ORBp.
 * Should be in sync with VALIDATE_DEFAULT_ORBP.
 */
export const HIDE_COLUMN_IS_DEFAULT_ORBP = 'hideColumnIsDefaultOrb';

/**
 * Enable PMS
 */
export const ENABLE_PRODUCTION_MANAGEMENT_SYSTEM = 'enableProductionManagementSystem';

/**
 * Enable configuration of order sorting for ORBp
 */
export const ENABLE_DSS_ORDER_SORT_CONFIGURATION = 'enableDssOrderSortConfiguration';
