
// Used only for legacy food category mapping and as string values in unit tests,
export enum FoodCategoryLegacy {
	UNKNOWN = 'UNKNOWN',
	HOT_DRINKS = 'HOT_DRINKS',
	COLD_DRINKS = 'COLD_DRINKS',
	TAP_DRINKS = 'TAP_DRINKS',
	ALCOHOLS = 'ALCOHOLS',
	BURGERS = 'BURGERS',
	HOT_DISH = 'HOT_DISH',
	KITCHEN_COUNTER = 'KITCHEN_COUNTER',
	PASTRY_KITCHEN = 'PASTRY_KITCHEN',
	FRUITS = 'FRUITS',
	DESSERTS = 'DESSERTS',
	FRIDGE_DESSERTS = 'FRIDGE_DESSERTS',
	BREAKFAST = 'BREAKFAST',
	TOYS = 'TOYS',
	ICE_CREAMS = 'ICE_CREAMS',
	SALADS = 'SALADS',
	COLD_SALADS = 'COLD_SALADS',
	HOT_SALADS = 'HOT_SALADS',
	SALAD_DRESSINGS = 'SALAD_DRESSINGS',
	FRIED_PRODUCTS = 'FRIED_PRODUCTS',
	FRIES = 'FRIES',
	OTHER_STUFF = 'OTHER_STUFF',
	SAUCES = 'SAUCES',
	SNACK_SAUCES = 'SNACK_SAUCES',
	GOURMET_HOT_DRINKS = 'GOURMET_HOT_DRINKS',
	GOURMET_COLD_DRINKS = 'GOURMET_COLD_DRINKS',
	GOURMET_BAG_DESSERTS = 'GOURMET_BAG_DESSERTS'
}
