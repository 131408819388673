import { HttpClient } from '@angular/common/http';
import { effect, inject, Injectable, signal, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { hasWhiteModeSignature } from '@bk/fullstack-common';
import { IBKKingdomClientData, IBKKingdomRebootCoupon } from '@bk/jscommondatas';
import { IDefaultConfig } from '@libs/shared/interfaces';
import { ClerkFacade } from '@libs/shared/store/clerk';
import { ConfigurationFacade } from '@libs/shared/store/configuration';
import { OrderFacade } from '@libs/shared/store/order';
import {
	getApplicableMenusForDiscount,
	getApplicableProductsForDiscount,
	isDiscountForMenus,
	isDiscountForProducts,
} from 'libs/shared/utils';

import { values } from 'ramda';
import { combineLatest, filter, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AVAILABLE_JSON, ConfigurationUrlsType, IBKBigData, IFidelityConnection, LOCAL_MANAGER } from '../../models';
import { ConfigurationService } from '../configuration.service';

@Injectable({ providedIn: 'root' })
export abstract class FidelityAbstractService<T extends IDefaultConfig> implements IFidelityConnection {
	private readonly ngUnsubscribe$: Subject<void> = new Subject<void>();
	protected url: string;

	configurationService = inject(ConfigurationService<T>);
	configurationFacade = inject(ConfigurationFacade);
	clerkFacade = inject(ClerkFacade);

	httpClient = inject(HttpClient);
	route = inject(ActivatedRoute);
	_loggedUser: IBKKingdomClientData;
	private _isFidelityActive: boolean = false;
	private _bigDataSig: Signal<IBKBigData>;
	private fidelityRewardsUsedSig: Signal<IBKKingdomRebootCoupon[]> = signal([]);
	private _orderFacade = inject(OrderFacade);
	private _availabilitySig: Signal<AVAILABLE_JSON>;

	abstract login(code: string, orderUUID: string, machineName: string);

	abstract logout();

	abstract getData();

	constructor() {
		const configValues$ = this.configurationService.config$;
		const queryParams = this.route.queryParams;
		const restoConfigSig = toSignal(this.configurationFacade.getRestoConfig());
		const restoSettingsSig = toSignal(this.configurationFacade.getRestoSettings());
		const clerkPaymentDataSig = toSignal(this.clerkFacade.getPayments$());
		this._bigDataSig = toSignal(this.configurationFacade.getBigData());
		this.fidelityRewardsUsedSig = toSignal(this._orderFacade.getFidelityRewardsUsed());
		this._availabilitySig = toSignal(this.configurationFacade.getAvailability());

		effect(() => {
			this._isFidelityActive =
				!hasWhiteModeSignature(clerkPaymentDataSig()) && restoConfigSig().restaurant?.enableKingdom && restoSettingsSig().enableKingdom;
		});

		combineLatest([configValues$, queryParams])
			.pipe(
				takeUntil(this.ngUnsubscribe$),
				filter(([configData, queryParams]) => configData?.['API']?.[LOCAL_MANAGER] || queryParams?.localManager)
			)
			.subscribe(([configData, queryParams]) => {
				this.url = this.getUrlPrecedency({
					localManagerUrl: configData?.['API']?.[LOCAL_MANAGER].url,
					directUrl: queryParams?.localManager,
				});

				if (this.getUrlPrecedency) {
					console.log(`[APP] Fidelity service: Fidelity service url updated to: ${values(this.getUrlPrecedency)?.[0]}`);
				}
			});
	}

	get isFidelityActive(): boolean {
		return this._isFidelityActive;
	}

	set loggedUser(user: IBKKingdomClientData) {
		this._loggedUser = user;
	}

	get loggedUser(): IBKKingdomClientData {
		if (!this._loggedUser) {
			return undefined;
		}
		const fidelityUser = { ...this._loggedUser };
		fidelityUser.points -= this.fidelityRewardsUsedSig().reduce((total, reward) => total + reward.points, 0);
		return fidelityUser;
	}

	get userDiscounts(): IBKKingdomRebootCoupon[] {
		const usedRewardIds = this.fidelityRewardsUsedSig().map((reward) => reward.idReboot);

		return this.loggedUser.coupons
			.filter((item) => item.points == 0)
			.filter((item) => this.canCouponBePurchased(item))
			.filter((item) => !usedRewardIds.includes(item.idReboot));
	}

	get userRewards(): IBKKingdomRebootCoupon[] {
		const usedRewardIds = this.fidelityRewardsUsedSig().map((reward) => reward.idReboot);

		return this.loggedUser.coupons
			.filter((item) => item.points > 0)
			.filter((item) => this.canCouponBePurchased(item))
			.filter((item) => !usedRewardIds.includes(item.idReboot));
	}

	getUrlPrecedency(urls: ConfigurationUrlsType): string | undefined {
		if (urls.directUrl) {
			return urls.localManagerUrl;
		} else if (urls.localManagerUrl) {
			return urls.localManagerUrl;
		}

		return undefined;
	}

	logoutUser(): void {
		this.loggedUser = null;
	}

	private canCouponBePurchased(coupon: IBKKingdomRebootCoupon) {
		const discount = this._bigDataSig().discounts[coupon.idReboot];
		if (!discount?.active) {
			return false;
		}
		if (this.fidelityRewardsUsedSig().some((usedReward) => usedReward.idReboot === discount.id)) {
			return false;
		}
		if (
			isDiscountForMenus(discount.discountType) &&
			getApplicableMenusForDiscount(this._bigDataSig(), this._availabilitySig(), discount, '_discountApplicableToPattern').length === 0
		) {
			console.warn(`Discount ${discount.id} has no active menus`);
			return false;
		}
		if (
			isDiscountForProducts(discount.discountType) &&
			getApplicableProductsForDiscount(this._bigDataSig(), this._availabilitySig(), discount, '_discountApplicableToPattern').length === 0
		) {
			console.warn(`Discount ${discount.id} has no active products`);
			return false;
		}
		return true;
	}

	ngOnDestroy() {
		this.ngUnsubscribe$.next();
		this.ngUnsubscribe$.complete();
	}
}
