/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ItemCrownPriceDto { 
    /**
     * If true, this item may be ordered internally for employee points.
     */
    crownEnable: boolean;
    /**
     * Price in employee points without taxes.
     */
    crown: number;
    /**
     * Id of the crown family.
     */
    crownFamily: number;
}

