import { LoadDistribution, OrbStatus } from '../../enums';
import { OrbpDetail } from '../../types';

/**
 * All active Master ORBp in a Program should have the same load balancing rules to maintain predictable behaviour.
 *
 * Expected usage: It is recommended to not run it directly, but via the LoadBalancingValidator.
 */
export function hasConflictingLoadBalancing(orbpDevice: OrbpDetail, masterOrbpDevices: OrbpDetail[]): boolean {
	const loadBalancingRule = orbpDevice.loadDistribution;
	const otherMasterOrbps = masterOrbpDevices.filter((o) => o.status === OrbStatus.On && o.id !== orbpDevice.id);

	const otherLoadBalancingRules = otherMasterOrbps.map((o) => o.loadDistribution);
	let allowedRules: LoadDistribution[] = [];

	// LoadDistribution.Empty can be combined with any other LoadDistribution rule
	// But other rules can not be combined
	if ([LoadDistribution.PRODUCT_DYNAMIC_DISTRIBUTION, LoadDistribution.ROUND_ROBIN, LoadDistribution.DYNAMIC_DISTRIBUTION].includes(orbpDevice.loadDistribution)) {
		allowedRules = [LoadDistribution.EMPTY];
	}

	if (orbpDevice.loadDistribution === LoadDistribution.EMPTY) {
		allowedRules = [LoadDistribution.ROUND_ROBIN, LoadDistribution.DYNAMIC_DISTRIBUTION, LoadDistribution.PRODUCT_DYNAMIC_DISTRIBUTION];
	}

	const hasConflict = otherLoadBalancingRules.some((lb) => loadBalancingRule !== lb && allowedRules.includes(lb) === false);

	return hasConflict;
}
