import { IBKPublishedOrderDataCassandra } from "../../../../bk-jscommondatas/src";

export interface IBKLiveCassandraOrder {
	businessday: number;
	orderuuid: string;
	mdmorder: IBKPublishedOrderDataCassandra;
}

// ultra-crazy-confusing almost-duplication of interface of the same name with incompatible properties from bk-bo-common-exchanged-data.ts
export interface IBKLiveOrder {
	uuid: string;
	orb: number;
	source: number;
	state: number;
	ackedkds: number[];
	order?: IBKLiveCassandraOrder;

	/**
	 * For Load Balancing in DSS Mode:
	 * If the Order has been previously assigned to some ORBp in some LoadBalancing mode,
	 * then this property will be filled.
	 */
	// Note: Currently I did not put it the IBKLiveHeaders in bk-bo-common-exchanged-data.ts
	// because it may not be used after all in the bk-bo-common-exchanged-data.ts.
	assignedToOrbpId?: number;

	isParked?: boolean;
}