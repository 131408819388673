import {
	BKCampaignDataWithLogic,
	BKMediaTargetFamilyEnum,
	IBKCampaignData,
	IBKItemBase,
	IBKItemInOrderBase,
	IBKMediaData,
	IBKMenuBase,
	IBKProductBase,
	IBKProductFamilyData
} from '@bk/jscommondatas';
import { LANGUAGES } from '@libs/shared/modules/i18n/models';
import { keys, omit, pickBy, values } from 'ramda';
import {
	ApplicationNavigationScreen,
	AvailabilityMap,
	AvailabilityStoreModel,
	AVAILABLE_JSON,
	BigDataStoreModel,
	IBKBigData,
	IRestoConfig,
	MENU_SIZE
} from '../models';
import { getMenuStepProducts } from './order-items.functions';

/** Product functions **/

/**
 * Is product available
 * @param bigData
 * @param availability
 * @param id
 */
export function isProductAvailable(bigData: BigDataStoreModel, availability: AvailabilityStoreModel, id: number): boolean {
	if (!bigData || !availability) {
		return false;
	}

	if (!bigData.data?.products?.[id]) {
		return false;
	}

	return availability?.data?.['availability']?.[id] !== false;
}

/**
 * Is product active ( not disabled )
 * @param bigData
 * @param availability
 * @param id
 */
export function isProductActive(bigData: BigDataStoreModel, availability: AvailabilityStoreModel, id: number): boolean {
	if (!bigData || !availability) {
		return false;
	}

	if (!bigData.data?.products?.[id]) {
		return false;
	}

	return availability?.data?.['productActive']?.[id] !== false;
}

// TODO: Add unit tests
/**
 * Is Product available with all default ingredients?
 */
export function isProductWithIngredientsAvailable(bigData: BigDataStoreModel, availability: AvailabilityStoreModel, id: number): boolean {
	if (!bigData || !availability) {
		return false;
	}

	const product = bigData.data?.products?.[id];

	if (availability?.data?.['availability']?.[id] === false || !product) {
		return false;
	}

	const defaultIngredients = (product._ingredients || []).filter((ing) => ing.initialQty > 0);
	const areAllDefaultIngredientsAvailable = defaultIngredients.every((item) =>
		isIngredientAvailable(availability?.data, item.ingredientDefault)
	);

	return areAllDefaultIngredientsAvailable;
}

export function isProductWithIngredientsAvailableAndActive(
	bigData: BigDataStoreModel,
	availability: AvailabilityStoreModel,
	id: number
): boolean {
	if (!isProductActive(bigData, availability, id)) {
		return false;
	}

	return isProductWithIngredientsAvailable(bigData, availability, id);
}

export function isIngredientAvailable(availability: AVAILABLE_JSON, id: number): boolean {
	const ingredientsInAvailableJson: AvailabilityMap = availability?.['ingredientActive'];
	return ingredientsInAvailableJson?.[id] !== false;
}

// TODO: Add unit tests
/**
 * Is Menu available with all default Products?
 */
export function isMenuWithAllDefaultProductsAvailable(
	bigData: BigDataStoreModel,
	availability: AvailabilityStoreModel,
	id: number
): boolean {
	if (!bigData || !availability) {
		return false;
	}

	const menu = bigData.data?.menus?.[id];

	if (!menu) {
		return false;
	}

	const preSelectedProductIds = menu._steps.map((menuStep) => menuStep._defaultSelection);
	const areAllPreselectedProductsAvailable = preSelectedProductIds.every((productId) => {
		return isProductWithIngredientsAvailableAndActive(bigData, availability, productId);
	});
	return areAllPreselectedProductsAvailable;
}

/**
 * at least one item per step must be available in menu to make it available
 * @param {BigDataStoreModel} bigData
 * @param {AvailabilityStoreModel} availability
 * @param {number} id
 * @returns {boolean}
 */
export function isMenuAvailable(bigData: BigDataStoreModel, availability: AvailabilityStoreModel, id: number): boolean {
	if (!bigData || !availability) {
		return false;
	}

	const menu = bigData.data?.menus?.[id];

	if (!menu) {
		return false;
	}

	const preSelectedProductIds = menu._steps.map((item) => getMenuStepProducts(item, menu, bigData.data, availability.data));
	const dataPerStepInSize = preSelectedProductIds.reduce(
		(a, menuSizes) => {
			keys(menuSizes).forEach((item) => {
				a[item] = (a[item] || []).concat(menuSizes[item]?.length || 0);
			});
			return a;
		},
		{} as Record<MENU_SIZE, number[]>
	);

	return values(dataPerStepInSize).some((size) => size.every((a) => a > 0));
}

export function getProductFamily(item: IBKItemBase, bigData: IBKBigData): IBKProductFamilyData {
	const productFamilyId: number = bigData.products?.[item.id]._familyId;
	return bigData.productFamilies?.[productFamilyId];
}

/** End of Product functions **/

/** Medias functions **/

export function isMediaFromTargetFamily(media: IBKMediaData, targetFamily: BKMediaTargetFamilyEnum) {
	return media.mediaTargets.some((mediaTargetId: number) => {
		return mediaTargetId - (mediaTargetId % 100) === targetFamily;
	});
}

export function convertTranslocoLanguageToBigDataLanguage(lang: LANGUAGES): string {
	if (lang === LANGUAGES.EN) {
		return LANGUAGES.EN.split('-')[0];
	} else if (lang === LANGUAGES.FR) {
		return LANGUAGES.FR.split('-')[0];
	} else {
		return lang;
	}
}

export function canProductBeModified(bigData: IBKBigData, id: number): boolean {
	const product = bigData.products?.[id];
	const canBeModified = product?._ingredients.some((item) => {
		return item.minQty !== item.maxQty && item.canModify;
	});
	return canBeModified;
}

/** End of Medias functions **/

export function modifyProductsAvailabilityInModel(
	products: Record<string, IBKProductBase>,
	bigData: BigDataStoreModel,
	availability: AvailabilityStoreModel
): any {
	const updatedProducts = values(products).reduce((a, b) => {
		a[b.id] = {
			...b,
			available: isProductWithIngredientsAvailable(bigData, availability, b.id)
		};
		return a;
	}, {});
	return updatedProducts;
}

export function selectOnlyAvailableCampaigns(bigData: IBKBigData, availability: AVAILABLE_JSON): Record<string, IBKCampaignData> {
	const campaignAvailability = pickBy<AvailabilityMap, Record<string, boolean>>(
		(value: boolean) => !value,
		availability.campaignAvailability
	);
	const notAvailableCampaigns = keys(campaignAvailability);
	return omit(notAvailableCampaigns, bigData.campaigns);
}

export function isMenu(item: IBKItemInOrderBase | IBKMenuBase | IBKProductBase): boolean {
	return '_boMenuType' in item;
}

export function isProduct(item: IBKItemInOrderBase | IBKMenuBase | IBKProductBase): boolean {
	return !('_boMenuType' in item) && ('_familyId' in item || 'familyId' in item);
}

export function getActiveNavigationScreenByPriority(
	device: ApplicationNavigationScreen,
	allAvailableCampaings: BKCampaignDataWithLogic[],
	restoConfig: IRestoConfig
): number {
	const campaignAttribute = device === ApplicationNavigationScreen.KIOSK ? 'kioskNavigationInCampaign' : 'posNavigationInCampaign';
	const defaultConfigAttribute = device === ApplicationNavigationScreen.KIOSK ? 'kioskConfigsDefault' : 'posConfigsDefault';

	const allDeviceNavigationScreens = allAvailableCampaings.map((campaign: BKCampaignDataWithLogic) => campaign[campaignAttribute]).flat();

	if (!allDeviceNavigationScreens.length) {
		return restoConfig[defaultConfigAttribute];
	}

	return allDeviceNavigationScreens.sort((a, b) => a.priority - b.priority)[0].navigationConfigurationIds[0];
}
