import { TaxContext } from './tax-context';

export type VATValue = {
	tvaName: TaxContext;
	/** "Normal", "Alcohol", "Empty" etc... */
	value: string;
	percent: number;
};

export const VATValueDefault = 'Normal';
