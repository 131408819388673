/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SelectionPatternDto } from './selection-pattern-dto';


export interface MenuStepDto { 
    /**
     * Id of the menu step (utilized only for HDFR env).
     */
    id?: number;
    /**
     * Dictionary (associative array) of names displayed on the step where key is the language.
     */
    _name: { [key: string]: string; };
    /**
     * Patterns for the selection of products for the menu step.
     */
    _patterns: Array<SelectionPatternDto>;
    /**
     * Id of the pre-selected (default) product.
     */
    _defaultSelection: number;
    /**
     * Type of a step to be displayed in KIOSK.   Values: NONE = 0,   BURGER = 1,   SIDE = 2,   DRINK = 3,   SWEET = 4,   TOY = 5,   GIFT = 6,   OTHER = 7
     */
    _stepType?: number;
}

