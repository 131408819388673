@if (localisedTexts$ | async; as texts) {
	<form [formGroup]="passwordForm">
		<legend>
			{{ texts.title }}
		</legend>
		<mat-form-field>
			<mat-label>{{ texts.inputs.password.label }}</mat-label>
			<!-- <merim-password  id="dk-new-password"  [isChangePassword]="false" [ngModelOptions]="{standalone: true}" [(ngModel)]='pw'></merim-password> -->
			<input matInput [type]="maskInput ? 'password' : 'text'" formControlName="password"/>
			<button mat-button matSuffix mat-icon-button (click)="maskInput = !maskInput">
				<mat-icon>{{ maskInput ? 'visibility_off' : 'visibility' }}</mat-icon>
			</button>
		</mat-form-field>
		@if (error) {
			<div class="error-msg">
				{{ texts[errorMsg] }}
			</div>
		}
		<dk-action-buttons [buttons]="actionButtons$ | async" (positiveAction)="promptPasswordSetup()"></dk-action-buttons>
	</form>
}
