import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { OrderEffects } from './state/effects';
import { OrderFacade } from './state/facade';
import { reducers } from './state/reducer';

@NgModule({
	imports: [StoreModule.forFeature('order', reducers), EffectsModule.forFeature([OrderEffects])],
	providers: [OrderFacade],
})
export class StateOrderModule {}
