import {
	BKItemSelectionPatternSourceEnum,
	BKPatternUtilities,
	IBKBigData,
	IBKDiscountData,
	IBKItemInOrderBase,
	IBKMenuBase,
	IBKProductBase,
} from '@bk/jscommondatas';
import { IProductSummary } from '@libs/shared/interfaces';
import { AVAILABLE_JSON, ProductFamilies } from '@libs/shared/models';
import { intersection, pluck, values } from 'ramda';
import { isMenu, isMenuWithAllDefaultProductsAvailable, isProduct, isProductWithIngredientsAvailableAndActive } from './big-data.functions';
import { isOrderItemMenu } from './order-items.functions';

export const getApplicableMenusForDiscount = (
	bigData: IBKBigData,
	availability: AVAILABLE_JSON,
	discount: IBKDiscountData,
	patternAttributeName: '_discountApplicableToPattern' | '_discountApplicableWhenPattern'
): IBKMenuBase[] => {
	const bigDataConfig = { loading: false, data: bigData };
	const availabilityConfig = { loading: false, data: availability };

	return BKPatternUtilities.filterMenusMulti(discount[patternAttributeName], values(bigData.menus), bigData.menus).filter((menu) => {
		return isMenuWithAllDefaultProductsAvailable(bigDataConfig, availabilityConfig, menu.id);
	});
};

export const getApplicableProductsForDiscount = (
	bigData: IBKBigData,
	availability: AVAILABLE_JSON,
	discount: IBKDiscountData,
	patternAttributeName: '_discountApplicableToPattern' | '_discountApplicableWhenPattern'
): IBKProductBase[] => {
	const productFamilies = new ProductFamilies(bigData.productFamilies);
	productFamilies.addProductsToFamily(bigData.products);
	const bigDataConfig = { loading: false, data: bigData };
	const availabilityConfig = { loading: false, data: availability };

	return BKPatternUtilities.filterProductsMulti(
		discount[patternAttributeName],
		values(bigData.products),
		bigData.products,
		productFamilies.allFamilies
	).filter((product) => isProductWithIngredientsAvailableAndActive(bigDataConfig, availabilityConfig, product.id));
};

export const getApplicableForReward = (
	bigData: IBKBigData,
	availability: AVAILABLE_JSON,
	discount: IBKDiscountData,
	patternAttributeName: '_discountApplicableToPattern' | '_discountApplicableWhenPattern'
): (IBKProductBase | IBKMenuBase)[] => {
	return discount[patternAttributeName]
		.map((item) => {
			switch (item.target) {
				case BKItemSelectionPatternSourceEnum.PRODUCT:
					return getApplicableProductsForDiscount(bigData, availability, discount, patternAttributeName);
				case BKItemSelectionPatternSourceEnum.MENU:
					return getApplicableMenusForDiscount(bigData, availability, discount, patternAttributeName);
				default:
					console.warn(`[APP]: Selection pattern for ${item.label} is not supported.`);
					return null;
			}
		})
		.filter((item) => !!item)
		.flat();
};

export const getOrderItemLineUuidsOfDiscountDependantItems = (
	bigData: IBKBigData,
	availability: AVAILABLE_JSON,
	orderContent: IBKItemInOrderBase[],
	product: IProductSummary
): string[] => {
	const currentOrderContentDependencies = orderContent.reduce((a, b) => {
		const itemAppliedDiscountRequirementIds = b.itemAppliedDiscount
			.map((appliedDiscount) => {
				return getApplicableForReward(bigData, availability, appliedDiscount, '_discountApplicableWhenPattern');
			})
			.flat();
		return {
			...a,
			[b.lineuuid]: {
				productIds: pluck(
					'id',
					itemAppliedDiscountRequirementIds.filter((item) => isProduct(item))
				),
				menuIds: pluck(
					'id',
					itemAppliedDiscountRequirementIds.filter((item) => isMenu(item))
				),
			},
		};
	}, {});

	const currentIds = orderContent
		.filter((item) => {
			return item.lineuuid !== product.lineuuid;
		})
		.reduce(
			(a, b) => {
				return isOrderItemMenu(b)
					? { productIds: a.productIds, menuIds: a.menuIds.concat(b.id) }
					: { productIds: a.productIds.concat(b.id), menuIds: a.menuIds };
			},
			{ productIds: [], menuIds: [] }
		);

	const lineUuidsToRemove = Object.entries(currentOrderContentDependencies)
		.reduce((a: string[], [key, val]: [string, Record<'menuIds' | 'productIds', number[]>]) => {
			if (val.productIds.length === 0 && val.menuIds.length === 0) {
				return a;
			}

			if (intersection(currentIds.productIds, val.productIds).length > 0 || intersection(currentIds.menuIds, val.menuIds).length > 0) {
				return a;
			}

			return [...a, key];
		}, [])
		.filter((item) => item !== product.lineuuid);

	return lineUuidsToRemove;
};

export const isDependencyForDiscountFullfilled = (
	bigData: IBKBigData,
	availability: AVAILABLE_JSON,
	orderContent: IBKItemInOrderBase[],
	discount: IBKDiscountData
): boolean => {
	const requiredIds = getApplicableForReward(bigData, availability, discount, '_discountApplicableWhenPattern').reduce(
		(a, b) => {
			return isMenu(b)
				? { productIds: a.productIds, menuIds: a.menuIds.concat(b.id) }
				: { productIds: a.productIds.concat(b.id), menuIds: a.menuIds };
		},
		{ productIds: [], menuIds: [] }
	);

	const currentOrderContentIds = orderContent.reduce(
		(a, b) => {
			return isOrderItemMenu(b)
				? { productIds: a.productIds, menuIds: a.menuIds.concat(b.id) }
				: { productIds: a.productIds.concat(b.id), menuIds: a.menuIds };
		},
		{ productIds: [], menuIds: [] }
	);

	return (
		intersection(currentOrderContentIds.productIds, requiredIds.productIds).length > 0 ||
		intersection(currentOrderContentIds.menuIds, requiredIds.menuIds).length > 0
	);
};
