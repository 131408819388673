/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ValidationMessageDto { 
    /**
     * Severity of the validation error.
     */
    severity: ValidationMessageDto.SeverityEnum;
    /**
     * Name of the property that is invalid. Can be used to highlight matching field in UI.
     */
    propertyName: string;
    /**
     * Raw translation key of the validation message to show to the user. This is currently only used in console.log() for easier debugging.
     */
    translationKey: string;
}
export namespace ValidationMessageDto {
    export type SeverityEnum = 'info' | 'warning' | 'error';
    export const SeverityEnum = {
        Info: 'info' as SeverityEnum,
        Warning: 'warning' as SeverityEnum,
        Error: 'error' as SeverityEnum
    };
}


