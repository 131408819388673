import {
	IBKAnnotationData,
	IBKDiscountInOrderData,
	IBKItemInOrderBase,
	IBKMachine,
	IBKOrderEventData,
	IBKPreproductionOrderData,
} from '@bk/jscommondatas';
import { clone } from 'ramda';

export class PreproductionOrderData implements IBKPreproductionOrderData {
	machine: IBKMachine = {} as IBKMachine;
	orderUUID: string = '';
	orderContent: IBKItemInOrderBase[] = [];
	orderDelContent: IBKItemInOrderBase[] = [];
	orderDelContentAfterDecl: IBKItemInOrderBase[] = [];
	orderDelContentAfterKitchenScreen?: IBKItemInOrderBase[] = [];
	deliveryMode: number = 0; //BKDeliveryModeEnum
	state: number = 0; //BKOrderState
	statedetail?: number = 0;
	source: number = 0;
	creationdate: number = 0;
	paidDate: number = 0;
	firstProductDate: number = 0;
	stateChangeDate: number = 0;
	orb: number = 0;
	annotations: IBKAnnotationData[] = [];
	orderDiscount: IBKDiscountInOrderData[] = [];
	event: IBKOrderEventData[] = [];

	constructor(init?: IBKPreproductionOrderData) {
		Object.assign(this, clone(init));
	}
}
