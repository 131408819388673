/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrbpConfigurationBaseDto } from './orbp-configuration-base-dto';
import { PrinterDto } from './printer-dto';


export interface PrinterConfigurationDto { 
    /**
     * Id of the printer. Same as in the printer.id.
     */
    id: string;
    /**
     * Detail of the printer.
     */
    printer: PrinterDto;
    /**
     * Configuration of the matching ORBp.
     */
    orbpConfiguration: OrbpConfigurationBaseDto;
}

