export class BreadcrumbItem {
	label: string;
	url: string;
	disabled: boolean;
	enablePrevious: boolean;

	constructor(init?: Partial<BreadcrumbItem>) {
		Object.assign(this, init);
	}
}
