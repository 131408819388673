/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BoGlobalManagerFeatures } from './bo-global-manager-features';
import { Brand } from './brand';
import { BrandConfigurationWebhook } from './brand-configuration-webhook';
import { Environment } from './environment';
import { WeborderChannel } from './weborder-channel';
import { BrandConfigurationLanguages } from './brand-configuration-languages';


export interface BoGlobalManagerConfiguration { 
    brand: Brand;
    environment: Environment;
    languages: BrandConfigurationLanguages;
    features: BoGlobalManagerFeatures;
    weborder_channels: Array<WeborderChannel>;
    /**
     * Configuration of webhooks and respective events for which they should be sent.
     */
    webhooks: Array<BrandConfigurationWebhook>;
}

