import { AvailableJson, Product } from "@bk/fullstack-common";
import { BKDeliveryModeEnum } from "@bk/jscommondatas";

import { ProductPriceType } from "../enums";
import { GlobalPriceDefinition, LocalPriceDefinitionPerSalesChannel } from "../types";
import { convertGlobalPriceToLocalPrice } from "./convert-global-price-to-local-price";
import { getLocalPriceDefinitions } from "./get-local-price-definitions";
import { getApplicablePriceForSalesChannel } from "./get-price-for-sales-channel";
import { mapDeliveryModeToSalesChannelOption } from "./map-delivery-mode-to-sales-channel-option";
import { mergeLocalPriceDefinitions } from "./merge-local-price-definitions";
import { updatePriceTTC } from "./update-price-ttc";

/**
 * Used in POS and KIOSK.
 * Patch prices for given Product based on currectly selected DeliveryMode/SalesChannelOption.
 * The price definitions are read and combined from both bigDatas.json and available.json
 */
export function patchProductPrices(
	product: Product,
	deliveryMode: BKDeliveryModeEnum,
	availableJson: AvailableJson,
	globalPrices: GlobalPriceDefinition[]): Product {
	
	const salesChannelOption = mapDeliveryModeToSalesChannelOption(deliveryMode);

	const localPrices = getLocalPriceDefinitions('PRODUCT', salesChannelOption, availableJson, product.id);
	const defaultPrices = globalPrices
		.filter(gp => gp.salesChannelOption === salesChannelOption)
		.map(gp => convertGlobalPriceToLocalPrice(gp));
	const mergedPriceDefinitions: LocalPriceDefinitionPerSalesChannel[] = mergeLocalPriceDefinitions(defaultPrices, localPrices, globalPrices);

	const soloPrices = mergedPriceDefinitions.filter(p => p.rowIdentifier === ProductPriceType.Solo);
	const suggestionPrices = mergedPriceDefinitions.filter(p => p.rowIdentifier === ProductPriceType.InSuggestion);
	const suplementPrices = mergedPriceDefinitions.filter(p => p.rowIdentifier === ProductPriceType.SupplementMenu);

	// Sanity check - There should be only 1 price definition for given "SalesChannelOption and rowIdentifier"
	if (soloPrices.length > 1 || suggestionPrices.length > 1 || suplementPrices.length > 1) {
		const errMessage = `patchProductPrice(): Duplicated price definitions found.`;
		console.error(errMessage);
		throw new Error(errMessage);
	}

	const applicablePriceForSolo = getApplicablePriceForSalesChannel(soloPrices[0]);
	const applicablePriceForSuggestion = getApplicablePriceForSalesChannel(suggestionPrices[0]);
	const applicablePriceForSupplement = getApplicablePriceForSalesChannel(suplementPrices[0]);

	// Handle Solo prices
	if (applicablePriceForSolo === undefined) {
		// This product is disabled globally. Can not be sold.
		// TODO DEV-2029 - Will be addressed in https://reboot-qsr.atlassian.net/browse/DEV-2029
		// todo: disable the product
		product.isDisabled = true;
	} else {
		updatePriceTTC(salesChannelOption, product.vatValues, product._priceM, applicablePriceForSolo);
		product.isDisabled = false;
	}

	product._priceSuggestionMenuTTC = applicablePriceForSuggestion ?? 0;
	product._priceExtraMenuTTC = applicablePriceForSupplement ?? 0;

	return product;
}
