export interface PlutusDevicesInstances {
	instances: InstancePlutus[];
}
export interface InstancePlutus {
	name: string;
	isConnected: boolean;
}

export interface PlutusArchive {
    keyId: number;
    signature: string;
    start: number;
    end: number;
    name: string;
    schoolMode: boolean;
    type: PlutusArchiveType;
    restaurant: Restaurant;
}

export interface Restaurant {
    name: string;
    nameExtra: string;
    entityId: string;
    establishmentId: string;
    vatNumber: string;
    businessCode: string;
    address: string;
    addressExtra: string;
    zipCode: number;
    city: string;
    country: string;
}

export enum PlutusArchiveType {
    MONTHLY = 'MONTHLY',
    MANUAL = 'MANUAL',
    YEARLY = 'YEARLY',
}