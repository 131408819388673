/**
 * DKI-BE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ProductOrders {
	/**
	 * Total order count.
	 */
	count: number;
	/**
	 * Total value (EUR).
	 */
	value: number;
	/**
	 * The name of the product.
	 */
	product: string;
	/**
	 * PLU of the product.
	 */
	product_plu: string;
	/**
	 * Mean order count from the beginning of the day to the current time.
	 */
	mean_count: number;
	/**
	 * Mean order count for the whole day
	 */
	mean_count_whole_day: number;
	/**
	 * Mean total value (EUR) from the beginning of the day to the current time
	 */
	mean_value: number;
	/**
	 * Mean total value (EUR) for the whole day
	 */
	mean_value_whole_day: number;
}

