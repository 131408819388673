import { LocalPriceDefinitionPerSalesChannel } from "../types";

/**
 * Helper function for LocalPriceDefinitionPerSalesChannel.
 * Returns undefined, when the Item is not sold globally.
 * When item can be sold globally, then it returns either local or global price.
 * Local price is prefered if defined.
 */
export function getApplicablePriceForSalesChannel(localPriceDefinition: LocalPriceDefinitionPerSalesChannel | undefined): number | undefined {
	if (localPriceDefinition === undefined) {
		return undefined;
	}


	if (localPriceDefinition.isDisabled) {
		return undefined;
	} else {
		// Reference price means "globaly defined price"
		return localPriceDefinition.localPrice ?? localPriceDefinition.referencePrice;
	}
}
