/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PermissionDto { 
    /**
     * Name of the permission.
     */
    name: PermissionDto.NameEnum;
    /**
     * Level of permission given to the user.
     */
    level?: PermissionDto.LevelEnum;
}
export namespace PermissionDto {
    export type NameEnum = 'reporting' | 'price-management' | 'dss-management' | 'pms-management';
    export const NameEnum = {
        Reporting: 'reporting' as NameEnum,
        PriceManagement: 'price-management' as NameEnum,
        DssManagement: 'dss-management' as NameEnum,
        PmsManagement: 'pms-management' as NameEnum
    };
    export type LevelEnum = 'read-only' | 'read-write';
    export const LevelEnum = {
        Only: 'read-only' as LevelEnum,
        Write: 'read-write' as LevelEnum
    };
}


