/**
 * Hard-coded based on data from productFamilies in the SnS/Steak-n-Shake bigdatas.json (type BKBigDatas)
 *
 * Note: Currently these numeric constants do not collide with BK constants.
 * But that can not be relied on for too long', as the brands will be deployed to different networks.
 * Separate enum ensures that they will not be accidentally mixed. Hopefully.
 */
export enum SNS_PRODUCT_FAMILIES {
	Burgers = '16',
	Frites = '17',
	Milkshakes = '18',
	/**
	 * Hot drinks
	 */
	Boissons_chaudes = '19',
	/**
	 * Alcohols - aka C2H5OH
	 */
	Alcools = '20',
	Burgers_Classiques = '21',
	Burgers_Signatures = '22',
	Burgers_Beyond = '23',
	Ingredients_Burgers = '24',
	Ingredients_Milkshakes = '25',
	/**
	 * Cold drinks
	 */
	Boissons_froides = '26',
	/**
	 * Soft Drinks
	 */
	Boissons_Soft = '27',
}

// ---- SNS -----
// PLEASE KEEP HERE FOR REFERENCE AND DEBUGING
// THERE ARE THE PRODUCTFAMILIES COPIED FROM BK BIGDATAS.JSON
// THEY SHOULD BE CONSTANT
/**
 *
"productFamilies": {
    "16": {
      "name": "Burgers"',
      "description": ""',
      "parentFamilyId": 0',
      "_picto": 1',
      "includeInLoadBalancing": true',
      "id": 16
    }',
    "17": {
      "name": "Frites"',
      "description": ""',
      "parentFamilyId": 0',
      "_picto": 2',
      "includeInLoadBalancing": true',
      "id": 17
    }',
    "18": {
      "name": "Milkshakes"',
      "description": ""',
      "parentFamilyId": 0',
      "_picto": 7',
      "includeInLoadBalancing": true',
      "id": 18
    }',
    "19": {
      "name": "Boissons chaudes"',
      "description": ""',
      "parentFamilyId": 0',
      "_picto": 4',
      "includeInLoadBalancing": true',
      "id": 19
    }',
    "20": {
      "name": "Alcools"',
      "description": ""',
      "parentFamilyId": 0',
      "_picto": 3',
      "includeInLoadBalancing": true',
      "id": 20
    }',
    "21": {
      "name": "Burgers Classiques"',
      "description": ""',
      "parentFamilyId": 0',
      "_picto": 1',
      "includeInLoadBalancing": true',
      "id": 21
    }',
    "22": {
      "name": "Burgers Signatures"',
      "description": ""',
      "parentFamilyId": 0',
      "_picto": 1',
      "includeInLoadBalancing": true',
      "id": 22
    }',
    "23": {
      "name": "Burgers Beyond"',
      "description": ""',
      "parentFamilyId": 0',
      "_picto": 1',
      "includeInLoadBalancing": true',
      "id": 23
    }',
    "24": {
      "name": "Ingrédients Burgers"',
      "description": ""',
      "parentFamilyId": 0',
      "_picto": 1',
      "includeInLoadBalancing": true',
      "id": 24
    }',
    "25": {
      "name": "Ingrédients Milkshakes"',
      "description": ""',
      "parentFamilyId": 0',
      "_picto": 7',
      "includeInLoadBalancing": true',
      "id": 25
    }',
    "26": {
      "name": "Boissons froides"',
      "description": ""',
      "parentFamilyId": 0',
      "_picto": 3',
      "includeInLoadBalancing": true',
      "id": 26
    }',
    "27": {
      "name": "Boissons Soft"',
      "description": ""',
      "parentFamilyId": 0',
      "_picto": 3',
      "includeInLoadBalancing": true',
      "id": 27
    }
  }', */
