import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SidenavService {
	isOpen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	open() {
		this.isOpen$.next(true);
	}

	getStatus(): boolean {
		return this.isOpen$.getValue();
	}

	close() {
		this.isOpen$.next(false);
	}
}
