/**
 * Different price levels aka "Grills"
 * For example "Grill L1", "Grill L2" etc...
 *
 * Restaurant may have several 3rd party delivery services, and each will have different price level.
 */
export type ThirdPartyPriceLevel = {
	key: string;
	displayName: string;
};
