/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ConfigurationFiscalPaymentMethod } from './configuration-fiscal-payment-method';
import { ConfigurationVatRate } from './configuration-vat-rate';
import { FiscalProvider } from './fiscal-provider';


export interface ConfigurationRestaurantFiscal { 
    provider: FiscalProvider;
    tpeIdMerchant?: string;
    neptingUrl?: string;
    fiscalYearStartDate?: string;
    closurePaymentMethod?: number;
    paymentMethods?: Array<ConfigurationFiscalPaymentMethod>;
    vatRates: Array<ConfigurationVatRate>;
}

