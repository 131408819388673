/**
 * Filesystem delegate, allow us to rely on an abstract file system, witch can vary implementation on browser or server.
 */
export interface IShar3dFSDelegate {
	/**
	 * Converts fs path to web path
	 */
	toURI(localfspath: string): Promise<string>;

	/**
	 * Converts a web path to FS path
	 */
	fromURI(webpath: string): Promise<string>;

	/**
	 * Create a temp directory and returns its FS path
	 */
	createTempDirectory(prefix: string): Promise<string>;

	/**
	 * Moves the source directory to the basedir directory
	 */
	moveDirectory(source: string, destination: string): Promise<void>;

	/**
	 * Remove recursively a directory
	 */
	deleteDirectory(dir: string): Promise<void>;

	/**
	 * Check file or directory existance
	 */
	exists(file: string): Promise<boolean>;

	/**
	 * Delete a single file
	 */
	delete(file: string): Promise<boolean>;

	/**
	 * Compute file bytelength
	 */
	length(file: string): Promise<number>;

	/**
	 * Join multiple paths together (FS)
	 */
	join(...paths: string[]): string;

	/**
	 * Get parent directory or null if root
	 */
	getParentFile(file: string): Promise<string | null>;

	/**
	 * Create directory recurcively
	 */
	mkdir(file: string): Promise<void>;

	/**
	 * Read file and returns its string content (use system default encoding)
	 */
	readFileAsString(file: string): Promise<string | null>;

	/**
	 * Read file and returns its content
	 */
	readFileAsBase64(file: string): Promise<string>;

	/**
	 * Copy a file from source to destination.
	 *
	 * Source and Destination may be nested files.
	 */
	copyFile(source: string, destination: string): Promise<void>;

	/**
	 * Write binary or text content to file
	 */
	writeFile(content: string | ArrayBuffer, file: string): Promise<void>;

	/**
	 * Reads and compute file's content SHA-1 Hash.
	 */
	computeSHA1(file: string): Promise<string>;

	/**
	 * Atomic writefile with sha1 computation, in case you can come up with an optimized algorithm.
	 */
	writeFileSHA1(content: string | ArrayBuffer, file: string): Promise<string>;

	/**
	 * Deflate an existing zip file content into destination dir.
	 */
	deflateZip(zipFile: string, targetDir: string): Promise<void>;

	listFiles(dir: string): Promise<IShar3dFSDelegateEntry[]>;
}

export interface IShar3dFSDelegateEntry {
	name: string; // basename of file or directory
	dir: boolean; // true if the entry is a directory
}

export class FSDelegateLogDecorator {
	static wrap(delegate: IShar3dFSDelegate): IShar3dFSDelegate {
		const methods = ['writeFile', 'copyFile', 'writeFileSHA1', 'downloadToFile', 'deflateZip', 'mkdir'];

		for (const method of methods) {
			(delegate as any)[method] = function () {
				console.log(method, arguments);
				(delegate as any)[method].apply(delegate, arguments);
			};
		}

		return delegate;
	}
}
