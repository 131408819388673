import { Injectable } from '@angular/core';
import {
	AppConfigurationGlobal,
	AVAILABLE_JSON,
	CONFIGURATION_DOWNLOAD_TYPES,
	GlobalAppsConfiguration,
	IBKBigData,
	IRestoConfig,
	IRestoSettings,
} from '@libs/shared/models';
import {
	AvailableJsonService,
	BigDataService,
	DashService,
	GlobalConfigurationService,
	RestoConfigService,
	RestoSettingsService,
} from '@libs/shared/services';
import { generateHashCode, readFromLocalStorage } from '@libs/shared/utils';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import {
	LoadAvailability,
	LoadAvailabilitySuccess,
	LoadBigDatas,
	LoadBigDatasSuccess,
	LoadGlobalAppsCredentials,
	LoadGlobalAppsCredentialsSuccess,
	LoadGlobalConfiguration,
	LoadGlobalConfigurationSuccess,
	LoadRestoConfigSuccess,
	LoadRestoSettings,
	LoadRestoSettingsSuccess,
	SetLoadingSuccess,
} from './actions';

@Injectable()
export class ConfigurationEffects {
	hashCodesOfData: Record<CONFIGURATION_DOWNLOAD_TYPES, number>;

	constructor(
		private actions$: Actions,
		private bigDataService: BigDataService,
		private dashService: DashService,
		private availableJsonService: AvailableJsonService,
		private restoSettingsService: RestoSettingsService,
		private restoConfigService: RestoConfigService,
		private globalConfigurationService: GlobalConfigurationService
	) {
		this.hashCodesOfData = {
			[CONFIGURATION_DOWNLOAD_TYPES.RESTO_CONFIG]: generateHashCode(readFromLocalStorage(CONFIGURATION_DOWNLOAD_TYPES.RESTO_CONFIG)),
			[CONFIGURATION_DOWNLOAD_TYPES.BIG_DATA]: generateHashCode(readFromLocalStorage(CONFIGURATION_DOWNLOAD_TYPES.BIG_DATA)),
			[CONFIGURATION_DOWNLOAD_TYPES.RESTO_SETTINGS]: generateHashCode(readFromLocalStorage(CONFIGURATION_DOWNLOAD_TYPES.RESTO_SETTINGS)),
			[CONFIGURATION_DOWNLOAD_TYPES.AVAILABILITY]: generateHashCode(readFromLocalStorage(CONFIGURATION_DOWNLOAD_TYPES.AVAILABILITY)),
		};
	}

	loadDkiApiCredentials$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LoadGlobalAppsCredentials),
			switchMap((action) =>
				this.dashService.getDkiConfiguration().pipe(
					map((data: GlobalAppsConfiguration) => {
						return LoadGlobalAppsCredentialsSuccess({
							data: {
								dkiBe: {
									accessToken: data.accessToken,
									restoUuid: data.restoUuid,
									dashUrl: data.dashUrl,
								},
							},
						});
					})
				)
			)
		)
	);
	loadBigDatas$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LoadBigDatas),
			switchMap((action) =>
				this.bigDataService.download().pipe(
					map((data: IBKBigData) => {
						const hash = generateHashCode(data);
						if (this.hashCodesOfData[CONFIGURATION_DOWNLOAD_TYPES.BIG_DATA] !== hash && data !== null) {
							this.hashCodesOfData[CONFIGURATION_DOWNLOAD_TYPES.BIG_DATA] = hash;
							return LoadBigDatasSuccess({ downloadType: CONFIGURATION_DOWNLOAD_TYPES.BIG_DATA, data: data });
						} else {
							return SetLoadingSuccess({ downloadType: CONFIGURATION_DOWNLOAD_TYPES.BIG_DATA });
						}
					})
				)
			)
		)
	);

	loadAvailability$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LoadAvailability),
			switchMap((action) =>
				this.availableJsonService.download().pipe(
					map((data: AVAILABLE_JSON) => {
						if (data !== null) {
							return LoadAvailabilitySuccess({ downloadType: CONFIGURATION_DOWNLOAD_TYPES.AVAILABILITY, data: data });
						} else {
							return SetLoadingSuccess({ downloadType: CONFIGURATION_DOWNLOAD_TYPES.AVAILABILITY });
						}
					})
				)
			)
		)
	);

	loadRestoSettings$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LoadRestoSettings),
			switchMap((action) =>
				this.restoSettingsService.download().pipe(
					map((data: IRestoSettings) => {
						const hash = generateHashCode(data);
						if (this.hashCodesOfData[CONFIGURATION_DOWNLOAD_TYPES.RESTO_SETTINGS] !== hash && data !== null) {
							this.hashCodesOfData[CONFIGURATION_DOWNLOAD_TYPES.RESTO_SETTINGS] = hash;
							return LoadRestoSettingsSuccess({ downloadType: CONFIGURATION_DOWNLOAD_TYPES.RESTO_SETTINGS, data: data });
						} else {
							return SetLoadingSuccess({ downloadType: CONFIGURATION_DOWNLOAD_TYPES.RESTO_SETTINGS });
						}
					})
				)
			)
		)
	);

	loadRestoConfig$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LoadRestoSettings),
			switchMap((action) =>
				this.restoConfigService.download().pipe(
					map((data: IRestoConfig) => {
						const hash = generateHashCode(data);
						if (this.hashCodesOfData[CONFIGURATION_DOWNLOAD_TYPES.RESTO_CONFIG] !== hash && data !== null) {
							this.hashCodesOfData[CONFIGURATION_DOWNLOAD_TYPES.RESTO_CONFIG] = hash;
							return LoadRestoConfigSuccess({ downloadType: CONFIGURATION_DOWNLOAD_TYPES.RESTO_CONFIG, data: data });
						} else {
							return SetLoadingSuccess({ downloadType: CONFIGURATION_DOWNLOAD_TYPES.RESTO_CONFIG });
						}
					})
				)
			)
		)
	);

	loadGlobalConfig$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LoadGlobalConfiguration),
			switchMap((action) =>
				this.globalConfigurationService.getGlobalConfiguration().pipe(
					map((data: AppConfigurationGlobal) => {
						return LoadGlobalConfigurationSuccess({ data: data });
					})
				)
			)
		)
	);
}
