export enum ItemToSellType {
	/**
	 * Default value. Ideally you should not encounter it.
	 */
	Unknown = 'UNKNOWN',
	Product = 'PRODUCT',
	Menu = 'MENU'
}


export type ItemToSell = {
	id: number,
	price: number,
	type: ItemToSellType
}
