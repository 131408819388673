/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CashParametersDto { 
    /**
     * Initial cash funds for every cash machine (i.e. the amount of money the cashier starts with). Set in BK Manager -> \"Mode de fonctionnement\" -> \"Paramètres fonds de caisse\".
     */
    initialCashFund: number;
    /**
     * Amount of cash funds that should not be exceeded in the cash machine. If this happens, someone from back office should come to withdraw the cash and store it in a safe. Set in BK Manager -> \"Mode de fonctionnement\" -> \"Paramètres fonds de caisse\".
     */
    alertWidthdraw: number;
    /**
     * Indicates how many cash machines (and initial funds) are available to employees. Set in BK Manager -> \"Mode de fonctionnement\" -> \"Paramètres fonds de caisse\".
     */
    cashFoundNumber: number;
}

