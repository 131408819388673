import { ModuleWithProviders, NgModule } from '@angular/core';
import { IShar3dWindowModuleConfiguration } from './interfaces';
import { InactivityService, RingConnectorService, Shar3dDesktopBaseService } from './services';

@NgModule({
	imports: [],
	declarations: [],
	exports: [],
})
export class Shar3dDesktopModule {
	static forRoot(sharedConfiguration?: IShar3dWindowModuleConfiguration): ModuleWithProviders<Shar3dDesktopModule> {
		return {
			ngModule: Shar3dDesktopModule,
			providers: [
				{
					provide: Shar3dDesktopBaseService,
					useFactory: (ringConnectorService: RingConnectorService, inactivityService: InactivityService) =>
						new Shar3dDesktopBaseService(ringConnectorService, inactivityService, sharedConfiguration),
					deps: [RingConnectorService, InactivityService],
				},
				RingConnectorService,
				InactivityService,
			],
		};
	}
}
