import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import * as api from '@dki/api-client';

import { ChannelOrders } from '../../../core/entity/interfaces';
import { TableDataSourceName } from '../entity/enumeration';

@Component({
	selector: 'dk-chart-suplement-table',
	templateUrl: './chart-suplement-table.component.html',
	styleUrls: ['./chart-suplement-table.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartSuplementTableComponent {
	@Input() currentLanguage: string;
	@Input() tableDataSource:
		| ChannelOrders[]
		| api.OrdersByTime[]
		| api.ProductOrders[]
		| api.ChannelOrdersWithAverages[]
		| api.SosDriveByTime[];

	// TO DO: PROVIDE INTERFACE
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	@Input() tableConfig: any;
	@Input() tableRowLabels: { [key: string]: string };

	composeTableHeader(): string[] {
		const headerCellLabels = [];

		// TEMPORARY SOLUTION UNTILL BECKEND PROVIDES DATA IN DESIRED STRUCTURE
		// TO DO: FORCE BECKEND TO PROVIDE DATA IN SUCH FORMAT THAT NO REMAPING IS REQUIRED
		if (this.tableDataSource && this.tableDataSource.length > 0) {
			Object.keys(this.tableDataSource[0]).forEach((tableCellDataKey) => {
				let headerCellLabel = tableCellDataKey.replace(/_/g, ' ').toLowerCase();

				if (
					tableCellDataKey !== 'mean_count_global' &&
					tableCellDataKey !== 'trend_global' &&
					tableCellDataKey !== 'mean_value_whole_day' &&
					tableCellDataKey !== 'mean_value' &&
					tableCellDataKey !== 'value'
				) {
					if (this.tableConfig.dataSourceName === TableDataSourceName.TOP_TEN_PRODUCTS) {
						headerCellLabel = headerCellLabel.replace('product', 'best-sales');
						headerCellLabel = headerCellLabel.replace('mean count whole day', 'all-day-average');
						headerCellLabel = headerCellLabel.replace('mean count', 'average');
					}

					headerCellLabels.push(headerCellLabel);
				}
			});
		}

		// if (this.tableConfig.rowClickAction) {
		// 	headerCellLabels.push('');
		// }

		return headerCellLabels;
	}

	composeTableBody(): string[][] {
		const bodyRows = [];

		// TEMPORARY SOLUTION UNTILL BECKEND PROVIDES DATA IN DESIRED STRUCTURE
		// TO DO: FORCE BECKEND TO PROVIDE DATA IN SUCH FORMAT THAT NO REMAPING IS REQUIRED
		this.tableDataSource.forEach((tableDataSourceEntry) => {
			const row = [];
			let cellValue: string | number;

			Object.keys(tableDataSourceEntry).forEach((tableCellDataKey) => {
				if (
					tableCellDataKey !== 'mean_count_global' &&
					tableCellDataKey !== 'trend_global' &&
					tableCellDataKey !== 'mean_value_whole_day' &&
					tableCellDataKey !== 'mean_value' &&
					tableCellDataKey !== 'value'
				) {
					cellValue = tableDataSourceEntry[tableCellDataKey];

					if (typeof cellValue === 'number' && !Number.isInteger(cellValue)) {
						cellValue = cellValue.toFixed(2);
					}

					row.push(cellValue);
				}
			});

			bodyRows.push(row);
		});

		return bodyRows;
	}

	translateRowLabel(cellValue: string | number): string | number {
		return this.tableRowLabels && this.tableRowLabels[cellValue] ? this.tableRowLabels[cellValue] : cellValue;
	}
}
