import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IBKPublishedOrderDataCassandra } from '@bk/jscommondatas';
import { LiveApiClient } from '@libs/shared-codebase/api/clerkmaster';
import { keys, values } from 'ramda';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { catchError, filter, map, takeUntil } from 'rxjs/operators';
import { CLERK_MASTER, ConfigurationUrlsType, URL_TYPE_ENUM } from '../models';
import { LiveBasketDto } from '../models/clerkmaster-service.dto';
import { ConfigurationService } from './configuration.service';

const LIVE_BASKET_ORDERS_URL = '/live-basket';
const LIVE_BASKET_ALL_URL = '/live/all';

@Injectable({ providedIn: 'root' })
export class ClerkMasterService implements OnDestroy {
	private url: ConfigurationUrlsType = { clerkMasterUrl: '', clerkUrl: '' };
	private readonly ngUnsubscribe$: Subject<void> = new Subject<void>();

	constructor(
		private readonly httpClient: HttpClient,
		private readonly configurationService: ConfigurationService<any>,
		private readonly route: ActivatedRoute,
		private readonly liveApiClient: LiveApiClient
	) {
		const configValues$ = this.configurationService.config$;
		const queryParams = this.route.queryParams;

		combineLatest([configValues$, queryParams])
			.pipe(
				takeUntil(this.ngUnsubscribe$),
				filter(([configData, queryParams]) => configData?.['API']?.[CLERK_MASTER] || queryParams?.clerkMaster)
			)
			.subscribe(([configData, queryParams]) => {
				const clerkUrl = queryParams?.clerkMaster ? queryParams.clerkMaster : '';
				this.url = {
					clerkMasterUrl: configData?.['API']?.[CLERK_MASTER]?.url,
					clerkUrl: clerkUrl,
				};

				if (this.getUrlPrecedency) {
					console.log(`Clerk master service: Clerk master url updated to: ${values(this.getUrlPrecedency)?.[0]}`);
				}
			});
	}

	/**
	 * Precedence of big data URL is:
	 * Take it from clerk query parameter
	 * Take it from clerkmaster url
	 */
	get getUrlPrecedency(): Record<string, string> | undefined {
		if (this.url.clerkMasterBoUrl) {
			return { [URL_TYPE_ENUM.CLERK_URL]: this.url.clerkUrl };
		} else if (this.url.clerkMasterUrl) {
			return { [URL_TYPE_ENUM.CLERK_MASTER_URL]: this.url.clerkMasterUrl };
		}

		return undefined;
	}

	loadLiveOrders(): Observable<LiveBasketDto> {
		const url = values(this.getUrlPrecedency)?.[0];
		const queryType = keys(this.getUrlPrecedency)?.[0];
		const dataEndpoint =
			queryType === URL_TYPE_ENUM.CLERK_MASTER_URL
				? this.liveApiClient.getUnfinishedOrders()
				: this.httpClient.get(url + LIVE_BASKET_ORDERS_URL);

		return dataEndpoint.pipe(
			map((data: LiveBasketDto) => data),
			catchError((err) => {
				console.error('[ERROR] downloading live basket orders.', err);
				return of(null);
			})
		);
	}

	loadCurrentOrders(): Observable<IBKPublishedOrderDataCassandra[]> {
		const url = values(this.getUrlPrecedency)?.[0];
		const dataEndpoint = this.httpClient.get(url + LIVE_BASKET_ALL_URL);

		return dataEndpoint.pipe(
			map((data: Record<'orders', any>) => data.orders.map((data) => data?.order?.mdmorder).filter((item) => !!item)),
			catchError((err) => {
				console.error('[ERROR] downloading live all orders.', err);
				return of(null);
			})
		);
	}

	ngOnDestroy() {
		this.ngUnsubscribe$.next();
		this.ngUnsubscribe$.complete();
	}
}
