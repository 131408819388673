/**
 * DKI-BE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DetailProductsForecast } from './detail-products-forecast';
import { WeatherDailyStatus } from './weather-daily-status';


export interface BusinessDayProductsForecast {
	/**
	 * Day Weather Prediction
	 */
	daily_weather_forecast: WeatherDailyStatus;
	/**
	 * Detail Product Forecast
	 */
	detail: Array<DetailProductsForecast>;
}

