
export enum CompanyNameIdentification {
	BurgerKing_France = 'bk_fr',
	BurgerKing_Reunion = 'bk_re',
	BeChef_France = 'bc_fr',
	Quick = 'quick',
	Cora_France = 'co_fr',
	PokeMama_France = 'pm_fr',
	Default = 'Default',
	Mqsr = 'mqsr',
	CapMerchant_Reunion = 'cm_re',
}
