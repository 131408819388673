import { Injectable } from '@angular/core';
import {
	BKMediaTargetFamilyEnum,
	IBKMediaData,
	IBKMenuBase,
	IBKProductBase,
	IBKSelectionPattern,
	ItemToSell,
	ItemToSellType,
} from '@bk/jscommondatas';
import {
	AppConfigurationGlobal,
	AvailabilityMap,
	AVAILABLE_JSON,
	CONFIGURATION_DOWNLOAD_TYPES,
	GlobalAppsConfiguration,
	IBKBigData,
	IRestoConfig,
	KioskConfigurationData,
	NavScreenContent,
	NavScreenContentOptions,
	RestoSettingsModel,
} from '@libs/shared/models';

import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IRootState } from '../interfaces';
import {
	InitData,
	LoadAvailability,
	LoadBigDatas,
	LoadGlobalAppsCredentials,
	LoadGlobalConfiguration,
	LoadRestoConfig,
	LoadRestoSettings,
} from './actions';
import {
	getData,
	getGlobalAppsCredentialsSelector,
	getGlobalConfigurationData,
	GetItemByIdProps,
	getItemByIdSelectorFactory,
	getKioskNavScreenConfiguration,
	GetMediasByMediaTargetProps,
	getMediasByTargetSelectorFactory,
	getMediasForDisabledKioskFactory,
	getMediasFromPlaylistForKioskFactory,
	getNavigationScreenContent,
	GetNavigationScreenContentProps,
	GetProductByIdProps,
	getProductByIdSelectorFactory,
	getProductsAvailabilityMapSelectorFactory,
	getProductsOrMenusListParams,
	getProductsOrMenusSelectorFactory,
	getRestoSettings,
	isApiLoading,
	isGlobalConfigurationLoading,
	isLoading,
	isMultiPaymentEnabled,
	isProductActive,
	isProductActiveAndAvailable,
	isProductAndIngredientsAvailable,
} from './selectors';

@Injectable()
export class ConfigurationFacade {
	private readonly ngUnsubscribe$: Subject<void> = new Subject<void>();

	getGlobalConfiguration$: Observable<AppConfigurationGlobal> = this.store.pipe(
		select(getGlobalConfigurationData),
		takeUntil(this.ngUnsubscribe$)
	);

	constructor(private readonly store: Store<IRootState>) {
		this.store.dispatch(InitData());
	}

	getBigData(): Observable<IBKBigData> {
		return this.store.pipe(select(getData, CONFIGURATION_DOWNLOAD_TYPES.BIG_DATA), takeUntil(this.ngUnsubscribe$));
	}

	getBigDataProductById(id: number): Observable<IBKProductBase> {
		const props: GetProductByIdProps = {
			downloadType: CONFIGURATION_DOWNLOAD_TYPES.BIG_DATA,
			id,
		};

		return this.store.pipe(select(getProductByIdSelectorFactory(props)), takeUntil(this.ngUnsubscribe$));
	}

	getBigDataProductByIds(items: ItemToSell[]): Observable<(IBKProductBase | IBKMenuBase)[]> {
		const params: getProductsOrMenusListParams = {
			downloadType: CONFIGURATION_DOWNLOAD_TYPES.BIG_DATA,
			items,
		};

		return this.store.pipe(select(getProductsOrMenusSelectorFactory(params)), takeUntil(this.ngUnsubscribe$));
	}

	getBigDataItemById(id: number, isMenu: boolean): Observable<IBKProductBase | IBKMenuBase> {
		const props: GetItemByIdProps = {
			downloadType: CONFIGURATION_DOWNLOAD_TYPES.BIG_DATA,
			id,
			itemType: isMenu ? ItemToSellType.Menu : ItemToSellType.Product,
		};

		return this.store.pipe(select(getItemByIdSelectorFactory(props)), takeUntil(this.ngUnsubscribe$));
	}

	getAvailability(): Observable<AVAILABLE_JSON> {
		return this.store.pipe(select(getData, CONFIGURATION_DOWNLOAD_TYPES.AVAILABILITY), takeUntil(this.ngUnsubscribe$));
	}

	getProductsAvailabilityMap(items: ItemToSell[]): Observable<AvailabilityMap> {
		return this.store.pipe(select(getProductsAvailabilityMapSelectorFactory({ items })), takeUntil(this.ngUnsubscribe$));
	}

	getBigDataMediasByTarget(mediaTargetFamily: BKMediaTargetFamilyEnum): Observable<IBKMediaData[]> {
		const params: GetMediasByMediaTargetProps = {
			downloadType: CONFIGURATION_DOWNLOAD_TYPES.BIG_DATA,
			mediaTargetFamily: mediaTargetFamily,
		};

		return this.store.pipe(select(getMediasByTargetSelectorFactory(params)), takeUntil(this.ngUnsubscribe$));
	}

	/** Medias to be shown on unattended screen when kiosk not used */
	getMediasFromPlaylistForKiosk$(): Observable<IBKMediaData[]> {
		return this.store.pipe(select(getMediasFromPlaylistForKioskFactory()), takeUntil(this.ngUnsubscribe$));
	}

	getMediasForDisabledKiosk$(): Observable<IBKMediaData[]> {
		return this.store.pipe(select(getMediasForDisabledKioskFactory()), takeUntil(this.ngUnsubscribe$));
	}

	getRestoConfig(): Observable<IRestoConfig> {
		return this.store.pipe(select(getData, CONFIGURATION_DOWNLOAD_TYPES.RESTO_CONFIG), takeUntil(this.ngUnsubscribe$));
	}

	loading(downloadType: CONFIGURATION_DOWNLOAD_TYPES): Observable<boolean> {
		return this.store.pipe(select(isLoading, downloadType), takeUntil(this.ngUnsubscribe$));
	}

	isAPILoading(): Observable<boolean> {
		return this.store.pipe(select(isApiLoading), takeUntil(this.ngUnsubscribe$));
	}

	download(downloadType: CONFIGURATION_DOWNLOAD_TYPES): void {
		switch (downloadType) {
			case CONFIGURATION_DOWNLOAD_TYPES.BIG_DATA:
				this.store.dispatch(LoadBigDatas({ downloadType }));
				return;
			case CONFIGURATION_DOWNLOAD_TYPES.AVAILABILITY:
				this.store.dispatch(LoadAvailability({ downloadType }));
				return;
			case CONFIGURATION_DOWNLOAD_TYPES.RESTO_SETTINGS:
				this.store.dispatch(LoadRestoSettings({ downloadType }));
				return;
			case CONFIGURATION_DOWNLOAD_TYPES.RESTO_CONFIG:
				this.store.dispatch(LoadRestoConfig({ downloadType }));
				return;
			default:
				console.warn(`ConfigurationFacade() Not implemented for type ... ${downloadType}`);
		}
	}

	loadDkiCredentials(): void {
		this.store.dispatch(LoadGlobalAppsCredentials());
	}

	getGlobalAppsCredentials(): Observable<Partial<GlobalAppsConfiguration>> {
		return this.store.pipe(select(getGlobalAppsCredentialsSelector()), takeUntil(this.ngUnsubscribe$));
	}

	isProductAndIngredientsAvailable(id: number): Observable<boolean> {
		return this.store.pipe(select(isProductAndIngredientsAvailable, id), takeUntil(this.ngUnsubscribe$));
	}

	isProductAndIngredientsAvailableAndActive(id: number): Observable<boolean> {
		return this.store.pipe(select(isProductActiveAndAvailable, id), takeUntil(this.ngUnsubscribe$));
	}

	isProductActive(id: number): Observable<boolean> {
		return this.store.pipe(select(isProductActive, id), takeUntil(this.ngUnsubscribe$));
	}

	getRestoSettings(): Observable<RestoSettingsModel> {
		return this.store.pipe(select(getRestoSettings), takeUntil(this.ngUnsubscribe$));
	}

	isMultiPaymentEnabled(): Observable<boolean> {
		return this.store.pipe(select(isMultiPaymentEnabled), takeUntil(this.ngUnsubscribe$));
	}

	getKioskNavScreenConfiguration(configNumber?: number): Observable<KioskConfigurationData> {
		return this.store.pipe(select(getKioskNavScreenConfiguration(configNumber)), takeUntil(this.ngUnsubscribe$));
	}

	getNavigationScreenContent(
		selectionPattern: IBKSelectionPattern[],
		navScreenContentOptions: NavScreenContentOptions = undefined
	): Observable<NavScreenContent> {
		const props: GetNavigationScreenContentProps = {
			selectionPattern: selectionPattern,
			navScreenContentOptions: navScreenContentOptions,
		};
		return this.store.pipe(select(getNavigationScreenContent, props), takeUntil(this.ngUnsubscribe$));
	}

	loadGlobalConfiguration(): void {
		this.store.dispatch(LoadGlobalConfiguration());
	}

	isGlobalConfigurationLoading(): Observable<boolean> {
		return this.store.pipe(select(isGlobalConfigurationLoading), takeUntil(this.ngUnsubscribe$));
	}

	unsubscribe(): void {
		this.ngUnsubscribe$.next();
		this.ngUnsubscribe$.complete();
	}
}
