import {
	BKDeliveryModeEnum,
	BKOrderEventUtilities,
	BKOrderSourceEnum,
	IBKOrderEventData,
	ICsiExport_elocalisation
} from "@bk/jscommondatas";

export function isOrderDeliveryLocal(deliveryMode: BKDeliveryModeEnum): boolean {
	return deliveryMode === BKDeliveryModeEnum.DELIVERY_LOCAL;
}

export function isOrderDeliveryTakeAway(deliveryMode: BKDeliveryModeEnum): boolean {
	return deliveryMode === BKDeliveryModeEnum.DELIVERY_TAKE_OVER;
}

export function isPAXDrive(events: IBKOrderEventData[], localisation: string): boolean {
	const isPaxOrder = BKOrderEventUtilities.isPaxOrder(events);
	return isPaxOrder && localisation === ICsiExport_elocalisation.DRIVE_THROUGH;
}

export function isClickAndCollectDrive(source: BKOrderSourceEnum, localisation: string): boolean {
	return source === BKOrderSourceEnum.ORDER_SOURCE_CLICK_AND_COLLECT && localisation === ICsiExport_elocalisation.DRIVE_THROUGH;
}

export function isDrive(source: BKOrderSourceEnum, localisation: string): boolean {
	return source === BKOrderSourceEnum.ORDER_SOURCE_DRIVE && localisation === ICsiExport_elocalisation.DRIVE_THROUGH
}

export function isThirdPartyDelivery(source: BKOrderSourceEnum): boolean {
	return [BKOrderSourceEnum.ORDER_SOURCE_THIRD_PARTY_DELIVERY, BKOrderSourceEnum.ORDER_SOURCE_HOME_DELIVERY].includes(source);
}

export function isWalk(source: BKOrderSourceEnum): boolean {
	return source === BKOrderSourceEnum.ORDER_SOURCE_WALK;
}

export function isParkingOrder(events: IBKOrderEventData[]): boolean {
	return !!BKOrderEventUtilities.getPickUpTypeParkingDetails(events);
}