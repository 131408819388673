import { isEmptyObject } from '@merim/utils';

import { OrbpRole } from '../../enums';
import { OrbpDetail } from '../../types/orbp-detail';
import { getForbiddenDevicesForAllSalesChannels } from '../device-assignment';
import { getMergedSalesChannels, getSalesChannelsTurnedOn, isSalesChannelOn } from '../sales-channels/index';
import { hasMatchingMasters } from './has-matching-masters';
import { hasSameSalesChannelOptions } from './has-same-sales-channel-options';
import { hasSubsetOfSalesChannelOptions } from './has-subset-of-sales-channel-options';

export function hasValidSalesChannels(orbpDevice: OrbpDetail, masterOrbpDevices: OrbpDetail[] = []): boolean {
	const isNotEmpty = doesNotHaveEmptySalesChannelOptions(orbpDevice);
	if (!isNotEmpty) {
		return false;
	}

	switch (orbpDevice.role) {
		case OrbpRole.Master:
			return hasAtLeastOneSalesChannelTurnedOn(orbpDevice);

		// Can have multiple Masters
		case OrbpRole.Dependent:
			return (
				!!masterOrbpDevices &&
				hasSubsetOfSalesChannels(orbpDevice, masterOrbpDevices) &&
				hasMatchingMasters(orbpDevice, masterOrbpDevices) &&
				hasSubsetOfSalesChannelOptionsAsMasters(orbpDevice, masterOrbpDevices) &&
				hasValidAssignedDevices(orbpDevice, masterOrbpDevices)
			);

		// Has only single Master
		case OrbpRole.Mirror: {
			const masterOrbpDevice = masterOrbpDevices[0];
			return (
				!!masterOrbpDevice &&
				hasSameSalesChannelsTurnedOn(orbpDevice, masterOrbpDevice) &&
				hasMatchingMasters(orbpDevice, masterOrbpDevices) &&
				hasTheSameSalesChannelOptions(orbpDevice, masterOrbpDevice)
			);
		}

		default:
			throw new Error(`Unexpected OrbpRole in hasValidSalesChannels() - ${orbpDevice.role}`);
	}
}

// Empty object "{}" in SalesChannelOptions are not allowed - it should have all properties filled with 'false'
function doesNotHaveEmptySalesChannelOptions(orbpDevice: OrbpDetail): boolean {
	return !!orbpDevice.salesChannelConfigurations && orbpDevice.salesChannelConfigurations.every((scc) => !isEmptyObject(scc.configuration));
}

function hasAtLeastOneSalesChannelTurnedOn(orbpDevice: OrbpDetail): boolean {
	const channels = orbpDevice.salesChannelConfigurations;
	if (!channels) {
		return false;
	}

	const atLeastOneOn = channels.some((salesChannel) => isSalesChannelOn(salesChannel));
	return atLeastOneOn;
}

function hasSubsetOfSalesChannels(orbpDevice: OrbpDetail, masterOrbpDevices: OrbpDetail[]): boolean {
	const salesChannelConfigurations = getMergedSalesChannels(masterOrbpDevices);

	// Empty SalesChannels configuration is not considered valid
	if (!orbpDevice.salesChannelConfigurations || !salesChannelConfigurations || orbpDevice.salesChannelConfigurations.length === 0 || salesChannelConfigurations.length === 0) {
		return false;
	}

	const deviceChannelsNames = getSalesChannelsTurnedOn(orbpDevice.salesChannelConfigurations).map((x) => x.salesChannel);
	const masterChannelsNames = getSalesChannelsTurnedOn(salesChannelConfigurations).map((x) => x.salesChannel);

	const isSubsetOfMaster = deviceChannelsNames.every((x) => masterChannelsNames.includes(x));
	return isSubsetOfMaster;
}

function hasSameSalesChannelsTurnedOn(orbpDevice: OrbpDetail, masterOrbpDevice: OrbpDetail): boolean {
	// Empty SalesChannels configuration is not considered valid
	if (
		!orbpDevice.salesChannelConfigurations ||
		!masterOrbpDevice.salesChannelConfigurations ||
		orbpDevice.salesChannelConfigurations.length === 0 ||
		masterOrbpDevice.salesChannelConfigurations.length === 0
	) {
		return false;
	}

	const deviceChannels = getSalesChannelsTurnedOn(orbpDevice.salesChannelConfigurations);
	const masterChannels = getSalesChannelsTurnedOn(masterOrbpDevice.salesChannelConfigurations);

	const deviceChannelNames = deviceChannels
		.map((x) => x.salesChannel)
		.sort()
		.join();
	const masterChannelName = masterChannels
		.map((x) => x.salesChannel)
		.sort()
		.join();

	return deviceChannelNames === masterChannelName;
}

function hasTheSameSalesChannelOptions(orbpDevice: OrbpDetail, masterOrbpDevice: OrbpDetail): boolean {
	if (!orbpDevice.salesChannelConfigurations || !masterOrbpDevice.salesChannelConfigurations) {
		return false;
	}

	return orbpDevice.salesChannelConfigurations.every((c) => {
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		const masterConfig = masterOrbpDevice.salesChannelConfigurations!.find((x) => x.salesChannel === c.salesChannel);
		if (!masterConfig) {
			return false;
		}
		const isMatching = hasSameSalesChannelOptions(c, masterConfig);
		return isMatching;
	});
}

function hasSubsetOfSalesChannelOptionsAsMasters(orbpDevice: OrbpDetail, masterOrbpDevices: OrbpDetail[]): boolean {
	const salesChannelConfigurations = getMergedSalesChannels(masterOrbpDevices);

	if (!orbpDevice.salesChannelConfigurations || !salesChannelConfigurations) {
		return false;
	}

	return orbpDevice.salesChannelConfigurations.every((c) => {
		const masterConfig = salesChannelConfigurations.find((x) => x.salesChannel === c.salesChannel);
		if (!masterConfig) {
			return false;
		}
		const isMatching = hasSubsetOfSalesChannelOptions(c, masterConfig);
		return isMatching;
	});
}

function hasValidAssignedDevices(orbpDevice: OrbpDetail, masterOrbpDevices: OrbpDetail[]): boolean {
	const forbiddenDevices: string[] = getForbiddenDevicesForAllSalesChannels(orbpDevice, masterOrbpDevices);

	return forbiddenDevices.length === 0;
}
