/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IntervalFilterDefinitionDto } from './interval-filter-definition-dto';


export interface PmsConfigurationDto { 
    /**
     * Size of step between levels of PeakIntensity.
     */
    peakIntensityStep: number;
    /**
     * Prediction Interval in miliseconds... 900000 means 15 minutes.
     */
    intervalMs: number;
    /**
     * Definition of filter buttons for Prediction table
     */
    intervalFilterDefinition?: Array<IntervalFilterDefinitionDto>;
    /**
     * Configured time (HH:MM) of the bussiness day start.
     */
    bussinessDayStartTime: string;
    /**
     * Start of the week. Monday is 0
     */
    weekStartDay: number;
}

