import { PERMISSION_OPERATIONS, PERMISSIONS } from '../constants';

export const getPermissionValues = (resource: PERMISSION_OPERATIONS): typeof PERMISSIONS => {
	switch (resource) {
		case PERMISSION_OPERATIONS.PRICE_MANAGEMENT:
		case PERMISSION_OPERATIONS.REPORTING:
		case PERMISSION_OPERATIONS.RESTAURANT_MANAGEMENT:
		case PERMISSION_OPERATIONS.USER_MANAGEMENT:
		case PERMISSION_OPERATIONS.MONITORING:
		case PERMISSION_OPERATIONS.SW_MANAGEMENT:
		case PERMISSION_OPERATIONS.DATA_UPDATE_MANAGEMENT:
		case PERMISSION_OPERATIONS.MEDIA_MANAGEMENT:
			return PERMISSIONS;
		default:
			return null;
	}
}
