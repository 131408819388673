/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PaymentMethodMetadataConfiguration } from './payment-method-metadata-configuration';


export interface BrandConfigurationPaymentMethods { 
    /**
     * Toggle for module in GAP.
     */
    enabled?: boolean;
    /**
     * Flag indicating is every restaurant must have payment method/s configured.
     */
    require_payment_methods?: boolean;
    /**
     * Configuration of payment methods metadata.
     */
    metadata?: Array<PaymentMethodMetadataConfiguration>;
}

