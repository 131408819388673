/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ConfigurationRestaurantOrders { 
    /**
     * Icons to be printed on tickets per localization type. Keys should equal to values of **ICsiExport_elocalisation** enum, values should be URLs to images in PNG format.
     */
    localizationIcons: { [key: string]: string; } | null;
    /**
     * Id of annotation that is utilized as a **NO CUTLERY** flag for the order.
     */
    noCutleryAnnotationId?: number;
}

