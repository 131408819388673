import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import {
	BKOrderEventType,
	CsiItemTypeEnum,
	CsiManager,
	IBKCassandraOrder,
	IBKItemInOrderBase,
	IBKMachine,
	IBKOrderPayData,
	ICsiMenuInOrder,
	ICsiProductInOrder,
	ICsiTicket,
	ICsiTicketSettings,
} from '@bk/jscommondatas';
import { DeclaredOrderResponse } from '@bk/price-management-common';
import { ClerkAuthHandler } from '@libs/shared/handlers';
import {
	IAdditionalInformationLogin,
	IClerkIsLoggedResponse,
	IClerkLogin,
	IClerkLoginResponse,
	IHttpPaymentInterfaceResponse,
	INewReglementResultResponse,
	INewReglementUserMessageResponse,
	IPaymentInterface,
} from '@libs/shared/interfaces';
import { ConfigurationFacade } from '@libs/shared/store/configuration';
import { DeviceFacade } from '@libs/shared/store/device-state';
import { OrderFacade } from '@libs/shared/store/order';
import { isMenu, isMenuPriceNegative, isProduct, urlParam } from '@libs/shared/utils';
import { Shar3dUtils } from '@shar3d/shar3d-utils';
import { IOrderCompleteData, IOrderUsedPayments } from '@shared/models';
import { clone, values } from 'ramda';
import { combineLatest, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, filter, map, takeUntil, timeout } from 'rxjs/operators';
import { IPayLines } from '../interfaces/clerk/payLines.interface';
import {
	AVAILABLE_JSON,
	CLERK,
	ConfigurationUrlsType,
	CSI_ORDER,
	IBKBigData,
	MDM_ORDER,
	ORDER_UUID,
	RemoteConfigs,
	RestoConfigModel,
	RestoSettingsModel,
	URL_TYPE_ENUM,
	UsersModel,
	WEBORDER_DISPLAY,
	WEBORDER_ORDER_ID,
	WEBORDER_SOURCE,
} from '../models';
import { ClerkSseService } from './clerk-sse.service';
import { ConfigurationService } from './configuration.service';
import { mapMenuToCsiMenuInOrder, mapProductToCsiProductInOrder } from './order-item-csi-mapper';

const LOGIN_URL = 'bksales/login';
const LOGGED_IN_URL = 'bksales/isLogged';
const PAYMENT_URL = 'sales/getConfigurationsPayments';
const PUT_COMMANDE_URL = 'bksales/putCommande';
const NEW_REGLEMENT_URL = 'sales/async/newReglement';
const PRINT_TICKET_RECEIPT = 'bksales/processCsiPrintTicketWithReceipt';
const PRINT_TICKET_WITHOUT_DETAIL = 'bksales/printTicketWithoutReceipt';
const NOT_FULLY_PAID_PRINT = 'bksales/printCommande';
const CANCEL_ORDER = 'sales/cancelCommande';
const BK_REQUEST_ORDER = 'bkrequest/order';
const FINALIZE_PAYMENT_URL = 'sales/finalizePayment';

@Injectable({ providedIn: 'root' })
export class ClerkService implements OnDestroy {
	private url: ConfigurationUrlsType = { clerkUrl: '', clerkBoUrl: '' };
	private readonly ngUnsubscribe$: Subject<void> = new Subject<void>();
	private httpClient: HttpClient;
	private restoSettingsSig: Signal<RestoSettingsModel>;
	private restoConfigSig: Signal<RestoConfigModel>;
	private bigDataSig: Signal<IBKBigData>;
	private availabilitySig: Signal<AVAILABLE_JSON>;
	private orderSig: Signal<IOrderCompleteData>;
	private readonly deviceDataSig: Signal<IBKMachine>;
	private readonly orderPaymentMethodsSig: Signal<IOrderUsedPayments[]>;
	private readonly CLERK_PUBLISH_ORDER_TIMEOUT: number = 30000;

	constructor(
		private readonly configurationService: ConfigurationService<any>,
		private readonly configurationFacade: ConfigurationFacade,
		private readonly route: ActivatedRoute,
		private readonly clerkAuthHandler: ClerkAuthHandler,
		private readonly clerkSseService: ClerkSseService,
		private readonly orderFacade: OrderFacade,
		private readonly deviceFacade: DeviceFacade
	) {
		this.httpClient = new HttpClient(clerkAuthHandler);
		const configValues$ = this.configurationService.config$;
		const queryParams = this.route.queryParams;
		this.deviceDataSig = toSignal(this.deviceFacade.getMachineInfo());
		this.orderPaymentMethodsSig = toSignal(this.orderFacade.getCurrentOrderPayments$());

		combineLatest([configValues$, queryParams])
			.pipe(
				takeUntil(this.ngUnsubscribe$),
				filter(([configData, queryParams]) => configData?.['API']?.[CLERK] || queryParams?.clerk)
			)
			.subscribe(([configData, queryParams]) => {
				this.url = {
					clerkUrl: configData?.['API']?.[CLERK]?.url,
					directUrl: queryParams[CLERK] || urlParam(CLERK),
				};

				if (this.getUrlPrecedency) {
					console.log(`Clerk service: Clerk url updated to: ${values(this.getUrlPrecedency)?.[0]}`);
				}
			});

		this.restoSettingsSig = toSignal(this.configurationFacade.getRestoSettings());
		this.restoConfigSig = toSignal(this.configurationFacade.getRestoConfig());
		this.bigDataSig = toSignal(this.configurationFacade.getBigData());
		this.availabilitySig = toSignal(this.configurationFacade.getAvailability());
		this.orderSig = toSignal(this.orderFacade.getCurrentOrder$());
	}

	/**
	 * Precedence of big data URL is:
	 * Take it from clerk query parameter
	 * Take it from clerk url
	 */
	get getUrlPrecedency(): Record<string, string> | undefined {
		if (this.url.directUrl) {
			return { [URL_TYPE_ENUM.DIRECT_URL]: this.url.directUrl };
		} else if (this.url.clerkUrl) {
			return { [URL_TYPE_ENUM.CLERK_URL]: this.url.clerkUrl };
		}

		return undefined;
	}

	login(user: UsersModel, manager: UsersModel, additionalInformation: IAdditionalInformationLogin): Observable<IClerkLoginResponse> {
		const loginRequestData: IClerkLogin = {
			codeVendeur: user.id,
			nomVendeur: user.login,
			codeClef: user.id,
			nomClef: user.login,
			codeManager: manager.id,
			nomManager: manager.login,
			...additionalInformation,
		};

		const url = `${values(this.getUrlPrecedency)?.[0] || ''}/${LOGIN_URL}`;
		return this.httpClient.post(url, loginRequestData).pipe(
			map((item: IClerkLoginResponse) => {
				return item;
			})
		);
	}

	checkIsLoggedIn(jwt: string): Observable<IClerkIsLoggedResponse> {
		const url = `${values(this.getUrlPrecedency)?.[0] || ''}/${LOGGED_IN_URL}`;
		const headers = new HttpHeaders({ Authorization: `Bearer ${jwt}` });
		return this.httpClient.get(url, { headers }).pipe(map((data: IClerkIsLoggedResponse) => data));
	}

	getPaymentMethods(): Observable<IPaymentInterface[]> {
		const url = `${values(this.getUrlPrecedency)?.[0] || ''}/${PAYMENT_URL}`;
		return this.httpClient.get(url).pipe(
			map((item: IHttpPaymentInterfaceResponse) => {
				return item.response;
			})
		);
	}

	// newReglement
	sendOrderToFiscalization(
		order: IOrderCompleteData,
		inputData: DeclaredOrderResponse,
		profitCenter: string,
		modeReglement: string
	): Observable<Partial<INewReglementResultResponse> | INewReglementUserMessageResponse> {
		const url = `${values(this.getUrlPrecedency)?.[0] || ''}/${NEW_REGLEMENT_URL}`;
		return this.clerkSseService.createEventSource(url, order, inputData, profitCenter, modeReglement).pipe(takeUntil(this.ngUnsubscribe$));
	}

	getOrderByOrderUuid(uuid: string): Observable<IBKCassandraOrder> {
		const params = {
			day: Shar3dUtils.getBusinessday(),
			uuid: uuid,
		};

		const url = `${values(this.getUrlPrecedency)?.[0] || ''}/${BK_REQUEST_ORDER}`;
		return this.httpClient.get(url, { params }).pipe(map((data: Record<'order', IBKCassandraOrder>) => data.order));
	}

	// putCommande
	publishOrderTowardsClerk(order: IOrderCompleteData): Observable<DeclaredOrderResponse> {
		if (order.orderContent.some((item) => isMenuPriceNegative(item))) {
			return throwError(() => {
				return new Error(`[APP]: Order ${order.orderUUID} contains an item with negative price value.`);
			});
		}

		const formData = new FormData();
		const csiOrder = this.createFiscalTicketOrderStructure(order);
		if (!csiOrder) {
			throw new Error('Could not create mandatory fiscal structure');
		}

		formData.append(MDM_ORDER, JSON.stringify(order, Shar3dUtils.JSONFilterPrivate));
		formData.append(CSI_ORDER, JSON.stringify(csiOrder, Shar3dUtils.JSONFilterPrivate));
		const contentType = 'application/x-www-form-urlencoded';
		const headers = new HttpHeaders({ enctype: contentType });
		const params = new HttpParams().set(ORDER_UUID, order.orderUUID);

		const url = `${values(this.getUrlPrecedency)?.[0] || ''}/${PUT_COMMANDE_URL}`;

		return this.httpClient.post(url, formData, { headers, params }).pipe(
			timeout(this.CLERK_PUBLISH_ORDER_TIMEOUT),
			map((item: DeclaredOrderResponse) => {
				return item;
			}),
			catchError((err) => {
				throw new Error('[APP]: Sending order to fiscal core was not successful');
			})
		);
	}

	ngOnDestroy() {
		this.ngUnsubscribe$.next();
		this.ngUnsubscribe$.complete();
	}

	createFiscalTicketOrderStructure(order: IOrderCompleteData): ICsiTicket {
		// this.restoConfig.restaurant.enableORBDigital -> global for brand
		// this.restoSettings.enableORBDigital -> local for given restaurant
		const isExtendedORBDigitalRecallEnabled: boolean =
			this.restoSettingsSig().enableORBDigital && this.restoConfigSig().restaurant.enableORBDigital;
		const orderPayRecallExArgs: string | undefined = isExtendedORBDigitalRecallEnabled
			? `${this.restoConfigSig().restaurant.number_bk}`
			: undefined;
		const csiTicketSettings: Partial<ICsiTicketSettings> = clone(this.restoSettingsSig());
		csiTicketSettings.brandName = this.configurationService.config$.getValue().brand;
		const addMoreLinesToCsiOrderCb: (ticket: ICsiTicket) => void = (ticket: ICsiTicket) => {
			// TODO: Add coupons lines to ticket
			return ticket;
		};

		//  Initial tags
		const tags: { [key: string]: string } = {};
		//  Feed with third party
		(order.event || []).forEach((ev) => {
			if (ev.eventType === BKOrderEventType.WEBORDER_SANDBOX_NAME) {
				tags[WEBORDER_SOURCE] = ev.arg;
			} else if (ev.eventType === BKOrderEventType.WEBORDER_ALIEN_ORDER_ID) {
				tags[WEBORDER_ORDER_ID] = ev.arg;
			} else if (ev.eventType === BKOrderEventType.WEBORDER_ALIEN_ORDER_ID_DISPLAY) {
				tags[WEBORDER_DISPLAY] = ev.arg;
			}
		});

		try {
			return CsiManager.orderToTicketWithCallback(
				order,
				order.orb,
				orderPayRecallExArgs,
				false,
				csiTicketSettings as ICsiTicketSettings,
				addMoreLinesToCsiOrderCb,
				this.identifyItem,
				this.convertProduct.bind(this),
				this.convertMenu.bind(this),
				tags
			);
		} catch (e) {
			console.warn('Could not create mandatory fiscal structure', e);
			return null;
		}
	}

	printTicket(
		order: IOrderCompleteData,
		user: UsersModel,
		manager: UsersModel,
		reglementData: INewReglementResultResponse,
		printerHost: string = '127.0.0.1'
	): Observable<boolean | HttpErrorResponse> {
		const payData: IBKOrderPayData = this.createPayDataStructureForReceipt(user, manager, reglementData);
		let formData = new FormData();
		formData.append('originName', this.deviceDataSig().ip);
		formData.append('numTicket', `${reglementData.ticketNumber}`);
		formData.append('orderUuid', `${order.orderUUID}`);
		formData.append('printerHost', printerHost);
		formData.append('operateur', `${user.login}`);
		formData.append('user', `${JSON.stringify(user)}`);
		formData.append('machine', `${JSON.stringify(payData.machine)}`);

		const url = `${values(this.getUrlPrecedency)?.[0] || ''}/${PRINT_TICKET_RECEIPT}`;
		return this.httpClient.post(url, formData).pipe(
			map((item: IClerkLoginResponse) => {
				return !!item;
			}),
			catchError((err) => of(err))
		);
	}

	printNotFullyPaiddOrderTicket(
		order: IOrderCompleteData,
		user: UsersModel,
		printerHost: string = '127.0.0.1'
	): Observable<boolean | HttpErrorResponse> {
		const url = `${values(this.getUrlPrecedency)?.[0] || ''}/${NOT_FULLY_PAID_PRINT}`;

		return this.httpClient
			.get(url, {
				params: {
					numCommande: order.orb,
					originName: this.deviceDataSig().ip,
					operateur: user.login,
					printerHost: printerHost,
					orderUUID: order.orderUUID,
				},
			})
			.pipe(
				map((item: IClerkLoginResponse) => {
					return !!item;
				}),
				catchError((err) => of(err))
			);
	}

	printTicketWithoutDetail(
		order: IOrderCompleteData,
		user: UsersModel,
		manager: UsersModel,
		reglementData: INewReglementResultResponse
	): Observable<boolean | HttpErrorResponse> {
		const payData: IBKOrderPayData = this.createPayDataStructureForReceipt(user, manager, reglementData);
		let formData = new FormData();
		formData.append('originName', this.deviceDataSig().ip);
		formData.append('codeVendeur', `${user.id}`);
		formData.append('numTicket', `${reglementData.ticketNumber}`);
		formData.append('nomVendeur', `${user.login}`);
		formData.append('orderUuid', `${order.orderUUID}`);
		formData.append('codeManager', `${manager.id}`);
		formData.append('nomManager', `${manager.login}`);
		formData.append('user', `${JSON.stringify(user)}`);
		formData.append('machine', `${JSON.stringify(payData.machine)}`);

		const url = `${values(this.getUrlPrecedency)?.[0] || ''}/${PRINT_TICKET_WITHOUT_DETAIL}`;
		return this.httpClient.post(url, formData).pipe(
			map((item: IClerkLoginResponse) => {
				return !!item;
			}),
			catchError((err) => of(err))
		);
	}

	sendFinalizePaymentToFiscalCore(
		order: IOrderCompleteData,
		user: UsersModel,
		manager: UsersModel,
		reglementData: INewReglementResultResponse
	): Observable<boolean | HttpErrorResponse> {
		let formData: FormData = new FormData();
		formData.append('pay', `${JSON.stringify(this.createPayDataStructureForReceipt(user, manager, reglementData))}`);

		const finalizePaymentUrl: string = `${values(this.getUrlPrecedency)?.[0] || ''}/${FINALIZE_PAYMENT_URL}/${order.orderUUID}`;

		return this.httpClient.post(finalizePaymentUrl, formData).pipe(
			map((result) => {
				return true;
			}),
			catchError((err) => of(err))
		);
	}

	cancelOrder(): Observable<boolean> {
		const order = this.orderSig();
		const params = {
			orderId: order.csiOrder.toString(),
			orderSource: order.csiSrc,
			orderUuid: order.orderUUID,
			userId: order.user.id.toString(),
			userLogin: order.user.login,
			managerId: order.manager.id.toString(),
			managerLogin: order.manager.login,
			source: order.machine.name,
			sourceType: order.machine.role,
		};

		const url = `${values(this.getUrlPrecedency)?.[0] || ''}/${CANCEL_ORDER}`;
		return this.httpClient.get(url, { params }).pipe(
			map((response) => {
				if (!CsiManager.isValidOKResponse(response)) {
					console.warn(`[APP]: Unable to cancel order ${order.orderUUID}. Response: ${response}`);
					return false;
				}
				return true;
			}),
			catchError((err) => {
				console.error(`[APP]: Unable to cancel order ${order.orderUUID}. Error: ${err}`);
				return of(err);
			})
		);
	}

	private convertMenu(item: IBKItemInOrderBase): ICsiMenuInOrder | null {
		// TODO Update check for products / menus in the order basket
		return isMenu(item) ? mapMenuToCsiMenuInOrder(item, this.bigDataSig()) : null;
	}

	private convertProduct(item: IBKItemInOrderBase): ICsiProductInOrder | null {
		// TODO Update check for products / menus in the order basket
		const remoteConfigs: RemoteConfigs = {
			bigData: this.bigDataSig(),
			availability: this.availabilitySig(),
		};
		return isProduct(item) ? mapProductToCsiProductInOrder(item, this.orderSig().deliveryMode, remoteConfigs) : null;
	}

	private identifyItem(item: IBKItemInOrderBase): CsiItemTypeEnum {
		if (isProduct(item)) {
			return CsiItemTypeEnum.PRODUCT;
		} else if (isMenu(item)) {
			return CsiItemTypeEnum.MENU;
		} else {
			return CsiItemTypeEnum.UNKNOWN;
		}
	}

	private createPayLines(): IPayLines[] {
		return this.orderPaymentMethodsSig().map((item) => {
			return {
				modeReglement: item.paymentMethod,
				payType: item.paymentMethod.number,
				payLabel: item.paymentMethod.libelle,
				changeAllowed: item.paymentMethod.changeAllowed,
				amount: item.amount,
				change: item.paymentMethod.change?.number ?? 0,
				csiNumber: this.orderSig().csiOrder,
				csiTags: item.paymentMethod.tag,
				family: item.paymentMethod.family,
			};
		});
	}

	private createDeletedLines(): IPayLines[] {
		return [];
	}

	private createPayDataStructureForReceipt(
		user: UsersModel,
		manager: UsersModel,
		reglementData: INewReglementResultResponse
	): IBKOrderPayData {
		const paid = this.orderPaymentMethodsSig().reduce((a, b) => a + b.amount, 0);
		const remaining = this.orderSig().orderTotal.ttc - paid;
		const data = {
			user,
			manager,
			csiOrder: reglementData.commandeNumber,
			csiTicket: reglementData.ticketNumber,
			machine: { ...this.deviceDataSig() },
			paid: paid,
			remaining: remaining,
			businessDay: this.orderSig().businessDay,
			total: paid + remaining,
			orderUUID: this.orderSig().orderUUID,
			payStartDate: this.orderSig().csiDeclaredTime,
			creationDate: this.orderSig().creationdate,
			csiSrc: reglementData.srcTicket,
			csiTicketSrc: reglementData.srcTicket,
			deletedLines: this.createDeletedLines(),
			lines: this.createPayLines(),
		};
		return data;
	}
}
