import { GlobalPriceDefinition, VATValue } from '@shared/models';

import { ItemPrice } from './item-price';

// Some properties are still missing, check http://192.168.100.200:8080/server/bkGetBigDatas.php?bo=192.168.100.200:81 if you need to add them
export class Product {
	sysName: string;

	shortName: string;

	_altName: string;

	_familyId: number;

	_name: {
		en: string;
		fr: string;
	};

	_description: {
		en: string;
		fr: string;
	};

	id: number;

	/**
	 * Used for "soft delete"
	 */

	active: boolean;

	_refM: {
		plu: string;
		bkpn: string;
		bkcName: string;
	};

	_priceM: ItemPrice;

	_priceExtraMenuTTC: number | undefined;

	_priceSuggestionMenuTTC: number | undefined;

	/**
	 * Used in conjuction with the new priceManagement in GAP/RAP.
	 * Product is disabled when the price is disabled for currently selected SalesChannelOption and
	 * other options such as "suggestion" or "supplement".
	 */

	isDisabled: boolean;

	/**
	 * Present only when the new PriceManagement is enabled. Otherwise is undefined.
	 */
	globalPriceDefinitions: GlobalPriceDefinition[];
	/**
	 * Present only when the new PriceManagement is enabled. Otherwise is undefined.
	 */
	vatValues: VATValue[];
}
