import { BKOrderEventDataImpl, BKOrderEventType, NewDeliveryBrandNames } from "@bk/jscommondatas";

export function remapDeliveryChannelsCorrectly(orderEvents: BKOrderEventDataImpl[]): BKOrderEventDataImpl[] {
	return orderEvents.map((e) => {
		if(e.eventType === BKOrderEventType.WEBORDER_SANDBOX_NAME) {
			return {...e, arg: NewDeliveryBrandNames[e.arg] || e.arg}
		}

		return e;
	});
}