/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 */
export type PermissionLevelReadWrite = 'read-only' | 'read-write';

export const PermissionLevelReadWrite = {
    Only: 'read-only' as PermissionLevelReadWrite,
    Write: 'read-write' as PermissionLevelReadWrite
};

