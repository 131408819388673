<form [formGroup]="serviceTimeTresholdsForm">
	<fieldset>
		<legend>
			{{ texts.title }}
		</legend>

		@if (serviceTimeTresholds) {
			<mat-form-field>
				<mat-label>Attention is needed after:</mat-label>
				<mat-icon [svgIcon]="'yellow_dot'" matPrefix></mat-icon>
				<input matInput type="number" min="0" max="60" formControlName="good"/>
				<span matSuffix>minutes</span>
			</mat-form-field>
			<mat-form-field>
				<mat-label>Its getting serious after:</mat-label>
				<mat-icon [svgIcon]="'orange_dot'" matPrefix></mat-icon>
				<input matInput type="number" min="0" max="60" formControlName="poor"/>
				<span matSuffix>minutes</span>
			</mat-form-field>
			<mat-form-field>
				<mat-label>There are real issues after:</mat-label>
				<mat-icon [svgIcon]="'red_dot'" matPrefix></mat-icon>
				<input matInput type="number" min="0" max="60" formControlName="critical"/>
				<span matSuffix>minutes</span>
			</mat-form-field>
		}
	</fieldset>
</form>
