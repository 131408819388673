/**
 * DKI-BE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PosEntry } from './pos-entry';
import { PosPerson } from './pos-person';
import { PosSessionDetail } from './pos-session-detail';


export interface PosRecord {
	id: string;
	restaurantId: string;
	employee: PosPerson;
	manager: PosPerson;
	entries: Array<PosEntry>;
	sessionDetail: PosSessionDetail;
	version: number;
}

