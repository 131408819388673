import { OpeningTime } from './opening-time';

export interface OpeningTimes {
	monday?: OpeningTime;
	tuesday?: OpeningTime;
	wednesday?: OpeningTime;
	thursday?: OpeningTime;
	friday?: OpeningTime;
	saturday?: OpeningTime;
	sunday?: OpeningTime;
}
