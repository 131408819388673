import { SvgIconType } from '@ngneat/svg-icon/lib/types';

export function convertIconsObjectToArray(iconsObj: { [key: string]: string }): SvgIconType[] {
	const keys = Object.keys(iconsObj);
	const result: SvgIconType[] = [];
	keys.forEach((name) => {
		const icon: SvgIconType = {
			name,
			data: iconsObj[name]
		};
		result.push(icon);
	});
	return result;
}
