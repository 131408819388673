export enum SocialEventType {
	PublicHolidays = 'PublicHolidays',
	ReligiousHolidays = 'ReligiousHolidays',
	Social = 'Social',
	SchoolHolidays = 'SchoolHolidays',
	Local = 'Local',
	Sport = 'Sport',
	Commercial = 'Commercial',
	ProductLaunch = 'ProductLaunch',
	MarketingHighlight = 'MarketingHighlight',
}
