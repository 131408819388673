import { SalesChannel } from "../../enums";
import { OrbpDetail } from "../../types";
import { isActiveMaster } from "../is-role";
import { SalesChannelsWithDeviceSpecificConfiguration } from "../sales-channels";
import { getAssignedDevicesInMasters } from "./get-assigned-devices-in-masters";
import { getAssignedDevicesToOrbp } from "./get-assigned-devices-to-orbp";
import { getORBpsMatchingAllDevices } from "./get-orbp-matching-all-devices";

/**
 * Detects devices which are assigned to Dependent ORBp, but are not allowed in his Master(s)
 */
export function getForbiddenDevices(salesChannel: SalesChannel, dependentOrbp: OrbpDetail, allOrbps: OrbpDetail[]): string[] {
	const activeMasters = allOrbps.filter(o => isActiveMaster(o) && dependentOrbp.masterIds.includes(o.id));

	// Is there a Master without device-specific config?
	const canUseAllDevices = (getORBpsMatchingAllDevices(salesChannel, activeMasters)).length > 0;

	if (canUseAllDevices) {
		return [];
	}

	const availableDevicesInMasters = getAssignedDevicesInMasters(salesChannel, activeMasters);
	const assignedDevices = getAssignedDevicesToOrbp(salesChannel, dependentOrbp);
	const forbiddenDevices = assignedDevices.filter(x => !availableDevicesInMasters.includes(x));

	return forbiddenDevices;
}

/**
 * Wrapper around `getForbiddenDevices()`.
 * Iterates over all SalesChannels which might use device-specific configuration, and checks for invalid configuration.
 */
export function getForbiddenDevicesForAllSalesChannels(dependentOrbp: OrbpDetail, allOrbps: OrbpDetail[]): string[] {
	const activeMasters = allOrbps.filter(o => isActiveMaster(o) && dependentOrbp.masterIds.includes(o.id));
	const forbiddenDevices: string[] = [];

	SalesChannelsWithDeviceSpecificConfiguration.forEach(salesChannel => {
		const forbidden = getForbiddenDevices(salesChannel, dependentOrbp, activeMasters);
		forbiddenDevices.push(...forbidden);
	})

	return forbiddenDevices;
}
