/**
 * DKI-BE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 */
export type RestaurantFeature =
	'DEFERRED_PAYMENT'
	| 'FIDELITY_PROGRAM'
	| 'GR_CARD_PAYMENT'
	| 'ICOUPON_PAYMENT'
	| 'KIOSK_MULTIPAYMENT'
	| 'ORB_SUBSCRIPTION'
	| 'TABLE_ALLOCATION'
	| 'TABLE_SERVICE';

export const RestaurantFeature = {
	DeferredPayment: 'DEFERRED_PAYMENT' as RestaurantFeature,
	FidelityProgram: 'FIDELITY_PROGRAM' as RestaurantFeature,
	GrCardPayment: 'GR_CARD_PAYMENT' as RestaurantFeature,
	IcouponPayment: 'ICOUPON_PAYMENT' as RestaurantFeature,
	KioskMultipayment: 'KIOSK_MULTIPAYMENT' as RestaurantFeature,
	OrbSubscription: 'ORB_SUBSCRIPTION' as RestaurantFeature,
	TableAllocation: 'TABLE_ALLOCATION' as RestaurantFeature,
	TableService: 'TABLE_SERVICE' as RestaurantFeature
};

