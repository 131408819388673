/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MediaExtension } from './media-extension';


export interface BrandConfigurationMediaManagement { 
    /**
     * Toggle for module in GAP.
     */
    enabled?: boolean;
    /**
     * File extensions allowed for upload of media files.
     */
    allowed_media_extensions?: Array<MediaExtension>;
    /**
     * File extensions allowed for upload of media\'s artifact files.
     */
    allowed_artifact_extensions?: Array<MediaExtension>;
    /**
     * File extensions allowed for upload of media\'s fallback image.
     */
    allowed_fallback_image_extensions?: Array<MediaExtension>;
}

