// TODO: Replace with MenuSize defined in @bk/fullstack-common, which is more descriptive
// In future we will have most likely different solution anyway.
export enum MenuSize {
	/** Small */
	S = 'S',
	/** Medium (Also the default menu size) */
	M = 'M',
	/** Large */
	L = 'L',
	/** Extra large */
	XL = 'XL',
}
