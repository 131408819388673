/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FidelityFilterDto } from './fidelity-filter-dto';
import { MediaActionDto } from './media-action-dto';


export interface MediaDto { 
    /**
     * Id of the media.
     */
    id: number;
    /**
     * Name of the media.
     */
    name: string;
    /**
     * Description of the media.
     */
    description: string;
    /**
     * Type of the media.   Values:   NONE = 0,   IMAGE = 1,   VIDEO = 2,   HTML5 = 3
     */
    mediaType: number;
    /**
     * Duration of the media (in ms).
     */
    mediaDurationMs: number;
    /**
     * Ids of tags associated with the media.
     */
    xtag: Array<number>;
    /**
     * List of targets where media should be displayed. Every target consists of combination of target family (MediaTargetFamilyEnum: NONE = -1, DMB = 0, POS = 100, KIOSK = 200, ORB = 300, COD = 400, XMB = 500) and device index.
     */
    mediaTargets: Array<number>;
    /**
     * Ids of products required to be available for media to display in order not to promote something that can\'t be ordered.
     */
    productReqs: Array<number>;
    /**
     * Ids of menus required to be available for media to display in order not to promote something that can\'t be ordered.
     */
    menuReqs: Array<number>;
    /**
     * Sorted products\' arguments.
     */
    productArgs: Array<number>;
    /**
     * Sorted menus\' arguments.
     */
    menuArgs: Array<number>;
    /**
     * Sorted ingredients\' arguments.
     */
    ingredientArgs: Array<number>;
    /**
     * Kiosk navigation menu argument.
     */
    navigationKioskArg?: number;
    /**
     * URL encoded string arguments.
     */
    stringArgs?: string;
    /**
     * Flag indicating if the media is associated with a campaign.
     */
    campaignMedia: boolean;
    /**
     * Flag indicating if the media has a content (image, video, HTML). Also main kiosk navigation tiles are considered medias.
     */
    _hasContent?: boolean;
    /**
     * Sorted games\' arguments.
     */
    gameArgs?: Array<number>;
    /**
     * Sorted discounts\' arguments.
     */
    discountArgs?: Array<number>;
    /**
     * Action to be performed when the media is activated/clicked by the customer.
     */
    action?: MediaActionDto;
    /**
     * Fidelity information.
     */
    fidelity?: FidelityFilterDto;
}

