import { Injectable, inject } from '@angular/core';
import { BKKingdomInOrderEventKey, BKKingdomLoginMethodEnum, BKOrderEventType, IBKKingdomClientData, IBKKingdomClientRequestResult, IBKOrderEventData } from '@bk/jscommondatas';
import { IDefaultConfig } from '@libs/shared/interfaces';
import { IFidelityConnection } from '@libs/shared/models';
import { Observable, of, tap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { FidelityAbstractService } from './fidelity-abstract.service';
import { Shar3dUtils } from '@shar3d/shar3d-utils';
import { OrderFacade } from '@libs/shared/store/order';

const SPLIO_URLS = {
	LOGIN: 'splio/login',
};

@Injectable({ providedIn: 'root' })
export class FidelitySplioService<T extends IDefaultConfig> extends FidelityAbstractService<T> implements IFidelityConnection {

	private readonly orderFacade: OrderFacade = inject(OrderFacade);

	override login(clientId: string, orderUUID: string, machineName: string): Observable<boolean> {
		const url = `${this.url}/${SPLIO_URLS.LOGIN}/${clientId}`;

		const options = {
			params: {
				machineName,
				orderUUID,
			},
		};
		return this.httpClient.get(url, options).pipe(
			tap((data: IBKKingdomClientRequestResult) => {
				if (data.result === 0) {
					this.loggedUser = data.client;
					this.addLoginEventToOrder();
				}
			}),
			map((item) => !!this.loggedUser),
			catchError(() => of(false))
		);
	}

	private addLoginEventToOrder(): void {
		const event = this.createFidelityLoginEvent(this.loggedUser);
		this.orderFacade.addOrderEvent(event);
	}
	
	private createFidelityLoginEvent(client: IBKKingdomClientData): IBKOrderEventData {
		return {
			timestamp: Shar3dUtils.now(),
			eventType: BKOrderEventType.KINGDOM_CLIENT_LOGIN,
			arg: Shar3dUtils.object2URLEncoded({
				[BKKingdomInOrderEventKey.CLIENT_ID]: client.id,
				[BKKingdomInOrderEventKey.KINGDOM_CLIENT_ID]: client.id,
			}),
		};
	}

	override logout() {}

	override getData() {}
}
