/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RestaurantFeature } from './restaurant-feature';
import { WeborderChannel } from './weborder-channel';


/**
 * Information about restaurant returned from REBOOT-API api/resto endpoint.
 */
export interface RebootApiRestoInfo { 
    id: string;
    name: string;
    bknb?: string;
    frnb?: string;
    /**
     * Deprecated, use weborderChannels property instead.
     */
    salesChannels: Array<string>;
    /**
     * Weborder channels enabled in the restaurant.
     */
    weborderChannels?: Array<WeborderChannel>;
    /**
     * Features enabled in the restaurant.
     */
    features?: Array<RestaurantFeature>;
}

