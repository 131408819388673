/**
 * DKI-BE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface RestaurantOwnerUpdateInput {
	entity_id?: string;
	name?: string;
	vat_number?: string;
	business_code?: string;
	address?: string;
	address_extra?: string;
	zip_code?: string;
	city?: string;
	country?: string;
	business_day_start_time?: string;
	fiscal_year_start_date?: string;
}

