/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ItemReferenceDto } from './item-reference-dto';
import { SelectionPatternDto } from './selection-pattern-dto';


export interface DiscountDto { 
    /**
     * Id of the discount.
     */
    id: number;
    /**
     * Name of the discount in the system.
     */
    sysName: string;
    /**
     * Name of the discount.
     */
    _nameTemp?: object;
    /**
     * Description of the discount.
     */
    _descriptionTemp?: object;
    /**
     * Type of discount.         Values:         NONE = 0,   MENU_DISCOUNT = 1,   PRODUCT_DISCOUNT = 2,   ORDER_DISCOUNT = 3,   MENU_SINGLE_PRICE = 4,   PRODUCT_SINGLE_PRICE = 5,   PRODUCT_AND_EXTRA_DISCOUNT = 6,   MENU_AND_EXTRA_DISCOUNT = 7,   MENU_EXCLUSIVE = 8,   PRODUCT_EXCLUSIVE = 9
     */
    discountType: number;
    /**
     * Code that may be associated to the discount.
     */
    discountCode?: string;
    /**
     * Flag indicating if the discount is in percentage.
     */
    discountInPercent: boolean;
    /**
     * Value of the discount in percentage or full value based on the \"discountInPercent\" property.
     */
    discountValue: number;
    /**
     * Reference of the discount.
     */
    ref: ItemReferenceDto;
    /**
     * Flag indicating if the discount can be added to another menu discount.
     */
    _canBeAddedWithOtherMenuDiscount?: boolean;
    /**
     * Flag indicating if the discount can be added to another product discount.
     */
    _canBeAddedWithOtherProductDiscount?: boolean;
    /**
     * Flag indicating if the discount can be added to another order discount (global discount).
     */
    _canBeAddedWithOtherOrderDiscount?: boolean;
    /**
     * Flag indicating if the discount can be utilized in combination with another fidelity discount.
     */
    _canBeUsedWithAnotherFidelityDiscount?: boolean;
    /**
     * Patterns of the items the discount may be applied to.
     */
    _discountApplicableToPattern?: Array<SelectionPatternDto>;
    /**
     * Mask indicating which menu sizes are concerned by the \"_discountApplicableToPattern\" property.   Values (MenuSizeMask):   NONE = 0,   MEDIUM = 2 ** MenuSizeEnum.MEDIUM,   LARGE = 2 ** MenuSizeEnum.LARGE
     */
    _applicableToMenuSizeMask?: number;
    /**
     * Patterns of the item that are required for the discount to apply.
     */
    _discountApplicableWhenPattern?: Array<SelectionPatternDto>;
    /**
     * Mask indicating which menu sizes are concerned by the \"_discountApplicableWhenPattern\" property.   Values (MenuSizeMask):   NONE = 0,   MEDIUM = 2 ** MenuSizeEnum.MEDIUM,   LARGE = 2 ** MenuSizeEnum.LARGE
     */
    _applicableWhenMenuSizeMask?: number;
    /**
     * Patterns of the restaurants the discount may be applied in (very likely not used anymore).
     */
    _applicableToRestaurantPattern?: Array<SelectionPatternDto>;
    /**
     * Flag indicating if the discount is associated with the campaign.
     */
    _campaignDiscount?: boolean;
    /**
     * Flag indicating if the discount is associated with the fidelity program.
     */
    _fidelityDiscount?: boolean;
    /**
     * Mask for additional flags that may be applied on the discount.   Values (DiscountFlagMask):   NONE = 0,   ITEM_CANNOT_BE_LEFT_WITHOUT_DISCOUNT = 2 ** 0,   MENU_WITH_MORE_EXPENSIVE_FIRST_STEP = 2 ** 1
     */
    _flagMask?: number;
    /**
     * Ids of tags associated with the discount.
     */
    xtag: Array<number>;
    /**
     * Level of validation required to apply the discount if \"_requireManagerValidation\" property set.   Values:   NONE = 0,   MANAGER = 1,   FRANCHISE = 2
     */
    validationRequired?: number;
    /**
     * Flag indicating if the upper level employee validation is required before adding the discount based on the \"validationRequired\" property.
     */
    _requireManagerValidation?: boolean;
}

