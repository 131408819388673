import {
	_BK,
	BKCampaignContents,
	BKCampaignDataWithLogic,
	BKItemSelectionPatternSourceEnum,
	BKOrderSourceEnum,
	BKPatternUtilities,
	CsiManager,
	IBKBigDataProcessed,
	IBKCampaignData,
	IBKGameData,
	IBKItemInOrderBase,
	IBKMenuBase,
	IBKMenuStepData,
	IBKNavigationScreenData,
	IBKProductBase,
	IBKProductIngredientData,
	IBKProductIngredientSelectedData,
	IBKSelectionPattern,
} from '@bk/jscommondatas';
import { mapDeliveryModeToSalesChannelOption, MenuSize, ProductPriceType, SalesChannelOption } from '@bk/price-management-common';
import {
	AVAILABLE_JSON,
	IBKBigData,
	IRestoConfig,
	MENU_SIZE,
	NavScreenContent,
	NavScreenContentOptions,
	ProductFamilies,
	RemoteConfigs,
} from '@libs/shared/models';
import { Shar3dUtils } from '@shar3d/shar3d-utils';
import { GlobalPriceDefinition } from '@shared/models';
import { union, without } from 'ramda';
import { IIngredientsAddedRemoved, IIngredientsDifferences, IProductSummary } from '../interfaces';
import { ActiveCampaignItems } from '../models/campaign-type';
import { isMenuAvailable, isProductWithIngredientsAvailable, isProductWithIngredientsAvailableAndActive } from './big-data.functions';

/** Navigation screen functions **/

// gets all items based on area property of selected navigation screen
export function getNavScreenContent(patterns: IBKSelectionPattern[], bigData: IBKBigData, restoConfig: IRestoConfig): NavScreenContent {
	const navScreenContent: NavScreenContent = {
		products: [],
		menus: [],
		navigationScreens: [],
		games: [],
	};

	const productFamilies = new ProductFamilies(bigData.productFamilies);
	productFamilies.addProductsToFamily(bigData.products);

	patterns.forEach((pattern) => {
		switch (pattern.target) {
			//  Product
			case BKItemSelectionPatternSourceEnum.PRODUCT:
				const products = bigData.products;
				const filteredProducts: IBKProductBase[] = BKPatternUtilities.filterProducts(
					pattern,
					Object.values(products),
					products,
					productFamilies.allFamilies
				)
					.filter((p: IBKProductBase) => !p.noKiosk)
					.sort(function (a: IBKProductBase, b: IBKProductBase) {
						const aIndex: number = a?._indexKiosk || 0;
						const bIndex: number = b?._indexKiosk || 0;
						return aIndex - bIndex;
					});
				if (pattern.excludeFilter) {
					navScreenContent.products = without(filteredProducts, navScreenContent.products);
				} else {
					navScreenContent.products = union(navScreenContent.products, filteredProducts);
				}
				break;
			// Menu
			case BKItemSelectionPatternSourceEnum.MENU:
				const menus = bigData.menus;
				const filteredMenus: IBKMenuBase[] = BKPatternUtilities.filterMenus(pattern, Object.values(menus || {}), menus || {})
					.filter((m: IBKMenuBase) => !m.noKiosk)
					.sort(function (a: IBKMenuBase, b: IBKMenuBase) {
						const aIndex: number = a?._indexKiosk || 0;
						const bIndex: number = b?._indexKiosk || 0;
						return aIndex - bIndex;
					});

				if (pattern.excludeFilter) {
					navScreenContent.menus = without(filteredMenus, navScreenContent.menus);
				} else {
					navScreenContent.menus = union(navScreenContent.menus, filteredMenus);
				}
				break;
			//  Navigation screen
			case BKItemSelectionPatternSourceEnum.KIOSK_NAVIGATION_MENU:
				const screens = restoConfig.kioskScreens as { [p: number]: IBKNavigationScreenData };
				const filteredScreens: IBKNavigationScreenData[] = BKPatternUtilities.filterKioskNavigations(
					pattern,
					Object.values(screens),
					screens
				);
				if (pattern.excludeFilter) {
					navScreenContent.navigationScreens = without(filteredScreens, navScreenContent.navigationScreens);
				} else {
					navScreenContent.navigationScreens = union(navScreenContent.navigationScreens, filteredScreens);
				}
				break;
			//  Games
			case BKItemSelectionPatternSourceEnum.GAME:
				const games = bigData.games;
				const filteredGames: IBKGameData[] = BKPatternUtilities.filterGames(pattern, Object.values(games), games);
				if (pattern.excludeFilter) {
					navScreenContent.games = without(filteredGames, navScreenContent.games);
				} else {
					navScreenContent.games = union(navScreenContent.games, filteredGames);
				}
				break;
		}
	});
	return navScreenContent;
}

// filters out navigation screen content items based on availability and other conditions depending on the items type
// TODO: Configure function to return valid content when fidelity program is active (See legacy kiosk fidelity program functionality).
export function getValidNavScreenContent(
	menuItems: NavScreenContent,
	remoteConfigs: RemoteConfigs,
	screenContentOptions: NavScreenContentOptions = undefined,
	isDescendantContent: boolean = false
): NavScreenContent {
	const validNavScreenItems: NavScreenContent = {
		products: [],
		menus: [],
		games: [],
		navigationScreens: [],
	};
	const activeCampaignContents: BKCampaignContents = getActiveCampaignsContents(
		remoteConfigs.bigData,
		remoteConfigs.availability,
		screenContentOptions.orderSource
	);

	const salesChannel = screenContentOptions ? mapDeliveryModeToSalesChannelOption(screenContentOptions?.deliveryMode) : undefined;

	if (isNavScreenContentEmpty(menuItems)) {
		return validNavScreenItems;
	}

	// filters out invalid products
	validNavScreenItems.products = menuItems.products.filter((product) => {
		const hasProductValidPrice = hasItemValidPrice(product, salesChannel, screenContentOptions?.productPriceType);
		const isProductAvailable = isProductWithIngredientsAvailable(
			{ loading: false, data: remoteConfigs.bigData },
			{ loading: false, data: remoteConfigs.availability },
			product.id
		);
		let isProductActive =
			remoteConfigs?.availability?.productActive[product.id] !== undefined
				? remoteConfigs?.availability?.productActive[product.id]
				: product.active;
		if (activeCampaignContents.daypartPrices.restrictive) {
			isProductActive = !!activeCampaignContents.daypartPrices.products?.[product.id];
		}

		const inActiveCampaign = activeCampaignContents.campaignProducts.includes(product.id);
		const isActiveForFidelity = isActiveOnlyForFidelity(product);

		return (
			isProductActive &&
			(!product.campaignItem || inActiveCampaign) &&
			!product.noKiosk &&
			isProductAvailable &&
			hasProductValidPrice &&
			!isActiveForFidelity
		);
	});

	// filters out invalid menus
	validNavScreenItems.menus = menuItems.menus.filter((menu) => {
		const hasMenuValidPrice = hasItemValidPrice(menu, salesChannel, screenContentOptions?.menuSize);

		const isMenuAvail = isMenuAvailable(
			{ loading: false, data: remoteConfigs.bigData },
			{ loading: false, data: remoteConfigs.availability },
			menu.id
		);
		let isMenuActive = activeCampaignContents.daypartPrices.restrictive
			? !!activeCampaignContents.daypartPrices.menus?.[menu.id]
			: menu.active;
		const inActiveCampaign = activeCampaignContents.campaignMenus.includes(menu.id);
		const isActiveForFidelity = isActiveOnlyForFidelity(menu);
		const canBeOrdered = canMenuBeOrdered(
			menu,
			remoteConfigs.bigData,
			remoteConfigs.availability,
			salesChannel,
			screenContentOptions?.menuSize
		);

		return (
			isMenuActive &&
			(!menu.campaignItem || inActiveCampaign) &&
			!menu.noKiosk &&
			isMenuAvail &&
			hasMenuValidPrice &&
			!isActiveForFidelity &&
			canBeOrdered
		);
	});

	// filters out invalid games
	validNavScreenItems.games = menuItems.games.filter((game) => {
		return !game._campaignGame;
	});

	// filters out invalid nav screens
	validNavScreenItems.navigationScreens = menuItems.navigationScreens.filter((screen) => {
		if (!screen.active || areNavScreenAreasEmpty(screen)) {
			return false;
		} else if (
			isDescendantContent &&
			(validNavScreenItems.menus?.length || validNavScreenItems.products?.length || validNavScreenItems.games?.length)
		) {
			return true;
		} else {
			const screenContent = getNavScreenContent(screen.area0, remoteConfigs.bigData, remoteConfigs.restoConfig);

			// recursive call to check if child navigation screen contains valid items
			const validScreenContent = getValidNavScreenContent(screenContent, remoteConfigs, screenContentOptions, true);

			return !isNavScreenContentEmpty(validScreenContent);
		}
	});
	return validNavScreenItems;
}

// helper function to check items valid price for a sales channel
// if no sales channel is provided to function a price value in "_priceM" is checked
function hasItemValidPrice(
	item: IBKProductBase | IBKMenuBase,
	salesChannelOption: SalesChannelOption = undefined,
	rowIdentifiers: Array<ProductPriceType | MenuSize> = undefined
): boolean {
	if ((!salesChannelOption || !rowIdentifiers) && item._priceM.ttc >= 0) {
		return true;
	}

	if (!item?.globalPriceDefinitions) {
		return false;
	}

	return !!(item.globalPriceDefinitions as GlobalPriceDefinition[]).find((salesChannel) => {
		return (
			salesChannel.salesChannelOption === salesChannelOption &&
			rowIdentifiers.includes(salesChannel.rowIdentifier) &&
			salesChannel?.price >= 0 &&
			!salesChannel.isDisabled
		);
	});
}

// helper function to check if item is active for fidelity program
function isActiveOnlyForFidelity(item: IBKProductBase | IBKMenuBase): boolean {
	return item.exclusiveToFidelity;
}

// checks if navScreenContent has no items
export function isNavScreenContentEmpty(navScreenContent: NavScreenContent): boolean {
	return Object.values(navScreenContent).every((contentType) => !contentType?.length);
}

// checks if navScreen has items  in its areas
export function areNavScreenAreasEmpty(screen: IBKNavigationScreenData): boolean {
	return !!(screen.area0?.length && screen?.area1?.length && screen?.area2?.length && screen?.area3?.length && screen?.area4?.length);
}

/** End of Navigation screen functions **/

/** Menus functions **/

// checks if order item is a menu
export function isOrderItemMenu(item: IBKItemInOrderBase): boolean {
	// selection property is present only in menu items
	return !!item?.selection;
}

// checks if menu item can be ordered in medium or large size
function canMenuBeOrdered(
	menu: IBKMenuBase,
	bigData: IBKBigData,
	availability: AVAILABLE_JSON,
	salesChannelOption: SalesChannelOption = undefined,
	rowIdentifiers: Array<ProductPriceType | MenuSize> = undefined
): boolean {
	let canMenuBeOrderedInMedium: boolean = true;
	let canMenuBeOrderedInLarge: boolean = true;

	// check if every menu step contains at least one item with valid price definition
	menu._steps.forEach((step) => {
		const menuStepProducts = getMenuStepProducts(step, menu, bigData, availability);
		const canBeOrderedInMedium: boolean = menuStepProducts[MENU_SIZE.MEDIUM].some((item) => {
			return isMenuStepItemPriceValid(item, salesChannelOption);
		});
		const canBeOrderedInLarge: boolean = menuStepProducts[MENU_SIZE.LARGE].some((item) => {
			isMenuStepItemPriceValid(item, salesChannelOption);
		});
		if (!canBeOrderedInMedium) {
			canMenuBeOrderedInMedium = false;
		}
		if (!canBeOrderedInLarge) {
			canMenuBeOrderedInLarge = false;
		}
	});

	return canMenuBeOrderedInMedium || canMenuBeOrderedInLarge;
}

// checks if price value of an item in menu step is valid
export function isMenuStepItemPriceValid(item: IBKProductBase | IBKMenuBase, salesChannelOption: SalesChannelOption = undefined): boolean {
	if (!salesChannelOption || !item?.globalPriceDefinitions) {
		return false;
	}

	const itemPriceDefinitions: GlobalPriceDefinition[] =
		(item?.globalPriceDefinitions as GlobalPriceDefinition[])?.filter((priceDef) => {
			return priceDef.salesChannelOption === salesChannelOption && !priceDef.isDisabled;
		}) || [];

	if (!itemPriceDefinitions?.length) {
		return false;
	} else {
		return (
			itemPriceDefinitions.some((priceDefinition) => {
				return priceDefinition.rowIdentifier === ProductPriceType.Solo;
			}) && itemPriceDefinitions.every((priceDefinition) => typeof priceDefinition.price === 'number')
		);
	}
}

// gets products for a menu step
export function getMenuStepProducts(
	step: IBKMenuStepData,
	menu: IBKMenuBase,
	bigData: IBKBigData,
	availability: AVAILABLE_JSON
): Record<MENU_SIZE, IBKProductBase[]> {
	const productFamilies = new ProductFamilies(bigData.productFamilies);
	productFamilies.addProductsToFamily(bigData.products);

	const defaultMediumItem = (step?._defaultSelection > 0 && bigData.products?.[step._defaultSelection]) || undefined;

	const mediumItems =
		step._patterns.length <= 0
			? defaultMediumItem
				? [defaultMediumItem]
				: []
			: BKPatternUtilities.filterProductsMulti(
					step._patterns,
					Object.values(bigData.products),
					bigData.products,
					productFamilies.allFamilies
				);

	const largeItems: IBKProductBase[] =
		mediumItems?.length && menu._existsInL
			? mediumItems.map((item) => (!item?._preventUpsize && bigData.products?.[item?._upperSizeProduct]) || item)
			: [];

	const configuration = {
		bigData: { loading: false, data: bigData },
		availability: { loading: false, data: availability },
	};
	const availableMediumItems = mediumItems.filter((item) =>
		isProductWithIngredientsAvailableAndActive(configuration.bigData, configuration.availability, item.id)
	);
	const availableLargeItems = largeItems.filter((item) =>
		isProductWithIngredientsAvailableAndActive(configuration.bigData, configuration.availability, item.id)
	);

	return {
		[MENU_SIZE.MEDIUM]: availableMediumItems,
		[MENU_SIZE.LARGE]: availableLargeItems,
	};
}

export function productToProductInOrder(product: IBKProductBase, quantity = 1, priceType = ProductPriceType.Solo): IBKItemInOrderBase {
	return {
		sysName: product.sysName,
		shortName: product.shortName,
		campaignItem: product.campaignItem,
		name: product._name,
		familyId: product._familyId,
		available: product.available,
		id: product.id,
		xtag: product.xtag,
		noKiosk: product.noKiosk,
		noWeb: product.noWeb,
		line: -1,
		lineuuid: CsiManager.getGeneratedUUID(),
		timestamp: 0,
		noIce: false,
		later: false,
		recipe: [],
		removedRecipeModificatorsBeforeKitchenScreen: [],
		removedRecipeModificatorsAfterSubtotal: [],
		removedRecipeModificatorsAfterKitchenScreen: [],
		freeItems: [],
		itemAppliedDiscount: [],
		itemInvolvedDiscount: [],
		ref: { ...product._refM },
		qty: quantity,
		price: { ...product._priceM },
		aLaCartePrice: { ...product._priceM },
		extraPrice: {
			ht: 0,
			tva: 0,
			ttc: 0,
			pc: 0,
		},
		_altName: product?._altName,
		inActiveCampaign: product?.inActiveCampaign,
		nonFood: product?.nonFood,
		exclusiveToFidelity: product?.exclusiveToFidelity,
		requireValidation: product?.requireValidation,
		_weight: product?._weight,
		priceType: priceType,
	};
}

export function menuToMenuInOrder(menu: IBKMenuBase, quantity = 1): IBKItemInOrderBase {
	return {
		sysName: menu.sysName,
		shortName: menu.shortName,
		campaignItem: menu.campaignItem,
		name: menu._name,
		available: menu.available,
		id: menu.id,
		xtag: menu.xtag,
		noKiosk: menu.noKiosk,
		noWeb: menu.noWeb,
		line: -1,
		lineuuid: CsiManager.getGeneratedUUID(),
		timestamp: 0,
		freeItems: [],
		selection: [],
		itemAppliedDiscount: [],
		itemInvolvedDiscount: [],
		ref: { ...menu._refM },
		qty: quantity,
		price: { ...menu._priceM },
		aLaCartePrice: { ...menu._priceM },
		extraPrice: {
			ht: 0,
			tva: 0,
			ttc: 0,
			pc: 0,
		},

		large: false,
		relatedGame: undefined,
		assemblyAreaId: undefined,
		_kidsMenu: false,
		_boMenuType: menu?._boMenuType,
		priceExtraMenuTTC: 0,
		user: undefined,
		manager: undefined,
		machine: undefined,
		_altName: menu?._altName,
		inActiveCampaign: menu?.inActiveCampaign,
		exclusiveToFidelity: menu?.exclusiveToFidelity,
		requireValidation: menu?.requireValidation,
		_weight: menu?._weight,
	};
}

/** End of Menus functions **/

/** Campaign functions **/

// gets ids of menus, products and ingredients which are in active campaigns
export function getActiveCampaignItems(campaigns: { [id: string]: IBKCampaignData }): ActiveCampaignItems {
	const campaignsWithLogic = Object.values(campaigns).map((c) => new BKCampaignDataWithLogic(c));
	const productsInCampaigns: number[] = [];
	const menusInCampaigns: number[] = [];
	const ingredientsInCampaigns: number[] = [];
	campaignsWithLogic.forEach((campaign) => {
		if (campaign.available) {
			if (campaign.productsInCampain?.length) {
				productsInCampaigns.push(...campaign.productsInCampain);
			}
			if (campaign.menusInCampain?.length) {
				menusInCampaigns.push(...campaign.menusInCampain);
			}
			if (campaign.ingredientsInCampain?.length) {
				ingredientsInCampaigns.push(...campaign.ingredientsInCampain);
			}
		}
	});
	return {
		products: productsInCampaigns,
		menus: menusInCampaigns,
		ingredients: ingredientsInCampaigns,
	};
}

// gets all content which is enabled/added in active campaigns
export function getActiveCampaignsContents(
	bigData: IBKBigData,
	availability: AVAILABLE_JSON,
	orderSource: BKOrderSourceEnum
): BKCampaignContents {
	const refDate: number = Shar3dUtils.now();
	const bigDataProcessed: IBKBigDataProcessed<null, null, null, null, null, null, null, null> = _BK.computeBigDataProcessedObject(bigData);
	const campaignsWithLogic: BKCampaignDataWithLogic[] = Object.values(bigData.campaigns).map((c) => new BKCampaignDataWithLogic(c));

	const campaignContents: BKCampaignContents = BKCampaignContents.computeCampaignContents(
		campaignsWithLogic,
		true,
		availability.campaignAvailability,
		availability.campaignFilters,
		availability.daypartPrices || {},
		orderSource,
		bigDataProcessed,
		refDate,
		false,
		0
	);

	return campaignContents;
}

/** Gets media playlists ids that are in an active campaign */
export function getActiveCampaignMediasPlaylistsIds(campaigns: Record<string, IBKCampaignData>): number[] {
	return Object.values(campaigns)
		.map((c) => new BKCampaignDataWithLogic(c))
		.filter((campaign) => campaign.available && campaign.mediaPlaylistsInCampain?.length)
		.reduce((acc: number[], campaign) => [...acc, ...campaign.mediaPlaylistsInCampain], []);
}

/** End of Campaign functions **/

export function getDifferencesForPersonalizedItem(product: IProductSummary, bigData: IBKBigData, lang: string): IIngredientsAddedRemoved {
	if (!product || !product.recipe?.length) {
		return { added: [], removed: [] };
	}

	const itemModifiedIngredients = product.recipe.filter((ingredient) => ingredient.selected !== -1 && ingredient.modified);
	const itemOriginalIngredients = bigData.products[product.id]._ingredients.filter((ingredient) => ingredient.ingredientDefault !== -1);

	const { modifiedIngredients, modifiedIngredientsFiltered } = getModifiedIngredients(itemModifiedIngredients, bigData);

	const originalIngredientsFiltered = getOriginalIngredientsFiltered(itemOriginalIngredients, modifiedIngredients, bigData, lang);

	return getMergedResult(modifiedIngredientsFiltered, originalIngredientsFiltered);
}

function calculateExtraPrice(item: IBKProductIngredientSelectedData, qty: number, bigData: IBKBigData): number {
	const itemPrice = item.priceTTC ?? bigData.ingredients[item.selected].priceTTC;
	const extraPrice = qty * itemPrice;
	// 0 if extraPrice is a negative number
	return Math.max(extraPrice, 0);
}

function getModifiedIngredients(
	itemModifiedIngredients: IBKProductIngredientSelectedData[],
	bigData: IBKBigData
): {
	modifiedIngredients: IBKProductIngredientSelectedData[];
	modifiedIngredientsFiltered: IIngredientsDifferences[];
} {
	const modifiedIngredientMap = itemModifiedIngredients.reduce((map, currentItem) => {
		const existingItem = map.get(currentItem.selected);

		if (existingItem) {
			existingItem.qty += currentItem.qty;
			existingItem.initQty += currentItem.initQty;
		} else {
			map.set(currentItem.selected, { ...currentItem });
		}

		return map;
	}, new Map<number, IBKProductIngredientSelectedData>());

	const modifiedIngredients = [...modifiedIngredientMap.values()];

	const modifiedIngredientsFiltered: IIngredientsDifferences[] = modifiedIngredients
		.filter((ingredient) => ingredient.initial === ingredient.selected || ingredient.qty !== 0)
		.map((ingredient) => {
			let qty: number;
			// Not original ingredient (eg: sauce)
			if (ingredient.initial !== ingredient.selected) {
				qty = ingredient.qty;
			} else {
				qty = ingredient.qty - ingredient.initQty || ingredient.qty;
			}

			return {
				id: ingredient.selected,
				name: ingredient.sysName,
				qty: qty,
				extraPrice: calculateExtraPrice(ingredient, qty, bigData),
			};
		});
	return { modifiedIngredients, modifiedIngredientsFiltered };
}

function getOriginalIngredientsFiltered(
	originalIngredients: IBKProductIngredientData[],
	modifiedIngredient: IBKProductIngredientSelectedData[],
	bigData: IBKBigData,
	lang: string
): IIngredientsDifferences[] {
	const originalIngredientsMap = originalIngredients.reduce((map, currentItem) => {
		const existingItem = map.get(currentItem.ingredientDefault);

		if (existingItem) {
			existingItem.initialQty += currentItem.initialQty;
		} else {
			map.set(currentItem.ingredientDefault, { ...currentItem });
		}

		return map;
	}, new Map<number, IBKProductIngredientData>());

	const originalIngredientsFiltered: IIngredientsDifferences[] = modifiedIngredient
		.filter((ingredient) => {
			// Remove non-default ingredients
			const original = originalIngredientsMap.get(ingredient.initial);
			return original && original.initialQty !== 0 && ingredient.selected !== ingredient.initial;
		})
		.map((ingredient) => {
			const original = originalIngredientsMap.get(ingredient.initial);

			const id = original.ingredientDefault;
			const qty = -ingredient.initQty;

			return {
				id: id,
				name: bigData.ingredients[id]._name[lang],
				qty: qty,
				extraPrice: calculateExtraPrice(ingredient, qty, bigData),
			};
		});

	return originalIngredientsFiltered;
}

function getMergedResult(
	modifiedIngredientsFiltered: IIngredientsDifferences[],
	originalIngredientsFiltered: IIngredientsDifferences[]
): IIngredientsAddedRemoved {
	const mergedIngredients = [...modifiedIngredientsFiltered, ...originalIngredientsFiltered];
	const aggregatedIngredientsMap = mergedIngredients.reduce((map, currentItem) => {
		const existingItem = map.get(currentItem.id);
		if (existingItem) {
			existingItem.qty += currentItem.qty;
		} else {
			map.set(currentItem.id, { ...currentItem });
		}

		return map;
	}, new Map<number, IIngredientsDifferences>());

	return [...aggregatedIngredientsMap.values()].reduce(
		(acc: IIngredientsAddedRemoved, item) => {
			if (item.qty > 0) {
				acc.added.push(item);
			} else {
				acc.removed.push(item);
			}

			return acc;
		},
		{ added: [], removed: [] }
	);
}
