// The values must be declared in increasing severity!

import { getEnumValues } from "../utils";

// This implicit sorting is used by validationMessageSeveritySorter().
export enum ValidationMessageSeverityEnum {
	INFO = 'info',
	WARNING = 'warning',
	ERROR = 'error'
}

/**
 * The highest severity will be first.
 * Lowest severity will be last.
 */
export function validationMessageSeveritySorter(a: ValidationMessageSeverityEnum, b: ValidationMessageSeverityEnum): number {
	const sortedValues = getEnumValues(ValidationMessageSeverityEnum);
	const aIndex = sortedValues.findIndex(x => x === a);
	const bIndex = sortedValues.findIndex(x => x === b);

	if (aIndex === bIndex) {
		return 0;
	} else if (aIndex > bIndex) {
		return -1;
	} else {
		return 1;
	}
}
