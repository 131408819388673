/**
 * DKI-BE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PaymentMethodEnum } from './payment-method-enum';


/**
 * Abstract base class for generic types.  A generic type is typically declared by inheriting from this class parameterized with one or more type variables. For example, a generic mapping type might be defined as::    class Mapping(Generic[KT, VT]):       def __getitem__(self, key: KT) -> VT:           ...       # Etc.  This class can then be used as follows::    def lookup_name(mapping: Mapping[KT, VT], key: KT, default: VT) -> VT:       try:           return mapping[key]       except KeyError:           return default
 */
export interface AccountingReportByPaymentMethodRecord {
	report_id: number;
	payment_method: PaymentMethodEnum;
	declared_value: number;
	validated_value: number;
}

