/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MonitoringRestaurantBasicInfo } from './monitoring-restaurant-basic-info';


export interface MonitoringRestaurantsInfo { 
    /**
     * Total amount of restaurants.
     */
    total: number;
    /**
     * Amount of connected restaurants (received VPN update within configured time - by default 5 minutes + 10 seconds).
     */
    connected: number;
    /**
     * Amount of disconnected restaurants (did not receive VPN update for a configured time - by default 5 minutes + 10 seconds).
     */
    disconnected: number;
    /**
     * List of restaurant\'s details.
     */
    data: Array<MonitoringRestaurantBasicInfo>;
}

