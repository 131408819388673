/***********************************************************************************
 * This file contains the common datas exchanged between the different applications
 ***********************************************************************************/

import { FIDELITY_PRIVILEGES } from "@bk/frontend-common";
import { MenuSize } from '@bk/fullstack-common';
import { BKItemBaseImpl } from "@bk/jscommondatas";
import { ProductPriceType } from "@bk/price-management-common";
import { IExcludedDiscountItems, POS_ROLE } from '@merim/utils';
import { MenuStepType } from "./models/menu-step-type";
import { IBKExchangedOrderContent } from "./myBKCommonAPI";
import { BKKingdomClientResultEnum, BKOrderState, MenuBaseSizeEnum } from './myBKSchemasValidator.index';
import { IPMSDisplayedProducts, PmsCurrentStatus } from "./pms";
import { VoiceChatEvent, VoiceChatOrder } from './voice-chat';


/**
 * @bkCassandra
 */
export interface ICsiUserSession {
	/** @s3dInteger */
	numClef: number;
	nomClef: string;
	/** @s3dInteger */
	codeManager: number;
	nomManager: string;
	/** @s3dInteger */
	codeVendeur: number;
	nomVendeur: string;
	libCaisse: string;
	/** @s3dInteger */
	numCaisse: number;
}

export interface IBKBigData {
	productFamilies: { [id: string]: IBKProductFamilyData };
	products: { [id: string]: IBKProductBase };
	allergens: { [id: string]: IBKAllergenData };
	menus: { [id: string]: IBKMenuBase };
	ingredientFamilies: { [id: string]: IBKIngredientFamilyData };
	ingredients: { [id: string]: IBKIngredientData };
	campaigns: { [id: string]: IBKCampaignData };
	medias: { [id: string]: IBKMediaData };
	mediasPlayList: { [id: string]: IBKMediaPlaylistData };
	discounts: { [id: string]: IBKDiscountData };
	options: { [id: string]: IBKItemOptionData };
	annotations: { [id: string]: IBKAnnotationData };
	suggestions: { [id: string]: IBKSuggestionData };
	productGroups: { [id: string]: IBKProductGroupData };
	ingredientFilters?: { [id: string]: IBKIngredientFilterData };
	nutritionNutrients?: { [id: string]: IBKNutritionNutrientData };
	games?: { [id: string]: IBKGameData };
	coupons?: { [id: string]: IBKCouponServiceData };
	menuStepProductGroups?: { [id: string]: IBKMenuStepProductGroupData };
}

export enum BKDiscountValidationRequiredEnum {
	NONE = 0,
	MANAGER = 1,
	FRANCHISE = 2,
}

/**
 * This export interface  defines a selection pattern
 * It helps selecting objects for menus and navigation screens
 * @bkCloneInterface
 * @bkCopyInterface
 * @bkCommonClass
 * @bkPHPInner
 * @bkBigDataExport
 */
export interface IBKSelectionPattern {
	/**
	 * Target of the pattern
	 * @s3dInteger
	 * @bkPHPValidation required|positiveinteger|intval
	 */
	target: number;
	/**
	 * Id of a specific familly if pertinent
	 * If 0, select all
	 * @s3dInteger
	 * @bkPHPValidation required|positiveornullinteger|intval
	 */
	family: number;
	/**
	 * Propagation of the selection for the familly
	 * 0 : Specified familly only
	 * 1 : Familly and its childs
	 * 2 : Childs of the selected familly ( not the familly itself )
	 * -1 : Familly and its ancestors
	 * -2 : Ancestors only ( not the familly itself )
	 * @s3dInteger
	 * @bkPHPValidation required|intval
	 */
	familyPropagation: number;
	/**
	 * Label describing the purpose of the pattern ( easier than auto compose )
	 */
	label: string;
	/**
	 * If true, all the withTags are required to be present to include an item
	 */
	withAllTags: boolean;
	/**
	 * Tags that an item MUST contain to be included
	 * @s3dPHPMultipleForeign
	 */
	withTags: number[];
	/**
	 * If true, all the withoutTags are required to be present to exclude an item
	 */
	withoutAllTags: boolean;
	/**
	 * Tags that an item MUST NOT contain in order to be included
	 * A single tag is enough to exclude an item
	 * @s3dPHPMultipleForeign
	 */
	withoutTags: number[];
	/**
	 * Ordered ids of the selected "items"
	 *
	 * The ID refers to a navigationScreen/kiosk in Global Manager.
	 * See http://192.168.100.253/#!/home/navigationScreen/kiosk/92 to a working example.
	 * It refers a Selection, which contains Tags. Products are then assigned to this Selection based on Tags.
	 * @s3dPHPMultipleForeign
	 */
	selected: number[];
	/**
	 * Ids of the items to exclude from the list
	 * @s3dPHPMultipleForeign
	 */
	excluded: number[];
	/**
	 * If true, this filter is used to exclude elements rather that include elements
	 */
	excludeFilter: boolean;
}

/**
 * This export interface  describes a campain
 * A campain is used in order to control the display and sell of items
 *
 * @bkCopyInterface
 * @bkCommonClass
 * @bkPHPModel
 */
export interface IBKCampaignData {
	/**
	 * @s3dPrimary
	 */
	id: number;
	/**
	 * Name of this campaign
	 * @bkPHPValidation  required|nonempty
	 */
	name: string;
	/**
	 * If true, this campaign is active by default
	 */
	enable: boolean;
	/**
	 * Start date and time in an unix format ( ms )
	 * @s3dInteger
	 */
	startDateTime: number;
	/**
	 * End date and time in an unix format ( ms )
	 * @s3dInteger
	 */
	endDateTime: number;
	/**
	 * If true, the campaign is hourly limited
	 */
	hourlyLimited?: boolean;
	/**
	 * Start hour in a business day
	 * Enabled if strictly lower than endHour
	 * @s3dInteger
	 */
	startHour: number;
	/**
	 * Start minute in a business day
	 * @s3dInteger
	 */
	startMinute: number;
	/**
	 * End hour in a business day
	 * Enabled if strictly greater than startHour
	 * @s3dInteger
	 */
	endHour: number;
	/**
	 * End minute in a business day
	 * @s3dInteger
	 */
	endMinute: number;
	/**
	 * Mask for the active day of the week
	 * @s3dInteger
	 */
	dayOfWeekMask?: number;
	/**
	 * Products referenced by this campaign
	 * @s3dPHPMultipleForeign
	 */
	productsInCampain: number[];
	/**
	 * Menus referenced by this campaign
	 * @s3dPHPMultipleForeign
	 */
	menusInCampain: number[];
	/**
	 * Ingredients referenced by this campaign
	 * @s3dPHPMultipleForeign
	 */
	ingredientsInCampain: number[];
	/**
	 * Medias referenced by this campaign
	 * @s3dPHPMultipleForeign
	 */
	mediasInCampain: number[];
	/**
	 * Medias playlists referenced by this campaign
	 * @s3dPHPMultipleForeign
	 */
	mediaPlaylistsInCampain: number[];
	/**
	 * Options referenced by this campaign
	 * @s3dPHPMultipleForeign
	 */
	optionsInCampain: number[];
	/**
	 * Discount referenced by this campaign
	 * @s3dPHPMultipleForeign
	 */
	discountInCampain: number[];
	/**
	 * Suggestion referenced by this campaign
	 * @s3dPHPMultipleForeign
	 */
	suggestionInCampaign: number[];
	/**
	 * Suggestion referenced by this campaign
	 * @s3dPHPMultipleForeign
	 */
	couponInCampaign?: number[];
	/**
	 * @s3dPHPSubModelArrayColumn
	 */
	kioskNavigationInCampaign?: BKCampaignNavigationMenuData[];
	/**
	 * @s3dPHPSubModelArrayColumn
	 */
	cashNavigationInCampaign?: BKCampaignNavigationMenuData[];
	/**
	 * @s3dPHPMultipleForeign
	 */
	gameInCampaign?: number[];
	/**
	 * If true, this campaign is a daypart
	 */
	daypart?: boolean;
	/**
	 * Selection for the dayparts
	 * @s3dPHPSubModelArrayColumn
	 */
	daypartSelection?: IBKCampaignDaypartSelection[];
	/**
	 * Prices to be replaced for products
	 * @s3dPHPSubModelArrayColumn
	 */
	daypartProductPrices?: IBKCampaignDaypartPrice[];
	/**
	 * Prices extra to be replaced for products
	 * @s3dPHPSubModelArrayColumn
	 */
	daypartProductExtraPrices?: IBKCampaignDaypartPrice[];
	/**
	 * Prices to be replaced for menus
	 * @s3dPHPSubModelArrayColumn
	 */
	daypartMenuPrices?: IBKCampaignDaypartPrice[];
	/**
	 * Prices to be replaced for menus
	 * @s3dPHPSubModelArrayColumn
	 */
	daypartIngredientPrices?: IBKCampaignDaypartPrice[];
	/**
	 * Mask for the sources of the order to display
	 * 0 Means all
	 * @s3dInteger
	 */
	sourceMask?: number;
	/**
	 * Planifications for the daypart or campaign
	 * @s3dPHPSubModelArrayColumn
	 */
	planifications?: IBKTimePlanification[];
	/**
	 * Fidelity information
	 * @s3dPHPSubModelColumn
	 */
	fidelity?: IBKFidelityFilterData;
}

/**
 * This export interface  contains parameters used to filter a campaign based on the fidelity program
 * @bkCopyInterface
 * @bkCommonClass
 * @bkPHPInner
 */
export interface IBKFidelityFilterData {
	/**
	 * Filter type
	 * @s3dInteger
	 */
	filter: number;
	/**
	 * First limit ( depends on the type )
	 * @s3dInteger
	 */
	limit1: number;
	/**
	 * Second limit ( depends on the type )
	 * @s3dInteger
	 */
	limit2: number;
}

/**
 * Time planification
 * @bkCopyInterface
 * @bkCommonClass
 * @bkPHPInner
 */
export interface IBKTimePlanification {
	/**
	 * If true, the campaign is hourly limited
	 */
	hourlyLimited?: boolean;
	/**
	 * Start hour in a business day
	 * Enabled if strictly lower than endHour
	 * @s3dInteger
	 */
	startHour: number;
	/**
	 * Start minute in a business day
	 * @s3dInteger
	 */
	startMinute: number;
	/**
	 * End hour in a business day
	 * Enabled if strictly greater than startHour
	 * @s3dInteger
	 */
	endHour: number;
	/**
	 * End minute in a business day
	 * @s3dInteger
	 */
	endMinute: number;
	/**
	 * Mask for the active day of the week
	 * @s3dInteger
	 */
	dayOfWeekMask?: number;
}

/**
 * @bkCopyInterface
 * @bkCommonClass
 * @bkPHPInner
 */
export interface BKCampaignNavigationMenuData {
	/**
	 * Priority of the menus
	 * @s3dInteger
	 */
	priority: number;
	/**
	 * navigation screen ids for the kiosk
	 * @s3dPHPMultipleForeign
	 */
	navigationScreenIds: number[];
	/**
	 * kiosk configuration ids
	 * @s3dPHPMultipleForeign
	 */
	navigationConfigurationIds: number[];
}

/**
 * @bkCopyInterface
 * @bkCommonClass
 * @bkPHPInner
 */
export interface IBKCampaignDaypartSelection {
	/**
	 * If true, show only the selected products
	 */
	showOnlySelected: boolean;
	/**
	 * Flag indicating that this discount is in percentage ( default ) or value
	 */
	priceAdjustInValue: boolean;
	/**
	 * Value of the discount
	 * @s3dDouble
	 */
	priceAdjust: number;
	/**
	 * Patterns of the products that can be included in this step
	 * @s3dPHPSubModelArrayColumn
	 */
	patterns: IBKSelectionPattern[];
}

/**
 * @bkCopyInterface
 * @bkCommonClass
 * @bkPHPInner
 */
export interface IBKCampaignDaypartPrice {
	/**
	 * Id of the element to override
	 * @s3dInteger
	 */
	id: number;
	/**
	 * Price to apply
	 * @s3dDouble
	 */
	price?: number;
	/**
	 * Price to apply
	 * @s3dDouble
	 */
	priceL?: number;
}

/*******************************************************************************
 * Products and Menu related elements
 ******************************************************************************/

/**
 * This simple class represents a simple tag
 * @bkCommonClass
 * @bkCopyInterface
 * @bkPHPModel
 */
export interface IBKTagData {
	/**
	 * Primary key
	 * @s3dPrimary
	 */
	id: number;
	/**
	 * Name of the family
	 * @bkPHPValidation  required|nonempty
	 */
	name: string;
	/**
	 * Description of the family
	 */
	description: string;
}

/**
 * This structure defines the crown price for a product
 * This price can only be used to buy products for employees
 * @bkCloneInterface
 * @bkCopyInterface
 * @bkPHPInner
 * @bkBigDataExport
 */
export interface IBKItemCrownPrice {
	/**
	 * If true, this product may be ordered internally
	 */
	crownEnable: boolean;
	/**
	 * Price without any taxe
	 * @s3dInteger
	 */
	crown: number;
	/**
	 * Id of the crown family
	 * @s3dInteger
	 */
	crownFamily: number;
}

/**
 * Item price
 * @bkCloneInterface
 * @bkCopyInterface
 * @bkPHPInner
 * @bkCassandra
 * @bkDocs
 */
export interface IBKItemPriceInfo {
	/**
	 * Price without any tax (VAT)
	 * @s3dDouble
	 * @bkPHPValidation required|positivedouble|doubleval
	 */
	ht: number;
	/**
	 * Amount of tax (VAT)
	 * @s3dDouble
	 * @bkPHPValidation required|positivedouble|doubleval
	 */
	tva: number;
	/**
	 * Price including tax (VAT)
	 * @s3dDouble
	 * @bkPHPValidation required|positivedouble|doubleval
	 */
	ttc: number;
	/**
	 * Percentage of the tax (VAT)
	 * @s3dDouble
	 * @bkPHPValidation required|positivedouble|doubleval
	 */
	pc: number;
	/**
	 * Percentage of the tax (VAT) in case of take over (TakeAway)
	 * @s3dDouble
	 * @bkPHPValidation required|positivedouble|doubleval
	 */
	pcto?: number;
	/**
	 * Price without any tax (VAT) in case of take over (TakeAway)
	 * @s3dDouble
	 * @bkPHPValidation required|positivedouble|doubleval
	 */
	htto?: number;
}

/**
 * External reference of an item in the supply chain and in the BKC world
 * @bkCloneInterface
 * @bkPHPInner
 * @bkCassandra
 */
export interface IBKItemReference {
	/**
	 * PLU of the item
	 */
	plu: string;
	/**
	 * BKPN of the item
	 */
	bkpn: string;
	/**
	 * Name of this item for the BKC world
	 */
	bkcName: string;
}

/*******************************************************************************
 * Ingredients
 ******************************************************************************/

/**
 * @bkCommonClass
 * @bkCopyInterface
 * @bkPHPModel
 */
export interface IBKIngredientFamilyData {
	/**
	 * @minimum 0
	 * @exclusiveMinimum
	 * @s3dPrimary
	 */
	id: number;
	/**
	 * Name of this ingredient
	 * @s3dJavaProperties
	 * @s3dPHPMultilingueColumn
	 */
	name: { [lang: string]: string };
	_name?: { [lang: string]: string };
	/**
	 * Name of this ingredient
	 * @s3dJavaProperties
	 * @s3dPHPMultilingueColumn
	 */
	description: { [lang: string]: string };
	_description?: { [lang: string]: string };
}

/**
 * @bkCommonClass
 * @bkCopyInterface
 * @bkPHPModel
 */
export interface IBKIngredientData {
	/**
	 * @minimum 0
	 * @exclusiveMinimum
	 * @s3dPrimary
	 */
	id: number;
	/**
	 * System name
	 * @bkPHPValidation  required|nonempty
	 */
	sysName: string;
	/**
	 * Short name used for display
	 * @bkPHPValidation  required|nonempty
	 */
	shortName: string;
	/**
	 * Whether or not the ingredient is active (can be shown in devices and used within the products).
	 * Used for "soft delete".
	 */
	active?: boolean;
	/**
	 * Parent Family of this ingredient
	 * @s3dInteger
	 * @s3dPHPForeignKeyColumn  \BKModels\BKIngredientFamily
	 */
	_familyId: number;
	/**
	 * Name of this ingredient
	 * @s3dJavaProperties
	 * @s3dPHPMultilingueColumn
	 */
	_name: { [lang: string]: string };
	/**
	 * Description of this ingredient
	 * @s3dJavaProperties
	 * @s3dPHPMultilingueColumn
	 */
	_description: { [lang: string]: string };
	/**
	 * @uniqueItems
	 * @s3dPHPMultipleForeign
	 */
	xtag: number[];
	/**
	 * @uniqueItems
	 * @s3dPHPMultipleForeign
	 */
	xallergen: number[];
	/**
	 * Array containing the ids of the allergens associated with this item with just traces
	 * @s3dPHPMultipleForeign
	 */
	_xtraces?: number[];
	/**
	 * Reference of the ingredient
	 * @s3dPHPSubModelColumn
	 */
	refI: IBKItemReference;
	/**
	 * Flag indicating that a kiosk image is available
	 * @s3dPHPIgnore
	 */
	_hasImageKiosk?: boolean;
	/**
	 * Flag indicating that a kiosk image is available
	 * @s3dPHPIgnore
	 */
	_hasImageKiosk2?: boolean;
	/**
	 * Flag indicating that an alternative kiosk image is available
	 * @s3dPHPIgnore
	 */
	_hasImageAlt?: boolean;
	/**
	 * Flag indicating that an on image is available
	 * @s3dPHPIgnore
	 */
	_hasImageOn?: boolean;
	/**
	 * Flag indicating that an off image is available
	 * @s3dPHPIgnore
	 */
	_hasImageOff?: boolean;
	/**
	 * If true, this ingredient has a price
	 */
	withPrice: boolean;
	/**
	 * If this specific ingredient has a price it is contained here
	 * @s3dPHPIgnore
	 */
	//price?:IBKItemPriceInfo;
	/**
	 * If this specific ingredient has a price it is contained here
	 * @s3dPHPIgnore
	 * @s3dDouble
	 */
	priceTTC: number;
	/**
	 * If true, this item is temporary and must be referenced by an active campain to be enabled
	 * If false, this item is permanent
	 */
	campaignItem: boolean;
	/**
	 * Id of the group of product this item belongs to
	 * @s3dInteger
	 */
	_groupId?: number;
	/**
	 * Flag indicating if this item is in an active campaign
	 * @s3dPHPIgnore
	 */
	inActiveCampaign?: boolean;

	/**
	 * New price definitions for price management.
	 * Type is GlobalPriceDefinitions, but it creates conflicts between legacy bk-jscommondatas and new libraries
	 * if the schemas are regenerated. Better to cast it in where used.
	 */
	globalPriceDefinitions?: GlobalPriceDefinition_ForLegacy[];
	/**
	 * New price definitions for price management.
	 * Type is vatValues:VATValue, but it creates conflicts between legacy bk-jscommondatas and new libraries
	 * if the schemas are regenerated. Better to cast it in where used.
	 */
	vatValues?: VatValues_ForLegacy[];
}

/**
 * Corresponds to global-price-definition.ts
 *
 * Needed for code-generation via gulp. There is a legacy generator gulp.genAll, which generates file BKCommonCopyUtilities.generated.ts.
 */
export interface GlobalPriceDefinition_ForLegacy {
	/**
	 * Corresponds to enum SalesChannelOption.ts
	 */
	salesChannelOption: string;
	isDisabled: boolean;
	price: number;
	/**
	 * Corresponds to enums MenuSize.ts or ProductPriceType.ts
	 */
	rowIdentifier: string;
}

/**
 * Corresponds to vat-values.ts
 *
 * Needed for code-generation via gulp. There is a legacy generator gulp.genAll, which generates file BKCommonCopyUtilities.generated.ts
 */
export interface VatValues_ForLegacy {
	/** Either SalesChannelOption.EatIn or SalesChannelOption.TakeAway */
	tvaName: string;
	/** "Normal", "Alcohol", "Empty" etc... */
	value: string;
	percent: number;
}

/**
 * This export interface  describes the relationship between ingredients and products
 * @bkCommonClass
 * @bkCopyInterface
 * @bkCloneInterface
 * @bkPHPInner
 * @bkBigDataExport
 */
export interface IBKProductIngredientData {
	/**
	 * Ingredient selection pattern
	 * @s3dPHPSubModelArrayColumn
	 */
	ingredientPattern: IBKSelectionPattern[];
	/**
	 * @s3dInteger
	 * @s3dPHPForeignKeyColumn  \BKModels\BKIngredient
	 */
	ingredientDefault: number;
	/**
	 * Flag indicating that this ingredient can be modified or not
	 */
	canModify: boolean;
	/**
	 * Initial quantity
	 * @s3dInteger
	 */
	initialQty: number;
	/**
	 * Minimal quantity
	 * @s3dInteger
	 */
	minQty: number;
	/**
	 * Maximal quantity
	 * @s3dInteger
	 */
	maxQty: number;
	/**
	 * Amount type for this ingredient
	 * @s3dInteger
	 */
	amountType: number;
	/**
	 * Initial amount
	 * @s3dInteger
	 */
	initialAmount: number;
	/**
	 * Sysname mainly used for debug purpose
	 */
	sysName: string;

	/**
	 * Only one item can be selected if this parameter is set to true
	 */
	displayQtySelector?: boolean;

	/**
	 * @s3dInteger
	 * @s3dPHPForeignKeyColumn  \BKModels\BKMedia
	 */
	mediaId?: number;
}

/**
 * @bkCassandra
 */
export interface IBKProductIngredientSelectedData {
	/**
	 * Id of the selected ingredient.
	 *
	 * BEWARE!!! If the Ingredient is de-selected by the user, then the Id is set to -1.
	 * So if you need to read the real Ingredient Id, then read it from the '.initial' property
	 * @s3dPHPForeignKeyColumn  \BKModels\BKIngredient
	 * @s3dInteger
	 */
	selected: number;
	/**
	 * Initial selected ingredient.
	 *
	 * This value stays unchanged, when user de-selects the ingredient
	 * @s3dInteger
	 */
	initial: number;
	/**
	 * Flag indicating that the quantity differs from the original.
	 * (Seems not needed, because there is .qty and .initQty properties, so this is duplicated information)
	 */
	modified: boolean;
	/**
	 * Amount.
	 *
	 * Note: Seems to be always 0
	 * @s3dInteger
	 */
	amount: number;
	/**
	 * Initial amount
	 *
	 * Note: Seems to be always 0
	 * @s3dInteger
	 */
	initAmount: number;
	/**
	 * Quantity of the ingredient
	 * @s3dInteger
	 */
	qty: number;
	/**
	 * Initial quantity
	 * @s3dInteger
	 */
	initQty: number;
	/**
	 * Price of the ingredient
	 * @s3dDouble
	 */
	priceTTC: number;
	/**
	 * Sysname, mainly for debug purpose
	 */
	sysName: string;
	/**
	 * Line number of this item
	 * This is a sequential number that is incremented each time something is added to the order
	 * @s3dInteger
	 */
	line: number;
	/**
	 * UUID of the line
	 * @bkCassandraType uuid
	 * @bkCassandraUUIDPatch
	 */
	lineuuid: string;
	/**
	 * UUID of the line
	 * @bkCassandraType uuid
	 * @bkCassandraUUIDPatch
	 */
	lineuuid2?: string;
	/**
	 * @s3dPHPSubModelColumn
	 */
	price?: IBKItemPriceInfo;
	/**
	 * @s3dPHPSubModelColumn
	 */
	ref?: IBKItemReference;
	timestamp?: number;
	csiUserSession?: ICsiUserSession;
	/**
	 * @s3dPHPIgnore
	 * @s3dPHPMultipleForeign
	 * @bkCassandraType list<int>
	 */
	selectedIngredientIds?: number[];

	/**
	 * @s3dPHPIgnore
	 * @s3dPHPMultipleForeign
	 * @bkCassandraType list<ISelectedIngredients>
	 */
	selectedIngredients?: ISelectedIngredients[];
}

/**
 * This class represents an ingredient filter for the menus and the products
 * @bkCommonClass
 * @bkCopyInterface
 * @bkPHPModel
 */
export interface IBKIngredientFilterData {
	/**
	 * Id of the item reference in the db
	 * @s3dPrimary
	 */
	id: number;
	/**
	 * The system name
	 */
	sysName: string;
	/**
	 * Name of this ingredient filter
	 * @s3dJavaProperties
	 * @s3dPHPMultilingueColumn
	 */
	_name: { [lang: string]: string };
	/**
	 * Label of this ingredient filter
	 * @s3dJavaProperties
	 * @s3dPHPMultilingueColumn
	 */
	_label: { [lang: string]: string };
	/**
	 * Flag indicating that a this filter is an excluding one
	 */
	excludeFilter: boolean;
	/**
	 * Flag indicating where this filter should be displayed
	 * @s3dInteger
	 */
	locationMask: number;
	/**
	 * Simple index
	 * @s3dInteger
	 */
	sortIndex: number;
	/**
	 * Ingredient selection pattern
	 * @s3dPHPSubModelArrayColumn
	 */
	ingredientPattern: IBKSelectionPattern[];
	/**
	 * Product selection pattern
	 * @s3dPHPSubModelArrayColumn
	 */
	productPattern: IBKSelectionPattern[];
	/**
	 * Navigation screen selection pattern
	 * @s3dPHPSubModelArrayColumn
	 */
	navScreenPattern?: IBKSelectionPattern[];
	/**
	 * @s3dPHPIgnore
	 */
	_hasImageMain?: boolean;
}

/*******************************************************************************
 * Products and Menus
 ******************************************************************************/

/**
 * This export interface  is used to describe a published item
 * It can either represent a product or a menu
 * It contains just the informations used for publication purpose (to BKC mainly)
 * Additionnal informations may be retrieved used the primary key
 * Fields starting with a leading underscore ( _ ) may be removed on serialization
 * @bkCopyInterface
 * @bkCommonClass
 */
export interface IBKItemBase {
	/**
	 * Id of the item reference in the db
	 * @s3dPrimary
	 * @s3dInteger
	 */
	id: number;
	/**
	 * System name used in kds and orb
	 * @bkPHPValidation  required|nonempty
	 */
	sysName: string;
	/**
	 * Short name used for display
	 * @bkPHPValidation  required|nonempty
	 */
	shortName: string;
	/**
	 * Alt name used for the ticket
	 */
	_altName?: string;
	/**
	 * Array containing the ids of the tags associated with this item
	 * @s3dPHPMultipleForeign
	 * @bkCassandraType list<int>
	 */
	xtag: number[];
	/**
	 * If true, this item is temporary and must be referenced by an active campain to be enabled
	 * If false, this item is permanent
	 */
	campaignItem: boolean;
	/**
	 * Flag indicating if this item is available or not
	 * @s3dPHPIgnore
	 */
	available?: boolean;
	/**
	 * Flag indicating if this item is in an active campaign
	 * @s3dPHPIgnore
	 */
	inActiveCampaign?: boolean;
	/**
	 * If true this item is not available on the kiosk
	 */
	noKiosk: boolean;
	/**
	 * If true this item is not available on the web
	 */
	noWeb: boolean;
	/**
	 * If true this item is not available on the orbp / print-server
	 */
	noPreparation?: boolean;
	/**
	 * If true this is a non food item
	 */
	nonFood?: boolean;
	/**
	 * Flag indicating if this item is exclusive to the fidelity program
	 */
	exclusiveToFidelity?: boolean;
	/**
	 * If true, the product or menu require the validation of a manager before being added to an order
	 */
	requireValidation?: boolean;
	/**
	 * Weight of the item
	 * @s3dDouble
	 */
	_weight?: number;
}

/**
 * @bkCopyInterface
 * @bkCommonClass
 */
export interface IBKItemWithTextBase extends IBKItemBase {
	/**
	 * Names of the published item in different languages
	 * Languages are the key
	 * @s3dJavaProperties
	 * @s3dPHPMultilingueColumn
	 */
	_name: { [lang: string]: string };
	/**
	 * Descriptions of the published item in different languages
	 * Languages are the key
	 * @s3dJavaProperties
	 * @s3dPHPMultilingueColumn
	 */
	_description: { [lang: string]: string };
	/**
	 * Index in the POS
	 * @s3dInteger
	 */
	_indexPOS: number;
	/**
	 * Index of the Kiosk
	 * @s3dInteger
	 */
	_indexKiosk: number;
}

/**
 * This class represents the description of an allergen
 * @bkCommonClass
 * @bkCopyInterface
 * @bkPHPModel
 */
export interface IBKAllergenData {
	/**
	 * @minimum 0
	 * @exclusiveMinimum
	 * @s3dPrimary
	 */
	id: number;
	/**
	 * Names of the published item in different languages
	 * Languages are the key
	 * @s3dJavaProperties
	 * @s3dPHPMultilingueColumn
	 */
	name: { [lang: string]: string };
	/**
	 * Descriptions of the published item in different languages
	 * Languages are the key
	 * @s3dJavaProperties
	 * @s3dPHPMultilingueColumn
	 */
	description: { [lang: string]: string };


	/**
	 * Names of the published item in different languages
	 * Languages are the key
	 * @s3dJavaProperties
	 * @s3dPHPMultilingueColumn
	 */
	_name?: { [lang: string]: string };
	/**
	 * Descriptions of the published item in different languages
	 * Languages are the key
	 * @s3dJavaProperties
	 * @s3dPHPMultilingueColumn
	 */

	_description?: { [lang: string]: string };
	/**
	 * @s3dPHPIgnore
	 */
	_hasImageMain?: boolean;
	/**
	 * Alternative image
	 * @s3dPHPIgnore
	 */
	_hasImageAlt?: boolean;
}

/**
 * This class represents the description of a nutrient
 * @bkCommonClass
 * @bkCopyInterface
 * @bkPHPModel
 */
export interface IBKNutritionNutrientData {
	/**
	 * @minimum 0
	 * @exclusiveMinimum
	 * @s3dPrimary
	 */
	id: number;
	/**
	 * System name
	 * @bkPHPValidation  required|nonempty
	 */
	sysName: string;
	/**
	 * Description of the nutrient
	 */
	description: string;
	/**
	 * Names of the nutrient in different languages
	 * Languages are the key
	 * @s3dJavaProperties
	 * @s3dPHPMultilingueColumn
	 */
	_name: { [lang: string]: string };
	/**
	 * Foreign key to the parent nutrient or 0 if none
	 * @s3dInteger
	 */
	parentNutrientId: number;
	/**
	 * Simple index
	 * @s3dInteger
	 */
	sortIndex: number;
	/**
	 * Unit of the values associated to the nutrient
	 * @s3dInteger
	 */
	unit: number; // BKNutritionUnitEnum
}

/**
 * This export interface  describes the relationship between nutrients and products
 * @bkCommonClass
 * @bkCopyInterface
 * @bkCloneInterface
 * @bkPHPInner
 */
export interface IBKProductNutritionData {
	/**
	 * Foreign key to the nutrient
	 * @s3dInteger
	 * @s3dPHPForeignKeyColumn  \BKModels\BKNutritionNutrient
	 */
	nutrientId: number; // IBKNutritionNutrientData id
	/**
	 * Value 1
	 */
	value1: string;
	/**
	 * Value 1
	 */
	value2: string;
}

/**
 * @bkCommonClass
 * @bkCopyInterface
 * @bkPHPModel
 */
export interface IBKProductFamilyData {
	/**
	 * Primary key
	 * @s3dPrimary
	 */
	id: number;
	name: string;
	description: string;
	/**
	 * Names of the family in different languages
	 * Languages are the key
	 * @s3dJavaProperties
	 * @s3dPHPMultilingueColumn
	 */
	_name: { [lang: string]: string };
	/**
	 * Descriptions of the family in different languages
	 * Languages are the key
	 * @s3dJavaProperties
	 * @s3dPHPMultilingueColumn
	 */
	_description: { [lang: string]: string };
	/**
	 * Id of the parent family of null if none
	 * @s3dInteger
	 */
	parentFamilyId: number;
	/**
	 * Id of the picto displayed for this family
	 * @s3dInteger
	 */
	_picto?: number;
	/**
	 * Flag indicating that this item should be include in load balancing
	 */
	includeInLoadBalancing?: boolean;
	/**
	 * Flag indicating that this item should have its vat value aligned with other items in menu
	 */
	includeInVatAlignment?: boolean;
}

/**
 * @bkCommonClass
 * @bkCopyInterface
 * @bkPHPModel
 */
export interface IBKProductGroupData {
	/**
	 * Primary key
	 * @s3dPrimary
	 */
	id: number;
	/**
	 * Name of the family
	 * @bkPHPValidation required|nonempty
	 */
	name: string;
	/**
	 * Description of the family
	 */
	description: string;
}

/**
 * This export interface  defines the base content of a product
 * @bkCommonClass
 * @bkCopyInterface
 * @bkCloneInterface
 * @bkPHPModel
 * @bkBigDataExport
 */
export interface IBKProductBase extends IBKItemWithTextBase {
	/**
	 * Foreign key to the parent familly
	 * @s3dInteger
	 * @s3dPHPForeignKeyColumn  \BKModels\BKProductFamily
	 */
	_familyId: number;
	/**
	 * Id of the group of product this item belongs to
	 * @s3dInteger
	 */
	_groupId?: number;
	/**
	 * Foreign key to the product of upper size
	 * @s3dInteger
	 */
	_upperSizeProduct: number;
	/**
	 * Array containing the ids of the allergens associated with this item
	 * @s3dPHPMultipleForeign
	 */
	_xallergen: number[];
	/**
	 * Flag indicating that this item can be configured ( personalization )
	 */
	_configurable: boolean;
	/**
	 * If true, this item has to be displayed in kitchen screens
	 */
	_displayInKitchen: boolean;
	/**
	 * Price for the M ( normal ) size
	 * @s3dPHPIgnore
	 */
	_priceM?: IBKItemPriceInfo;
	/**
	 * Reference of the selected menu ( regarding the selected size )
	 * @s3dPHPSubModelColumn
	 */
	_refM: IBKItemReference;
	/**
	 * Crown informations used in order
	 * @s3dPHPSubModelColumn
	 */
	_crown: IBKItemCrownPrice;
	/**
	 * Flag indicating that this product can be delivered later
	 */
	_canDeliverLater: boolean;
	/**
	 * List of ingredients
	 * @s3dPHPSubModelArrayColumn
	 */
	_ingredients: IBKProductIngredientData[];
	/**
	 * @s3dPHPIgnore
	 */
	_hasImageMain?: boolean;
	/**
	 * @s3dPHPIgnore
	 */
	_hasImageSys?: boolean;
	/**
	 * @s3dPHPIgnore
	 */
	_hasKioskImage2?: boolean;
	/**
	 * Flag indicating is this product has ice
	 */
	_hasIce: boolean;
	/**
	 * If true, do not propose the upsize
	 */
	_preventUpsize?: boolean;
	/**
	 * If true, do not suggest menu inclusion
	 */
	_preventMenuSuggestion?: boolean;
	/**
	 * Array containing the ids of the allergens associated with this item with just traces
	 * @s3dPHPMultipleForeign
	 */
	_xtraces?: number[];
	/**
	 * Mask for flag
	 * @s3dInteger
	 */
	_flagMask?: number;
	/**
	 * Price as extra in a menu
	 * @s3dPHPIgnore
	 * @s3dDouble
	 */
	_priceExtraMenuTTC?: number;
	/**
	 * Price as suggestion in a menu
	 * @s3dPHPIgnore
	 * @s3dDouble
	 */
	_priceSuggestionMenuTTC?: number;
	/**
	 * Id of a linked product that will be disabled along with this one
	 * @s3dInteger
	 */
	_linkedProduct?: number;
	/**
	 * If true, this product is for students
	 */
	_forStudents?: boolean;

	_requireProofOfAdulthood?: boolean;
	/**
	 * Nutrition header type
	 * @s3dInteger
	 */
	_nutritionHeader1?: number; // BKNutritionHeaderTypeEnum
	/**
	 * Nutrition header type
	 * @s3dInteger
	 */
	_nutritionHeader2?: number; // BKNutritionHeaderTypeEnum
	/**
	 * List of nutrition timeBasedPredictions
	 * @s3dPHPSubModelArrayColumn
	 */
	_nutrition?: IBKProductNutritionData[];

	_excludeFromGlobalDiscount?: boolean;

	/**
	 * New price definitions for price management.
	 * Type is GlobalPriceDefinitions, but it creates conflicts between legacy bk-jscommondatas and new libraries
	 * if the schemas are regenerated. Better to cast it in where used.
	 */
	globalPriceDefinitions?: GlobalPriceDefinition_ForLegacy[];
	/**
	 * New price definitions for price management.
	 * Type is vatValues:VATValue, but it creates conflicts between legacy bk-jscommondatas and new libraries
	 * if the schemas are regenerated. Better to cast it in where used.
	 */
	vatValues?: VatValues_ForLegacy[];
	/**
	 * Whether or not the product/menu is active.
	 * Used for "soft delete".
	 */
	active?: boolean;
	/**
	 * Color code for product sheet
	 */
	codeColor?: string;

	/**
	 * Flag indicating if the product may be included in XL menu.
	 */
	_eligibleForMenuXL?: boolean;

	isComposable?: boolean;

	/**
	 * If true this item is not seen on ORBp & print-server
	 */
	noPreparation?: boolean;
}

/**
 * This export interface  defines a step in a menu
 * @bkCloneInterface
 * @bkPHPInner
 * @bkBigDataExport
 */
export interface IBKMenuStepData {
	/**
	 * HD only
	 * @s3dIgnoreInCopy
	 * @s3dPHPIgnore
	 * @s3dInteger
	 */
	id?: number;
	/**
	 * Name displayed on the step
	 * Languages are the key
	 * @s3dJavaProperties
	 * @s3dPHPMultilingueColumn
	 */
	_name: { [lang: string]: string };
	/**
	 * Patterns of the products that can be included in this step
	 * @s3dPHPSubModelArrayColumn
	 */
	_patterns: IBKSelectionPattern[];
	/**
	 * Foreign key to the default selected product
	 * @s3dInteger
	 * @s3dPHPForeignKeyColumn  \BKModels\BKProduct
	 */
	_defaultSelection: number;
	/**
	 * Type of the step ( for message display in Kiosk )
	 * Corresponds to numeric enum BKMenuStepTypeEnum defined in myBKCommonEnum.ts
	 * @s3dInteger
	 * @bkPHPDefaultValue 0
	 * @bkPHPValidation positiveornullinteger
	 */
	_stepType?: number;
}

/**
 * @bkCommonClass
 * @bkCopyInterface
 * @bkCloneInterface
 * @bkPHPModel
 * @bkBigDataExport
 */
export interface IBKMenuBase extends IBKItemWithTextBase {
	/**
	 * Flag used to indicate if this menu is for kids
	 * It may be used to alter the display on kiosks
	 */
	_kidsMenu: boolean;
	/**
	 * Flag used to indicate menu type
	 * It may be used to alter the display on ORBps
	 * @s3dInteger
	 */
	_boMenuType: number;
	/**
	 * If true, this menu is a bundle
	 */
	_isBundle: boolean;
	/**
	 * If true, the menu exists in normal and large size
	 */
	_existsInL: boolean;
	/**
	 * If true, the menu exists in extra large size (large size must be enabled as well)
	 */
	_existsInXL?: boolean;
	/**
	 * Price for the M ( normal ) size
	 * @s3dPHPIgnore
	 */
	_priceM?: IBKItemPriceInfo;
	/**
	 * Reference of the selected menu ( regarding the selected size )
	 * @s3dPHPSubModelColumn
	 */
	_refM: IBKItemReference;
	/**
	 * Price for the L ( large ) size
	 * @s3dPHPIgnore
	 */
	_priceL?: IBKItemPriceInfo;
	/**
	 * Reference of the selected menu ( regarding the selected size )
	 * @s3dPHPSubModelColumn
	 */
	_refL: IBKItemReference;
	/**
	 * Reference of the selected menu ( regarding the selected size )
	 * @s3dPHPSubModelColumn
	 */
	_refXL?: IBKItemReference;
	/**
	 * Steps of the menu
	 * @s3dPHPSubModelArrayColumn
	 */
	_steps: IBKMenuStepData[];
	/**
	 * @s3dPHPIgnore
	 */
	_hasImageMain?: boolean;
	/**
	 * @s3dPHPIgnore
	 */
	_hasImageSys?: boolean;
	/**
	 * Id of the group of product this item belongs to
	 * @s3dInteger
	 */
	_groupId?: number;
	/**
	 * Id of the group of product this item belongs to ( for large )
	 * @s3dInteger
	 */
	_groupIdL?: number;
	/**
	 * Id of the group of product this item belongs to ( for extra large )
	 * @s3dInteger
	 */
	_groupIdXL?: number;
	/**
	 * If true, this product is for students
	 */
	_forStudents?: boolean;

	_requireProofOfAdulthood?: boolean;

	_excludeFromGlobalDiscount?: boolean;

	_baseSize?: MenuBaseSizeEnum;

	/**
	 * New price definitions for price management.
	 * Type is GlobalPriceDefinitions, but it creates conflicts between legacy bk-jscommondatas and new libraries
	 * if the schemas are regenerated. Better to cast it in where used.
	 */
	globalPriceDefinitions?: GlobalPriceDefinition_ForLegacy[];
	/**
	 * New price definitions for price management.
	 * Type is vatValues:VATValue, but it creates conflicts between legacy bk-jscommondatas and new libraries
	 * if the schemas are regenerated. Better to cast it in where used.
	 */
	vatValues?: VatValues_ForLegacy[];
	/**
	 * Whether or not the menu is active.
	 * Used for "soft delete"
	 */
	active?: boolean;
}

/**
 * @bkCommonClass
 * @bkCopyInterface
 * @bkPHPModel
 */
export interface IBKMenuStepProductGroupData {
	/**
	 * @minimum 0
	 * @exclusiveMinimum
	 * @s3dPrimary
	 */
	id: number;
	/**
	 * Name displayed on the step
	 * Languages are the key
	 * @s3dJavaProperties
	 * @s3dPHPMultilingueColumn
	 */
	_name: { [lang: string]: string };
	/**
	 * Description of this ingredient
	 * @s3dJavaProperties
	 * @s3dPHPMultilingueColumn
	 */
	_description: { [lang: string]: string };
	/**
	 * @s3dPHPIgnore
	 */
	_hasImageMain?: boolean;
	/**
	 * @s3dPHPIgnore
	 */
	_hasImageSys?: boolean;
	/**
	 * Simple index
	 * @s3dInteger
	 */
	sortIndex?: number;
	/**
	 * Pattern selecting to which menu this item is applicable to
	 * @s3dPHPSubModelArrayColumn
	 */
	applicableToMenuPattern?: IBKSelectionPattern[];
	/**
	 * Configuration flags
	 * Unused for the moment, may be set to 0
	 * @s3dInteger
	 */
	flags: number;
	/**
	 * Products to display in the group
	 * @s3dPHPSubModelArrayColumn
	 */
	products: IBKSelectionPattern[];
}

/**
 * @bkCommonClass
 * @bkCopyInterface
 * @bkCassandra
 * @bkDocs
 */
export interface IBKSubItemInOrderBase extends IBKItemBase {
	/**
	 * Line number of this item
	 * This is a sequential number that is incremented each time something is added to the order
	 * @s3dInteger
	 */
	line: number;
	/**
	 * UUID of the line
	 * @bkCassandraType uuid
	 * @bkCassandraUUIDPatch
	 */
	lineuuid: string;
	/**
	 * Timestamp when this item has been added to the order
	 * @s3dLong
	 */
	timestamp: number;
	/**
	 * Price regarding the size, the recept and the discounts
	 * @s3dPHPSubModelColumn
	 */
	price: IBKItemPriceInfo;
	/**
	 * Original price of the item
	 */
	aLaCartePrice: IBKItemPriceInfo;
	/**
	 * Price for the extra(s)
	 */
	extraPrice: IBKItemPriceInfo;
	/**
	 * Copy of _priceExtraMenuTTC from bigDatas. Used typicaly for multipayment when price at pos may be different that price at kiosk
	 * @s3dDouble
	 */
	priceExtraMenuTTC?: number;
	/**
	 * Copy of _priceSuggestionMenuTTC from bigDatas. Used typicaly for multipayment when price at pos may be different that price at kiosk
	 * @s3dDouble
	 */
	priceSuggestionMenuTTC?: number;
	/**
	 * Reference of the selected menu ( regarding the selected size )
	 * @s3dPHPSubModelColumn
	 */
	ref: IBKItemReference;
	/**
	 * Quantity
	 * @s3dInteger
	 */
	qty: number;
	/**
	 * Product Only, selected ingredients in the product
	 * May be empty if the recipe has not changed
	 */
	recipe?: IBKProductIngredientSelectedData[];
	/**
	 * Product Only. Removed ingredient modifiers before kitchen screen
	 */
	removedRecipeModificatorsBeforeKitchenScreen?: IBKProductIngredientSelectedData[];
	/**
	 * Product Only. Removed ingredient modifiers after kitchen screen
	 */
	removedRecipeModificatorsAfterKitchenScreen?: IBKProductIngredientSelectedData[];
	/**
	 * Product Only. Removed ingredient modifiers after subtotal
	 */
	removedRecipeModificatorsAfterSubtotal?: IBKProductIngredientSelectedData[];
	/**
	 * Product only
	 * If true the product must be delivered later (can be picked up later for C&C)
	 */
	later?: boolean;
	/**
	 * Flag indicating if this product requires no ice
	 */
	noIce?: boolean;
	/**
	 * Name of the product group it belongs to
	 */
	productGroupName?: string;
	/**
	 * Discount that apply to this item
	 * Moved from IBKItemInOrderBase
	 */
	itemAppliedDiscount?: IBKDiscountInOrderData[];
	/**
	 * Discount that require this item but applies to another one
	 * List of UUIDs
	 * Moved from IBKItemInOrderBase
	 */
	itemInvolvedDiscount?: string[];

	user?: IBKUserData;
	manager?: IBKUserData;
	machine?: IBKMachine;
	name?: Record<string, string>;
}

/**
 * @bkCommonClass
 * @bkCopyInterface
 * @bkCassandra
 * @bkDocs
 */
export interface IBKSubItemInOrderBaseWithFreeItems extends IBKSubItemInOrderBase {
	/**
	 * Free related items
	 * @s3dIgnoreInCopy
	 */
	freeItems?: IBKSubItemInOrderBase[];
	name?: Record<string, string>;
	priceType?: ProductPriceType;
}

/**
 * @bkCopyInterface
 * @bkCassandra
 * @bkDocs
 */
export interface IBKItemInOrderBase extends /*IBKSubItemInOrderBase*/ IBKSubItemInOrderBaseWithFreeItems {
	/**
	 * Menu Only
	 * If true, the selected menu is large ( not normal )
	 */
	large?: boolean;
	/**
	 * Menu Only
	 * If true, the selected menu is in extra large size
	 */
	xl?: boolean;

	/**
	 * Menu only, new definition of size to replace 'large' and 'xl'.
	 * Already utilized for weborders.
	 */
	size?: string;
	/**
	 * Menu Only, selected items in the menu
	 * Optional
	 * @s3dIgnoreInCopy
	 */
	selection?: IBKSubItemInOrderBaseWithFreeItems[]; /*IBKSubItemInOrderBase[]*/

	/**
	 * Free related items
	 * Moved to IBKSubItemInOrderBase
	 * @__s3dIgnoreInCopy
	 */
	//freeItems: IBKSubItemInOrderBase[];
	/**
	 * Discount that apply to this item
	 * Moved to IBKSubItemInOrderBase
	 */
	//itemAppliedDiscount: IBKDiscountInOrderData[];
	/**
	 * Discount that require this item but applies to another one
	 * List of UUIDs
	 * Moved to IBKSubItemInOrderBase
	 */
	//itemInvolvedDiscount: string[];
	/**
	 * If defined, contains the UUID of the associated game
	 */
	relatedGame?: string;
	/**
	 * AssemblyAreaId
	 * used to regroup and sort products while printing or showing order in orbp
	 * @s3dInteger
	 */
	assemblyAreaId?: number;

	/**
	 * Kids menu attributes
	 */
	_kidsMenu?: boolean;
	_boMenuType?: number;

	name?: Record<string, string>;
	familyId?: number;
	priceType?: ProductPriceType;
	isComposable?: boolean;

	/**
	 * Flag indicating if the price of the item may be flexible (may not equal to price in
	 * availability or BigData). Typical for fees of weborders.
	 *
	 * Such item's price should not be validated against availability or BigData.
	 **/
	flexiblePrice?: boolean;
}

/**
 * @bkCommonClass
 * @bkCopyInterface
 * @bkCloneInterface
 * @bkCassandra
 * @bkDocs
 */
export interface IBKOrderEventData {
	/**
	 * Timestamp of the event ( in ms relative to the creation date of the order )
	 * @s3dInteger
	 */
	timestamp: number;
	/**
	 * @s3dInteger
	 * Matches enum BKOrderEventType, but can not be of type BKOrderEventType, because ABProd setup did not allow it.
	 */
	eventType: number;
	/**
	 * Argument of the event
	 */
	arg: string;
}

/**
 * Published order base
 * @bkCopyInterface
 * @bkCommonClass
 * @bkCassandra
 * @bkDocs
 */
export interface IBKPublishedOrderBase {
	/**
	 * Machine on wich this order has been processed
	 */
	machine: IBKMachine;
	/**
	 * UUID of the order
	 * @bkCassandraType uuid
	 * @bkCassandraUUIDPatch
	 */
	orderUUID: string;
	/**
	 * Content of the order
	 * @s3dIgnoreInCopy
	 */
	orderContent: IBKItemInOrderBase[];
	/**
	 * Items which user decided to remove from basket, before the Order was finalized by starting the payment process.
	 * These timeBasedPredictions are required by BKFR which likely uses it for further analysis of user behaviour.
	 * Data are collected by CSI, and currently likely ignored by other brands and fiscal cores.
	 * @s3dIgnoreInCopy
	 */
	orderDelContent: IBKItemInOrderBase[];
	/**
	 * Items which user decided to remove from basket after the Order has been finalized.
	 * @s3dIgnoreInCopy
	 */
	orderDelContentAfterDecl: IBKItemInOrderBase[];
	/**
	 * Order has been already displayed in Kitchen, but was not yet paid, and user decided to remove some Items.
	 * @s3dIgnoreInCopy
	 */
	orderDelContentAfterKitchenScreen?: IBKItemInOrderBase[];

	/**
	 * Delivery mode of the order (BKDeliveryModeEnum)
	 * @s3dInteger
	 */
	deliveryMode: number; //BKDeliveryModeEnum
	/**
	 * State of the order (BKOrderState)
	 * @s3dInteger
	 */
	state: number; //BKOrderState
	/**
	 * Detail about the state of the order
	 * @s3dInteger
	 */
	statedetail?: number;
	/**
	 * Source of the order. Maps to BKOrderSourceEnum
	 * @s3dInteger
	 */
	source: number;

	/**
	 * Timestamp of the creation of this order
	 * @s3dLong
	 */
	creationdate: number;
	/**
	 * Timestamp of the paid event
	 * @s3dLong
	 */
	paidDate: number;
	/**
	 * Represents actual start of the order, not only first product added.
	 * @s3dLong
	 */
	firstProductDate: number;
	/**
	 * @s3dLong
	 */
	stateChangeDate: number;

	/**
	 * ORB number
	 * @s3dInteger
	 */
	orb: number;
	/**
	 * Annotations associated to the
	 */
	annotations: IBKAnnotationData[];
	/**
	 * Discounts applied to this order
	 */
	orderDiscount: IBKDiscountInOrderData[];
	/**
	 * Events that occured related to this order
	 */
	event: IBKOrderEventData[];

	/**
	 * Coupons this Order may contain.
	 * This is always empty array or undefined in PreProduction
	 */
	orderCoupons?: IBKCouponServiceInOrderData[];

	excludedDiscountItems?: Record<string, IExcludedDiscountItems>;
}

/**
 * Published order = mdmorder structure ( many informations may have been removed )
 * @bkCopyInterface
 * @bkCommonClass
 * @bkCassandra
 * @bkDocs
 */
export interface IBKPublishedOrderData extends IBKPublishedOrderBase {
	/**
	 * Manager
	 */
	user: IBKUserData;
	/**
	 * Manager
	 */
	manager: IBKUserData;

	/**
	 * Total price of the order
	 */
	orderTotal: IBKItemPriceInfo;
	/**
	 * Line counter
	 * @s3dInteger
	 */
	lineCounter: number;
	/**
	 * CSI informations
	 * @s3dInteger
	 */
	csiOrder: number;
	/**
	 * Order source
	 */
	csiSrc: string;
	/**
	 * CSI timestamp when order have been declared
	 * @s3dLong
	 */
	csiDeclaredTime?: number;
	/**
	 * List the games that are involved in this order
	 */
	orderGames?: IBKGameInOrderData[];
	/**
	 * @s3dInteger
	 */
	businessDay?: number;
	localisation?: string;

	/**
	 * Tax distribution is typically filled in by Clerk based on putCommande/replaceCommande response from fiscal core
	 * and later utilized by ClerkMaster only.
	 */
	taxDistribution?: ITaxDistribution[];
}

export interface ITaxDistribution {
	taxRate: number;
	name: string;
	taxAmount: number;
	amountIncludingTaxes: number;
	amountExcludingTaxes: number;
}

export interface IEneanceOrderData extends IBKPublishedOrderData {
	/**
	 * Where was order paid or delivered
	 */
	machineName: string
}

/**
 * Preproduction order from PMS to KDS only
 * @bkCopyInterface
 * @bkCommonClass
 * @bkCassandra
 * @bkDocs
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IBKPreproductionOrderData extends IBKPublishedOrderBase {

}

/*******************************************************************************
 * Navigation Menu and screen
 ******************************************************************************/

/**
 * This export interface  describes a navigation screen for both the pos and the kiosk
 * @bkCommonClass
 * @bkCopyInterface
 * @bkPHPModel
 */
export interface IBKNavigationScreenData {
	/**
	 * Id of the item reference in the db
	 * @s3dPrimary
	 */
	id: number;
	/**
	 * The system name
	 */
	sysName: string;
	/**
	 * Name displayed on the navigation screen
	 * Languages are the key
	 * @s3dJavaProperties
	 * @s3dPHPMultilingueColumn
	 */
	name: { [lang: string]: string };
	/**
	 * Languages are the key
	 * @s3dJavaProperties
	 * @s3dPHPMultilingueColumn
	 */
	description?: { [lang: string]: string };
	/**
	 * Display colors
	 */
	color1: string;
	color2: string;
	/**
	 * Layout of the navigation screen
	 * @s3dInteger
	 */
	layout: number;
	/**
	 * Elements to display in area0
	 * @s3dPHPSubModelArrayColumn
	 */
	area0: IBKSelectionPattern[];
	/**
	 * Elements to display in area1
	 * @s3dPHPSubModelArrayColumn
	 */
	area1: IBKSelectionPattern[];
	/**
	 * Elements to display in area2
	 * @s3dPHPSubModelArrayColumn
	 */
	area2: IBKSelectionPattern[];
	/**
	 * Elements to display in area3
	 * @s3dPHPSubModelArrayColumn
	 */
	area3: IBKSelectionPattern[];
	/**
	 * Elements to display in area4
	 * @s3dPHPSubModelArrayColumn
	 */
	area4: IBKSelectionPattern[];
	/**
	 * @s3dPHPIgnore
	 */
	_hasImageOn?: boolean;
	/**
	 * @s3dPHPIgnore
	 */
	_hasImageOff?: boolean;
	/**
	 * @s3dPHPIgnore
	 */
	_hasImagePromote?: boolean;
	/**
	 * @s3dPHPIgnore
	 */
	_hasImageAlt?: boolean;
	/**
	 * Array containing the ids of the tags associated with specific entries
	 * @s3dPHPMultipleForeign
	 */
	xtag: number[];
	/**
	 * If true this screen is part of a campaign
	 */
	campaignScreen?: boolean;
	/**
	 * Extra filter for elements
	 * Refer to an external enumeration
	 * @s3dInteger
	 */
	extraFilter?: number;
}

/**
 * This export interface  defines a single POS configuration
 * @bkCommonClass
 * @bkCopyInterface
 * @bkPHPModel
 */
export interface IBKPosConfigurationData {
	/**
	 * Id of the item reference in the db
	 * @s3dPrimary
	 */
	id: number;
	/**
	 * Name of this configuration
	 * @bkPHPValidation  required|nonempty
	 */
	name: string;
	/**
	 * Ordered screens in the configuration
	 * @s3dPHPMultipleForeign
	 */
	screens: number[];
	/**
	 * @s3dPHPMultipleForeign
	 */
	associatedTargetsForLoop: number[];
	/**
	 * Pattern selecting to which restaurant this config is applicable to
	 * @s3dPHPSubModelArrayColumn
	 */
	applicableToPattern: IBKSelectionPattern[];
	/**
	 * If true this navigation screen is part of a campaign
	 */
	campaignConfig?: boolean;
}

/**
 * This export interface  defines a single KIOSK configuration
 * @bkCommonClass
 * @bkCopyInterface
 * @bkPHPModel
 */
export interface IBKKioskConfigurationData {
	/**
	 * Id of the item reference in the db
	 * @s3dPrimary
	 */
	id: number;
	/**
	 * Name of this configuration
	 * @bkPHPValidation  required|nonempty
	 */
	name: string;
	/**
	 * @s3dJavaProperties
	 * @s3dPHPMultilingueColumn
	 */
	welcomeMessage?: { [lang: string]: string };
	/**
	 * Ordered screens in the configuration
	 * @s3dPHPMultipleForeign
	 */
	screens: number[];
	/**
	 * @s3dPHPMultipleForeign
	 */
	associatedTargetsForLoop: number[];
	/**
	 * @s3dPHPMultipleForeign
	 */
	associatedTargetsForTransition: number[];
	/**
	 * @s3dPHPMultipleForeign
	 */
	associatedTargetsForHome: number[];
	/**
	 * Pattern selecting to which restaurant this config is applicable to
	 * @s3dPHPSubModelArrayColumn
	 */
	applicableToPattern: IBKSelectionPattern[];
	/**
	 * If true this navigation screen is part of a campaign
	 */
	campaignConfig?: boolean;
}

/*******************************************************************************
 * Medias and related items
 ******************************************************************************/

/**
 * This export interface  defines a single KIOSK configuration
 * @bkCommonClass
 * @bkCopyInterface
 * @bkPHPModel
 */
export interface IBKMediaData {
	/**
	 * Id of the item reference in the db
	 * @s3dPrimary
	 */
	id: number;
	/**
	 * Name of the media
	 */
	name: string;
	/**
	 * Description of the media
	 */
	description: string;
	/**
	 * @s3dInteger
	 */
	mediaType: number;
	/**
	 * @s3dInteger
	 */
	mediaDurationMs: number;
	/**
	 * Tags
	 * @s3dPHPMultipleForeign
	 */
	xtag: number[];
	/**
	 * @@@s3dInteger
	 */
	//mediaTarget: number;
	/**
	 * @s3dPHPMultipleForeign
	 */
	mediaTargets: number[];
	/**
	 * Required products (i.e. if not available, do not display)
	 * @s3dPHPMultipleForeign
	 */
	productReqs: number[];
	/**
	 * Required menus (i.e. if not available, do not display)
	 * @s3dPHPMultipleForeign
	 */
	menuReqs: number[];
	/**
	 * Sorted product arguments
	 * @s3dPHPMultipleForeign
	 */
	productArgs: number[];
	/**
	 * Sorted menu arguments
	 * @s3dPHPMultipleForeign
	 */
	menuArgs: number[];
	/**
	 * Sorted ingredient arguments
	 * @s3dPHPMultipleForeign
	 */
	ingredientArgs: number[];
	/**
	 * Sorted navigation menus
	 * @s3dInteger
	 */
	navigationKioskArg?: number;
	/**
	 * String arguments
	 */
	stringArgs?: string;
	/**
	 * If true, this media is a campaign media
	 */
	campaignMedia: boolean;
	/**
	 * Flag indicating if a media has a content
	 */
	_hasContent?: boolean;
	/**
	 * Sorted Game arguments
	 * @s3dPHPMultipleForeign
	 */
	gameArgs?: number[];
	/**
	 * Sorted discount arguments
	 * @s3dPHPMultipleForeign
	 */
	discountArgs?: number[];
	/**
	 * Action to be performed when the media can be activated by the user
	 * @s3dPHPSubModelColumn
	 */
	action?: IBKMediaActionData;
	/**
	 * Fidelity information
	 * @s3dPHPSubModelColumn
	 */
	fidelity?: IBKFidelityFilterData;
}

/**
 * @bkCloneInterface
 * @bkPHPInner
 * @bkCassandra
 */
export interface IBKMediaActionData {
	/**
	 * Action to be performed
	 * @s3dInteger
	 */
	action: number;
	/**
	 * Argument related to the action
	 * @s3dInteger
	 */
	arg: number;
}

/**
 * Describes a screen configuration timeBasedPredictions
 * @bkCloneInterface
 * @bkCopyInterface
 * @bkCommonClass
 * @bkPHPInner
 */
export interface IBKMediaPlaylistContentData {
	/**
	 * @s3dInteger
	 * @s3dPHPForeignKeyColumn  \BKModels\BKMedia
	 */
	mediaId: number;
	/**
	 * Priority of the media
	 * 0 = Normal
	 * 1 = High
	 * 2 = Very High
	 * @s3dInteger
	 */
	priority: number;
}

/**
 * This export interface  defines a single KIOSK configuration
 * @bkCommonClass
 * @bkCopyInterface
 * @bkPHPModel
 */
export interface IBKMediaPlaylistData {
	/**
	 * Id of the item reference in the db
	 * @s3dPrimary
	 */
	id: number;
	/**
	 * Name of the playlist
	 */
	name: string;
	/**
	 * Description of the playlist
	 */
	description: string;
	/**
	 * Ingredient selection pattern
	 * @s3dPHPSubModelArrayColumn
	 */
	content: IBKMediaPlaylistContentData[];
	/**
	 * If true this playlist contains campaign items
	 */
	campaignPlaylist: boolean;
	/**
	 * Pattern selecting to which restaurant this config is applicable to
	 * @s3dPHPSubModelArrayColumn
	 */
	applicableToPattern: IBKSelectionPattern[];
}

/*******************************************************************************
 * ORB and assembly areas
 ******************************************************************************/

/**
 * This class represents a
 * @bkCommonClass
 * @bkCopyInterface
 * @bkPHPModel
 */
export interface IBKAssemblyAreaData {
	/**
	 * Id of the item reference in the db
	 * @s3dPrimary
	 */
	id: number;
	/**
	 * Name of this assembly area
	 */
	name: string;
	/**
	 * Simple description of this assembly area
	 */
	description: string;
	/**
	 * Ingredient selection pattern
	 * @s3dPHPSubModelArrayColumn
	 */
	productPattern: IBKSelectionPattern[];
}

/**
 * Describes a screen configuration timeBasedPredictions
 * For Legacy mode only
 * @bkCloneInterface
 * @bkCopyInterface
 * @bkCommonClass
 * @bkPHPInner
 */
export interface IBKORBScreenConfigData {
	/**
	 * Index of this screen
	 * @s3dInteger
	 */
	screenIdx: number;
	/**
	 * If true, this screen acts as a slave
	 */
	isSlave: boolean;
	/**
	 * If this screen is a slave, index of the master(s)
	 *
	 * This is an idiotic way of defining Master ORBps.
	 * 1-9 means ID of single Master (no surprise here)
	 * 0 means unspecified multimaster's slave (every master is its master)
	 * x0x0x means multimaster selection. For example 10207 means that 1, 2, 7 are IDs of Master ORBps
	 *
	 * So 0 is used either as a separator or "wildcard to take all"
	 * @s3dInteger
	 */
	parentIdx: number;
	/**
	 * @s3dPHPMultipleForeign
	 */
	assemblyAreas: number[];
	/**
	 * Dispatch strategy for the orb
	 * @s3dInteger
	 */
	dispatchStrategy?: number;
	/**
	 * Specific kiosks to receive orders from
	 * @s3dPHPMultipleForeign
	 */
	specificKiosks?: number[];
	/**
	 * Note: Seems like Legacy mode.
	 *
	 * Mask for the sources of the order to display
	 * 0 means default source
	 * @s3dInteger
	 */
	sourceMask?: number;
	/**
	 * Indexes of the ORB
	 * @s3dPHPMultipleForeign
	 */
	orbIdx?: number[];
}

/**
 * This class represents an ORB configuration
 * @bkCommonClass
 * @bkCopyInterface
 * @bkPHPModel
 */
export interface IBKORBConfigData {
	/**
	 * Id of the item reference in the db
	 * @s3dPrimary
	 */
	id: number;
	/**
	 * Name of the config
	 */
	name: string;
	/**
	 * Description of the config
	 */
	description: string;
	autoPrintOrders: boolean;
	autoDisplayNotPaid: boolean;
	autoDisplayNotFinished: boolean;
	/**
	 * @s3dInteger
	 */
	alertTimer: number;
	/**
	 * Ingredient selection pattern
	 * @s3dPHPSubModelArrayColumn
	 */
	screens: IBKORBScreenConfigData[];
	/**
	 * Pattern selecting to which restaurant this config is applicable to
	 * @s3dPHPSubModelArrayColumn
	 */
	applicableToPattern: IBKSelectionPattern[];
}

/**
 * Describes a screen configuration timeBasedPredictions
 * @bkCloneInterface
 * @bkCopyInterface
 * @bkCommonClass
 * @bkPHPInner
 */
export interface IBKDriveScreenConfigData {
	/**
	 * Index of this screen
	 * @s3dInteger
	 */
	screenIdx: number;
	/**
	 * If true, this screen acts as a slave
	 */
	isSlave: boolean;
	/**
	 * If this screen is a slave, index of the master
	 * @s3dInteger
	 */
	parentIdx: number;
	/**
	 * @s3dPHPMultipleForeign
	 */
	assemblyAreas: number[];
	/**
	 * Mask for the sources of the order to display
	 * 0 means default source
	 * @s3dInteger
	 */
	sourceMask?: number;
}

/**
 * This class represents an ORB configuration
 * @bkCommonClass
 * @bkCopyInterface
 * @bkPHPModel
 */
export interface IBKDriveConfigData {
	/**
	 * Id of the item reference in the db
	 * @s3dPrimary
	 */
	id: number;
	/**
	 * Name of the config
	 */
	name: string;
	/**
	 * Description of the config
	 */
	description: string;
	autoPrintOrders: boolean;
	autoDisplayNotPaid: boolean;
	autoDisplayNotFinished: boolean;
	/** If true display the prices  **/
	displayOrderPrices?: boolean;
	/**
	 * @s3dInteger
	 */
	alertTimer: number;
	/**
	 * Ingredient selection pattern
	 * @s3dPHPSubModelArrayColumn
	 */
	screens: IBKDriveScreenConfigData[];
	/**
	 * Pattern selecting to which restaurant this config is applicable to
	 * @s3dPHPSubModelArrayColumn
	 */
	applicableToPattern: IBKSelectionPattern[];
}

/**
 * This class represents a COD configuration
 * @bkCommonClass
 * @bkCopyInterface
 * @bkPHPModel
 */
export interface IBKCODConfigData {
	/**
	 * Id of the item reference in the db
	 * @s3dPrimary
	 */
	id: number;
	/**
	 * Name of the config
	 */
	name: string;
	/**
	 * Description of the config
	 */
	description: string;
	/**
	 * List of the drive co configs
	 * @s3dPHPSubModelArrayColumn
	 */
	driveCoConfigs: IBKCODConfigDriveCoData[];
	/**
	 *
	 * List of the drive pay configs
	 * @s3dPHPSubModelArrayColumn
	 */
	drivePayConfigs: IBKCODConfigDrivePayData[];
	/**
	 * Pattern selecting to which restaurant this config is applicable to
	 * @s3dPHPSubModelArrayColumn
	 */
	applicableToPattern: IBKSelectionPattern[];
}

/**
 * @bkCloneInterface
 * @bkCopyInterface
 * @bkCommonClass
 * @bkPHPInner
 */
export interface IBKCODConfigDriveCoData {
	/**
	 * Index of the drive co currently configured
	 * @s3dInteger
	 */
	driveCoIdx: number;
	/**
	 * Drive pay to send the orders to
	 * @s3dInteger
	 */
	targetPay: number;
	/**
	 * List of the COD that can display the orders
	 * @s3dPHPMultipleForeign
	 */
	targetCOD: number[];
}

/**
 * @bkCloneInterface
 * @bkCopyInterface
 * @bkCommonClass
 * @bkPHPInner
 */
export interface IBKCODConfigDrivePayData {
	/**
	 * Index of the drive pay currently configured
	 * @s3dInteger
	 */
	drivePayIdx: number;
	/**
	 * List of the COD that can display the orders
	 * @s3dPHPMultipleForeign
	 */
	targetCOD: number[];
}

/*******************************************************************************
 * KDS configuration
 ******************************************************************************/

/**
 * @bkCloneInterface
 * @bkCopyInterface
 * @bkCommonClass
 * @bkPHPInner
 */
export interface IBKKitchenScreenConfigData {
	/**
	 * @s3dInteger
	 */
	screenIdx: number;
	/**
	 * @s3dPHPMultipleForeign
	 */
	orderSource: number[];
	/**
	 * Filtering or orders
	 * @s3dInteger
	 */
	orderFilter?: number;
	/**
	 * Paying state for display
	 */
	displayPayingPOS?: boolean;
	displayPayingDrive?: boolean;
	displayPayingKiosk?: boolean;
	displayPayingWeb?: boolean;
	/**
	 * Ingredient selection pattern
	 * @s3dPHPSubModelArrayColumn
	 */
	productPattern: IBKSelectionPattern[];
}

/**
 * This class represents a
 * @bkCommonClass
 * @bkCopyInterface
 * @bkPHPModel
 */
export interface IBKKitchenConfigData {
	/**
	 * Id of the item reference in the db
	 * @s3dPrimary
	 */
	id: number;
	/**
	 * Name of this config
	 */
	name: string;
	/**
	 * Description of this config
	 */
	description: string;
	/**
	 * Colors
	 */
	colorordersource_kiosk: string;
	colorordersource_cash_machine: string;
	colorordersource_drive: string;
	colorordersource_website: string;
	/**
	 * If true, show the right column
	 */
	showRightColumn: boolean;
	/**
	 * Time at which an alert is raised in ms
	 * @s3dInteger
	 */
	alerttimer: number;
	/**
	 * Number of infos to sumup
	 * @s3dInteger
	 */
	infostosumup: number;
	/**
	 * Ingredient selection pattern
	 * @s3dPHPSubModelArrayColumn
	 */
	screens: IBKKitchenScreenConfigData[];
	/**
	 * Pattern selecting to which restaurant this config is applicable to
	 * @s3dPHPSubModelArrayColumn
	 */
	applicableToPattern: IBKSelectionPattern[];
}

/*******************************************************************************
 * DMB configuration
 ******************************************************************************/

/**
 * @bkCloneInterface
 * @bkCopyInterface
 * @bkCommonClass
 * @bkPHPInner
 */
export interface IBKDMBScreenConfig {
	/**
	 * @s3dInteger
	 */
	index: number;
	/**
	 * @s3dPHPMultipleForeign
	 */
	associatedTargets: number[];
}

/**
 * @bkCloneInterface
 * @bkCopyInterface
 * @bkCommonClass
 * @bkPHPModel
 */
export interface IBKDMBConfig {
	/**
	 * Id of the item reference in the db
	 * @s3dPrimary
	 */
	id: number;
	/**
	 * Name of this config
	 */
	name: string;
	/**
	 * Description of this config
	 */
	description: string;
	/**
	 * Screen configuration
	 * @s3dPHPSubModelArrayColumn
	 */
	screens: IBKDMBScreenConfig[];
	/**
	 * Pattern selecting to which restaurant this config is applicable to
	 * @s3dPHPSubModelArrayColumn
	 */
	applicableToPattern?: IBKSelectionPattern[];
}

/**
 * @bkCloneInterface
 * @bkCopyInterface
 * @bkCommonClass
 * @bkPHPModel
 */
export type IBKXMBConfig = IBKDMBConfig;

/*******************************************************************************
 * Users and Machines
 ******************************************************************************/

/**
 * @bkCassandra
 * @bkCloneInterface
 * @bkCopyInterface
 * @bkDocs
 */
export interface IBKUserData {
	/**
	 * @s3dInteger
	 */
	id: number;
	login: string;
}

/**
 * @bkCloneInterface
 * @bkCopyInterface
 * @bkCassandra
 * @bkDocs
 */
export interface IBKMachine {
	name: string;
	role: string;
	/**
	 * @s3dInteger
	 */
	idx: number;
	/**
	 * @bkCassandraType inet
	 * @bkCassandraInet
	 */
	ip: string;
}

/*******************************************************************************
 * Restaurant configuration
 ******************************************************************************/

export interface IBKRestoConfigData {
	/**
	 * Name of the restaurant
	 */
	name?: string;
	/**
	 * UUID of this restaurant
	 */
	uuid?: string;
	/**
	 * Enable the multipay on the kiosk
	 */
	enableMultipayOnKiosk?: boolean;
	/**
	 * Enable Kingdom in the restaurant
	 */
	enableKingdom?: boolean;
	/**
	 * Enable the ORB digital
	 */
	enableORBDigital?: boolean;
	/**
	 * Enable the deferred pay
	 */
	enableDeferredPay?: boolean;
	/**
	 * Enable the icoupon pay
	 */
	enableICouponPay?: boolean;
	/**
	 * Kitchen configurations applicable to this restaurant
	 */
	kitchenConfigs: { [key: string]: IBKKitchenConfigData };
	/**
	 * Default kitchen config
	 * @s3dInteger
	 */
	kitchenConfigsDefault: number;

	/**
	 * ORB configurations applicable to this restaurant
	 */
	orbConfigs: { [key: string]: IBKORBConfigData };
	/**
	 * Default orb configuration
	 * @s3dInteger
	 */
	orbConfigsDefault: number;

	/**
	 * Drive configurations applicable to this restaurant
	 */
	driveConfigs?: { [key: string]: IBKDriveConfigData };
	/**
	 * COD configuration
	 */
	codConfigs?: { [key: string]: IBKCODConfigData };
	/**
	 * Default orb configuration
	 * @s3dInteger
	 */
	driveConfigsDefault?: number;

	/**
	 * Assembly areas of this restaurant
	 */
	assemblyAreas: { [key: string]: IBKAssemblyAreaData };

	/**
	 * DMB configuration
	 */
	dmbConfig?: IBKDMBConfig;
	/**
	 * XMB configuration
	 */
	xmbConfig?: IBKXMBConfig;
	/**
	 * Kiosk navigation screens
	 */
	kioskScreens: { [key: string]: IBKNavigationScreenData };
	/**
	 * Kiosk configurations
	 */
	kioskConfigs: { [key: string]: IBKKioskConfigurationData };
	/**
	 * Default kiosk configuration
	 * @s3dInteger
	 */
	kioskConfigsDefault: number;

	/**
	 * Kiosk navigation screens
	 */
	posScreens: { [key: string]: IBKNavigationScreenData };
	/**
	 * POS configurations
	 */
	posConfigs: { [key: string]: IBKPosConfigurationData };
	/**
	 * Default POS configuration
	 * @s3dInteger
	 */
	posConfigsDefault: number;
	/**
	 * Id of the restaurant
	 * @s3dInteger
	 */
	id?: number;
	/**
	 * Restaurant
	 * @s3dInteger
	 */
	restaurant?: IBKRestaurantData;
	/**
	 * Mask containing the enabled sources
	 * @s3dInteger
	 */
	enabledSources?: number;
	/**
	 * List containing resto features
	 */
	features?: string[];

	/**
	 * Enable the method of payment “CARTE GR” in the POS
	 */
	enablePaymentCarteGR?: boolean;
}

/*******************************************************************************
 * Product option
 ******************************************************************************/

/**
 * This export interface  describes an option for a product
 * @bkCloneInterface
 * @bkCopyInterface
 * @bkCommonClass
 * @bkPHPModel
 */
export interface IBKItemOptionData {
	/**
	 * Id of the item reference in the db
	 * @s3dPrimary
	 */
	id: number;
	/**
	 * System name
	 * @bkPHPValidation  required|nonempty
	 */
	sysName: string;
	/**
	 * Name of this otpion
	 * @s3dJavaProperties
	 * @s3dPHPMultilingueColumn
	 */
	_name: { [lang: string]: string };
	/**
	 * Descriptions of the option in different languages
	 * Languages are the key
	 * @s3dJavaProperties
	 * @s3dPHPMultilingueColumn
	 */
	_description: { [lang: string]: string };
	/**
	 * Type of the option
	 * @s3dInteger
	 */
	optionType: number;
	/**
	 * Filter for the delivery mode
	 * @s3dInteger
	 */
	deliveryFilter?: number;
	/**
	 * String arguments as an url encoded string
	 */
	stringArgs?: string;
	/**
	 * Pattern selecting elements for the option
	 * @s3dPHPSubModelArrayColumn
	 */
	optionContent: IBKSelectionPattern[];
	/**
	 * Pattern selecting to which product this option applies to
	 * @s3dPHPSubModelArrayColumn
	 */
	applicableToPattern: IBKSelectionPattern[];
	/**
	 * Is this option a campaign option
	 */
	campaignOption: boolean;
}

/*******************************************************************************
 * Annotation
 ******************************************************************************/

/**
 * This export interface  describes an annotation that can be attached to an order
 * @bkCloneInterface
 * @bkCopyInterface
 * @bkCommonClass
 * @bkPHPModel
 * @bkCassandra
 * @bkDocs
 */
export interface IBKAnnotationData {
	/**
	 * Id of the item reference in the db
	 * @s3dPrimary
	 * @s3dInteger
	 */
	id: number;
	/**
	 * Name of this config
	 */
	_name?: string;
	/**
	 * Description of this config
	 */
	_description?: string;
	/**
	 * Label of this annotation
	 */
	label: string;
	/**
	 * Array containing the ids of the tags associated with this item
	 * @s3dPHPMultipleForeign
	 */
	_xtag?: number[];
	/**
	 * If true, print the label on the ticket
	 */
	_printInTicket?: boolean;
	/**
	 * If true, print the label in the kitchen display
	 */
	_printInKitchen?: boolean;
	/**
	 * If true, print the label in the ORB display
	 */
	_printInORB?: boolean;
	/**
	 * Type of annotation
	 * @s3dInteger
	 */
	annotationType?: number;
}

/*******************************************************************************
 * Suggestion
 ******************************************************************************/

/**
 * This export interface  describes a suggestion
 * @bkCloneInterface
 * @bkCopyInterface
 * @bkCommonClass
 * @bkPHPModel
 */
export interface IBKSuggestionData {
	/**
	 * Id of the item reference in the db
	 * @s3dPrimary
	 */
	id: number;
	/**
	 * System name
	 */
	sysName: string;
	/**
	 * Text of the suggestion
	 * Languages are the key
	 * @s3dJavaProperties
	 * @s3dPHPMultilingueColumn
	 */
	_text: { [lang: string]: string };
	/**
	 * @s3dJavaProperties
	 * @s3dPHPMultilingueColumn
	 */
	textSecondary?: { [lang: string]: string };
	/**
	 * Type of the option
	 * @s3dInteger
	 */
	suggestionType: number;
	/**
	 * Pattern selecting elements for the option
	 * @s3dPHPSubModelArrayColumn
	 */
	suggestionContent: IBKSelectionPattern[];
	/**
	 * Pattern selecting to which product this suggestion applies to
	 * @s3dPHPSubModelArrayColumn
	 */
	applicableToPattern: IBKSelectionPattern[];
	/**
	 * If true this suggestion will applies only to the large menus
	 */
	appliesOnlyToLargeMenus?: boolean;
	/**
	 * If true this suggestion is only displayed during a campaign
	 */
	campaignSuggestion: boolean;
	/**
	 * Mask for display methods
	 * @s3dInteger
	 */
	_displayMethods?: number;
	/**
	 * Filter for the delivery mode
	 * @s3dInteger
	 */
	deliveryFilter?: number;
	/**
	 * String arguments
	 */
	stringArgs?: string;

	enableBackgroundImage?: boolean;
	backgroundPositionVertical?: number;
	backgroundPositionHorizontal?: number;
	/**
	 * @s3dPHPIgnore
	 */
	_hasBackgroundImage?: boolean;
}

/*******************************************************************************
 * Discount
 ******************************************************************************/

/**
 * This export interface  describes an annotation that can be attached to an order
 * @bkCloneInterface
 * @bkCopyInterface
 * @bkCommonClass
 * @bkPHPModel
 */
export interface IBKDiscountData {
	/**
	 * Id of the item reference in the db
	 * @s3dPrimary
	 * @s3dInteger
	 */
	id: number;
	/**
	 * System name
	 */
	sysName: string;
	_name?: string;
	_description?: string;
	/**
	 * Active flag
	 */
	active?: boolean;
	/**
	 * Names of the config in different languages
	 * Languages are the key
	 * @s3dJavaProperties
	 * @s3dPHPMultilingueColumn
	 */
	_nameTemp?: { [lang: string]: string };
	/**
	 * Descriptions of config family in different languages
	 * Languages are the key
	 * @s3dJavaProperties
	 * @s3dPHPMultilingueColumn
	 */
	_descriptionTemp?: { [lang: string]: string };
	/**
	 * Type of discount
	 * @s3dInteger
	 */
	discountType: number;
	/**
	 * Code that may be associated to the discount
	 */
	discountCode?: string;
	/**
	 * Flag indicating that this discount is in percentage
	 */
	discountInPercent: boolean;
	/**
	 * Value of the discount
	 * @s3dDouble
	 */
	discountValue: number;
	/**
	 * Reference of the discount
	 * @s3dPHPSubModelColumn
	 */
	ref: IBKItemReference;
	/**
	 * If true, this discount can be added to one other menu discount
	 */
	_canBeAddedWithOtherMenuDiscount?: boolean;
	/**
	 * If true, this discount can be added to one other product discount
	 */
	_canBeAddedWithOtherProductDiscount?: boolean;
	/**
	 * If true, this discount can be added to one other order discount
	 */
	_canBeAddedWithOtherOrderDiscount?: boolean;
	/**
	 * Pattern selecting the elements to which this discount apply
	 * @s3dPHPSubModelArrayColumn
	 */
	_discountApplicableToPattern?: IBKSelectionPattern[];
	/**
	 * Mask indicating which menu sizes are concerned by the "applicable to" patterns
	 * @s3dInteger
	 */
	_applicableToMenuSizeMask?: number;
	/**
	 * Pattern selecting elements required before enabling this discount
	 * @s3dPHPSubModelArrayColumn
	 */
	_discountApplicableWhenPattern?: IBKSelectionPattern[];
	/**
	 * Mask indicating which menu sizes are concerned by the "applicable when" patterns
	 * @s3dInteger
	 */
	_applicableWhenMenuSizeMask?: number;
	/**
	 * Pattern selecting to which restaurant this config is applicable to
	 * @s3dPHPSubModelArrayColumn
	 */
	_applicableToRestaurantPattern?: IBKSelectionPattern[];
	/**
	 * Is this discount associated to a campaign
	 */
	_campaignDiscount?: boolean;
	/**
	 * Is this discount related to the fidelity program
	 */
	_fidelityDiscount?: boolean;
	/**
	 * Is this fidelity discount able to be cumulated with other fidelity discounts
	 */
	_canBeUsedWithAnotherFidelityDiscount?: boolean;
	/**
	 * Mask for flag
	 * @s3dInteger
	 */
	_flagMask?: number;
	/**
	 * Array containing the ids of the tags associated with this item
	 * @s3dPHPMultipleForeign
	 * @bkCassandraType list<int>
	 */
	xtag: number[];
	/**
	 * @s3dInteger
	 */
	validationRequired?: number;

	/**
	 * If true, a manager validation if required prior to add this discount
	 */
	_requireManagerValidation?: boolean;
}

/**
 * This export interface  describes a discount when set in an order
 * @bkCloneInterface
 * @bkCopyInterface
 * @bkCommonClass
 * @bkCassandra
 * @bkDocs
 */
export interface IBKDiscountInOrderData extends IBKDiscountData {
	/**
	 * UUID of this discount
	 * @bkCassandraType uuid
	 * @bkCassandraUUIDPatch
	 */
	uuid: string;
	/**
	 * Value of the discount
	 */
	appliedValue: IBKItemPriceInfo;
	/**
	 * Manager
	 */
	manager?: IBKUserData;
	/**
	 * If defined, contains the UUID of the associated game
	 */
	relatedGame?: string;
}

/*******************************************************************************
 * Restaurant
 ******************************************************************************/

/**
 * This export interface  describes a restaurant
 * @bkCloneInterface
 * @bkCopyInterface
 * @bkCommonClass
 * @bkPHPModel
 */
export interface IBKRestaurantData {
	/**
	 * Id of the item reference in the db
	 * @s3dPrimary
	 */
	id: number;
	/**
	 * Name of the restaurant
	 */
	name: string;
	/**
	 * UUID of this restaurant
	 */
	uuid: string;
	/**
	 * Number of POS
	 * @s3dInteger
	 */
	nbPOS: number;
	/**
	 * Number of DMB
	 * @s3dInteger
	 */
	nbDMB: number;
	/**
	 * Number of KDS
	 * @s3dInteger
	 */
	nbKDS: number;
	/**
	 * Number of kiosks
	 * @s3dInteger
	 */
	nbKIOSK: number;
	/**
	 * Number of ORBp
	 * @s3dInteger
	 */
	nbORBp: number;
	/**
	 * Number of ORBs
	 * @s3dInteger
	 */
	nbORBs: number;
	/**
	 * Number of drive line
	 * @s3dInteger
	 */
	nbDrive: number;
	/**
	 * The current restop state
	 * @s3dInteger
	 */
	restoState?: number;

	/**
	 * FR Number
	 */
	number_fr?: string;

	/**
	 * BK Number
	 */
	number_bk?: string;
}

/*******************************************************************************
 * Pay
 ******************************************************************************/

/**
 * Element with csi order infos
 * @bkCloneInterface
 * @bkCopyInterface
 * @bkCassandra
 */
export interface IBKOrderElementWithCsiInfos {
	/**
	 * @s3dInteger
	 */
	csiOrder: number;
	csiSrc: string;
}

/**
 * Order pay (mdmPay)
 * @bkCloneInterface
 * @bkCopyInterface
 * @bkCassandra
 */
export interface IBKOrderPayData extends IBKOrderElementWithCsiInfos {
	/**
	 * UUID of the order this pay belongs to
	 */
	orderUUID: string;
	/**
	 * Pay lines of the order
	 */
	lines: IBKOrderPayLineData[];
	/**
	 * Deleted lines of the order
	 */
	deletedLines: IBKOrderPayLineData[];
	/**
	 * Total amount to pay
	 * @s3dDouble
	 */
	total: number;
	/**
	 * Remaining to be paid
	 * @s3dDouble
	 */
	paid: number;
	/**
	 * @s3dDouble
	 */
	remaining: number;
	/** Information about the pay informations  **/
	//csiOrder: number;
	//csiSrc: string;
	/**
	 * @s3dInteger
	 */
	csiTicket: number;
	csiTicketSrc: string;
	/** Users   **/
	user: IBKUserData;
	manager: IBKUserData;
	/** Machine **/
	machine: IBKMachine;
	/**
	 * Creation date
	 * @s3dLong
	 */
	creationDate: number;
	/**
	 * First payline date
	 * @s3dLong
	 */
	payStartDate: number;
	/**
	 * @s3dInteger
	 */
	businessDay?: number;
}

/**
 * Order pay line
 * @bkCloneInterface
 * @bkCopyInterface
 * @bkCassandra
 */
export interface IBKOrderPayLineData {
	/**
	 * Type of pay
	 * @s3dInteger
	 */
	payType: number;
	/**
	 * Label
	 */
	payLabel: string;
	/**
	 * Is change allowed
	 */
	changeAllowed: boolean;
	/**
	 * Amount paid
	 * @s3dDouble
	 */
	amount: number;
	/**
	 * Change
	 * @s3dDouble
	 */
	change: number;
	/**
	 * Number of the csi pay mode
	 * @s3dInteger
	 */
	csiNumber: number;
	/**
	 * Tag of the csi pay mode
	 */
	csiTags: string;
	family: string;
	paymentLineUuid?: string;
	cbToken?: string;
}

/*******************************************************************************
 * Game export interface
 ******************************************************************************/

/**
 * This export interface  defines a game definition
 * @bkCloneInterface
 * @bkCopyInterface
 * @bkCommonClass
 * @bkPHPModel
 */
export interface IBKGameData {
	/**
	 * Primary key
	 * @s3dPrimary
	 * @s3dInteger
	 */
	id: number;
	/**
	 * Name of the game
	 * @bkPHPValidation  required|nonempty
	 */
	name: string;
	/**
	 * Names of the published item in different languages
	 * Languages are the key
	 * @s3dJavaProperties
	 * @s3dPHPMultilingueColumn
	 */
	_name?: { [lang: string]: string };

	/**
	 * Descriptions of the published item
	 * Languages are the key
	 * @s3dJavaProperties
	 * @s3dPHPMultilingueColumn
	 */
	_description?: string

	/**
	 * Descriptions of the published item in different languages
	 * Languages are the key
	 * @s3dJavaProperties
	 * @s3dPHPMultilingueColumn
	 */
	_descriptionTemp?: { [lang: string]: string };
	/**
	 * Algo for the game
	 * @s3dInteger
	 */
	algo: number;
	/**
	 * Sorted product arguments
	 * @s3dPHPMultipleForeign
	 * @bkCassandraType list<int>
	 */
	_productArgs?: number[];
	/**
	 * Sorted discount arguments
	 * @s3dPHPMultipleForeign
	 * @bkCassandraType list<int>
	 */
	_discountArgs?: number[];
	/**
	 * Sorted menu arguments
	 * @s3dPHPMultipleForeign
	 * @bkCassandraType list<int>
	 */
	_integer1Args?: number[];
	/**
	 * If true, this game is a campaign game
	 */
	_campaignGame?: boolean;
	/**
	 * Price for the M ( normal ) size
	 * @s3dPHPIgnore
	 */
	_priceM?: IBKItemPriceInfo;
	/**
	 * Check if this game entry can be mixed with a product discount
	 */
	_canCombineWithProductDiscount?: boolean;
	/**
	 * Check if this game entry can be mixed with a product discount
	 */
	_canCombineWithMenuDiscount?: boolean;
	/**
	 * Check if this game entry can be mixed with an order discount
	 */
	_canCombineWithOrderDiscount?: boolean;
	/**
	 * Check if this game can be combined with other games
	 */
	_canCombineWithOtherGame?: boolean;
	/**
	 * Number of times this game can be used in the same order
	 * 0 means no limit
	 * @s3dInteger
	 */
	_maxSameGameItem?: number;
	/**
	 * Pattern selecting the elements to which this game apply
	 * @s3dPHPSubModelArrayColumn
	 */
	_gameApplicableToPattern?: IBKSelectionPattern[];
	/**
	 * Array containing the ids of the tags associated with this item
	 * @s3dPHPMultipleForeign
	 * @bkCassandraType list<int>
	 */
	_xtag?: number[];
	/**
	 * String arguments
	 */
	stringArgs?: string;
	/**
	 * Sorted media arguments
	 * @s3dPHPMultipleForeign
	 * @bkCassandraType list<int>
	 */
	mediaArgs?: number[];
	/**
	 * @s3dPHPIgnore
	 */
	_hasImageMain?: boolean;
	/**
	 * @s3dPHPIgnore
	 */
	_hasImageSys?: boolean;
	/**
	 * @s3dPHPIgnore
	 */
	_hasKioskImage2?: boolean;
}

/**
 * This export interface  describes a discount when set in an order
 * @bkCloneInterface
 * @bkCopyInterface
 * @bkCommonClass
 * @bkCassandra
 * @bkDocs
 */
export interface IBKGameInOrderData extends IBKGameData {
	/**
	 * UUID of this discount
	 * @bkCassandraType uuid
	 * @bkCassandraUUIDPatch
	 */
	uuid: string;
}

/*******************************************************************************
 * Coupon service
 ******************************************************************************/

/**
 * This export interface  defines a coupon service
 * @bkCloneInterface
 * @bkCopyInterface
 * @bkCommonClass
 * @bkPHPModel
 */
export interface IBKCouponServiceData {
	/**
	 * Primary key
	 * @s3dPrimary
	 * @s3dInteger
	 */
	id: number;
	/**
	 * Name of the game
	 * @bkPHPValidation  required|nonempty
	 */
	name: string;
	/**
	 * Names of the published item in different languages
	 * Languages are the key
	 * @s3dJavaProperties
	 * @s3dPHPMultilingueColumn
	 */
	_name?: { [lang: string]: string };
	/**
	 * Description of the game
	 */
	_description?: string;
	_descriptionTemp?: { [lang: string]: string };
	/**
	 * Array containing the ids of the tags associated with this item
	 * @s3dPHPMultipleForeign
	 */
	_xtag?: number[];
	/**
	 * Algo for the game
	 * @s3dInteger
	 */
	algo: number;
	/**
	 * If true, this coupon is a campaign coupon
	 */
	_campaignCoupon?: boolean;
	/**
	 * String arguments as a url encoded string
	 * @bkPHPValidation  #required
	 */
	stringArgs: string;
	/**
	 * Pattern selecting the elements to which this coupon apply
	 * @s3dPHPSubModelArrayColumn
	 */
	_couponApplicableToPattern?: IBKSelectionPattern[];
	/**
	 * Pattern selecting to which restaurant this config is applicable to
	 * @s3dPHPSubModelArrayColumn
	 */
	//applicableToPattern: IBKSelectionPattern[];

	couponPageTitle?: string;
	couponPageLegalNotice?: string;
	/**
	 * @s3dPHPIgnore
	 */
	_hasImage?: boolean;
	/**
	 * @s3dPHPIgnore
	 */
	_hasImageTitle?: boolean;

	_canBeUsedWithAnotherCoupon?: boolean;
	codeLength?: number;
	codePrefix?: string;
	orderSourcesFilter?: number[]; //BKOrderSourceEnum[]
	entryOptions?: number;
}

/**
 * This export interface  describes a coupon when set in an order
 * @bkCloneInterface
 * @bkCopyInterface
 * @bkCommonClass
 * @bkCassandra
 * @bkDocs
 */
export interface IBKCouponServiceInOrderData extends IBKCouponServiceData {
	/**
	 * UUID of this coupon in order
	 * @bkCassandraType uuid
	 * @bkCassandraUUIDPatch
	 */
	uuid: string;
	/**
	 * Args that may be required to be stored by the coupon implementation
	 */
	couponInOrderArgs: string;
}

/*******************************************************************************
 * SOS export interface
 ******************************************************************************/

/**
 * This export interface  describes a speed of service record
 * @bkCloneInterface
 * @bkCopyInterface
 * @bkCommonClass
 * @bkCassandra
 */
export interface IBKSOSOrderRecord {
	//    /**
	//     * Business day this order belongs to
	//     * @s3dInteger_legacy
	//     * @bkConstructor_legacy
	//     */
	//    businessday: number;
	/**
	 * Minute of the day
	 * @s3dInteger
	 * @bkConstructor
	 */
	minuteOfDay: number;
	/**
	 * Last state of the order
	 * @s3dInteger
	 */
	state: number;
	//    /**
	//     * UUID of the order
	//     * @bkCassandraType_legacy uuid
	//     * @bkCassandraUUIDPatch_legacy
	//     * @bkConstructor_legacy
	//     */
	//    orderUUID: string;
	/**
	 * Source of the order
	 * @s3dInteger
	 * @bkConstructor
	 */
	src: number;
	/**
	 * Delivery mode
	 * @s3dInteger
	 * @bkConstructor
	 */
	deliveryMode: number;
	/**
	 * Role associated to the order
	 * @bkConstructor
	 */
	role: string;
	/**
	 * Index of the machine
	 * @s3dInteger
	 * @bkConstructor
	 */
	roleIdx: number;
	/**
	 * @s3dLong
	 * @bkConstructor
	 */
	creationTime: number;
	/**
	 * Absolute time when the car arrived at the COD or Delivery window in case of ordering at delivery).
	 * @s3dLong
	 */
	hardWaitTime: number;
	/**
	 * @s3dLong
	 */
	waitTime: number;
	/**
	 * @s3dLong
	 */
	orderTime: number;
	/**
	 * @s3dLong
	 */
	lineTime: number;
	/**
	 * @s3dLong
	 */
	payTime: number;
	/**
	 * Relative time between payTime and when the car entered the delivery window
	 *
	 * @s3dLong
	 */
	hardServeTime: number;
	/**
	 * @s3dLong
	 */
	serveTime: number;
	/**
	 * @s3dLong
	 */
	parkTime: number;
	/**
	 * @s3dLong
	 */
	endTime: number;
	/**
	 * _s3dLong
	 * @bkConstructor
	 */
	sameCar: boolean;
	/**
	 * If true, the order has been issued by a tablet
	 * @bkConstructor
	 */
	tabletOrder: boolean;
	/**
	 * Kds validation list
	 */
	kdsValidation?: IBKSOSOrderRecordKDSValidation[];
	/**
	 * Absolute time when the car has leaved the delivery window
	 *
	 * @s3dLong
	 */
	hardDepartureTime: number;

	/**
	 * Absolute time when the car leave the COD captor (won't be set in case of drivepay2 orders)
	 *
	 * @s3dLong
	 */
	hardCODLeaveTime: number;
}

/**
 * This export interface  is used inb ordser to register kds validation in the SOS process
 * @bkCloneInterface
 * @bkCopyInterface
 * @bkCommonClass
 * @bkCassandra
 */
export interface IBKSOSOrderRecordKDSValidation {
	/**
	 * @s3dInteger
	 */
	kdsIdx: number;
	/**
	 * @s3dLong
	 */
	validationTime: number;
}

/**
 * Simple DTT record
 * @bkCassandraTable BKConstants.TABLE_DTT
 * @bkCassandraTablePrimary businessday,minuteOfDay, dttuuid
 * @bkCassandraTableUUID f3efd1d0-f6ce-48fb-bf4b-9ed7cf3252c2
 */
export interface IBKDTTRecord {
	/**
	 * businessday int
	 * @s3dInteger
	 */
	businessday: number;
	/**
	 * UUID of the dtt entry
	 * dttuuid uuid
	 * @bkCassandraType uuid
	 * @bkCassandraUUIDPatch
	 */
	dttuuid: string;
	/**
	 * Minute of the day
	 * @s3dInteger
	 */
	minuteOfDay: number;
	/**
	 * Detection timestamp
	 * @bkCassandraType timestamp
	 * @s3dLong
	 */
	detectionDate: number;
	/**
	 * Number or time for ordering a menu
	 * @s3dLong
	 */
	menuTime: number;
	/**
	 * Number of time for picking up a menu
	 * @s3dLong
	 */
	pickupTime: number;
	/**
	 * Total time of the process
	 * @s3dLong
	 */
	total: number;
}

/*******************************************************************************
 * BurgerMystere BK
 ******************************************************************************/

export interface IBKBurgerMystereIsValidCouponResult {
	result: number; // BKBurgerMystereIsValidCouponResultEnum
	coupon?: IBKKingdomRebootCoupon; // set only if result is OK
}

export interface IBKBurgerMystereBurnCouponResult {
	result: number; // BKBurgerMystereBurnCouponResultEnum
	failedCoupons?: string[]; // set only if result is SERVER_ERROR
}

/*******************************************************************************
 * KINGDOM BK
 ******************************************************************************/
/**
 * Interface representing a result for the getting a kingdom client
 */
export interface IBKKingdomClientRequestResult {
	result: number; // BKKingdomClientResultEnum
	client?: IBKKingdomClientData;
}

/**
 * Interface representing a kingdom client
 */
export interface IBKKingdomClientData {
	id: string;
	lastname: string;
	firstname: string;
	points?: number;
	coupons: IBKKingdomRebootCoupon[];
	vip?: boolean;
	lastOrderContent?: IBKExchangedOrderContent[];
	masterId?: string; // Splio only
	email?: string; // Bchef only
}

/**
 * Interface representing a kingdom reboot coupon for a client
 */
export interface IBKKingdomRebootCoupon {
	idReboot: number;
	idEcomm: string;
	dateStart: number;
	dateEnd: number;
	promotionCode: string;
	promotionLabel: string;
	promotionMediaUrl: string;
	promotionDescription: string;
	promotionCornerLabel: string;
	points?: number;
	pointsBeforeDiscount?: number;
	attributionId?: number;
}

export interface IBKFidelityCODUserData {
	firstname: string;
	lastname: string;
	couponNumber: number;
	points?: number;
}

export interface IBKPrivilegeItem extends BKItemBaseImpl {
	image: string;
	points: number;
}

// #region POS-vs-SmartCOD communication
type BaseItemAvailableInFidelityDiscount = {
	/**
	 * product or menu id
	 */
	id: number;
	// For debugging purposes
	name: string;
	// For debugging purposes
	discountId: number;
}

export type ProductAvailableInFidelityDiscount = BaseItemAvailableInFidelityDiscount & {
	isMenu: false;
}
export type MenuAvailableInFidelityDiscount = BaseItemAvailableInFidelityDiscount & {
	isMenu: true;
}

export type DiscountBase = {
	discountName: string;
	discountId: number;
	/**
	 * Customer can choose from available Products or Menus in exchange for earned FidelityPoints
	 */
	availableItems: (ProductAvailableInFidelityDiscount | MenuAvailableInFidelityDiscount)[];
}

export type FidelityDiscountConvertibleToItems = DiscountBase & {
	/**
	 * cost of the items in earned fidelity points
	 */
	points: number;
}

/**
 * Classic Privilege either adds Discount in %,
 * or it set lower fixed price for some Product (eg 2 Eur instead of 5).
 */
export type ClassicPrivilegeDiscount = DiscountBase & {
	discountPercent?: number;
	reducedPrice?: number;
}
// #endregion POS-vs-SmartCOD communication

/*******************************************************************************
 * Schemas BK
 ******************************************************************************/

/**
 *
 */
export interface IBKCampaignLocalPricesData {
	daypartProductPrices?: IBKCampaignDaypartPrice[];
	daypartProductExtraPrices?: IBKCampaignDaypartPrice[];
	daypartMenuPrices?: IBKCampaignDaypartPrice[];
	daypartIngredientPrices?: IBKCampaignDaypartPrice[];
}

/**
 * Structure of raw availability which is created and modified in local server (available.json file)
 * which is then sent to global server and with BigData recalculated to ApiAvailabilityDto
 * to return from /api/avl endpoint.
 */
export interface IBKProductAvailabilityData {
	timestamp?: number;
	availability: { [productId: string]: boolean };
	price?: {
		products: {
			[productId: string]: number;
		};
		menus: {
			[menuId: string]: number;
		};
		menusL: {
			[menuId: string]: number;
		};
		ingredients: {
			[ingredientId: string]: number;
		};
	};
	crowns?: {
		products: {
			[productId: string]: number;
		};
		menus: {
			[menuId: string]: number;
		};
		menusL: {
			[menuId: string]: number;
		};
	};
	menuExtraPrices?: {
		[productId: string]: number;
	};
	suggestionMenuPrices?: {
		[productId: string]: number;
	};
	daypartPrices?: {
		[campaignId: string]: IBKCampaignLocalPricesData;
	};
	campaignAvailability: { [campaignId: string]: boolean };
	ingredientAvailability?: { [ingredientId: string]: boolean };
	productActive?: { [productId: string]: boolean };
	ingredientActive?: { [ingredientId: string]: boolean };
	crownPrices?: { [productId: string]: number };
	kitchenRedirection?: {
		[configIdx: string]: {
			[productId: string]: number;
		};
	};
	campaignMediasFilters?: {
		[campaignId: string]: number[];
	};
	campaignFilters?: {
		[campaignId: string]: number[];
	};
	//Unable to use, error: circular dependency occurs during commit.
	//priceDefinitions: LocalPriceDefinitionSchema;
	priceDefinitions?: {
		product: {
			[productId: string]: ILocalPriceDefinition;
		};
		menu: {
			[menuId: string]: ILocalPriceDefinition;
		};
		ingredient: {
			[ingredientId: string]: ILocalPriceDefinition;
		};
	};
}

export interface IBKCashParameters {
	initialCashFund: number;
	alertWidthdraw: number;
	cashFoundNumber: number;
}

export interface IBKMoneyConfig {
	label: string;
	value: number;
}

export interface IBKCurrentRestoSettings {
	currentKioskMenu: number;
	currentCashMenu: number;
	currentOrbConfig: number;
	currentKdsConfig: number;
	currentCodConfig?: number;
	currentDriveConfig?: number;
	currentDrivePay?: IBKMachine;
	currentDriveCOD1?: IBKMachine;
	currentDriveCOD2?: IBKMachine;
	currentCODConfig?: number;
	printerKds?: { [kdsIdx: string]: number };
}

export interface IBKCurrentRestoConfig {
	timestamp?: number;
	restosettings: IBKCurrentRestoSettings;
	cashFundParameters?: IBKCashParameters;
	moneyconfig?: IBKMoneyConfig[];
	restostate?: number;
	disableAllKiosks?: boolean;
	disableKiosks?: number[];
	blackScreenKiosks?: number[];
	messageOnTicket?: string;
	toiletCode?: string;
	maxCrownPerEmployee?: number;
	maxRestTR?: number;
	kioskMultipayMessage?: string;
	kioskMultipayMessageLangs?: { [lang: string]: string };
	kioskMultipayMode?: number;
	enableEatInOnKiosk?: boolean;
	enableMultipayOnKiosk?: boolean;
	enableKingdom?: boolean;
	enableORBDigital?: boolean;
	payingPosMultipayMode1?: number;
	payingPosMultipayMode2?: number;
	printCODTicket?: boolean;
	printCODLongTicket?: boolean;
	printTicketOnAllBurgers?: boolean;
	enableKingdomForEmployee?: boolean;
	employeeMealInEuros?: boolean;
	openDrawerOnEmployeeMeal?: boolean;
	alertDayForDumpSafe?: number;
	preparationAheadTimeWebS?: number;
	/**
	 * IP of target machine to process payment.
	 * Used for DELIVERY and Click & Collect and backup for PAX orders.
	 */
	cashTargetWebOrder?: string;
	/**
	 * IP of target machine to process payment.
	 * Used for PAX orders created inside restaurant (POSPAX).
	 */
	cashTargetPaxOrderInstore?: string;
	/**
	 * IP of target machine to process payment.
	 * Used for PAX orders created on drive (DRIVEPAX).
	 */
	cashTargetPaxOrderDrive?: string;
	driveSortByFirstProduct?: boolean;
	tableService?: boolean;
	terraceAvailableForTableService?: boolean;
	waitAtTheTable?: boolean;
	hasWalkByWindow?: boolean;
	franchiseeCanEditLocalPrices?: boolean;
	franchiseeCanEditDaypartPrices?: boolean;
	optionNotToPrintTicketEnabled?: boolean;
	optionNotToPrintTicketMaxPrice?: number;
	useSmallSafe?: boolean;
	enablePaymentCarteGR?: boolean;
	kioskCashPaymentMode?: string;
	liveBasketModeEnabled?: boolean;
	enableEnvelopeManagement?: boolean;
	showProductsOnSimplifiedFiscalTicket?: boolean;
}

export interface IBKPickUpTypeParkingDetails {
	licensePlateNumber: string;
	carMakeAndModel?: string;
	carColour?: string;
	parkingSlot?: string;
}

/*******************************************************************************
 * Consolidation datas
 ******************************************************************************/

/**
 * This class is used in the cassandra db
 *
 * @bkCassandra
 * @bkCassandraTable BKConstants.TABLE_ORDERS_EX
 * @bkCassandraTablePrimary businessday,orderuuid
 * @bkCassandraTableUUID 4f2d9225-2a27-4b76-8c87-6480e9bc4509
 */
export interface IBKCassandraOrder {
	/**
	 * businessday int
	 * @s3dInteger
	 */
	businessday: number;

	/**
	 * UUID of the line
	 * orderuuid uuid
	 * @bkCassandraType uuid
	 * @bkCassandraUUIDPatch
	 */
	orderuuid: string;

	/**
	 * orderidx bigint
	 * @s3dLong
	 */
	orderidx: number;

	/**
	 * mdmorder frozen <ibkpublishedorderdata>
	 */
	mdmorder: IBKPublishedOrderData;

	/**
	 * modificationdate timestamp
	 * @bkCassandraType timestamp
	 * @s3dLong
	 */
	modificationdate: number;

	/**
	 * csicmd frozen <ibkorderelementwithcsiinfos>
	 */
	csicmd: IBKOrderElementWithCsiInfos;

	/**
	 * csiticket frozen <ibkorderelementwithcsiinfos>
	 */
	csiticket: IBKOrderElementWithCsiInfos;

	/**
	 * csiraw text
	 */
	csiraw: string;

	/**
	 * consolidation int
	 * @s3dInteger
	 */
	consolidation: number;

	/**
	 * consolidatedttc double
	 * @s3dDouble
	 */
	consolidatedttc: number;

	/**
	 * consolidatedht double
	 * @s3dDouble
	 */
	consolidatedht: number;

	/**
	 * voided boolean
	 */
	voided: boolean;

	/**
	 * voidedby frozen <ibkuserdata>
	 */
	voidedby: IBKUserData;

	/**
	 * mdmpay frozen <ibkorderpaydata>
	 */
	mdmpay: IBKOrderPayData;

	/**
	 * sos frozen <ibksosorderrecord>
	 */
	sos: IBKSOSOrderRecord;

	/**
	 * minuteofday int
	 * @s3dInteger
	 */
	minuteofday: number;

	/**
	 * machinename text
	 */
	machinename: string;

	/**
	 * role text
	 */
	role: string;

	/**
	 * roleidx int
	 * @s3dInteger
	 */
	roleidx: number;

	/**
	 * deliverymode int
	 * @s3dInteger
	 */
	deliverymode: number;

	/**
	 * src int
	 * @s3dInteger
	 */
	src: number;

	/**
	 * orb int
	 * @s3dInteger
	 */
	orb: number;

	/**
	 * ordertotal double
	 * @s3dDouble
	 */
	ordertotal: number;

	/**
	 * firstproductdate timestamp
	 * @bkCassandraType timestamp
	 * @s3dLong
	 */
	firstproductdate: number;
}

/**
 * This class is used in the cassandra db on the global side
 * @bkCassandraTable BKConstants.TABLE_ORDERS_EX
 * @bkCassandraTablePrimary (businessday,restoid),orderuuid
 * @bkCassandraTableUUID e7fcf978-dd3c-4e60-9307-2cb063129e1f
 */
export interface IBKCassandraOrderWithRestaurant extends IBKCassandraOrder {
	/**
	 * @bkCassandraType uuid
	 * @bkCassandraUUIDPatch
	 */
	restoid: string;
}

/**
 * This class is used in the cassandra db on the global side
 * @bkCassandraTable BKConstants.TABLE_BUSINESSDAY_SUMUP
 * @bkCassandraTablePrimary restoid,businessday
 * @bkCassandraTableUUID db0d158b-888f-4100-906b-1c0fba3cfc2c
 */
export interface IBKCassandraBusinessDaySumup {
	/**
	 * businessday int
	 * @s3dInteger
	 */
	businessday: number;

	/**
	 * @bkCassandraType uuid
	 * @bkCassandraUUIDPatch
	 */
	restoid: string;

	/**
	 * consolidatedttc double
	 * @s3dDouble
	 */
	consolidatedttc: number;

	/**
	 * consolidatedht double
	 * @s3dDouble
	 */
	consolidatedht: number;

	/**
	 * ordertotal double
	 * @s3dDouble
	 */
	ordertotal: number;

	/**
	 * @s3dInteger
	 */
	orderCount: number;
}

export interface IBKMessageBase {
	bktype: string;

	/**
	 * Tracking property.
	 *
	 * Type of device/application which has sent the message.
	 */
	applicationName?: string;

	/**
	 * Tracking property.
	 *
	 * Additional metadata about the Device.
	 * Such as ORB ID in case of ORBp,
	 * or DriveCo, DrivePay in case of BK-CASH.
	 *  */
	deviceId?: string;

	/**
	 * Tracking property.
	 *
	 * When the message was created.
	 */
	timestamp?: number;

	/**
	 * Optional info about the device which has sent the ring message.
	 */
	machine?: IBKMachine;
}


export interface IBKMessageShowMenuOnSmartCOD extends IBKMessageBase {
	menuSteps: MenuStepType[];
	menuId: number;
	menuName: string;
	menuSize: MenuSize;
	existsInL: boolean,
	existsInXL: boolean,

	/**
	 * How much does it cost to buy bigger menu?
	 */
	extraPrice: number | undefined;
}

export interface IBKMessageToggleWelcomeScreenOnSmartCOD extends IBKMessageBase {
	display: boolean;
}

export interface IBKMessageSmartCODBasketPreviewFullscreen extends IBKMessageBase {
	isFullScreen: boolean
}

export interface IBKMesssageSmartCodORBNumber extends IBKMessageBase {
	orbNumber: number;
	orderState: BKOrderState;
}

export interface IBKMesssageSmartCodFidelityData extends IBKMessageBase {
	fidelityData: IBKKingdomClientData;
	convertiblePoints: FidelityDiscountConvertibleToItems[];
	classicPrivileges: ClassicPrivilegeDiscount[];
	currSelectedPrivilegeId: number;
	displayedPrivilegesType: FIDELITY_PRIVILEGES;
}

export interface IBKMesssageSmartCodFidelityLoginError extends IBKMessageBase {
	fidelityLoginError: BKKingdomClientResultEnum;
}

export interface IBKMessageKDSCsiPrintSpeTicket extends IBKMessageBase {
	printerIndex: number;
	doc: string;
	script: string;
}

export interface IBKMessageDSSPrintTestTicket extends IBKMessageBase {
	orbpId: string;
	programId: string;
}

export enum AssignedOrbpPrinterTypeEnum {
	Grouped = <any>'grouped',
	Separated = <any>'separated',
	Simplified = <any>'simplified',
}

export interface EmployeeMealEventArguments {
	employee: {
		id: number;
		firstName: string;
		lastName: string;
		rhId: string;
	};

	manager: {
		id: number;
		firstName: string;
		lastName: string;
	};
}

export interface ILocalPriceDefinition {
	/**
	 * ID of Product, Menu or Ingredient
	 */
	id: string;
	/**
	 * Name of the Product, Menu or Ingredient
	 */
	name: string;

	/**
	 * Same interface as LocalPriceDefinitionPerSalesChannel.ts in price-management-common.
	 * Duplicated to prevent cyclic-dependency
	 */
	values: {
		salesChannelOption: any;
		rowIdentifier: string;
		/**
		 * Price defined Globally for this PriceLevel
		 */
		referencePrice: number;
		/**
		 * Optional.
		 * Localy defined Price overriding the global one.
		 * localPrice is read only when "isDisabled === false"
		 */
		localPrice?: number;
		/**
		 * When there is no global price defined, then the field is disabled in local restaurant.
		 * Meaning that this salesChannelOption will not offer that product.
		 */
		isDisabled: boolean;
	}[];
}

/**
 * Reload ORBp/ORBs devices when ORBp ${originatedFromOrbpId} has switched configuration
 */
export interface IBKMessageReloadORBDevice extends IBKMessageBase {
	originatedFromOrbpId?: number;
	forceReboot?: boolean;
}

export interface IBKMessageSmartCODHeartBeat extends IBKMessageBase {
	smartCodId: number;
	// ID of paired DriveCo or DrivePay
	posId: number;
	posRole: POS_ROLE,
	requestInitialData?: boolean
}

export interface IBKMessageSmartCODShuttingDown extends IBKMessageBase {
	smartCodId: number;
	// ID of paired DriveCo or DrivePay
	posId: number;
}

export interface IBKMessageSmartCODVoiceChatEvent extends IBKMessageBase {
	//#region for pairing
	smartCodId: number;
	posId: number;
	posRole: POS_ROLE,
	//#endregion

	voiceChatEvent: VoiceChatEvent;
}

export interface IBKMessageSmartCODVoiceChatOrderPreview extends IBKMessageBase {
	//#region for pairing
	smartCodId: number;
	posId: number;
	posRole: POS_ROLE;
	//#endregion

	// OrderPreview from VoiceChat comes in different format then used internally in Merim
	voiceChatOrder: VoiceChatOrder;
}


export interface IBKMessageDisableKiosk extends IBKMessageBase {
	/**
	 * @s3dInteger
	 */
	kioskId: number
}

export interface IBKMessageBlackScreenKiosks extends IBKMessageBase {
	/**
	 * @s3dInteger
	 */
	kioskId: number
}

export interface ISelectedIngredients {
	/**
	 * Quantity
	 * @s3dInteger
	 */
	qty: number;
	/**
	 * ID
	 * @s3dInteger
	 */
	id: number;

	name?: string;

	/**
	* @s3dPHPSubModelColumn
	*/
	price?: IBKItemPriceInfo;
	/**
	 * UUID of the line
	 * @bkCassandraType uuid
	 * @bkCassandraUUIDPatch
	 */
	lineuuid?: string;

	ref?: IBKItemReference;
}

export interface IPMSDisplayedData extends IBKMessageBase {
	products: IPMSDisplayedProducts[],
}

export interface IPMSProductState extends IBKMessageBase {
	state: PmsCurrentStatus,
	id: number
}
