/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DriveScreenConfigDataDto { 
    /**
     * Index of this screen
     */
    screenIdx: number;
    /**
     * If true, this screen act as a slave
     */
    isSlave: boolean;
    /**
     * If the screen is a slave, index of the master
     */
    parentIdx: number;
    assemblyAreas: Array<number>;
    /**
     * Mask of the sources for the order to display
     */
    sourceMask?: number;
}

