/**
 * DKI-BE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PermissionLevelApplyDefine } from './permission-level-apply-define';


export interface PermissionInfoApplyDefine {
	name: PermissionInfoApplyDefine.NameEnum;
	level?: PermissionLevelApplyDefine;
}

export namespace PermissionInfoApplyDefine {
	export type NameEnum = 'sw_management' | 'data_update_management';
	export const NameEnum = {
		SwManagement: 'sw_management' as NameEnum,
		DataUpdateManagement: 'data_update_management' as NameEnum
	};
}


