/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type FoodCategoryIcon = 'UNKNOWN' | 'HOT_DRINKS' | 'COLD_DRINKS' | 'REFRIGERATED_DRINKS' | 'BURGERS' | 'FRIED_PRODUCTS' | 'FRIES' | 'HOT_DISH_BOWL' | 'HOT_SALAD_BOWL' | 'COLD_SALAD_BOWL' | 'HOT_DESSERTS' | 'COLD_DESSERTS' | 'DESSERT_IN_GLASS' | 'ICE_CREAMS' | 'DONUT' | 'FRUITS' | 'PASTRIES' | 'HOT_CAKE' | 'MILK_SHAKE' | 'DRESSINGS' | 'SAUCES' | 'TOYS' | 'TICKETS';

export const FoodCategoryIcon = {
    Unknown: 'UNKNOWN' as FoodCategoryIcon,
    HotDrinks: 'HOT_DRINKS' as FoodCategoryIcon,
    ColdDrinks: 'COLD_DRINKS' as FoodCategoryIcon,
    RefrigeratedDrinks: 'REFRIGERATED_DRINKS' as FoodCategoryIcon,
    Burgers: 'BURGERS' as FoodCategoryIcon,
    FriedProducts: 'FRIED_PRODUCTS' as FoodCategoryIcon,
    Fries: 'FRIES' as FoodCategoryIcon,
    HotDishBowl: 'HOT_DISH_BOWL' as FoodCategoryIcon,
    HotSaladBowl: 'HOT_SALAD_BOWL' as FoodCategoryIcon,
    ColdSaladBowl: 'COLD_SALAD_BOWL' as FoodCategoryIcon,
    HotDesserts: 'HOT_DESSERTS' as FoodCategoryIcon,
    ColdDesserts: 'COLD_DESSERTS' as FoodCategoryIcon,
    DessertInGlass: 'DESSERT_IN_GLASS' as FoodCategoryIcon,
    IceCreams: 'ICE_CREAMS' as FoodCategoryIcon,
    Donut: 'DONUT' as FoodCategoryIcon,
    Fruits: 'FRUITS' as FoodCategoryIcon,
    Pastries: 'PASTRIES' as FoodCategoryIcon,
    HotCake: 'HOT_CAKE' as FoodCategoryIcon,
    MilkShake: 'MILK_SHAKE' as FoodCategoryIcon,
    Dressings: 'DRESSINGS' as FoodCategoryIcon,
    Sauces: 'SAUCES' as FoodCategoryIcon,
    Toys: 'TOYS' as FoodCategoryIcon,
    Tickets: 'TICKETS' as FoodCategoryIcon
};

