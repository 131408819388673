/**
 * Create and manage the Order
 */
export const DRIVE_CO = 'DRIVE_CO';
/**
 * DriveCo + Payment.
 */
export const DRIVE_PAY = 'DRIVE_PAY';

export type POS_ROLE = typeof DRIVE_CO | typeof DRIVE_PAY;
