import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { EtagInterceptor } from './lib/etag.interceptor';

export * from './lib/etag.interceptor';

export const cacheInterceptorProviders = [
	{
		provide: HTTP_INTERCEPTORS,
		useClass: EtagInterceptor,
		multi: true,
	},
];
