export enum AssemblyArea {
	BURGERS = 3,
	SNACK_FRITES = 7,
	SALADS = 6,
	COLD_DRINKS = 2,
	ICE_CREAM = 10,
	DESSERT = 5,
	HOT_DRINKS = 1,
	OTHER = 4,
	TOYS = 11,
	UNKNOWN = 0,
	SAUCE = 12,
}
