/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface RestaurantDto { 
    /**
     * Name of the restaurant.
     */
    name: string;
    /**
     * Main restaurant\'s identifier - UUIDv4.
     */
    uuid: string;
    /**
     * Restaurant\'s address (main address line).
     */
    address: string;
    /**
     * Restaurant\'s address (optional secondary address line).
     */
    addressExtra?: string;
    /**
     * Restaurant\'s address (city).
     */
    city: string;
    /**
     * Restaurant\'s ZIP code.
     */
    zipCode: string;
    /**
     * Restaurant\'s address (country).
     */
    country: string;
    /**
     * Time when business day (fiscal day) starts at the restaurant.
     */
    businessDayStartTime: string;
}

