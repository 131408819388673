import { IBKItemInOrderBase, IBKPublishedOrderData } from "@bk/jscommondatas";

export function shouldShowDeliveryLaterProduct(order: IBKItemInOrderBase, showDeliveryLaterProducts: boolean): boolean {
	if(showDeliveryLaterProducts) {
		return true;
	}

	return order.later !== true;
}

export function patchOrderContentDeliveryLater(order: IBKPublishedOrderData, showDeliveryLaterProducts: boolean): void {
	order.orderContent = order?.orderContent?.filter((productInOrder) => {
		if(productInOrder.selection?.length > 0) {
			productInOrder.selection = productInOrder.selection.filter((menuItem) => shouldShowDeliveryLaterProduct(menuItem, showDeliveryLaterProducts));
			return productInOrder.selection.length > 0;
		}

		return shouldShowDeliveryLaterProduct(productInOrder, showDeliveryLaterProducts);
	}) || [];
}