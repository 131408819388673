/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrbpConfigurationBaseDto } from './orbp-configuration-base-dto';


export interface DssProgramOrbpConfigurationDto { 
    /**
     * Id of the DSS program.
     */
    programId?: string;
    /**
     * Configuration of ORBp devices for given DSS program.
     */
    orbps?: Array<OrbpConfigurationBaseDto>;
    /**
     * Flag indicating if the DSS program can be reset.
     */
    canBeResetToFactoryDefault?: boolean;
}

