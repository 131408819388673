/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DrinkCodesDto { 
    /**
     * Codes for SMALL sized drinks.
     */
    smallDrinks: Array<string>;
    /**
     * Codes for MEDIUM sized drinks.
     */
    mediumDrinks: Array<string>;
    /**
     * Codes for LARGE sized drinks.
     */
    bigDrinks: Array<string>;
}

