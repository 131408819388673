import { Shar3dUtils } from './shar3d-utils';

/**
 * Main class for the timer
 */
export class Shar3dUtilsTimer {
	private static $provider: IShar3dUtilsTimerProvider | null = null;

	/**
	 * Get the timer provider
	 */
	private static getProvider(): IShar3dUtilsTimerProvider {
		if (Shar3dUtilsTimer.$provider !== null) return Shar3dUtilsTimer.$provider;
		//  Create it
		if (Shar3dUtils.isBrowser()) {
			Shar3dUtilsTimer.$provider = new Shar3dUtilsTimerProviderBrowser();
		} else {
			Shar3dUtilsTimer.$provider = new Shar3dUtilsTimerProviderNode();
		}
		return Shar3dUtilsTimer.$provider;
	}

	/**
	 * Set a timeout
	 */
	public static setTimeout(callback: (...args: any[]) => void, ms: number /*, ...args: any[]*/): Shar3dUtilsTimerRef {
		return Shar3dUtilsTimer.getProvider().setTimeout(callback, ms);
	}

	public static clearTimeout(timeoutId: Shar3dUtilsTimerRef): void {
		Shar3dUtilsTimer.getProvider().clearTimeout(timeoutId);
	}

	public static setInterval(callback: (...args: any[]) => void, ms: number /*, ...args: any[]*/): Shar3dUtilsTimerRef {
		return Shar3dUtilsTimer.getProvider().setInterval(callback, ms);
	}

	public static clearInterval(intervalId: Shar3dUtilsTimerRef): void {
		Shar3dUtilsTimer.getProvider().clearTimeout(intervalId);
	}
}

/**
 * Timer reference
 */
export abstract class Shar3dUtilsTimerRef {
}

/**
 * Node reference
 */
class Shar3dUtilsTimerRefNode extends Shar3dUtilsTimerRef {
	public constructor(public readonly nodeRef: any) {
		super();
	}
}

/**
 * Browser reference
 */
class Shar3dUtilsTimerRefBrowser extends Shar3dUtilsTimerRef {
	public constructor(public readonly windowRef: number) {
		super();
	}
}

/**
 * Interface for the timers
 */
interface IShar3dUtilsTimerProvider {
	setTimeout(callback: (...args: any[]) => void, ms: number /*, ...args: any[]*/): Shar3dUtilsTimerRef;

	clearTimeout(timeoutId: Shar3dUtilsTimerRef): void;

	setInterval(callback: (...args: any[]) => void, ms: number /*, ...args: any[]*/): Shar3dUtilsTimerRef;

	clearInterval(intervalId: Shar3dUtilsTimerRef): void;
}

class Shar3dUtilsTimerProviderNode implements IShar3dUtilsTimerProvider {
	public setTimeout(callback: (...args: any[]) => void, ms: number): Shar3dUtilsTimerRef {
		return new Shar3dUtilsTimerRefNode(setTimeout(callback, ms));
	}

	public clearTimeout(timeoutId: Shar3dUtilsTimerRef): void {
		if (timeoutId instanceof Shar3dUtilsTimerRefNode) clearTimeout(timeoutId.nodeRef);
		else throw new Error('Invalid type');
	}

	public setInterval(callback: (...args: any[]) => void, ms: number): Shar3dUtilsTimerRef {
		return new Shar3dUtilsTimerRefNode(setInterval(callback, ms));
	}

	public clearInterval(intervalId: Shar3dUtilsTimerRef): void {
		if (intervalId instanceof Shar3dUtilsTimerRefNode) clearInterval(intervalId.nodeRef);
		else throw new Error('Invalid type');
	}
}

class Shar3dUtilsTimerProviderBrowser implements IShar3dUtilsTimerProvider {
	public setTimeout(callback: (...args: any[]) => void, ms: number): Shar3dUtilsTimerRef {
		return new Shar3dUtilsTimerRefBrowser(window.setTimeout(callback, ms));
	}

	public clearTimeout(timeoutId: Shar3dUtilsTimerRef): void {
		if (timeoutId instanceof Shar3dUtilsTimerRefBrowser) window.clearTimeout(timeoutId.windowRef);
		else throw new Error('Invalid type');
	}

	public setInterval(callback: (...args: any[]) => void, ms: number): Shar3dUtilsTimerRef {
		return new Shar3dUtilsTimerRefBrowser(window.setInterval(callback, ms));
	}

	public clearInterval(intervalId: Shar3dUtilsTimerRef): void {
		if (intervalId instanceof Shar3dUtilsTimerRefBrowser) window.clearInterval(intervalId.windowRef);
		else throw new Error('Invalid type');
	}

	/**
	 *
	 * Like a setInterval, expect that the runner function can use await without filling the call stack.
	 *
	 * Usage:
	 * let closeInterval = synchronizedInterval(async () => longRunningTask(), 1000);
	 *
	 * // clean interval
	 * closeInterval();
	 */
	public static synchronizedInterval(runner: () => Promise<void>, ms: number, onError?: (e: Error) => void): () => void {
		let stopInterval = false;

		const internalRunner = async () => {
			try {
				while (!stopInterval) {
					const beforeRun = Shar3dUtils.now();
					await runner().catch((e) => {
						if (onError) return onError(e);
					});
					await Shar3dUtils.wait(Math.max(ms, Shar3dUtils.now() - beforeRun));
				}
			} catch (e) {
				if (onError) return onError(e);
				else throw e; // retrhow, won't harm anyone expect console, the runner is asynchronous
			}
		};

		// start async runner without await
		internalRunner();

		return () => (stopInterval = true);
	}
}
