/**
 * DKI-BE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface MonitoringTaskInfo {
	/**
	 * Name of the application.
	 */
	name: string;
	/**
	 * Version of the application
	 */
	version?: string;
	/**
	 * Timestamp of last application start.
	 */
	last_start_time?: string;
	/**
	 * Timestamp of last application end.
	 */
	last_end_time?: string;
	/**
	 * Amount of restarts. Typically signifies a big problem.
	 */
	start_count: number;
	/**
	 * Flag indicating if attention should be paid to the task. Task with warning is determined by having \'start_count\' more than 1 and \'last_end_time\' of this task was in last 48 hours [configurable] (i.e. the task is restarting).
	 */
	is_warning: boolean;
}

