/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface FidelityFilterDto { 
    /**
     * Type of the fidelity filter to be applied in connection to the limits set by \"limit1\" and \"limit2\" properties.         Values:         NONE = 0,         IDENTIFIED = 1,         IDENTIFIED_ABOVE_LIMIT = 2,         IDENTIFIED_BETWEEN_LIMITS = 3,         NOT_IDENTIFIED = 100
     */
    filter?: number;
    /**
     * First limit for the type of fidelity filter based on \"filter\" property.
     */
    limit1?: number;
    /**
     * Second limit for the type of fidelity filter based on \"filter\" property.
     */
    limit2?: number;
}

