/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SelectionPatternDto } from './selection-pattern-dto';


export interface KioskConfigurationDataDto { 
    /**
     * Id of the item reference in the DB
     */
    id: number;
    /**
     * Name of this configuration
     */
    name: string;
    welcomeMessage?: object;
    /**
     * Ordered screens int the configuration
     */
    screens: Array<number>;
    associatedTargetsForLoop: Array<number>;
    associatedTargetsForTransition: Array<number>;
    associatedTargetsForHome: Array<number>;
    applicableToPattern: Array<SelectionPatternDto>;
    campaignConfig?: boolean;
}

