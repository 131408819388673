import { Shar3dUtils } from './shar3d-utils';
import { Mutex } from './shar3d-utils-sync';

/**
 * Synchronized CRUD list
 * Correctly spelled version
 */
export class Shar3dSynchronizedCRUDList<T /* list type */, U /* selector type */> {
	private lock = new Mutex();

	constructor(
		private readonly matchRecordFn: (selector: U, record: T) => Promise<boolean>,
		private data: T[] = [],
		/**
		 * Optionnal validator method, is called before persists operations, should throw an error if validation fails.
		 */
		private readonly validatorFn: (d: T) => Promise<T> = async (d) => d
	) {
	}

	/**
	 * @throws Error on validaton failed
	 */
	public add(record: T): Promise<boolean> {
		return this.lock.use(async () => {
			this.data.push(await this.validatorFn(record));
			return true;
		});
	}

	/**
	 * Get record by selector.
	 *
	 * @return matched record or undefined if no record found.
	 */
	public get(selector: U): Promise<T | undefined> {
		return this.lock.use(async () => {
			for (const elem of this.data) {
				const found = await this.matchRecordFn(selector, elem);
				if (found) {
					return elem;
				}
			}

			return undefined;
		});
	}

	/**
	 * Get all records
	 */
	public all(): Promise<T[]> {
		return this.lock.use(async () => {
			return this.data;
		});
	}

	/**
	 * Allows to update a record trough the passed in updateFn method.
	 *
	 * @returns true if the update was applied or false if the element could not be found
	 * @throws Error on validation failed
	 */
	public update(selector: U, updateFn: (existing: T) => Promise<T>): Promise<boolean> {
		return this.lock.use(async () => {
			let index: number | null = null;
			for (const elem of this.data) {
				const found = await this.matchRecordFn(selector, elem);
				if (found) {
					index = this.data.indexOf(elem);
					const toAdd = await updateFn(elem);
					this.data[index] = await this.validatorFn(toAdd);
					return true;
				}
			}

			return false;
		});
	}

	/**
	 * Remove the element at selector and return its value
	 *
	 * @returns the removed element or undefined if the element was not found
	 */
	public remove(selector: U): Promise<T | undefined> {
		return this.lock.use(async () => {
			let index: number | null = null;
			for (const elem of this.data) {
				const found = await this.matchRecordFn(selector, elem);
				if (found) {
					index = this.data.indexOf(elem);
					this.data = Shar3dUtils.without(this.data, elem);
					return elem;
				}
			}
			return undefined;
		});
	}

	/**
	 * Removes all elements of the list
	 */
	public clear() {
		return this.lock.use(async () => {
			this.data = [];
		});
	}
}

/**
 * @deprecated Please use the correctly spelled version : Shar3dSynchronizedCRUDList
 */
export class Shar3dSyncrhonizedCRUDList<T, U> extends Shar3dSynchronizedCRUDList<T, U> {
	constructor(matchRecordFn: (selector: U, record: T) => Promise<boolean>, data: T[] = [], validatorFn: (d: T) => Promise<T> = async (d) => d) {
		super(matchRecordFn, data, validatorFn);
	}
}
