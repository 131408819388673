import cloneDeep from 'lodash-es/cloneDeep';

export class UniqueDirectiveModel {

	key: string;
	entries: Record<string, any>[];

	constructor(init?: Partial<UniqueDirectiveModel>) {
		Object.assign(this, cloneDeep(init));
	}
}
