/**
 * DKI-BE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MonitoringOverallDevices } from './monitoring-overall-devices';
import { MonitoringOverallRestaurants } from './monitoring-overall-restaurants';


export interface MonitoringOverall {
	/**
	 * Overall information about devices in all the restaurants.
	 */
	devices: MonitoringOverallDevices;
	/**
	 * Overall information about all the restaurants.
	 */
	restaurants: MonitoringOverallRestaurants;
}

