export type PredictionKitchen = {
	dateKey: string;
	details: PredictionKitchenDetail[];
};

export type PredictionKitchenDetail = {
	/**
	 * Start of the predicted interval.
	 */
	dateTime: Date;
	/**
	 * Length of the interval for prediction in milliseconds.
	 */
	timeInterval: number;
	/**
	 * Items with predictions. Ordered by priority.
	 */
	salesMap: PredictionSalesMap[];
};

export type PredictionSalesMap = {
	/**
	 * Predicted item ID.
	 */
	id: string;
	/**
	 * Sales prediction (user defined or automatic if not defined by user).
	 */
	prediction: number;
};
