/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CampaignNavigationMenuDto { 
    /**
     * Priority of the menu.         Values:         LOW = 0,         NORMAL = 1,         HIGH = 2
     */
    priority: number;
    /**
     * Navigation screen ids for the KIOSK/POS/DRIVEPAY/DRIVECO.
     */
    navigationScreenIds: Array<number>;
    /**
     * Configuration ids for the KIOSK/POS/DRIVEPAY/DRIVECO.
     */
    navigationConfigurationIds: Array<number>;
}

