import { getRandomNumber } from './number-utils';
import { isNumber, isString } from './object-utils';

/**
 * Works for array of primitive values
 */
export function removeFromArray<T>(arr: T[], itemToRemove: T): T[] {
	return arr.filter((item) => item !== itemToRemove);
}

/**
 * Adds to array if not already included.
 */
export function addToArrayIfNotIncluded<T>(arr: T[], itemToAdd: T): T[] {
	if (!arr.includes(itemToAdd)) {
		arr.push(itemToAdd);
	}
	return arr;
}

/**
 * Get unique values from an Array
 */
export function getUniqueValues<T>(arr: T[]): T[] {
	if (!arr || arr.length === 0) {
		return arr;
	}

	const theSet = new Set(arr);
	const uniqueArray: T[] = [];
	theSet.forEach((x) => {
		uniqueArray.push(x);
	});
	return uniqueArray;

	// We can't have nice things now.
	// return [...new Set(arr)]; // Fails in runtime, it is transpiled to "tslib_1.__spreadArrays(theSet);" which seems not work with Set
}

/**
 * Function to get unique identificator of an Object
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type GetIdFn = (item: any) => string | number;

/**
 * Get unique values from an Array using custom comparator fn
 */
export function getUniqueValuesByComparator<T>(arr: T[], getId: GetIdFn): T[] {
	if (!arr || arr.length === 0) {
		return arr;
	}

	const ids: (number | string)[] = [];
	const uniqueArray: T[] = [];

	arr.forEach((x) => {
		const id = getId(x);
		const alreadyUsed = ids.includes(id);
		if (!alreadyUsed) {
			ids.push(id);
			uniqueArray.push(x);
		}
	});
	return uniqueArray;
}

export function flattenArray<T>(arrays: T[][]): T[] {
	const result: T[] = [];

	arrays.forEach((arr) => {
		result.push(...arr);
	});

	return result;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isArrayOfStrings(arr: any[]): boolean {
	return arr.every(isString);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isEmptyArrayOfStrings(arr: any[]): boolean {
	if (arr && isArrayOfStrings(arr) && arr.length > 0) {
		return false;
	}
	return true;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isArrayOfNumber(arr: any[]): boolean {
	return arr.every(isNumber);
}

/**
 * Example of usage:
 * arrayOfNumbers.sort(sortNumber)
 *
 * Motivation:
 * arrayOfNumbers.sort() will sort it alphabetically, so 11 would be before 2.
 */
export function sortNumber(a: number, b: number): number {
	return a - b;
}

/**
 * Sorts Array and returns new instance of Array
 */
export function sortByNumericProperty<T extends { [key: string]: number }>(arr: T[], propertyName: string): T[] {
	const nextArr = [...arr];
	return nextArr.sort((a, b) => {
		const aProp = a[propertyName];
		const bProp = b[propertyName];
		return aProp > bProp ? 1 : aProp < bProp ? -1 : 0;
	});
}

/**
 * Sorts Array and returns new instance of Array
 */
export function sortByStringProperty<T extends { [key: string]: string | number | Date }>(arr: T[], propertyName: string): T[] {
	const nextArr = [...arr];
	return nextArr.sort((a, b) => {
		const aProp = a[propertyName].toString();
		const bProp = b[propertyName].toString();
		return aProp > bProp ? 1 : aProp < bProp ? -1 : 0;
	});
}

/**
 * Because sometimes the object looks like this:
 * {bbb: 'x', c: 'x', a: 'x'}
 * but you need to properties sorted alphabetically, like this
 * {a: 'x', bbb: 'x', c: 'x'}
 */
export function sortObjectProperties(obj: Record<string, any>): Record<string, any> {
	const keys = Object.keys(obj);
	const sortedKeys = keys.sort();
	const result: Record<string, any> = sortedKeys.reduce((acc, currentProperty) => {
		const value = obj[currentProperty];
		acc[currentProperty] = value;
		return acc;
	}, {} as Record<string, any>);
	return result;
}

/**
 * Converts Array of Objects in a Map
 * where Object.id is the key
 */
export function arrayToMap<T>(arr: T[]): { [id: string]: T } {
	const result: { [id: string]: T } = arr.reduce((acc, item) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const id = (<any>item).id;

		if (id === undefined) {
			const errMessage = 'Object in array does not have property id';
			console.error(errMessage);
			throw new Error(errMessage);
		}

		return { ...acc, ...{ [id]: item } };
	}, {});

	return result;
}

export function mapToArray<T>(map: { [id: string]: T }): T[] {
	const keys = Object.keys(map);
	return keys.map((k) => map[k]);
}

/**
 * Get random item from Array
 */
export function getRandomItem<T>(arr: T[]): T {
	const rndIndex = getRandomNumber(0, arr.length - 1);
	return arr[rndIndex];
}

/**
 * Count same items in array
 */
export function getCountOfItemsInArray(arr: string[]): Record<string, number> {
	const counts: Record<string, number> = {};
	arr.forEach((item) => {
		counts[item] = (counts[item] || 0) + 1;
	});

	return counts;
}

export type ArrayEqualityOptions = {
	/**
	 * [1, 2] would be equal to [1, 2, 2].
	 * By default this is false.
	 */
	ignoreDuplicateValues?: boolean
}

export function areArraysEqual<T>(array1: T[], array2: T[], options?: ArrayEqualityOptions): boolean {
	let sortedArray1 = array1.slice().sort();
	let sortedArray2 = array2.slice().sort();

	// Arr
	if (options?.ignoreDuplicateValues) {
		sortedArray1 = getUniqueValues(sortedArray1);
		sortedArray2 = getUniqueValues(sortedArray2);
	} else {
		if (array1.length !== array2.length) {
			return false;
		}
	}

	for (let i = 0; i < sortedArray1.length; i++) {
		if (sortedArray1[i] !== sortedArray2[i]) {
			return false;
		}
	}

	return true;
}

// TODO: Add unit tests
/**
 * Merge arbitrary number of arrays.
 *
 * Example:
 * We have arrays A, B, C.
 * The arrays may differ in their lenght.
 *
 * When A has length 5, B lenght 4 and C length 3.
 * Merged result will be:
 * [A1, B1, C1, A2, B2, C2, A3, B3, C3, A4, B4, A5]
 */
export function mergeArrays<T>(...arrays: T[][]): T[] {
	if (arrays.length === 0) {
		return [];
	}

	if (arrays.length === 1) {
		return arrays[0];
	}

	const numberOfArrays = arrays.length;
	const maxLength = arrays.map(a => a.length).sort().reverse()[0];
	const result: T[] = [];

	for (let i = 0; i < maxLength; i++) {
		for (let n = 0; n < numberOfArrays; n++) {
			const arr = arrays[n];
			const item = arr[i];
			if (item !== undefined) {
				result.push(item);
			}
		}
	}

	return result;
}

/**
 * Returns true if the unordered contents of the two arrays are disctinct, not identical.
 * The order of values in the arrays does not affect the comparison, we are only checking values.
 * This function also considers duplicate values.
 * @param array1 (string | number)[]
 * @param array2 (string | number)[]
 * @returns boolean - Returns true if the arrays have different values.
 */
export function areUnorderedArraysDistinct(array1: (string | number)[], array2: (string | number)[]): boolean {
	const sortedArray1 = [...array1].sort();
	const sortedArray2 = [...array2].sort();

	if (sortedArray1.length !== sortedArray2.length) {
		return true;
	}
	for (let i = 0; i < sortedArray1.length; i++) {
		if (sortedArray1[i] !== sortedArray2[i]) {
			return true;
		}
	}
	return false;
}
