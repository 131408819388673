import { PERMISSION_OPERATIONS, PERMISSIONS } from '@bk/frontend-common';
import { NavigationTileActionType } from '@libs/shared/modules/navigation-tile';
import { clone } from 'ramda';
import { MENU_MODEL_ACTION_CONDITION } from './enums';

export class MenuModel<T> {
	text: string;
	icon: string;
	url: string;
	submenu: SubMenuModel<T>[];
	condition?: MENU_MODEL_ACTION_CONDITION;
	module?: T;
	permissions?: MenuPermissionsModel;
	// alternative env file key  which can be checked in order to allow access to menu item
	// has to start with a name of a main module e.g. restaurant.options.ownerManagement
	enabled?: string;
	color?: string;
	description?: string; // translation key
	actions?: MenuActionModel<T>[];

	constructor(init?: Partial<MenuModel<T>>) {
		Object.assign(this, clone(init));
	}
}

export class MenuPermissionsModel {
	key: PERMISSION_OPERATIONS;
	minimal: PERMISSIONS;

	constructor(init?: Partial<MenuPermissionsModel>) {
		Object.assign(this, clone(init));
	}
}

export class StoreMenuSubmodelMenu<T> {
	url: string;
	data: MenuModel<T>[];

	constructor(init?: Partial<StoreMenuSubmodelMenu<T>>) {
		Object.assign(this, clone(init));
	}
}

export type SubMenuModel<T> = Omit<MenuModel<T>, 'submenu'>;

export class MenuActionModel<T> {
	condition: MENU_MODEL_ACTION_CONDITION;
	text: string;
	url: string;
	action: NavigationTileActionType;
	subMenuModel?: SubMenuModel<T>;

	constructor(init?: Partial<MenuActionModel<T>>) {
		Object.assign(this, clone(init));
	}
}
