/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CampaignLocalPricesDto } from './campaign-local-prices-dto';
import { AvailabilityCrownsDto } from './availability-crowns-dto';
import { LocalPriceDefinitionsDto } from './local-price-definitions-dto';
import { AvailabilityPricesDto } from './availability-prices-dto';


export interface AvailabilityDto { 
    /**
     * Unix timestamp indicating the last update to the file (in seconds).
     */
    timestamp?: number;
    /**
     * Dictionary of products\' availability by ID.
     */
    availability: { [key: string]: boolean; };
    /**
     * Items prices.
     */
    price?: AvailabilityPricesDto;
    /**
     * Items prices in crowns.
     */
    crowns?: AvailabilityCrownsDto;
    /**
     * Dictionary of products\' extra menu prices by ID.
     */
    menuExtraPrices?: { [key: string]: number; };
    /**
     * Dictionary of products\' suggestion menu prices by ID.
     */
    suggestionMenuPrices?: { [key: string]: number; };
    /**
     * Dictionary of products\' daypart prices by ID.
     */
    daypartPrices?: { [key: string]: CampaignLocalPricesDto; };
    /**
     * Dictionary of campaigns\' availability by ID.
     */
    campaignAvailability: { [key: string]: boolean; };
    /**
     * Dictionary of ingredients\' availability by ID.
     */
    ingredientAvailability?: { [key: string]: boolean; };
    /**
     * Dictionary of products\' active states by ID.
     */
    productActive?: { [key: string]: boolean; };
    /**
     * Dictionary of ingredients\' active states by ID.
     */
    ingredientActive?: { [key: string]: boolean; };
    /**
     * Dictionary of products\' crown prices by ID.
     */
    crownPrices?: { [key: string]: number; };
    /**
     * Dictionary of kitchen redirections\' dictionaries by product ID.
     */
    kitchenRedirection?: { [key: string]: { [key: string]: number; }; };
    /**
     * Dictionary of campaigns\' media filters by ID.
     */
    campaignMediasFilters?: { [key: string]: Array<number>; };
    /**
     * Dictionary of campaigns\' filters by ID.
     */
    campaignFilters?: { [key: string]: Array<number>; };
    /**
     * Local price definitions for price management.
     */
    priceDefinitions?: LocalPriceDefinitionsDto;
}

