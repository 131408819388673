import { EnumMap } from '@merim/utils';

import { AssignedOrbpPrinterType, LoadDistribution, OrbpRole, OrbStatus } from '../enums';
import { SortingOrdersAlgorithm } from '../enums/sorting-orders-algorithm';
import { OrbpSplitRowsConfiguration } from "./orbp-split-rows-configuration";
import { SalesChannelConfiguration } from './sales-channel-configuration';

/**
 * ORBp Detail stored in RAP BE.
 *
 * Stored in user-defined-programs.json
 */
export type OrbpDetail = {
	/**
	 * This is actually a stringified number
	 */
	id: string;
	/**
	 * Critial property. See ORBP_CRITICAL_PROPERTIES.
	 */
	status?: OrbStatus;
	/**
	 * Acts as a nickname. For example "Burger Table #1"
	 */
	name: string;
	/**
	 * Critial property. See ORBP_CRITICAL_PROPERTIES.
	 */
	role: OrbpRole;
	/**
	 * Critial property. See ORBP_CRITICAL_PROPERTIES.
	 *
	 * Points to another OrbpDetail.id, from which the Dependent or Slave Orb inherits the settings.
	 * Dependent may have multiple Master.
	 * Slave only 1.
	 * Master does not have any.
	 */
	masterIds?: string[];

	/**
	 * If no other ORBp/Printer is configured to display that SalesChannel, it will be displayed on this ORBp/Printer.
	 * Only for Master ORBp.
	 * Only 1 ORBp can be set as default.
	 */
	isDefaultForSalesChannel: boolean;

	/**
	 * Critial property. See ORBP_CRITICAL_PROPERTIES.
	 *
	 * Mirror has the same value as his Master
	 */
	salesChannelConfigurations?: SalesChannelConfiguration[];

	/**
	 * Mirror has the same value as his Master
	 */
	foodCategoryConfiguration: EnumMap;

	// WARNING. If you rename this property, then also update the reference in OrbpValidatedProperties.
	/**
	 * Critial property. See ORBP_CRITICAL_PROPERTIES.
	 *
	 * Mirror has the same value as his Master
	 */
	loadDistribution: LoadDistribution;

	/**
	 * In minutes.
	 * Minimal value is 1 minute. Usual value is 5 mi
	 * nutes.
	 */
	alertTime: number;
	clientScreenOrbIds: Array<string>;
	/**
	 * One printer can be assigned to only one ORBp
	 */
	assignedOrbpPrinterId?: string;

	/**
	 * Printer type configuration
	 * https://reboot-qsr.atlassian.net/jira/software/c/projects/DEV/boards/19?modal=detail&selectedIssue=DEV-1267
	 */
	assignedOrbpPrinterType?: AssignedOrbpPrinterType;

	bumpbarEnabled: boolean;

	canParkOrders?: boolean;

	/**
	 * Require confirmation of 1st bumped Order when it does not have all Items marked as Checked.
	 */
	requireConfirmationOfIncompleteOrder: boolean;

	simplificationOrders?: boolean;

	/**
	 * How should ORBp sort the Orders?
	 *
	 * This field has stored value only for Master ORBp.
	 * Dependent ORBp will always have the default value BY_VALIDATION_TIME.
	 * Mirror ORBp will always have calculated the same value as its Master ORBp.
	 */
	sortingOrdersAlgorithm?: SortingOrdersAlgorithm;

	/**
	 * Split rows for dependants
	 */
	splitRowsConfiguration?: OrbpSplitRowsConfiguration[];
};

export enum OrbpDetailProperties {
	ALERT_TIME = 'alertTime',
	CAN_PARK_ORDERS = 'canParkOrders',
	SIMPLIFICATION_ORDERS = 'simplificationOrders',
}
