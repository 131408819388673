/**
 * This file contains the structure definition for BKC
 */

export interface IBKCMessageTLogsTender {
	/**
	 * @bkForceJavaType long
	 * @pattern ^[0-9]+$
	 */
	tender_id: string;
	tender_name: string;
	tender_reference_uid?: string;
	/**
	 * @bkForceJavaType int
	 * @pattern ^(-)?([0-9])+$
	 */
	count: string;
	/**
	 * @bkForceJavaType double
	 * @pattern ^(-)?([0-9])+(\\.[0-9]+)?$
	 */
	amount: string;
	/**
	 * @bkForceJavaType int
	 * @pattern ^([0123]){1}$
	 */
	mode: string;
	/**
	 * @bkForceJavaType boolean
	 * @pattern ^(true|false)$
	 */
	is_change?: string;
	third_party_id: string;
}

export interface IBKCMessageTLogsProductQualifier {
	/**
	 * @bkForceJavaType long
	 * @pattern ^[0-9]+$
	 */
	modifier_id: string; //"7715",
	/**
	 * @bkForceJavaType long
	 * @pattern ^[0-9]+$
	 */
	modifier_third_party_id: string; //"7715",
	modifier_name: string; //"No",
	/**
	 * @bkForceJavaType long
	 * @pattern ^[0-9]+$
	 */
	qualifier_id: string; //"9201",
	/**
	 * @bkForceJavaType long
	 * @pattern ^[0-9]+$
	 */
	qualifier_third_party_id: string; //"505",
	qualifier_name: string; //"Bacon",
	/**
	 * @bkForceJavaType int
	 * @pattern ^([0123]){1}$
	 */
	mode: string; //"0",
	count: string; //"1",
	amount: string; //"0.00",
	price: string; //"0.00",
	a_la_carte_price: string; //"0.00"
	modifier_reference_uid?: string;
}

export interface IBKCMessageTLogsProductDiscount {
	/**
	 * @bkForceJavaType long
	 * @pattern ^[0-9]+$
	 */
	discount_id: string; //"19016",
	/**
	 * @bkForceJavaType long
	 * @pattern ^[0-9]+$
	 */
	third_party_id: string; //"883398957725",
	discount_name: string; //"KD Long TexasBBQ",
	/**
	 * @bkForceJavaType long
	 * @pattern ^[0-9]+$
	 */
	count: string; //"6",
	/**
	 * @bkForceJavaType double
	 * @pattern ^(-)?([0-9])+(\\.[0-9]+)?$
	 */
	amount: string; //"-12.11",
	/**
	 * @bkForceJavaType int
	 * @pattern ^([0123]){1}$
	 */
	mode: string; //"0"
	discount_reference_uid?: string;
}

export interface IBKCMessageTLogsProduct {
	/**
	 * @bkForceJavaType long
	 * @pattern ^[0-9]+$
	 */
	product_id: string;
	product_name: string;
	/**
	 * @bkForceJavaType int
	 * @pattern ^(-)?([0-9])+$
	 */
	count: string;
	/**
	 * @bkForceJavaType double
	 * @pattern ^(-)?([0-9])+(\\.[0-9]+)?$
	 */
	amount: string;
	/**
	 * @bkForceJavaType double
	 * @pattern ^(-)?([0-9])+(\\.[0-9]+)?$
	 */
	a_la_carte_price: string;
	/**
	 * @bkForceJavaType double
	 * @pattern ^(-)?([0-9])+(\\.[0-9]+)?$
	 */
	price: string;
	/**
	 * @bkForceJavaType int
	 * @pattern ^([0123]){1}$
	 */
	mode: string;
	/**
	 * @bkForceJavaType long
	 * @pattern ^[0-9]+$
	 */
	third_party_id: string;
	product_qualifiers?: IBKCMessageTLogsProductQualifier[];
	product_discounts?: IBKCMessageTLogsProductDiscount[];
	product_reference_uid?: string;
}

export interface IBKCMessageTLogsValueMeals {
	/**
	 * @bkForceJavaType long
	 * @pattern ^[0-9]+$
	 */
	valuemeal_id: string; //"1",
	/**
	 * @bkForceJavaType long
	 * @pattern ^[0-9]+$
	 */
	third_party_id: string; //"883398947726",
	valuemeal_name: string; //"CB+CHCKNUGBG+HB",
	/**
	 * @bkForceJavaType int
	 * @pattern ^([0123]){1}$
	 */
	mode: string; //"0",
	/**
	 * @bkForceJavaType int
	 * @pattern ^(-)?([0-9])+$
	 */
	count: string; //"1",
	valuemeal_products: IBKCMessageTLogsProduct[];
	valuemeal_product_qualifiers?: IBKCMessageTLogsProductQualifier[];
	valuemeal_discounts?: IBKCMessageTLogsProductDiscount[];
	/**
	 * @bkForceJavaType double
	 * @pattern ^(-)?([0-9])+(\\.[0-9]+)?$
	 */
	amount: string; //"2.51",
	/**
	 * @bkForceJavaType double
	 * @pattern ^(-)?([0-9])+(\\.[0-9]+)?$
	 */
	savings: string; //"-0.57"
	valuemeal_reference_uid?: string;
}

export interface IBKCMessageTLogsOrderDiscount {
	/**
	 * @bkForceJavaType int
	 * @pattern ^([0123]){1}$
	 */
	mode: string; //"0",
	/**
	 * @bkForceJavaType long
	 * @pattern ^[0-9]+$
	 */
	discount_id: string; //"903",
	discount_name: string; //"100% EMPLOYEE MEAL",
	/**
	 * @bkForceJavaType long
	 * @pattern ^[0-9]+$
	 */
	third_party_id: string; //"7710",
	/**
	 * @bkForceJavaType long
	 * @pattern ^[0-9]+$
	 */
	count: string; //"1",
	/**
	 * @bkForceJavaType double
	 * @pattern ^(-)?([0-9])+(\\.[0-9]+)?$
	 */
	amount: string; //"-0.39"
}

export interface IBKCMessageTLogs {
	/**
	 * @bkForceJavaType long
	 * @pattern ^[0-9]+$
	 */
	sale_id: string;
	/**
	 * @bkForceJavaType BKDateOnly
	 * @pattern ^([0-9]){4}-([0-9]){2}-([0-9]){2}$
	 */
	date: string;
	/**
	 * @bkForceJavaType Date
	 * @pattern ^([0-9]){4}-([0-9]){2}-([0-9]){2}([ ])+([0-9]){2}:([0-9]){2}:([0-9]){2}$
	 */
	transaction_start_datetime: string;
	/**
	 * @bkForceJavaType Date
	 * @pattern ^([0-9]){4}-([0-9]){2}-([0-9]){2}([ ])+([0-9]){2}:([0-9]){2}:([0-9]){2}$
	 */
	transaction_end_datetime: string;
	destination: string;
	order_number: string;
	cancelled_sale_reference_uid?: string;
	is_overring: string;
	order_sub_total: string;
	deleted_items: string;
	products?: IBKCMessageTLogsProduct[];
	valuemeals?: IBKCMessageTLogsValueMeals[];
	order_discounts?: IBKCMessageTLogsOrderDiscount[];
	tenders: IBKCMessageTLogsTender[];
}

export interface IBKCMessageParams {
	restaurant_id: string;
	tlogs: IBKCMessageTLogs[];
}

export interface IBKCMessage {
	api_version: string;
	provider: string;
	password: string;
	method: string;
	params: IBKCMessageParams;
}
