import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AllergensEffects } from './state/effects';
import { AllergensFacade } from './state/facade';
import { reducers } from './state/reducer';

@NgModule({
	imports: [StoreModule.forFeature('allergens', reducers), EffectsModule.forFeature([AllergensEffects])],
	providers: [AllergensFacade],
})
export class StateAllergensModule {}
