/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PropertyType = 'boolean' | 'string' | 'integer' | 'float';

export const PropertyType = {
    Boolean: 'boolean' as PropertyType,
    String: 'string' as PropertyType,
    Integer: 'integer' as PropertyType,
    Float: 'float' as PropertyType
};

