import { Injectable, OnDestroy } from '@angular/core';
import { getAllergenListForProduct } from '@bk/fullstack-common';
import { IBKAllergenData, IBKMenuBase, IBKProductBase, IBKProductIngredientSelectedData } from '@bk/jscommondatas';
import { IBKBigData } from '@libs/shared/models';
import { AllergensFacade } from '@libs/shared/store/allergens';
import { ConfigurationFacade } from '@libs/shared/store/configuration';
import { intersection, keys, values } from 'ramda';
import { Subject, takeUntil } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AllergensService implements OnDestroy {
	bigData: IBKBigData;
	allergenIds: (string | number)[];

	private readonly ngUnsubscribe$ = new Subject<void>();

	constructor(
		private readonly configurationFacade: ConfigurationFacade,
		private readonly allergensFacade: AllergensFacade
	) {
		this.configurationFacade
			.getBigData()
			.pipe(takeUntil(this.ngUnsubscribe$))
			.subscribe((item) => {
				this.bigData = item;
			});
		this.allergensFacade
			.getCurrentlyUsedAllergens()
			.pipe(takeUntil(this.ngUnsubscribe$))
			.subscribe((item: { [id: string]: IBKAllergenData }) => {
				this.allergenIds = keys(item).map((a) => +a);
			});
	}

	isProductAllergenicToUser(product: IBKProductBase, recipe: IBKProductIngredientSelectedData[] = []): boolean {
		if (this.allergenIds.length === 0) {
			return false;
		}
		const ingredients = values(this.bigData.ingredients);
		const allergensInProduct = getAllergenListForProduct(this.bigData, product, recipe, ingredients).map((item) => item.aId);
		return intersection(this.allergenIds, allergensInProduct).length > 0;
	}

	isMenuAllergenicToUser(menu: IBKMenuBase, recipe: IBKProductIngredientSelectedData[] = []): boolean {
		if (this.allergenIds.length === 0) {
			return false;
		}
		const productIds = menu._steps.map((step) => step._defaultSelection);
		const products = productIds.map((productId) => this.bigData.products[productId]);
		return products.some((product) => this.isProductAllergenicToUser(product, recipe));
	}

	ngOnDestroy() {
		this.ngUnsubscribe$.next();
		this.ngUnsubscribe$.complete();
	}
}
