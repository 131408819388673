
/**
 * Type for putCommande/replaceCommande requests response body when order is sent to csi
 */

export type DeclaredOrderResponse = {
	code: number,
	response: Array<number|string>
	orderDetail?: DeclaredOrderDetail,
};

export type DeclaredOrderDetail = {
	orderId: number,
	source: string,
	computationDetail: DeclaredOrderComputationDetail
};

export type DeclaredOrderComputationDetail = {
	version: number,
	amountExcludingTax: number,
	amountIncludingTax: number,
	taxAmount: number,
	numberOfLines: number,
	discounts: Record<string, DeclaredAppliedDiscounts>,
	productDetail: Record<string, DeclaredProductDetail>,
	taxDistribution: Array<TaxDistribution>
};

export type DeclaredProductDetail = {
	quantity: number,
	amountExclTaxes: number,
	amountInclTaxes: number,
	totalAmountItemAloneExclTaxes: number,
	totalAmountItemAloneInclTaxes: number,
	totalAmountExclTaxes: number,
	totalAmountInclTaxes: number,
	discountAmountExclTaxes: number,
	discountAmountInclTaxes: number,
	totalDiscountAmountExclTaxes: number,
	totalDiscountAmountInclTaxes: number,
	catalogPrice: number,
	parentLineUuid?: string
};

export type TaxDistribution = {
	taxRate: number,
	name: string,
	taxAmount: number,
	amountIncludingTax: number,
	amountExcludingTax: number
};

export type DeclaredAppliedDiscounts = {
	discountAmount: number;
	discountAmountExcludingTax: number;
	discountAmountIncludingTax: number;
	discountRate: number
	parentLineUuid?: string
};
