import { RawValidationMessage, TranslatedValidationMessage, ValidationMessageBase, ValidationMessageSeverityEnum } from '@merim/utils';

/**
 * This is the type used by Validators. It only returns translationKeys in the validation messages.
 * Not the translations themselves.
 * The consuming application is responsible for translating them and displaying to the user.
 */
export type RawDssProgramValidation = {
	/**
	 * Validation Problems not bound to any specific ORBp
	 */
	general: RawValidationMessage[],
	/**
	 * Validation Problems found in particular ORBps
	 */
	orbp: {
		[orbpId: string]: RawValidationMessage[];
	};
};

export type TranslatedDssProgramValidation = {
	/**
	 * Validation Problems not bound to any specific ORBp
	 */
	general: TranslatedValidationMessage[],
	/**
	 * Validation Problems found in particular ORBps
	 */
	orbp: {
		[orbpId: string]: TranslatedValidationMessage[];
	};
};

export function dssProgramHasError(programValidation: RawDssProgramValidation | TranslatedDssProgramValidation): boolean {
	return dssProgramHasValidationMessage(ValidationMessageSeverityEnum.ERROR, programValidation);
}

export function dssProgramHasValidationWarning(programValidation: RawDssProgramValidation | TranslatedDssProgramValidation): boolean {
	return dssProgramHasValidationMessage(ValidationMessageSeverityEnum.WARNING, programValidation);
}

export function dssProgramHasValidationInfo(programValidation: RawDssProgramValidation | TranslatedDssProgramValidation): boolean {
	return dssProgramHasValidationMessage(ValidationMessageSeverityEnum.INFO, programValidation);
}

function dssProgramHasValidationMessage(severity: ValidationMessageSeverityEnum, programValidation: RawDssProgramValidation | TranslatedDssProgramValidation): boolean {
	const hasOrbpValidationMsg = Object.values(programValidation.orbp)
		.some(validationMessages => validationMessages.filter(vm => vm.severity === severity).length > 0);

	const generalMessages: ValidationMessageBase[] = programValidation.general;
	const hasSomeGenericValidationMsg = generalMessages.filter(vm => vm.severity === severity).length !== 0;

	return hasOrbpValidationMsg || hasSomeGenericValidationMsg;
}

export function getDefaultDssProgramValidation(): RawDssProgramValidation {
	return {
		general: [],
		orbp: {}
	}
}

export function getDefaultTranslatedDssProgramValidation(): TranslatedDssProgramValidation {
	return {
		general: [],
		orbp: {}
	}
}
