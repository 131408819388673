import { IntervalPrediction } from './interval-prediction';

export type PredictionDetail = {
	/**
	 * Start of prediction interval
	 */
	datetime: Date;
	/**
	 * Length of an interval for prediction in milliseconds.
	 */
	timeInterval: number;
	/**
	 * Associative array of item predictions. Item IDs are the keys.
	 */
	salesMap: { [key: string]: IntervalPrediction };
	/**
	 * Predicted revenue.
	 */
	revenue: IntervalPrediction;
	/**
	 * Calculated peak intensity index of the interval.
	 */
	peakIntensityIndex: number;
	/**
	 * Modified peak intensity index of the interval.
	 * This is filled only when the Preview mode is used.
	 */
	modifiedPeakIntensityIndex?: number;
};
