/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { WeborderChannelConfigurationDto } from './weborder-channel-configuration-dto';


export interface WebordersDto { 
    /**
     * Configuration of weborder channels that may be utilized by restaurant if enabled in RAP.
     */
    channels: Array<WeborderChannelConfigurationDto>;
}

