/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';
import { DssProgramOrbpConfigurationDto } from '../model/models';
import { DssTableConfigurationDto } from '../model/models';
import { IdNameDto } from '../model/models';
import { OrbpConfigurationBaseDto } from '../model/models';
import { OrbpDetailDto } from '../model/models';
import { OrbpDetailInputDto } from '../model/models';
import { ToogleFoodCategoryDto } from '../model/models';
import { UpdateMultipleSalesChannelDto } from '../model/models';
import { UpdateSingleSalesChannelDto } from '../model/models';
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
@Injectable()
export class ORBpApiClient {
    protected basePath = 'http://localhost:3333';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;
    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }
    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }
        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }
    /**
     * Disable food category
     * Disables food category in DSS program.
     * @param toogleFoodCategoryDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public disableFoodCategory(toogleFoodCategoryDto: ToogleFoodCategoryDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<OrbpConfigurationBaseDto>>;
    public disableFoodCategory(toogleFoodCategoryDto: ToogleFoodCategoryDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<OrbpConfigurationBaseDto>>>;
    public disableFoodCategory(toogleFoodCategoryDto: ToogleFoodCategoryDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<OrbpConfigurationBaseDto>>>;
    public disableFoodCategory(toogleFoodCategoryDto: ToogleFoodCategoryDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (toogleFoodCategoryDto === null || toogleFoodCategoryDto === undefined) {
            throw new Error('Required parameter toogleFoodCategoryDto was null or undefined when calling disableFoodCategory.');
        }
        let headers = this.defaultHeaders;
        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.post<Array<OrbpConfigurationBaseDto>>(`${this.configuration.basePath}/api/orbp/disable-food-category`,
            toogleFoodCategoryDto,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    /**
     * Enable food category
     * Enables food category in DSS program.
     * @param toogleFoodCategoryDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public enableFoodCategory(toogleFoodCategoryDto: ToogleFoodCategoryDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<OrbpConfigurationBaseDto>>;
    public enableFoodCategory(toogleFoodCategoryDto: ToogleFoodCategoryDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<OrbpConfigurationBaseDto>>>;
    public enableFoodCategory(toogleFoodCategoryDto: ToogleFoodCategoryDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<OrbpConfigurationBaseDto>>>;
    public enableFoodCategory(toogleFoodCategoryDto: ToogleFoodCategoryDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (toogleFoodCategoryDto === null || toogleFoodCategoryDto === undefined) {
            throw new Error('Required parameter toogleFoodCategoryDto was null or undefined when calling enableFoodCategory.');
        }
        let headers = this.defaultHeaders;
        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.post<Array<OrbpConfigurationBaseDto>>(`${this.configuration.basePath}/api/orbp/enable-food-category`,
            toogleFoodCategoryDto,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    /**
     * Get default configuration for each ORBp
     * Returns default configuration for all ORBp devices.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllAvailableOrbpDevices(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<OrbpConfigurationBaseDto>>;
    public getAllAvailableOrbpDevices(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<OrbpConfigurationBaseDto>>>;
    public getAllAvailableOrbpDevices(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<OrbpConfigurationBaseDto>>>;
    public getAllAvailableOrbpDevices(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        let headers = this.defaultHeaders;
        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.get<Array<OrbpConfigurationBaseDto>>(`${this.configuration.basePath}/api/orbp/devices`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    /**
     * Get DSS UI table configuration
     * Returns DSS UI table configuration.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDssTableConfiguration(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<DssTableConfigurationDto>;
    public getDssTableConfiguration(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<DssTableConfigurationDto>>;
    public getDssTableConfiguration(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<DssTableConfigurationDto>>;
    public getDssTableConfiguration(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        let headers = this.defaultHeaders;
        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.get<DssTableConfigurationDto>(`${this.configuration.basePath}/api/orbp/table-configuration`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    /**
     * Get available ORBp devices for DSS program
     * Returns configurations of ORBp devices for the selected DSS program. Only the available ORBps will be returned in the resulting configuration.
     * @param programId Id of the DSS program.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDssTableData(programId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<DssProgramOrbpConfigurationDto>;
    public getDssTableData(programId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<DssProgramOrbpConfigurationDto>>;
    public getDssTableData(programId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<DssProgramOrbpConfigurationDto>>;
    public getDssTableData(programId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling getDssTableData.');
        }
        let headers = this.defaultHeaders;
        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.get<DssProgramOrbpConfigurationDto>(`${this.configuration.basePath}/api/orbp/table-data/${encodeURIComponent(String(programId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    /**
     * Get all the active ORBs screens
     * Returns list of active (detected by Shar3dClient) ORBs screens (do not confuse with ORBp devices).
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getORBsScreens(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<IdNameDto>>;
    public getORBsScreens(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<IdNameDto>>>;
    public getORBsScreens(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<IdNameDto>>>;
    public getORBsScreens(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        let headers = this.defaultHeaders;
        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.get<Array<IdNameDto>>(`${this.configuration.basePath}/api/orbp/orb-screens`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    /**
     * Get single ORBp device configuration in DSS program
     * Returns configurations of singe ORBp device for the selected DSS program.
     * @param programId Id of the DSS program.
     * @param orbpId Id of the ORBp.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSpecificORBpPerDssTable(programId: string, orbpId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<OrbpConfigurationBaseDto | object>;
    public getSpecificORBpPerDssTable(programId: string, orbpId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<OrbpConfigurationBaseDto | object>>;
    public getSpecificORBpPerDssTable(programId: string, orbpId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<OrbpConfigurationBaseDto | object>>;
    public getSpecificORBpPerDssTable(programId: string, orbpId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling getSpecificORBpPerDssTable.');
        }
        if (orbpId === null || orbpId === undefined) {
            throw new Error('Required parameter orbpId was null or undefined when calling getSpecificORBpPerDssTable.');
        }
        let headers = this.defaultHeaders;
        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.get<OrbpConfigurationBaseDto | object>(`${this.configuration.basePath}/api/orbp/device/${encodeURIComponent(String(programId))}/${encodeURIComponent(String(orbpId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    /**
     * Stores new ORBp configurations for DSS program
     * Stores new ORBp configurations for DSS program and returns updated configurations.
     * @param programId Id of the DSS program.
     * @param orbpDetailInputDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public saveDeviceSettings(programId: string, orbpDetailInputDto: OrbpDetailInputDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<OrbpDetailDto>>;
    public saveDeviceSettings(programId: string, orbpDetailInputDto: OrbpDetailInputDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<OrbpDetailDto>>>;
    public saveDeviceSettings(programId: string, orbpDetailInputDto: OrbpDetailInputDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<OrbpDetailDto>>>;
    public saveDeviceSettings(programId: string, orbpDetailInputDto: OrbpDetailInputDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling saveDeviceSettings.');
        }
        if (orbpDetailInputDto === null || orbpDetailInputDto === undefined) {
            throw new Error('Required parameter orbpDetailInputDto was null or undefined when calling saveDeviceSettings.');
        }
        let headers = this.defaultHeaders;
        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.post<Array<OrbpDetailDto>>(`${this.configuration.basePath}/api/orbp/device-settings/${encodeURIComponent(String(programId))}`,
            orbpDetailInputDto,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    /**
     * Updates \&quot;defaultForSalesChannel\&quot; property in ORBp configuration
     * Stores new ORBp configurations for DSS program and returns updated configurations.
     * @param programId Id of the DSS program.
     * @param orbpId Id of the ORBp.
     * @param isDefault Flag indicating if the selected ORBp should be used as default.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setIsDefaultForSalesChannel(programId: string, orbpId: string, isDefault: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<OrbpConfigurationBaseDto>>;
    public setIsDefaultForSalesChannel(programId: string, orbpId: string, isDefault: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<OrbpConfigurationBaseDto>>>;
    public setIsDefaultForSalesChannel(programId: string, orbpId: string, isDefault: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<OrbpConfigurationBaseDto>>>;
    public setIsDefaultForSalesChannel(programId: string, orbpId: string, isDefault: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling setIsDefaultForSalesChannel.');
        }
        if (orbpId === null || orbpId === undefined) {
            throw new Error('Required parameter orbpId was null or undefined when calling setIsDefaultForSalesChannel.');
        }
        if (isDefault === null || isDefault === undefined) {
            throw new Error('Required parameter isDefault was null or undefined when calling setIsDefaultForSalesChannel.');
        }
        let headers = this.defaultHeaders;
        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.post<Array<OrbpConfigurationBaseDto>>(`${this.configuration.basePath}/api/orbp/default-for-sales-channel/${encodeURIComponent(String(programId))}/${encodeURIComponent(String(orbpId))}/${encodeURIComponent(String(isDefault))}`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    /**
     * Updates multiple sales channel options in ORBp configuration
     * Stores new ORBp configurations for DSS program and returns updated configurations.
     * @param programId Id of the DSS program.
     * @param orbpId Id of the ORBp.
     * @param updateMultipleSalesChannelDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateMultipleSalesChannelConfiguration(programId: string, orbpId: string, updateMultipleSalesChannelDto: UpdateMultipleSalesChannelDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<OrbpConfigurationBaseDto>>;
    public updateMultipleSalesChannelConfiguration(programId: string, orbpId: string, updateMultipleSalesChannelDto: UpdateMultipleSalesChannelDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<OrbpConfigurationBaseDto>>>;
    public updateMultipleSalesChannelConfiguration(programId: string, orbpId: string, updateMultipleSalesChannelDto: UpdateMultipleSalesChannelDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<OrbpConfigurationBaseDto>>>;
    public updateMultipleSalesChannelConfiguration(programId: string, orbpId: string, updateMultipleSalesChannelDto: UpdateMultipleSalesChannelDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling updateMultipleSalesChannelConfiguration.');
        }
        if (orbpId === null || orbpId === undefined) {
            throw new Error('Required parameter orbpId was null or undefined when calling updateMultipleSalesChannelConfiguration.');
        }
        if (updateMultipleSalesChannelDto === null || updateMultipleSalesChannelDto === undefined) {
            throw new Error('Required parameter updateMultipleSalesChannelDto was null or undefined when calling updateMultipleSalesChannelConfiguration.');
        }
        let headers = this.defaultHeaders;
        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.post<Array<OrbpConfigurationBaseDto>>(`${this.configuration.basePath}/api/orbp/sales-channel-configuration/multiple/${encodeURIComponent(String(programId))}/${encodeURIComponent(String(orbpId))}`,
            updateMultipleSalesChannelDto,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    /**
     * Updates multiple sales channel options in ORBp configuration
     * Stores new ORBp configurations for DSS program and returns updated configurations.
     * @param programId Id of the DSS program.
     * @param orbpId Id of the ORBp.
     * @param status New ORBp status.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateORBpStatus(programId: string, orbpId: string, status: 'Unknown' | 'Off' | 'ShuttingDown' | 'On', observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<OrbpConfigurationBaseDto>>;
    public updateORBpStatus(programId: string, orbpId: string, status: 'Unknown' | 'Off' | 'ShuttingDown' | 'On', observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<OrbpConfigurationBaseDto>>>;
    public updateORBpStatus(programId: string, orbpId: string, status: 'Unknown' | 'Off' | 'ShuttingDown' | 'On', observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<OrbpConfigurationBaseDto>>>;
    public updateORBpStatus(programId: string, orbpId: string, status: 'Unknown' | 'Off' | 'ShuttingDown' | 'On', observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling updateORBpStatus.');
        }
        if (orbpId === null || orbpId === undefined) {
            throw new Error('Required parameter orbpId was null or undefined when calling updateORBpStatus.');
        }
        if (status === null || status === undefined) {
            throw new Error('Required parameter status was null or undefined when calling updateORBpStatus.');
        }
        let headers = this.defaultHeaders;
        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.post<Array<OrbpConfigurationBaseDto>>(`${this.configuration.basePath}/api/orbp/${encodeURIComponent(String(programId))}/devices/${encodeURIComponent(String(orbpId))}/status/${encodeURIComponent(String(status))}`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    /**
     * Updates single sales channel option in ORBp configuration
     * Stores new ORBp configurations for DSS program and returns updated configurations.
     * @param programId Id of the DSS program.
     * @param orbpId Id of the ORBp.
     * @param updateSingleSalesChannelDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateSingleSalesChannelConfiguration(programId: string, orbpId: string, updateSingleSalesChannelDto: UpdateSingleSalesChannelDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<OrbpConfigurationBaseDto>>;
    public updateSingleSalesChannelConfiguration(programId: string, orbpId: string, updateSingleSalesChannelDto: UpdateSingleSalesChannelDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<OrbpConfigurationBaseDto>>>;
    public updateSingleSalesChannelConfiguration(programId: string, orbpId: string, updateSingleSalesChannelDto: UpdateSingleSalesChannelDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<OrbpConfigurationBaseDto>>>;
    public updateSingleSalesChannelConfiguration(programId: string, orbpId: string, updateSingleSalesChannelDto: UpdateSingleSalesChannelDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling updateSingleSalesChannelConfiguration.');
        }
        if (orbpId === null || orbpId === undefined) {
            throw new Error('Required parameter orbpId was null or undefined when calling updateSingleSalesChannelConfiguration.');
        }
        if (updateSingleSalesChannelDto === null || updateSingleSalesChannelDto === undefined) {
            throw new Error('Required parameter updateSingleSalesChannelDto was null or undefined when calling updateSingleSalesChannelConfiguration.');
        }
        let headers = this.defaultHeaders;
        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.post<Array<OrbpConfigurationBaseDto>>(`${this.configuration.basePath}/api/orbp/sales-channel-configuration/single/${encodeURIComponent(String(programId))}/${encodeURIComponent(String(orbpId))}`,
            updateSingleSalesChannelDto,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
}
