/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LocalPriceDefinitionDto } from './local-price-definition-dto';


export interface LocalPriceDefinitionsDto { 
    /**
     * Dictionary of products\' price definitions by ID.
     */
    product: { [key: string]: LocalPriceDefinitionDto; };
    /**
     * Dictionary of menus\' price definitions by ID.
     */
    menu: { [key: string]: LocalPriceDefinitionDto; };
    /**
     * Dictionary of ingredients\' price definitions by ID.
     */
    ingredient: { [key: string]: LocalPriceDefinitionDto; };
}

