import { LocalPriceDefinitionMap } from './local-price-definition-map';
import { PriceDefinitionType } from './price-definition-type';

/** Used in available.json */
export type LocalPriceDefinitionSchema = {
	product: LocalPriceDefinitionMap;
	menu: LocalPriceDefinitionMap;
	ingredient: LocalPriceDefinitionMap;
};

export function getInitialLocalPriceDefinitionSchema(): LocalPriceDefinitionSchema {
	return {
		product: {},
		menu: {},
		ingredient: {}
	};
}

/** Get matching property name in LocalPriceDefinitionSchema used in AvailableJson */
export function getLocalPriceDefinitionSchemaPropertyName(priceDefType: PriceDefinitionType): 'ingredient' | 'menu' | 'product' {
	switch (priceDefType) {
		case 'INGREDIENT':
			return 'ingredient';
		case 'MENU':
			return 'menu';
		case 'PRODUCT':
			return 'product';
		default: {
			const errMessage = `getLocalPriceDefinitionSchemaPropertyName() - Unexpected priceDefinitionType: ${priceDefType}`;
			console.error(errMessage);
			throw new Error(errMessage);
		}
	}
}

/** Get matching property name used in BigDatasJson */
export function getGlobalPriceDefinitionSchemaPropertyName(priceDefType: PriceDefinitionType): 'ingredients' | 'menus' | 'products' {
	switch (priceDefType) {
		case 'INGREDIENT':
			return 'ingredients';
		case 'MENU':
			return 'menus';
		case 'PRODUCT':
			return 'products';
		default: {
			const errMessage = `getGlobalPriceDefinitionSchemaPropertyName() - Unexpected priceDefinitionType: ${priceDefType}`;
			console.error(errMessage);
			throw new Error(errMessage);
		}
	}
}
