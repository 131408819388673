import { getTruthyEnumMapKeys } from '@merim/utils';

import { SalesChannelConfiguration } from '../../types/sales-channel-configuration';

/*
 *	Returns the number of sales channel options enabled.
 */
export function countSalesChannelOptionsEnabled(configArray: SalesChannelConfiguration[]): number {
	if (!configArray || configArray.length == 0) {
		return 0;
	}
	let countOptionsEnabled = 0;
	configArray.forEach((config) => {
		countOptionsEnabled += getTruthyEnumMapKeys(config.configuration).length;
	});
	return countOptionsEnabled;
}
