import { BKOrderSourceEnum } from '@bk/jscommondatas';

export function getOrderSourcePrefix(source: number | BKOrderSourceEnum): string {
	let prefix = '';
	switch (source) {
		case BKOrderSourceEnum.ORDER_SOURCE_CASH_MACHINE:
			prefix = 'C';
			break;
		case BKOrderSourceEnum.ORDER_SOURCE_KIOSK:
			prefix = 'K';
			break;
		case BKOrderSourceEnum.ORDER_SOURCE_DRIVE:
			prefix = 'D';
			break;
		case BKOrderSourceEnum.ORDER_SOURCE_WALK:
			prefix = 'W';
			break;
		case BKOrderSourceEnum.ORDER_SOURCE_CLICK_AND_COLLECT:
			prefix = 'E';
			break;
		case BKOrderSourceEnum.ORDER_SOURCE_HOME_DELIVERY:
			prefix = 'H';
			break;
		case BKOrderSourceEnum.ORDER_SOURCE_THIRD_PARTY_DELIVERY:
			prefix = 'T';
			break;
		//  For init purpose
		case BKOrderSourceEnum.ORDER_SOURCE_UNKNOWN:
			break;
	}

	return prefix;
}
