/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LocalPriceDefinitionValueDto } from './local-price-definition-value-dto';


export interface LocalPriceDefinitionDto { 
    /**
     * Id of the item (product/menu/ingredient).
     */
    id: string;
    /**
     * Short name of the item (product/menu/ingredient).
     */
    name: string;
    /**
     * Local price definitions.
     */
    values: Array<LocalPriceDefinitionValueDto>;
}

