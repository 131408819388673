import { LocalRestaurantStateEnum } from '@bk/jscommondatas';
import { clone } from 'ramda';
import { IRestoSettings, IRestoSettingsNested } from '../remote-configs';

export class RestoSettingsStoreModel {
	loading: boolean = true;
	data: RestoSettingsModel = new RestoSettingsModel();

	constructor(init?: Partial<RestoSettingsStoreModel>) {
		Object.assign(this, clone(init));
	}
}

export class RestoSettingsModel implements IRestoSettings {
	restosettings: IRestoSettingsNested;
	timestamp?: number;
	cashFundParameters?: ICashParameters;
	moneyconfig?: ICashConfigurationDto[];
	restostate?: LocalRestaurantStateEnum;
	disableAllKiosks?: boolean;
	disableKiosks?: Array<number>;
	blackScreenKiosks?: Array<number>;
	messageOnTicket?: string;
	toiletCode?: string;
	maxCrownPerEmployee?: number;
	maxRestTR?: number;
	kioskMultipayMessage?: string;
	kioskMultipayMessageLangs?: { [key: string]: string };
	kioskMultipayMode?: number;
	enableEatInOnKiosk?: boolean;
	enableMultipayOnKiosk?: boolean;
	enableKingdom?: boolean;
	enableORBDigital?: boolean;
	showPickUpParkingOrdersOnORB?: boolean;
	payingPosMultipayMode1?: number;
	payingPosMultipayMode2?: number;
	printCODTicket?: boolean;
	printCODLongTicket?: boolean;
	printTicketOnAllBurgers?: boolean;
	enableKingdomForEmployee?: boolean;
	employeeMealInEuros?: boolean;
	openDrawerOnEmployeeMeal?: boolean;
	alertDayForDumpSafe?: number;
	preparationAheadTimeWebS?: number;
	cashTargetWebOrder?: string;
	driveSortByFirstProduct?: boolean;
	tableService?: boolean;
	terraceAvailableForTableService?: boolean;
	waitAtTheTable?: boolean;
	hasWalkByWindow?: boolean;
	franchiseeCanEditLocalPrices?: boolean;
	franchiseeCanEditDaypartPrices?: boolean;
	optionNotToPrintTicketEnabled?: boolean;
	optionNotToPrintTicketMaxPrice?: number;
	useSmallSafe?: boolean;
	enablePaymentCarteGR?: boolean;
	kioskCashPaymentMode?: string;
	liveBasketModeEnabled?: boolean;

	constructor(init?: Partial<RestoSettingsModel>) {
		Object.assign(this, clone(init));
	}
}

interface ICashParameters {
	initialCashFund: number;
	alertWidthdraw: number;
	cashFoundNumber: number;
}

interface ICashConfigurationDto {
	label: string;
	value: number;
}
