/*
 Copyright (C) 2011 Patrick Gillespie, http://patorjk.com/

 Permission is hereby granted, free of charge, to any person obtaining a copy
 of this software and associated documentation files (the "Software"), to deal
 in the Software without restriction, including without limitation the rights
 to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
 copies of the Software, and to permit persons to whom the Software is
 furnished to do so, subject to the following conditions:

 The above copyright notice and this permission notice shall be included in
 all copies or substantial portions of the Software.

 THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
 AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
 LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
 OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
 THE SOFTWARE.
 */

/*
 Extendible BBCode Parser v1.0.0
 By Patrick Gillespie (patorjk@gmail.com)
 Website: http://patorjk.com/

 This module allows you to parse BBCode and to extend to the mark-up language
 to add in your own tags.
 */

/*
 * Converted to TS by Arnaud BOUTIN
 */

export interface IXBBCODEConfig {
	text: string;
	removeMisalignedTags?: boolean;
	addInLineBreaks?: boolean;
	escapeHtml?: boolean;
}

interface IXBBCODETag {
	validChildLookup?: { [tagName: string]: boolean };
	validParentLookup?: { [tagName: string]: boolean };
	restrictParentsTo?: string[];
	restrictChildrenTo?: string[];
	noParse?: boolean;
	displayContent?: boolean;
	openTag?: (params: string, content: string) => string;
	closeTag?: (params: string, content: string) => string;
}

export interface IXBBCODEResult {
	html: string;
	error: boolean;
	errorQueue?: string[];
}

export class XBBCODE {
	// -----------------------------------------------------------------------------
	// Set up private variables
	// -----------------------------------------------------------------------------

	private static readonly urlPattern: RegExp = /^(?:https?|file|c):(?:\/{1,3}|\\{1})[-a-zA-Z0-9:;@#%&()~_?\+=\/\\\.]*$/;
	private static readonly colorNamePattern: RegExp = /^(?:aliceblue|antiquewhite|aqua|aquamarine|azure|beige|bisque|black|blanchedalmond|blue|blueviolet|brown|burlywood|cadetblue|chartreuse|chocolate|coral|cornflowerblue|cornsilk|crimson|cyan|darkblue|darkcyan|darkgoldenrod|darkgray|darkgreen|darkkhaki|darkmagenta|darkolivegreen|darkorange|darkorchid|darkred|darksalmon|darkseagreen|darkslateblue|darkslategray|darkturquoise|darkviolet|deeppink|deepskyblue|dimgray|dodgerblue|firebrick|floralwhite|forestgreen|fuchsia|gainsboro|ghostwhite|gold|goldenrod|gray|green|greenyellow|honeydew|hotpink|indianred|indigo|ivory|khaki|lavender|lavenderblush|lawngreen|lemonchiffon|lightblue|lightcoral|lightcyan|lightgoldenrodyellow|lightgray|lightgreen|lightpink|lightsalmon|lightseagreen|lightskyblue|lightslategray|lightsteelblue|lightyellow|lime|limegreen|linen|magenta|maroon|mediumaquamarine|mediumblue|mediumorchid|mediumpurple|mediumseagreen|mediumslateblue|mediumspringgreen|mediumturquoise|mediumvioletred|midnightblue|mintcream|mistyrose|moccasin|navajowhite|navy|oldlace|olive|olivedrab|orange|orangered|orchid|palegoldenrod|palegreen|paleturquoise|palevioletred|papayawhip|peachpuff|peru|pink|plum|powderblue|purple|red|rosybrown|royalblue|saddlebrown|salmon|sandybrown|seagreen|seashell|sienna|silver|skyblue|slateblue|slategray|snow|springgreen|steelblue|tan|teal|thistle|tomato|turquoise|violet|wheat|white|whitesmoke|yellow|yellowgreen)$/;
	private static readonly colorCodePattern: RegExp = /^#?[a-fA-F0-9]{6}$/;
	private static readonly emailPattern: RegExp = /[^\s@]+@[^\s@]+\.[^\s@]+/;
	private static readonly fontFacePattern: RegExp = /^([a-z][a-z0-9_]+|"[a-z][a-z0-9_\s]+")$/i;

	private static tagList: string[];
	private static tagsNoParseList: string[] = [];
	private static bbRegExp: RegExp;
	private static pbbRegExp: RegExp;
	private static pbbRegExp2: RegExp;
	private static openTags: RegExp;
	private static closeTags: RegExp;

	/* -----------------------------------------------------------------------------
	 * tags
	 * This object contains a list of tags that your code will be able to understand.
	 * Each tag object has the following properties:
	 *
	 *   openTag - A function that takes in the tag's parameters (if any) and its
	 *             contents, and returns what its HTML open tag should be.
	 *             Example: [color=red]test[/color] would take in "=red" as a
	 *             parameter input, and "test" as a content input.
	 *             It should be noted that any BBCode inside of "content" will have
	 *             been processed by the time it enter the openTag function.
	 *
	 *   closeTag - A function that takes in the tag's parameters (if any) and its
	 *              contents, and returns what its HTML close tag should be.
	 *
	 *   displayContent - Defaults to true. If false, the content for the tag will
	 *                    not be displayed. This is useful for tags like IMG where
	 *                    its contents are actually a parameter input.
	 *
	 *   restrictChildrenTo - A list of BBCode tags which are allowed to be nested
	 *                        within this BBCode tag. If this property is omitted,
	 *                        any BBCode tag may be nested within the tag.
	 *
	 *   restrictParentsTo - A list of BBCode tags which are allowed to be parents of
	 *                       this BBCode tag. If this property is omitted, any BBCode
	 *                       tag may be a parent of the tag.
	 *
	 *   noParse - true or false. If true, none of the content WITHIN this tag will be
	 *             parsed by the XBBCode parser.
	 *
	 *
	 *
	 * LIMITIONS on adding NEW TAGS:
	 *  - Tag names should be alphanumeric (including underscores) and all tags should have an opening tag
	 *    and a closing tag.
	 *    The [*] tag is an exception because it was already a standard
	 *    bbcode tag. Technecially tags don't *have* to be alphanumeric, but since
	 *    regular expressions are used to parse the text, if you use a non-alphanumeric
	 *    tag names, just make sure the tag name gets escaped properly (if needed).
	 * --------------------------------------------------------------------------- */

	private static tags: { [tagName: string]: IXBBCODETag } = {
		b: {
			openTag: function (params: string, content: string) {
				return '<span class="xbbcode-b">';
			},
			closeTag: function (params: string, content: string) {
				return '</span>';
			}
		},
		/*
		 This tag does nothing and is here mostly to be used as a classification for
		 the bbcode input when evaluating parent-child tag relationships
		 */
		bbcode: {
			openTag: function (params: string, content: string) {
				return '';
			},
			closeTag: function (params: string, content: string) {
				return '';
			}
		},
		center: {
			openTag: function (params: string, content: string) {
				return '<span class="xbbcode-center">';
			},
			closeTag: function (params: string, content: string) {
				return '</span>';
			}
		},

		code: {
			openTag: function (params: string, content: string) {
				return '<span class="xbbcode-code">';
			},
			closeTag: function (params: string, content: string) {
				return '</span>';
			},
			noParse: true
		},
		color: {
			openTag: function (params: string, content: string) {
				params = params || '';

				let colorCode = params.substr(1).toLowerCase() || 'black';
				XBBCODE.colorNamePattern.lastIndex = 0;
				XBBCODE.colorCodePattern.lastIndex = 0;
				if (!XBBCODE.colorNamePattern.test(colorCode)) {
					if (!XBBCODE.colorCodePattern.test(colorCode)) {
						colorCode = 'black';
					} else {
						if (colorCode.substr(0, 1) !== '#') {
							colorCode = '#' + colorCode;
						}
					}
				}

				return '<span style="color:' + colorCode + '">';
			},
			closeTag: function (params: string, content: string) {
				return '</span>';
			}
		},
		email: {
			openTag: function (params: string, content: string) {
				let myEmail: string;

				if (!params) {
					myEmail = content.replace(/<.*?>/g, '');
				} else {
					myEmail = params.substr(1);
				}

				XBBCODE.emailPattern.lastIndex = 0;
				if (!XBBCODE.emailPattern.test(myEmail)) {
					return '<a>';
				}

				return '<a href="mailto:' + myEmail + '">';
			},
			closeTag: function (params: string, content: string) {
				return '</a>';
			}
		},
		face: {
			openTag: function (params: string, content: string) {
				params = params || '';

				let faceCode = params.substr(1) || 'inherit';
				XBBCODE.fontFacePattern.lastIndex = 0;
				if (!XBBCODE.fontFacePattern.test(faceCode)) {
					faceCode = 'inherit';
				}
				return '<span style="font-family:' + faceCode + '">';
			},
			closeTag: function (params: string, content: string) {
				return '</span>';
			}
		},
		font: {
			openTag: function (params: string, content: string) {
				params = params || '';

				let faceCode = params.substr(1) || 'inherit';
				XBBCODE.fontFacePattern.lastIndex = 0;
				if (!XBBCODE.fontFacePattern.test(faceCode)) {
					faceCode = 'inherit';
				}
				return '<span style="font-family:' + faceCode + '">';
			},
			closeTag: function (params: string, content: string) {
				return '</span>';
			}
		},

		i: {
			openTag: function (params: string, content: string) {
				return '<span class="xbbcode-i">';
			},
			closeTag: function (params: string, content: string) {
				return '</span>';
			}
		},
		img: {
			openTag: function (params: string, content: string) {
				let myUrl = content;

				XBBCODE.urlPattern.lastIndex = 0;
				if (!XBBCODE.urlPattern.test(myUrl)) {
					myUrl = '';
				}

				return '<img src="' + myUrl + '" />';
			},
			closeTag: function (params: string, content: string) {
				return '';
			},
			displayContent: false
		},
		justify: {
			openTag: function (params: string, content: string) {
				return '<span class="xbbcode-justify">';
			},
			closeTag: function (params: string, content: string) {
				return '</span>';
			}
		},
		large: {
			openTag: function (params: string, content: string) {
				params = params || '';

				let colorCode = params.substr(1) || 'inherit';
				XBBCODE.colorNamePattern.lastIndex = 0;
				XBBCODE.colorCodePattern.lastIndex = 0;
				if (!XBBCODE.colorNamePattern.test(colorCode)) {
					if (!XBBCODE.colorCodePattern.test(colorCode)) {
						colorCode = 'inherit';
					} else {
						if (colorCode.substr(0, 1) !== '#') {
							colorCode = '#' + colorCode;
						}
					}
				}

				return '<span class="xbbcode-size-36" style="color:' + colorCode + '">';
			},
			closeTag: function (params: string, content: string) {
				return '</span>';
			}
		},
		left: {
			openTag: function (params: string, content: string) {
				return '<span class="xbbcode-left">';
			},
			closeTag: function (params: string, content: string) {
				return '</span>';
			}
		},
		li: {
			openTag: function (params: string, content: string) {
				return '<li>';
			},
			closeTag: function (params: string, content: string) {
				return '</li>';
			},
			restrictParentsTo: ['list', 'ul', 'ol']
		},
		list: {
			openTag: function (params: string, content: string) {
				return '<ul>';
			},
			closeTag: function (params: string, content: string) {
				return '</ul>';
			},
			restrictChildrenTo: ['*', 'li']
		},
		noparse: {
			openTag: function (params: string, content: string) {
				return '';
			},
			closeTag: function (params: string, content: string) {
				return '';
			},
			noParse: true
		},
		ol: {
			openTag: function (params: string, content: string) {
				return '<ol>';
			},
			closeTag: function (params: string, content: string) {
				return '</ol>';
			},
			restrictChildrenTo: ['*', 'li']
		},
		php: {
			openTag: function (params: string, content: string) {
				return '<span class="xbbcode-code">';
			},
			closeTag: function (params: string, content: string) {
				return '</span>';
			},
			noParse: true
		},
		quote: {
			openTag: function (params: string, content: string) {
				return '<blockquote class="xbbcode-blockquote">';
			},
			closeTag: function (params: string, content: string) {
				return '</blockquote>';
			}
		},
		right: {
			openTag: function (params: string, content: string) {
				return '<span class="xbbcode-right">';
			},
			closeTag: function (params: string, content: string) {
				return '</span>';
			}
		},
		s: {
			openTag: function (params: string, content: string) {
				return '<span class="xbbcode-s">';
			},
			closeTag: function (params: string, content: string) {
				return '</span>';
			}
		},
		size: {
			openTag: function (params: string, content: string) {
				params = params || '';

				let mySize = parseInt(params.substr(1), 10) || 0;
				if (mySize < 4 || mySize > 40) {
					mySize = 14;
				}

				return '<span class="xbbcode-size-' + mySize + '">';
			},
			closeTag: function (params: string, content: string) {
				return '</span>';
			}
		},
		small: {
			openTag: function (params: string, content: string) {
				params = params || '';

				let colorCode = params.substr(1) || 'inherit';
				XBBCODE.colorNamePattern.lastIndex = 0;
				XBBCODE.colorCodePattern.lastIndex = 0;
				if (!XBBCODE.colorNamePattern.test(colorCode)) {
					if (!XBBCODE.colorCodePattern.test(colorCode)) {
						colorCode = 'inherit';
					} else {
						if (colorCode.substr(0, 1) !== '#') {
							colorCode = '#' + colorCode;
						}
					}
				}

				return '<span class="xbbcode-size-10" style="color:' + colorCode + '">';
			},
			closeTag: function (params: string, content: string) {
				return '</span>';
			}
		},

		sub: {
			openTag: function (params: string, content: string) {
				return '<sub>';
			},
			closeTag: function (params: string, content: string) {
				return '</sub>';
			}
		},
		sup: {
			openTag: function (params: string, content: string) {
				return '<sup>';
			},
			closeTag: function (params: string, content: string) {
				return '</sup>';
			}
		},

		table: {
			openTag: function (params: string, content: string) {
				return '<table class="xbbcode-table">';
			},
			closeTag: function (params: string, content: string) {
				return '</table>';
			},
			restrictChildrenTo: ['tbody', 'thead', 'tfoot', 'tr']
		},
		tbody: {
			openTag: function (params: string, content: string) {
				return '<tbody>';
			},
			closeTag: function (params: string, content: string) {
				return '</tbody>';
			},
			restrictChildrenTo: ['tr'],
			restrictParentsTo: ['table']
		},
		tfoot: {
			openTag: function (params: string, content: string) {
				return '<tfoot>';
			},
			closeTag: function (params: string, content: string) {
				return '</tfoot>';
			},
			restrictChildrenTo: ['tr'],
			restrictParentsTo: ['table']
		},
		thead: {
			openTag: function (params: string, content: string) {
				return '<thead class="xbbcode-thead">';
			},
			closeTag: function (params: string, content: string) {
				return '</thead>';
			},
			restrictChildrenTo: ['tr'],
			restrictParentsTo: ['table']
		},
		td: {
			openTag: function (params: string, content: string) {
				return '<td class="xbbcode-td">';
			},
			closeTag: function (params: string, content: string) {
				return '</td>';
			},
			restrictParentsTo: ['tr']
		},
		th: {
			openTag: function (params: string, content: string) {
				return '<th class="xbbcode-th">';
			},
			closeTag: function (params: string, content: string) {
				return '</th>';
			},
			restrictParentsTo: ['tr']
		},
		tr: {
			openTag: function (params: string, content: string) {
				return '<tr class="xbbcode-tr">';
			},
			closeTag: function (params: string, content: string) {
				return '</tr>';
			},
			restrictChildrenTo: ['td', 'th'],
			restrictParentsTo: ['table', 'tbody', 'tfoot', 'thead']
		},
		u: {
			openTag: function (params: string, content: string) {
				return '<span class="xbbcode-u">';
			},
			closeTag: function (params: string, content: string) {
				return '</span>';
			}
		},
		ul: {
			openTag: function (params: string, content: string) {
				return '<ul>';
			},
			closeTag: function (params: string, content: string) {
				return '</ul>';
			},
			restrictChildrenTo: ['*', 'li']
		},
		url: {
			openTag: function (params: string, content: string) {
				let myUrl: string;

				if (!params) {
					myUrl = content.replace(/<.*?>/g, '');
				} else {
					myUrl = params.substr(1);
				}

				XBBCODE.urlPattern.lastIndex = 0;
				if (!XBBCODE.urlPattern.test(myUrl)) {
					myUrl = '#';
				}

				return '<a href="' + myUrl + '">';
			},
			closeTag: function (params: string, content: string) {
				return '</a>';
			}
		},
		/*
		 The [*] tag is special since the user does not define a closing [/*] tag when writing their bbcode.
		 Instead this module parses the code and adds the closing [/*] tag in for them. None of the tags you
		 add will act like this and this tag is an exception to the others.
		 */
		'*': {
			openTag: function (params: string, content: string) {
				return '<li>';
			},
			closeTag: function (params: string, content: string) {
				return '</li>';
			},
			restrictParentsTo: ['list', 'ul', 'ol']
		}
	};

	// create tag list and lookup fields
	private static initTags() {
		XBBCODE.tagList = [];
		for (const prop in XBBCODE.tags) {
			if (XBBCODE.tags.hasOwnProperty(prop)) {
				if (prop === '*') {
					XBBCODE.tagList.push('\\' + prop);
				} else {
					XBBCODE.tagList.push(prop);
					if (XBBCODE.tags[prop].noParse) {
						XBBCODE.tagsNoParseList.push(prop);
					}
				}

				XBBCODE.tags[prop].validChildLookup = {};
				XBBCODE.tags[prop].validParentLookup = {};
				XBBCODE.tags[prop].restrictParentsTo = XBBCODE.tags[prop].restrictParentsTo || [];
				XBBCODE.tags[prop].restrictChildrenTo = XBBCODE.tags[prop].restrictChildrenTo || [];

				//let aa:{[ket:string]:boolean} = {};
				//let tag = XBBCODE.tags[prop].restrictChildrenTo || [];
				XBBCODE.tags[prop].validChildLookup = {};
				let len: number = XBBCODE.tags[prop].restrictChildrenTo!.length;
				for (let ii = 0; ii < len; ii++) {
					XBBCODE.tags[prop].validChildLookup![XBBCODE.tags[prop].restrictChildrenTo![ii]] = true;
				}

				//aa = {};
				//tag = XBBCODE.tags[prop].restrictParentsTo || [];
				XBBCODE.tags[prop].validParentLookup = {};
				len = XBBCODE.tags[prop].restrictParentsTo!.length;
				for (let ii = 0; ii < len; ii++) {
					XBBCODE.tags[prop].validParentLookup![XBBCODE.tags[prop].restrictParentsTo![ii]] = true;
				}
			}
		}

		XBBCODE.bbRegExp = new RegExp('<bbcl=([0-9]+) (' + XBBCODE.tagList.join('|') + ')([ =][^>]*?)?>((?:.|[\\r\\n])*?)<bbcl=\\1 /\\2>', 'gi');
		XBBCODE.pbbRegExp = new RegExp('\\[(' + XBBCODE.tagList.join('|') + ')([ =][^\\]]*?)?\\]([^\\[]*?)\\[/\\1\\]', 'gi');
		XBBCODE.pbbRegExp2 = new RegExp('\\[(' + XBBCODE.tagsNoParseList.join('|') + ')([ =][^\\]]*?)?\\]([\\s\\S]*?)\\[/\\1\\]', 'gi');

		// create the regex for escaping ['s that aren't apart of tags
		(function () {
			const closeTagList: string[] = [];
			for (let ii = 0; ii < XBBCODE.tagList.length; ii++) {
				if (XBBCODE.tagList[ii] !== '\\*') {
					// the * tag doesn't have an offical closing tag
					closeTagList.push('/' + XBBCODE.tagList[ii]);
				}
			}

			XBBCODE.openTags = new RegExp('(\\[)((?:' + XBBCODE.tagList.join('|') + ')(?:[ =][^\\]]*?)?)(\\])', 'gi');
			XBBCODE.closeTags = new RegExp('(\\[)(' + closeTagList.join('|') + ')(\\])', 'gi');
		})();
	}

	// -----------------------------------------------------------------------------
	// private functions
	// -----------------------------------------------------------------------------

	private static checkParentChildRestrictions(
		parentTag: string,
		bbcode: string,
		bbcodeLevel: number,
		tagName: string,
		tagParams: string,
		tagContents: string,
		_errQueue?: string[]
	) {
		let errQueue: string[] = _errQueue || [];
		bbcodeLevel++;

		// get a list of all of the child tags to this tag
		const reTagNames = new RegExp('(<bbcl=' + bbcodeLevel + ' )(' + XBBCODE.tagList.join('|') + ')([ =>])', 'gi');
		const reTagNamesParts = new RegExp('(<bbcl=' + bbcodeLevel + ' )(' + XBBCODE.tagList.join('|') + ')([ =>])', 'i');
		const matchingTags: string[] = tagContents.match(reTagNames) || [];
		const pInfo: IXBBCODETag = XBBCODE.tags[parentTag] || {};

		reTagNames.lastIndex = 0;

		if (!matchingTags) {
			tagContents = '';
		}

		for (let ii = 0; ii < matchingTags.length; ii++) {
			reTagNamesParts.lastIndex = 0;
			const childTag: string = (matchingTags[ii].match(reTagNamesParts) || [])[2].toLowerCase();

			if (pInfo && pInfo.restrictChildrenTo && pInfo.restrictChildrenTo.length > 0) {
				if (!pInfo.validChildLookup![childTag]) {
					const errStr: string = 'The tag "' + childTag + '" is not allowed as a child of the tag "' + parentTag + '".';
					errQueue.push(errStr);
				}
			}
			const cInfo: IXBBCODETag = XBBCODE.tags[childTag] || {};
			if ((cInfo.restrictParentsTo || []).length > 0) {
				if (!cInfo.validParentLookup![parentTag]) {
					const errStr = 'The tag "' + parentTag + '" is not allowed as a parent of the tag "' + childTag + '".';
					errQueue.push(errStr);
				}
			}
		}

		tagContents = tagContents.replace(
			XBBCODE.bbRegExp,
			function (matchStr: string, bbcodeLevel: number, tagName: string, tagParams: string, tagContents: string) {
				errQueue = XBBCODE.checkParentChildRestrictions(tagName.toLowerCase(), matchStr, bbcodeLevel, tagName, tagParams, tagContents, errQueue);
				return matchStr;
			}
		);
		return errQueue;
	}

	/*
	 This function updates or adds a piece of metadata to each tag called "bbcl" which
	 indicates how deeply nested a particular tag was in the bbcode. This property is removed
	 from the HTML code tags at the end of the processing.
	 */
	private static updateTagDepths(tagContents: string) {
		tagContents = tagContents.replace(/\<([^\>][^\>]*?)\>/gi, function (matchStr: string, subMatchStr: string) {
			const bbCodeLevel = subMatchStr.match(/^bbcl=([0-9]+) /);
			if (bbCodeLevel === null) {
				return '<bbcl=0 ' + subMatchStr + '>';
			} else {
				return (
					'<' +
					subMatchStr.replace(/^(bbcl=)([0-9]+)/, function (matchStr: string, m1: string, m2: string) {
						return m1 + (parseInt(m2, 10) + 1);
					}) +
					'>'
				);
			}
		});
		return tagContents;
	}

	/*
	 This function removes the metadata added by the updateTagDepths function
	 */
	private static unprocess(tagContent: string) {
		return tagContent
			.replace(/<bbcl=[0-9]+ \/\*>/gi, '')
			.replace(/<bbcl=[0-9]+ /gi, '&#91;')
			.replace(/>/gi, '&#93;');
	}

	private static replaceFunct(matchStr: string, bbcodeLevel: number, tagName: string, tagParams: string, tagContents: string): string {
		tagName = tagName.toLowerCase();

		//IXBBCODETag
		const code = XBBCODE.tags[tagName];
		let processedContent: string = XBBCODE.tags[tagName].noParse ? XBBCODE.unprocess(tagContents) : tagContents.replace(XBBCODE.bbRegExp, XBBCODE.replaceFunct),
			openTag = typeof code.openTag !== 'undefined' ? code.openTag(tagParams, processedContent) : '',
			closeTag = typeof code.closeTag !== 'undefined' ? code.closeTag(tagParams, processedContent) : '';

		if (XBBCODE.tags[tagName].displayContent === false) {
			processedContent = '';
		}

		return openTag + processedContent + closeTag;
	}

	private static parse(config: IXBBCODEConfig) {
		let output: string = config.text;
		output = output.replace(XBBCODE.bbRegExp, XBBCODE.replaceFunct);
		return output;
	}

	/*
	 The star tag [*] is special in that it does not use a closing tag. Since this parser requires that tags to have a closing
	 tag, we must pre-process the input and add in closing tags [/*] for the star tag.
	 We have a little levaridge in that we know the text we're processing wont contain the <> characters (they have been
	 changed into their HTML entity form to prevent XSS and code injection), so we can use those characters as markers to
	 help us define boundaries and figure out where to place the [/*] tags.
	 */
	private static fixStarTag(text: string) {
		text = text.replace(/\[(?!\*[ =\]]|list([ =][^\]]*)?\]|\/list[\]])/gi, '<');
		text = text.replace(/\[(?=list([ =][^\]]*)?\]|\/list[\]])/gi, '>');

		while (
			text !==
			(text = text.replace(/>list([ =][^\]]*)?\]([^>]*?)(>\/list])/gi, function (matchStr: string, contents: string, endTag: string) {
				let innerListTxt = matchStr;
				while (
					innerListTxt !==
					(innerListTxt = innerListTxt.replace(/\[\*\]([^\[]*?)(\[\*\]|>\/list])/i, function (matchStr: string, contents: string, endTag: string) {
						if (endTag.toLowerCase() === '>/list]') {
							endTag = '</*]</list]';
						} else {
							endTag = '</*][*]';
						}
						return '<*]' + contents + endTag;
					}))
					) ;

				innerListTxt = innerListTxt.replace(/>/g, '<');
				return innerListTxt;
			}))
			) ;

		// add ['s for our tags back in
		text = text.replace(/</g, '[');
		return text;
	}

	private static addBbcodeLevels(text: string) {
		while (
			text !==
			(text = text.replace(XBBCODE.pbbRegExp, function (matchStr: string, tagName: string, tagParams: string, tagContents: string) {
				matchStr = matchStr.replace(/\[/g, '<');
				matchStr = matchStr.replace(/\]/g, '>');
				return XBBCODE.updateTagDepths(matchStr);
			}))
			) ;
		return text;
	}

	private static inited = false;

	public static process(config: IXBBCODEConfig): IXBBCODEResult {
		//  Check for inited
		if (!XBBCODE.inited) {
			//  Init
			XBBCODE.initTags();
			XBBCODE.inited = true;
		}

		//  Now really process
		const ret: IXBBCODEResult = {html: '', error: false};
		let errQueue: string[] = [];

		config.text = config.text.replace(/</g, '&lt;'); // escape HTML tag brackets
		config.text = config.text.replace(/>/g, '&gt;'); // escape HTML tag brackets

		config.text = config.text.replace(XBBCODE.openTags, function (matchStr: string, openB: string, contents: string, closeB: string) {
			return '<' + contents + '>';
		});
		config.text = config.text.replace(XBBCODE.closeTags, function (matchStr: string, openB: string, contents: string, closeB: string) {
			return '<' + contents + '>';
		});

		config.text = config.text.replace(/\[/g, '&#91;'); // escape ['s that aren't apart of tags
		config.text = config.text.replace(/\]/g, '&#93;'); // escape ['s that aren't apart of tags
		config.text = config.text.replace(/</g, '['); // escape ['s that aren't apart of tags
		config.text = config.text.replace(/>/g, ']'); // escape ['s that aren't apart of tags

		// process tags that don't have their content parsed
		while (
			config.text !==
			(config.text = config.text.replace(XBBCODE.pbbRegExp2, function (matchStr: string, tagName: string, tagParams: string, tagContents: string) {
				tagContents = tagContents.replace(/\[/g, '&#91;');
				tagContents = tagContents.replace(/\]/g, '&#93;');
				tagParams = tagParams || '';
				tagContents = tagContents || '';
				return '[' + tagName + tagParams + ']' + tagContents + '[/' + tagName + ']';
			}))
			) ;

		config.text = XBBCODE.fixStarTag(config.text); // add in closing tags for the [*] tag
		config.text = XBBCODE.addBbcodeLevels(config.text); // add in level metadata

		errQueue = XBBCODE.checkParentChildRestrictions('bbcode', config.text, -1, '', '', config.text);

		ret.html = XBBCODE.parse(config);

		if (ret.html.indexOf('[') !== -1 || ret.html.indexOf(']') !== -1) {
			errQueue.push('Some tags appear to be misaligned.');
		}

		if (config.removeMisalignedTags) {
			ret.html = ret.html.replace(/\[.*?\]/g, '');
		}
		if (config.addInLineBreaks) {
			ret.html = '<div style="white-space:pre-wrap;">' + ret.html + '</div>';
		}

		if (!config.escapeHtml) {
			ret.html = ret.html.replace('&#91;', '['); // put ['s back in
			ret.html = ret.html.replace('&#93;', ']'); // put ['s back in
		}

		ret.error = errQueue.length !== 0;
		ret.errorQueue = errQueue;

		return ret;
	}
}
