// More messages are in ClerMaster - RingMessage.java
export class BKMessageConstants {
	/**
	 * Remote message received by the ring
	 */
	public static readonly REMOTE_MESSAGE_BASKET: string = 'basket';
	public static readonly REMOTE_MESSAGE_BASKET_PREVIEW: string = 'basket_preview';
	public static readonly REMOTE_MESSAGE_POS_UNATTENDED: string = 'pos_is_unattended';
	public static readonly REMOTE_MESSAGE_SMART_COD_PROBING: string = 'probing_smart_cod';
	public static readonly BROADCAST_MESSAGE_POS_UNATTENDED: string = 'broadcast_pos_is_unattended';
	public static readonly REMOTE_MESSAGE_SMART_COD_HEARTBEAT: string = 'smart_cod_heartbeat';
	public static readonly BROADCAST_MESSAGE_SMART_COD_HEARTBEAT = 'broadcast_smart_cod_heartbeat';
	public static readonly REMOTE_MESSAGE_SMART_COD_PAIRED_WITH_DRIVE_DEVICE: string = 'smart_cod_paired_with_drive_device';
	public static readonly BROADCAST_MESSAGE_SMART_COD_PAIRED_WITH_DRIVE_DEVICE: string = 'broadcast_smart_cod_paired_with_drive_device';
	public static readonly REMOTE_MESSAGE_SMART_COD_PRODUCT_SELECTION: string = 'smart_cod_product_selection';
	public static readonly REMOTE_MESSAGE_SMART_COD_ORB_NUMBER: string = 'smart_cod_show_orb_number';
	public static readonly REMOTE_MESSAGE_SMART_COD_FIDELITY_DATA: string = 'smart_cod_fidelity_data';
	public static readonly REMOTE_MESSAGE_SMART_COD_FIDELITY_LOGIN_ERROR: string = 'smart_cod_fidelity_loging_error';
	public static readonly REMOTE_MESSAGE_SMART_COD_DRIVECO_ACTIVITTY: string = 'smart_cod_drive_co_is_actively_used';
	public static readonly REMOTE_MESSAGE_SMART_COD_TOGGLE_WELCOME_SCREEN: string = 'smart_cod_toggle_welcome_screen';
	public static readonly REMOTE_MESSAGE_SMART_COD_DISPLAY_MENU_STEPS: string = 'smart_cod_display_menu_steps';;
	public static readonly REMOTE_MESSAGE_SMART_COD_TOGGLE_BASKET_PREVIEW_FULLSCREEN: string = 'smart_cod_toggle_basket_preview_fullscreen';

	public static readonly REMOTE_MESSAGE_COD_DISPLAY_FIDELITY_DATA = 'cod_display_fidelity_data';
	public static readonly REMOTE_MESSAGE_COD_DISPLAY_PRIVILEGES_DIALOG = 'cod_display_privileges_dialog';
	public static readonly REMOTE_MESSAGE_COD_SELECT_PRIVILEGES = 'cod_select_privileges';
	public static readonly REMOTE_MESSAGE_COD_DISPLAY_AVAILABLE_ITEMS_PRIVILEGES = 'cod_display_available_items_privileges';
	public static readonly REMOTE_MESSAGE_COD_SELECT_AVAILABLE_ITEMS_PRIVILEGES = 'cod_select_available_items_privileges';
	public static readonly REMOTE_MESSAGE_COD_DISPLAY_CLASSIC_PRIVILEGES = 'cod_display_classic_privileges';
	public static readonly REMOTE_MESSAGE_COD_SELECT_CLASSIC_PRIVILEGE = 'cod_select_classic_privilege';
	public static readonly REMOTE_MESSAGE_COD_CLOSE_FIDELITY_DIALOG = 'cod_close_fidelity_dialog';

	public static readonly REMOTE_MESSAGE_SMART_COD_SHUTTING_DOWN: string = 'smart_cod_shutting_down';
	public static readonly BROADCAST_MESSAGE_SMART_COD_SHUTTING_DOWN: string = 'broadcast_smart_cod_shutting_down';

	public static readonly REMOTE_MESSAGE_SMART_COD_VOICE_CHAT_ORDER_PREVIEW = 'smart_cod_voice_chat_order_preview';
	public static readonly BROADCAST_MESSAGE_SMART_COD_VOICE_CHAT_ORDER_PREVIEW = 'broadcast_smart_cod_voice_chat_order_preview';

	public static readonly REMOTE_MESSAGE_SMART_COD_VOICE_CHAT_EVENT = 'smart_cod_voice_chat_event';
	public static readonly BROADCAST_MESSAGE_SMART_COD_VOICE_CHAT_EVENT = 'broadcast_smart_cod_voice_chat_event';

	public static readonly REMOTE_MESSAGE_BASKET_COD: string = 'basketcod';
	public static readonly REMOTE_MESSAGE_BASKET_COD_EX: string = 'basketcodex';
	public static readonly REMOTE_MESSAGE_BASKET_UPDATE: string = 'basketupdate';
	public static readonly REMOTE_MESSAGE_BASKET_UPDATE_CSI: string = 'basketupdatecsi';
	public static readonly REMOTE_MESSAGE_ORB_PREPARE: string = 'orbprepare';
	public static readonly REMOTE_MESSAGE_ORB_FINISHED: string = 'orbfinished';
	public static readonly REMOTE_MESSAGE_ORB_CLEAR: string = 'orbfinished';
	public static readonly REMOTE_MESSAGE_ORB_DISPLAY: string = 'orbdisplay';
	public static readonly REMOTE_MESSAGE_KDS_FINISHED: string = 'kdsfinished';
	public static readonly REMOTE_MESSAGE_KDS_CSI_PRINT: string = 'PRINT_COMMANDE_KDS';
	public static readonly REMOTE_MESSAGE_KDS_CSI_PRINT_JS: string = 'PRINT_COMMANDE_KDS_JS';
	public static readonly REMOTE_MESSAGE_KINGDOM_LOCK_CONNECTING: string = 'REMOTE_MESSAGE_KINGDOM_LOCK_CONNECTING';
	public static readonly REMOTE_MESSAGE_KINGDOM_LOCK_ALREADY_CONNECTED: string = 'REMOTE_MESSAGE_KINGDOM_LOCK_ALREADY_CONNECTED';
	public static readonly REMOTE_MESSAGE_COUPON_BURGER_MYSTERE_LOCK_CONNECTING: string = 'REMOTE_MESSAGE_COUPON_BURGER_MYSTERE_LOCK_CONNECTING';
	public static readonly REMOTE_MESSAGE_COUPON_BURGER_MYSTERE_LOCK_ALREADY_CONNECTED: string = 'REMOTE_MESSAGE_COUPON_BURGER_MYSTERE_LOCK_ALREADY_CONNECTED';
	public static readonly REMOTE_MESSAGE_COUPON_LOCAL_MAILINGS_LOCK_CONNECTING: string = 'REMOTE_MESSAGE_COUPON_LOCAL_MAILINGS_LOCK_CONNECTING';
	public static readonly REMOTE_MESSAGE_COUPON_LOCAL_MAILINGS_LOCK_ALREADY_CONNECTED: string = 'REMOTE_MESSAGE_COUPON_LOCAL_MAILINGS_LOCK_ALREADY_CONNECTED';
	public static readonly REMOTE_MESSAGE_DRIVE_QUEUE: string = 'drivequeue';
	public static readonly REMOTE_MESSAGE_PREPRODUCTION_BASKET = 'preproduction-basket';

	public static readonly REMOTE_MESSAGE_LIVE_BASKET_UPDATE = 'live-basket-update';
	public static readonly BROADCAST_LIVE_BASKET_UPDATE = 'broadcast-live-basket-update';

	/** ORBp - Printing in DSS Mode */
	public static readonly REMOTE_MESSAGE_PRINT_ORBP_ORDER: string = 'print_orbp_order';

	/** ORBp - Printing test configuration */
	public static readonly REMOTE_MESSAGE_PRINT_ORBP_CONFIGURATION: string = 'print_orbp_configuration';

	/**
	 * The legacy way of synchronization
	 */
	public static readonly REMOTE_MESSAGE_ORBP_SLAVE_PAGES: string = 'orbpslavepages';
	/**
	 * The new way of synchronization of checked lines
	 */
	public static readonly REMOTE_MESSAGE_ORBP_CHANGED_LINE: string = 'orbp_changed_line';
	/**
	 * React the message about checked lines from other ORBps (both from Masters and Dependents)
	 */
	public static readonly BROADCAST_ORBP_CHANGED_LINE: string = 'broadcast_orbp_changed_line';

	/**
	 * The new way of synchronization between ORBPs.
	 *
	 * When ORBp boots-up, it asks its Master's for their current state, so it can sync checked Lines and bumped Orders
	 */
	public static readonly REMOTE_MESSAGE_REQUEST_FOR_INITIAL_SYNCHRONIZATION = 'orbp_request_for_initial_synchronization';

	/**
	 * The new way of synchronization between ORBPs.
	 *
	 * Response with currently checked Lines and bumped Orders
	 */
	public static readonly REMOTE_MESSAGE_RESPONSE_TO_INITIAL_SYNCHRONIZATION = 'orbp_response_to_initial_synchronization';

	/**
	 * The new way of synchronization of bumped Orders
	 */
	public static readonly REMOTE_MESSAGE_ORBP_BUMPED_ORDER: string = 'orbp_bumped_order';
	/**
	 * New sync
	 * React the message about bumped Orders from other ORBps (both from Masters and Dependents)
	 */
	public static readonly BROADCAST_ORBP_BUMPED_ORDER: string = 'broadcast_orbp_bumped_order';

	/**
	 * The new way of synchronization of parked/abandoned Orders
	 */
	public static readonly REMOTE_MESSAGE_ORBP_PARKED_ORDER: string = 'orbp_parked_order';
	/**
	 * New sync
	 */
	public static readonly BROADCAST_ORBP_PARKED_ORDER: string = 'broadcast_orbp_parked_order';

	/**
	 * New sync for Legacy Slaves - triggers when new Order arrives to Master
	 */
	public static readonly REMOTE_MESSAGE_ORBP_LEGACY_SLAVE_NEW_ORDER_NOTIFICATION = 'rm_orbp_legacy_slave_new_order_notification';

	public static readonly REMOTE_MESSAGE_ORB_RELOAD_DEVICE = 'rm_orb_reload_device';
	public static readonly BROADCAST_MESSAGE_ORB_RELOAD_DEVICE = 'broadcast_orb_reload_device';

	/**
	 * New sync for Legacy Slaves - triggers when new Order arrives to Master
	 */
	public static readonly BROADCAST_ORBP_LEGACY_SLAVE_NEW_ORDER_NOTIFICATION = 'broadcast_orbp_legacy_slave_new_order_notification';

	/**
	 * The new way of synchronization between ORBp and ORBs.
	 *
	 * When ORBs boots-up, it asks its Master ORBps for currently active orders to be shown on ORBs
	 */
	public static readonly REMOTE_MESSAGE_REQUEST_FOR_INITIAL_ORBS_SYNCHRONIZATION = 'orbs_request_for_initial_synchronization';

	/**
	 * The new way of synchronization between ORBp and ORBs.
	 *
	 * Response with currently active orders to be shown on ORBs
	 */
	public static readonly REMOTE_MESSAGE_RESPONSE_TO_INITIAL_ORBS_SYNCHRONIZATION = 'orbs_response_to_initial_synchronization';

	/**
	 * For DSS mode with LoadBalancing.
	 * Synchronization of Assigned Orders to specific ORBps.
	 *
	 * The info is used by ORBp, PrintServer and ClerkMaster.
	 */
	public static readonly REMOTE_MESSAGE_ORDER_ASSIGNED_TO_ORBP = 'assignedorbp';
	public static readonly BROADCAST_ORDER_ASSIGNED_TO_ORBP = 'broadcast_assignedorbp';

	/** Way to notify ORBs dependant on ORBp that the master ORBp has changed configuration. */
	public static readonly REMOTE_MESSAGE_ORBP_CONFIG_HAS_CHANGED = 'orbp_config_change';
	public static readonly BROADCAST_ORBP_CONFIG_HAS_CHANGED = 'broadcast_orbp_config_change';

	public static readonly REMOTE_MESSAGE_ORBD_SLAVE_PAGES: string = 'orbdslavepages';
	public static readonly REMOTE_MESSAGE_IMACHINE_START: string = 'imachinestart';
	public static readonly REMOTE_MESSAGE_KDS_START: string = 'kdsstart';
	public static readonly REMOTE_MESSAGE_MENU_ORDER_HELPER_OPEN_COD: string = 'menuorderopencod';
	public static readonly REMOTE_MESSAGE_MENU_ORDER_HELPER_DONE_COD: string = 'menuorderdonecod';
	public static readonly REMOTE_MESSAGE_ORDER_CANCEL_COD: string = 'ordercancelcod';
	public static readonly REMOTE_MESSAGE_ORDER_FINISHED_COD: string = 'orderfinishedcod';
	public static readonly REMOTE_MESSAGE_DRIVE_JAM: string = 'drivejam';
	public static readonly REMOTE_MESSAGE_DRIVE_MODE: string = 'drivemode';

	public static readonly REMOTE_MESSAGE_ORBP_LOAD_ASSIGN: string = 'orbloadassign';
	public static readonly REMOTE_MESSAGE_ORBP_ORDER_FINISHED: string = 'orborderdelivered';

	public static readonly REMOTE_MESSAGE_ORBP_ORDER_COMPLETE: string = 'orbpordercomplete';

	public static readonly REMOTE_MESSAGE_COD_WORKING_SCANNER: string = 'bkcodworkingscanner';
	public static readonly REMOTE_MESSAGE_COD_SCAN: string = 'bkcodscan';
	public static readonly REMOTE_MESSAGE_COD_SCAN_KINGDOM_LOGIN_RESULT: string = 'bkcodkingdomloginresult';
	public static readonly REMOTE_MESSAGE_COD_SCAN_COUPON_RESULT: string = 'bkcodscancouponresult';

	// public static readonly REMOTE_MESSAGE_CC_ORDERS_DISABLED: string = 'ccordersdisabled';

	/*
	 * Messages broadcasted inside the application
	 */
	public static readonly BROADCAST_ACTIF: string = 'bk_on';
	public static readonly BROADCAST_INACTIF: string = 'bk_off';

	public static readonly BROADCAST_ORDER_RECEIVED: string = 'bk_orderReceived';
	public static readonly BROADCAST_PREPRODUCTION_ORDER_RECEIVED: string = 'bk_preproductionOrderReceived';
	public static readonly BROADCAST_ORDER_RECEIVED_COD: string = 'bk_orderReceived_cod';
	public static readonly BROADCAST_ORDER_RECEIVED_COD_EX: string = 'bk_orderReceived_cod_ex';
	public static readonly BROADCAST_ORDER_STATE_RECEIVED: string = 'bk_orderStateReceived'; // Legacy way of synchronizing bumped orders

	// New sync of Dismissed orders
	public static readonly BROADCAST_ORDER_DISMISSED: string = 'BROADCAST_ORDER_DISMISSED';

	/**
	 * The new way of synchronization between ORBPs.
	 *
	 * This ORBp has received initial sync timeBasedPredictions from its Master(s)
	 */
	public static readonly BROADCAST_DATA_FOR_INITIAL_SYNCHRONIZATION_RECEIVED = 'data_for_initial_synchronization_received';

	/**
	 * The new way of synchronization between ORBPs.
	 *
	 * This ORBp has received request for initial timeBasedPredictions sync.
	 * If this ORBp is Master or Dependent, then it will send its state back to the requesting ORBp
	 */
	public static readonly BROADCAST_REQUEST_FOR_INITIAL_SYNCHRONIZATION_RECEIVED = 'request_for_initial_synchronization_received';

	/**
	 * The new way of synchronization between ORBPs.
	 *
	 * This ORBp has received request for initial timeBasedPredictions sync.
	 * If this ORBp is Master or Dependent, then it will send its state back to the requesting ORBp
	 */
	public static readonly BROADCAST_RESPONSE_TO_INITIAL_SYNCHRONIZATION_RECEIVED = 'response_to_initial_synchronization_received';

	/**
	 * The new way of synchronization between ORBPs.
	 *
	 * This ORBp has received request for initial timeBasedPredictions sync.
	 * If this ORBp is Master or Dependent, then it will send its state back to the requesting ORBp
	 */
	public static readonly BROADCAST_REQUEST_FOR_INITIAL_ORBS_SYNCHRONIZATION_RECEIVED = 'request_for_initial_orbs_synchronization_received';

	/**
	 * The new way of synchronization between ORBPs.
	 *
	 * This ORBp has received request for initial timeBasedPredictions sync.
	 * If this ORBp is Master or Dependent, then it will send its state back to the requesting ORBp
	 */
	public static readonly BROADCAST_RESPONSE_TO_INITIAL_ORBS_SYNCHRONIZATION_RECEIVED = 'response_to_initial_orbs_synchronization_received';

	public static readonly BROADCAST_MENU_ORDER_HELPER_OPEN_COD: string = 'bk_menu_helper_open_cod';
	public static readonly BROADCAST_MENU_ORDER_HELPER_DONE_COD: string = 'bk_menu_helper_done_cod';
	public static readonly BROADCAST_CANCEL_ORDER_COD: string = 'bk_cancel_order_cod';
	public static readonly BROADCAST_FINISHED_ORDER_COD: string = 'bk_order_finished_cod';

	public static readonly BROADCAST_ORB_PREPARE: string = 'bk_orbprepare';
	public static readonly BROADCAST_ORB_FINISHED: string = 'bk_orbfinished';
	public static readonly BROADCAST_ORB_CLEAR: string = 'bk_orbclear';

	public static readonly BROADCAST_ORB_DISPLAY: string = 'bk_orbdisplay';

	public static readonly BROADCAST_KDS_FINISHED: string = 'bk_kdsfinished';

	public static readonly BROADCAST_BIGDATAS_UPDATED: string = 'bk_bigdatas_updated';

	public static readonly BROADCAST_DRIVE_MODE: string = 'bk_drive_mode';
	public static readonly BROADCAST_DRIVE_QUEUE: string = 'bk_drive_queue';

	public static readonly BROADCAST_KINGDOM_LOCK_CONNECTING: string = 'BROADCAST_KINGDOM_LOCK_CONNECTING';
	public static readonly BROADCAST_KINGDOM_LOCK_ALREADY_CONNECTED: string = 'BROADCAST_KINGDOM_LOCK_ALREADY_CONNECTED';

	public static readonly BROADCAST_COUPON_BURGER_MYSTERE_LOCK_CONNECTING: string = 'BROADCAST_COUPON_BURGER_MYSTERE_LOCK_CONNECTING';
	public static readonly BROADCAST_COUPON_BURGER_MYSTERE_LOCK_ALREADY_CONNECTED: string = 'BROADCAST_COUPON_BURGER_MYSTERE_LOCK_ALREADY_CONNECTED';

	public static readonly BROADCAST_COUPON_LOCAL_MAILINGS_LOCK_CONNECTING: string = 'BROADCAST_COUPON_LOCAL_MAILINGS_LOCK_CONNECTING';
	public static readonly BROADCAST_COUPON_LOCAL_MAILINGS_LOCK_ALREADY_CONNECTED: string = 'BROADCAST_COUPON_LOCAL_MAILINGS_LOCK_ALREADY_CONNECTED';

	public static readonly BROADCAST_ORBP_SLAVE_PAGES: string = 'bk_orbp_slave_page';
	public static readonly BROADCAST_ORBD_SLAVE_PAGES: string = 'bk_orbd_slave_page';

	public static readonly BROADCAST_IMACHINE_START: string = 'bk_i_machine_start';
	public static readonly BROADCAST_KDS_START: string = 'bk_i_kds_start';

	public static readonly BROADCAST_DRIVE_JAM: string = 'bk_drive_jam';

	public static readonly BROADCAST_ORBP_SLAVE_ASSIGN: string = 'bk_orbp_assign';
	public static readonly BROADCAST_ORBP_ORDER_FINISHED: string = 'bk_orbp_delivered';

	public static readonly BROADCAST_ORBP_ORDER_COMPLETE: string = 'bk_orbp_order_complete';

	public static readonly BROADCAST_COD_WORKING_SCANNER: string = 'bk_cod_working_scanner';
	public static readonly BROADCAST_COD_SCAN: string = 'bk_cod_scan';
	public static readonly BROADCAST_COD_SCAN_KINGDOM_LOGIN_RESULT: string = 'bk_cod_kingdom_login_result';
	public static readonly BROADCAST_COD_SCAN_COUPON_RESULT: string = 'bk_cod_coupon_result';

	public static readonly SCRIPT_PRINT_SPE_KDS: string = 'PRINT_SPE_KDS';

	public static readonly BROADCAST_SCAN_DETECTED = 'BKScanDetected';

	/**
	 * Triggered when a car leaves by the DELIVERY Window captor
	 */
	public static readonly DTT_DELIVERY_OFF = 'dttdeliveryoff';

	/**
	 * Triggered when a car enters by the DELIVERY Window captor
	 */
	public static readonly DTT_DELIVERY_ON = 'dttdeliveryon';

	public static readonly DISABLE_KIOSK = 'disable_kiosk';
	public static readonly ENABLE_KIOSK = 'enable_kiosk';

	/**
	 * Used for message scans in new applications, scanned messages have format: scan_messages|906541091340100022500193
	 * we add bktype dynamically in new componentss
	 * @type {string}
	 */
	public static readonly SCAN_MESSAGES = 'scan_messages';

	public static readonly REMOTE_MESSAGE_ORDER_PATCH_PAID_DATE: string = 'order_patch_paid_date';

	/** PMS */
	public static readonly REMOTE_MESSAGE_PREPRODUCTION_DISPLAY_DATA = 'pms_displayed_data';
	public static readonly REMOTE_MESSAGE_PMS_PRODUCT_STATE = 'pms_product_state';
	public static readonly REMOTE_MESSAGE_PMS_CHANGE_MODE = 'pms_active_mode';
	public static readonly REMOTE_MESSAGE_PMS_DATA_REQUEST = 'pms_data_request';
	public static readonly REMOTE_MESSAGE_PMS_RESET_STORAGE = 'pms_reset_storage';
	public static readonly REMOTE_MESSAGE_PMS_PROGRAM_CHANGE = 'pms_program_change';
}
