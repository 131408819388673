import { OrbpRole, OrbStatus, SalesChannel } from '../enums';
import { isSalesChannelOn } from "../functions/sales-channels/index";
import { OrbpDetail, SalesChannelConfiguration } from '../types';

/**
 * Orders created on POS should be handled on POS if there is no ORBp device displaying Orders created on POS
 */
export function shouldHandlePosOrdersOnPos(allProgramOrbps: OrbpDetail[]): boolean {
	const canOrbpHandlePosOrders = (salesChannels: SalesChannelConfiguration[]) => salesChannels.some((sc) =>
		sc.salesChannel === SalesChannel.CASH_MACHINE && isSalesChannelOn(sc)
	);

	const orbpsHandlingPosOrders = allProgramOrbps.filter(
		(orbp) => orbp.status === OrbStatus.On && orbp.role === OrbpRole.Master && canOrbpHandlePosOrders(orbp.salesChannelConfigurations)
	);

	const shouldHandlePosOrdersOnCash = orbpsHandlingPosOrders.length === 0;
	return shouldHandlePosOrdersOnCash;
}
