import { BKDeliveryModeEnum } from '@bk/jscommondatas';
import { SANDBOXES } from '../constants';

export const getSandboxNameByDeliverySource = (delivery: BKDeliveryModeEnum): SANDBOXES => {
	switch (delivery) {
		case BKDeliveryModeEnum.DELIVEROO:
			return SANDBOXES.DELIVEROO;
		case BKDeliveryModeEnum.DELIVERY_BK:
			return SANDBOXES.DELIVERYBK;
		case BKDeliveryModeEnum.JUST_EAT:
			return SANDBOXES.JUSTEAT;
		case BKDeliveryModeEnum.UBER_EAT:
			return SANDBOXES.UBEREATS;
		case BKDeliveryModeEnum.DELIVERY_LOCAL:
		case BKDeliveryModeEnum.NONE:
		default:
			return undefined;
	}
};
