import cloneDeep from 'lodash-es/cloneDeep';

export class TagsModel {
	id: number;
	name: string;
	description: string;

	constructor(init?: Partial<TagsModel>) {
		Object.assign(this, cloneDeep(init));
	}
}
