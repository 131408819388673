export function OnPropertyChange<T>(methodName: string, scope?: unknown): (target: T, key: keyof T) => void {
	return (target: T, key: keyof T): void => {
		const originalDescriptor = <PropertyDescriptor>Object.getOwnPropertyDescriptor(target, key);

		let val: T;

		// Wrap hook methods

		Object.defineProperty(target, key, {
			set(value): void {
				// eslint-disable-next-line @typescript-eslint/no-this-alias
				const instance = this;

				const previousValue = val;

				if (previousValue === value) {
					return;
				}

				val = value;

				if (originalDescriptor) {
					originalDescriptor.set?.call(instance, value);
				}

				if (methodName && val !== previousValue) {
					instance[methodName].call(scope || instance, value, previousValue);
				}
			},

			get(): T {
				// eslint-disable-next-line @typescript-eslint/no-this-alias
				const instance = this;

				if (originalDescriptor) {
					return originalDescriptor.get?.call(instance);
				}

				return val;
			}
		});
	};
}
