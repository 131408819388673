import { IBKPublishedOrderData } from '@bk/jscommondatas';
import { SalesChannel } from "../enums";
import { canDisplayOrderBySalesChannelOptions, getAllowedSalesChannels, isOrderSourceAllowed } from '../functions';

import { SalesChannelConfiguration } from '../types/sales-channel-configuration';

// If ORBp has allowed SalesChannel matching the OrderSource, then the Order can be potentially displayed
export function isOrderSalesChannelAllowedInOrbp(order: IBKPublishedOrderData, orbpSalesChannelConfigurations: SalesChannelConfiguration[]): boolean {
	const allowedSalesChannels: SalesChannel[] = getAllowedSalesChannels(orbpSalesChannelConfigurations);
	const isAllowedBySource = isOrderSourceAllowed(order.source, allowedSalesChannels);
	const isAllowedBySalesChannelOption = canDisplayOrderBySalesChannelOptions(order, orbpSalesChannelConfigurations);

	return isAllowedBySource && isAllowedBySalesChannelOption;
}
