/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface FeatureConfigurationDto { 
    feature: FeatureConfigurationDto.FeatureEnum;
    /**
     * Flag indicating if the feature may be modified in restaurant.
     */
    modifiable: boolean;
    /**
     * Flag indicating if the feature is already enabled even without activation in the restaurant (if possible).
     */
    enabled: boolean;
}
export namespace FeatureConfigurationDto {
    export type FeatureEnum = 'DEFERRED_PAYMENT' | 'FIDELITY_PROGRAM' | 'GR_CARD_PAYMENT' | 'ICOUPON_PAYMENT' | 'KIOSK_MULTIPAYMENT' | 'ORB_SUBSCRIPTION' | 'WAIT_AT_THE_TABLE' | 'LOCAL_PRICE_CHANGE' | 'LOCAL_PRICE_CHANGE_DELIVERY' | 'TABLE_SERVICE' | 'TABLE_SERVICE_TERRACE' | 'KIOSK_EAT_IN' | 'WALK_BY_WINDOW';
    export const FeatureEnum = {
        DeferredPayment: 'DEFERRED_PAYMENT' as FeatureEnum,
        FidelityProgram: 'FIDELITY_PROGRAM' as FeatureEnum,
        GrCardPayment: 'GR_CARD_PAYMENT' as FeatureEnum,
        IcouponPayment: 'ICOUPON_PAYMENT' as FeatureEnum,
        KioskMultipayment: 'KIOSK_MULTIPAYMENT' as FeatureEnum,
        OrbSubscription: 'ORB_SUBSCRIPTION' as FeatureEnum,
        WaitAtTheTable: 'WAIT_AT_THE_TABLE' as FeatureEnum,
        LocalPriceChange: 'LOCAL_PRICE_CHANGE' as FeatureEnum,
        LocalPriceChangeDelivery: 'LOCAL_PRICE_CHANGE_DELIVERY' as FeatureEnum,
        TableService: 'TABLE_SERVICE' as FeatureEnum,
        TableServiceTerrace: 'TABLE_SERVICE_TERRACE' as FeatureEnum,
        KioskEatIn: 'KIOSK_EAT_IN' as FeatureEnum,
        WalkByWindow: 'WALK_BY_WINDOW' as FeatureEnum
    };
}


