import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'dk-data-table',
	templateUrl: './dk-data-table.component.html',
	styleUrls: ['./dk-data-table.component.scss'],
})
export class DkDataTableComponent implements OnInit {
	@Input() dataSource = [];
	@Input() footer = {};
	@Input() showHideButton = false;

	constructor() {
		console.log(this.dataSource);
	}

	cols() {
		return Object.keys(this.dataSource[0]);
	}

	ngOnInit(): void {
		console.log('init');
	}
}
