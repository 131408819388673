import { getTruthyEnumMapKeys } from "@merim/utils";
import { SalesChannel } from "../../enums";
import { OrbpDetail, SalesChannelConfiguration } from "../../types";
import { isUsingDeviceSpecificConfiguration } from "../sales-channels";

export function getAssignedDevicesToOrbp(salesChannel: SalesChannel, orbpDetail: OrbpDetail): string[] {
	const matchingScConfig: SalesChannelConfiguration = orbpDetail.salesChannelConfigurations.find(x => x.salesChannel === salesChannel);

	if (isUsingDeviceSpecificConfiguration(matchingScConfig) === false) {
		return [];
	} else {
		return getTruthyEnumMapKeys(matchingScConfig.deviceSpecificConfiguration || {});
	}
}
