import { IntervalGroupName } from '../enums';

export type PmsConfiguration = {
	peakIntensityStep: number;
	intervalFilterDefinition: IntervalFilterDefinition[];

	/**
	 * Prediction interval in miliseconds
	 */
	intervalMs: number;
	/**
	 * Hours ofset in hh:mm format
	 */
	bussinessDayStartTime: string;
	/**
	 * 0 means Monday,
	 * 1 means Tuesday ...
	 */
	weekStartDay: number;
};

export type IntervalFilterDefinition = {
	name: IntervalGroupName;
	from: number;
	to: number;
};
