/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SelectionPatternDto { 
    /**
     * Specifies target on which the selection pattern is applied.    Values:    NONE = 0,    PRODUCT = 1,    MENU = 2,    INGREDIENT = 3,    KIOSK_NAVIGATION_MENU = 4,    POS_NAVIGATION_MENU = 5,    RESTAURANT = 6,    DISCOUNT = 7,    ANNOTATION = 8,    GAME = 9,    COUPON = 10.
     */
    target: number;
    /**
     * Id of a specific product family to filter by.
     */
    family: number;
    /**
     * Specifies how the selected \"family\" is propagated in the selection pattern.    Values:    PARENTS_ONLY = -2,    FAMILY_AND_PARENTS = -1,    FAMILY_ONLY = 0,    FAMILY_AND_CHILDREN = 1,    CHILDREN_ONLY = 2.
     */
    familyPropagation: number;
    /**
     * Label describing the purpose of the pattern.
     */
    label: string;
    /**
     * Flag indicating if all tags set in \"withTags\" property must be present in the item for it to be selected.
     */
    withAllTags: boolean;
    /**
     * Tags that an item must contain to be included. Either one of them or all of them based on \"withAllTags\" property.
     */
    withTags: Array<number>;
    /**
     * Flag indicating if all tags set in \"withoutTags\" property must not be present in the item for it to be selected.
     */
    withoutAllTags: boolean;
    /**
     * Tags that an item must not contain to be included. Either one of them or all of them based on \"withoutAllTags\" property.
     */
    withoutTags: Array<number>;
    /**
     * Ids of the selected items.
     */
    selected: Array<number>;
    /**
     * Ids of the excluded items.
     */
    excluded: Array<number>;
    /**
     * Flag that completely reverses the logic of a selection pattern. Performs exclusion instead of inclusion.
     */
    excludeFilter: boolean;
}

