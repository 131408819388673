/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PermissionOptionDto { 
    /**
     * Name of the permission.
     */
    name: PermissionOptionDto.NameEnum;
    /**
     * All possible options for the permission.
     */
    levels: Array<PermissionOptionDto.LevelsEnum>;
}
export namespace PermissionOptionDto {
    export type NameEnum = 'reporting' | 'price-management' | 'dss-management' | 'pms-management';
    export const NameEnum = {
        Reporting: 'reporting' as NameEnum,
        PriceManagement: 'price-management' as NameEnum,
        DssManagement: 'dss-management' as NameEnum,
        PmsManagement: 'pms-management' as NameEnum
    };
    export type LevelsEnum = 'read-only' | 'read-write';
    export const LevelsEnum = {
        Only: 'read-only' as LevelsEnum,
        Write: 'read-write' as LevelsEnum
    };
}


