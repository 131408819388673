/**
 * Difference between two Dates in minutes
 * @param dateA
 * @param dateB
 */
export function dateDifferenceInMinutes(dateA: Date, dateB: Date): number {
	const diff = dateA.getTime() - dateB.getTime();
	const minutes = Math.floor(diff / 1000 / 60);
	return minutes;
}

/**
 * Gets previous monday
 * @param d
 */
export function getMonday(d: Date): Date {
	d = new Date(d);
	const day = d.getDay();
	const diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
	return new Date(d.setDate(diff));
}
