import { Injectable } from '@angular/core';
import { BRAND_NAME_KEY } from '@libs/rap/models';
import { IDefaultConfig } from '@libs/shared/interfaces';
import { ConfigurationService, FidelityAbstractService } from '@libs/shared/services';
import { BrandNameNew } from '@merim/utils';

import { FidelitySplioService } from './fidelity-splio.service';

@Injectable({
	providedIn: 'root',
})
export class FidelityFactory<T extends IDefaultConfig> {
	constructor(private configurationService: ConfigurationService<T>) {}

	createService(): FidelityAbstractService<T> {
		const config = this.configurationService.getValue(BRAND_NAME_KEY) as BrandNameNew;
		switch (config) {
			case BrandNameNew.Mqsr:
			case BrandNameNew.BeChef_France:
			case BrandNameNew.Quick:
				return new FidelitySplioService();
			default:
				console.error('[Error] Could not initialize fidelity program...');
				return undefined;
		}
	}
}
