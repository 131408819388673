@if (tableDataSource && tableDataSource.length > 0) {
	<table [class]="tableConfig.cssClass">
		<thead>
		<tr>
			@for (headerCellLabel of composeTableHeader(); track headerCellLabel) {
				<th>
					{{ translateRowLabel(headerCellLabel) }}
				</th>
			}
		</tr>
		</thead>
		<tbody>
			@for (row of composeTableBody(); track row) {
				@if (tableConfig.rowClickAction === 'viewDetail') {
					<!-- [routerLink]="['/' + currentLanguage + '/detail']"
					[queryParams]="{
						'data-source-name': tableConfig.dataSourceName,
						'item-name': row[0],
						'time-range': 'today'
					}" -->
					<tr
						class="dashking-clickable-table-row"
					>
						@for (cell of row; track cell) {
							<td>
								{{ translateRowLabel(cell) }}
							</td>
						}
						<!-- <td>></td> -->
					</tr>
				} @else {
					<tr>
						@for (cell of row; track cell) {
							<td>
								{{ translateRowLabel(cell) | stringToNumber }}
							</td>
						}
					</tr>
				}
			}
		</tbody>
	</table>
}
