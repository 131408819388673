/**
 * DKI-BE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DkiBeClickhouseAnalyticsProductReportOrdersByChannel } from './dki-be-clickhouse-analytics-product-report-orders-by-channel';
import { OrdersByProduct } from './orders-by-product';


export interface ProductReport {
	by_family: { [key: string]: OrdersByProduct; };
	total: DkiBeClickhouseAnalyticsProductReportOrdersByChannel;
}

