/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ProfilerStatusDto { 
    /**
     * Current state of the profiler.
     */
    status: ProfilerStatusDto.StatusEnum;
}
export namespace ProfilerStatusDto {
    export type StatusEnum = 'started' | 'stopped' | 'not allowed';
    export const StatusEnum = {
        Started: 'started' as StatusEnum,
        Stopped: 'stopped' as StatusEnum,
        NotAllowed: 'not allowed' as StatusEnum
    };
}


