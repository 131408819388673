import { LoadDistribution } from '../../enums/load-distribution';
import { OrbStatus } from '../../enums/orb-status';
import { OrbpRole } from '../../enums/orbp-role';
import { LoadBalancingErrorCode } from '../../models/load-balancing-error-code';
import { OrbpDetail } from '../../types/orbp-detail';

/**
 * Validator for LoadDistribution.ROUND_ROBIN.
 *
 * Expected usage: It is recommended to not run it directly, but via the LoadBalancingValidator.
 */
export function hasValidDynamicDistributionRoundRobin(orbpDevice: OrbpDetail, masterOrbpDevices: OrbpDetail[]): LoadBalancingErrorCode | undefined {
	// LoadBalancing is applicable only for Masters
	if (orbpDevice.role !== OrbpRole.Master) {
		return undefined;
	}

	// LoadBalancing does not make sense on inactive ORBp
	if (orbpDevice.status !== OrbStatus.On) {
		return undefined;
	}

	// We do not care about other types at the moment
	if (orbpDevice.loadDistribution !== LoadDistribution.ROUND_ROBIN) {
		return undefined;
	}

	const otherActiveMasters = masterOrbpDevices.filter((orbp) => orbp.status === OrbStatus.On && orbp.role === OrbpRole.Master && orbp.id !== orbpDevice.id);

	const isSingle = otherActiveMasters.filter((orbp) => orbp.loadDistribution === LoadDistribution.ROUND_ROBIN).length === 0;

	// This is invalid. There has to be at least 2 ORBps with ROUND_ROBIN, otherwise it does not make sense
	// and it confuses Mirror of Dependent following RR Master
	if (isSingle) {
		return LoadBalancingErrorCode.RoundRobinNotSingle;
	}

	return undefined;
}
