export class LoadBalancingDistributionModel {
	orbpId: string;
	receivedOrders: Record<string, RoundRobinDistributionAllOrders>;
	oldestOrderBumpTime: number;

	constructor(init?: Partial<LoadBalancingDistributionModel>) {
		Object.assign(this, init);
	}
}

export class RoundRobinDistributionAllOrders {
	uuid: string;
	printed: boolean;
	numberOfProducts: number;
	lastUpdatedOn: number;
	isParked = false;

	constructor(init?: Partial<RoundRobinDistributionAllOrders>) {
		Object.assign(this, init);
	}
}
