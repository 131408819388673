export * from './accounting-report-api-client.service';
import { AccountingReportApiClient } from './accounting-report-api-client.service';
import { AuthApiClient } from './auth-api-client.service';
import { DataApiClient } from './data-api-client.service';
import { ExportApiClient } from './export-api-client.service';
import { ForecastingApiClient } from './forecasting-api-client.service';
import { GAPApiClient } from './gap-api-client.service';
import { LegacyApiClient } from './legacy-api-client.service';
import { MonitoringApiClient } from './monitoring-api-client.service';
import { MyApiClient } from './my-api-client.service';
import { OwnersApiClient } from './owners-api-client.service';
import { PaymentMethodsApiClient } from './payment-methods-api-client.service';
import { PricesApiClient } from './prices-api-client.service';
import { ReportsApiClient } from './reports-api-client.service';
import { RestaurantsApiClient } from './restaurants-api-client.service';
import { UsersApiClient } from './users-api-client.service';

export * from './auth-api-client.service';

export * from './data-api-client.service';

export * from './export-api-client.service';

export * from './forecasting-api-client.service';

export * from './gap-api-client.service';

export * from './legacy-api-client.service';

export * from './monitoring-api-client.service';

export * from './my-api-client.service';

export * from './owners-api-client.service';

export * from './payment-methods-api-client.service';

export * from './prices-api-client.service';

export * from './reports-api-client.service';

export * from './restaurants-api-client.service';

export * from './users-api-client.service';

export const APIS = [AccountingReportApiClient, AuthApiClient, DataApiClient, ExportApiClient, ForecastingApiClient, GAPApiClient, LegacyApiClient, MonitoringApiClient, MyApiClient, OwnersApiClient, PaymentMethodsApiClient, PricesApiClient, ReportsApiClient, RestaurantsApiClient, UsersApiClient];
