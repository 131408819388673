/**
 * Return a letter that will be incremented on each next()
 * When 'z' is reached, next will be 'aa' and etc...
 * First next() will return 'a'
 */
export class IncrementLetterGenerator {
	private _chars: string;
	private _nextId: number[];

	constructor(chars = 'abcdefghijklmnopqrstuvwxyz') {
		this._chars = chars;
		this._nextId = [0];
	}

	/**
	 * Get the next incremented letter
	 * First call whill return 'a'
	 * @returns string containing the incremented letter
	 */
	next(): string {
		const r = [];
		for (const char of this._nextId) {
			r.unshift(this._chars[char]);
		}
		this._increment();
		return r.join('');
	}

	_increment() {
		for (let i = 0; i < this._nextId.length; i++) {
			const val = ++this._nextId[i];
			if (val >= this._chars.length) {
				this._nextId[i] = 0;
			} else {
				return;
			}
		}
		this._nextId.push(0);
	}

	* [Symbol.iterator]() {
		while (true) {
			yield this.next();
		}
	}
}
