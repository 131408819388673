/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CampaignDaypartPriceDto { 
    /**
     * Id of the item to override.
     */
    id: number;
    /**
     * Override price to apply for item of regular size.
     */
    price?: number;
    /**
     * Override price to apply for item of large size.
     */
    priceL?: number;
}

