import { BKOrderNewReglementUserMessageStatus, BKOrderPayLineResultEnum } from '@bk/fullstack-common';
import { ISalesServerModeReglement } from '@bk/jscommondatas';

export interface IHttpPaymentInterfaceResponse {
	code: number;
	response: IPaymentInterface[];
}

export interface IPaymentInterface extends ISalesServerModeReglement {
	[key: string]: string | boolean | number | ISalesServerModeReglement;
}

export interface INewReglementResultResponse {
	cbToken: string;
	difference: number;
	differenceType: string;
	orb: number;
	paymentLineUuid: string;
	srcTicket: string;
	status: BKOrderPayLineResultEnum;
	ticketNumber: number;
	commandeNumber: number;
}

export interface INewReglementUserMessageResponse {
	status: BKOrderNewReglementUserMessageStatus;
	content: any;
}

export interface IClerkPrintWithoutDetail {
	originName: string;
	numTicket: number;
	nomManager: string;
	codeManager: number;
	codeVendeur: number;
	nomVendeur: string;
	orderUuid: string;
	pay: any;
}

export interface IClerkPrint {
	numTicket: number;
	originName: string;
	operateur: string;
	printerHost: string;
	orderUuid: string;
}
