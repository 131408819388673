import { FeatureFlagFile } from '@merim/utils';

/**
 * All devices are expected to have feature-flag file in the same location
 * ! But that is not yet implemented, they do not have it there.
 */
export const globalFeatureFlagPath = '//localhost/feature-flags/feature-flags.json';

/**
 * Access the global feature flag file
 */
export async function loadFeatureFlags(url = globalFeatureFlagPath): Promise<FeatureFlagFile | null> {
	try {
		const response = await fetch(url);
		if (response.ok) {
			const jsonData = (await response.json()) as FeatureFlagFile;
			return jsonData;
		} else {
			console.error('loadFeatureFlags() failed: ', response.status);
			return null;
		}
	} catch (err) {
		console.error('loadFeatureFlags() failed: ', err);
		return null;
	}
}
