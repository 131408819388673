/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ItemPriceDto } from './item-price-dto';
import { SelectionPatternDto } from './selection-pattern-dto';


export interface GameDto { 
    /**
     * Id of the game.
     */
    id: number;
    /**
     * Dictionary (associative array) of names where key is the language.
     */
    _name?: { [key: string]: string; };
    /**
     * Description of the game.
     */
    _descriptionTemp?: object;
    /**
     * Algorithm to be used for the game.   Values:   NONE = 0,   RANDOM_PRODUCT = 1,   GIFT_WITH_LARGE_MENU = 2,   RANDOM_DISCOUNT = 3,   ERROR_IN_FAVOR = 4
     */
    algo: number;
    /**
     * Sorted product arguments.
     */
    _productArgs?: Array<number>;
    /**
     * Sorted discount arguments.
     */
    _discountArgs?: Array<number>;
    /**
     * Sorted menu arguments.
     */
    _integer1Args?: Array<number>;
    /**
     * Flag indicating if the game is associated with a campaign.
     */
    _campaignGame?: boolean;
    /**
     * Price for the regular (M) size.
     */
    _priceM?: ItemPriceDto;
    /**
     * Flag indicating if the game can be combined with product discount.
     */
    _canCombineWithProductDiscount?: boolean;
    /**
     * Flag indicating if the game can be combined with menu discount.
     */
    _canCombineWithMenuDiscount?: boolean;
    /**
     * Flag indicating if the game can be combined with order discount.
     */
    _canCombineWithOrderDiscount?: boolean;
    /**
     * Flag indicating if the game can be combined with other games.
     */
    _canCombineWithOtherGame?: boolean;
    /**
     * How many times the same game may be used in one order. 0 means no limit.
     */
    _maxSameGameItem?: number;
    /**
     * Patterns of the items the game applies to.
     */
    _gameApplicableToPattern?: Array<SelectionPatternDto>;
    /**
     * Ids of tags associated with the game.
     */
    _xtag?: Array<number>;
    /**
     * Additional arguments as URL encoded string.
     */
    stringArgs?: string;
    /**
     * Sorted media arguments.
     */
    mediaArgs?: Array<number>;
    /**
     * If the game has main image.
     */
    _hasImageMain?: boolean;
    /**
     * If the game has system image (on POS/DRIVECO/DRIVEPAY button).
     */
    _hasImageSys?: boolean;
    /**
     * If the game has additional kiosk image (in the KIOSK info popup).
     */
    _hasKioskImage2?: boolean;
}

