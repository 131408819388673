/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FoodCategoryDto } from './food-category-dto';
import { ConfigurationProductFamilyDto } from './configuration-product-family-dto';


export interface ProductListDto { 
    /**
     * Configuration of food categories in relation to product families utilized by DSS, ORBp and PRINT-SERVER.
     */
    foodCategories: Array<FoodCategoryDto>;
    /**
     * Ordered list of product families utilized by ORBp.
     */
    productFamilies: Array<ConfigurationProductFamilyDto>;
}

