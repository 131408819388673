import { Injectable } from '@angular/core';
import { IPaymentInterface } from '@libs/shared/interfaces';
import { ClerkService } from '@libs/shared/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ClerkPaymentLoad, ClerkPaymentLoadSuccess } from './actions';

@Injectable()
export class AppEffects {
	constructor(
		private actions$: Actions,
		private readonly clerkService: ClerkService
	) {}

	getClerkPaymentMethods$: Observable<Action> = createEffect(() =>
		this.actions$.pipe(
			ofType(ClerkPaymentLoad),
			switchMap(() => {
				return this.clerkService.getPaymentMethods().pipe(
					map((data: IPaymentInterface[]) => ClerkPaymentLoadSuccess({ data })),
					catchError(() => {
						console.warn('[Clerk]: Unable to load clerk payment information');
						return of(ClerkPaymentLoadSuccess({ data: [] }));
					})
				);
			})
		)
	);
}
