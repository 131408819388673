/**
 * DKI-BE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PermissionInfoApplyDefine } from './permission-info-apply-define';
import { PermissionInfoLegacyAccess } from './permission-info-legacy-access';
import { PermissionInfoReadWrite } from './permission-info-read-write';


export interface UpdateUserInfo {
	email?: string;
	password?: string;
	send_invitation_link?: boolean;
	first_name?: string;
	last_name?: string;
	is_admin?: boolean;
	has_all_restaurants?: boolean;
	phone?: string;
	landline?: string;
	permissions?: Array<PermissionInfoReadWrite | PermissionInfoLegacyAccess | PermissionInfoApplyDefine>;
	restaurant_ids?: Array<string>;
}

