export interface PasswordStrengthInferface {
	score: number;
	password: string;
	calc_time: number;
	guesses: number;
	guesses_log10: number;
	feedback: PasswordStrengthFeedbackInferface
	crack_times_display: PasswordStrengthCrackTimesDisplayInferface;
	crack_times_seconds: PasswordStrengthCrackTimesSecondsInferface;
	sequence: PasswordStrengthSequenceInterface;
}

export interface PasswordStrengthFeedbackInferface {
	warning: string;
	suggestions: string[];
}

export interface PasswordStrengthCrackTimesDisplayInferface {
	offline_fast_hashing_1e10_per_second: string;
	offline_slow_hashing_1e4_per_second: string;
	online_no_throttling_10_per_second: string;
	online_throttling_100_per_hour: string;
}

export interface PasswordStrengthCrackTimesSecondsInferface {
	offline_fast_hashing_1e10_per_second: number;
	offline_slow_hashing_1e4_per_second: number;
	online_no_throttling_10_per_second: number;
	online_throttling_100_per_hour: number;
}

export interface PasswordStrengthSequenceInterface {
	guesses: number;
	guesses_log10: number;
	i: number;
	j: number;
	pattern: string;
	token: string;
}
