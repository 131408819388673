/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AnnotationDto { 
    /**
     * Id of the annotation.
     */
    id: number;
    /**
     * System name of the annotation.
     */
    _name?: string;
    /**
     * Description of the annotation.
     */
    _description?: string;
    /**
     * Annotation\'s label to be displayed and printed.
     */
    label: string;
    /**
     * Ids of tags associated with the annotation.
     */
    _xtag?: Array<number>;
    /**
     * Whether the annotation\'s label should be printed on fiscal ticket.
     */
    _printInTicket?: boolean;
    /**
     * Whether the annotation\'s label should be printed on KDS printer.
     */
    _printInKitchen?: boolean;
    /**
     * Whether the annotation\'s label should be printed on ORBp printer.
     */
    _printInORB?: boolean;
    /**
     * Type of annotation.         Values:         TEXT = 0,         SYSTEM_SAME_CAR = 1,         SYSTEM_EMPTY_CAR = 2
     */
    annotationType?: number;
}

