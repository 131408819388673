/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Allergen { 
    name: object;
    description: object;
    /**
     * Flag indicating if the allergen will be included in BigData after publication.
     */
    active?: boolean;
    image?: string;
    id: number;
}

