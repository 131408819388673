/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PredictionKitchenDetailDto } from './prediction-kitchen-detail-dto';


export interface PredictionKitchenDto { 
    /**
     * Date of the prediction.
     */
    date: string;
    /**
     * Detailed prediction by set interval.
     */
    detail: Array<PredictionKitchenDetailDto>;
}

