/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface BoGlobalManagerFeatures { 
    new_price_management?: boolean;
    /**
     * Id of annotation that is utilized as a **NO CUTLERY** flag for the order.
     */
    no_cutlery_annotation_id?: number;
}

