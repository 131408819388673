export enum BrandName {
	BurgerKing_France = 'bk_fr',
	BurgerKing_Reunion = 'bk_re',

	// TODO DEV-2570 - Add _france suffix as well.
	HollysDiner = 'hd',
	Quick = 'quick',
	Cora_France = 'co_fr',
	PokeMama_France = 'pm_fr',
	CapMerchant_Reunion = 'cm_re',
	BChef_France = 'bc_fr',

	Default = 'Default',

	/**
	 * Merim QSR
	 */
	Mqsr = 'mqsr',
	/**
	 * Fallback for whitelabelled apps.
	 */
	Unknown = 'unknown',
}

/**
 * DO NOT USE THIS GENERIC FORM IN CONFIG FILES!
 */
export const BurgerKing_Generic = 'bk';

/**
 * DO NOT USE THIS GENERIC FORM IN CONFIG FILES!
 */
// In future we will have other generic variants here.
export type BrandName_Generic_UNSAFE = typeof BurgerKing_Generic;

export type NormalizedBrandName = BrandName | BrandName_Generic_UNSAFE;
