/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface VatRateDto { 
    /**
     * Name of the VAT rate.
     */
    name: string;
    /**
     * VAT rate.
     */
    percent: number;
    /**
     * Flag indicating if the VAT rate is supposed to be utilized as default.
     */
    isDefault: boolean;
}

