import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ErrorAlertService {
	public error = new BehaviorSubject<Error | HttpErrorResponse | null>(null);

	setErrorAlert(error: Error | HttpErrorResponse | null): void {
		this.error.next(error);
	}

	clearErrorAlert(): void {
		this.error.next(null);
	}
}
