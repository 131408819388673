/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OpeningTimes } from './opening-times';
import { RestaurantFeature } from './restaurant-feature';
import { WeborderChannel } from './weborder-channel';
import { VatRate } from './vat-rate';
import { PaymentMethodExport } from './payment-method-export';


/**
 * Information about restaurant returned from REBOOT-API api/restaurant/{{restaurant_id}} endpoint.
 */
export interface RebootApiRestaurant { 
    id: string;
    name: string;
    bknb?: string;
    frnb?: string;
    /**
     * Features enabled in the restaurant.
     */
    features?: Array<RestaurantFeature>;
    /**
     * VAT rates applied in the restaurant.
     */
    vatRates?: Array<VatRate>;
    /**
     * Weborder channels enabled in the restaurant.
     */
    weborderChannels?: Array<WeborderChannel>;
    address: string;
    city: string;
    country: string;
    addressExtra?: string;
    zipCode?: number;
    openingTimes?: OpeningTimes;
    /**
     * List of enabled payment methods.
     */
    paymentMethods?: Array<PaymentMethodExport>;
}

