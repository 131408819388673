/**
 * Extracts ID (number) from the back of string formatted ID
 * Loops through string ID characters from the back and returns the appended number.
 * (e.g. OSMDM-ORB1 -> 1; OSMDM-ORB15 -> 15; OSMDM15-ORB -> NaN)
 */
export function GetIdxFromStringId(stringId: string): number {
	let startingIndex: number = stringId.length;
	for (let i = stringId.length - 1; i >= 0; i--) {
		// Get last character and parse it to integer
		const int: number = parseInt(stringId.charAt(i));
		// Stop searching if the character is a letter
		if (isNaN(int)) {
			break;
		}
		// Move starting index
		startingIndex--;
	}
	return parseInt(stringId.substring(startingIndex));
}
