<table class="dk-data-table">
	<thead>
	<tr>
		@for (col of cols(); track col) {
			<th>{{ col }}</th>
		}
	</tr>
	</thead>
	<tbody>
		@for (entry of dataSource; track entry) {
			<tr>
				@for (col of cols(); track col) {
					<td>{{ entry[col] }}</td>
				}
			</tr>
		}
	</tbody>
	<tbody></tbody>
	<tfoot>
	<tr class="highlight">
		@for (col of cols(); track col) {
			<td>{{ footer[col] }}</td>
		}
	</tr>
	</tfoot>
</table>
<div class="actions">
	@if (showHideButton) {
		<button>hide null values</button>
	}
	<button class="export">export tableau</button>
</div>
