/**
 * DKI-BE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Account } from './account';
import { Amounts } from './amounts';
import { RemoteUser } from './remote-user';
import { SafeOperation } from './safe-operation';


export interface SafeTransaction {
	version: number;
	id: string;
	restaurant_id: string;
	validation_time: string;
	effective_time: string;
	businessday: string;
	employee: RemoteUser;
	manager: RemoteUser;
	operation: SafeOperation;
	source: Account;
	destination: Account;
	amounts: Amounts;
	comment: string;
	reference?: string;
}

