/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OpeningTimes } from './opening-times';
import { ServiceTimeThresholds } from './service-time-thresholds';


export interface UpdateRestaurantSettings { 
    opening_times?: OpeningTimes;
    line_thresholds?: Array<number>;
    service_time_thresholds?: ServiceTimeThresholds;
}

