export type ConfigurationUrlsType = {
	[value in URL_TYPE_ENUM]?: string;
};

// Direct URL can be used to manually specify data endpoint, if it varies from the local server
// or if its not specified in URL
export enum URL_TYPE_ENUM {
	DIRECT_URL = 'directUrl',
	BO_URL = 'boUrl',
	BE_URL = 'beUrl',
	RAP_BE_URL = 'rapBeUrl',
	CLERK_MASTER_URL = 'clerkMasterUrl',
	CLERK_MASTER_BO_URL = 'clerkMasterBoUrl',
	CLERK_URL = 'clerkUrl',
	CLERK_BO_URL = 'clerkBoUrl',
	LOCAL_MANAGER_URL = 'localManagerUrl',
	DEVICE_URL = 'deviceUrl',
}
