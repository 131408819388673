import { AfterViewInit, Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import * as api from '@dki/api-client';
import { inOutAnimation } from '../inoutanimation/in-out-animation';

@Component({
	selector: 'data-table-cumul',
	templateUrl: './data-table-cumul.component.html',
	styleUrls: ['./data-table-cumul.component.scss'],
	animations: [inOutAnimation],
})
export class DataTableCumulComponent implements AfterViewInit {
	@ViewChild('secondLabel') secondlabel: ElementRef<HTMLElement>;
	@ViewChild('cell') cell: ElementRef<HTMLElement>;
	@Input() restaurants: api.Restaurant[];
	@Input() data: any;

	row = false;

	repeatTimes: any[] = new Array(4);

	labels = {
		morning: 'Matinée',
		launch: 'Déjeuner',
		afternoon: 'Après-midi',
		dinner: 'Dîner',
		evening: 'Soir',
		night: 'Nuit',
		peak_night: 'Nuit de pointe',
	};

	ngAfterViewInit(): void {
		this.positionLabel();
	}

	@HostListener('window:resize', ['$event'])
	onResize() {
		this.positionLabel();
	}

	positionLabel() {
		const referenceRect = this.cell.nativeElement.getBoundingClientRect();
		const targetStyle = this.secondlabel.nativeElement.style;
		targetStyle.position = 'absolute';
		targetStyle.marginLeft = '-6ch';
		targetStyle.left = `${referenceRect.left}px`;
		targetStyle.height = `${referenceRect.height}px`;
	}

	toggleRow() {
		this.row = !this.row;
	}

	get isOpen() {
		return this.row;
	}

	restaurantName(id) {
		return this.restaurants.filter((res) => res.id === id)[0]?.name || id;
	}

	formatHoraire(value) {
		let _start = value.start.toString().length === 1 ? '0' + value.start.toString() : value.start.toString();
		let _end = value.end.toString().length === 1 ? '0' + value.end.toString() : value.end.toString();
		return `${_start}h à ${_end}h`;
	}

	originalOrder = (a, b): number => {
		return 0;
	};

	obj(v: number, s: string) {
		if (!v) return;
		if (s === 'total') {
			return v > 120;
		} else {
			return v > 60;
		}
	}

	totals(v) {
		if (v === 'count') {
			let t = 0;
			Object.values(this.data).forEach((a) => {
				t += a['totals'].count;
			});
			return t;
		} else {
			let t = 0;
			Object.values(this.data).forEach((a) => {
				t += a['totals'][v] || 0;
			});
			return t;
		}
	}
}
