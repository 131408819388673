import {
	BKDeliveryModeEnum,
	BKOrderEventType,
	BKOrderEventUtilities,
	BKOrderSourceEnum,
	BKPickUpTypeEnum,
	BKTableAllocationLocationSpace,
	IBKOrderEventData,
	IBKPublishedOrderData,
	IBKTableAllocationAssignment,
} from '@bk/jscommondatas';
import { SalesChannelOption } from '@bk/price-management-common';
import { EnumKeyValuePair, EnumMap, getTruthyEnumMapKeys, isEmptyObject, sortByExampleArray } from '@merim/utils';

import { SalesChannelConfiguration } from '../types/sales-channel-configuration';
import { getOrderSandboxName } from './get-order-sandbox-name';
import { getOrderSourceDeviceName } from './order';
import { ConfigurationOfSalesChannelOptionsDependency, mapOrderSourceToSalesChannel } from './sales-channels';

type SalesChannelOptionAllowance = {
	salesChannelOption: SalesChannelOption;
	isAllowed: boolean;
}

/**
 * Order can be displayed on ORBp if at least one SalesChannelOption for that SalesChannel is allowed.
 * Some SalesChannelOptions have special treatment due to their dependency on other Options.
 */
export function canDisplayOrderBySalesChannelOptions(order: IBKPublishedOrderData, configurations: SalesChannelConfiguration[]): boolean {
	const orderSource: BKOrderSourceEnum = order.source;
	const salesChannel = mapOrderSourceToSalesChannel(orderSource);
	const matchingConfiguration = configurations.find((c) => c.salesChannel === salesChannel);
	const hasDeviceSpecificConfiguration = !!matchingConfiguration?.deviceSpecificConfiguration && isEmptyObject(matchingConfiguration.configuration) === false;

	// No salesChannelOptions are allowed
	if (!matchingConfiguration
		|| isEmptyObject(matchingConfiguration.configuration)
		|| getTruthyEnumMapKeys(matchingConfiguration.configuration).length === 0) {
		return false;
	}

	if (hasDeviceSpecificConfiguration) {
		const allowedSourceDevices: string[] = getTruthyEnumMapKeys(matchingConfiguration.deviceSpecificConfiguration);
		const sourceDevice = getOrderSourceDeviceName(order);
		const isSourceDeviceAllowed = allowedSourceDevices.includes(sourceDevice);

		if (!isSourceDeviceAllowed) {
			return false;
		}
	}

	const allPossiblyAllowedScOptions: SalesChannelOptionAllowance[] = enumMapToKeyValuePairsSorted(matchingConfiguration.configuration).map((configPair) => {
		const salesChannelOption = configPair.key as SalesChannelOption;
		const isAllowed = configPair.value;

		switch (salesChannelOption) {
			case SalesChannelOption.TableServiceInside:
			case SalesChannelOption.TableServiceTerrasse:
				return {
					isAllowed: isAllowed && doesTableAllocationMatch(order, salesChannelOption),
					salesChannelOption
				};

			case SalesChannelOption.EatIn:
				return {
					isAllowed: isAllowed && doesDeliveryModeMatch(order, salesChannelOption),
					salesChannelOption
				};

			case SalesChannelOption.TakeAway:
				// Pickup and Drive orders are considered as takeaways, but they must not be mistaken for that
				// pickup & drive have its own categories, thus it is important to check that they are not in TakeAway in the sales channel options

				return {
					isAllowed: isAllowed && doesDeliveryModeMatch(order, salesChannelOption) &&
						!isDriveWindowOrder(order) &&
						!isPickupParkingOrder(order),
					salesChannelOption
				};

			case SalesChannelOption.Deliveroo:
			case SalesChannelOption.UberEats:
			case SalesChannelOption.JustEat:
			case SalesChannelOption.DeliveryBK:
				// This is form of TakeAway, but Dependency was not yet defined.
				return {
					isAllowed: isAllowed && hasMatchingDeliveryCompany(order, salesChannelOption),
					salesChannelOption
				};

			case SalesChannelOption.DriveIn:
				return {
					isAllowed: isAllowed && isDriveWindowOrder(order),
					salesChannelOption
				};

			case SalesChannelOption.PickupParking:
				// This is form of TakeAway, but Dependency was not yet defined.
				return {
					isAllowed: isAllowed && isPickupParkingOrder(order),
					salesChannelOption
				};

			case SalesChannelOption.WaitingAtTable:
				return {
					isAllowed: isAllowed && hasSomeItemsDeliveredLater(order),
					salesChannelOption
				};

			default:
				// No match? We rather show the Order, otherwise it might not get displayed at all
				console.warn(`Unexpected salesChannelOption ${salesChannelOption}. Order will be displayed just in case.`);
				return {
					isAllowed: true,
					salesChannelOption
				};
		}
	});

	// If the Order is for TableService or similar SalesChannelOption,
	// then we need to check also the "Master SalesChannelOptions".
	const checkDependentOptions = doesOrderHaveDependentSalesChannelOptions(order);
	const isEatInToBeExamined = !checkDependentOptions && order.deliveryMode === BKDeliveryModeEnum.DELIVERY_LOCAL && getTableAllocation(order);

	if (checkDependentOptions || isEatInToBeExamined) {
		// Both "Master" and "Dependent" SalesChannelOptions must be allowed, so that Order can be displayed on that ORBp.
		const isDependentOptionAllowed = ConfigurationOfSalesChannelOptionsDependency.some(dependency => {
			const allowance = allPossiblyAllowedScOptions.find(x => x.salesChannelOption === dependency.optionName);
			const isMasterDependencyAllowed = allPossiblyAllowedScOptions.find(x => x.salesChannelOption === dependency.dependsOn)?.isAllowed;

			const isAllowed = allowance?.isAllowed || false;
			return isMasterDependencyAllowed && isAllowed;
		});

		return isDependentOptionAllowed;
	} else {
		// Order does not depend on any special option.
		// So it may be displayed if any SalesChannelOption is allowed
		const canBeDisplayed = allPossiblyAllowedScOptions.some(x => x.isAllowed);
		return canBeDisplayed;
	}
}


function doesOrderHaveDependentSalesChannelOptions(order: IBKPublishedOrderData): any {
	const dependentOptions = ConfigurationOfSalesChannelOptionsDependency.map(x => x.optionName);
	const option = dependentOptions.find((option: SalesChannelOption) => {
		switch (option) {
			case SalesChannelOption.TableServiceInside:
			case SalesChannelOption.TableServiceTerrasse: {
				const hasTableAllocation = !!getTableAllocation(order);
				return hasTableAllocation;
			}
			case SalesChannelOption.WaitingAtTable: {
				const hasWaitingOnTable = hasSomeItemsDeliveredLater(order);
				const tableAllocationPresent = !!getTableAllocation(order);

				// fix of orders not being displayed: DEV-3722
				// if we have table allocation service and we have table allocation, we need to check for later delivery
				// otherwise we dont need to check later delivery

				return tableAllocationPresent ? hasWaitingOnTable : false;
			}
			default:
				return false;
		}
	});

	return !!option;
}

function enumMapToKeyValuePairsSorted(enumMap: EnumMap): EnumKeyValuePair[] {
	// The options 'PickupParking' and 'DriveIn' are special cases of 'TakeAway'
	// so they need to be evaluated first.
	// Otherwise TakeAway rule would take precedence, and we do not want that.
	// (note: changing order inside switch/case does not help)
	const exampleSorting = [
		SalesChannelOption.PickupParking,
		SalesChannelOption.DriveIn
	];

	const sortedKeys = sortByExampleArray(Object.keys(enumMap), exampleSorting);

	const result: EnumKeyValuePair[] = [];
	sortedKeys.forEach((key) => {
		result.push({key, value: enumMap[key]});
	});
	return result;
}

function doesTableAllocationMatch(order: IBKPublishedOrderData, salesChannelOption: SalesChannelOption): boolean {
	const tableAllocation = getTableAllocation(order);
	if (!tableAllocation) {
		return false;
	}

	if (salesChannelOption === SalesChannelOption.TableServiceInside) {
		return tableAllocation.locationSpace === BKTableAllocationLocationSpace.INDOORS;
	}

	if (salesChannelOption === SalesChannelOption.TableServiceTerrasse) {
		return tableAllocation.locationSpace === BKTableAllocationLocationSpace.TERRACE;
	}

	return false;
}

function getTableAllocation(order: IBKPublishedOrderData): IBKTableAllocationAssignment | null {
	const orderTableAlloc = BKOrderEventUtilities.getTableAllocationAssignment(order.event);
	return orderTableAlloc;
}

function doesDeliveryModeMatch(order: IBKPublishedOrderData, salesChannelOption: SalesChannelOption): boolean {
	if (salesChannelOption === SalesChannelOption.EatIn) {
		return order.deliveryMode === BKDeliveryModeEnum.DELIVERY_LOCAL;
	}

	if (salesChannelOption === SalesChannelOption.TakeAway) {
		return order.deliveryMode === BKDeliveryModeEnum.DELIVERY_TAKE_OVER;
	}

	return false;
}

function isDriveWindowOrder(order: IBKPublishedOrderData): boolean {
	const metadataEvent: IBKOrderEventData | undefined = (order.event || []).find((e) => e.eventType === BKOrderEventType.WEBORDER_META_PICKUP_TYPE);
	return !!metadataEvent && metadataEvent.arg === BKPickUpTypeEnum.DRIVE;
}

function isPickupParkingOrder(order: IBKPublishedOrderData): boolean {
	const metadataEvent: IBKOrderEventData | undefined = (order.event || []).find((e) => e.eventType === BKOrderEventType.WEBORDER_META_PICKUP_TYPE);
	return !!metadataEvent && metadataEvent.arg === BKPickUpTypeEnum.PARKING;
}

function hasSomeItemsDeliveredLater(order: IBKPublishedOrderData): boolean {
	return order.orderContent.some((item) => item.later) && order.deliveryMode === BKDeliveryModeEnum.DELIVERY_LOCAL;
}

function hasMatchingDeliveryCompany(order: IBKPublishedOrderData, salesChannelOption: SalesChannelOption): boolean {
	const allowedDeliveryCompany = salesChannelOption.toLocaleLowerCase();
	const metadataEvent = order.event.find((e) => e.eventType === BKOrderEventType.WEBORDER_SANDBOX_NAME);
	const deliveryCompany = getOrderSandboxName(order).toLowerCase();

	const isMatchingDeliveryCompany = deliveryCompany === allowedDeliveryCompany;
	const isMatchingFromMetadata = (!!metadataEvent && metadataEvent.arg.toLowerCase() === allowedDeliveryCompany);

	return isMatchingDeliveryCompany || isMatchingFromMetadata;
}