/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { XMBScreenConfigDto } from './xmb-screen-config-dto';
import { SelectionPatternDto } from './selection-pattern-dto';


export interface XMBConfigDto { 
    /**
     * Id of the item reference in the DB
     */
    id: number;
    /**
     * Name of the config
     */
    name: string;
    /**
     * description of the config
     */
    description: string;
    /**
     * Screen configuration
     */
    screens: Array<XMBScreenConfigDto>;
    /**
     * Pattern selecting to which restaurant this config is applicable to
     */
    applicableToPattern: Array<SelectionPatternDto>;
}

