/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface NutrientDto { 
    /**
     * Id of the nutrient.
     */
    id: number;
    /**
     * System name of the nutrient.
     */
    sysName: string;
    /**
     * Description of the nutrient.
     */
    description: string;
    /**
     * Dictionary (associative array) of names where key is the language.
     */
    _name: { [key: string]: string; };
    /**
     * Id of the parent nutrient. Defaults to 0 if none.
     */
    parentNutrientId: number;
    /**
     * Simple sorting index.
     */
    sortIndex: number;
    /**
     * Unit of the values associated with the nutrient.   Values:   NONE = 0,   GRAM = 1,   KILOCALORIE = 2
     */
    unit: number;
}

