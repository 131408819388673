<div class="labels">
	<p>Cumul</p>
	<p #secondLabel class="ignore">Temps Operationnels</p>
</div>

<table class="dk-table">
	<thead>
	<tr>
		<th>Créneaux</th>
		<th>Horaire</th>
		<th>CA</th>
		<th>Ticket Moyen</th>
		<th>Nb Commandes</th>
		<th #cell>Preperation</th>
		<th>Départ</th>
		<th class="has-info">
			Total service
			<button mat-icon-button matTooltip="Départ + Préparation" aria-label="Tooltip for Départ + Préparation">
				<mat-icon>info_outline</mat-icon>
			</button>
		</th>
	</tr>
	</thead>
	<tbody>
		@for (item of data | keyvalue: originalOrder; track $index) {
			<tr>
				<td>{{ labels[item.key] }}</td>
				<td>{{ formatHoraire(item.value) }}</td>
				<td>{{ ' - ' }}</td>
				<td>{{ ' - ' }}</td>
				<td>{{ item.value.count }}</td>
				<td [ngClass]="item.value.depart_time ? (obj(item.value.depart_time / item.value.count, 'depart') ? 'red' : 'green') : 'white'">
					{{ item.value.depart_time / item.value.count | toMins }}
				</td>
				<td
					[ngClass]="
						item.value.preparation_time ? (obj(item.value.preparation_time / item.value.count, 'preparation') ? 'red' : 'green') : 'white'
					"
				>
					{{ item.value.preparation_time / item.value.count | toMins }}
				</td>
				<td
					[ngClass]="
						item.value.preparation_time
							? obj((item.value.depart_time + item.value.depart_time) / item.value.count, 'total')
								? 'red'
								: 'green'
							: 'white'
					"
				>
					{{ (item.value.preparation_time + item.value.depart_time) / item.value.count | toMins }}
				</td>
			</tr>
		}

	<!-- Repeat the above row for each time slot -->
	</tbody>
	<tfoot>
	<tr>
		<td colspan="2">Total/Moyenne</td>
		<td>{{ ' - ' }}</td>
		<td>{{ ' - ' }}</td>
		<td>{{ total('count') }}</td>
		<td>{{ total('depart_time') / total('count') | toMins }}</td>
		<td>{{ total('preparation_time') / total('count') | toMins }}</td>
		<td>{{ total() / total('count') | toMins }}</td>
	</tr>
	</tfoot>
</table>
