/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type FiscalProvider = 'plutus' | 'csi' | 'zelty';

export const FiscalProvider = {
    Plutus: 'plutus' as FiscalProvider,
    Csi: 'csi' as FiscalProvider,
    Zelty: 'zelty' as FiscalProvider
};

