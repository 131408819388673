/**
 * Type definition for both bower.json and manifest.json.
 * We are stuck with bower.json because of Shar3dClient.
 */
export type ManifestFile = {
	id: string;
	name: string;
	version: string;
	buildTimeStamp: string;
};
