import { SingleAPIConfig } from '@libs/shared/models';
import { BrandName } from '@merim/utils';
import { ENVIRONMENT_ENUM } from '@shared/models';
import { CurrencyConfig } from './config.type';

export class ConfigModel {
	environment: ENVIRONMENT_ENUM = ENVIRONMENT_ENUM.DEV;
	brand: BrandName = undefined;
	isLocal: boolean = true;
	showProductDynamicDistribution: true;
	validateDefaultOrbp: true;
	hideColumnIsDefaultOrb: false;
	enableProductionManagementSystem: true;
	enableDssOrderSortConfiguration: false;
	API: Record<string, SingleAPIConfig> = {};
	currency: CurrencyConfig = {
		symbol: '',
		code: '',
	};

	constructor(init?: Partial<ConfigModel>) {
		Object.assign(this, init);
	}
}
