/**
 * DKI-BE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Channel } from './channel';


export interface ChannelOrdersWithAverages {
	/**
	 * Sales channel
	 */
	channel: Channel;
	/**
	 * Total count of orders.
	 */
	count: number;
	/**
	 * Total value of orders for this channel (EUR).
	 */
	value: number;
	/**
	 * Mean count of orders.
	 */
	mean_count: number;
	/**
	 * (count - mean_count) / mean_count
	 */
	trend: number;
	/**
	 * Mean count of orders across all restaurants
	 */
	mean_count_global: number;
	/**
	 * (count - mean_count_global) / mean_count_global
	 */
	trend_global: number;
}

