import { Directive, HostListener, Input } from "@angular/core";
import { NgControl } from "@angular/forms";
import { UniqueDirectiveModel } from "../models";

@Directive({
	selector: "[uniqueValidator]",
})
export class UniqueValidatorDirective {
	@Input() arrayToCheck: UniqueDirectiveModel = new UniqueDirectiveModel();
	@Input() caseSensitive = true;
	@Input() typeSensitive = true;
	@Input() checkOnChange = false;

	@HostListener("blur") onBlur() {
		this.handleChange();
	}

	@HostListener("input") onChange() {
		if (this.checkOnChange) {
			this.handleChange();
		}
	}

	constructor(public formControl: NgControl) {}

	private handleChange(): void {
		if (
			this.arrayToCheck.entries?.some((item) => {
				const formValue = this.formControl.control.value;
				const iterateValue = item[this.arrayToCheck.key];

				if (!this.caseSensitive && typeof formValue === "string" && typeof iterateValue === "string") {
					if (this.typeSensitive) {
						return formValue.toLowerCase() === iterateValue.toLowerCase();
					} else {
						return formValue.toLowerCase() == iterateValue.toLowerCase();
					}
				}

				if (this.typeSensitive) {
					return formValue === iterateValue;
				} else {
					return formValue == iterateValue;
				}
			})
		) {
			this.formControl.control.setErrors({ notUnique: true });
		}
	}
}
