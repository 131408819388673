import { EnumMap } from '@merim/utils';
import { SalesChannel } from '../enums';
import { DeviceSpecificSalesChannelConfiguration } from './device-specific-sales-channel-configuration';

export type SalesChannelConfiguration = {
	salesChannel: SalesChannel;
	/**
	 * Key is name of SalesChannelOption enum
	 */
	configuration: EnumMap;

	/**
	 * Can be used only when SalesChannel is in SalesChannelsWithDeviceSpecificConfiguration array.
	 * When deviceSpecificConfiguration empty object or undefined, then all "SalesChannel Devices" (eg All Kiosks) are routed to this ORBp.
	 */
	deviceSpecificConfiguration?: DeviceSpecificSalesChannelConfiguration;
};
