<div class="wrapper">
	@for (label of labels; track label; let index = $index) {
		<div class="label" [class.selected]="label.selected" (click)="toggleLabel(index)">{{ label.label }}</div>
	}
	<div class="selector" (click)="openPicker(picker)">
		<mat-icon id="calendar">date_range</mat-icon>
		@if (!myDate) {
			<span>Dates Personnalisées</span>
		} @else {
			<span>{{ myDate }}</span>
		}
		<mat-icon id="arrow">keyboard_arrow_down</mat-icon>
		<mat-date-range-input [formGroup]="range" [rangePicker]="picker" class="hideme">
			<input formControlName="from" matStartDate #from/>
			<input formControlName="to" matEndDate #to/>
		</mat-date-range-input>
		<mat-date-range-picker #picker>
			<mat-date-range-picker-actions>
				<button mat-raised-button color="primary" matDateRangePickerApply (click)="eraseSelectionAndApply(from.value, to.value)">
					Apply
				</button>
			</mat-date-range-picker-actions>
		</mat-date-range-picker>
	</div>
</div>
