import { AvailableJson } from "@bk/fullstack-common";
import { SalesChannelOption } from '@bk/price-management-common';

import { getLocalPriceDefinitionSchemaPropertyName, LocalPriceDefinition, LocalPriceDefinitionPerSalesChannel, PriceDefinitionType } from "../types";

/**
 * Find matching local prices definitions
 * @param type Menu, Product or Ingredient
 * @param availableJson File containing local price definitions (among other stuff)
 * @param itemId Id of given Menu, Product or Ingredient
 * @returns matching price definitions
 */
export function getLocalPriceDefinitions(
	type: PriceDefinitionType,
	salesChannelOption: SalesChannelOption,
	availableJson: AvailableJson,
	itemId: number): LocalPriceDefinitionPerSalesChannel[] {
	const localPropertyName = getLocalPriceDefinitionSchemaPropertyName(type);

	const localPriceDefinition: LocalPriceDefinition | undefined = availableJson.priceDefinitions[localPropertyName][itemId];
	const values: LocalPriceDefinitionPerSalesChannel[] = localPriceDefinition?.values || [];
	return values.filter(localPriceDef => localPriceDef.salesChannelOption === salesChannelOption);
}
