/**
 * DKI-BE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DkiBeClickhouseAnalyticsDailySalesReportOrdersByChannel } from './dki-be-clickhouse-analytics-daily-sales-report-orders-by-channel';


export interface DailySales {
	/**
	 * Order statistics by day
	 */
	by_date: { [key: string]: DkiBeClickhouseAnalyticsDailySalesReportOrdersByChannel; };
	/**
	 * Total order statistics
	 */
	total: DkiBeClickhouseAnalyticsDailySalesReportOrdersByChannel;
}

