/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FeatureConfigurationDto } from './feature-configuration-dto';


export interface FeaturesDto { 
    /**
     * All the features listed here are enabled on global level or allowed without global configuration.
     */
    configurations: Array<FeatureConfigurationDto>;
    /**
     * Flag indicating if \'EAT_IN\' and \'TAKE_AWAY\' sales channel options should be merged, i.e. should have the same prices set.
     */
    mergeEatInAndTakeAwaySalesChannelOptions: boolean;
}

