/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SalesChannelOption } from './sales-channel-option';


export interface RestaurantPriceLevel { 
    sales_channel_option: SalesChannelOption;
    is_enabled: boolean;
    price_level: string;
}

