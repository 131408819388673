/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MonitoringDeviceInfo } from './monitoring-device-info';
import { RestaurantStateLocal } from './restaurant-state-local';
import { RestaurantFeature } from './restaurant-feature';
import { WeborderChannel } from './weborder-channel';
import { VatRate } from './vat-rate';


export interface MonitoringRestaurantInfo { 
    /**
     * Restaurant\'s UUID.
     */
    id: string;
    /**
     * Name of the restaurant (taken from MySQL).
     */
    name: string;
    /**
     * Primary third party restaurant identification.
     */
    bknb?: string;
    /**
     * Secondary third party restaurant identification.
     */
    frnb?: string;
    /**
     * Flag indicating if the restaurant is connected to the VPN network (if there was correct VPN notification from restaurant in set time).
     */
    is_connected?: boolean;
    /**
     * Not sure?
     */
    is_verified?: boolean;
    /**
     * Flag indicating if the diagnostics is activated for the restaurant (future feature).
     */
    diagnostics_enabled?: boolean;
    /**
     * List of the devices registered in the restaurant.
     */
    devices: Array<MonitoringDeviceInfo>;
    /**
     * Timestamp of the last time the restaurant was connected to the VPN network.
     */
    last_connection?: string;
    /**
     * Address in the VPN network.
     */
    vpn_address?: string;
    /**
     * Local state of a restaurant.
     */
    local_state: RestaurantStateLocal;
    /**
     * Local IP address of a device utilized as a WebPOS.
     */
    webpos_ip?: string;
    /**
     * Number of websocket connections configured on BO-LOCAL-MANAGER that should be connected.
     */
    websockets_configured: number;
    /**
     * Number of websocket connections of BO-LOCAL-MANAGER that are connected.
     */
    websockets_connected: number;
    /**
     * Number of websocket connections of BO-LOCAL-MANAGER that are not connected.
     */
    websockets_disconnected: number;
    /**
     * List of enabled features in the restaurant. Combines global features and local features.
     */
    features: Array<RestaurantFeature>;
    /**
     * Weborder channels active in the restaurant. Combines globally enabled weborder channels with locally disabled weborder channels.
     */
    weborder_channels: Array<WeborderChannel>;
    /**
     * VAT rates applied in the restaurant.
     */
    vatRates?: Array<VatRate>;
}

