import { equals } from 'ramda';

export function arrsHaveEqualStrings(arr1: string[], arr2: string[]): boolean {
	const arr1Sorted = (arr1 || []).sort();
	const arr2Sorted = (arr2 || []).sort();
	return equals(arr1Sorted, arr2Sorted);
}

export function getUniqueFilteredStringArr(arr1: string[], itemsToRemove: string[] = undefined): string[] {
	const set1 = new Set(arr1);
	if (itemsToRemove && itemsToRemove?.length) {
		itemsToRemove.forEach((column) => {
			if (set1.has(column)) {
				set1.delete(column);
			}
		});
	}
	return [...set1];
}

export function sortArrayByAttribute<T>(a: T[], sortedOrder: number[], key: string): T[] {
	const sortedArr = a.sort((a, b) => {
		let indA = sortedOrder.indexOf(+a[key]);
		let indB = sortedOrder.indexOf(+b[key]);

		if (indA === -1) {
			indA = sortedOrder.length;
		}
		if (indB === -1) {
			indB = sortedOrder.length;
		}

		return indA - indB;
	});
	return sortedArr;
}
