export class OrbpLiveOrdersRemove {

	toBeRemovedOrders: Record<string, number> = {};
	timer: number;
	onOrderRemove: (uuid) => void;

	constructor(timer: number, onOrderRemove: (uuid) => void) {
		if(!timer) {
			console.warn('[APP]: OrbpLiveOrders Removal no timeout set. Not considering this option...');
			return;
		}

		this.timer = timer;
		this.onOrderRemove = onOrderRemove;
	}

	addOrderToEntry(uuid: string): void {
		this.toBeRemovedOrders[uuid] = window.setTimeout(() => this.onOrderRemove(uuid), this.timer);
	}

	removeOrderFromEntry(uuid: string): void {
		clearTimeout(this.toBeRemovedOrders[uuid]);
		delete this.toBeRemovedOrders[uuid];
	}
}
