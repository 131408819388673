/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LanguageConfigurationDto } from './language-configuration-dto';


export interface LanguagesDto { 
    /**
     * All the available languages ordered by importance.
     */
    orderedList: Array<LanguageConfigurationDto>;
    /**
     * Default language code in ISO 639-1 format including country code.
     */
    _default: string;
}

