/**
 * DKI-BE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface MonitoringOverallDevices {
	/**
	 * Total amount of devices in all the restaurants (sum of active and inactive devices).
	 */
	total: number;
	/**
	 * Total amount of active devices.
	 */
	active: number;
	/**
	 * Total amount of inactive.
	 */
	inactive: number;
	/**
	 * Total amount of active devices which show warnings. Device with warning is determined by having at least one task that has \'start_count\' more than 1 and \'last_end_time\' of this task was in last 48 hours [configurable] (i.e. the task is restarting).
	 */
	warning: number;
}

