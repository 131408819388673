import { OrbStatus } from '../enums/orb-status';
import { OrbpRole } from '../enums/orbp-role';
import { OrbpDetail } from '../types';

/**
 * Can some ORBp property be changed?
 */
export function isOrbpChangeable(orbpDetail: OrbpDetail): boolean {
	return (orbpDetail.role === OrbpRole.Master || orbpDetail.role === OrbpRole.Dependent) && orbpDetail.status === OrbStatus.On;
}
