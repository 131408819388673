/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PeakHoursUpdateInput { 
    /**
     * Start of prediction interval of which the peak intensity index should be modified in ISO-8601 dateTime string.
     */
    datetime: string;
    /**
     * New peak intensity index value.
     */
    peakIntensityIndex: number;
}

