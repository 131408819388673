/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SocialEventDto { 
    /**
     * Name of the social event.
     */
    name: string;
    /**
     * Event start dateTime ISO-8601 string.
     */
    from: string;
    /**
     * Event end dateTime ISO-8601 string.
     */
    to: string;
    /**
     * Type of a social event.
     */
    type: SocialEventDto.TypeEnum;
    /**
     * Description of a social event.
     */
    description?: string;
    /**
     * Auto-generated ID of the MySQL row of \'social_events\' table.
     */
    id: string;
}
export namespace SocialEventDto {
    export type TypeEnum = 'PublicHolidays' | 'ReligiousHolidays' | 'Social' | 'SchoolHolidays' | 'Local' | 'Sport' | 'Commercial' | 'ProductLaunch' | 'MarketingHighlight';
    export const TypeEnum = {
        PublicHolidays: 'PublicHolidays' as TypeEnum,
        ReligiousHolidays: 'ReligiousHolidays' as TypeEnum,
        Social: 'Social' as TypeEnum,
        SchoolHolidays: 'SchoolHolidays' as TypeEnum,
        Local: 'Local' as TypeEnum,
        Sport: 'Sport' as TypeEnum,
        Commercial: 'Commercial' as TypeEnum,
        ProductLaunch: 'ProductLaunch' as TypeEnum,
        MarketingHighlight: 'MarketingHighlight' as TypeEnum
    };
}


