/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PmsDeviceDto { 
    /**
     * Standalone device API properties
     */
    id: number;
    /**
     * Device name
     */
    name: string;
    /**
     * Is device active
     */
    active: boolean;
    /**
     * Sorting given to products
     */
    alphabeticalSort: PmsDeviceDto.AlphabeticalSortEnum | null;
    /**
     * All product ids that should be shown, sorted
     */
    productIds: Array<number>;
}
export namespace PmsDeviceDto {
    export type AlphabeticalSortEnum = 'ascending' | 'descending';
    export const AlphabeticalSortEnum = {
        Ascending: 'ascending' as AlphabeticalSortEnum,
        Descending: 'descending' as AlphabeticalSortEnum
    };
}


