import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AppEffects } from './state/effects';
import { ErrorFacade } from './state/facade';
import { reducers } from './state/reducer';

@NgModule({
	imports: [StoreModule.forFeature('error', reducers), EffectsModule.forFeature([AppEffects])],
	providers: [ErrorFacade],
})
export class StateErrorModule {}
