import { LanguageISOCode, LocaleKey, RegionKey } from '../enums';

export * from './exported/channel-orders';
export * from './exported/channel-orders-with-averages';
export * from './exported/opening-time';
export * from './exported/opening-times';
export * from './exported/orders-by-time';
export * from './exported/product-orders';
export * from './exported/restaurant';
export * from './exported/restaurant-settings';
export * from './exported/service-time-thresholds';

export interface Locale {
	[LocaleKey.LANGUAGE]: LanguageISOCode;
	[LocaleKey.REGION]: RegionKey;
}
