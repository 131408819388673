<div class="labels">
	<p>Par Restaurants</p>
	<p #secondLabel class="ignore">Temps Operationnels</p>
</div>

<table class="dk-table">
	<thead>
	<tr style="height: 32px">
		<th>Créneaux</th>
		<th>Horaire</th>
		<th>CA</th>
		<th>Ticket Moyen</th>
		<th>Nb Commandes</th>
		<th #cell>Preperation</th>
		<th>Départ</th>
		<th class="has-info">
			Total service
			<button mat-icon-button matTooltip="Départ + Préparation" aria-label="Tooltip for Départ + Préparation">
				<mat-icon>info_outline</mat-icon>
			</button>
		</th>
	</tr>
	</thead>
	<tbody>
		@for (item of data | keyvalue: originalOrder; track $index) {
			<tr (click)="toggleRow()" class="expandable">
				<td class="text-align-left"><span class="arrow material-icons">keyboard_arrow_down</span>{{ restaurantName(item.key) }}</td>
				<td></td>
				<td>-</td>
				<td>-</td>
				<td>-</td>
				<td>{{ item.value['totals']['preparation_time'] / item.value['totals']['count'] | toMins }}</td>
				<td>{{ item.value['totals']['depart_time'] / item.value['totals']['count'] | toMins }}</td>
				<td>
					{{ (item.value['totals']['depart_time'] + item.value['totals']['preparation_time']) / item.value['totals']['count'] | toMins }}
				</td>
			</tr>
			@if (isOpen) {
				@for (line of item.value | keyvalue: originalOrder; track $index) {
					@if (line.key !== 'totals') {
						<tr [@inOutAnimation]>
							<td>{{ labels[line.key] }}</td>
							<td>{{ formatHoraire(line.value) }}</td>
							<td>-</td>
							<td>-</td>
							<td>{{ line.value['count'] }}</td>
							<td
								[ngClass]="
									line.value['preparation_time']
										? obj(item.value['preparation_time'] / line.value['count'], 'preparation')
											? 'red'
											: 'green'
										: 'white'
								"
							>
								{{ line.value['preparation_time'] / line.value['count'] | toMins }}
							</td>
							<td
								[ngClass]="
									line.value['depart_time'] ? (obj(line.value['depart_time'] / line.value['count'], 'depart') ? 'red' : 'green') : 'white'
								"
							>
								{{ line.value['depart_time'] / line.value['count'] | toMins }}
							</td>
							<td>{{ (line.value['preparation_time'] + line.value['depart_time']) / line.value['count'] | toMins }}</td>
						</tr>
					}
				}
			}
		}

	<!-- Repeat the above row for each time slot -->
	</tbody>
	<tfoot>
	<tr>
		<td colspan="2">Total/Moyenne</td>
		<td>-</td>
		<td>-</td>
		<td>{{ totals('count') }}</td>
		<td>{{ totals('preparation_time') / totals('count')| toMins }}</td>
		<td>{{ totals('depart_time') / totals('count') | toMins }}</td>
		<td>{{ (totals('preparation_time') + totals('depart_time')) / totals('count') | toMins }}</td>
	</tr>
	</tfoot>
</table>
