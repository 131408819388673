export type ProgramFilter = {
	showColumnsIsDefaultORBp: boolean;
	showColumnsSalesChannel: boolean;
	showColumnsFoodCategories: boolean;
	showMaster: boolean;
	showDependents: boolean;
	showMirrors: boolean;
	showOn: boolean;
	showOff: boolean;
};
