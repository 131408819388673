/**
 * Load image from given URLs.
 * If first URL does not yield an image,
 * then try next URL until all URLs are used
 */
export async function loadImageWithFallbackUrl(...imageUrls: string[]): Promise<HTMLImageElement> {
	let imageEl: HTMLImageElement = undefined;

	// Cant use forEach loop here
	for (let i = 0; i < imageUrls.length; i++) {
		const imageUrl = imageUrls[i];

		try {
			imageEl = await loadImage(imageUrl);
			break;
		} catch {
			imageEl = undefined;

		}
	}

	return imageEl;
}

export function loadImage(url: string): Promise<HTMLImageElement> {
	const promise: Promise<HTMLImageElement> = new Promise((resolve, reject) => {
		const image = new Image();

		image.addEventListener('load', () => {
			resolve(image);
		});

		image.addEventListener('error', (err) => {
			reject(err);
		});

		image.src = url;
	});

	return promise;
}
