// Based on https://stackoverflow.com/a/26156806/552194
export function trimChar(str: string, charToRemove: string): string {
	while (str.charAt(0) === charToRemove) {
		str = str.substring(1);
	}

	while (str.charAt(str.length - 1) === charToRemove) {
		str = str.substring(0, str.length - 1);
	}

	return str.trim();
}

export function isNumber(str: string): boolean {
	const num = parseInt(str);
	return Number.isFinite(num);
}

export function normalizeString(str: string): string {
	return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}
