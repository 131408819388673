import { _BK, BKOrderSourceEnum, ISalesServerModeReglement } from "@bk/jscommondatas";
import { isQueryString } from "@merim/utils";
import { BKOrderPayNames, BKOrderPayType } from "@shared/models";

export const BLANC_MODE = 'ablanc';

export function hasWhiteModeSignature(modes: ISalesServerModeReglement[]): boolean {
	//  Do some lookup in the tags
	return modes.some((item) => {
		if (!item.tag) {
			return false;
		}

		if (isQueryString(item.tag)) {
			const args: { [key: string]: string } = _BK.getQueryVariables(item.tag);
			return args[BLANC_MODE] != null;
		}

		return false;
	})
}

export function getWhiteModeSignature(modes: ISalesServerModeReglement[]): ISalesServerModeReglement {
	//  Do some lookup in the tags
	return modes.find((item) => {
		if (!item.tag) {
			return false;
		}

		if (isQueryString(item.tag)) {
			const args: { [key: string]: string } = _BK.getQueryVariables(item.tag);
			return args[BLANC_MODE] != null;
		}

		return false;
	})
}

/**
 * Lookup for the kiosk cash payment method
 */
export function hasCashKioskPaymentSignature(modes: ISalesServerModeReglement[]): boolean {
	//  Do some lookup in the tags
	return modes.some((item) => {
		if (!item.tag) {
			return false;
		}

		if (isQueryString(item.tag)) {
			return item.libelle === BKOrderPayNames.MONNAYEUR_CI10 && item.number === BKOrderPayType.PAID_BY_MONNAYEUR_CI10;
		}

		return false;
	})
}

/**
 * Get payment option according to order's pay type
 */
export function getModeReglementForPayType(payType: BKOrderPayType, paymentOptions: ISalesServerModeReglement[], currentOrderSource: BKOrderSourceEnum): ISalesServerModeReglement | null {
	// WORKAROUND TO ENSURE THAT WALK ORDER SOURCE RECEIVES SAME PAYMENT OPTIONS AS DRIVE ORDER SOURCE
	// SEE DEV-492 FOR MORE DETAILS
	let normalizedCurrentOrderSource = currentOrderSource;
	if(currentOrderSource === BKOrderSourceEnum.ORDER_SOURCE_WALK) {
		normalizedCurrentOrderSource = BKOrderSourceEnum.ORDER_SOURCE_DRIVE;
	} else if(currentOrderSource === BKOrderSourceEnum.ORDER_SOURCE_CLICK_AND_COLLECT) {
		normalizedCurrentOrderSource = BKOrderSourceEnum.ORDER_SOURCE_CASH_MACHINE;
	}

	// THESE ORDER SOURCES ARE NOT SUPPOSED TO BE PAYED AT POS
	const orderSourcesWithoutThirdPartyPaymentOptions = [BKOrderSourceEnum.ORDER_SOURCE_HOME_DELIVERY];

	// CASH, CHECQES, TICKETS AND OTHER SIMILAR PHYSICAL METHODS HAS NO MDM SOURCE
	// SHOULD BE AVAILABLE ON POS AT ANY STATE
	const orderSourceWithPhysicalPaymentOptions = [
		BKOrderSourceEnum.ORDER_SOURCE_CASH_MACHINE,
		BKOrderSourceEnum.ORDER_SOURCE_DRIVE,
		BKOrderSourceEnum.ORDER_SOURCE_WALK,
		BKOrderSourceEnum.ORDER_SOURCE_KIOSK,
	];

	// FYI: "paymentOptions: ISalesServerModeReglement[]" originally come from sales server
	// and its value is stored in related csi db
	const paymentOptionAccordingToOrderPayType =
		paymentOptions
			.filter((paymentOption) => !!paymentOption.tag)
			.find((paymentOption) => {
				if (isQueryString(paymentOption.tag)) {
					const currentOrderSourceHasNoThirdPartyPaymentOptions = orderSourcesWithoutThirdPartyPaymentOptions.includes(normalizedCurrentOrderSource);

					if (!currentOrderSourceHasNoThirdPartyPaymentOptions) {
						const tagQueryVariables = _BK.getQueryVariables(paymentOption.tag);

						if (tagQueryVariables) {
							const mdIdMatchesPayType = !!tagQueryVariables.mdmid && +tagQueryVariables.mdmid === payType;

							if (mdIdMatchesPayType) {
								const hasOrderSource = !!tagQueryVariables.mdmsource && currentOrderSource !== BKOrderSourceEnum.ORDER_SOURCE_UNKNOWN;
								// MDM SOURCE IS EXPONENTIAL VALUE OF ORDER SOURCE
								// ABPROD CALLS IT MASK
								// ORIGINALLY USED FOR BITWISE OPERATIONS TO FOUND MATCHING VALUES
								// CASHIER PAYMENT OPTIONS HAS NO MDM SOURCE
								const mdmSourceMatchesCurrentOrderSource = !!hasOrderSource && 2 ** normalizedCurrentOrderSource === +tagQueryVariables.mdmsource;
								const isPaymentOptionWithoutSource =
									!tagQueryVariables.mdmsource &&
									(orderSourceWithPhysicalPaymentOptions.includes(normalizedCurrentOrderSource) || normalizedCurrentOrderSource === BKOrderSourceEnum.ORDER_SOURCE_UNKNOWN);

								if (isPaymentOptionWithoutSource || mdmSourceMatchesCurrentOrderSource) {
									return paymentOption;
								} else {
									return null;
								}
							} else {
								return null;
							}
						} else {
							return null;
						}
					} else {
						return null;
					}
				} else if (+paymentOption.tag === payType) {
					return paymentOption;
				} else {
					return null;
				}
			}) || null;

	return paymentOptionAccordingToOrderPayType;
}