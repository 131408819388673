/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TestOrbpConfigurationDto { 
    /**
     * Id of the DSS program with printers configuration.
     */
    basedOnProgramId: string;
}

