import { GlobalAppsConfigurationDto } from '@rap/api-client';
import { GlobalAppsConfiguration } from './global-apps-configuration';

export function mapDkiBEConfigurationFromDto(globalAppsConfigurationDto: GlobalAppsConfigurationDto): GlobalAppsConfiguration {
	const result: GlobalAppsConfiguration = {
		accessToken: globalAppsConfigurationDto.accessToken,
		dashUrl: globalAppsConfigurationDto.dashUrl,
		dashBeUrl: globalAppsConfigurationDto.dashBeUrl,
		gapBeUrl: globalAppsConfigurationDto.gapBeUrl,
		restoUuid: globalAppsConfigurationDto.uuid,
	};
	return result;
}
