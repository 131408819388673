// This complements the legacy enum in bk-jscommondata called BKMenuSizeEnum, which only uses numbers.
// We will likely refactor this in future, because Menus will be defined in chained-links
// pointing to upper/lower sizes.
// That way we may define infinite number of sizes.
//
// There is also MenuSize enum in PriceManagementCommon which only has M/L values.
// In future we would like to replace it as well.
export enum MenuSize {
	/**
	 * Fallback. Ideally should not be used.
	 */
	UNKNOWN = 'Unknown',
	MEDIUM = 'Medium',
	LARGE = 'Large',
	XL = "XL",
}