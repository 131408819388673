import { IBKItemInOrderBase, IBKPublishedOrderData } from '@bk/jscommondatas';
import { IncrementLetterGenerator } from '../classes';
import { PatchedIBKItemBase } from "./patch-kids-menu";

/**
 * Adds suffix to every menu item
 * @param order Expected to be a `BKOrder` or `IBKPublishedOrderData`
 */
export function patchMenuSuffix(order: IBKPublishedOrderData): void {
	const menus = order?.orderContent?.filter((item) => !!item.selection) || [];
	const letterSuffixGenerator = new IncrementLetterGenerator();
	let menuLetter = letterSuffixGenerator.next().toUpperCase();
	const areMenusIdentical = areMenusIdenticalWithoutRecipe(menus);
	menus.forEach((menu) => {
		menu.selection.forEach((product) => {
			product.shortName += ` - Menu ${menuLetter}`;
			(<PatchedIBKItemBase>product).isPatchedWithSuffix = true;
		});
		if(!areMenusIdentical) {
			menuLetter = letterSuffixGenerator.next().toUpperCase();
		}
	})
}

function areMenusIdenticalWithoutRecipe(menus: IBKItemInOrderBase[]): boolean {
	if(menus.length === 0) {
		return true;
	}

	if(menus.length === 1) {
		return false;
	}

	const firstMenuProducts = menus[0].selection.map((a) => a.id).sort().join('');
	return menus.every((item) => {
		const productIds = item.selection.map((a) => a.id).sort().join('');
		return productIds === firstMenuProducts && item.selection.every((b) => !b.recipe || b.recipe?.length === 0);
	})
}

