export enum DssProgramErrorCode {
	/**
	 * Each SalesChannel (expect POS) has to be assigned to some Master ORBp
	 */
	NotAssignedSalesChannel = 'not-assigned-sales-channel',
	MissingDefaultOrbp = 'missing-default-orbp',
	/**
	 * There must be single default ORBp
	 */
	DefaultOrpbIsNotSingle = 'not-single-default-orbp',
	/**
	 * This is just information, not a validation error.
	 */
	POSWillHandlePOSOrders = 'pos-will-handle-pos-orders',
}
