import { getTruthyEnumMapKeys } from '@merim/utils';

import { OrbpRole } from '../../enums';
import { OrbpDetail } from '../../types/orbp-detail';
import { getMergedFoodCategories } from '../get-merged-food-categories';
import { hasMatchingMasters } from './has-matching-masters';

export function hasValidFoodCategories(orbpDevice: OrbpDetail, masterOrbpDevices?: OrbpDetail[]): boolean {
	if (orbpDevice.role !== OrbpRole.Master) {
		const isMasterOk = masterOrbpDevices && hasMatchingMasters(orbpDevice, masterOrbpDevices);

		if (!isMasterOk) {
			return false;
		}
	}

	const masterFood = getMergedFoodCategories(masterOrbpDevices || []);

	switch (orbpDevice.role) {
		// All FoodCategories are ON
		case OrbpRole.Master: {
			const foodCategories = Object.keys(orbpDevice.foodCategoryConfiguration);
			const enabledFoodCategories = getTruthyEnumMapKeys(orbpDevice.foodCategoryConfiguration);
			return foodCategories.length === enabledFoodCategories.length;
		}

		// Subset of FoodCategories
		case OrbpRole.Dependent:
			if (!masterOrbpDevices) {
				throw new Error('Missing masterOrbpDevice');
			}

		{
			const masterFoodCategories = getTruthyEnumMapKeys(masterFood);
			const enabledFoodCategories = getTruthyEnumMapKeys(orbpDevice.foodCategoryConfiguration);
			return enabledFoodCategories.every((fc) => masterFoodCategories.includes(fc));
		}

		// Same FoodCategories as in Master
		case OrbpRole.Mirror:
			if (!masterOrbpDevices) {
				throw new Error('Missing masterOrbpDevice');
			}

		{
			const masterFoodCategories = getTruthyEnumMapKeys(masterFood).sort().join();
			const enabledFoodCategories = getTruthyEnumMapKeys(orbpDevice.foodCategoryConfiguration).sort().join();
			return masterFoodCategories === enabledFoodCategories;
		}

		default:
			throw new Error(`Unexpected OrbpRole: ${orbpDevice.role}`);
	}
}
