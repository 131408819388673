import { OrbpRole } from '@bk/fullstack-common';
import { BKOrderState } from '@bk/jscommondatas';

export type BumpedOrderInfo = {
	/**
	 * Unique identification of Order
	 */
	orderUUID: string;
	/**
	 * Unique identification for humans
	 */
	orderIdToDisplay: string;
	sourceORBpRole: OrbpRole;
	sourceORBpId: string;
	newState: BKOrderState;
};
