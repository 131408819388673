import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { keys } from 'ramda';
import { filter, take } from 'rxjs/operators';

export const INJECTOR_TOKEN_CSS_LOAD = 'document-easy-branding-token';

@Injectable({
	providedIn: 'root',
})
/**
 * Downloads stylesheets from local-server with overriding classes for primary / secondary / tertiary colours
 * Might be used for small brands with lightweight branding
 */
export class LazyLoadDynamicStylesService {
	constructor(
		@Inject(DOCUMENT) private document: Document,
		@Inject(INJECTOR_TOKEN_CSS_LOAD) private url: string,
		private readonly route: ActivatedRoute
	) {
		this.route.queryParams
			.pipe(
				filter((item) => keys(item).length > 0),
				take(1)
			)
			.subscribe((queryParams) => {
				const urlToBeUsed = queryParams.branding || url;
				if (urlToBeUsed) {
					const head = this.document.getElementsByTagName('head')[0];

					const style = this.document.createElement('link');
					style.id = 'client-theme';
					style.rel = 'stylesheet';
					style.href = `${urlToBeUsed}`;

					head.appendChild(style);
				} else {
					console.warn('[APP]: No branding file found in the configuration');
				}
			});
	}
}
