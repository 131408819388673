import { RawValidationMessage, ValidationMessageSeverityEnum } from '@merim/utils';

import { OrbStatus } from '../../enums/orb-status';
import { shouldHandlePosOrdersOnPos } from '../../load-balancing/should-handle-pos-orders-on-pos';
import { DssProgramErrorCode } from '../../models/dss-program-error-code';
import { LoadBalancingErrorCode } from '../../models/load-balancing-error-code';
import { OrbpValidatedProperties } from '../../models/orbp-properties-for-validation';
import { getDefaultDssProgramValidation, RawDssProgramValidation } from '../../models/program-validation';
import { OrbpDetail } from '../../types/orbp-detail';
import { isDependent, isMaster } from '../is-role';
import { areAllSalesChannelsAssignedToSomeMaster } from './are-all-sales-channels-assigned-to-some-master';
import { hasConflictingLoadBalancing } from './has-conficting-load-balancing';
import { hasValidDynamicDistributionLoadBalancing } from './has-valid-dynamic-distribution-load-balancing';
import { hasValidDynamicDistributionRoundRobin } from './has-valid-dynamic-distribution-round-robin';
import { hasValidProductDynamicDistributionLoadBalancing } from "./has-valid-product-dynamic-distribution-load-balancing";
import { isDependentOnActiveMasters } from './is-dependent-on-active-masters';
import { isDependentWithMultipleMastersValid } from './is-dependent-with-multiple-masters-valid';
import { hasMissingDefaultORBpForSalesChannels, isValidDefaultORBpForSalesChannels } from './is-valid-default-orbp-for-sales-channels';
import { DssValidatorOptions } from './orbp-validator-options';

export class LoadBalancingValidator {

	validate(allProgramOrbps: OrbpDetail[], options: DssValidatorOptions): RawDssProgramValidation {
		const activeMasterOrbps = allProgramOrbps.filter((orbp) => isMaster(orbp) && orbp.status === OrbStatus.On);
		const dependentOrbps = allProgramOrbps.filter((orbp) => isDependent(orbp) && orbp.status === OrbStatus.On);

		let validationResult: RawDssProgramValidation = getDefaultDssProgramValidation();

		// Validate Masters
		activeMasterOrbps.forEach((orbp) => {
			const otherMasters = activeMasterOrbps.filter((x) => x.id !== orbp.id);

			// 1st validate uniquiness
			if (hasConflictingLoadBalancing(orbp, otherMasters)) {
				const validationMessage = getRawValidationMessage(
					LoadBalancingErrorCode.ConflictingRules,
					OrbpValidatedProperties.loadDistribution
				);

				validationResult = addOrbpValidationMessage(validationResult, orbp.id, validationMessage);
			}

			// 2nd validate by LoadDistribution.DYNAMIC_DISTRIBUTION
			const validationCodeDD = hasValidDynamicDistributionLoadBalancing(orbp, otherMasters);

			if (validationCodeDD !== undefined) {
				const validationMessage = getRawValidationMessage(validationCodeDD, OrbpValidatedProperties.loadDistribution);
				validationResult = addOrbpValidationMessage(validationResult, orbp.id, validationMessage);
			}
			// 2nd validate by LoadDistribution.PRODUCT_DYNAMIC_DISTRIBUTION
			const validationCodePDD = hasValidProductDynamicDistributionLoadBalancing(orbp, otherMasters);

			if (validationCodePDD !== undefined) {
				const validationMessage = getRawValidationMessage(validationCodePDD, OrbpValidatedProperties.loadDistribution);
				validationResult = addOrbpValidationMessage(validationResult, orbp.id, validationMessage);
			}

			// 3rd validate by LoadDistribution.ROUND_ROBIN
			const validationCodeRR = hasValidDynamicDistributionRoundRobin(orbp, otherMasters);

			if (validationCodeRR !== undefined) {
				const validationMessage = getRawValidationMessage(validationCodeRR, OrbpValidatedProperties.loadDistribution);
				validationResult = addOrbpValidationMessage(validationResult, orbp.id, validationMessage);
			}
		});

		if (options.validateDefaultORBp) {
			//
			// Validatin Masters - Part II (not in a loop).
			//
			// 4th - Check for Default ORBp
			// the rule is: "are all SalesChannels assigned to some Master ORBP? If not, then Default ORBp has to be set".
			const areAllSalesChannelsAssigned = areAllSalesChannelsAssignedToSomeMaster(activeMasterOrbps);
			const defaultOrbp = activeMasterOrbps.find((o) => o.isDefaultForSalesChannel);
			if (!areAllSalesChannelsAssigned && defaultOrbp === undefined) {
				const validationMessage = getRawValidationMessage(DssProgramErrorCode.NotAssignedSalesChannel, undefined);
				validationResult.general.push(validationMessage);
			}

			// 5th - There must be only single Default ORBp
			const isMissingDefaultORBp = hasMissingDefaultORBpForSalesChannels(activeMasterOrbps);
			if (isMissingDefaultORBp) {
				const validationMessage = getRawValidationMessage(DssProgramErrorCode.MissingDefaultOrbp, undefined);
				validationResult.general.push(validationMessage);
			} else {
				const hasSingleDefaultORBp = isValidDefaultORBpForSalesChannels(activeMasterOrbps);
				if (!hasSingleDefaultORBp) {
					const validationMessage = getRawValidationMessage(DssProgramErrorCode.DefaultOrpbIsNotSingle, undefined);
					validationResult.general.push(validationMessage);
				}
			}
		}

		if (shouldHandlePosOrdersOnPos(allProgramOrbps)) {
			const infoMessage = getRawValidationMessage(DssProgramErrorCode.POSWillHandlePOSOrders, undefined);
			infoMessage.severity = ValidationMessageSeverityEnum.INFO;
			validationResult.general.push(infoMessage);
		}

		// 2nd - Validate Dependent ORBps
		dependentOrbps.forEach((orbp) => {
			const isDependentValid = isDependentWithMultipleMastersValid(orbp, allProgramOrbps);
			if (!isDependentValid) {
				const messageKey = getValidationMessageKey(LoadBalancingErrorCode.DependentOrbpHasInvalidMultiMaster);

				const validationMessage: RawValidationMessage = {
					severity: ValidationMessageSeverityEnum.WARNING,
					propertyName: OrbpValidatedProperties.masterIds,
					translationKey: messageKey
				};

				const messages: RawValidationMessage[] = validationResult.orbp[orbp.id] || [];
				messages.push(validationMessage);
				validationResult.orbp[orbp.id] = messages;
			}

			const hasOnlyActiveMasters = isDependentOnActiveMasters(orbp, allProgramOrbps);
			if (!hasOnlyActiveMasters) {
				const validationMessage = getRawValidationMessage(
					LoadBalancingErrorCode.DependentHasInactiveMaster,
					OrbpValidatedProperties.masterIds
				);
				validationResult = addOrbpValidationMessage(validationResult, orbp.id, validationMessage);
			}
		});

		return validationResult;
	}
}

function getRawValidationMessage(
	validationCode: LoadBalancingErrorCode | DssProgramErrorCode,
	propertyName: string | undefined
): RawValidationMessage {
	const messageKey = getValidationMessageKey(validationCode);

	const validationMessage: RawValidationMessage = {
		severity: ValidationMessageSeverityEnum.WARNING,
		propertyName,
		translationKey: messageKey
	};

	return validationMessage;
}

function addOrbpValidationMessage(validationResult: RawDssProgramValidation, orbpId: string, message: RawValidationMessage): RawDssProgramValidation {
	const nextValidationResult = {...validationResult};

	const validationMessages = nextValidationResult.orbp[orbpId] || [];
	validationMessages.push(message);

	nextValidationResult.orbp[orbpId] = validationMessages;
	return nextValidationResult;
}

function getValidationMessageKey(code: LoadBalancingErrorCode | DssProgramErrorCode): string {
	switch (code) {
		case LoadBalancingErrorCode.ConflictingRules:
			return 'LoadBalancing_ConflictingRules';
		case LoadBalancingErrorCode.DynamicDistributionNotSingle:
			return 'LoadBalancing_DynamicDistributionNotSingle';
		case LoadBalancingErrorCode.ProductDynamicDistributionNotSingle:
			return 'LoadBalancing_ProductDynamicDistributionNotSingle';
		case LoadBalancingErrorCode.RoundRobinNotSingle:
			return 'LoadBalancing_RoundRobinNotSingle';
		case LoadBalancingErrorCode.DependentOrbpHasInvalidMultiMaster:
			return 'DependentOrpb_InvalidMultiMasterLoadDistribution';
		case LoadBalancingErrorCode.DependentHasInactiveMaster:
			return 'DependentORbp_MustHaveActiveMasters';
		case DssProgramErrorCode.NotAssignedSalesChannel:
			return 'DssProgram_NotAssignedSalesChannel';
		case DssProgramErrorCode.DefaultOrpbIsNotSingle:
			return 'DssProgram_OnlySingleORBPCanBeDefault';
		case DssProgramErrorCode.MissingDefaultOrbp:
			return 'DssProgram_MissingDefaultORBp';
		case DssProgramErrorCode.POSWillHandlePOSOrders:
			return 'DssProgram_POSHandlesPOSORders';

		default: {
			const errMessage = `getValidationMessage(): Unexpected LoadBalancingErrorCode ${code}`;
			console.warn(errMessage);
			return 'ORBp_GenericValidationMessage';
		}
	}
}
