import { BKDiscountTypeEnum } from '@bk/jscommondatas';

export const isDiscountForProducts = (discountType: BKDiscountTypeEnum): boolean => {
	switch (discountType) {
		case BKDiscountTypeEnum.PRODUCT_DISCOUNT:
		case BKDiscountTypeEnum.PRODUCT_SINGLE_PRICE:
		case BKDiscountTypeEnum.PRODUCT_AND_EXTRA_DISCOUNT:
		case BKDiscountTypeEnum.PRODUCT_EXCLUSIVE:
			return true;
		default:
			return false;
	}
};

/**
 * Check for if a discount should be for menus
 */
export const isDiscountForMenus = (discountType: BKDiscountTypeEnum): boolean => {
	switch (discountType) {
		case BKDiscountTypeEnum.MENU_DISCOUNT:
		case BKDiscountTypeEnum.MENU_SINGLE_PRICE:
		case BKDiscountTypeEnum.MENU_AND_EXTRA_DISCOUNT:
		case BKDiscountTypeEnum.MENU_EXCLUSIVE:
			return true;
		default:
			return false;
	}
};

/**
 * Check if a discount should be for orders
 */
export const isDiscountForOrder = (discountType: BKDiscountTypeEnum): boolean => {
	switch (discountType) {
		case BKDiscountTypeEnum.ORDER_DISCOUNT:
			return true;
		default:
			return false;
	}
};
