export function isSameDate(dateA: Date, dateB: Date): boolean {
	const isSame = dateA.getDate() === dateB.getDate() && dateA.getMonth() === dateB.getMonth() && dateA.getFullYear() === dateB.getFullYear();

	return isSame;
}

export function isToday(date: Date): boolean {
	const now = new Date();
	const isToday = isSameDate(date, now);
	return isToday;
}

// Example of usage:
// const yesterday = getDate(-1);
// const dayAfterTomorrow = getDate(2)
export function getDate(offset: number = 0): Date {
	const date = setMidnight(new Date());
	date.setDate(date.getDate() + offset);
	return date;
}

/**
 * Return number of days between two dates.
 * Expect that the dates are set to midnight, so you should strip hours/minutes/etc yourself
 *
 * if dateA is greater then dateB, then you get positive result
 */
export function getDateDifference(dateA: Date, dateB: Date): number {
	const timeDiff = dateA.getTime() - dateB.getTime();
	const daysDiff = Math.ceil(timeDiff / (getHourInMs() * 24));
	return daysDiff;
}

/**
 * Returns true if dateToCheck is within upper and lower bounds.
 */
export function isDateWithin(dateToCheck: Date, lowerBound: Date, upperBound: Date): boolean {
	// Sanity check
	const checkLimits = getDateDifference(upperBound, lowerBound);
	if (checkLimits < 0) {
		const errMessage = 'isDateWithin(): upperBound must be greater or equal to lowerBound';
		throw new Error(errMessage);
	}

	return lowerBound <= dateToCheck && dateToCheck <= upperBound;
}

export function addDays(date: Date, days: number): Date {
	const nextDate = new Date(date.valueOf());
	nextDate.setDate(nextDate.getDate() + days);
	return nextDate;
}

export function removeDays(date: Date, days: number): Date {
	const nextDate = new Date(date.valueOf());
	nextDate.setDate(nextDate.getDate() - days);
	return nextDate;
}

export function addYears(date: Date, years: number): Date {
	const nextYear = new Date(date.valueOf());
	nextYear.setFullYear(nextYear.getFullYear() + years);
	return nextYear;
}

function padTo2Digits(num: number): string {
	return num.toString().padStart(2, '0');
}

/**
 * Returns date in YYYY-MM-DD string format in local timezone.
 * @param date
 * @returns YYYY-MM-DD string
 */
export function getDateAs_YYYY_MM_DD(date: Date): string {
	const year = date.getFullYear();
	const month = padTo2Digits(date.getMonth() + 1);
	const day = padTo2Digits(date.getDate());
	return `${year}-${month}-${day}`;
}

/**
 * Returns date in YYYY-MM-DD_HH-mm string format in local timezone.
 * @param date
 * @returns YYYY-MM-DD_HHmm string
 */
export function getDateAs_YYYY_MM_DD_HHmm(date: Date): string {
	const yyyymmdd = getDateAs_YYYY_MM_DD(date);
	const hours = padTo2Digits(date.getHours());
	const minutes = padTo2Digits(date.getMinutes());

	return `${yyyymmdd}_${hours}${minutes}`;
}

export function setMidnight(date: Date): Date {
	const resultDate = new Date(date);
	resultDate.setHours(0);
	resultDate.setMinutes(0);
	resultDate.setSeconds(0);
	resultDate.setMilliseconds(0);

	return resultDate;
}

/**
 * Modifies the Date object by adding or subtracting milliseconds.
 * @param date
 * @param offsetInMs
 * @returns date
 */
export function modifyDate(date: Date, offsetInMs: number): Date {
	return new Date(date.getTime() + offsetInMs);
}

export function getHourInMs(): number {
	return 1000 * 60 * 60;
}

/**
 * Treats 24 hours time string
 * @param inputTime
 * @returns
 */
export function treatTimeString(inputTime: string): string {
	const split = inputTime.split(':');
	const startHours = Math.min(Math.max(parseInt(split[0]) || 0, 0), 23);
	const startMinutes = Math.min(Math.max(parseInt(split[1]) || 0, 0), 59);
	return `${startHours}:${startMinutes}`;
}

export function getMondayOfCurrentWeek() {
	const today = new Date();
	const first = today.getDate() - today.getDay() + 1;
	const monday = new Date(today.setDate(first));
	return monday;
}

/**
 * For a given date, get the ISO-8601 week number (Monday is first day)
 *
 * Based on the ISO-8601, the first week of the year must include a Thursday,
 * i.e. if the year starts on Monday, Tuesday, Wednesday or Thursday, it is the
 * first week of a year. If it starts on Friday, Saturday or Sunday, the first
 * week starts on the next Monday.
 *
 * Note that dates in one year can be weeks of previous or next year, overlap is up to 3 days.
 *
 * e.g. 2014/12/29 is Monday in week  1 of 2015
 *      2012/01/01 is Sunday in week 52 of 2011
 *
 * https://stackoverflow.com/a/6117889/6366846
 */
export function getWeekNumber(date: Date): number {
	// Copy date so don't modify original
	date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
	// Set to nearest Thursday: current date + 4 - current day number
	// Make Sundays day number 7
	date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7));
	// Get first day of year
	const yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
	// Calculate full weeks to nearest Thursday
	const weekNo = Math.ceil(((date.getTime() - yearStart.getTime()) / 86400000 + 1) / 7);
	// Return array of year and week number
	return weekNo;
}

/**
 * Returns Date object with local Monday 00:00 of the required week and a year.
 *
 * The Date().getDay() object returns day of the week index starting with Sunday
 * as 0 (first day of a week), ISO-8601 standard considers Monday the first day
 * of a week, so we are returning Monday here.
 *
 * @param week
 * @param year
 * @returns Date
 */
export function getStartOfTheWeek(week: number, year: number): Date {
	/** Get local date of a 1st of January */
	const yearStart = new Date(`${year}-01-01 00:00`);
	/** Find the required week by adding 7 days times week number */
	let dayWithinATargetWeek = addDays(yearStart, 7 * week);
	/** Check if we found the correct week (there may be overlap of 3 days) */
	const foundWeek = getWeekNumber(dayWithinATargetWeek);
	/** Correct the week if incorrect was found initially */
	if (foundWeek !== week) {
		// console.log(`Found week ${foundWeek} is different from target week ${week}.`);
		dayWithinATargetWeek = addDays(dayWithinATargetWeek, 7 * (week - foundWeek));
	}
	// console.log(`Calculating start of a week ${week} in a year ${year}.`);
	// console.log(`Using calculated day ${dayWithinATargetWeek.toISOString()} which is day ${dayWithinATargetWeek.getDay()} of the week ${getWeekNumber(dayWithinATargetWeek)}.`);

	/** Change Sunday to day 7 instead of 0 */
	const foundDayOfAWeek = dayWithinATargetWeek.getDay() === 0 ? 7 : dayWithinATargetWeek.getDay();
	/** Add/subtract days to get Monday */
	const startOfAWeek = addDays(dayWithinATargetWeek, -(foundDayOfAWeek - 1));
	return startOfAWeek;
}

/**
 * Returns the same day of the same week from different year
 * set to 00:00 local time.
 * Defaults to previous year.
 * @param currentDate
 * @param targetYear
 * @returns
 */
export function getSameDayOfTheWeekFromDifferentYear(currentDate: Date, targetYear?: number) {
	/** Default target year to previous year */
	if (!targetYear) {
		targetYear = currentDate.getFullYear() - 1;
	}
	/** Get week number */
	const week = getWeekNumber(currentDate);
	/** Get day of the week (0-6) and change Sunday (0) to 7 */
	const dayOfTheWeek = currentDate.getDay() === 0 ? 7 : currentDate.getDay();
	/** Get first day of target week */
	const mondayOfTheTargetWeek = getStartOfTheWeek(week, targetYear);
	// console.log(`Target week starts with day ${mondayOfTheTargetWeek.toDateString()}`);
	/** Log some errors */
	if (getWeekNumber(mondayOfTheTargetWeek) !== week) {
		console.error(`Week number for calculated Monday (${getWeekNumber(mondayOfTheTargetWeek)}) does not match the target week number ${week}.`);
	}
	if (mondayOfTheTargetWeek.getDay() !== 1) {
		console.error(`Calculated Monday is not a Monday (day of the week 1) but actually day: ${mondayOfTheTargetWeek.getDay()}`);
	}
	/** Calculate proper day */
	const sameDayOfTheWeek = addDays(mondayOfTheTargetWeek, dayOfTheWeek - 1);
	return sameDayOfTheWeek;
}

export function getLastMonth(): Date {
	const currentDate = new Date();
	return new Date(currentDate.getFullYear(), currentDate.getMonth() - 1);
}

export function isValidDate(date: Date): boolean {
	return date instanceof Date && !isNaN(date.getTime());
}
