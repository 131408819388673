import { inject, Injectable } from '@angular/core';
import { OrderEventsUtils } from '@bk/fullstack-common';
import {
	_BK,
	BKKingdomInOrderEventKey,
	BKMessageConstants,
	BKOrderEventArgumentKeys,
	BKOrderEventType,
	BKOrderState,
	IBKItemInOrderBase,
	IBKOrderEventData,
	MESSAGE_TOPICS,
} from '@bk/jscommondatas';
import { DeclaredOrderResponse } from '@bk/price-management-common';
import { ConfigType } from '@libs/kiosk/models';
import { AVAILABLE_JSON, IBKBigData, ProductFamilies } from '@libs/shared/models';
import { ClerkService, ConfigurationService, MessageSenderService, Shar3dClientService } from '@libs/shared/services';
import { ConfigurationFacade } from '@libs/shared/store/configuration';
import { IOrderState } from '@libs/shared/store/order';
import { isMenuPriceNegative, shouldPublishCancelOrder, updateOrderItemsPrice, updateOrderTotalPrice } from '@libs/shared/utils';
import { getExcludedItemsFromGlobalDiscounts, IExcludedDiscountItems } from '@merim/utils';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { IOrderCompleteData } from '@shared/models';
import { clone, keys, reject } from 'ramda';
import { combineLatestWith, filter, of, Subject, take, tap, throttle } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import {
	AddDiscountToOrder,
	AddDiscountToOrderType,
	AddOrderEvents,
	AddProductToOrder,
	AddProductToOrderType,
	ChangeProductQuantity,
	ChangeProductQuantityFinish,
	ChangeProductQuantityType,
	CleanOrderState,
	CleanOrderStateSuccess,
	RequestFailure,
	SetOrderEvents,
	UpdateOrderEvents,
	UpdateOrderLocation,
	UpdateOrderLocationType,
	UpdateOrderPrice,
	UpdateOrderPriceFail,
	UpdateOrderPriceSuccess,
	UpdateOrderWithFiscalPrice,
	UpdateOrderWithFiscalPriceFail,
	UpdateOrderWithFiscalPriceSuccess,
	UpdatePartialOrder,
	UpdatePartialOrderType,
	UpdateProductInOrderType,
	UpsertProductInOrder,
} from './actions';
import { getCurrentOrder, getCurrentOrderContent, isItemInOrder } from './selectors';

@Injectable()
export class OrderEffects {
	private _actions$: Actions = inject(Actions);
	private _store: Store<IOrderState> = inject(Store);
	private readonly _shar3dClientService: Shar3dClientService<ConfigType> = inject(Shar3dClientService);
	private readonly _clerkService: ClerkService = inject(ClerkService);
	private readonly _configurationFacade: ConfigurationFacade = inject(ConfigurationFacade);
	private readonly _configurationService: ConfigurationService<ConfigType> = inject(ConfigurationService);
	private readonly _messageSenderService: MessageSenderService = inject(MessageSenderService);

	constructor() {}

	updatePartialOrder$ = createEffect(() => {
		return this._actions$.pipe(
			ofType(UpdatePartialOrder),
			filter((action: UpdatePartialOrderType) => action.updateOrderPrice === true),
			map((action: UpdatePartialOrderType) => {
				if (this._configurationService.config.common.fiscalCorePriceCalculation) {
					return UpdateOrderWithFiscalPrice({ itemWithoutFiscalPrice: undefined });
				} else {
					return UpdateOrderPrice();
				}
			}),
			catchError(() => {
				return of(RequestFailure());
			})
		);
	});

	addProductToOrder$ = createEffect(() => {
		return this._actions$.pipe(
			ofType(AddProductToOrder),
			tap((action: AddProductToOrderType) => {
				const productEventType =
					action.productInOrder.selection?.length > 0 ? BKOrderEventArgumentKeys.MENU : BKOrderEventArgumentKeys.PRODUCT;
				this._store.dispatch(
					AddOrderEvents({
						data: [OrderEventsUtils.createEvent(BKOrderEventType.BASKET_ITEM_ADD, { [productEventType]: action.productInOrder.id })],
					})
				);
			}),
			map((action: AddProductToOrderType) => {
				if (this._configurationService.config.common.fiscalCorePriceCalculation) {
					return UpdateOrderWithFiscalPrice({ itemWithoutFiscalPrice: action.productInOrder });
				} else {
					return UpdateOrderPrice();
				}
			}),
			catchError(() => {
				return of(RequestFailure());
			})
		);
	});

	addDiscountToOrder$ = createEffect(() => {
		return this._actions$.pipe(
			ofType(AddDiscountToOrder),
			map((action: AddDiscountToOrderType) => {
				if (this._configurationService.config.common.fiscalCorePriceCalculation) {
					return UpdateOrderWithFiscalPrice({ itemWithoutFiscalPrice: undefined });
				} else {
					return UpdateOrderPrice();
				}
			}),
			catchError(() => {
				return of(RequestFailure());
			})
		);
	});

	upsertProductInOrder$ = createEffect(() => {
		return this._actions$.pipe(
			ofType(UpsertProductInOrder),
			switchMap((action: UpdateProductInOrderType) => {
				return this._store.select(isItemInOrder, action.data.lineUuid).pipe(
					take(1),
					map((item: IBKItemInOrderBase) => {
						if (item) {
							if (this._configurationService.config.common.fiscalCorePriceCalculation) {
								return UpdateOrderWithFiscalPrice({ itemWithoutFiscalPrice: action.data.data });
							} else {
								return UpdateOrderPrice();
							}
						} else {
							return AddProductToOrder({ productInOrder: action.data.data });
						}
					}),
					catchError(() => {
						return of(RequestFailure());
					})
				);
			})
		);
	});

	changeProductQuantity$ = createEffect(() => {
		return this._actions$.pipe(
			ofType(ChangeProductQuantity),
			withLatestFrom(this._store.pipe(select(getCurrentOrder))),
			switchMap(([action, order]: [ChangeProductQuantityType, IOrderCompleteData]) => {
				const product = order.orderContent.find((item) => item.lineuuid === action.lineUuid);
				const discount = product?.itemAppliedDiscount[0];
				if (discount && action.isItemRemoved) {
					this._store.dispatch(
						SetOrderEvents({
							data: reject(
								(event: IBKOrderEventData) =>
									event.eventType === BKOrderEventType.KINGDOM_DISCOUNT_IN_ORDER &&
									_BK.getQueryVariables(event.arg)?.[BKKingdomInOrderEventKey.DISCOUNT_UUID] === discount.uuid,
								order.event
							),
						})
					);
				}
				return of(ChangeProductQuantityFinish(action));
			})
		);
	});

	changeProductQuantityFinish$ = createEffect(() => {
		return this._actions$.pipe(
			ofType(ChangeProductQuantityFinish),
			switchMap((action: ChangeProductQuantityType) => {
				return this._store.select(getCurrentOrderContent).pipe(
					tap((data: IBKItemInOrderBase[]) => {
						const product = data.find((item) => item.lineuuid === action.lineUuid);
						if (product) {
							const productEventType = product?.selection?.length > 0 ? BKOrderEventArgumentKeys.MENU : BKOrderEventArgumentKeys.PRODUCT;
							const quantityEventType = action.quantity > 0 ? BKOrderEventArgumentKeys.ADD : BKOrderEventArgumentKeys.REMOVE;
							this._store.dispatch(
								AddOrderEvents({
									data: [
										OrderEventsUtils.createEvent(BKOrderEventType.BASKET_ITEM_ADD, {
											[quantityEventType]: action.quantity,
											[productEventType]: action.id,
										}),
									],
								})
							);
						} else {
							this._store.dispatch(
								AddOrderEvents({
									data: [
										OrderEventsUtils.createEvent(BKOrderEventType.BASKET_ITEM_REMOVE, {
											[BKOrderEventArgumentKeys.PRODUCT]: action.id,
										}),
									],
								})
							);
						}
					}),
					take(1),
					map((orderContent: IBKItemInOrderBase[]) => {
						const product = orderContent.find((item) => item.lineuuid === action.lineUuid);
						if (this._configurationService.config.common.fiscalCorePriceCalculation) {
							const itemWithoutFiscalPrice = !action?.isItemRemoved ? product : undefined;
							return UpdateOrderWithFiscalPrice({ itemWithoutFiscalPrice: itemWithoutFiscalPrice });
						} else {
							return UpdateOrderPrice();
						}
					}),
					catchError(() => {
						return of(RequestFailure());
					})
				);
			})
		);
	});

	updateOrderLocation$ = createEffect(
		() => {
			return this._actions$.pipe(
				ofType(UpdateOrderLocation),
				tap((data: UpdateOrderLocationType) => {
					this._store.dispatch(
						UpdateOrderEvents({
							data: OrderEventsUtils.createEvent(BKOrderEventType.TABLE_ALLOCATION, {
								[BKOrderEventArgumentKeys.EASEL_NUM]: data.location.easelNum,
								[BKOrderEventArgumentKeys.LOCATION_SPACE]: data.location.locationType,
							}),
						})
					);
				})
			);
		},
		{ dispatch: false }
	);

	// updates order items and total price with help of calculation logic on frontend
	updateOrderPrice$ = createEffect(() => {
		return this._actions$.pipe(
			ofType(UpdateOrderPrice),
			switchMap(() => {
				return this._store.select(getCurrentOrder).pipe(
					combineLatestWith(this._configurationFacade.getBigData(), this._configurationFacade.getAvailability()),
					take(1),
					map(([order, bigData, availability]: [IOrderCompleteData, IBKBigData, AVAILABLE_JSON]) => {
						let isErrorInPriceUpdate: boolean = false;
						const updatedOrder: IOrderCompleteData = clone(order);
						const updatedOrderContent: IBKItemInOrderBase[] = updateOrderItemsPrice(updatedOrder, bigData, availability);

						if (updatedOrderContent) {
							updatedOrder.orderContent = updatedOrderContent;
							updatedOrder.orderTotal = updateOrderTotalPrice(updatedOrder, bigData);
						} else {
							isErrorInPriceUpdate = true;
							console.warn(`[APP]: Order ${updatedOrder.orderUUID}. Error in price update`);
						}
						if (updatedOrder.orderContent.some((item) => isMenuPriceNegative(item))) {
							updatedOrder.orderContent = updatedOrder.orderContent.filter((item) => !isMenuPriceNegative(item));
							updatedOrder.orderTotal = updateOrderTotalPrice(updatedOrder, bigData);
						}
						return isErrorInPriceUpdate
							? UpdateOrderPriceFail({ orderContent: updatedOrder.orderContent, orderTotal: updatedOrder.orderTotal })
							: UpdateOrderPriceSuccess({ orderContent: updatedOrder.orderContent, orderTotal: updatedOrder.orderTotal });
					}),
					catchError(() => {
						return of(RequestFailure());
					})
				);
			})
		);
	});

	// updates order items and total price with fiscal core prices
	updateOrderWithFiscalPrice$ = createEffect(() => {
		let orderFiscalResArrived$: Subject<void> = new Subject<void>();
		return this._actions$.pipe(
			ofType(UpdateOrderWithFiscalPrice),
			// throttles subsequent update calls until a fiscal core response for currently processed update is received,
			throttle(() => orderFiscalResArrived$, { leading: true, trailing: true }),
			switchMap(() => {
				// get all the necessary data for price update
				return this._store.select(getCurrentOrder).pipe(
					combineLatestWith(this._configurationFacade.getBigData(), this._configurationFacade.getAvailability()),
					take(1),
					switchMap(([order, bigData, availability]: [IOrderCompleteData, IBKBigData, AVAILABLE_JSON]) => {
						const productFamilies = new ProductFamilies(bigData.productFamilies);
						productFamilies.addProductsToFamily(bigData.products);
						const excludedData: Record<string, IExcludedDiscountItems> = getExcludedItemsFromGlobalDiscounts(
							bigData,
							productFamilies.allFamilies,
							order.orderContent,
							order.orderDiscount
						);

						// recalculate order prices for correct creation of csi order
						let isErrorInPriceUpdate: boolean = false;
						const declaredOrder: IOrderCompleteData = clone(order);
						if (keys(excludedData).length > 0) {
							declaredOrder.excludedDiscountItems = excludedData;
						}
						const updatedOrderContent: IBKItemInOrderBase[] = updateOrderItemsPrice(declaredOrder, bigData, availability);

						if (updatedOrderContent) {
							declaredOrder.orderContent = updatedOrderContent;
							declaredOrder.orderTotal = updateOrderTotalPrice(declaredOrder, bigData);
						} else {
							isErrorInPriceUpdate = true;
							console.warn(`[APP]: Order ${declaredOrder.orderUUID}. Error in price update before declaration.`);
						}
						if (declaredOrder.orderContent.some((item) => isMenuPriceNegative(item))) {
							declaredOrder.orderContent = declaredOrder.orderContent.filter((item) => !isMenuPriceNegative(item));
							declaredOrder.orderTotal = updateOrderTotalPrice(declaredOrder, bigData);
						}
						return this._shar3dClientService.getNextORB(declaredOrder).pipe(
							switchMap((orb: number) => {
								declaredOrder.orb = orb;
								return this._clerkService.publishOrderTowardsClerk(declaredOrder).pipe(
									tap(() => orderFiscalResArrived$.next()),
									map((data: DeclaredOrderResponse) => {
										declaredOrder.csiOrder = +data.response?.[0] || 0;
										declaredOrder.csiSrc = data.response?.[1].toString();
										declaredOrder.csiDeclaredTime = Date.now();
										const updatedOrderContentAfterDecl: IBKItemInOrderBase[] = updateOrderItemsPrice(
											declaredOrder,
											bigData,
											availability,
											data.orderDetail
										);
										if (updatedOrderContentAfterDecl) {
											declaredOrder.orderContent = updatedOrderContentAfterDecl;
											declaredOrder.orderTotal = updateOrderTotalPrice(declaredOrder, bigData, data.orderDetail);
										} else {
											isErrorInPriceUpdate = true;
											console.warn(`[APP]: Order ${declaredOrder.orderUUID}. Error in price update after declaration.`);
										}
										return isErrorInPriceUpdate
											? UpdateOrderWithFiscalPriceFail({ order: declaredOrder, orderDetail: data.orderDetail })
											: UpdateOrderWithFiscalPriceSuccess({ order: declaredOrder, orderDetail: data.orderDetail });
									})
								);
							}),
							catchError((err) => {
								orderFiscalResArrived$.next();
								`[APP]: Order ${declaredOrder.orderUUID}. Error in order price update.`
								return of(UpdateOrderWithFiscalPriceFail({ order: declaredOrder, orderDetail: null }));
							})
						);
					})
				);
			})
		);
	});

	cleanOrderState$ = createEffect(() => {
		return this._actions$.pipe(
			ofType(CleanOrderState),
			switchMap(() => this._store.select(getCurrentOrder).pipe(take(1))),
			switchMap((order: IOrderCompleteData) => {
				if (shouldPublishCancelOrder(order)) {
					this._messageSenderService.sendMessage(MESSAGE_TOPICS.TO_BK_GROUP, {
						bktype: BKMessageConstants.REMOTE_MESSAGE_BASKET_UPDATE,
						orderUUID: order.orderUUID,
						state: BKOrderState.ORDER_STATE_DISMISSED,
					});
					return this._clerkService.cancelOrder().pipe(take(1));
				}
				return of(true);
			}),
			switchMap((result) => (result ? of(CleanOrderStateSuccess()) : of(RequestFailure()))),
			catchError(() => {
				return of(RequestFailure());
			})
		);
	});
}
