import { IBKProductBase, IBKPublishedOrderData } from "@bk/jscommondatas";

export function patchOrderContentHiddenProducts(order: IBKPublishedOrderData, $productsById: { [id: string]: IBKProductBase}): void {
	order.orderContent = order?.orderContent?.filter((productInOrder) => {
		if(productInOrder.selection?.length > 0) {
			productInOrder.selection = productInOrder.selection.filter((menuItem) => !$productsById[menuItem.id]?.noPreparation);
			return productInOrder.selection.length > 0;
		}

		return !$productsById[productInOrder.id]?.noPreparation;
	}) || [];
}