import {
	BKCampaignPriceAdjustMethodEnum,
	BKCommonCloneUtilities,
	BKDeliveryModeEnum,
	BKDiscountTypeEnum,
	IBKBigData,
	IBKCampaignAllPrices,
	IBKCampaignPriceAdjust,
	IBKDiscountData,
	IBKIngredientData,
	IBKItemInOrderBase,
	IBKItemPriceInfo,
	IBKMenuBase,
	IBKProductBase,
	IBKProductFamilyData,
	IBKProductIngredientSelectedData,
	VatValues_ForLegacy,
} from '@bk/jscommondatas';
import {
	convertGlobalPriceToLocalPrice,
	DeclaredOrderDetail,
	getApplicablePriceForSalesChannel,
	getItemVat,
	getLocalPriceDefinitions,
	getLocalPriceDefinitionSchemaPropertyName,
	GlobalPriceDefinition,
	isAlignedVatUsable,
	isItemIncludedInVatAlignment,
	ItemProdFamily,
	LocalPriceDefinition,
	LocalPriceDefinitionPerSalesChannel,
	LocalPriceDefinitionSchema,
	mapDeliveryModeToSalesChannelOption,
	MENU,
	MenuSize,
	mergeLocalPriceDefinitions,
	PRODUCT,
	ProductPriceType,
	SalesChannelOption,
	updatePriceWithVat,
} from '@bk/price-management-common';
import { AVAILABLE_JSON } from '@libs/shared/models';
import { round, round5 } from '@merim/utils';
import { IOrderCompleteData } from '@shared/models';
import { clone } from 'ramda';
import { getProductFamily } from './big-data.functions';
import { getActiveCampaignsContents, isOrderItemMenu } from './order-items.functions';

// updates prices of items placed in order
export function updateOrderItemsPrice(
	order: IOrderCompleteData,
	bigData: IBKBigData,
	availability: AVAILABLE_JSON,
	declaredOrderDetail: DeclaredOrderDetail = undefined
): IBKItemInOrderBase[] {
	let isErrorInUpdateItemsPrice: boolean = false;
	const orderContent: IBKItemInOrderBase[] = clone(order.orderContent);
	const activeCampaignsDaypartPrices: IBKCampaignAllPrices = getActiveCampaignsContents(bigData, availability, order.source).daypartPrices;

	orderContent.forEach((itemInOrder) => {
		// item is a menu
		if (isOrderItemMenu(itemInOrder)) {
			// update items alacarte price based on new price management values
			const aLaCartePrice: IBKItemPriceInfo = getNewPriceManagementPriceInfo(
				bigData.menus?.[itemInOrder.id],
				itemInOrder.large ? MenuSize.L : MenuSize.M,
				order.deliveryMode,
				availability.priceDefinitions.menu?.[itemInOrder.id]
			);

			if (aLaCartePrice) {
				itemInOrder.aLaCartePrice = aLaCartePrice;
			} else {
				console.log(`[APP]: Order ${order.orderUUID}. Error in calculating price for menu ${itemInOrder.id} in price update.`);
				isErrorInUpdateItemsPrice = true;
			}

			const menuDayPartPrice: number = getItemDayPartPrice(
				itemInOrder.id,
				itemInOrder.aLaCartePrice.ttc,
				activeCampaignsDaypartPrices.active,
				activeCampaignsDaypartPrices.menus,
				itemInOrder.large
			);

			if (menuDayPartPrice && menuDayPartPrice >= 0) {
				updatePriceTTC(itemInOrder.aLaCartePrice, menuDayPartPrice);
			}

			itemInOrder.price = updateMenuPrice(itemInOrder, order.deliveryMode, bigData, availability, activeCampaignsDaypartPrices);

			if (!itemInOrder.price) {
				console.log(`[APP]: Order ${order.orderUUID}. Error in calculating item prices of menu ${itemInOrder.id}.`);
				isErrorInUpdateItemsPrice = true;
			}

			// item is a product
		} else {
			// update items alacarte price based on new price management values
			const aLaCartePrice: IBKItemPriceInfo = getNewPriceManagementPriceInfo(
				bigData.products?.[itemInOrder.id],
				ProductPriceType.Solo,
				order.deliveryMode,
				availability.priceDefinitions.product?.[itemInOrder.id]
			);

			if (aLaCartePrice) {
				itemInOrder.aLaCartePrice = aLaCartePrice;
			} else {
				console.log(`[APP]: Order ${order.orderUUID}. Error in calculating price for product ${itemInOrder.id} in price update.`);
				isErrorInUpdateItemsPrice = true;
			}

			const productDayPartPrice: number = getItemDayPartPrice(
				itemInOrder.id,
				itemInOrder.aLaCartePrice.ttc,
				activeCampaignsDaypartPrices.active,
				activeCampaignsDaypartPrices.products
			);

			if (productDayPartPrice && productDayPartPrice >= 0) {
				updatePriceTTC(itemInOrder.aLaCartePrice, productDayPartPrice);
			}

			if (itemInOrder?.recipe) {
				itemInOrder.extraPrice = getProductExtraPrice(itemInOrder.aLaCartePrice, itemInOrder.recipe, order.deliveryMode);
			}

			const supplementMenuPrice: IBKItemPriceInfo = getNewPriceManagementPriceInfo(
				bigData.products?.[itemInOrder.id],
				ProductPriceType.SupplementMenu,
				order.deliveryMode,
				availability.priceDefinitions.product?.[itemInOrder.id]
			);

			const supplementMenuDayPartPrice: number = getItemDayPartPrice(
				itemInOrder.id,
				itemInOrder.aLaCartePrice.ttc,
				activeCampaignsDaypartPrices.active,
				activeCampaignsDaypartPrices.productsExtras
			);

			itemInOrder.priceExtraMenuTTC =
				supplementMenuDayPartPrice ??
				supplementMenuPrice?.ttc ??
				itemInOrder?.priceExtraMenuTTC ??
				bigData.products?.[itemInOrder.id]?._priceExtraMenuTTC ??
				0;

			const inSuggestionPrice: IBKItemPriceInfo = getNewPriceManagementPriceInfo(
				bigData.products?.[itemInOrder.id],
				ProductPriceType.InSuggestion,
				order.deliveryMode,
				availability.priceDefinitions.product?.[itemInOrder.id]
			);

			itemInOrder.priceSuggestionMenuTTC =
				inSuggestionPrice.ttc ??
				itemInOrder?.priceSuggestionMenuTTC ??
				bigData.products?.[itemInOrder.id]?._priceSuggestionMenuTTC ??
				0;

			itemInOrder.price = updateProductPrice(itemInOrder, order.deliveryMode);
		}

		// if fiscal core prices are present update items price with it
		if (declaredOrderDetail && declaredOrderDetail?.computationDetail?.productDetail?.[itemInOrder?.lineuuid]) {
			itemInOrder.price.ttc = declaredOrderDetail.computationDetail.productDetail[itemInOrder?.lineuuid].amountInclTaxes / 100;
		}
	});

	return isErrorInUpdateItemsPrice ? undefined : orderContent;
}

// Updated total price value of an order 1
export function updateOrderTotalPrice(
	order: IOrderCompleteData,
	bigData: IBKBigData,
	declaredOrderDetail: DeclaredOrderDetail = undefined
): IBKItemPriceInfo {
	let total: IBKItemPriceInfo = {
		ht: 0,
		tva: 0,
		ttc: 0,
		pc: 0,
	};

	//  calculate order total price
	order.orderContent.forEach((itemInOrder) => {
		total = addPriceToTotal(total, itemInOrder.price, itemInOrder.qty);
	});

	//  Now compute the reduction
	if (order.orderDiscount.length > 0) {
		order.orderDiscount.forEach((discount) => {
			applyDiscountToOrderPrice(total, order.orderContent, discount, bigData);
		});
	}

	// if fiscal core prices are present update total price with it
	if (declaredOrderDetail && declaredOrderDetail?.computationDetail?.amountIncludingTax) {
		total.ttc = declaredOrderDetail.computationDetail.amountIncludingTax / 100;
	}

	return total;
}

/**
 * Update the product item price in order
 */
export function updateProductPrice(
	itemInOrder: IBKItemInOrderBase,
	deliveryMode: BKDeliveryModeEnum,
	alignedVat: number = undefined
): IBKItemPriceInfo {
	// get basic price value of a product
	let priceInfo: IBKItemPriceInfo = getItemPrice(itemInOrder.aLaCartePrice, deliveryMode);

	// recalculate basic price value of a product when it should have aligned VAT value
	if (alignedVat) {
		priceInfo = updatePriceWithVat(priceInfo, alignedVat);
	}

	// apply existing discounts before extra price is added to total
	if (itemInOrder?.itemAppliedDiscount?.length) {
		itemInOrder.itemAppliedDiscount.forEach((discount: IBKDiscountData) => {
			applyDiscountBeforeExtraToProductPrice(priceInfo, discount);
		});
	}

	// add extra price if exists (usually present when products recipe is altered)
	if (itemInOrder.extraPrice.ttc > 0) {
		priceInfo = addPriceToTotal(priceInfo, itemInOrder.extraPrice, 1);
	}

	// apply another type of discounts after extra price is added
	if (itemInOrder?.itemAppliedDiscount?.length) {
		itemInOrder?.itemAppliedDiscount?.forEach((discount: IBKDiscountData) => {
			if (discount.discountType === BKDiscountTypeEnum.PRODUCT_AND_EXTRA_DISCOUNT) {
				applyDiscountAfterExtraToProductPrice(priceInfo, discount);
			}
		});
	}

	//  Ensure the price is not negative
	if (priceInfo.ttc < 0 && itemInOrder.qty > 0) {
		priceInfo = {
			ht: 0,
			tva: 0,
			ttc: 0,
			pc: priceInfo.pc,
		};
	}
	return priceInfo;
}

/**
 * Update the menu item price in order
 */
export function updateMenuPrice(
	menuInOrder: IBKItemInOrderBase,
	deliveryMode: BKDeliveryModeEnum,
	bigData: IBKBigData,
	availability: AVAILABLE_JSON,
	activeCampaignsDaypartPrices: IBKCampaignAllPrices
): IBKItemPriceInfo {
	let priceInfo: IBKItemPriceInfo = BKCommonCloneUtilities.cloneIBKItemPriceInfo(menuInOrder.aLaCartePrice);

	let totalALaCarte: number = 0;
	let compositeVATpc: number = 0;
	let highestAlignedSelVat: number = 0;

	let isErrorInUpdateSelItemsPrice: boolean = false;

	// apply existing discounts before extra price is added to total
	if (menuInOrder?.itemAppliedDiscount?.length) {
		menuInOrder.itemAppliedDiscount.forEach((discount: IBKDiscountData) => {
			applyDiscountBeforeExtraToProductPrice(priceInfo, discount);
		});
	}

	// Compute a-la-carte prices
	menuInOrder.selection.forEach((selection) => {
		if (!isOrderItemMenu(selection)) {
			// update items alacarte price based on new price management values
			const aLaCartePrice: IBKItemPriceInfo = getNewPriceManagementPriceInfo(
				bigData.products?.[selection.id],
				ProductPriceType.Solo,
				deliveryMode,
				availability.priceDefinitions.product?.[selection.id]
			);

			if (aLaCartePrice) {
				selection.aLaCartePrice = aLaCartePrice;
			} else {
				isErrorInUpdateSelItemsPrice = true;
			}

			const selectionDayPartPrice: number = getItemDayPartPrice(
				selection.id,
				selection.aLaCartePrice.ttc,
				activeCampaignsDaypartPrices.active,
				activeCampaignsDaypartPrices.products
			);

			if (selectionDayPartPrice && selectionDayPartPrice >= 0) {
				updatePriceTTC(selection.aLaCartePrice, selectionDayPartPrice);
			}
		}
	});

	highestAlignedSelVat = getHighestAlignedVat(menuInOrder.selection, deliveryMode, bigData);

	// Update selection prices
	menuInOrder.selection.forEach((selection) => {
		if (!isOrderItemMenu(selection)) {
			const aLaCarteVat: number = getItemVat(selection.aLaCartePrice, deliveryMode);
			const updateProdWithAlignedVat: boolean = isAlignedVatUsable(
				getItemProdFamilyData(selection, bigData),
				highestAlignedSelVat,
				aLaCarteVat
			);

			if (selection?.recipe) {
				selection.extraPrice = getProductExtraPrice(
					selection.aLaCartePrice,
					selection.recipe,
					deliveryMode,
					updateProdWithAlignedVat ? highestAlignedSelVat : undefined
				);
			}

			selection.price = updateProductPrice(selection, deliveryMode, updateProdWithAlignedVat ? highestAlignedSelVat : undefined);

			//  Add it to the total ALaCarte
			totalALaCarte += selection.price.ht; //sel.aLaCartePrice.ht;
		}
	});

	//  Clear the extra price
	menuInOrder.extraPrice = {
		ht: 0,
		tva: 0,
		ttc: 0,
		pc: menuInOrder.aLaCartePrice.pc,
	};

	//  Compute the composite TVA
	menuInOrder.selection.forEach((selection, index) => {
		if (!isOrderItemMenu(selection)) {
			let supplementMenuPrice: IBKItemPriceInfo;
			let suggestionMenuPrice: IBKItemPriceInfo;

			const aLaCarteVat: number = getItemVat(selection.aLaCartePrice, deliveryMode);
			const updateProdWithAlignedVat: boolean = isAlignedVatUsable(
				getItemProdFamilyData(selection, bigData),
				highestAlignedSelVat,
				aLaCarteVat
			);
			const selectionVat: number = updateProdWithAlignedVat ? highestAlignedSelVat : aLaCarteVat;

			// get supplement (extra) price val of a selection item from new price management
			if (isGlobalPriceDefinitionValid(bigData.products?.[selection.id], deliveryMode, ProductPriceType.SupplementMenu)) {
				supplementMenuPrice = getNewPriceManagementPriceInfo(
					bigData.products?.[selection.id],
					ProductPriceType.SupplementMenu,
					deliveryMode,
					availability.priceDefinitions.product?.[selection.id]
				);
				// update the priceExtraMenu value of a selection product
				if (supplementMenuPrice?.ttc) {
					selection.priceExtraMenuTTC = supplementMenuPrice.ttc;
				}
			}

			const supplementMenuDayPartPrice: number = getItemDayPartPrice(
				selection.id,
				selection.priceExtraMenuTTC,
				activeCampaignsDaypartPrices.active,
				activeCampaignsDaypartPrices.productsExtras
			);

			if (supplementMenuDayPartPrice && supplementMenuDayPartPrice >= 0) {
				selection.priceExtraMenuTTC = supplementMenuDayPartPrice;
			}

			// get suggestion price val of a selection item from new price management
			if (isGlobalPriceDefinitionValid(bigData.products?.[selection.id], deliveryMode, ProductPriceType.InSuggestion)) {
				suggestionMenuPrice = getNewPriceManagementPriceInfo(
					bigData.products?.[selection.id],
					ProductPriceType.InSuggestion,
					deliveryMode,
					availability.priceDefinitions.product?.[selection.id]
				);

				if (suggestionMenuPrice?.ttc) {
					selection.priceSuggestionMenuTTC = suggestionMenuPrice.ttc;
				}
			}

			const priceExtraMenuTTC: number =
				supplementMenuDayPartPrice ??
				supplementMenuPrice?.ttc ??
				selection?.priceExtraMenuTTC ??
				bigData.products?.[selection.id]?._priceExtraMenuTTC ??
				0;

			selection.priceExtraMenuTTC = priceExtraMenuTTC;

			const priceSuggestionMenuTTC: number = suggestionMenuPrice?.ttc
				?? selection.priceSuggestionMenuTTC
				?? bigData.products?.[selection.id]?._priceSuggestionMenuTTC
				?? 0;

			selection.priceSuggestionMenuTTC = priceSuggestionMenuTTC;

			// Check for a price for suggestion menu
			if (index >= bigData.menus?.[menuInOrder.id]._steps.length) {
				if (priceSuggestionMenuTTC && priceSuggestionMenuTTC >= 0) {
					const priceSuggestionTva = round5((priceSuggestionMenuTTC / (100 + selectionVat)) * selectionVat);
					const emprice: IBKItemPriceInfo = {
						ttc: priceSuggestionMenuTTC,
						pc: selectionVat,
						tva: priceSuggestionTva,
						ht: priceSuggestionMenuTTC - priceSuggestionTva,
					};
					menuInOrder.extraPrice = addPriceToTotal(menuInOrder.extraPrice, emprice, 1);
				}
			} else if (priceExtraMenuTTC !== 0) {
				const priceExtraTva = round5((priceExtraMenuTTC / (100 + selectionVat)) * selectionVat);
				const emprice: IBKItemPriceInfo = {
					ttc: priceExtraMenuTTC,
					pc: selectionVat,
					tva: priceExtraTva,
					ht: priceExtraMenuTTC - priceExtraTva,
				};
				menuInOrder.extraPrice = addPriceToTotal(menuInOrder.extraPrice, emprice, 1);
			}

			//  Update the composite TVA
			compositeVATpc += (selection.price.pc * selection.price.ht) / totalALaCarte;
		}
	});

	//  Fix the VAT
	priceInfo.ht = priceInfo.ttc / (1 + compositeVATpc / 100);
	priceInfo.tva = priceInfo.ttc - priceInfo.ht;

	//  Add the extra price
	priceInfo = addPriceToTotal(priceInfo, menuInOrder.extraPrice, 1);

	// apply another type of discounts after extra price is added
	if (menuInOrder?.itemAppliedDiscount?.length) {
		menuInOrder?.itemAppliedDiscount?.forEach((discount: IBKDiscountData) => {
			if (discount.discountType === BKDiscountTypeEnum.PRODUCT_AND_EXTRA_DISCOUNT) {
				applyDiscountAfterExtraToProductPrice(priceInfo, discount);
			}
		});
	}
	//  Ensure the price is not negative
	if (priceInfo.ttc < 0 && menuInOrder.qty > 0) {
		priceInfo = {
			ht: 0,
			tva: 0,
			ttc: 0,
			pc: priceInfo.pc,
		};
	}

	return isErrorInUpdateSelItemsPrice ? undefined : priceInfo;
}

/**
 * Get price value for added extras to product
 */
export function getProductExtraPrice(
	aLaCartePrice: IBKItemPriceInfo,
	recipe: IBKProductIngredientSelectedData[] = undefined,
	deliveryMode: BKDeliveryModeEnum,
	alignedVat: number = undefined
): IBKItemPriceInfo {
	let total = 0;
	const itemVAT: number = alignedVat || getItemVat(aLaCartePrice, deliveryMode);
	//  Check for a recipe
	if (recipe?.length > 0) {
		recipe.forEach((sd: IBKProductIngredientSelectedData) => {
			if (sd.priceTTC > 0) {
				total += Math.max(0, sd.qty - sd.initQty) * sd.priceTTC;
			}
		});
	}

	const vatAmount: number = round5((total / (100 + itemVAT)) * itemVAT);

	return {
		ttc: total,
		pc: itemVAT,
		tva: vatAmount,
		ht: total - vatAmount,
	};
}

/**
 * Get price for an item without any discount
 */
export function getItemPrice(aLaCartePrice: IBKItemPriceInfo, deliveryMode: BKDeliveryModeEnum): IBKItemPriceInfo {
	const itemPrice = {
		ht: aLaCartePrice.ht,
		tva: aLaCartePrice.tva,
		ttc: aLaCartePrice.ttc,
		pc: aLaCartePrice.pc,
	};

	if (deliveryMode !== BKDeliveryModeEnum.DELIVERY_LOCAL && (aLaCartePrice?.htto || aLaCartePrice?.pcto)) {
		const htto: number = aLaCartePrice?.htto;
		const pcto: number = aLaCartePrice?.pcto;
		itemPrice.ht = aLaCartePrice?.htto && aLaCartePrice?.htto >= 0 ? htto : aLaCartePrice.ht;
		itemPrice.pc = aLaCartePrice?.pcto && aLaCartePrice?.pcto >= 0 ? pcto : aLaCartePrice.pc;
	}

	return itemPrice;
}

/**
 * Add a price value to total price value
 */
export function addPriceToTotal(totalPrice: IBKItemPriceInfo, toAddPrice: IBKItemPriceInfo, quantity: number): IBKItemPriceInfo {
	return {
		ht: totalPrice.ht + quantity * toAddPrice.ht,
		tva: totalPrice.tva + quantity * toAddPrice.tva,
		ttc: round5(totalPrice.ttc + quantity * toAddPrice.ttc),
		pc: totalPrice.pc || toAddPrice.pc,
		pcto: totalPrice?.pcto,
		htto: totalPrice?.htto,
	};
}

export function updatePriceTTC(target: IBKItemPriceInfo, newTTC: number): void {
	target.ttc = newTTC;
	target.tva = round5((newTTC / (100 + target.pc)) * target.pc);
	target.ht = target.ttc - target.tva;
}

/** Discount functions **/

/**
 * Apply a discount before extras have been added
 */
export function applyDiscountBeforeExtraToProductPrice(price: IBKItemPriceInfo, discount: IBKDiscountData): void {
	switch (discount.discountType) {
		case BKDiscountTypeEnum.PRODUCT_DISCOUNT:
		case BKDiscountTypeEnum.PRODUCT_EXCLUSIVE:
			if (discount.discountInPercent) {
				discountPercent(price, discount.discountValue);
			} else {
				discountFixed(price, discount.discountValue);
			}
			break;
		case BKDiscountTypeEnum.PRODUCT_SINGLE_PRICE:
			updatePriceTTC(price, discount.discountValue);
			break;
		case BKDiscountTypeEnum.PRODUCT_AND_EXTRA_DISCOUNT:
			// Nothing to do, but this is not an error
			break;
		default:
			console.log('[DISCOUNT ERROR] : This type (' + discount.discountType + ') can not be apply to a product');
			break;
	}
}

export function discountPercent(price: IBKItemPriceInfo, percent: number) {
	price.ttc = Math.max(0, price.ttc - Math.round(price.ttc * Math.abs(percent)) / 100);
	price.tva = round5((price.ttc / (100 + price.pc)) * price.pc);
	price.ht = price.ttc - price.tva;
}

export function discountFixed(price: IBKItemPriceInfo, amount: number) {
	price.ttc = Math.ceil(round5(100 * Math.max(0, price.ttc - Math.abs(amount)))) / 100;
	price.tva = round5((price.ttc / (100 + price.pc)) * price.pc);
	price.ht = price.ttc - price.tva;
}

/**
 * Apply a discount after extras have been added
 */
export function applyDiscountAfterExtraToProductPrice(price: IBKItemPriceInfo, discount: IBKDiscountData): void {
	if (discount.discountType !== BKDiscountTypeEnum.PRODUCT_AND_EXTRA_DISCOUNT) {
		return;
	}
	if (discount.discountInPercent) {
		discountPercent(price, discount.discountValue);
	} else {
		discountFixed(price, discount.discountValue);
	}
}

/**
 * Apply a discount to order
 */
export function applyDiscountToOrderPrice(
	totalPrice: IBKItemPriceInfo,
	orderContent: IBKItemInOrderBase[],
	discount: IBKDiscountData,
	bigData: IBKBigData
): void {
	switch (discount.discountType) {
		case BKDiscountTypeEnum.ORDER_DISCOUNT:
			if (discount.discountInPercent) {
				discountPercentEx(totalPrice, orderContent, discount.discountValue, bigData);
			} else {
				discountFixed(totalPrice, discount.discountValue);
			}
			break;
		default:
			console.log('[DISCOUNT ERROR] : This type (' + discount.discountType + ') can not be apply to an order');
			break;
	}
}

export function discountPercentEx(price: IBKItemPriceInfo, content: IBKItemInOrderBase[], percent: number, bigData: IBKBigData): void {
	let totalDiscountAmount: IBKItemPriceInfo = { ttc: 0, tva: 0, ht: 0, pc: 0 };
	content.forEach((item) => {
		if (!bigData.products?.[item.id]?._excludeFromGlobalDiscount) {
			const discountAmountForItem: IBKItemPriceInfo = {
				ht: Math.round(100 * item.qty * item.price.ht * percent),
				tva: Math.round(100 * item.qty * item.price.tva * percent),
				ttc: Math.round(100 * item.qty * item.price.ttc * percent),
				pc: item.price.pc,
			};
			totalDiscountAmount = addPriceToTotal(totalDiscountAmount, discountAmountForItem, 1);
		}
	});

	const discount: IBKItemPriceInfo = {
		ttc: Math.round(totalDiscountAmount.ttc / 100) / 100,
		tva: round5(totalDiscountAmount.tva / 10000),
		ht: 0,
		pc: totalDiscountAmount.pc,
	};
	discount.ht = discount.ttc - discount.tva;

	console.log('discount is', discount);
	// remove discount amount from total price
	price.ht = price.ht - discount.ht;
	price.tva = price.tva - discount.tva;
	price.ttc = round5(price.ttc - discount.ttc);
}

/** New price management functions **/

/**
 * Creates item price from supplied sales channel option price value and vat
 */
export function getNewPriceManagementPriceInfo(
	item: IBKProductBase | IBKMenuBase,
	priceType: MenuSize | ProductPriceType,
	deliveryMode: BKDeliveryModeEnum,
	localPriceDefinition?: LocalPriceDefinition
): IBKItemPriceInfo {
	const salesChannel: SalesChannelOption = mapDeliveryModeToSalesChannelOption(deliveryMode);
	const salesChannelPriceTtc: number = getGlobalPriceDefinitionPrice(item, deliveryMode, priceType);
	const vatValue: VatValues_ForLegacy = getVatValue(item?.vatValues, deliveryMode);

	if (!salesChannel) {
		console.warn(`[APP]: Order price update: Could not get a sales channel for ${item.sysName}`);
		return undefined;
	}

	let itemPriceInfo: IBKItemPriceInfo = {
		ht: 0,
		tva: 0,
		ttc: 0,
		pc: 0,
	};

	if (salesChannelPriceTtc && vatValue) {
		const takeAwayVat = vatValue.tvaName !== SalesChannelOption.EatIn ? vatValue.percent : undefined;
		itemPriceInfo = calculateItemPriceInfoFromTtcAndVat(salesChannelPriceTtc, vatValue.percent, takeAwayVat);
	}

	if (salesChannelPriceTtc === undefined && priceType !== ProductPriceType.InSuggestion && priceType !== ProductPriceType.SupplementMenu) {
		console.warn(`[APP]: Order price update: Failed to get '${salesChannel}' price value for ${item.sysName}`);
		return undefined;
	}

	if (vatValue === undefined) {
		console.warn(`[APP]: Order price update: Failed to get vat value for ${item.sysName}`);
		return undefined;
	}

	// Search for local price definitions and overwrite price if found
	if (localPriceDefinition && localPriceDefinition?.values?.length > 0 && vatValue) {
		const relevantPriceDefinition = localPriceDefinition.values.find(
			(priceDefinition) => priceDefinition.rowIdentifier === priceType.toString() && priceDefinition.salesChannelOption === salesChannel
		);
		/** If we found correct price definition, populate the response object */
		if (relevantPriceDefinition && relevantPriceDefinition.localPrice) {
			itemPriceInfo = calculateItemPriceInfoFromTtcAndVat(
				relevantPriceDefinition.localPrice,
				vatValue.percent,
				salesChannel !== SalesChannelOption.EatIn ? vatValue.percent : undefined
			);
		}
	}

	// Make sure VAT rates (pc and pcto) are always populated
	if (itemPriceInfo.pc === 0 && vatValue?.percent >= 0) {
		itemPriceInfo.pc = vatValue?.percent;
	}
	if (itemPriceInfo?.pcto && itemPriceInfo.pcto === 0 && vatValue?.percent >= 0) {
		itemPriceInfo.pcto = vatValue.percent;
	}

	return itemPriceInfo;
}

/**
 * Get price from global price definitions
 */
export function getGlobalPriceDefinitionPrice(
	item: IBKProductBase | IBKMenuBase,
	deliveryMode: BKDeliveryModeEnum,
	rowIdentifier: MenuSize | ProductPriceType
): number {
	const salesChannel = mapDeliveryModeToSalesChannelOption(deliveryMode);
	return item?.globalPriceDefinitions?.find((priceDefinition) => {
		return (
			priceDefinition.salesChannelOption === salesChannel &&
			priceDefinition.rowIdentifier === rowIdentifier &&
			priceDefinition?.price >= 0 &&
			!priceDefinition.isDisabled
		);
	})?.price;
}

/**
 * Checks if global price definition for an item is valid
 */
export function isGlobalPriceDefinitionValid(
	item: IBKProductBase | IBKMenuBase,
	deliveryMode: BKDeliveryModeEnum,
	rowIdentifier: MenuSize | ProductPriceType
): boolean {
	const salesChannel = mapDeliveryModeToSalesChannelOption(deliveryMode);
	return !!item?.globalPriceDefinitions?.find((priceDefinition) => {
		return (
			priceDefinition.salesChannelOption === salesChannel &&
			priceDefinition.rowIdentifier === rowIdentifier &&
			priceDefinition?.price >= 0 &&
			!priceDefinition.isDisabled
		);
	});
}

/**
 * Get local price from availability price definitions
 */
export function getLocalPriceDefinitionPrice(
	item: IBKProductBase | IBKMenuBase,
	deliveryMode: BKDeliveryModeEnum,
	rowIdentifier: MenuSize | ProductPriceType,
	availability: AVAILABLE_JSON
): number {
	const priceDefinitionSchema: keyof LocalPriceDefinitionSchema =
		rowIdentifier === MenuSize.L || rowIdentifier === MenuSize.M
			? getLocalPriceDefinitionSchemaPropertyName(MENU)
			: getLocalPriceDefinitionSchemaPropertyName(PRODUCT);
	const salesChannel: SalesChannelOption = mapDeliveryModeToSalesChannelOption(deliveryMode);

	return availability?.priceDefinitions?.[priceDefinitionSchema]?.[item.id]?.values?.find((priceDefinition) => {
		return (
			priceDefinition.salesChannelOption === salesChannel &&
			priceDefinition.rowIdentifier === rowIdentifier &&
			!priceDefinition.isDisabled &&
			priceDefinition.localPrice >= 0
		);
	})?.localPrice;
}

/**
 * Get correct vat for order item
 */
export function getVatValue(vatValues: VatValues_ForLegacy[], deliveryMode: BKDeliveryModeEnum): VatValues_ForLegacy {
	const salesChannel = mapDeliveryModeToSalesChannelOption(deliveryMode);
	return vatValues?.find((value) => value.tvaName === salesChannel);
}

/**
 * Creates item price info object based on provided ttc price and vat value
 */
export function calculateItemPriceInfoFromTtcAndVat(priceTTC: number, vat: number, vatTakeAway?: number): IBKItemPriceInfo {
	const itemPriceInfo: IBKItemPriceInfo = {
		ttc: priceTTC,
		pc: vat,
		tva: 0,
		ht: 0,
		pcto: undefined,
		htto: undefined,
	};

	// Calculate total VAT value
	const taxTotal = (priceTTC / (100 + vat)) * vat;
	// Round to 5 decimal points
	itemPriceInfo.tva = parseFloat(taxTotal.toFixed(5));
	// Get price without tax
	const priceWithoutTax = priceTTC - itemPriceInfo.tva;
	// Round to 5 decimal point
	itemPriceInfo.ht = parseFloat(priceWithoutTax.toFixed(5));

	if (vatTakeAway) {
		itemPriceInfo.pcto = vatTakeAway;
		// Calculate total VAT value for TakeAway
		const taxTotalTo = (priceTTC / (100 + vatTakeAway)) * vatTakeAway;
		// Get price without tax
		const priceWithoutTaxTo = priceTTC - taxTotalTo;
		// Round to 5 decimal point
		itemPriceInfo.htto = parseFloat(priceWithoutTaxTo.toFixed(5));
	}

	return itemPriceInfo;
}

export function getProductPrice(product: IBKProductBase, salesChannelOption: SalesChannelOption, availability: AVAILABLE_JSON): number {
	const globalPrices = product.globalPriceDefinitions as GlobalPriceDefinition[];
	const localPrices = clone(getLocalPriceDefinitions('PRODUCT', salesChannelOption, availability, product.id));
	const defaultPrices = globalPrices
		.filter((gp) => gp.salesChannelOption === salesChannelOption)
		.map((gp) => convertGlobalPriceToLocalPrice(gp));

	const mergedPriceDefinitions: LocalPriceDefinitionPerSalesChannel[] = mergeLocalPriceDefinitions(
		defaultPrices,
		localPrices,
		globalPrices
	);

	const soloPrices = mergedPriceDefinitions.filter((p) => p.rowIdentifier === ProductPriceType.Solo);
	const price = getApplicablePriceForSalesChannel(soloPrices[0]);
	return price;
}

export function getMenuPrice(
	menu: IBKMenuBase,
	salesChannelOption: SalesChannelOption,
	menuSize: MenuSize,
	availability: AVAILABLE_JSON
): number {
	const globalPrices = menu.globalPriceDefinitions as GlobalPriceDefinition[];
	const localPrices = clone(getLocalPriceDefinitions('MENU', salesChannelOption, availability, menu.id));

	const defaultPrices = globalPrices
		.filter((gp) => gp.salesChannelOption === salesChannelOption)
		.map((gp) => convertGlobalPriceToLocalPrice(gp));

	// hack needed because Store returns immutable object
	const writeableLocalPrices = localPrices.map((lp) => {
		return { ...lp };
	});

	const mergedPriceDefinitions: LocalPriceDefinitionPerSalesChannel[] = mergeLocalPriceDefinitions(
		defaultPrices,
		localPrices,
		globalPrices
	);

	const soloPrices = mergedPriceDefinitions.filter((p) => p.rowIdentifier === menuSize);
	const price = getApplicablePriceForSalesChannel(soloPrices[0]);
	return price;
}

export function getIngredientPrice(item: IBKIngredientData, salesChannelOption: SalesChannelOption, availability: AVAILABLE_JSON): number {
	const oldPriceManagement: number = item.priceTTC;
	const globalPrice: number =
		item.globalPriceDefinitions?.find((priceDef) => priceDef.salesChannelOption === salesChannelOption)?.price || 0;
	let localPrice: number = undefined;

	if (item.globalPriceDefinitions?.length > 0 && availability.priceDefinitions.ingredient?.[item.id]) {
		localPrice = availability.priceDefinitions.ingredient?.[item.id].values.find(
			(ingredient) => ingredient.salesChannelOption === salesChannelOption
		)?.localPrice;
	} else {
		localPrice = availability.price.ingredients?.[item.id];
	}

	return localPrice || globalPrice || oldPriceManagement;
}

/*
 * Calculates items price based on items price (local price if available) and type of daypart price
 */
export function getItemDayPartPrice(
	itemId: number,
	currentItemPrice: number,
	isDayPartPricesActive: boolean,
	campaignPriceAdjusts: Record<string, IBKCampaignPriceAdjust>,
	isItemLarge: boolean = false
): number | undefined {
	if (!isDayPartPricesActive || currentItemPrice < 0) {
		return undefined;
	}
	let modifiedPrice: number = undefined;
	const itemPriceAdjust: IBKCampaignPriceAdjust = campaignPriceAdjusts?.[itemId];
	const globalPriceAdjust: IBKCampaignPriceAdjust = campaignPriceAdjusts?.['0'];
	/**
	 * The utilization of daypart price modification is reproduced from KIOSK and POS behavior
	 * from BKBigDatas.dispatchCampaignContent() method. Also the same behavior was utilized
	 * to calculate API availability from BigData and Raw Availability, so we consider this behavior
	 * to be the most correct.
	 *
	 * In conclusion, apply global modification only if there is no specific modification for item.
	 */
	if (itemPriceAdjust) {
		modifiedPrice = getPriceTtcWithDaypartModification(currentItemPrice, itemPriceAdjust, isItemLarge);
	} else if (globalPriceAdjust) {
		modifiedPrice = getPriceTtcWithDaypartModification(modifiedPrice || currentItemPrice, globalPriceAdjust, isItemLarge);
	}
	return modifiedPrice;
}

export function getPriceTtcWithDaypartModification(
	basePriceTtc: number,
	priceModification: IBKCampaignPriceAdjust,
	isItemLarge = false
): number | undefined {
	const adjustmentValue = isItemLarge ? priceModification.priceAdjustL : priceModification.priceAdjust;
	switch (priceModification.priceAdjustMethod) {
		case BKCampaignPriceAdjustMethodEnum.DELTA:
			return round(basePriceTtc + adjustmentValue, 2);
		case BKCampaignPriceAdjustMethodEnum.PERCENT:
			return round(basePriceTtc * ((100 + adjustmentValue) / 100), 2);
		case BKCampaignPriceAdjustMethodEnum.ABSOLUTE:
			return adjustmentValue;
		default:
			return undefined;
	}
}

export function isMenuPriceNegative(item: IBKItemInOrderBase): boolean {
	if (!item?.selection) {
		return false;
	}
	return (
		item.selection.some((menuItem) => item.aLaCartePrice.ttc + menuItem.priceExtraMenuTTC < 0) ||
		item.aLaCartePrice.ttc + item.extraPrice.ttc < 0
	);
}

export function getHighestAlignedVat(items: IBKItemInOrderBase[], deliveryMode: BKDeliveryModeEnum, bigData: IBKBigData): number {
	if (!items?.length) {
		return 0;
	}

	return items.reduce((highestVat: number, item: IBKItemInOrderBase) => {
		const itemProdFamilyData: ItemProdFamily = getItemProdFamilyData(item, bigData);

		if (isItemIncludedInVatAlignment(itemProdFamilyData)) {
			const priceKey =
				deliveryMode === BKDeliveryModeEnum.DELIVERY_LOCAL || !item.aLaCartePrice?.pcto || item.aLaCartePrice?.pcto <= 0 ? 'pc' : 'pcto';

			return item.aLaCartePrice[priceKey] > highestVat ? item.aLaCartePrice[priceKey] : highestVat;
		} else {
			return highestVat;
		}
	}, 0);
}

function getItemProdFamilyData(item: IBKItemInOrderBase, bigData: IBKBigData): ItemProdFamily {
	const itemProductFamilyData: ItemProdFamily = {
		_productFamily: null,
		_productSubFamily: null,
	};
	const productFamily: IBKProductFamilyData = getProductFamily(item, bigData);

	if (productFamily?.parentFamilyId <= 0) {
		itemProductFamilyData._productFamily = productFamily;
	} else if (productFamily?.parentFamilyId > 0) {
		itemProductFamilyData._productFamily = bigData.productFamilies?.[productFamily.parentFamilyId];
		itemProductFamilyData._productSubFamily = productFamily;
	}

	return itemProductFamilyData;
}
