/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PermissionDto } from './permission-dto';


export interface UserInfoDto { 
    /**
     * Id of the user assigned by MySQL.
     */
    id: number;
    /**
     * User\'s username utilized for login.
     */
    username: string;
    /**
     * User\'s first name.
     */
    firstName: string;
    /**
     * User\'s last name.
     */
    lastName: string;
    /**
     * User\'s role mapped from legacy integer.
     */
    role: UserInfoDto.RoleEnum;
    /**
     * List of permissions with values assigned to the user.
     */
    permissions: Array<PermissionDto>;
    /**
     * Legacy integer describing role. Ideally use new \"role\" property.
     */
    roleLegacy: number;
    /**
     * ???
     */
    schoolMode: boolean;
    /**
     * Flag indicating if the user account is active.
     */
    active: boolean;
    /**
     * ???
     */
    iCard?: string;
    /**
     * ???
     */
    rhId?: string;
}
export namespace UserInfoDto {
    export type RoleEnum = 'operator' | 'manager' | 'franchisee' | 'maintainer';
    export const RoleEnum = {
        Operator: 'operator' as RoleEnum,
        Manager: 'manager' as RoleEnum,
        Franchisee: 'franchisee' as RoleEnum,
        Maintainer: 'maintainer' as RoleEnum
    };
}


