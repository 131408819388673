import { BKDeliveryModeEnum, BKOrderSourceEnum, BKOrderState, BKOrderStateDetail, IBKMachine } from '@bk/jscommondatas';
import { UsersModel } from '@libs/shared/models';
import { Shar3dUtils } from '@shar3d/shar3d-utils';
import { IOrderCompleteData } from '@shared/models';
import { generateUUID } from './random-generator-functions';

export interface IGenerateNewOrder {
	user: UsersModel;
	manager: UsersModel;
	machine: IBKMachine;
	source: BKOrderSourceEnum;
	deliveryMode: BKDeliveryModeEnum;
}

export function generateNewOrder(data: IGenerateNewOrder): IOrderCompleteData {
	const now = new Date().getTime();
	return {
		user: data.user,
		manager: data.manager,
		machine: data.machine,
		orderUUID: generateUUID(),
		orderContent: [],
		orderDelContent: [],
		orderDelContentAfterDecl: [],
		orderDelContentAfterKitchenScreen: [],
		orderTotal: {
			ht: 0,
			tva: 0,
			ttc: 0,
			pc: 0,
		},
		deliveryMode: data.deliveryMode,
		state: undefined,
		statedetail: BKOrderStateDetail.ORDER_STATE_DETAIL_NONE,
		lineCounter: 0,
		source: data.source || null,
		creationdate: now,
		paidDate: 0,
		firstProductDate: 0,
		stateChangeDate: now,
		csiOrder: 0,
		csiSrc: '',
		csiDeclaredTime: 0,
		orb: 0,
		annotations: [],
		orderDiscount: [],
		event: [],
		orderGames: [],
		orderCoupons: [],
		getNextLineIndex: () => {
			return 0;
		},
		businessDay: Shar3dUtils.getBusinessday(now),
	};
}

export function shouldPublishCancelOrder(order: IOrderCompleteData): boolean {
	if (!order.csiOrder) {
		return false;
	}
	if (order.state >= BKOrderState.ORDER_STATE_PAID) {
		return false;
	}
	if (order.statedetail === BKOrderStateDetail.ORDER_STATE_DETAIL_PAYING_DELAYED) {
		return false;
	}
	return true;
}
