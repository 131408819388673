import { clone } from 'ramda';

export class ApiConfigurationModel {
	path: string = '';
	credentials: ApiCredentials = new ApiCredentials();

	constructor(init?: Partial<ApiConfigurationModel>) {
		Object.assign(this, clone(init));
	}
}

export class ApiCredentials {
	username: string = '';
	password: string = '';

	constructor(init?: Partial<ApiCredentials>) {
		Object.assign(this, clone(init));
	}
}
