/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CampaignDaypartPriceDto } from './campaign-daypart-price-dto';


export interface CampaignLocalPricesDto { 
    /**
     * Products\' regular daypart prices.
     */
    daypartProductPrices?: Array<CampaignDaypartPriceDto>;
    /**
     * Products\' extra menu daypart prices.
     */
    daypartProductExtraPrices?: Array<CampaignDaypartPriceDto>;
    /**
     * Menus\' daypart prices.
     */
    daypartMenuPrices?: Array<CampaignDaypartPriceDto>;
    /**
     * Ingredients\' daypart prices.
     */
    daypartIngredientPrices?: Array<CampaignDaypartPriceDto>;
}

