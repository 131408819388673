import { IBKProductBase } from "../myBKCommonDatas";
import { BKMenuStepTypeEnum } from "../myBKCommonEnums";

export type MenuStepType = {
	stepNumber: number;
	stepType: BKMenuStepTypeEnum;
	stepLabel: {
		[langKey: string]: string
	};
	availableProducts: IBKProductBase[];
	selectedProduct: IBKProductBase | undefined;
	defaultProduct: IBKProductBase | undefined;
}
