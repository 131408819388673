import { addDays, getDateAs_YYYY_MM_DD, treatTimeString } from './date-utils';

/**
 * Converts Date() object to equivalent business day YYYY-MM-DD string based on set
 * business day start time.
 * @param localDate
 * @param businessDayStartTime
 * @returns
 */
export function getBusinessDay(localDate: Date = new Date(), businessDayStartTime: string = '00:00'): string {
	const businessDay = getBusinessDayAsDate(localDate, businessDayStartTime);
	return getDateAs_YYYY_MM_DD(businessDay);
}

export function getBusinessDayAsDate(localDate: Date = new Date(), businessDayStartTime: string = '00:00'): Date {
	/** First extract the business day start hours and minutes and handle possible issues */
	const split = treatTimeString(businessDayStartTime).split(':');
	const startHours = parseInt(split[0]);
	const startMinutes = parseInt(split[1]);

	const businessDayStartMinutesOffset = startHours * 60 + startMinutes;
	let businessDay: Date = localDate;
	const localMinuteOfADay = businessDay.getHours() * 60 + businessDay.getMinutes();

	/**
	 * Check if business day already started today and if so, subtract one day from business day
	 */
	if (localMinuteOfADay < businessDayStartMinutesOffset) {
		/**
		 * Business day has not started today, will start between now and 23:59:59.999
		 * so we are currently technically on yesterday's business day.
		 */
		businessDay = addDays(new Date(businessDay), -1);
	}

	return businessDay;
}

/**
 * Calculates UTC business day start and end based on local time business day start and set local date.
 *
 * Used to query data from DB and cloud apps where the timestamps are mostly stored as UTC.
 *
 * Example: Current date is 2022-08-26, 9:45 +02:00, business day starts at 8:00. Result will be:
 * [2022-08-26T06:00:00.000Z, 2022-08-27T05:59:59.999Z] (converted to ISOString for the sake of example).
 *
 * @param localDate default new Date()
 * @param businessDayStartTime default "00:00"
 * @returns [Date, Date]
 */
export function getBusinessDayStartEnd(localDate: Date = new Date(), businessDayStartTime: string = '00:00'): [Date, Date] {
	/** First extract the business day start hours and minutes and handle possible issues */
	const split = treatTimeString(businessDayStartTime).split(':');
	const startHours = parseInt(split[0]);
	const startMinutes = parseInt(split[1]);

	const businessDayStartMinutesOffset = startHours * 60 + startMinutes;
	let businessDay: Date = localDate;
	const localMinuteOfADay = businessDay.getHours() * 60 + businessDay.getMinutes();

	/**
	 * Check if business day already started today and if so, subtract one day from business day
	 */
	if (localMinuteOfADay < businessDayStartMinutesOffset) {
		/**
		 * Business day has not started today, will start between now and 23:59:59.999
		 * so we are currently technically on yesterday's business day.
		 */
		businessDay = addDays(new Date(businessDay), -1);
	}

	/** Get business day start by getting date from business day and appending start hours and minutes */
	const start = new Date(getDateAs_YYYY_MM_DD(businessDay) + ' ' + startHours + ':' + startMinutes);

	/** Add one day to business day end */
	const endDate = addDays(start, 1);
	/**
	 * Add subtract 1 ms from end, to be 1 ms before next business day start.
	 * This also handles the issue of 1 added day when the business day starts at the midnight.
	 */
	const end = new Date(endDate.getTime() - 1);
	return [start, end];
}

/**
 * Calculates UTC business day start and end based on local time business day start and business day.
 *
 * Utilizes getUTCBusinessDayStartEnd().
 *
 * Example: Target business day is 2022-08-26, business day starts at 8:00. Result will be:
 * [2022-08-26T06:00:00.000Z, 2022-08-27T05:59:59.999Z] (converted to ISOString for the sake of example).
 *
 * @param localDate default new Date()
 * @param businessDayStartTime default "00:00"
 * @returns [Date, Date]
 */
export function getBusinessDayStartEndFromBusinessDay(businessDay: string, businessDayStartTime: string = '00:00'): [Date, Date] {
	return getBusinessDayStartEnd(new Date(`${businessDay} ${businessDayStartTime}`), businessDayStartTime);
}

/**
 * Returns array of tuples of bussiness day starts and ends in given time interval.
 * @param from
 * @param to
 * @param businessDayStartTime
 * @returns
 */
export function getListOfBussinessDaysStartsEnds(from: Date, to: Date, businessDayStartTime: string = '00:00'): [Date, Date][] {
	const firstBussinessDayStartEnd: [Date, Date] = getBusinessDayStartEnd(from, businessDayStartTime);
	const lastBussinessDayStartEnd: [Date, Date] = getBusinessDayStartEnd(to, businessDayStartTime);
	/** Init response array */
	const response: [Date, Date][] = [firstBussinessDayStartEnd];
	/** Early return if the from - to dates describe single bussiness day */
	if (firstBussinessDayStartEnd[0].getTime() === lastBussinessDayStartEnd[0].getTime()) {
		return response;
	}
	let nextBussinessDayStartEnd: [Date, Date] = firstBussinessDayStartEnd;
	while (nextBussinessDayStartEnd[1].getTime() !== lastBussinessDayStartEnd[1].getTime()) {
		/** Add one day to start time of previous day and get new start-end. */
		nextBussinessDayStartEnd = getBusinessDayStartEnd(addDays(nextBussinessDayStartEnd[0], 1), businessDayStartTime);
		/** Push the next day to response array */
		response.push(nextBussinessDayStartEnd);
	}
	return response;
}

/**
 * Returns array of YYYY-MM-DD business days between two dates including partial business days
 * @param from
 * @param to
 * @param businessDayStartTime
 * @returns
 */
export function getListOfBusinessDaysBetweenDates(from: Date, to: Date, businessDayStartTime: string = '00:00'): string[] {
	const firstBussinessDayStart: Date = getBusinessDayStartEnd(from, businessDayStartTime)[0];
	const lastBussinessDayStart: Date = getBusinessDayStartEnd(to, businessDayStartTime)[0];

	/** Init response array */
	const response: string[] = [getDateAs_YYYY_MM_DD(firstBussinessDayStart)];
	/** Early return if the from - to dates describe single bussiness day */
	if (firstBussinessDayStart.getTime() === lastBussinessDayStart.getTime()) {
		return response;
	}
	let nextBussinessDayStart: Date = firstBussinessDayStart;
	while (nextBussinessDayStart.getTime() !== lastBussinessDayStart.getTime()) {
		/** Add one day to start time of previous day and get new start-end. */
		nextBussinessDayStart = getBusinessDayStartEnd(addDays(nextBussinessDayStart, 1), businessDayStartTime)[0];
		/** Push the next day to response array */
		response.push(getDateAs_YYYY_MM_DD(nextBussinessDayStart));
	}
	return response;
}

export function sortArrayOfBusinessDays(businessDays: string[]): string[] {
	return businessDays.sort((n1, n2) => {
		if (n1 > n2) {
			return 1;
		}
		if (n1 < n2) {
			return -1;
		}
		return 0;
	});
}
