import { IBKMachine } from '@bk/jscommondatas';
import { IBKTechShar3dInfos } from '@libs/shared/interfaces';
import { ADDRESS_VARIABLE, NAME_VARIABLE, RING_APP, ROLE_VARIABLE } from '@libs/shared/models';

export function extractDeviceInformation(data: IBKTechShar3dInfos): IBKMachine {
	if (data.tasks) {
		const ringAppVars = data.tasks[RING_APP]?.vars || {};
		const name = ringAppVars[NAME_VARIABLE] || '';

		return {
			name: name,
			role: ringAppVars[ROLE_VARIABLE],
			ip: ringAppVars[ADDRESS_VARIABLE],
			idx: parseInt(name.match(/\d+$/)?.[0] || '0'),
		};
	} else {
		return data as unknown as IBKMachine;
	}
}
