/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MediaPlaylistContentDto } from './media-playlist-content-dto';
import { SelectionPatternDto } from './selection-pattern-dto';


export interface MediaPlaylistDto { 
    /**
     * Id of the media playlist.
     */
    id: number;
    /**
     * Name of the playlist.
     */
    name: string;
    /**
     * Description of the playlist.
     */
    description: string;
    /**
     * Content of the playlist.
     */
    content: Array<MediaPlaylistContentDto>;
    /**
     * Flag indicating if the playlist contains campaign items.
     */
    campaignPlaylist: boolean;
    /**
     * Patterns of restaurants the media playlist is applicable to (very likely not utilized anymore).
     */
    applicableToPattern: Array<SelectionPatternDto>;
}

