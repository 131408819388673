// Full list of modules - https://stackblitz.com/angular/dgqrvderavj?file=src%2Fapp%2Fmaterial-module.ts
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

// Material Design Popover - neede because Mat Tooltips support only plain unformatted text
// See more info at https://github.com/joejordanbrown/popover/tree/master/projects/material-extended/mde/src/lib/popover
export const MaterialModules = [
	MatBadgeModule,
	MatButtonModule,
	MatDialogModule,
	MatDividerModule,
	MatFormFieldModule,
	MatSelectModule,
	MatSliderModule,
	MatSlideToggleModule,
	MatTableModule,
	MatPaginatorModule,
	MatTabsModule,
	MatToolbarModule,
	MatTooltipModule,
	MatIconModule,
	MatInputModule,
	MatProgressBarModule,
	TextFieldModule,
	MatCheckboxModule,
	MatRadioModule,
];
