
export type FoodCategoryMappingModel = {
	name: string;

	/**
	 * Product Families defined in Global3 Database,
	 * mapped to this FoodCategory.
	 */
	productFamilyIds: number[];

	/**
	 * Used in ORBp.
	 * Optional. If no color is provided then ORBp will automatically alternate between two basic colors.
	 * Background color of food category.
	 */
	color?: string;
}