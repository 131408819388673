/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface LocalWebordersDto { 
    /**
     * Disables all new weborders in restaurant. If disabled, \'disabledChannels\' property is not taken into account.
     */
    disabled: boolean;
    disabledChannels: Array<LocalWebordersDto.DisabledChannelsEnum>;
}
export namespace LocalWebordersDto {
    export type DisabledChannelsEnum = 'CLICK_COLLECT' | 'DELIVEROO' | 'DELIVERY_BK' | 'JUST_EAT' | 'UBER_EATS' | 'ZOT' | 'PLEASE' | 'LYVEAT';
    export const DisabledChannelsEnum = {
        ClickCollect: 'CLICK_COLLECT' as DisabledChannelsEnum,
        Deliveroo: 'DELIVEROO' as DisabledChannelsEnum,
        DeliveryBk: 'DELIVERY_BK' as DisabledChannelsEnum,
        JustEat: 'JUST_EAT' as DisabledChannelsEnum,
        UberEats: 'UBER_EATS' as DisabledChannelsEnum,
        Zot: 'ZOT' as DisabledChannelsEnum,
        Please: 'PLEASE' as DisabledChannelsEnum,
        Lyveat: 'LYVEAT' as DisabledChannelsEnum
    };
}


