/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ItemReferenceDto { 
    /**
     * PLU of the item.
     */
    plu: string;
    /**
     * BKPN of the item.
     */
    bkpn: string;
    /**
     * Name of the item for the BKC world.
     */
    bkcName: string;
}

