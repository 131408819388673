import { IBKKingdomRebootCoupon } from '@bk/jscommondatas';
import { IOrderCompleteData, IOrderFiscalCorePrices, IOrderLocation, IOrderUsedPayments } from '@shared/models';

export interface IOrderState {
	data: IOrderCompleteData;
	location: IOrderLocation;
	fiscalCorePrices: IOrderFiscalCorePrices;
	usedPaymentMethods: IOrderUsedPayments[];
	fidelityRewardsUsed: IBKKingdomRebootCoupon[];
	kioskPaymentEnabled: boolean;
}
