/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ORBScreenConfigDataDto { 
    /**
     * Id of the screen
     */
    screenIdx: number;
    /**
     * If true, this screen act as a slave
     */
    isSlave: boolean;
    parentIdx: number;
    assemblyAreas: Array<number>;
    /**
     * Dispatch strategy for the orb
     */
    dispatchStrategy?: number;
    /**
     * Specific kiosk to receive order from
     */
    specificKiosks?: Array<number>;
    /**
     * Mask for the sources of the order to display
     */
    sourceMask?: number;
    /**
     * Indexes of the orb
     */
    orbIdx?: Array<number>;
}

