import { Injectable } from '@angular/core';
import { ConfigurationsApiClient } from '@rap/api-client';
import { map, Observable } from 'rxjs';
import { GlobalAppsConfiguration } from '../models';
import { mapDkiBEConfigurationFromDto } from '../models/global-apps-configuration-mapper';

@Injectable({ providedIn: 'root' })
export class DashService {
	url: String;

	constructor(private readonly configurationApiService: ConfigurationsApiClient) {}

	getDkiConfiguration(): Observable<Partial<GlobalAppsConfiguration>> {
		return this.configurationApiService.getGlobalAppsCredentials().pipe(map((data) => mapDkiBEConfigurationFromDto(data)));
	}
}
