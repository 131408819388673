// See http://192.168.100.200:8080/server/bkGetBigDatas.php?bo=192.168.100.200:81

import { IngredientMap } from '../ingredient-map';
import { MenuMap } from '../menu-map';
import { ProductMap } from '../product-map';

// Oh yes, oh yes, yes yes, naming is so hard
// Don't get ever confused by "big names", somebody probably needed to compensate for something small
// and the funny name stayed.
// BigDatas are for "default product/menu pricing", "media campaings", and a bug of other random staff.
export class BigDatas {
	products: ProductMap;
	menus: MenuMap;
	ingredients: IngredientMap;

	allergens: any;

	ingredientFamilies: any;

	ingredientFilters: any;

	nutritionNutrients: any;

	productFamilies: any;

	productGroups: any;

	menuStepProductGroups: any;

	annotations: any;

	options: any;

	discounts: any;

	coupons: any;

	mediasPlayList: any;

	games: any;

	medias: any;

	campaigns: any;
}
