import { clone } from 'ramda';

export type APIType = {
	[value in API]: ApiDefinition;
};

export enum API {
	DKI_BE = 'dkiBe',
}

export type ApiDefinition = {
	accessToken: string;
	restoUuid?: string;
	dashUrl?: string;
};

export class ApiCredentialsStoreModel {
	loading: boolean = true;
	data: APIType;

	constructor(init?: Partial<ApiCredentialsStoreModel>) {
		Object.assign(this, clone(init));
	}
}
