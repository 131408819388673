/**
 * DKI-BE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Discount } from './discount';


export interface Item {
	id: number;
	line_uuid: string;
	name: string;
	number: number;
	base_price: number;
	base_price_excluding_vat: number;
	base_price_vat: number;
	alacarte_price: number;
	vat: number;
	total_discount: number;
	total_discount_excluding_vat: number;
	discounts?: Array<Discount>;
	family_id: number;
	has_ingredients?: boolean;
}

