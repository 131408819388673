import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AppEffects } from './state/effects';
import { DeviceFacade } from './state/facade';
import { reducer } from './state/reducer';

@NgModule({
	imports: [StoreModule.forFeature('device', reducer), EffectsModule.forFeature([AppEffects])],
	providers: [DeviceFacade],
})
export class StateDeviceModule {}
