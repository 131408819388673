import { IBKIngredientData, IBKProductIngredientData } from '@bk/jscommondatas';
import { generateUUID } from '@libs/shared/utils';
import { IngredientType } from '../ingredient.type';

const x = {
	user: { id: 0, name: 'KIOSK', surname: 'KIOSK', login: 'KIOSK', rightHanded: true, code: '', roles: 1 },
	manager: { id: 0, name: 'KIOSK', surname: 'KIOSK', login: 'KIOSK', rightHanded: true, code: '', roles: 1 },
	machine: { name: 'KIOSK1', role: 'KIOSK', ip: '192.168.100.51', idx: 1 },
	orderUUID: '2af9aa93-2d51-4f72-af2e-5142ce41c76d',
	orderContent: [
		{
			sysName: "Menu L'Américain",
			shortName: 'Menu Américain',
			campaignItem: false,
			name: { fr: "L'Américain", en: "L'Américain" },
			id: 475,
			xtag: [475, 478, 477, 476],
			noKiosk: false,
			noWeb: false,
			line: -1,
			lineuuid: '8e26fa2c-f880-48ce-a935-df67b878cdf2',
			timestamp: 0,
			freeItems: [],
			selection: [
				{
					sysName: "L'Américain Classic",
					shortName: 'Américain',
					campaignItem: false,
					name: { fr: "L'Américain Classic", en: "L'Américain Classic" },
					familyId: 19,
					available: true,
					id: 647,
					xtag: [475, 476, 478],
					noKiosk: false,
					noWeb: false,
					line: -1,
					lineuuid: '71ebefd9-cc3d-4778-a06c-4b5f5ae2310c',
					timestamp: 1700815192644,
					noIce: false,
					later: false,
					recipe: [
						{
							selected: 153,
							initial: 153,
							amount: 1,
							initAmount: 0,
							qty: 1,
							initQty: 1,
							priceTTC: 0,
							sysName: 'Bun Boulanger BCHEF',
							modified: false,
							line: 0,
							lineuuid: 'f246b6c0-e234-4e7d-88dc-da56964081a0',
							timestamp: 1700815187269,
						},
						{
							selected: 152,
							initial: 152,
							amount: 1,
							initAmount: 0,
							qty: 1,
							initQty: 1,
							priceTTC: 1,
							sysName: 'Cheddar',
							modified: false,
							line: 1,
							lineuuid: '7389a090-2fac-4069-a37d-8aabad6e9d27',
							timestamp: 1700815187269,
						},
						{
							selected: 183,
							initial: 183,
							amount: 1,
							initAmount: 0,
							qty: 2,
							initQty: 2,
							priceTTC: 1,
							sysName: 'Steak BCHEF',
							modified: false,
							line: 2,
							lineuuid: 'abf6dad4-9f3e-4c68-95fb-056fdb0f1aba',
							timestamp: 1700815187269,
						},
						{
							selected: 176,
							initial: 176,
							amount: 1,
							initAmount: 0,
							qty: 1,
							initQty: 1,
							priceTTC: 0,
							sysName: 'Tomate',
							modified: false,
							line: 3,
							lineuuid: '80bc7021-033f-470d-ae44-d8a569eeb5a4',
							timestamp: 1700815187269,
						},
						{
							selected: 180,
							initial: 180,
							amount: 1,
							initAmount: 0,
							qty: 1,
							initQty: 1,
							priceTTC: 0,
							sysName: 'Salade verte',
							modified: false,
							line: 4,
							lineuuid: '85aab0ef-a978-464f-a9a0-0303cb6e0262',
							timestamp: 1700815187269,
						},
						{
							selected: 199,
							initial: 199,
							amount: 1,
							initAmount: 0,
							qty: 1,
							initQty: 1,
							priceTTC: 0,
							sysName: 'Oignon Rouge',
							modified: false,
							line: 5,
							lineuuid: '9189979f-b2b7-405b-958f-0ab855c1c352',
							timestamp: 1700815187269,
						},
						{
							selected: 187,
							initial: 187,
							amount: 1,
							initAmount: 0,
							qty: 1,
							initQty: 1,
							priceTTC: 0,
							sysName: 'Sauce Barbecue',
							modified: false,
							line: 6,
							lineuuid: 'd90cfaca-68fb-4d98-9822-3d05d5157372',
							timestamp: 1700815187269,
						},
						{
							selected: 152,
							initial: 152,
							amount: 1,
							initAmount: 0,
							qty: 4,
							initQty: 0,
							priceTTC: 1,
							sysName: 'Cheddar',
							modified: true,
							line: 7,
							lineuuid: 'c4e1cb47-79a8-4640-a914-0ad0bca52ad0',
							timestamp: 1700815187269,
						},
					],
					removedRecipeModificatorsBeforeKitchenScreen: [],
					removedRecipeModificatorsAfterSubtotal: [],
					removedRecipeModificatorsAfterKitchenScreen: [],
					freeItems: [],
					itemAppliedDiscount: [],
					itemInvolvedDiscount: [],
					ref: { plu: 'BURG_AMER_CLAS', bkpn: 'BURG_AMER_CLAS', bkcName: 'BURG_AMER_CLAS' },
					qty: 1,
					price: { ht: 11.07, tva: 1.23, ttc: 12.3, pc: 10 },
					aLaCartePrice: { ttc: 8.3, pc: 10, tva: 0.83, ht: 7.47 },
					extraPrice: { ttc: 4, pc: 10, tva: 0.4, ht: 3.6 },
					nonFood: false,
					exclusiveToFidelity: false,
					requireValidation: false,
				},
				{
					sysName: 'Frite',
					shortName: 'Frite',
					campaignItem: false,
					name: { fr: 'Frite Crinkle', en: 'Crinkle Fries' },
					familyId: 23,
					available: true,
					id: 648,
					xtag: [475, 496, 485],
					noKiosk: false,
					noWeb: false,
					line: -1,
					lineuuid: '5fc06990-3ec0-46c7-9cbd-65984d5a2fc4',
					timestamp: 1700815192644,
					noIce: false,
					later: false,
					recipe: [],
					removedRecipeModificatorsBeforeKitchenScreen: [],
					removedRecipeModificatorsAfterSubtotal: [],
					removedRecipeModificatorsAfterKitchenScreen: [],
					freeItems: [],
					itemAppliedDiscount: [],
					itemInvolvedDiscount: [],
					ref: { plu: 'FRIT_CLAS', bkpn: 'FRIT_CLAS', bkcName: 'FRIT_CLAS' },
					qty: 1,
					price: { ht: 2.7, tva: 0.3, ttc: 3, pc: 10 },
					aLaCartePrice: { ttc: 3, pc: 10, tva: 0.3, ht: 2.7 },
					extraPrice: { ttc: 0, pc: 10, tva: 0, ht: 0 },
					nonFood: false,
					exclusiveToFidelity: false,
					requireValidation: false,
				},
				{
					sysName: 'Thé Glacé Pêche & Mangue - M',
					shortName: 'Thé Glacé Pêche & Mangue - M',
					campaignItem: false,
					name: { fr: 'Thé Glacé Pêche & Mangue - Medium', en: 'Peach & Mango Iced Tea - Medium' },
					familyId: 26,
					available: true,
					id: 810,
					xtag: [487, 503, 495],
					noKiosk: false,
					noWeb: false,
					line: -1,
					lineuuid: '2ea53d5e-e596-48c2-bbe9-8815b0e62f5d',
					timestamp: 1700815192644,
					noIce: false,
					later: false,
					recipe: [],
					removedRecipeModificatorsBeforeKitchenScreen: [],
					removedRecipeModificatorsAfterSubtotal: [],
					removedRecipeModificatorsAfterKitchenScreen: [],
					freeItems: [],
					itemAppliedDiscount: [],
					itemInvolvedDiscount: [],
					ref: { plu: 'THE_GLAC_PECH_MEDI', bkpn: 'THE_GLAC_PECH_MEDI', bkcName: 'THE_GLAC_PECH_MEDI' },
					qty: 1,
					price: { ht: 2.88, tva: 0.32, ttc: 3.2, pc: 10 },
					aLaCartePrice: { ttc: 3.2, pc: 10, tva: 0.32, ht: 2.88 },
					extraPrice: { ttc: 0, pc: 10, tva: 0, ht: 0 },
					nonFood: false,
					exclusiveToFidelity: false,
					requireValidation: false,
				},
			],
			itemAppliedDiscount: [],
			itemInvolvedDiscount: [],
			ref: { plu: 'BURG_AMER_MENU', bkpn: 'BURG_AMER_MENU', bkcName: 'BURG_AMER_MENU' },
			qty: 1,
			price: { ht: 10.818181818181818, tva: 1.081818181818182, ttc: 11.9, pc: 10 },
			aLaCartePrice: { ttc: 11.9, pc: 10, tva: 1.19, ht: 10.71 },
			extraPrice: { ht: 0, tva: 0, ttc: 0, pc: 10 },
			large: false,
			priceExtraMenuTTC: 0,
			exclusiveToFidelity: false,
			requireValidation: false,
		},
	],
	orderDelContent: [],
	orderDelContentAfterDecl: [],
	orderDelContentAfterKitchenScreen: [],
	orderTotal: { ht: 10.818181818181818, tva: 1.081818181818182, ttc: 11.9, pc: 10 },
	deliveryMode: 0,
	statedetail: 0,
	lineCounter: 0,
	source: 1,
	creationdate: 1700815176162,
	paidDate: 0,
	firstProductDate: 0,
	stateChangeDate: 1700815176162,
	csiOrder: 0,
	csiSrc: '',
	csiDeclaredTime: 0,
	orb: 834,
	annotations: [],
	orderDiscount: [],
	event: [
		{
			timestamp: 1700815176170,
			eventType: 1,
			arg: 'campaigns=372&delivery=0&lang=fr&version=&portrait=true',
		},
		{ timestamp: 1700815181714, eventType: 3, arg: 'kn=2' },
		{
			timestamp: 1700815182306,
			eventType: 100,
			arg: 'm=475',
		},
		{ timestamp: 1700815182331, eventType: 101, arg: 'stepIdx=1&m=475' },
		{
			timestamp: 1700815187269,
			eventType: 200,
			arg: 'p=647&lines=1',
		},
		{ timestamp: 1700815187375, eventType: 101, arg: 'stepIdx=2&m=475' },
		{
			timestamp: 1700815189764,
			eventType: 101,
			arg: 'stepIdx=3&m=475',
		},
		{ timestamp: 1700815192449, eventType: 300, arg: 'm=475' },
	],
	orderGames: [],
	orderCoupons: [],
	businessDay: 20231124,
};

const oldKiosk = {
	mdmOrder: {
		machine: { name: 'OSMDM-KIOSK1', role: 'KIOSK', idx: 1, ip: '192.168.100.51' },
		orderUUID: '18093e61-240e-4a3d-bea0-8c630e44bae0',
		deliveryMode: 0,
		state: 0,
		statedetail: 0,
		source: 1,
		creationdate: 1700815567240,
		paidDate: 0,
		firstProductDate: 1700815577609,
		stateChangeDate: 1700815567240,
		orb: 135,
		annotations: [],
		orderDiscount: [],
		event: [
			{
				timestamp: 3,
				eventType: 1,
				arg: 'delivery=0&lang=fr&version=v4.1.690&kpi=1.14&campaigns=192%7C243%7C329%7C454%7C505%7C543%7C584%7C638%7C684%7C802%7C825%7C831%7C832&media_Wait=1517%7C2577&media_Home_RZF_1=1715&media_Home_RZF_2=2872&media_Home_RZF_3=2868&media_Home_RZF_4=2871&media_Home_RZF_6=2143&media_Home_RZF_7=1866&media_Home_RZF_8=1159&media_Home_RZF_9=29',
			},
			{ timestamp: 2036, eventType: 3, arg: 'kn=88' },
			{ timestamp: 7782, eventType: 3, arg: 'kn=88' },
			{
				timestamp: 11597,
				eventType: 604,
				arg: 'p=17',
			},
			{ timestamp: 11597, eventType: 300, arg: 'p=17' },
			{
				timestamp: 26289,
				eventType: 200,
				arg: 'lines=1&p=17',
			},
			{ timestamp: 31995, eventType: 4001, arg: 'easelNum=9&locationSpace=terrace' },
			{
				timestamp: 35105,
				eventType: 1002,
				arg: 'payInPos=0',
			},
		],
		orderCoupons: [],
		user: { id: 0, login: 'KIOSK' },
		manager: { id: 0, login: 'KIOSK' },
		orderTotal: { ht: 2.34, tva: 0.26, ttc: 2.6, pc: 0 },
		lineCounter: 1,
		csiOrder: 0,
		csiSrc: '',
		csiDeclaredTime: 0,
		orderGames: [],
		businessDay: 20231124,
		localisation: 'EAT_IN',
		orderContent: [
			{
				id: 17,
				sysName: 'Double Whopper Cheese',
				shortName: 'DBL Whopper CHS',
				xtag: [15, 18, 31, 30, 172, 55],
				campaignItem: false,
				available: true,
				inActiveCampaign: false,
				noKiosk: false,
				noWeb: false,
				nonFood: false,
				exclusiveToFidelity: false,
				requireValidation: false,
				active: true,
				vatValues: [
					{ value: 'Normal', tvaName: 'EatIn', percent: 10 },
					{ value: 'Normal', tvaName: 'TakeAway', percent: 10 },
				],
				codeColor: '',
				lineuuid: '052b4461-5575-434d-8498-4adcaada58ce',
				timestamp: 1700815593527,
				itemAppliedDiscount: [],
				itemInvolvedDiscount: [],
				freeItems: [],
				removedRecipeModificatorsBeforeKitchenScreen: [],
				removedRecipeModificatorsAfterKitchenScreen: [],
				removedRecipeModificatorsAfterSubtotal: [],
				noIce: false,
				productGroupName: '',
				qty: 1,
				price: { ht: 2.34, tva: 0.26, ttc: 2.6, pc: 10 },
				aLaCartePrice: { ht: 1.8, tva: 0.2, ttc: 2, pc: 10, pcto: 10 },
				extraPrice: { ht: 0.54, tva: 0.06, ttc: 0.6000000000000001, pc: 10 },
				priceExtraMenuTTC: 6,
				line: 1,
				later: false,
				ref: { plu: '20202', bkpn: '17', bkcName: 'Whopper Chs Dbl' },
				recipe: [
					{
						selected: 9,
						initial: 9,
						amount: 0,
						initAmount: 0,
						qty: 1,
						initQty: 1,
						priceTTC: 0,
						sysName: 'Bun 5 (Whopper)',
						modified: false,
						line: 0,
						lineuuid: '1bb93732-5efe-4cbb-8a62-430d298d4aa0',
						timestamp: 1700815593527,
						price: { ht: 0, tva: 0, ttc: 0, pc: 10 },
						ref: { plu: '8021000', bkpn: '103711000', bkcName: 'Condiment Bun Whopper' },
					},
					{
						selected: 23,
						initial: 23,
						amount: 0,
						initAmount: 0,
						qty: 1,
						initQty: 1,
						priceTTC: 0,
						sysName: 'Sauce ketchup',
						modified: false,
						line: 0,
						lineuuid: 'ba1f8959-c33c-4fa1-962c-9413b6e5d493',
						timestamp: 1700815593527,
						price: { ht: 0, tva: 0, ttc: 0, pc: 10 },
						ref: { plu: '9003000', bkpn: '7905000', bkcName: 'Condiment Ketchup' },
					},
					{
						selected: 24,
						initial: 24,
						amount: 0,
						initAmount: 0,
						qty: 1,
						initQty: 1,
						priceTTC: 0,
						sysName: 'Sauce mayonnaise',
						modified: false,
						line: 0,
						lineuuid: 'eca0e336-aa76-4624-9b26-5e75af79b2cf',
						timestamp: 1700815593527,
						price: { ht: 0, tva: 0, ttc: 0, pc: 10 },
						ref: { plu: '9011000', bkpn: '7909000', bkcName: 'Condiment Mayo' },
					},
					{
						selected: 20,
						initial: 20,
						amount: 0,
						initAmount: 0,
						qty: 1,
						initQty: 1,
						priceTTC: 0,
						sysName: 'Salade',
						modified: false,
						line: 0,
						lineuuid: '1101b7f3-6bdd-47c7-b611-5b5e037b2cd5',
						timestamp: 1700815593527,
						price: { ht: 0, tva: 0, ttc: 0, pc: 10 },
						ref: { plu: '9005000', bkpn: '7907000', bkcName: 'Condiment Lettuce Salad' },
					},
					{
						selected: 27,
						initial: 27,
						amount: 0,
						initAmount: 0,
						qty: 1,
						initQty: 1,
						priceTTC: 0,
						sysName: 'Tomate',
						modified: false,
						line: 0,
						lineuuid: '96ef1129-a507-4265-b8e8-724da219c93f',
						timestamp: 1700815593527,
						price: { ht: 0, tva: 0, ttc: 0, pc: 10 },
						ref: { plu: '9012000', bkpn: '515000', bkcName: 'Condiment Tomato' },
					},
					{
						selected: 7,
						initial: 7,
						amount: 0,
						initAmount: 0,
						qty: 1,
						initQty: 1,
						priceTTC: 0,
						sysName: 'Oignons',
						modified: false,
						line: 0,
						lineuuid: 'fbc5ee63-5875-4051-8454-671620278c73',
						timestamp: 1700815593527,
						price: { ht: 0, tva: 0, ttc: 0, pc: 10 },
						ref: { plu: '9010000', bkpn: '7914000', bkcName: 'Condiment Onion Sandwich' },
					},
					{
						selected: 8,
						initial: 8,
						amount: 0,
						initAmount: 0,
						qty: 0,
						initQty: 0,
						priceTTC: 0.3,
						sysName: 'Oignons croustillants',
						modified: false,
						line: 0,
						lineuuid: 'f35ffbee-e6eb-4603-9da1-685a7a5b8cfd',
						timestamp: 1700815593527,
						price: { ht: 0.27, tva: 0.03, ttc: 0.3, pc: 10 },
						ref: { plu: '9038000', bkpn: '7912000', bkcName: 'Condiment Onion Crispy' },
					},
					{
						selected: 78,
						initial: 78,
						amount: 0,
						initAmount: 0,
						qty: 3,
						initQty: 0,
						priceTTC: 0.2,
						sysName: '3 tr Bacon',
						modified: true,
						line: 0,
						lineuuid: 'bd660e9f-7293-488b-a77f-53a959a04890',
						timestamp: 1700815593527,
						price: { ht: 0.18000000000000002, tva: 0.02, ttc: 0.2, pc: 10 },
						ref: { plu: 'I0012000', bkpn: '505000', bkcName: 'Portion Bac' },
					},
					{
						selected: 18,
						initial: 18,
						amount: 0,
						initAmount: 0,
						qty: 1,
						initQty: 1,
						priceTTC: 0,
						sysName: 'Cornichons',
						modified: false,
						line: 0,
						lineuuid: 'e930a4f0-3812-4db6-9b39-41a26435c454',
						timestamp: 1700815593527,
						price: { ht: 0, tva: 0, ttc: 0, pc: 10 },
						ref: { plu: '9004000', bkpn: '7917000', bkcName: 'Condiment Pickles' },
					},
					{
						selected: 101,
						initial: 101,
						amount: 0,
						initAmount: 0,
						qty: 1,
						initQty: 1,
						priceTTC: 0.7,
						sysName: '2 tr Fromage',
						modified: false,
						line: 0,
						lineuuid: '331218ce-02f4-4b81-a5da-3db12cccaf12',
						timestamp: 1700815593527,
						price: { ht: 0.6299999999999999, tva: 0.07, ttc: 0.7, pc: 10 },
						ref: { plu: 'I0013000', bkpn: '560000', bkcName: 'Portion Chs' },
					},
					{
						selected: 26,
						initial: 26,
						amount: 0,
						initAmount: 0,
						qty: 2,
						initQty: 2,
						priceTTC: 1.6,
						sysName: 'Whopper patty',
						modified: false,
						line: 0,
						lineuuid: '013b73b2-b584-43ee-bc1c-a7dcc958ce72',
						timestamp: 1700815593527,
						price: { ht: 1.4400000000000002, tva: 0.16, ttc: 1.6, pc: 10 },
						ref: { plu: '20100000', bkpn: '500000', bkcName: 'Portion Whopper Patty' },
					},
					{
						selected: 14,
						initial: 14,
						amount: 0,
						initAmount: 0,
						qty: 1,
						initQty: 1,
						priceTTC: 0,
						sysName: 'Bun 5 (whopper) fond',
						modified: false,
						line: 0,
						lineuuid: '84238b87-213e-4cad-8f47-ea237d4ce22e',
						timestamp: 1700815593527,
						price: { ht: 0, tva: 0, ttc: 0, pc: 10 },
						ref: { plu: '8021000', bkpn: '103711000', bkcName: 'Condiment Bun Whopper' },
					},
				],
			},
		],
		orderDelContent: [],
		orderDelContentAfterDecl: [],
		orderDelContentAfterKitchenScreen: [],
	},
	csiorder: {
		ticketUuid: '18093e61-240e-4a3d-bea0-8c630e44bae0',
		localisation: 'EAT_IN',
		codeVendeur: 0,
		nomVendeur: 'KIOSK',
		codeOperateur: 0,
		nomOperateur: 'KIOSK',
		clefEcole: false,
		nbClient: 1,
		commandeNameCaisse: 'OSMDM-KIOSK1',
		commandeNumber: 0,
		sourceTicketNameCaisse: '',
		sourceTicketNumber: 0,
		destinationTicketNameCaisse: '',
		destinationTicketNumber: 0,
		sourceTicketState: 'NONE',
		destinationTicketState: 'NONE',
		horodatage: 1700815602412,
		regroupementConsommation: '',
		tag: 'destination=EAT_IN',
		dateFiscale: 1700815602412,
		commandeId: 0,
		generator: '',
		lines: [
			{
				lineUuid: '06955e9f-7a51-4195-b8ea-0e547c3b603b',
				numero: 0,
				horodatage: 1700815602412,
				libelleTarif: '',
				numTarif: 0,
				libelle: '',
				code: '',
				prix: 0,
				prixUnitaireCarte: 0,
				qte: 0,
				poids: 0,
				unite: 'PIECE',
				ticketUuid: '18093e61-240e-4a3d-bea0-8c630e44bae0',
				typeLigne: 'DATE',
				numClef: 0,
				nomClef: 'KIOSK',
				codeManager: 0,
				nomManager: 'KIOSK',
				codeVendeur: 0,
				nomVendeur: 'KIOSK',
				libFamille: '',
				libSousFamille: '',
				libGroupe: '',
				numFamille: -1,
				numSousFamille: -1,
				numGroupe: -1,
				libelleTVA: '',
				numTVA: -1,
				tauxTVA: 0,
				libCaisse: '192.168.100.51',
				numCaisse: 1,
				remiseMontantTTC: 0,
				remiseMontantHT: 0,
				remiseTaux: 0,
				serviceTaux: 0,
				serviceMode: 'AUCUN',
				serviceMontant: 0,
				montantTotalTTC: 0,
				montantTotalHT: 0,
				deviseCode: '',
				deviseTaux: 0,
				deviseLibelle: '',
				deviseMontant: 0,
				libelleModifier: '',
				libelleQualifier: '',
				change: false,
			},
			{
				lineUuid: '052b4461-5575-434d-8498-4adcaada58ce',
				numero: 17,
				horodatage: 1700815593527,
				libelleTarif: '',
				numTarif: 0,
				libelle: 'Double Whopper Cheese',
				code: '20202',
				prix: 2,
				prixUnitaireCarte: 2,
				qte: 1,
				poids: 1,
				unite: 'PIECE',
				ticketUuid: '18093e61-240e-4a3d-bea0-8c630e44bae0',
				typeLigne: 'ARTICLE',
				numClef: 0,
				nomClef: 'KIOSK',
				codeManager: 0,
				nomManager: 'KIOSK',
				codeVendeur: 0,
				nomVendeur: 'KIOSK',
				libFamille: 'Burgers',
				libSousFamille: '',
				libGroupe: 'WHOPPER',
				numFamille: 3,
				numSousFamille: 0,
				numGroupe: 2,
				libelleTVA: 'TVA 10%',
				numTVA: 1000,
				tauxTVA: 10,
				libCaisse: '192.168.100.51',
				numCaisse: 1,
				remiseMontantTTC: 0,
				remiseMontantHT: 0,
				remiseTaux: 0,
				serviceTaux: 0,
				serviceMode: 'AUCUN',
				serviceMontant: 0,
				montantTotalTTC: 0,
				montantTotalHT: 0,
				deviseCode: 'euro',
				deviseTaux: 1,
				deviseLibelle: 'euro',
				deviseMontant: 3,
				tag: 'product_id=17&third_party_id=17&a_la_carte_price=2',
				libelleModifier: '',
				libelleQualifier: '',
				change: false,
				filter: 1,
			},
			{
				lineUuid: 'bd660e9f-7293-488b-a77f-53a959a04890',
				numero: 7714,
				horodatage: 1700815593527,
				libelleTarif: '',
				numTarif: 0,
				libelle: '',
				code: 'I0012200',
				prix: 0.2,
				prixUnitaireCarte: 0.2,
				qte: 3,
				poids: 1,
				unite: 'PIECE',
				ticketUuid: '18093e61-240e-4a3d-bea0-8c630e44bae0',
				parentLineUuid: '052b4461-5575-434d-8498-4adcaada58ce',
				typeLigne: 'MODIFIER_QUALIFIER',
				numClef: 0,
				nomClef: 'KIOSK',
				codeManager: 0,
				nomManager: 'KIOSK',
				codeVendeur: 0,
				nomVendeur: 'KIOSK',
				libFamille: '',
				libSousFamille: '',
				libGroupe: 'SUPPLEMENTS',
				numFamille: 0,
				numSousFamille: 0,
				numGroupe: 12,
				libelleTVA: 'TVA 10%',
				numTVA: 1000,
				tauxTVA: 10,
				libCaisse: '192.168.100.51',
				numCaisse: 1,
				remiseMontantTTC: 0,
				remiseMontantHT: 0,
				remiseTaux: 0,
				serviceTaux: 0,
				serviceMode: 'AUCUN',
				serviceMontant: 0,
				montantTotalTTC: 0,
				montantTotalHT: 0,
				deviseCode: 'euro',
				deviseTaux: 1,
				deviseLibelle: 'euro',
				deviseMontant: 0,
				tag: 'a_la_carte_price=0.2&modifier_third_party_id=7714&qualifier_third_party_id=505&qualifier_id=78',
				libelleModifier: 'Add',
				libelleQualifier: '3 tr Bacon',
				change: false,
				filter: 1,
			},
			{
				lineUuid: '2ba3ffac-a836-433b-bfbf-c18bd74f812c',
				numero: 0,
				horodatage: 1700815602417,
				libelleTarif: '',
				numTarif: 0,
				libelle: '',
				code: '',
				prix: 0,
				prixUnitaireCarte: 0,
				qte: 0,
				poids: 0,
				unite: 'PIECE',
				ticketUuid: '18093e61-240e-4a3d-bea0-8c630e44bae0',
				typeLigne: 'MIDDLE_TEXT',
				numClef: 0,
				nomClef: 'KIOSK',
				codeManager: 0,
				nomManager: 'KIOSK',
				codeVendeur: 0,
				nomVendeur: 'KIOSK',
				libFamille: '',
				libSousFamille: '',
				libGroupe: '',
				numFamille: -1,
				numSousFamille: -1,
				numGroupe: -1,
				libelleTVA: '',
				numTVA: -1,
				tauxTVA: 0,
				libCaisse: '192.168.100.51',
				numCaisse: 1,
				remiseMontantTTC: 0,
				remiseMontantHT: 0,
				remiseTaux: 0,
				serviceTaux: 0,
				serviceMode: 'AUCUN',
				serviceMontant: 0,
				montantTotalTTC: 0,
				montantTotalHT: 0,
				deviseCode: '',
				deviseTaux: 0,
				deviseLibelle: '',
				deviseMontant: 0,
				libelleModifier: '',
				libelleQualifier: '',
				change: false,
				tag: '{"text":"** KIOSK - SUR PLACE - Terrasse **","style":"BOLD","size":"NORMAL","align":"CENTER","separator":"NONE","lineBreakBefore":0,"lineBreakAfter":0,"qrcode":"","document":"TICKET","title":""}',
			},
			{
				lineUuid: 'e1e29dc1-2902-45db-a5b6-b2a9dbf4a03b',
				numero: 0,
				horodatage: 1700815602417,
				libelleTarif: '',
				numTarif: 0,
				libelle: '',
				code: '',
				prix: 0,
				prixUnitaireCarte: 0,
				qte: 0,
				poids: 0,
				unite: 'PIECE',
				ticketUuid: '18093e61-240e-4a3d-bea0-8c630e44bae0',
				typeLigne: 'MIDDLE_TEXT',
				numClef: 0,
				nomClef: 'KIOSK',
				codeManager: 0,
				nomManager: 'KIOSK',
				codeVendeur: 0,
				nomVendeur: 'KIOSK',
				libFamille: '',
				libSousFamille: '',
				libGroupe: '',
				numFamille: -1,
				numSousFamille: -1,
				numGroupe: -1,
				libelleTVA: '',
				numTVA: -1,
				tauxTVA: 0,
				libCaisse: '192.168.100.51',
				numCaisse: 1,
				remiseMontantTTC: 0,
				remiseMontantHT: 0,
				remiseTaux: 0,
				serviceTaux: 0,
				serviceMode: 'AUCUN',
				serviceMontant: 0,
				montantTotalTTC: 0,
				montantTotalHT: 0,
				deviseCode: '',
				deviseTaux: 0,
				deviseLibelle: '',
				deviseMontant: 0,
				libelleModifier: '',
				libelleQualifier: '',
				change: false,
				tag: '{"text":"PREPARATION : 135","style":"BOLD","size":"WX2HX2","align":"CENTER","separator":"NONE","lineBreakBefore":0,"lineBreakAfter":0,"qrcode":"","document":"TICKET","title":""}',
			},
			{
				lineUuid: '24355955-ff68-48f6-bc37-48b450c44844',
				numero: 0,
				horodatage: 1700815602417,
				libelleTarif: '',
				numTarif: 0,
				libelle: '',
				code: '',
				prix: 0,
				prixUnitaireCarte: 0,
				qte: 0,
				poids: 0,
				unite: 'PIECE',
				ticketUuid: '18093e61-240e-4a3d-bea0-8c630e44bae0',
				typeLigne: 'MIDDLE_TEXT',
				numClef: 0,
				nomClef: 'KIOSK',
				codeManager: 0,
				nomManager: 'KIOSK',
				codeVendeur: 0,
				nomVendeur: 'KIOSK',
				libFamille: '',
				libSousFamille: '',
				libGroupe: '',
				numFamille: -1,
				numSousFamille: -1,
				numGroupe: -1,
				libelleTVA: '',
				numTVA: -1,
				tauxTVA: 0,
				libCaisse: '192.168.100.51',
				numCaisse: 1,
				remiseMontantTTC: 0,
				remiseMontantHT: 0,
				remiseTaux: 0,
				serviceTaux: 0,
				serviceMode: 'AUCUN',
				serviceMontant: 0,
				montantTotalTTC: 0,
				montantTotalHT: 0,
				deviseCode: '',
				deviseTaux: 0,
				deviseLibelle: '',
				deviseMontant: 0,
				libelleModifier: '',
				libelleQualifier: '',
				change: false,
				tag: '{"text":"N° chevalet: 9","style":"BOLD","size":"WX2HX2","align":"CENTER","separator":"NONE","lineBreakBefore":0,"lineBreakAfter":0,"qrcode":"","document":"ALL","title":""}',
			},
			{
				lineUuid: 'c14e5d40-99a6-47fd-b16e-730ef33a21c2',
				numero: 0,
				horodatage: 1700815602418,
				libelleTarif: '',
				numTarif: 0,
				libelle: '',
				code: '',
				prix: 0,
				prixUnitaireCarte: 0,
				qte: 0,
				poids: 0,
				unite: 'PIECE',
				ticketUuid: '18093e61-240e-4a3d-bea0-8c630e44bae0',
				typeLigne: 'MIDDLE_TEXT',
				numClef: 0,
				nomClef: 'KIOSK',
				codeManager: 0,
				nomManager: 'KIOSK',
				codeVendeur: 0,
				nomVendeur: 'KIOSK',
				libFamille: '',
				libSousFamille: '',
				libGroupe: '',
				numFamille: -1,
				numSousFamille: -1,
				numGroupe: -1,
				libelleTVA: '',
				numTVA: -1,
				tauxTVA: 0,
				libCaisse: '192.168.100.51',
				numCaisse: 1,
				remiseMontantTTC: 0,
				remiseMontantHT: 0,
				remiseTaux: 0,
				serviceTaux: 0,
				serviceMode: 'AUCUN',
				serviceMontant: 0,
				montantTotalTTC: 0,
				montantTotalHT: 0,
				deviseCode: '',
				deviseTaux: 0,
				deviseLibelle: '',
				deviseMontant: 0,
				libelleModifier: '',
				libelleQualifier: '',
				change: false,
				tag: '{"text":"","style":"BOLD","size":"NORMAL","align":"CENTER","separator":"NONE","lineBreakBefore":0,"lineBreakAfter":0,"qrcode":"BK/{NUMCMD}/{SRCCMD}/{NUMTIC}/{SRCTIC}/2.6/18093e61-240e-4a3d-bea0-8c630e44bae0/1337/135/-","document":"ALL","title":""}',
			},
			{
				lineUuid: 'c47b897d-fe09-4f40-b859-d33a86e69e96',
				numero: 0,
				horodatage: 1700815602419,
				libelleTarif: '',
				numTarif: 0,
				libelle: '',
				code: '',
				prix: 0,
				prixUnitaireCarte: 0,
				qte: 0,
				poids: 0,
				unite: 'PIECE',
				ticketUuid: '18093e61-240e-4a3d-bea0-8c630e44bae0',
				typeLigne: 'MIDDLE_TEXT',
				numClef: 0,
				nomClef: 'KIOSK',
				codeManager: 0,
				nomManager: 'KIOSK',
				codeVendeur: 0,
				nomVendeur: 'KIOSK',
				libFamille: '',
				libSousFamille: '',
				libGroupe: '',
				numFamille: -1,
				numSousFamille: -1,
				numGroupe: -1,
				libelleTVA: '',
				numTVA: -1,
				tauxTVA: 0,
				libCaisse: '192.168.100.51',
				numCaisse: 1,
				remiseMontantTTC: 0,
				remiseMontantHT: 0,
				remiseTaux: 0,
				serviceTaux: 0,
				serviceMode: 'AUCUN',
				serviceMontant: 0,
				montantTotalTTC: 0,
				montantTotalHT: 0,
				deviseCode: '',
				deviseTaux: 0,
				deviseLibelle: '',
				deviseMontant: 0,
				libelleModifier: '',
				libelleQualifier: '',
				change: false,
				tag: '{"text":"A PAYER :135","style":"BOLD","size":"WX2HX2","align":"CENTER","separator":"NONE","lineBreakBefore":0,"lineBreakAfter":0,"qrcode":"","document":"ORDER","title":""}',
			},
		],
		complements: [{ cle: 'NUM_ORB', valeur: '135', type: 'STRING', complementId: 0 }],
	},
};

const zzzz = {
	ticketUuid: 'f343fb65-3b2f-481d-acd7-20c97ba4dc0b',
	localisation: 'EAT_IN',
	codeVendeur: 0,
	nomVendeur: 'KIOSK',
	codeOperateur: 0,
	nomOperateur: 'KIOSK',
	clefEcole: false,
	nbClient: 1,
	commandeNameCaisse: 'KIOSK1',
	commandeNumber: 0,
	sourceTicketNameCaisse: '',
	sourceTicketNumber: 0,
	destinationTicketNameCaisse: '',
	destinationTicketNumber: 0,
	sourceTicketState: 'NONE',
	destinationTicketState: 'NONE',
	horodatage: 1700816154985,
	regroupementConsommation: '',
	tag: 'destination=EAT_IN',
	dateFiscale: 1700816154985,
	commandeId: 0,
	generator: '',
	lines: [
		{
			lineUuid: 'fa2b77b4-4ec2-4e29-a0c1-cca66be0dfc2',
			numero: 0,
			horodatage: 1700816154986,
			libelleTarif: '',
			numTarif: 0,
			libelle: '',
			code: '',
			prix: 0,
			prixUnitaireCarte: 0,
			qte: 0,
			poids: 0,
			unite: 'PIECE',
			ticketUuid: 'f343fb65-3b2f-481d-acd7-20c97ba4dc0b',
			typeLigne: 'DATE',
			numClef: 0,
			nomClef: 'KIOSK',
			codeManager: 0,
			nomManager: 'KIOSK',
			codeVendeur: 0,
			nomVendeur: 'KIOSK',
			libFamille: '',
			libSousFamille: '',
			libGroupe: '',
			numFamille: -1,
			numSousFamille: -1,
			numGroupe: -1,
			libelleTVA: '',
			numTVA: -1,
			tauxTVA: 0,
			libCaisse: '192.168.100.51',
			numCaisse: 1,
			remiseMontantTTC: 0,
			remiseMontantHT: 0,
			remiseTaux: 0,
			serviceTaux: 0,
			serviceMode: 'AUCUN',
			serviceMontant: 0,
			montantTotalTTC: 0,
			montantTotalHT: 0,
			deviseCode: '',
			deviseTaux: 0,
			deviseLibelle: '',
			deviseMontant: 0,
			libelleModifier: '',
			libelleQualifier: '',
			change: false,
		},
		{
			lineUuid: 'aaa37a60-47ae-48c3-bb84-d03d54393087',
			numero: 475,
			horodatage: 1700816154987,
			libelleTarif: '',
			numTarif: 0,
			libelle: "Menu L'Américain",
			code: 'BURG_AMER_MENU',
			prix: 11.9,
			prixUnitaireCarte: 11.9,
			qte: 1,
			poids: 1,
			unite: 'PIECE',
			ticketUuid: 'f343fb65-3b2f-481d-acd7-20c97ba4dc0b',
			typeLigne: 'VALUEMEAL',
			numClef: 0,
			nomClef: 'KIOSK',
			codeManager: 0,
			nomManager: 'KIOSK',
			codeVendeur: 0,
			nomVendeur: 'KIOSK',
			libFamille: '',
			libSousFamille: '',
			libGroupe: 'BCHEF',
			numFamille: 0,
			numSousFamille: 0,
			numGroupe: 1,
			libelleTVA: 'TVA 10%',
			numTVA: 1000,
			tauxTVA: 10,
			libCaisse: '192.168.100.51',
			numCaisse: 1,
			remiseMontantTTC: 0,
			remiseMontantHT: 0,
			remiseTaux: 0,
			serviceTaux: 0,
			serviceMode: 'AUCUN',
			serviceMontant: 0,
			montantTotalTTC: 0,
			montantTotalHT: 0,
			deviseCode: 'euro',
			deviseTaux: 1,
			deviseLibelle: 'euro',
			deviseMontant: 0,
			tag: 'menu_id=475&third_party_id=7592&a_la_carte_price=11.9',
			libelleModifier: '',
			libelleQualifier: '',
			change: false,
			filter: 1,
		},
		{
			lineUuid: '9e5e357f-21d3-4861-bcb4-d25cc8c71bdb',
			numero: 647,
			horodatage: 1700816154988,
			libelleTarif: '',
			numTarif: 0,
			libelle: "L'Américain Classic",
			code: 'BURG_AMER_CLAS',
			prix: 0,
			prixUnitaireCarte: 8.3,
			qte: 1,
			poids: 1,
			unite: 'PIECE',
			ticketUuid: 'f343fb65-3b2f-481d-acd7-20c97ba4dc0b',
			typeLigne: 'ARTICLE',
			numClef: 0,
			nomClef: 'KIOSK',
			codeManager: 0,
			nomManager: 'KIOSK',
			codeVendeur: 0,
			nomVendeur: 'KIOSK',
			libFamille: 'Burgers',
			libSousFamille: '',
			libGroupe: 'BCHEF',
			numFamille: 19,
			numSousFamille: 0,
			numGroupe: 1,
			libelleTVA: 'TVA 10%',
			numTVA: 1000,
			tauxTVA: 10,
			libCaisse: '192.168.100.51',
			numCaisse: 1,
			remiseMontantTTC: 0,
			remiseMontantHT: 0,
			remiseTaux: 0,
			serviceTaux: 0,
			serviceMode: 'AUCUN',
			serviceMontant: 0,
			montantTotalTTC: 0,
			montantTotalHT: 0,
			deviseCode: 'euro',
			deviseTaux: 1,
			deviseLibelle: 'euro',
			deviseMontant: 0,
			tag: 'product_id=647&third_party_id=7414&a_la_carte_price=8.3',
			libelleModifier: '',
			libelleQualifier: '',
			change: false,
			filter: 1,
			parentLineUuid: 'aaa37a60-47ae-48c3-bb84-d03d54393087',
		},
		{
			lineUuid: '24ce3500-a1d4-4530-a941-c42be69a6920',
			numero: 7714,
			horodatage: 1700816148944,
			libelleTarif: '',
			numTarif: 0,
			libelle: '',
			code: 'CHED',
			prix: 1,
			prixUnitaireCarte: 1,
			qte: 4,
			poids: 1,
			unite: 'PIECE',
			ticketUuid: 'f343fb65-3b2f-481d-acd7-20c97ba4dc0b',
			parentLineUuid: '9e5e357f-21d3-4861-bcb4-d25cc8c71bdb',
			typeLigne: 'MODIFIER_QUALIFIER',
			numClef: 0,
			nomClef: 'KIOSK',
			codeManager: 0,
			nomManager: 'KIOSK',
			codeVendeur: 0,
			nomVendeur: 'KIOSK',
			libFamille: '',
			libSousFamille: '',
			libGroupe: 'BCHEF',
			numFamille: 0,
			numSousFamille: 0,
			numGroupe: 1,
			libelleTVA: 'TVA 10%',
			numTVA: 1000,
			tauxTVA: 10,
			libCaisse: '192.168.100.51',
			numCaisse: 1,
			remiseMontantTTC: 0,
			remiseMontantHT: 0,
			remiseTaux: 0,
			serviceTaux: 0,
			serviceMode: 'AUCUN',
			serviceMontant: 0,
			montantTotalTTC: 0,
			montantTotalHT: 0,
			deviseCode: 'euro',
			deviseTaux: 1,
			deviseLibelle: 'euro',
			deviseMontant: 0,
			tag: 'a_la_carte_price=1&modifier_third_party_id=7714&qualifier_third_party_id=CHED&qualifier_id=152',
			libelleModifier: 'Add',
			libelleQualifier: 'Cheddar',
			change: false,
			filter: 1,
		},
		{
			lineUuid: '6b8bc99f-f7b3-47d0-b284-c382e3c761c2',
			numero: 648,
			horodatage: 1700816154988,
			libelleTarif: '',
			numTarif: 0,
			libelle: 'Frite',
			code: 'FRIT_CLAS',
			prix: 0,
			prixUnitaireCarte: 3,
			qte: 1,
			poids: 1,
			unite: 'PIECE',
			ticketUuid: 'f343fb65-3b2f-481d-acd7-20c97ba4dc0b',
			typeLigne: 'ARTICLE',
			numClef: 0,
			nomClef: 'KIOSK',
			codeManager: 0,
			nomManager: 'KIOSK',
			codeVendeur: 0,
			nomVendeur: 'KIOSK',
			libFamille: "P'tites Faims",
			libSousFamille: '',
			libGroupe: 'BCHEF',
			numFamille: 23,
			numSousFamille: 0,
			numGroupe: 1,
			libelleTVA: 'TVA 10%',
			numTVA: 1000,
			tauxTVA: 10,
			libCaisse: '192.168.100.51',
			numCaisse: 1,
			remiseMontantTTC: 0,
			remiseMontantHT: 0,
			remiseTaux: 0,
			serviceTaux: 0,
			serviceMode: 'AUCUN',
			serviceMontant: 0,
			montantTotalTTC: 0,
			montantTotalHT: 0,
			deviseCode: 'euro',
			deviseTaux: 1,
			deviseLibelle: 'euro',
			deviseMontant: 0,
			tag: 'product_id=648&third_party_id=7414&a_la_carte_price=3',
			libelleModifier: '',
			libelleQualifier: '',
			change: false,
			filter: 1,
			parentLineUuid: 'aaa37a60-47ae-48c3-bb84-d03d54393087',
		},
		{
			lineUuid: 'e64e3787-065b-403c-ac3a-408869502cc0',
			numero: 808,
			horodatage: 1700816154989,
			libelleTarif: '',
			numTarif: 0,
			libelle: 'Thé Glacé Pêche & Mangue - L',
			code: 'THE_GLAC_PECH_LARG',
			prix: 0.9,
			prixUnitaireCarte: 4.2,
			qte: 1,
			poids: 1,
			unite: 'PIECE',
			ticketUuid: 'f343fb65-3b2f-481d-acd7-20c97ba4dc0b',
			typeLigne: 'ARTICLE',
			numClef: 0,
			nomClef: 'KIOSK',
			codeManager: 0,
			nomManager: 'KIOSK',
			codeVendeur: 0,
			nomVendeur: 'KIOSK',
			libFamille: 'Boissons',
			libSousFamille: '',
			libGroupe: 'BCHEF',
			numFamille: 26,
			numSousFamille: 0,
			numGroupe: 1,
			libelleTVA: 'TVA 10%',
			numTVA: 1000,
			tauxTVA: 10,
			libCaisse: '192.168.100.51',
			numCaisse: 1,
			remiseMontantTTC: 0,
			remiseMontantHT: 0,
			remiseTaux: 0,
			serviceTaux: 0,
			serviceMode: 'AUCUN',
			serviceMontant: 0,
			montantTotalTTC: 0,
			montantTotalHT: 0,
			deviseCode: 'euro',
			deviseTaux: 1,
			deviseLibelle: 'euro',
			deviseMontant: 0,
			tag: 'product_id=808&third_party_id=7414&a_la_carte_price=4.2',
			libelleModifier: '',
			libelleQualifier: '',
			change: false,
			filter: 1,
			parentLineUuid: 'aaa37a60-47ae-48c3-bb84-d03d54393087',
		},
		{
			lineUuid: '87745040-553e-46a3-939f-8630533322b4',
			numero: 0,
			horodatage: 1700816154989,
			libelleTarif: '',
			numTarif: 0,
			libelle: '',
			code: '',
			prix: 0,
			prixUnitaireCarte: 0,
			qte: 0,
			poids: 0,
			unite: 'PIECE',
			ticketUuid: 'f343fb65-3b2f-481d-acd7-20c97ba4dc0b',
			typeLigne: 'MIDDLE_TEXT',
			numClef: 0,
			nomClef: 'KIOSK',
			codeManager: 0,
			nomManager: 'KIOSK',
			codeVendeur: 0,
			nomVendeur: 'KIOSK',
			libFamille: '',
			libSousFamille: '',
			libGroupe: '',
			numFamille: -1,
			numSousFamille: -1,
			numGroupe: -1,
			libelleTVA: '',
			numTVA: -1,
			tauxTVA: 0,
			libCaisse: '192.168.100.51',
			numCaisse: 1,
			remiseMontantTTC: 0,
			remiseMontantHT: 0,
			remiseTaux: 0,
			serviceTaux: 0,
			serviceMode: 'AUCUN',
			serviceMontant: 0,
			montantTotalTTC: 0,
			montantTotalHT: 0,
			deviseCode: '',
			deviseTaux: 0,
			deviseLibelle: '',
			deviseMontant: 0,
			libelleModifier: '',
			libelleQualifier: '',
			change: false,
			tag: '{"text":"** KIOSK - SUR PLACE **","style":"BOLD","size":"NORMAL","align":"CENTER","separator":"NONE","lineBreakBefore":0,"lineBreakAfter":0,"qrcode":"","document":"TICKET","title":""}',
		},
		{
			lineUuid: '7f95a436-b818-405c-88a7-390ef90f5ba1',
			numero: 0,
			horodatage: 1700816154989,
			libelleTarif: '',
			numTarif: 0,
			libelle: '',
			code: '',
			prix: 0,
			prixUnitaireCarte: 0,
			qte: 0,
			poids: 0,
			unite: 'PIECE',
			ticketUuid: 'f343fb65-3b2f-481d-acd7-20c97ba4dc0b',
			typeLigne: 'MIDDLE_TEXT',
			numClef: 0,
			nomClef: 'KIOSK',
			codeManager: 0,
			nomManager: 'KIOSK',
			codeVendeur: 0,
			nomVendeur: 'KIOSK',
			libFamille: '',
			libSousFamille: '',
			libGroupe: '',
			numFamille: -1,
			numSousFamille: -1,
			numGroupe: -1,
			libelleTVA: '',
			numTVA: -1,
			tauxTVA: 0,
			libCaisse: '192.168.100.51',
			numCaisse: 1,
			remiseMontantTTC: 0,
			remiseMontantHT: 0,
			remiseTaux: 0,
			serviceTaux: 0,
			serviceMode: 'AUCUN',
			serviceMontant: 0,
			montantTotalTTC: 0,
			montantTotalHT: 0,
			deviseCode: '',
			deviseTaux: 0,
			deviseLibelle: '',
			deviseMontant: 0,
			libelleModifier: '',
			libelleQualifier: '',
			change: false,
			tag: '{"text":"PREPARATION : 843","style":"BOLD","size":"WX2HX2","align":"CENTER","separator":"NONE","lineBreakBefore":0,"lineBreakAfter":0,"qrcode":"","document":"TICKET","title":""}',
		},
		{
			lineUuid: 'e43dfe06-b50b-4f13-8fc0-1e19fb0099cf',
			numero: 0,
			horodatage: 1700816154989,
			libelleTarif: '',
			numTarif: 0,
			libelle: '',
			code: '',
			prix: 0,
			prixUnitaireCarte: 0,
			qte: 0,
			poids: 0,
			unite: 'PIECE',
			ticketUuid: 'f343fb65-3b2f-481d-acd7-20c97ba4dc0b',
			typeLigne: 'MIDDLE_TEXT',
			numClef: 0,
			nomClef: 'KIOSK',
			codeManager: 0,
			nomManager: 'KIOSK',
			codeVendeur: 0,
			nomVendeur: 'KIOSK',
			libFamille: '',
			libSousFamille: '',
			libGroupe: '',
			numFamille: -1,
			numSousFamille: -1,
			numGroupe: -1,
			libelleTVA: '',
			numTVA: -1,
			tauxTVA: 0,
			libCaisse: '192.168.100.51',
			numCaisse: 1,
			remiseMontantTTC: 0,
			remiseMontantHT: 0,
			remiseTaux: 0,
			serviceTaux: 0,
			serviceMode: 'AUCUN',
			serviceMontant: 0,
			montantTotalTTC: 0,
			montantTotalHT: 0,
			deviseCode: '',
			deviseTaux: 0,
			deviseLibelle: '',
			deviseMontant: 0,
			libelleModifier: '',
			libelleQualifier: '',
			change: false,
			tag: '{"text":"Merci de vous diriger vers la caisse prévue à cet effet.","style":"BOLD","size":"NORMAL","align":"CENTER","separator":"NONE","lineBreakBefore":0,"lineBreakAfter":0,"qrcode":"","document":"ORDER","title":""}',
		},
		{
			lineUuid: 'c2ce3384-1c46-4d51-8456-d8b85f1bb60e',
			numero: 0,
			horodatage: 1700816154989,
			libelleTarif: '',
			numTarif: 0,
			libelle: '',
			code: '',
			prix: 0,
			prixUnitaireCarte: 0,
			qte: 0,
			poids: 0,
			unite: 'PIECE',
			ticketUuid: 'f343fb65-3b2f-481d-acd7-20c97ba4dc0b',
			typeLigne: 'MIDDLE_TEXT',
			numClef: 0,
			nomClef: 'KIOSK',
			codeManager: 0,
			nomManager: 'KIOSK',
			codeVendeur: 0,
			nomVendeur: 'KIOSK',
			libFamille: '',
			libSousFamille: '',
			libGroupe: '',
			numFamille: -1,
			numSousFamille: -1,
			numGroupe: -1,
			libelleTVA: '',
			numTVA: -1,
			tauxTVA: 0,
			libCaisse: '192.168.100.51',
			numCaisse: 1,
			remiseMontantTTC: 0,
			remiseMontantHT: 0,
			remiseTaux: 0,
			serviceTaux: 0,
			serviceMode: 'AUCUN',
			serviceMontant: 0,
			montantTotalTTC: 0,
			montantTotalHT: 0,
			deviseCode: '',
			deviseTaux: 0,
			deviseLibelle: '',
			deviseMontant: 0,
			libelleModifier: '',
			libelleQualifier: '',
			change: false,
			tag: '{"text":"A PAYER :843","style":"BOLD","size":"WX2HX2","align":"CENTER","separator":"NONE","lineBreakBefore":0,"lineBreakAfter":0,"qrcode":"","document":"ORDER","title":""}',
		},
	],
	complements: [{ cle: 'NUM_ORB', valeur: '843', type: 'STRING', complementId: 0 }],
};

export class Ingredient {
	id: string;
	price: number;
	ingredientId: number;

	constructor(
		private readonly ingredient: IngredientType,
		private readonly langCode: string,
		private readonly getImgPath: (ingredient: IBKIngredientData) => string,
		public qty?: number
	) {
		this.qty = this.qty ?? this.ingredient.initialQty;
		this.id = generateUUID();
		this.ingredientId = this.ingredient.id;
		this.price = this.ingredient.priceTTC;
	}

	get name(): string {
		return this.ingredient._name?.[this.langCode] ?? '';
	}

	get extraPrice(): number {
		const extraQty = this.qty - this.ingredient.initialQty;
		const extraPrice = extraQty * this.ingredient.priceTTC;

		return extraPrice > 0 ? extraPrice : 0;
	}

	get canDecrementQty(): boolean {
		const { canModify, minQty } = this.ingredient;
		return canModify && minQty < this.qty;
	}

	get canIncrementQty(): boolean {
		const { canModify, maxQty } = this.ingredient;
		return canModify && maxQty > this.qty;
	}

	get imgPath(): string {
		return this.getImgPath(this.ingredient);
	}

	get ingredientData(): IBKProductIngredientData {
		return this.ingredient;
	}

	decrementQty(qty: number = -1): void {
		if (!this.canDecrementQty) {
			return;
		}

		this.qty -= Math.abs(qty);
	}

	incrementQty(qty: number = 1): void {
		if (!this.canIncrementQty) {
			return;
		}

		this.qty += qty;
	}
}
