import { SESSION_TYPE } from '@bk/fullstack-common';
import { IS_LOGGED_IN_USER_ROLES } from '@libs/shared/models';

export interface IClerkLogin {
	codeVendeur: number;
	nomVendeur: string;
	codeClef: number;
	nomClef: string;
	codeManager: number;
	nomManager: string;
	fondCaisse?: number;
	sessionType: SESSION_TYPE;
	session: LOGIN_SESSION;
	initialAmounts: IInitialAmount[] | null;
	metadata: ILoginMetadata;
}

export interface IClerkLoginResponse {
	code: number;
	jwt: string;
	responses: any[];
}

export interface IClerkIsLoggedResponse {
	code: number;
	response: Record<IS_LOGGED_IN_USER_ROLES, IClerkIsLoggedUserData>;
}

export interface IClerkIsLoggedUserData {
	userId: number;
	userName: string;
}

export interface IInitialAmount {
	paymentMethodId: number;
	amount: number;
}

export interface IAdditionalInformationLogin {
	sessionType: SESSION_TYPE;
	session: LOGIN_SESSION;
	initialAmounts: IInitialAmount[] | null;
	metadata: ILoginMetadata;
}

export interface ILoginMetadata {
	[key: string]: string | number;
}

export enum LOGIN_SESSION {
	EXPIRED = 'Expired',
}
