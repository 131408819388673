/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BrandConfigurationWebhookEvent } from './brand-configuration-webhook-event';


export interface BrandConfigurationWebhook { 
    url: string;
    /**
     * Any string that should be sent in **Authorization: Bearer XXX** header for authentication towards target URL.
     */
    bearer?: string;
    /**
     * Events that should be notified towards set URL. If empty list, no webhook will be sent.
     */
    events?: Array<BrandConfigurationWebhookEvent>;
}

