import { IBKIngredientData, IBKItemInOrderBase, IBKProductIngredientSelectedData, ISelectedIngredients } from "@bk/jscommondatas";
import { Shar3dUtils } from "@shar3d/shar3d-utils";

export function patchIngrediences(orderContent: IBKItemInOrderBase[], ingredients: { [id: string]: IBKIngredientData }): void {
	const createIngredient = (selectedIngredient: ISelectedIngredients): IBKProductIngredientSelectedData => {
		const ingredient = ingredients[selectedIngredient.id];
		const ingredientToAdd = {
			selected: selectedIngredient.id,
			initial: selectedIngredient.id,
			amount: 0,
			initAmount: 0,
			qty: 1,
			initQty: 0,
			priceTTC: selectedIngredient.price.ttc,
			sysName: ingredient.sysName,
			modified: true,
			line: 0,
			lineuuid: Shar3dUtils.generateUUID(),
			timestamp: Shar3dUtils.now(),
			price: undefined,
			ref: selectedIngredient.ref,
			selectedIngredients: [],
		}
		return ingredientToAdd;
	}

	orderContent.forEach((item, idx) => {
		const updatedRecipe = [];
		item.recipe?.forEach((ingredient) => {
			if(ingredient.selectedIngredients?.length > 0) {
				ingredient.selectedIngredients.forEach((selIngredient) => {
					const presentIngredient = updatedRecipe.find((ing) => ing.selected === selIngredient.id);
					if(presentIngredient) {
						presentIngredient.qty += 1;
					} else {
						updatedRecipe.push(createIngredient(selIngredient));
					}
				});
			} else {
				updatedRecipe.push(ingredient)
			}
		});
		item.recipe = updatedRecipe;
		
		item.selection?.forEach((selection) => {
			selection.recipe?.forEach((ingredient) => {
				if(ingredient.selectedIngredients?.length > 0) {
					ingredient.selectedIngredients.forEach((selIngredient) => {
						const presentIngredient = updatedRecipe.find((ing) => ing.selected === selIngredient.id);
						if(presentIngredient) {
							presentIngredient.qty += 1;
						} else {
							updatedRecipe.push(createIngredient(selIngredient));
						}
					});
				} else {
					updatedRecipe.push(ingredient)
				}
				selection.recipe = updatedRecipe;
			});
		});
	});
}