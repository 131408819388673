export function getFileExtension(filename: string): string {
	const split = filename.split('.');
	return split[split.length - 1];
}

export function isFilePathRelative(path: string): boolean {
	return path.startsWith('.');
}

/**
 * Take filePath (e.g. "/var/www/BKBoLocal/configlocal/current/available.json")
 * and returns tuple ["/var/www/BKBoLocal/configlocal/current/", "available.json"]
 */
export function splitFilePathToDirectoryAndFileName(path: string): [string, string] {
	let splitChar = '';
	if (path.includes('/')) {
		splitChar = '/';
	} else if (path.includes('\\')) {
		splitChar = '\\';
	}
	const split = path.split(splitChar);
	const fileName = split[split.length - 1];
	const fileDir = path.substring(0, path.length - fileName.length);
	return [fileDir, fileName];
}


export function getFileNameFromPath(path: string): string {
	return splitFilePathToDirectoryAndFileName(path)[1];
}