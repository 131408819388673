import { AvailableJson, Ingredient } from "@bk/fullstack-common";
import { BKDeliveryModeEnum } from "@bk/jscommondatas";

import { ProductPriceType } from "../enums";
import { GlobalPriceDefinition, LocalPriceDefinitionPerSalesChannel } from "../types";
import { convertGlobalPriceToLocalPrice } from "./convert-global-price-to-local-price";
import { getApplicablePriceForSalesChannel } from "./get-price-for-sales-channel";
import { mapDeliveryModeToSalesChannelOption } from "./map-delivery-mode-to-sales-channel-option";
import { mergeLocalPriceDefinitions } from "./merge-local-price-definitions";
import { getLocalPriceDefinitions } from "./get-local-price-definitions";

/**
 * Used in POS and KIOSK.
 * Patch prices for given Ingredient based on currectly selected DeliveryMode/SalesChannelOption.
 * The price definitions are read and combined from both bigDatas.json and available.json
 */
export function patchIngredientPrice(
	ingredient: Ingredient,
	deliveryMode: BKDeliveryModeEnum,
	availableJson: AvailableJson,
	globalPrices: GlobalPriceDefinition[]): Ingredient {

	const salesChannelOption = mapDeliveryModeToSalesChannelOption(deliveryMode);

	const localPrices = getLocalPriceDefinitions('INGREDIENT', salesChannelOption, availableJson, ingredient.id);
	const defaultPrices = globalPrices
		.filter(gp => gp.salesChannelOption === salesChannelOption)
		.map(gp => convertGlobalPriceToLocalPrice(gp));
	const mergedPriceDefinitions: LocalPriceDefinitionPerSalesChannel[] = mergeLocalPriceDefinitions(defaultPrices, localPrices, globalPrices);

	// Ingredients only have "Solo price" rowIdentifier
	// As far as I know, there should not be other rows.
	const soloPrices = mergedPriceDefinitions.filter(p => p.rowIdentifier === ProductPriceType.Solo);

	// Sanity check
	if (soloPrices.length > 1) {
		const errMessage = `patchIngredientPrice(): Duplicated price definitions found. Length was ${soloPrices.length}`;
		console.error(errMessage);
		throw new Error(errMessage);
	}

	const applicablePrice = getApplicablePriceForSalesChannel(soloPrices[0]);

	if (applicablePrice !== undefined) {
		ingredient.priceTTC = applicablePrice;
		ingredient.isDisabled = false;
	} else {
		ingredient.priceTTC = 0;
		ingredient.isDisabled = true;
	}

	return ingredient;
}
