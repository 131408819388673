import { MenuSize, ProductPriceType, SalesChannelOption } from '@bk/price-management-common';

export type LocalPriceDefinitionPerSalesChannel = {
	salesChannelOption: SalesChannelOption;
	rowIdentifier: MenuSize | ProductPriceType;
	/**
	 * Price defined Globally for this PriceLevel
	 *
	 * IMPORTANT NOTE:
	 * NOT to be used in Kiosk or POS. RAP-BE does not update this value automatically.
	 * It was intended to be used in RAP-BE => RAP API communication.
	 * KIOSK and POS must get the reference price from BigDatas.json.
	 */
	referencePrice: number;
	/**
	 * Optional.
	 * Localy defined Price overriding the global one.
	 * Can be read only when "isDisabled === false"
	 */
	localPrice?: number;
	/**
	 * When there is no global price defined, then the field is disabled in local restaurant.
	 * Meaning that this salesChannelOption will not offer that product.
	 */
	isDisabled: boolean;
};
