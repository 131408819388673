import { flattenArray, getUniqueValues } from "@merim/utils";
import { SalesChannel } from "../../enums";
import { OrbpDetail, SalesChannelConfiguration } from "../../types";
import { isActiveMaster } from "../is-role";
import { isUsingDeviceSpecificConfiguration } from "../sales-channels";
import { getAssignedDevicesToOrbp } from "./get-assigned-devices-to-orbp";

/**
 * Returns list of devices of given type which are assigned to some active ORBp.
 */
export function getAssignedDevicesInMasters(salesChannel: SalesChannel, masterOrbps: OrbpDetail[]): string[] {
	// Find ORBp which are using device-specific configuration
	const mastersWithSpecificDevices = masterOrbps.filter(orbp => {
		if (!isActiveMaster(orbp)) {
			return false
		}

		const matchingScConfig: SalesChannelConfiguration = orbp.salesChannelConfigurations.find(x => x.salesChannel === salesChannel);

		return isUsingDeviceSpecificConfiguration(matchingScConfig);
	});

	// Get all devices which are assigned to some ORBp
	const assignedDevices = flattenArray(mastersWithSpecificDevices.map(orbp => {
		return getAssignedDevicesToOrbp(salesChannel, orbp);
	}));

	return getUniqueValues(assignedDevices).sort();
}
