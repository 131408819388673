/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FoodCategoryIcon } from './food-category-icon';


export interface ConfigurationRestaurantFoodCategory { 
    /**
     * Name of the food category to be displayed.
     */
    name: string;
    /**
     * Icon to be displayed for given food category. If not set, default icon will be used.
     */
    icon?: FoodCategoryIcon;
    /**
     * Ids of product families included in the food category.
     */
    productFamilyIds: Array<number>;
    /**
     * Hexadecimal color to be used for food category. If not set, predefined colors will be used.
     */
    color?: string;
}

