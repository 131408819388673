/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PaymentMethodDto } from './payment-method-dto';
import { VatRateDto } from './vat-rate-dto';


export interface FiscalDto { 
    provider: FiscalDto.ProviderEnum;
    /**
     * TPE merchant ID.
     */
    tpeIdMerchant?: string;
    /**
     * URL for Nepting.
     */
    neptingUrl?: string;
    /**
     * Day of the year when fiscal year starts (format MM-DD). Leap year not supported. Mandatory for Plutus fiscal core.
     */
    fiscalYearStartDate?: string;
    /**
     * Id of the payment method that wil be used for operations during the closure of the day. Mandatory for Plutus fiscal core.
     */
    closurePaymentMethod?: number;
    /**
     * All the payment methods available in the restaurant with their configurations. Mandatory for Plutus fiscal core.
     */
    paymentMethods?: Array<PaymentMethodDto>;
    /**
     * All the VAT rates applicable in the restaurant.
     */
    vatRates: Array<VatRateDto>;
}
export namespace FiscalDto {
    export type ProviderEnum = 'plutus' | 'csi' | 'zelty';
    export const ProviderEnum = {
        Plutus: 'plutus' as ProviderEnum,
        Csi: 'csi' as ProviderEnum,
        Zelty: 'zelty' as ProviderEnum
    };
}


