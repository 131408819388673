import { WeatherType } from '../enums';

// The forecasting is still WIP by Gauss
// TODO - see the draft of forecasting https://dash-api-dev4.reboot-qsr.com/docs#/forecasting/fake_products_forecast_v1_forecasting_fake_products_forecast_get
export type WeatherInfo = {
	/**
	 * Air Temperature is in Celsius
	 */
	temperature: number;

	/**
	 * Air temperature max - celsius
	 */
	temperatureMax: number;

	/**
	 * Air temperature min - celsius
	 */
	temperatureMin: number;

	/**
	 * In meters per seconds.
	 */
	windSpeed: number;

	/**
	 * In percentages
	 */
	cloudCover: number;

	/**
	 * Amount of water leaking from the sky :)
	 * In milimeters per square meter.
	 */
	precipitation: number;

	/**
	 * The overall status for the whole period (day).
	 */
	overallStatus: WeatherType;
};
