import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, share } from 'rxjs';
import { map } from 'rxjs/operators';
import { ETAG, HTTP_STATUS_CODE_NO_CHANGE, ICache, IF_NONE_MATCH } from '../models';

@Injectable()
export class EtagInterceptor implements HttpInterceptor {
	private cache: Map<string, ICache> = new Map();

	constructor() {}

	intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		if (req.method !== 'GET') {
			return next.handle(req);
		}
		const update = {};
		const etagValue = this.cache.get(req.urlWithParams);
		if (etagValue) {
			update[IF_NONE_MATCH] = etagValue.etag;
		}

		const request = etagValue ? req.clone(update) : req;

		return next.handle(request).pipe(
			map((response) => {
				if (response instanceof HttpResponse) {
					if (response.status === HTTP_STATUS_CODE_NO_CHANGE) {
						return this.cache.get(req.urlWithParams)?.response;
					} else {
						this.cache.set(req.urlWithParams, { etag: response.headers.get(ETAG), response: response.clone() });
					}
				}
				return response;
			}),
			share()
		);
	}
}
