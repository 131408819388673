/**
 * DKI-BE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { WeatherOverallStatus } from './weather-overall-status';


export interface WeatherHourlyStatus {
	/**
	 * Air temperature at two meters above ground level. Units dependent on the unitcode setting. [C]
	 */
	air_temp: number;
	/**
	 * Cloud cover data. Cloud cover refers to the percentage of the sky that is covered by clouds. [%]
	 */
	cloud_cover: number;
	/**
	 * Hourly liquid-equivalent precipitation accumulation data. [mm]
	 */
	precip_acc: number;
	/**
	 * Hourly liquid accumulation data. [mm]
	 */
	liquid_precip_acc: number;
	/**
	 * Hourly snow accumulation data. [mm]
	 */
	snow_precip_acc: number;
	/**
	 * Wind speed at ten meters above ground level. Units dependent on the unitcode setting. [m/s]
	 */
	wind_speed: number;
	/**
	 * Overall Weather Status: clear_sky, few_clouds, scattered_clouds, broken_clouds, overcast_clouds, shower_rain, light_rain, rain, thunderstorm, snow
	 */
	over_all_status: WeatherOverallStatus;
}

