import { Injectable, OnDestroy } from '@angular/core';
import { MESSAGE_TOPICS, SingleAPIConfig } from '@libs/shared/models';

import { Shar3dDesktopBaseService } from '@libs/shared/modules/shar3dDesktop';
import { ConfigurationService, WebsocketService } from '@libs/shared/services';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Injectable()
export class MessageSenderService implements OnDestroy {
	private readonly ngUnsubscribe$ = new Subject<void>();
	websocketConnection: string = '';

	constructor(
		private configurationService: ConfigurationService<any>,
		private websocketService: WebsocketService<any>,
		private shar3dDesktopBaseService: Shar3dDesktopBaseService
	) {
		this.configurationService
			.getApiDefinition$('websockets', 'ring')
			.pipe(
				takeUntil(this.ngUnsubscribe$),
				filter((data) => !!data)
			)
			.subscribe((data: SingleAPIConfig) => {
				this.websocketConnection = data.url;
			});
	}

	sendMessage(topic: MESSAGE_TOPICS, message: Record<any, any> | string): void {
		if (this.shar3dDesktopBaseService.getRingConnectorService.isHostShared) {
			this.shar3dDesktopBaseService.getRingConnectorService.sendMessage(topic, message);
		}

		if (this.websocketConnection) {
			this.websocketService.sendMessage(topic, message);
		}
	}

	ngOnDestroy() {
		this.ngUnsubscribe$.next();
		this.ngUnsubscribe$.complete();
	}
}
