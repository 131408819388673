import { BKOrbRapOrder } from '../models/bk-orb-rap-order';
import { getOrderIdToDisplay } from './get-order-id-to-display';

/**
 * Unique identifier of Order, but human readable.
 * Can be of course used for other purpose then debugging.
 */
export function getOrderDebuggingId(order: BKOrbRapOrder): string {
	const idToDisplay = getOrderIdToDisplay(order);
	const orderId = `${idToDisplay}_${order.orderUUID}`; // format for better debugging
	return orderId;
}
