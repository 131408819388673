/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Brand } from './brand';
import { ConfigurationRestaurantFiscal } from './configuration-restaurant-fiscal';
import { ConfigurationRestaurantInfo } from './configuration-restaurant-info';
import { ConfigurationRestaurantWeborders } from './configuration-restaurant-weborders';
import { ConfigurationRestaurantLanguages } from './configuration-restaurant-languages';
import { ConfigurationRestaurantLegal } from './configuration-restaurant-legal';
import { ConfigurationRestaurantFeatures } from './configuration-restaurant-features';
import { Environment } from './environment';
import { ConfigurationRestaurantProductList } from './configuration-restaurant-product-list';
import { ConfigurationRestaurantOrders } from './configuration-restaurant-orders';
import { ConfigurationRestaurantFidelity } from './configuration-restaurant-fidelity';


/**
 * Restaurant information from MySQL.
 */
export interface ConfigurationGlobal { 
    brand: Brand;
    environment: Environment;
    restaurant: ConfigurationRestaurantInfo;
    languages: ConfigurationRestaurantLanguages;
    legal?: ConfigurationRestaurantLegal;
    fiscal: ConfigurationRestaurantFiscal;
    fidelity?: ConfigurationRestaurantFidelity;
    weborders: ConfigurationRestaurantWeborders;
    features: ConfigurationRestaurantFeatures;
    orders: ConfigurationRestaurantOrders;
    productList: ConfigurationRestaurantProductList;
}

