/**
 * DKI-BE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 */
export type SourceDrive = 0 | 1 | 2 | 3 | 4 | 5 | 6 | -1;

export const SourceDrive = {
	NUMBER_0: 0 as SourceDrive,
	NUMBER_1: 1 as SourceDrive,
	NUMBER_2: 2 as SourceDrive,
	NUMBER_3: 3 as SourceDrive,
	NUMBER_4: 4 as SourceDrive,
	NUMBER_5: 5 as SourceDrive,
	NUMBER_6: 6 as SourceDrive,
	NUMBER_MINUS_1: -1 as SourceDrive
};

