/**
 * DKI-BE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Role } from './role';


export interface Sos {
	minuteofday: number;
	state: number;
	src: number;
	deliverymode: number;
	roleidx: number;
	samecar: boolean;
	tabletorder: boolean;
	role: Role;
	hardwaittime?: string;
	creationtime?: string;
	hardcodleavetime?: string;
	endtime?: string;
	harddeparturetime?: string;
	ordertime?: number;
	waittime?: number;
	linetime?: number;
	paytime?: number;
	hardservetime?: number;
	servetime?: number;
	parktime?: number;
}

