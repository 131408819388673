/**
 * DKI-BE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface RestaurantOwnerInput {
	/**
	 * Registered id of the legal entity/natural person.
	 */
	entity_id: string;
	/**
	 * Legal registered name of the entity.
	 */
	name: string;
	/**
	 * Legal registered VAT number of the entity.
	 */
	vat_number: string;
	/**
	 * Registered business code for the type of business conducted by the entity.
	 */
	business_code?: string;
	/**
	 * Address of main registered office of the entity.
	 */
	address: string;
	/**
	 * Additional address information of main registered office if needed.
	 */
	address_extra?: string;
	/**
	 * ZIP code of the registered office.
	 */
	zip_code: string;
	/**
	 * City of the registered office.
	 */
	city: string;
	/**
	 * Country of the registered office.
	 */
	country: string;
	/**
	 * Time of the day when business day starts (for the accounting purpose). Format: \'HH:MM\'.
	 */
	business_day_start_time: string;
	/**
	 * Day of the year when fiscal year starts (for the accounting purpose). Format: \'mm-dd\'.
	 */
	fiscal_year_start_date: string;
}

