/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PredictionKitchenSalesMapDto { 
    /**
     * Predicted item ID.
     */
    id: string;
    /**
     * Sales prediction in pieces (user defined or automatic if not defined by user), treated by Math.ceil().
     */
    prediction: number;
}

