/**
 * Pay types
 */
export enum BKOrderPayType {
	PAID_BY_UNKNOWN = 0,
	PAID_BY_CARD = 1,
	PAID_BY_CASH = 2,
	PAID_BY_CHECK = 3,
	PAID_BY_TICKET = 4,
	PAID_BY_HOLIDAYS_TICKET = 5,
	PAID_BY_CHECK_DEJ = 6,
	PAID_BY_CRT = 7,
	PAID_BY_CONECS = 8,
	PAID_BY_CARD_MOBILE = 9,
	PAID_BY_ICOUPON = 10,
	PAID_BY_DELIVEROO = 11,
	PAID_BY_UBER_EAT = 12,
	PAID_BY_JUST_EAT = 13,
	PAID_BY_ANCV_CONNECT = 14,
	PAID_BY_CONECSAPETIZ = 16,
	PAID_BY_CONECSCHEQDEJ = 17,
	PAID_BY_CONECSEDENRED = 18,
	PAID_BY_CONECSSODEXO = 19,
	PAID_BY_PLEASE = 20,
	PAID_BY_CHEQUE_CADEAU = 21,
	PAID_BY_CARD_GR = 22,
	PAID_BY_ZOT = 23,
	LYVEAT = 24,
	PAID_BY_DELIVERY_BK = 25,
	PAID_BY_MONNAYEUR_CI10 = 26,
	PAID_BY_DEFERRED = 27,
	PAID_BY_TOO_GOOD_TO_GO = 28,
	PAID_BY_REPAS_EMPLOYES = 100,
}

export enum BKOrderPayNames {
	MONNAYEUR_CI10 = 'MONNAYEUR CI10'
}