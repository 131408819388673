/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FidelityFilterDto } from './fidelity-filter-dto';
import { TimePlanningDto } from './time-planning-dto';
import { CampaignDaypartSelectionDto } from './campaign-daypart-selection-dto';
import { CampaignDaypartPriceDto } from './campaign-daypart-price-dto';
import { CampaignNavigationMenuDto } from './campaign-navigation-menu-dto';


export interface CampaignDto { 
    /**
     * Flag indicating if the event is hourly limited during the day.
     */
    hourlyLimited?: boolean;
    /**
     * Start hour during the day if the event is \"hourlyLimited\". Must be lower than \"endHour\".
     */
    startHour: number;
    /**
     * Start minute during the day if the event is \"hourlyLimited\".
     */
    startMinute: number;
    /**
     * End hour during the day if the event is \"hourlyLimited\". Must be higher than \"startHour\".
     */
    endHour: number;
    /**
     * End minute during the day if the event is \"hourlyLimited\".
     */
    endMinute: number;
    /**
     * Mask for the days of the week when the event is active.         Values (DayOfWeekMask):         MONDAY = 1,         TUESDAY = 2,         WEDNESDAY = 4,         THURSDAY = 8,         FRIDAY = 16,         SATURDAY = 32,         SUNDAY = 64
     */
    dayOfWeekMask?: number;
    /**
     * Id of the campaign.
     */
    id: number;
    /**
     * Name of the campaign.
     */
    name: string;
    /**
     * Flag if the campaign is enabled (available) by default.
     */
    enable: boolean;
    /**
     * Unix timestamp of the campaign\'s start in milliseconds.
     */
    startDateTime: number;
    /**
     * Unix timestamp of the campaign\'s end in milliseconds.
     */
    endDateTime: number;
    /**
     * Ids of products referenced by the campaign.
     */
    productsInCampain: Array<number>;
    /**
     * Ids of menus referenced by the campaign.
     */
    menusInCampain: Array<number>;
    /**
     * Ids of ingredients referenced by the campaign.
     */
    ingredientsInCampain: Array<number>;
    /**
     * Ids of medias referenced by the campaign.
     */
    mediasInCampain: Array<number>;
    /**
     * Ids of media playlists referenced by the campaign.
     */
    mediaPlaylistsInCampain: Array<number>;
    /**
     * Ids of options referenced by the campaign.
     */
    optionsInCampain: Array<number>;
    /**
     * Ids of discounts referenced by the campaign.
     */
    discountInCampain: Array<number>;
    /**
     * Ids of suggestions referenced by the campaign.
     */
    suggestionInCampaign: Array<number>;
    /**
     * Ids of coupons referenced by the campaign.
     */
    couponInCampaign?: Array<number>;
    /**
     * Ids of games referenced by the campaign.
     */
    gameInCampaign?: Array<number>;
    /**
     * Kiosk navigations for the campaign.
     */
    kioskNavigationInCampaign?: Array<CampaignNavigationMenuDto>;
    /**
     * POS/DRIVEPAY/DRIVECO navigations for the campaign.
     */
    cashNavigationInCampaign?: Array<CampaignNavigationMenuDto>;
    /**
     * Flag indicating if the campaign is technically not a campaign but an override for prices/availability during different time of a day (e.g. breakfast options).
     */
    daypart?: boolean;
    /**
     * Selection of dayparts for the campaign.
     */
    daypartSelection?: Array<CampaignDaypartSelectionDto>;
    /**
     * Overrides for products\' regular prices.
     */
    daypartProductPrices?: Array<CampaignDaypartPriceDto>;
    /**
     * Overrides for products\' extra menu prices (lower prices when added to menu).
     */
    daypartProductExtraPrices?: Array<CampaignDaypartPriceDto>;
    /**
     * Overrides for menus\' regular prices.
     */
    daypartMenuPrices?: Array<CampaignDaypartPriceDto>;
    /**
     * Overrides for ingredients\' regular prices.
     */
    daypartIngredientPrices?: Array<CampaignDaypartPriceDto>;
    /**
     * Mask for the order sources this campaign may be used for. Default 0 means any order source.
     */
    sourceMask?: number;
    /**
     * Time planning for the dayparts of the campaign.
     */
    planifications?: Array<TimePlanningDto>;
    /**
     * Information on how the fidelity program is applied to the campaign.
     */
    fidelity?: FidelityFilterDto;
}

