/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { WeborderChannel } from './weborder-channel';


export interface BrandConfigurationWeborderChannelManagement { 
    /**
     * Toggle for module in GAP.
     */
    enabled?: boolean;
    /**
     * Weborder channels allowed for brand. If not set, may not be utilized anywhere.
     */
    weborder_channels?: Array<WeborderChannel>;
}

