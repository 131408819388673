export function saveToSessionStorage(key: string, value: any): void {
	try {
		sessionStorage.setItem(key, JSON.stringify(value));
	} catch (e) {
		console.error('[APP]: Error in storing a value in session storage.');
		return;
	}
}

export function readFromSessionStorage<T>(key: string): T {
	try {
		return JSON.parse(sessionStorage.getItem(key));
	} catch (e) {
		console.error('[APP]: Error in parsing a value from session storage.');
		return null;
	}
}

export function removeFromSessionStorage<T>(key: string): void {
	try {
		sessionStorage.removeItem(key);
	} catch (e) {
		return;
	}
}
