/**
 * DKI-BE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Discount {
	id: number;
	name: string;
	uuid: string;
	type: number;
	value: number;
	percent: boolean;
	total: number;
	total_excluding_vat: number;
	total_vat: number;
	manager_id?: number;
}

