import {
	ApiCredentialsStoreModel,
	AvailabilityStoreModel,
	BigDataStoreModel,
	CONFIGURATION_DOWNLOAD_TYPES,
	GlobalConfigStoreModel,
	RestoConfigStoreModel,
	RestoSettingsStoreModel,
} from '@libs/shared/models';

export type IRootState = {
	version?: string;
	configuration?: IFeatureConfigurationState;
};

export interface IFeatureConfigurationState {
	bigData: BigDataStoreModel;
	restoSettings: RestoSettingsStoreModel;
	availability: AvailabilityStoreModel;
	restoConfig: RestoConfigStoreModel;
	api: ApiCredentialsStoreModel;
	globalConfiguration: GlobalConfigStoreModel;
}

export type DownloadTypeState =
	| (CONFIGURATION_DOWNLOAD_TYPES.BIG_DATA & {
			data: BigDataStoreModel;
	  })
	| (CONFIGURATION_DOWNLOAD_TYPES.RESTO_SETTINGS & {
			data: RestoSettingsStoreModel;
	  })
	| (CONFIGURATION_DOWNLOAD_TYPES.AVAILABILITY & {
			data: AvailabilityStoreModel;
	  })
	| (CONFIGURATION_DOWNLOAD_TYPES.RESTO_CONFIG & {
			data: RestoConfigStoreModel;
	  });
