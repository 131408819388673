/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ToogleFoodCategoryDto { 
    /**
     * Id of the DSS program.
     */
    programId: string;
    /**
     * Id of the ORBp.
     */
    orbpId: string;
    /**
     * Food category that should be toggled.
     */
    foodCategory: string;
}

