import { Injectable } from '@angular/core';
import { IBKMachine } from '@bk/jscommondatas';
import { IBKTechShar3dInfos } from '@libs/shared/interfaces';
import { extractDeviceInformation } from '@libs/shared/utils';

import { select, Store } from '@ngrx/store';
import { filter, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { IDeviceRootState } from '../interfaces';
import { DeviceInformationLoad } from './actions';
import { deviceInfo, deviceInfoLoading } from './selectors';

@Injectable()
export class DeviceFacade {
	readonly ngUnsubscribe$: Subject<void> = new Subject<void>();

	constructor(private store: Store<IDeviceRootState>) {}

	loadDeviceInfo(defaultDeviceInfo?: IBKMachine) {
		this.store.dispatch(DeviceInformationLoad({ defaultDeviceInfo }));
	}

	isLoading(): Observable<boolean> {
		return this.store.pipe(select(deviceInfoLoading), takeUntil(this.ngUnsubscribe$));
	}

	getDeviceFullInfo(): Observable<IBKTechShar3dInfos> {
		return this.store.pipe(select(deviceInfo), takeUntil(this.ngUnsubscribe$));
	}

	getMachineInfo(): Observable<IBKMachine> {
		return this.store.pipe(
			select(deviceInfo),
			filter((item) => !!item),
			map((data) => extractDeviceInformation(data)),
			takeUntil(this.ngUnsubscribe$)
		);
	}

	unsubscribe(): void {
		this.ngUnsubscribe$.next();
		this.ngUnsubscribe$.complete();
	}
}
