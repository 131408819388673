/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PmsProgramsDto } from './pms-programs-dto';


export interface PmsOverallConfigurationDto { 
    /**
     * Active configuration id
     */
    activeProgramId: string;
    /**
     * Scheduled next quarter program id
     */
    scheduledNextProgramId: string;
    /**
     * Available programs
     */
    programs: Array<PmsProgramsDto>;
}

