/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SelectionPatternDto } from './selection-pattern-dto';


export interface KitchenScreenConfigDataDto { 
    /**
     * Id of the screen
     */
    screenIdx: number;
    /**
     * Source of the order
     */
    orderSource: Array<number>;
    /**
     * Filtering of orders
     */
    orderFilter?: number;
    /**
     * Paying state for display
     */
    displayPayingPOS?: boolean;
    /**
     * Paying state for display
     */
    displayPayingDrive?: boolean;
    /**
     * Paying state for display
     */
    displayPayingKiosk?: boolean;
    /**
     * Paying state for display
     */
    displayPayingWeb?: boolean;
    /**
     * Ingredient selection pattern
     */
    productPattern: Array<SelectionPatternDto>;
}

