/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UpdateSingleSalesChannelDto { 
    /**
     * Sales channel to be updated.
     */
    salesChannel: UpdateSingleSalesChannelDto.SalesChannelEnum;
    /**
     * Sales channel option to be updated.
     */
    option: UpdateSingleSalesChannelDto.OptionEnum;
    /**
     * New value for sales channel option within sales channel.
     */
    isEnabled: boolean;
}
export namespace UpdateSingleSalesChannelDto {
    export type SalesChannelEnum = 'UNKNOWN' | 'CASH_MACHINE' | 'KIOSK' | 'DRIVE_WINDOW' | 'WALK' | 'CLICK_AND_COLLECT' | 'HOME_DELIVERY' | 'THIRD_PARTY_DELIVERY';
    export const SalesChannelEnum = {
        Unknown: 'UNKNOWN' as SalesChannelEnum,
        CashMachine: 'CASH_MACHINE' as SalesChannelEnum,
        Kiosk: 'KIOSK' as SalesChannelEnum,
        DriveWindow: 'DRIVE_WINDOW' as SalesChannelEnum,
        Walk: 'WALK' as SalesChannelEnum,
        ClickAndCollect: 'CLICK_AND_COLLECT' as SalesChannelEnum,
        HomeDelivery: 'HOME_DELIVERY' as SalesChannelEnum,
        ThirdPartyDelivery: 'THIRD_PARTY_DELIVERY' as SalesChannelEnum
    };
    export type OptionEnum = 'TakeAway' | 'EatIn' | 'WaitingAtTable' | 'TableServiceInside' | 'TableServiceTerrasse' | 'DriveIn' | 'PickupParking' | 'DeliveryBK' | 'Deliveroo' | 'UberEats' | 'JustEat' | 'ZOT' | 'Lyveat' | 'Please' | 'DeliverectClickAndCollect';
    export const OptionEnum = {
        TakeAway: 'TakeAway' as OptionEnum,
        EatIn: 'EatIn' as OptionEnum,
        WaitingAtTable: 'WaitingAtTable' as OptionEnum,
        TableServiceInside: 'TableServiceInside' as OptionEnum,
        TableServiceTerrasse: 'TableServiceTerrasse' as OptionEnum,
        DriveIn: 'DriveIn' as OptionEnum,
        PickupParking: 'PickupParking' as OptionEnum,
        DeliveryBk: 'DeliveryBK' as OptionEnum,
        Deliveroo: 'Deliveroo' as OptionEnum,
        UberEats: 'UberEats' as OptionEnum,
        JustEat: 'JustEat' as OptionEnum,
        Zot: 'ZOT' as OptionEnum,
        Lyveat: 'Lyveat' as OptionEnum,
        Please: 'Please' as OptionEnum,
        DeliverectClickAndCollect: 'DeliverectClickAndCollect' as OptionEnum
    };
}


