/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PredictionWeatherDto } from './prediction-weather-dto';
import { PredictionRevenueDto } from './prediction-revenue-dto';


export interface PredictionDayDto { 
    /**
     * Revenue statistics and predictions for given business day.
     */
    revenue: PredictionRevenueDto;
    /**
     * Expected overall weather on the given business day. Only available on days with calculated prediction.
     */
    weather?: PredictionWeatherDto;
}

