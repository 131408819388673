import { ValidationMessageSeverityEnum } from "../enums";

export type ValidationMessage = RawValidationMessage | TranslatedValidationMessage;

export type ValidationMessageBase = {
	severity: ValidationMessageSeverityEnum;
	/**
	 * Name of the property which is invalid.
	 * Can be used to highlight matching field in UI.
	 *
	 * If property is undefined, then this is more generic message, not bound to any specific ORBp property.
	 */
	propertyName: string | undefined;
}

export type TranslatedValidationMessage = ValidationMessageBase & {
	/**
	 * Message to show to the user
	 */
	translatedMessage: string;

	/**
	 * Original message for easier debugging when the app runs in other language mutation
	 */
	message: string;
}

/**
 * This validation messages not yet been translated / localized.
 * It needs to be first converted to TranslatedValidationMessage before it can be displayed to user.
 */
export type RawValidationMessage = ValidationMessageBase & {
	translationKey: string;
	/**
	 * any other data that can be passed to translated string.
	 * See https://ngneat.github.io/transloco/docs/translation-api/
	 */
	additionalData?: Record<string, string>;
}
