import { OrbpConfiguration } from "../models";
import { LoadBalancingConfiguration } from "../types";

// Conversion of map to array
export function getLoadBalancingConfigurations(orbpConfiguration: OrbpConfiguration): LoadBalancingConfiguration[] {
	const lbConfigurations = orbpConfiguration.loadBalancingConfigurations;
	const keys = Object.keys(lbConfigurations);
	return keys.map(orbpId => {
		return lbConfigurations[orbpId];
	})
}
