/**
 * Change in any of these properties while the DSS Program is running
 * requires user confirmation, because it has effect on Orders assigned/distributed to ORBps in the restaurant
 */
export const ORBP_CRITICAL_PROPERTIES: string[] = [
	'status',
	'role',
	'masterIds',
	'salesChannelConfigurations',
	'loadDistribution',
	'splitRowsConfiguration'
];
