/**
 * ORBp properties which are validated.
 * Used then in ValidationMessage for pairing with form field, so it can be highligted.
 */
export const OrbpValidatedProperties = {
	// Property name is weak point, it will not rename automatically
	// So this is poor-man's solution. If the property will be renamed, it has to be updated in this single place too.
	loadDistribution: 'loadDistribution',
	masterIds: 'masterIds',
	sortingOrdersAlgorithm: 'sortingOrdersAlgorithm'
};
