/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RestaurantFeature } from './restaurant-feature';


export interface BrandConfigurationFeatureConfiguration { 
    feature: RestaurantFeature;
    /**
     * Flag indicating if feature can be modified in GAP.
     */
    modifiable_globally?: boolean;
    /**
     * Flag indicating if the feature that may be modified on both global and local level is enabled after activation globally.
     */
    enabled_by_default?: boolean;
    /**
     * Flag indicating if feature can be modified in restaurant.
     */
    modifiable_locally?: boolean;
}

