/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SelectionPatternDto } from './selection-pattern-dto';


export interface CouponDto { 
    /**
     * Id of the coupon.
     */
    id: number;
    /**
     * Dictionary (associative array) of names where key is the language.
     */
    _name?: { [key: string]: string; };
    /**
     * Description of the coupon.
     */
    _descriptionTemp?: object;
    /**
     * Ids of tags associated with the annotation.
     */
    _xtag?: Array<number>;
    /**
     * Type of coupon.         Values:         NONE = 0,         ICOUPON = 1,         CRM_COLLECT_PHONE = 2,         CRM_GENERATE_CODE = 3,         COUNT_KING_SIZE = 4,         PREPAID = 5,         BURGER_MYSTERE = 6,         DEMOCRATIC_BURGER = 7,         LOCAL_MAILINGS = 8,         KINGDOM_COUPON = 9
     */
    algo: number;
    /**
     * If the coupon is a part of a campaign.
     */
    _campaignCoupon?: boolean;
    /**
     * Additional coupon\'s arguments as a URL encoded string.
     */
    stringArgs: string;
    /**
     * Patterns of the items the coupon applies to.
     */
    _couponApplicableToPattern?: Array<SelectionPatternDto>;
    /**
     * Title of a coupon.
     */
    couponPageTitle?: string;
    /**
     * Coupon\'s legal notice.
     */
    couponPageLegalNotice?: string;
    /**
     * If the coupon has image.
     */
    _hasImage?: boolean;
    /**
     * If the coupon has title image.
     */
    _hasImageTitle?: boolean;
}

