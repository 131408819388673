import { OrbpRole } from '../enums';
import { LoadBalancingConfiguration, OrbpDetail } from '../types';

export function mapOrbpDetailToLoadBalancingConfiguration(orbpDetail: OrbpDetail): LoadBalancingConfiguration {
	const result: LoadBalancingConfiguration = {
		id: orbpDetail.id,
		name: orbpDetail.name,
		masterIds: orbpDetail.masterIds,
		role: orbpDetail.role,
		status: orbpDetail.status,
		loadDistribution: orbpDetail.loadDistribution,
		isDefaultForSalesChannel: orbpDetail.role === OrbpRole.Master ? orbpDetail.isDefaultForSalesChannel : undefined,
		salesChannelConfigurations: orbpDetail.salesChannelConfigurations,
		foodCategoryConfiguration: orbpDetail.role === OrbpRole.Dependent ? orbpDetail.foodCategoryConfiguration : undefined,
		splitRowsConfiguration: orbpDetail.role === OrbpRole.Dependent ? orbpDetail.splitRowsConfiguration : undefined
	};

	return result;
}
