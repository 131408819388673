/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Brand } from './brand';
import { Environment } from './environment';
import { BrandConfigurationLanguages } from './brand-configuration-languages';


export interface BoGlobal3Configuration { 
    brand: Brand;
    environment: Environment;
    languages: BrandConfigurationLanguages;
}

