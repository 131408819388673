import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-sales-channel-selector',
	templateUrl: './sales-channel-selector.component.html',
	styleUrls: ['./sales-channel-selector.component.scss'],
})
export class SalesChannelSelectorComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
