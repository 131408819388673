/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PermissionLevelManagementAdmin } from './permission-level-management-admin';


export interface PermissionInfoLegacyAccess { 
    name: PermissionInfoLegacyAccess.NameEnum;
    level?: PermissionLevelManagementAdmin;
}
export namespace PermissionInfoLegacyAccess {
    export type NameEnum = 'legacy-access';
    export const NameEnum = {
        LegacyAccess: 'legacy-access' as NameEnum
    };
}


