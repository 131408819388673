import { Injectable } from '@angular/core';
import { IStoreErrorData } from '@libs/shared/interfaces';

import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IErrorRootState } from '../interfaces';
import { MarkAllAsSeen, MarkAsSeen, NewErrorAppeared } from './actions';
import { getAllErrors, getLastError, getLastUnseenError } from './selectors';

@Injectable()
export class ErrorFacade {
	readonly ngUnsubscribe$: Subject<void> = new Subject<void>();

	constructor(private store: Store<IErrorRootState>) {}

	insertNewError(errorData: IStoreErrorData) {
		this.store.dispatch(NewErrorAppeared({ error: errorData }));
	}

	markErrorAsSeen(id: string) {
		this.store.dispatch(MarkAsSeen({ id }));
	}

	markAllErrorsAsSeen() {
		this.store.dispatch(MarkAllAsSeen());
	}

	getLastError$(): Observable<IStoreErrorData> {
		return this.store.pipe(select(getLastError), takeUntil(this.ngUnsubscribe$));
	}

	getLastUnseenError$(): Observable<IStoreErrorData> {
		return this.store.pipe(select(getLastUnseenError), takeUntil(this.ngUnsubscribe$));
	}

	getAllErrors$(): Observable<IStoreErrorData[]> {
		return this.store.pipe(select(getAllErrors), takeUntil(this.ngUnsubscribe$));
	}

	unsubscribe(): void {
		this.ngUnsubscribe$.next();
		this.ngUnsubscribe$.complete();
	}
}
