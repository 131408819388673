/**
 * Helper to manipulate variables in string
 */
export class Shar3dVarStrUtils {
	/**
	 * Replaces specified vars in text (format ${varName})
	 *
	 * The same var will be replaced multiple time if required
	 *
	 * /!\ Caution /!\ varnames must be a regex-escaped string (ie. does not contains special regex chars).
	 */
	public static replaceVars(text: string, vars: { [id: string]: string }) {
		if (!text) {
			// undefined / null
			return text;
		}
		for (const prop in vars) {
			if (!vars.hasOwnProperty(prop)) {
				continue;
			}
			// @FIXME check that prop does not contain unescapped regex reserved chars
			text = text.replace(new RegExp('\\${' + prop + '}', 'g'), vars[prop]);
		}

		return text;
	}
}
