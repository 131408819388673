export interface IDevice {
	/**
	 * Name of the device including role and index.
	 */
	name: string;
	/**
	 * Role of the device.
	 */
	role: DeviceDto.RoleEnum;
	/**
	 * Index of the device.
	 */
	idx: number;
	/**
	 * IP address of the device.
	 */
	ip: string;
}

export namespace DeviceDto {
	export type RoleEnum =
		| 'BO'
		| 'COD'
		| 'CSI'
		| 'DMB'
		| 'DRIVECO'
		| 'DRIVEPAY'
		| 'DRIVEPAX'
		| 'DRIVETABLET'
		| 'KIOSK'
		| 'KIOSKACRELEC'
		| 'KIOSKV'
		| 'KDS'
		| 'ORBD'
		| 'ORBP'
		| 'ORB'
		| 'PBD'
		| 'PC'
		| 'POS'
		| 'POSPAX'
		| 'POSTABLET'
		| 'PRINTER'
		| 'PRINTERDRIVEPAY'
		| 'PRINTERKDS'
		| 'PRINTERORB'
		| 'SOS'
		| 'TPE'
		| 'TPEDRIVEPAY'
		| 'XMB'
		| 'WEB';
	export const RoleEnum = {
		Bo: 'BO' as RoleEnum,
		Cod: 'COD' as RoleEnum,
		Csi: 'CSI' as RoleEnum,
		Dmb: 'DMB' as RoleEnum,
		Driveco: 'DRIVECO' as RoleEnum,
		Drivepay: 'DRIVEPAY' as RoleEnum,
		Drivepax: 'DRIVEPAX' as RoleEnum,
		Drivetablet: 'DRIVETABLET' as RoleEnum,
		Kiosk: 'KIOSK' as RoleEnum,
		Kioskacrelec: 'KIOSKACRELEC' as RoleEnum,
		Kioskv: 'KIOSKV' as RoleEnum,
		Kds: 'KDS' as RoleEnum,
		Orbd: 'ORBD' as RoleEnum,
		Orbp: 'ORBP' as RoleEnum,
		Orb: 'ORB' as RoleEnum,
		Pbd: 'PBD' as RoleEnum,
		Pc: 'PC' as RoleEnum,
		Pos: 'POS' as RoleEnum,
		Pospax: 'POSPAX' as RoleEnum,
		Postablet: 'POSTABLET' as RoleEnum,
		Printer: 'PRINTER' as RoleEnum,
		Printerdrivepay: 'PRINTERDRIVEPAY' as RoleEnum,
		Printerkds: 'PRINTERKDS' as RoleEnum,
		Printerorb: 'PRINTERORB' as RoleEnum,
		Sos: 'SOS' as RoleEnum,
		Tpe: 'TPE' as RoleEnum,
		Tpedrivepay: 'TPEDRIVEPAY' as RoleEnum,
		Xmb: 'XMB' as RoleEnum,
		Web: 'WEB' as RoleEnum,
	};
}

export interface IRestoSettings {
	/**
	 * Unix timestamp indicating the last update to the file (in seconds).
	 */
	timestamp?: number;
	/**
	 * Current configuration of devices. Set in BK Manager -> \"Configuration des écrans\".
	 */
	restosettings: IRestoSettingsNested;
	/**
	 * Configuration for cash machines. Set in BK Manager -> \"Mode de fonctionnement\" -> \"Paramètres fonds de caisse\".
	 */
	cashFundParameters?: ICashParameters;
	/**
	 * Configuration of initial funds for cash machine in coins and bank notes. Set in BK Manager -> \"Mode de fonctionnement\" -> \"Configuration de l\'ajout monnaie\".
	 */
	moneyconfig?: ICashConfiguration[];
	/**
	 * Indicates current state of the restaurant. Set in BK Manager -> \"Mode de fonctionnement\" -> \"Gestion du mode de fonctionnement\".   Values:   UNKNOWN = 0,         CONFIG = 1,         INSTALL = 2,         WHITE = 3,         PRODUCTION = 4
	 */
	restostate?: number;
	/**
	 * Flag indicating if all the kiosk should be disabled. Set in BK Manager -> \"Gestion du restaurant\".
	 */
	disableAllKiosks?: boolean;
	/**
	 * Indices of KIOSKs that should be disabled. Set in BK Manager -> \"Gestion du restaurant\".
	 */
	disableKiosks?: Array<number>;
	/**
	 * Indices of kiosks that should be in Black Screen Mode. Set in BK-MANAGER -> \"Gestion du restaurant\"
	 */
	blackScreenKiosks?: Array<number>;
	/**
	 * Extra message to be printed on the ticket. Set in BK Manager -> \"Mode de fonctionnement\" -> \"Paramètres généraux\".
	 */
	messageOnTicket?: string;
	/**
	 * Code for toilet access to be printed on the ticket. Set in BK Manager -> \"Mode de fonctionnement\" -> \"Paramètres généraux\".
	 */
	toiletCode?: string;
	/**
	 * Amount of crowns (or euros based on \"employeeMealInEuros\" property) to be spent by employee in a day (e.g. for a lunch - sponsored by employer). Set in BK Manager -> \"Mode de fonctionnement\" -> \"Paramètres généraux\".
	 */
	maxCrownPerEmployee?: number;
	/**
	 * In BK Manager \"Valeur maximale du restant dû des TR\". No sure what the \"TR\" stands for. Set in BK Manager -> \"Mode de fonctionnement\" -> \"Paramètres généraux\".
	 */
	maxRestTR?: number;
	/**
	 * Message to be displayed on KIOSK when customer selects multipayment. Very likely deprecated and replaced with \"kioskMultipayMessageLangs\" property. Set in BK Manager -> \"Gestion du restaurant\".
	 */
	kioskMultipayMessage?: string;
	/**
	 * Dictionary (associative array) of messages to be displayed on KIOSK when customer selects multipayment. Set in BK Manager -> \"Gestion du restaurant\".
	 */
	kioskMultipayMessageLangs?: { [key: string]: string };
	/**
	 * Indicates when the multipayment orders from KIOSK should be sent to production. Set in BK Manager -> \"Gestion du restaurant\"..   Values:   SEND_ORDERS_ON_PAID = 0,         SEND_ORDERS_ON_PAYING = 1,         SEND_ORDERS_ALL_TIME = 2
	 */
	kioskMultipayMode?: number;
	/**
	 * Flag indicating if eat in order can be created on KIOSK. Set in BK Manager -> \"Gestion du restaurant\".
	 */
	enableEatInOnKiosk?: boolean;
	/**
	 * Flag indicating if multipayment is enabled on KIOSK. Set in BK Manager -> \"Gestion du restaurant\".
	 */
	enableMultipayOnKiosk?: boolean;
	/**
	 * Flag indicating if fidelity program is enabled in the restaurant. Set in BK Manager -> \"Gestion du restaurant\".
	 */
	enableKingdom?: boolean;
	/**
	 * Flag indicating if ORB subscriptions are enabled in the restaurant. This is functionality when customer receives QR code on the ticket which he may scan and see the state of his order in the app. Set in BK Manager -> \"Gestion du restaurant\".
	 */
	enableORBDigital?: boolean;
	/**
	 * Index of a POS which should handle multipayment orders. Set in BK Manager -> \"Gestion du restaurant\".
	 */
	payingPosMultipayMode1?: number;
	/**
	 * Index of an alternative POS which should handle multipayment orders. Set in BK Manager -> \"Gestion du restaurant\".
	 */
	payingPosMultipayMode2?: number;
	/**
	 * Flag indicating if short tickets should be printed on COD. Set in BK Manager -> \"Gestion du restaurant\".
	 */
	printCODTicket?: boolean;
	/**
	 * Flag indicating if long tickets should be printed on COD. Set in BK Manager -> \"Gestion du restaurant\".
	 */
	printCODLongTicket?: boolean;
	/**
	 * Flag indicating if special tickets should be printed in KDS for every burger. Set in BK Manager -> \"Gestion du restaurant\".
	 */
	printTicketOnAllBurgers?: boolean;
	/**
	 * Flag indicating if fidelity program is enabled for employees (employees can collect fidelity point on employee meals). Set in BK Manager -> \"Mode de fonctionnement\" -> \"Paramètres généraux\".
	 */
	enableKingdomForEmployee?: boolean;
	/**
	 * Flag indicating if maximal value for employee meals is in euros instead of crowns. Set in BK Manager -> \"Mode de fonctionnement\" -> \"Paramètres généraux\".
	 */
	employeeMealInEuros?: boolean;
	/**
	 * Flag indicating if cash machine drawer should be opened when employee meal is purchased. Set in BK Manager -> \"Mode de fonctionnement\" -> \"Paramètres généraux\".
	 */
	openDrawerOnEmployeeMeal?: boolean;
	/**
	 * Day on which employees should be alerted to empty the back office safe. Set in BK Manager -> \"Mode de fonctionnement\" -> \"Paramètres généraux\".
	 */
	alertDayForDumpSafe?: number;
	/**
	 * Number of seconds to pass until the production of DELIVERY orders is automatically triggered (unless there is specifit time set in the order). Set in BK Manager -> \"Mode de fonctionnement\" -> \"Paramètres généraux\".
	 */
	preparationAheadTimeWebS?: number;
	/**
	 * IP of the POS/DRIVEPAY which processes the payments for Click & Collect and DELIVERY orders (technically it is CLERK on the device which handles the payment). Also reffered to as \"webPosIP\". Set in BK Manager -> \"Mode de fonctionnement\" -> \"Paramètres généraux\".
	 */
	cashTargetWebOrder?: string;
	/**
	 * Flag indicating if DRIVE orders should be sorted by the first product. Not sure where this is configured.
	 */
	driveSortByFirstProduct?: boolean;
	/**
	 * Flag indicating if table service is enabled in the restaurant. Set in BK Manager -> \"Gestion du restaurant\".
	 */
	tableService?: boolean;
	/**
	 * Flag indicating if table service on the terrace is enabled in the restaurant. Set in BK Manager -> \"Gestion du restaurant\".
	 */
	terraceAvailableForTableService?: boolean;
	/**
	 * Flag indicating if waiting at the table (serving customers from their table, not the counter) is enabled in the restaurant. Set in BK Manager -> \"Gestion du restaurant\".
	 */
	waitAtTheTable?: boolean;
	/**
	 * Flag indicating if restaurant has walk by enabled on DRIVE. Set in BK Manager -> \"Gestion du restaurant\".
	 */
	hasWalkByWindow?: boolean;
	/**
	 * Flag indicating if franchisee (restaurant owner) can modify instore prices. Set in BK Manager -> \"Gestion du restaurant\".
	 */
	franchiseeCanEditLocalPrices?: boolean;
	/**
	 * Flag indicating if franchisee (restaurant owner) can modify DELIVERY prices. Set in BK Manager -> \"Gestion du restaurant\".
	 */
	franchiseeCanEditDaypartPrices?: boolean;
	/**
	 * Flag indicating if option to toggle ticket printing is enabled. Set in BK Manager -> \"Gestion du restaurant\" (SNS only).
	 */
	optionNotToPrintTicketEnabled?: boolean;
	/**
	 * Option for setting max price up to which the ticket doos not need to be printed. Set in BK Manager -> \"Gestion du restaurant\" (SNS only).
	 */
	optionNotToPrintTicketMaxPrice?: number;
	/**
	 * Flag indicating if the small safe is used in the restaurant. Not sure where this is configured.
	 */
	useSmallSafe?: boolean;
	/**
	 * Flag indicating if the order can be paid by GR card (some kind of special card to pay for petrol when the restaurant is at the gas station). Not sure where this is configured.
	 */
	enablePaymentCarteGR?: boolean;
	/**
	 * Switch for payment modes in KIOSK. Not sure where this is configured, probably SNS only.
	 */
	kioskCashPaymentMode?: string;
	/**
	 * Flag indicating that ORBp can display LiveBasketUpdates in real time.
	 */
	liveBasketModeEnabled?: boolean;
}

export interface IRestoSettingsNested {
	/**
	 * Indicates which KIOSK UI is to be used from those configured in RestoConfig. Selected in BK Manager \"Configuration des écrans\" menu.
	 */
	currentKioskMenu: number;
	/**
	 * Indicates which POS UI is to be used from those configured in RestoConfig. Selected in BK Manager \"Configuration des écrans\" menu.
	 */
	currentCashMenu: number;
	/**
	 * Indicates which ORBp configuration is to be used from those configured in RestoConfig. Selected in BK Manager \"Configuration des écrans\" menu. Deprecated by DSS.
	 */
	currentOrbConfig: number;
	/**
	 * Indicates which KDS configuration is to be used from those configured in RestoConfig. Selected in BK Manager \"Configuration des écrans\" menu.
	 */
	currentKdsConfig: number;
	/**
	 * Indicates which COD configuration is to be used from those configured in RestoConfig. Selected in BK Manager \"Configuration des écrans\" menu.
	 */
	currentCodConfig?: number;
	/**
	 * Indicates which COD configuration is to be used from those configured in RestoConfig. Selected in BK Manager \"Configuration des écrans\" menu. And YES, this is very likely duplicate of \"currentCodConfig\" property but I am not sure which one is used.
	 */
	currentCODConfig?: number;
	/**
	 * Indicates which DRIVEPAY/DRIVECO configuration is to be used from those configured in RestoConfig. Selected in BK Manager \"Configuration des écrans\" menu.
	 */
	currentDriveConfig?: number;
	/**
	 * Current active DRIVEPAY device information.
	 */
	currentDrivePay?: IDevice;
	/**
	 * Current active DRIVECO/COD 1 device information.
	 */
	currentDriveCOD1?: IDevice;
	/**
	 * Current active DRIVECO/COD 2 device information.
	 */
	currentDriveCOD2?: IDevice;
	/**
	 * Dictionary (associative array) of KDS printers where key is the KDS index. Describes which priter is to be used with which KDS
	 */
	printerKds?: { [key: string]: string };
}

export interface ICashParameters {
	/**
	 * Initial cash funds for every cash machine (i.e. the amount of money the cashier starts with). Set in BK Manager -> \"Mode de fonctionnement\" -> \"Paramètres fonds de caisse\".
	 */
	initialCashFund: number;
	/**
	 * Amount of cash funds that should not be exceeded in the cash machine. If this happens, someone from back office should come to withdraw the cash and store it in a safe. Set in BK Manager -> \"Mode de fonctionnement\" -> \"Paramètres fonds de caisse\".
	 */
	alertWidthdraw: number;
	/**
	 * Indicates how many cash machines (and initial funds) are available to employees. Set in BK Manager -> \"Mode de fonctionnement\" -> \"Paramètres fonds de caisse\".
	 */
	cashFoundNumber: number;
}

export interface ICashConfiguration {
	/**
	 * Label for the cash configuration describing amount of cash.
	 */
	label: string;
	/**
	 * Total fiscal value of this cash configuration.
	 */
	value: number;
}
