/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SelectionPatternDto } from './selection-pattern-dto';


export interface IngredientFilterDto { 
    /**
     * Id of the ingredient filter.
     */
    id: number;
    /**
     * System name of the ingredient filter.
     */
    sysName: string;
    /**
     * Dictionary (associative array) of names where key is the language.
     */
    _name: { [key: string]: string; };
    /**
     * Dictionary (associative array) of labels where key is the language.
     */
    _label: { [key: string]: string; };
    /**
     * Flag indicating if this is an exclusion filter.
     */
    excludeFilter: boolean;
    /**
     * Mask indicating where the ingredient filter should be displayed.   Values (IngredientFilterLocationMask):   LOCATION_NONE = 0,   LOCATION_NAVIGATION_SCREEN_FILTER = 2 ** 0,   LOCATION_BURGER_FINDER_SLIDE = 2 ** 1
     */
    locationMask: number;
    /**
     * Simple sorting index.
     */
    sortIndex: number;
    /**
     * Patterns of the ingredients selection.
     */
    ingredientPattern: Array<SelectionPatternDto>;
    /**
     * Patterns of the product selection.
     */
    productPattern: Array<SelectionPatternDto>;
    /**
     * Patterns of the navigation screens selection.
     */
    navScreenPattern?: Array<SelectionPatternDto>;
    /**
     * Flag indicating if the filter has main image.
     */
    _hasImageMain?: boolean;
}

