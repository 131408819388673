/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PmsAvailableSingleProductDto } from './pms-available-single-product-dto';


export interface PmsAvailableProductsDto { 
    /**
     * Available products for PMS
     */
    products: Array<PmsAvailableSingleProductDto>;
}

