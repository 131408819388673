/**
 * DKI-BE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RestaurantSalesChannel } from './restaurant-sales-channel';


export interface RestaurantSalesChannelsInput {
	eat_in?: RestaurantSalesChannel;
	take_away?: RestaurantSalesChannel;
	deliveroo?: RestaurantSalesChannel;
	delivery_bk?: RestaurantSalesChannel;
	just_eat?: RestaurantSalesChannel;
	uber_eats?: RestaurantSalesChannel;
}

