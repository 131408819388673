/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface LocalPriceDefinitionValueDto { 
    /**
     * Sales channel option for the specified price.
     */
    salesChannelOption: LocalPriceDefinitionValueDto.SalesChannelOptionEnum;
    /**
     * Price type identifier. For product specifies the use case of the price: **Solo** = regular price, **InSuggestion** = discounted price when the product is added from suggestion popup, **SupplementMenu** = extra price to be added to the menu price when this product is selected as a menu step. For menu contains menu size.
     */
    rowIdentifier: LocalPriceDefinitionValueDto.RowIdentifierEnum;
    /**
     * Flag indicating if the price for given \"salesChannelOption\" and \"rowIdentifier\" is disabled on global level.
     */
    isDisabled: boolean;
    /**
     * Total price of the item for \"salesChannelOption\" and \"rowIdentifier\" combination (taxes included). This is a global price taken from BigData.
     */
    referencePrice: number;
    /**
     * Total price of the item for \"salesChannelOption\" and \"rowIdentifier\" combination (taxes included). This is a local price which overrides global price taken from BigData.
     */
    localPrice?: number;
}
export namespace LocalPriceDefinitionValueDto {
    export type SalesChannelOptionEnum = 'TakeAway' | 'EatIn' | 'WaitingAtTable' | 'TableServiceInside' | 'TableServiceTerrasse' | 'DriveIn' | 'PickupParking' | 'DeliveryBK' | 'Deliveroo' | 'UberEats' | 'JustEat' | 'ZOT' | 'Lyveat' | 'Please' | 'DeliverectClickAndCollect';
    export const SalesChannelOptionEnum = {
        TakeAway: 'TakeAway' as SalesChannelOptionEnum,
        EatIn: 'EatIn' as SalesChannelOptionEnum,
        WaitingAtTable: 'WaitingAtTable' as SalesChannelOptionEnum,
        TableServiceInside: 'TableServiceInside' as SalesChannelOptionEnum,
        TableServiceTerrasse: 'TableServiceTerrasse' as SalesChannelOptionEnum,
        DriveIn: 'DriveIn' as SalesChannelOptionEnum,
        PickupParking: 'PickupParking' as SalesChannelOptionEnum,
        DeliveryBk: 'DeliveryBK' as SalesChannelOptionEnum,
        Deliveroo: 'Deliveroo' as SalesChannelOptionEnum,
        UberEats: 'UberEats' as SalesChannelOptionEnum,
        JustEat: 'JustEat' as SalesChannelOptionEnum,
        Zot: 'ZOT' as SalesChannelOptionEnum,
        Lyveat: 'Lyveat' as SalesChannelOptionEnum,
        Please: 'Please' as SalesChannelOptionEnum,
        DeliverectClickAndCollect: 'DeliverectClickAndCollect' as SalesChannelOptionEnum
    };
    export type RowIdentifierEnum = 'M' | 'L' | 'Solo' | 'SupplementMenu' | 'InSuggestion';
    export const RowIdentifierEnum = {
        M: 'M' as RowIdentifierEnum,
        L: 'L' as RowIdentifierEnum,
        Solo: 'Solo' as RowIdentifierEnum,
        SupplementMenu: 'SupplementMenu' as RowIdentifierEnum,
        InSuggestion: 'InSuggestion' as RowIdentifierEnum
    };
}


