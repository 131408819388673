import { formatNumber, getLocaleNumberSymbol, NumberSymbol } from '@angular/common';
import { Directive, ElementRef, HostListener, Inject, Input, LOCALE_ID, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';
import { replaceAll, trimDecimalPlaces } from '@merim/utils';

/**
 * Automatically formats numeric value according to current Locale,
 * using locale thousands separator, locale sign for decimal separator.
 *
 * Automatically displays raw numeric value on focus, and formatted value on blur.
 *
 * It is possible to set max lenght of decimal places by [decimal-place-limit]="2"
 */
@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: '[merim-decimal-number-field]'
})
export class DecimalNumberFieldDirective {
	private regex = new RegExp(/^-?[0-9]+(\.[0-9]*){0,1}$/g);
	private previousValue = '';
	private _decimalPlaceLimit = 100000000;

	// Default value. Is updated automatically based on current locale.
	private THOUSANDS_GROUP_SEPARATOR = ',';
	private DECIMAL_SEPARATOR = '.';

	constructor(
		private readonly el: ElementRef,
		@Inject(LOCALE_ID) private readonly locale: string,
		@Optional() private readonly control: NgControl
	) {
		this.DECIMAL_SEPARATOR = getLocaleNumberSymbol(this.locale, NumberSymbol.Decimal);
		this.THOUSANDS_GROUP_SEPARATOR = getLocaleNumberSymbol(this.locale, NumberSymbol.Group);

		setTimeout(() => {
			const inputElement = this.el.nativeElement as HTMLInputElement;
			this.setFormattedValue(inputElement, inputElement.value);
			this.previousValue = inputElement.value;
		})
	}

	@Input('decimal-place-limit')
	set decimalPlaceLimit(value: number) {
		this._decimalPlaceLimit = value;
	}

	@Input() allowNegativeValue = false;

	// Explanation for using keyup.
	// I have tried both 'input' and 'keydown' listeners,
	// but using them cancels the (keyup) event listener in component.
	// There seems to be some competition between component and directive both listening on same DOM element.
	// Eventhough the event is not directly cancelled or prevented.
	@HostListener('keyup', ['$event'])
	onKeyUp(event: KeyboardEvent): void {
		if (event.key === 'Control' || event.ctrlKey) {
			// This has been Ctrl+V event or similar.
			// This should be only processed in the onPaste() handler
			return;
		}
		const inputElement = this.el.nativeElement as HTMLInputElement;
		const nextValue: string = this.getRawValue(inputElement);
		this.processEnteredValue(nextValue, event);
	}

	@HostListener('paste', ['$event'])
	onPaste(event: Event): void {
		// This is actually ClipboardEvent, but the frontend-common library is included in bk-js-lib
		const pastedValue = (<any>event).clipboardData.getData('text/plain');
		this.processEnteredValue(pastedValue, event);
	}

	@HostListener('blur', ['$event'])
	onBlur(_event: Event): void {
		this.format();
	}

	@HostListener('focus', ['$event'])
	onFocus(_event: Event): void {
		const inputElement = this.el.nativeElement as HTMLInputElement;
		this.setRawValue(inputElement);
	}

	/**
	 * API method. To be called from other components.
	 */
	format(): void {
		const inputElement = this.el.nativeElement as HTMLInputElement;
		this.setFormattedValue(inputElement, inputElement.value);
	}

	triggerChangeEvent(element: HTMLInputElement, value: string): void {
		const event = new InputEvent('change', {
			view: window,
			bubbles: true,
			cancelable: true,
			data: value
		});
		element.dispatchEvent(event);
	}

	private processEnteredValue(nextValue: string, event: Event): void {
		const inputElement = this.el.nativeElement as HTMLInputElement;

		if (nextValue && !String(nextValue).match(this.regex) && !this.isMinusSignFirst(nextValue)) {
			event.preventDefault();
			this.setValue(inputElement, this.previousValue);
			return;
		}

		// Check for duplication of decimal dot.
		if (nextValue && nextValue.indexOf(this.DECIMAL_SEPARATOR) !== -1 && nextValue.indexOf(this.DECIMAL_SEPARATOR) !== nextValue.lastIndexOf(this.DECIMAL_SEPARATOR)) {
			event.preventDefault();
			this.setValue(inputElement, this.previousValue);
			return;
		}

		// Treat decimal places:
		// To test whether it is a decimal value with a maximum number of decimal places
		const endsWithDecimalDot = nextValue.lastIndexOf(this.DECIMAL_SEPARATOR) === nextValue.length - 1;
		const absoluteNextValue: number = Math.abs(parseFloat(nextValue));
		if (endsWithDecimalDot === false && absoluteNextValue) {
			const endsWithZero = nextValue.lastIndexOf('0') === nextValue.length - 1;
			const hasDecimalDot = nextValue.lastIndexOf(this.DECIMAL_SEPARATOR) !== -1;
			const isNegativeNumber = this.isMinusSignFirst(nextValue);

			nextValue = trimDecimalPlaces(absoluteNextValue, this._decimalPlaceLimit).toString();

			// numbers ending with zero, like for example '3.0' needs special treatment
			// so that the zero does not dissapear in parseFloat('3.0')
			if (endsWithZero && hasDecimalDot) {
				nextValue = `${parseFloat(nextValue)}.0`;
			}
			if (isNegativeNumber) {
				nextValue = `-${nextValue}`;
			}
		}

		this.previousValue = nextValue;
		setTimeout(() => {
			this.setValue(inputElement, nextValue);
		});
	}

	private setValue(element: HTMLInputElement, value: string): void {
		if (this.control) {
			this.control.control.setValue(value, {emit: true});
		}
		this.previousValue = value;
		element.value = value;

		if (!this.isMinusSignFirst(value)) {
			this.triggerChangeEvent(element, value);
		}
	}

	private setFormattedValue(element: HTMLInputElement, value: string): void {
		value = replaceAll(element.value, this.THOUSANDS_GROUP_SEPARATOR, '');
		let numericValue = parseFloat(value);
		if (isNaN(numericValue)) {
			if (this.isMinusSignFirst(value) && typeof value === 'string') {
				numericValue = 0;
			} else {
				return;
			}
		}

		const endsWithDecimalDot = value.lastIndexOf(this.DECIMAL_SEPARATOR) === value.length - 1;
		let formattedValue = formatNumber(numericValue, this.locale);

		if (endsWithDecimalDot) {
			formattedValue = `${formattedValue}${this.DECIMAL_SEPARATOR}`;
		}

		element.value = formattedValue;
	}

	private setRawValue(element: HTMLInputElement): void {
		const value = element.value;
		const numericValue = parseFloat(value);
		if (isNaN(numericValue)) {
			return;
		}

		const rawValue = this.getRawValue(element);
		element.value = rawValue;
	}

	private getRawValue(element: HTMLInputElement): string {
		const rawValue = replaceAll(element.value, this.THOUSANDS_GROUP_SEPARATOR, '');
		return rawValue;
	}

	private isMinusSignFirst(value: string): boolean {
		if (this.allowNegativeValue && value) {
			return value[0] === '-';
		} else {
			return false;
		}
	}

}
