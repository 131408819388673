/**
 * Works for array of primitive values
 */
export function removeFromArray<T>(arr: T[], itemToRemove: T) {
	return arr.filter((item) => item !== itemToRemove);
}

/**
 * Get unique values from an Array
 */
export function getUniqueValues<T>(arr: T[]): T[] {
	if (!arr || arr.length === 0) {
		return arr;
	}


	const theSet = new Set(arr);
	const uniqueArray = [];
	theSet.forEach((x) => {
		uniqueArray.push(x);
	});
	return uniqueArray;

	// We can't have nice things now.
	// return [...new Set(arr)]; // Fails in runtime, it is transpiled to "tslib_1.__spreadArrays(theSet);" which seems not work with Set
}

export function flattenArray<T>(arrays: T[][]): T[] {
	const result: T[] = [];

	arrays.forEach((arr) => {
		result.push(...arr);
	});

	return result;
}
