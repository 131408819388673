import { IRingMessage } from '@bk/jscommondatas';
import { MESSAGE_TOPICS, RING_MESSAGE_TYPES } from '../models';
import { Base64 } from './base64';

const RING_MESSAGE_LENGTH_AFTER_SPLIT = 2;

export function parseRingMessage(msg: string): IRingMessage<any> {
	const splitMessage = msg.split('|');
	if (splitMessage.length !== RING_MESSAGE_LENGTH_AFTER_SPLIT) {
		console.warn('Unexpected ring message arrived...', msg);
		return {
			bktype: RING_MESSAGE_TYPES.UNKNOWN,
			content: msg,
		};
	}

	const topic = splitMessage[0].split(':').pop().split('|')[0];
	if (topic === MESSAGE_TOPICS.SCAN_MESSAGES) {
		return JSON.parse(splitMessage[1]);
	}

	const parsedMessage = JSON.parse(Base64.decode(splitMessage[1]));
	const { bktype, ...content } = parsedMessage;

	return {
		bktype: bktype,
		content: content,
	};
}
