import { SalesChannel } from "../../enums";
import { OrbpDetail, SalesChannelConfiguration } from "../../types";
import { isActiveMaster } from "../is-role";
import { isSalesChannelOn, isUsingDeviceSpecificConfiguration } from "../sales-channels";

/**
 * Returns Active ORBp Master which is either marked as Default ORBp,
 * or is not using device-specific configuration for that SalesChannel.
 */
export function getORBpsMatchingAllDevices(salesChannel: SalesChannel, masterOrbps: OrbpDetail[]): OrbpDetail[] {
	const mastersMatchingAllDevices = masterOrbps.filter(orbp => {
		if (isActiveMaster(orbp) && orbp.isDefaultForSalesChannel) {
			return true;
		}

		const matchingScConfig: SalesChannelConfiguration = orbp.salesChannelConfigurations.find(x => x.salesChannel === salesChannel);

		if (!isSalesChannelOn(matchingScConfig)) {
			return false;
		}

		return isUsingDeviceSpecificConfiguration(matchingScConfig) === false;
	});

	return mastersMatchingAllDevices;
}
