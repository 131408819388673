import { clone, pick } from 'ramda';
import {
	AssemblyArea,
	DeviceConfig,
	DeviceScreen,
	DriveConfig,
	IRestaurantSalesChannel,
	IRestoConfig,
	KioskConfigurationData,
	KitchenConfig,
	NavigationScreen,
	OrbConfig,
	Restaurant,
} from '../remote-configs';

export class RestoConfigStoreModel {
	loading: boolean = true;
	data: RestoConfigModel = new RestoConfigModel();

	constructor(init?: Partial<RestoConfigStoreModel>) {
		Object.assign(this, clone(init));
	}
}

export class RestoConfigModel implements IRestoConfig {
	restaurant: Restaurant = undefined;
	kitchenConfigs: KitchenConfig = undefined;
	kitchenConfigsDefault: number = undefined;
	driveConfigs: DriveConfig = undefined;
	driveConfigsDefault: number = undefined;
	orbpConfigs: OrbConfig = undefined;
	orbConfigsDefault: number = undefined;
	codConfigs: Record<string, DeviceConfig> = undefined;
	assemblyAreas: Record<string, AssemblyArea> = undefined;
	kioskScreens: Record<string, NavigationScreen> = undefined;
	kioskConfigs: Record<string, KioskConfigurationData> = undefined;
	kioskConfigsDefault: number = undefined;
	posScreens: Record<string, DeviceScreen> = undefined;
	posConfigs: Record<string, DeviceConfig> = undefined;
	posConfigsDefault: number = undefined;
	salesChannels: Record<string, IRestaurantSalesChannel> = undefined;
	dmbConfig: Record<string, DeviceConfig> = undefined;
	xmbConfig: Record<string, DeviceConfig> = undefined;

	constructor(init?: Partial<RestoConfigModel>) {
		Object.assign(this, clone(pick(Object.keys(this), init)));
	}
}
