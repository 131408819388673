/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { VatValue } from './vat-value';
import { PriceDefinition } from './price-definition';


export interface PriceDataInput { 
    price_definitions?: Array<PriceDefinition>;
    /**
     * Expected values: \'EatIn\', \'TakeAway\', \'Deliveroo\', \'DeliveryBK\', \'JustEat\', \'UberEats\'
     */
    disabled_sales_channel_options?: Array<string>;
    vat_values?: Array<VatValue>;
}

