/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LocalWebordersDto } from './local-weborders-dto';
import { LocalFeaturesDto } from './local-features-dto';


export interface ConfigurationLocalDto { 
    features: LocalFeaturesDto;
    weborders: LocalWebordersDto;
}

