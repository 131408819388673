/**
 * DKI-BE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface RestaurantFiscalInfo {
	tpe_id_merchant?: string;
	nepting_url?: string;
	/**
	 * List of enabled payment methods.
	 */
	payment_methods?: Array<number>;
	/**
	 * Payment method to be utilized on fiscal day closure (for refunds, cancellations etc.).
	 */
	closure_payment_method?: number;
}

