import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-days-selector',
	templateUrl: './days-selector.component.html',
	styleUrls: ['./days-selector.component.scss'],
})
export class DaysSelectorComponent implements OnInit {
	days = [
		{
			name: 'Lundi',
			selected: false,
		},
		{
			name: 'Mardi',
			selected: false,
		},
		{
			name: 'Mercredi',
			selected: false,
		},
		{
			name: 'Jeudi',
			selected: false,
		},
		{
			name: 'Vendredi',
			selected: false,
		},
		{
			name: 'Samedi',
			selected: false,
		},
		{
			name: 'Dimanche',
			selected: false,
		},
	];

	constructor() {}

	ngOnInit(): void {}

	toggleDay(index: number) {
		this.days[index].selected = !this.days[index].selected;
	}
}
