/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GlobalPriceDefinitionDto } from './global-price-definition-dto';
import { VatValueDto } from './vat-value-dto';
import { ItemReferenceDto } from './item-reference-dto';


export interface IngredientDto { 
    /**
     * Id of the item.
     */
    id: number;
    /**
     * System name of the item.
     */
    sysName: string;
    /**
     * Short name used for employee facing displays.
     */
    shortName: string;
    /**
     * Dictionary (associative array) of names where key is the language.
     */
    _name: { [key: string]: string; };
    /**
     * Dictionary (associative array) of descriptions where key is the language.
     */
    _description: { [key: string]: string; };
    /**
     * Ids of tags associated with the item.
     */
    xtag: Array<number>;
    /**
     * Flag indicating if the item is temporary and must be referenced in an active campaign to be enabled.
     */
    campaignItem: boolean;
    /**
     * Flag indicating if the item is in an active campaign.
     */
    inActiveCampaign?: boolean;
    /**
     * Id of the export group the item belongs to.
     */
    _groupId?: number;
    /**
     * Flag indicating if the item is active and can be displayed to customers. Used for \"soft delete\". Everything included in BigData must be presumed active, because initial filtering of active items is performed when BigData are generated on the global level.
     */
    active?: boolean;
    /**
     * New price definitions for price management.
     */
    globalPriceDefinitions?: Array<GlobalPriceDefinitionDto>;
    /**
     * New VAT values for price management.
     */
    vatValues?: Array<VatValueDto>;
    /**
     * Ingredient family ID.
     */
    _familyId: number;
    /**
     * Ids of allergens included in the ingredient.
     */
    xallergen: Array<number>;
    /**
     * Ids of allergens included in the ingredient as traces (small amounts).
     */
    _xtraces?: Array<number>;
    /**
     * Reference of the ingredient.
     */
    refI: ItemReferenceDto;
    /**
     * If the ingredient has image specific for KIOSK.
     */
    _hasImageKiosk?: boolean;
    /**
     * If the ingredient has alternative image specific for KIOSK.
     */
    _hasImageKiosk2?: boolean;
    /**
     * If the ingredient has alternative image.
     */
    _hasImageAlt?: boolean;
    /**
     * If the ingredient has \"ON\" image.
     */
    _hasImageOn?: boolean;
    /**
     * If the ingredient has \"OFF\" image.
     */
    _hasImageOff?: boolean;
    /**
     * Flag indicating if the ingredient has a price set. If not, it is a base ingredient (e.g. basic bun) that is typically already included in the product without any additional payment. It the ingredient is \"withPrice\" it is extra ingredient which may be added to the product for extra payment, unless already included in base product.
     */
    withPrice: boolean;
    /**
     * Price of the ingredient (taxes included) if it is ingredient \"withPrice\".
     */
    priceTTC: number;
}

