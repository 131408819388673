import { SalesChannel } from '@bk/fullstack-common';
import { DssProgramDto } from '@rap/api-client/models';

import { AssignedOrbpPrinterType, LoadDistribution, OrbpRole, OrbStatus, SortingOrdersAlgorithm } from "../enums";
import { DssProgram } from "../types";

export function mapDssProgramFromDto(dto: DssProgramDto): DssProgram {
	return {
		...dto,
		orbpConfigurations: (dto.orbpConfigurations || []).map((configuration) => {
			return {
				...configuration,
				status: configuration.status as OrbStatus,
				role: configuration.role as OrbpRole,
				salesChannelConfigurations: configuration.salesChannelConfigurations.map((configuration) => {
					return {
						...configuration,
						salesChannel: configuration.salesChannel as SalesChannel
					}
				}),
				loadDistribution: configuration.loadDistribution as LoadDistribution,
				sortingOrdersAlgorithm: configuration.sortingOrdersAlgorithm as SortingOrdersAlgorithm,
				assignedOrbpPrinterType: configuration.assignedOrbpPrinterType as AssignedOrbpPrinterType
			}
		})
	}
}
