/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ProductFamilyDto { 
    /**
     * Id of the ingredient\'s family.
     */
    id: number;
    /**
     * Dictionary (associative array) of descriptions where key is the language.
     */
    _description: { [key: string]: string; };
    /**
     * Dictionary (associative array) of names where key is the language.
     */
    _name: { [key: string]: string; };
    /**
     * Id of the parent product family, if set.
     */
    parentFamilyId: number;
    /**
     * Id of the image/pictogram displayed for the product family. No idea how those are managed.
     */
    _picto?: number;
    /**
     * Flag indicating if the product family should be included in load balancing.
     */
    includeInLoadBalancing?: boolean;
    /**
     * Flag indicating that this item should have its VAT value aligned with other items in menu.
     */
    includeInVatAlignment?: boolean;
}

