export type MerimConsole = Console & {
	merimLog: (...args: unknown[]) => void;

	// reference to original console.log implementation
	originalLog: (...args: unknown[]) => void;
};

/**
 * Add custom method merimLog() to console object.
 */
export function patchConsole() {
	(<MerimConsole>console).merimLog = merimLog;

	// reference to original console.log implementation
	(<MerimConsole>console).originalLog = console.log;

	console.log = (<MerimConsole>console).merimLog;
}

/**
 * Log all arguments as single string.
 * This solves the problem with "file logger" in our wrapped Chromium browser,
 * which now logs only the 1st argument.
 */
function merimLog(...args: unknown[]): void {
	const stringifiedArgs = args.map((x) => JSON.stringify(x)).join(', ');
	(<MerimConsole>console).originalLog(stringifiedArgs);
}
