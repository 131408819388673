// inspired by https://24ways.org/2010/calculating-color-contrast/

const HEX_LENGTH_FULL = 6;
const HEX_LENGTH_HALF = 3;

// TODO: Tests
export function isBright(color: string): boolean {

	const hex = normalizeHexColor(color);
	const c_r = parseInt(hex.substring(0, 0 + 2), 16);
	const c_g = parseInt(hex.substring(2, 2 + 2), 16);
	const c_b = parseInt(hex.substring(4, 4 + 2), 16);
	const brightness = ((c_r * 299) + (c_g * 587) + (c_b * 114)) / 1000;

	return brightness > 155;
}

// TODO: Tests
export function getHexColor(color: string) {
	//if color is already in hex, just return it...
	if (color.indexOf('#') != -1) return color;

	//leave only "R,G,B" :
	color = color
		.replace("rgba", "") //must go BEFORE rgb replace
		.replace("rgb", "")
		.replace("(", "")
		.replace(")", "");
	const colorArr = color.split(","); // get Array["R","G","B"]

	// 0) add leading #
	// 1) add leading zero, so we get 0XY or 0X
	// 2) append leading zero with parsed out int value of R/G/B
	//    converted to HEX string representation
	// 3) slice out 2 last chars (get last 2 chars) =>
	//    => we get XY from 0XY and 0X stays the same
	return "#"
		+ ('0' + parseInt(colorArr[0], 10).toString(16)).slice(-2)
		+ ('0' + parseInt(colorArr[1], 10).toString(16)).slice(-2)
		+ ('0' + parseInt(colorArr[2], 10).toString(16)).slice(-2);
}

/**
 * - Removes hex prefix '#'.
 * - Converts to 6 digits format rrggbb.
 * - If input is in different format, it stays intact (DOES NOT WORK WITH NAMED COLORS LIKE 'red')
 */
export function normalizeHexColor(hexColor: string): string {
	if (hexColor.startsWith('#')) {
		hexColor = hexColor.substr(1);
	}

	// rgb equals rrggbb - all digits are duplicated.
	if (hexColor.length === HEX_LENGTH_HALF) {
		const arr = hexColor.split('');
		const [r, g, b] = [...arr];
		hexColor = `${r}${r}${g}${g}${b}${b}`;
	}

	if (hexColor.length !== HEX_LENGTH_FULL) {
		console.warn('normalizeHexColor is of incorrect length, no color returned');
		return '';
	}

	return hexColor;
}
