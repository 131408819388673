/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PmsStatusDto { 
    /**
     * Flag indincating if PMS is active.
     */
    operationMode: PmsStatusDto.OperationModeEnum;
}
export namespace PmsStatusDto {
    export type OperationModeEnum = 'assisted' | 'free';
    export const OperationModeEnum = {
        Assisted: 'assisted' as OperationModeEnum,
        Free: 'free' as OperationModeEnum
    };
}


