import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IShar3dWindow } from '../interfaces';
import { INACTIVITY_STATE, INACTIVITY_TIMER_SECONDS } from '../models';

@Injectable()
export class InactivityService {
	private inactivityTimer: number;
	private inactivityState$: BehaviorSubject<INACTIVITY_STATE> = new BehaviorSubject(INACTIVITY_STATE.NONE);
	_hostWindow: IShar3dWindow;

	constructor() {}

	set hostWindow(window: IShar3dWindow) {
		this._hostWindow = window;
	}

	get hostWindow(): IShar3dWindow {
		return this._hostWindow;
	}

	get isHostShared(): boolean {
		return this.hostWindow?.shar3dbridge !== undefined;
	}

	startInactivityTimerIfNotRunning(waitTime: number = INACTIVITY_TIMER_SECONDS): void {
		if (this.isHostShared) {
			this.inactivityTimer = waitTime;
			if (this.inactivityState$.getValue() === INACTIVITY_STATE.NONE) {
				this.hostWindow.shar3dbridge.startInactivityMonitor(waitTime);
				this.inactivityState$.next(INACTIVITY_STATE.COUNTING);
			}
		}
	}

	setInactivityMode(state: INACTIVITY_STATE): void {
		this.inactivityState$.next(state);
	}

	stopInactivityTimer(): void {
		if (this.isHostShared) {
			this.setInactivityMode(INACTIVITY_STATE.NONE);
			this.hostWindow.shar3dbridge.stopInactivityMonitor();
		}
	}

	get getInactivityState$(): BehaviorSubject<INACTIVITY_STATE> {
		return this.inactivityState$;
	}

	get getInactivityTimer(): number {
		return this.inactivityTimer;
	}
}
