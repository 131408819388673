import { Base64 } from '@merim/utils';

import { Shar3dUtils } from './shar3d-utils';

/**
 * Main class for the JWT
 */
export class Shar3dUtilsJWT {
	/**
	 * Converter for a JWT (as string or object)
	 */
	public static readonly JWTConverter: (a: any) => IShar3dHttpJWTResponse | null = (a) => {
		let o: any = null;
		if (Shar3dUtils.isString(a)) {
			try {
				o = JSON.parse(a);
			} catch (e) {
				return null;
			}
		} else if (Shar3dUtils.isObject(a)) {
			o = a;
		} else {
			return null;
			//throw new Error("Invalid object type");
		}
		if (Shar3dUtils.isUndefined(o.access_token)) {
			return null;
		}
		return {
			access_token: o.access_token,
			expires_in: Shar3dUtils.isDefined(o.expires_in) && Shar3dUtils.isNumber(o.expires_in) ? o.expires_in : -1,
			token_type: Shar3dUtils.isDefined(o.token_type) && Shar3dUtils.isString(o.token_type) ? o.token_type : undefined
		};
	};

	/**
	 * Get the decoded header of a JWT
	 * @param token string
	 */
	public static getDecodedHeader(token: string): object | null {
		return this.getDecodedJWTPart(token, 0);
	}

	/**
	 * Get the decoded payload of a JWT
	 * @param token string
	 */
	public static getDecodedPayload(token: string): object | null {
		return this.getDecodedJWTPart(token, 1);
	}

	/**
	 * Get the decoded payload of a JWT
	 * @param token string
	 */
	public static getPartToVerifySignature(token: string): string | null {
		// Get part of the JWT
		const partsOfTheJWT: string[] = (token || '').split('.');
		// Check it
		if (partsOfTheJWT.length !== 3) {
			// This is not a correct JWT
			return null;
		}
		return partsOfTheJWT[0] + '.' + partsOfTheJWT[1];
	}

	/**
	 * Get the signature part of a JWT
	 * @param token string
	 */
	public static getSignature(token: string): string | null {
		return (token || '').split('.')[2] || null;
	}

	/**
	 * Get a decoded part of a JWT
	 */
	private static getDecodedJWTPart(token: string, partIndex: 0 | 1): object | null {
		// Get part of the JWT
		const partsOfTheJWT: string[] = (token || '').split('.');
		// Check it
		if (partsOfTheJWT.length !== 3) {
			// This is not a correct JWT
			return null;
		}
		// Try to decode the payload
		const stringPart: string = Base64.decode(partsOfTheJWT[partIndex]);
		if (!stringPart || !Shar3dUtils.isString(stringPart)) {
			return null;
		}
		// Try to parse
		try {
			const jwtDecodedPart: object = JSON.parse(stringPart.replace(/\u0000/g, ''));
			return Shar3dUtils.isObject(jwtDecodedPart) ? jwtDecodedPart : null;
		} catch (e) {
			return null;
		}
	}

	/**
	 * Check if a JWT is expired compared to a reference time (now if not specified)
	 */
	public static isJWTExpired(jwt: string, refTimestampInSecond: number = Math.floor(Shar3dUtils.now() / 1000)): boolean {
		// Check parameters
		if (!Shar3dUtils.isString(jwt) || !Shar3dUtils.isNumber(refTimestampInSecond)) {
			return true;
		}
		const payloadOfTheJWT: any | null = Shar3dUtilsJWT.getDecodedPayload(jwt);
		// Search for "exp" claim
		if (!payloadOfTheJWT || !Shar3dUtils.isNumber(payloadOfTheJWT[JWT_CLAIM_EXPIRATION_TIME])) {
			return true;
		}
		// The token is expired if its expiration date is lower than the reference timestamp
		return payloadOfTheJWT[JWT_CLAIM_EXPIRATION_TIME] < refTimestampInSecond;
	}

	public static getJWTPartToObject(s: string): object | null {
		// Try to decode the payload
		const stringPart: string = Base64.decode(s);
		if (!stringPart || !Shar3dUtils.isString(stringPart)) {
			return null;
		}
		return Shar3dUtilsJWT.getJWTPartToObjectDecoded(stringPart);
	}

	public static getJWTPartToObjectDecoded(stringPart: string): object | null {
		// Try to parse
		try {
			const jwtDecodedPart: object = JSON.parse(stringPart.replace(/\u0000/g, ''));
			return Shar3dUtils.isObject(jwtDecodedPart) ? jwtDecodedPart : null;
		} catch (e) {
			return null;
		}
	}
}

/** Header claims **/
export const JWT_CLAIM_ALGORITHM = 'alg';
export const JWT_CLAIM_TYPE = 'typ';
export const JWT_CLAIM_CONTENT_TYPE = 'cty';
export const JWT_CLAIM_KEY_ID = 'kid';
/** Payload claims **/
export const JWT_CLAIM_ISSUER = 'iss';
export const JWT_CLAIM_SUBJECT = 'sub';
export const JWT_CLAIM_AUDIENCE = 'aud';
export const JWT_CLAIM_EXPIRATION_TIME = 'exp';
export const JWT_CLAIM_NOT_BEFORE_TIME = 'nbf';
export const JWT_CLAIM_ISSUED_AT_TIME = 'iat';
export const JWT_CLAIM_JWT_ID = 'jti';

export interface IShar3dHttpJWTResponse {
	access_token: string;
	expires_in?: number;
	token_type?: string;
}
