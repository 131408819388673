import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfigurationsApiClient, RestoSettingsDto } from '@rap/api-client';
import { keys, values } from 'ramda';
import { catchError, combineLatest, Observable, of, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { ConfigurationUrlsType, IRestoSettings, RAPBE, RESTO_SETTINGS, URL_TYPE_ENUM } from '../models';
import { ConfigurationService } from './configuration.service';

const DEFAULT_RESTO_SETTINGS_ENDPOINT = '/api/configurations/resto-settings';

@Injectable({ providedIn: 'root' })
export class RestoSettingsService implements OnDestroy {
	private url: ConfigurationUrlsType = { boUrl: '', directUrl: '', rapBeUrl: '' };
	private readonly ngUnsubscribe$: Subject<void> = new Subject<void>();

	constructor(
		private readonly httpClient: HttpClient,
		private readonly configurationService: ConfigurationService<any>,
		private readonly route: ActivatedRoute,
		private readonly configurationApiService: ConfigurationsApiClient
	) {
		const configValues$ = this.configurationService.config$;
		const queryParams = this.route.queryParams;

		combineLatest([configValues$, queryParams])
			.pipe(
				takeUntil(this.ngUnsubscribe$),
				filter(
					([configData, queryParams]) => configData?.['API']?.[RESTO_SETTINGS] || configData?.['API']?.[RESTO_SETTINGS] || queryParams?.bo
				)
			)
			.subscribe(([configData, queryParams]) => {
				const boUrl = queryParams?.bo ? queryParams.bo + DEFAULT_RESTO_SETTINGS_ENDPOINT : '';
				this.url = {
					boUrl: boUrl,
					rapBeUrl:
						configData?.['API']?.[RAPBE]?.url ||
						`${window.location.protocol}//${window.location.hostname}:${configData?.['API']?.[RAPBE]?.port || 3333}`,
					directUrl: configData?.['API']?.[RESTO_SETTINGS]?.url || '',
				};

				if (this.getUrlPrecedency) {
					console.log(`Resto settings service: Restosettings url updated to: ${values(this.getUrlPrecedency)?.[0]}`);
				}
			});
	}

	/**
	 * Precedence of resto settings URL is:
	 * Take it from bo query parameter
	 * Take it from direct link to resto settings data url defined in config file
	 * Take if from rap link defined in config file
	 */
	get getUrlPrecedency(): Record<string, string> | undefined {
		if (this.url.boUrl) {
			return { [URL_TYPE_ENUM.BO_URL]: this.url.boUrl };
		} else if (this.url.directUrl) {
			return { [URL_TYPE_ENUM.DIRECT_URL]: this.url.directUrl };
		} else if (this.url.rapBeUrl) {
			return { [URL_TYPE_ENUM.RAP_BE_URL]: this.url.rapBeUrl };
		}

		return undefined;
	}

	download(): Observable<IRestoSettings> {
		const url = values(this.getUrlPrecedency)?.[0] || '';
		const queryType = keys(this.getUrlPrecedency)?.[0];
		const dataEndpoint =
			queryType === URL_TYPE_ENUM.RAP_BE_URL ? this.configurationApiService.getRestoSettingsJson() : this.httpClient.get(url);

		return dataEndpoint.pipe(
			map((data) => data as RestoSettingsDto),
			catchError((err) => {
				console.error('[ERROR] downloading resto settings.', err);
				return of(null);
			})
		);
	}

	ngOnDestroy() {
		this.ngUnsubscribe$.next();
		this.ngUnsubscribe$.complete();
	}
}
