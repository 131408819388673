<div class="dk-tresholds-container">
	@for (horizontalBreak of horizontalBreaks; track horizontalBreak) {
		<div class="dk-blind-treshold"></div>
	}
</div>

<div class="dk-value-bar" [style.height]="barHeight"></div>

<div class="dk-tresholds-container-overlay">
	@for (horizontalBreak of horizontalBreaks; track horizontalBreak) {
		<div class="dk-treshold">
			<div class="dk-treshold-label" [ngClass]="{ 'dk-active-treshold-label': isActiveTreshold(horizontalBreak) }">
				@if (isActiveTreshold(horizontalBreak)) {
					<div>
						T-Line {{ tLineNumber(horizontalBreak) }} &nbsp;
					</div>
				} @else {
					{{ horizontalBreak }}
				}
			</div>
			<div class="dk-treshold-line" [ngClass]="{ 'dk-active-treshold-line': isActiveTreshold(horizontalBreak) }"></div>
		</div>
	}
</div>
