/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface VatValueDto { 
    /**
     * Name of the VAT value.
     */
    tvaName: VatValueDto.TvaNameEnum;
    /**
     * Specification of the VAT value as created on global level.
     */
    value: string;
    /**
     * Percentual VAT rate.
     */
    percent: number;
}
export namespace VatValueDto {
    export type TvaNameEnum = 'EatIn' | 'TakeAway';
    export const TvaNameEnum = {
        EatIn: 'EatIn' as TvaNameEnum,
        TakeAway: 'TakeAway' as TvaNameEnum
    };
}


