/**
 * Hard-coded based on data from productFamilies of Cap Mechant
 */

export enum CM_RE_PRODUCT_FAMILIES {
	Burger = '16',
	Dessert = '17',
	BoissonChaude = '18',
	BoissonFroide = '19',
	Menu = '20',
	Accompagnement= '21',
	PlatsChauds = '22',
	Pates = '23',
	SaladeJap = '24',
	Salades = '25',
	Test = '26',
	Boulangerie = '27',
	Cake='28',
	PatisserieInd = '29',
	Tarte = '30',
	Macarons = '31',
	GrandGateau = '32',
	Viennoiserie = '33',
	Chocolat = '34',
	Sandwich = '35',
	Snacking= '36',
	Sauce = '37',
	Glace = '38',
}
