import { DeviceRole } from '@merim/utils';

/**
 * The structure of network configuration file administered by the BKConfigurator, available at:
 * http://192.168.100.200:8181/network.json
 */
export type NetworkConfiguration = {
	Devices: [
		{
			type: DeviceRole;
			minAddress: string;
			maxAddress: string;
			port: string;
			time?: string;
			ringrestriction?: string /** 192.168.100.200;192.168.100.16;192.168.100.17;192.168.100.14; */;
		},
	];
	Network: {
		netmask: string;
		gateway: string;
		broadcast: string;
		dns: string[];
	};
};
