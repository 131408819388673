/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface IntervalPredictionDto { 
    /**
     * Automated prediction result.
     */
    prediction: number;
    /**
     * Prediction modified by authorized person.
     */
    modifiedPrediction?: number;
}

