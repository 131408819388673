// Maybe move to more common location. But at the moment it is consumed by RAP-BE only, so it is ok
// see http://192.168.100.200:8080/server/bkGetRestoSettings.php?bo=192.168.100.200:81 for more details

export class RestoSettings {
	timestamp: number;

	disableAllKiosks: false;

	tableService: boolean;

	waitAtTheTable: boolean;

	printTicketOnAllBurgers: boolean;

	hasWalkByWindow: boolean;

	enableEatInOnKiosk: boolean;

	terraceAvailableForTableService: boolean;
}

/**
 * FULL CONTENT OF RESTOSETTINGS.JSON FILE
 *
 * 	timestamp: 1623754588,
  restosettings: {
    currentKdsConfig: 21,
    currentKioskMenu: 0,
    currentCashMenu: 0,
    currentOrbConfig: 487,
    currentDriveConfig: 44,
    printerKds: { '1': 1, '2': 1, '3': 1, '4': 1, '5': 1, '6': 1 },
    currentDrivePay: {
      ip: '192.168.100.14',
      idx: 1,
      name: 'OSMDM-DRIVEPAY1',
      role: 'DRIVEPAY'
    },
    currentDriveCOD1: {
      ip: '192.168.100.14',
      idx: 1,
      name: 'OSMDM-DRIVEPAY1',
      role: 'DRIVEPAY'
    },
    currentDriveCOD2: {
      ip: '192.168.100.17',
      idx: 1,
      name: 'OSMDM-DRIVECO1',
      role: 'DRIVECO'
    }
  },
  moneyconfig: [
    { label: 'Rouleau de 25 pièces de 2€, soit 50€', value: 50 },
    { label: 'Rouleau de 25 pièces de 1€, soit 25€', value: 25 },
    { label: 'Rouleau de 40 pièces de 50cts, soit 20€', value: 20 },
    { label: 'Rouleau de 40 pièces de 20 cts, soit 8€', value: 8 },
    { label: 'Rouleau de 40 pièces de 10 cts, soit 4€', value: 4 },
    { label: 'Rouleau de 50 pièces de 5 cts, soit 2.5€', value: 2.5 },
    { label: 'Rouleau de 50 pièces de 2 cts, soit 1€', value: 1 },
    { label: 'Rouleau de 50 pièces de 1 cts, soit 50 cts', value: 0.5 },
    { label: 'Vrac monnaie', value: 1 }
  ],
  csiconfig: { modespaiement: [] },
  cashFundParameters: { initialCashFund: 150, alertWidthdraw: 300, cashFoundNumber: 7 },
  restostate: 2,
  disableAllKiosks: false,
  disableKiosks: [],
  messageOnTicket: '',
  toiletCode: '15523',
  maxCrownPerEmployee: 12,
  maxRestTR: 5,
  enableMultipayOnKiosk: true,
  kioskMultipayMessage: 'Merci de vous diriger vers la caisse prévue à cet effet.',
  kioskMultipayMessageLangs: {
    fr: 'Merci de vous diriger vers la caisse prévue à cet effet.',
    en: 'Please move towards a cash register available for this purpose.'
  },
  kioskMultipayMode: 2,
  payingPosMultipayMode1: 1,
  payingPosMultipayMode2: 0,
  enableKingdom: true,
  enableORBDigital: false,
  printCODTicket: false,
  employeeMealInEuros: true,
  alertDayForDumpSafe: 1,
  preparationAheadTimeWebS: 180,
  cashTargetWebOrder: '192.168.100.1',
  driveSortByFirstProduct: false,
  openDrawerOnEmployeeMeal: true,
  printCODLongTicket: true,
  tableService: true,
  waitAtTheTable: true,
  printTicketOnAllBurgers: true,
  hasWalkByWindow: true,
  enableEatInOnKiosk: true,
  terraceAvailableForTableService: true,
 showProductsOnSimplifiedFiscalTicket: false
 */
