export enum DeviceRole {
	/** Back Office (local server) */
	BO = 'BO',
	/** Screen cooperating with DRIVECO to display order process to customers */
	COD = 'COD',
	/** CSI Sales server on Local server */
	CSI = 'CSI',
	/** Passive media screen with offers in the restaurant */
	DMB = 'DMB',
	/** POS in DRIVECO mode */
	DRIVECO = 'DRIVECO',
	/** POS in DRIVEPAY mode */
	DRIVEPAY = 'DRIVEPAY',
	/** PAX on drive (see PAX) */
	DRIVE_PAX = 'DRIVEPAX',
	/** POS on a tablet in drive mode - not actually used, tablet on DRIVE is just POSTABLET in DRIVE mode (tested on Fantome) */
	DRIVE_TABLET = 'DRIVETABLET',
	/** Classic kiosk */
	KIOSK = 'KIOSK',
	/** Also a kiosk, but with different configuration and/or different peripheral devices (terminal etc.) */
	KIOSK_ACRELEC = 'KIOSKACRELEC',
	/** Also a kiosk, but with different configuration and/or different peripheral devices (terminal etc.) */
	KIOSK_V = 'KIOSKV',
	/** Kitchen device screen */
	KDS = 'KDS',
	/** ORBp screen for DRIVE */
	ORBd = 'ORBD',
	/** Classic ORBp screen */
	ORBp = 'ORBP',
	/** ORB screen with numbers */
	ORBs = 'ORB',
	/** Price board */
	PBD = 'PBD',
	/** I assume this is the PC in the Back Office  */
	PC = 'PC',
	/** Point of Sale */
	POS = 'POS',
	/** PAX = third party tablet with ordering functionality in POS mode */
	POS_PAX = 'POSPAX',
	/** POS on a tablet */
	POS_TABLET = 'POSTABLET',
	/** Printer on regular POS */
	PRINTER = 'PRINTER',
	/** Printer on DRIVEPAY */
	PRINTER_DRIVEPAY = 'PRINTERDRIVEPAY',
	/** Printer on KDS */
	/**
	 * ! May require update in the future, it is not yet used in BKConfigurator, even though everyone says it is.
	 * ! See: https://gitlab.reboot-qsr.com/BK/BKConfigurator/-/blob/master/network.json
	 */
	PRINTER_KDS = 'PRINTERKDS', //
	/** Printer on ORBp */
	PRINTER_ORBP = 'PRINTERORB',
	/** Speed of Service */
	SOS = 'SOS',
	/** Payment terminal */
	TPE = 'TPE',
	/** Payment terminal in DRIVE */
	TPE_DRIVEPAY = 'TPEDRIVEPAY',
	/** BO-LOCAL-MANAGER for webordering */
	WEB = 'WEB',
	/** Passive media screen with offers in DRIVE (also known as Totems Drive) */
	XMB = 'XMB',
}
