/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * Removes given properties from an object, and returns given type.
 */
export function omit<T>(obj: { [key: string]: any }, propertiesToRemove: string[]): T {
	let result: { [key: string]: any } = {...obj};
	propertiesToRemove.forEach((prop) => {
		result = removeProperty(result, prop);
	});
	return result as T;
}

export function removeProperty(obj: { [key: string]: any }, propertyToRemove: string): { [key: string]: any } {
	const result: { [key: string]: any } = {...obj};
	delete result[propertyToRemove];
	return result;
}

export function isString(obj: any): boolean {
	return typeof obj === 'string';
}

export function isNumber(val: any): boolean {
	return typeof val === 'number' && val === val;
}

export function isStringifiedObject(str: string): boolean {
	if (!str || str === '' || str === 'undefined' || str === 'null') {
		return false;
	}

	const result = str.startsWith('{') && str.endsWith('}');
	return result;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
/**
 * undefined and null is not an object.
 * Returns true for {}.
 */
export function isEmptyObject(obj: any): boolean {
	const isEmpty = (
		!!obj && // null and undefined check
		Object.keys(obj).length === 0 &&
		obj.constructor === Object
	);

	return isEmpty;
}

// Based on _.isNil(): https://github.com/lodash/lodash/blob/master/isNil.js?source=post_page---------------------------
export function isNil(value: any) {
	return value == null;
}

// Was used in a lot of places by ABProd
// as angular.isUndefined.
// Does not account for null
export function isUndefined(value: any) {
	return typeof value === 'undefined';
}

// Was used in a lot of places by ABProd
// as angular.isDefined
// Does not account for null
export function isDefined(value: any) {
	return typeof value !== 'undefined';
}

export function hasValue(value: any) {
	return value !== undefined && value !== null;
}

/**
 * Filters out properties of given Object which are undefined.
 * Making it a Partial<T>
 */
export function removeUndefinedProperties<T>(obj: T): Partial<T> {
	const result = {} as T;
	Object.keys(obj as Record<string, any>).forEach((key) => {
		const value = (<any>obj)[key];
		if (value !== undefined) {
			(<any>result)[key] = value;
		}
	});

	return result;
}
