/**
 * DKI-BE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Prediction } from './prediction';
import { WeatherHourlyStatus } from './weather-hourly-status';


export interface DetailProductsForecast {
	/**
	 * Datetime of the prediction
	 */
	datetime: string;
	/**
	 * Prediction Interval [minute(s)]
	 */
	timeinterval: number;
	/**
	 * ProductForecast for each ItemId
	 */
	sales_map: { [key: string]: Prediction; };
	/**
	 * Total Revenue Prediction
	 */
	revenue: Prediction;
	/**
	 * Weather Prediction
	 */
	hourly_weather_forecast: WeatherHourlyStatus;
}

