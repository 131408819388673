/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SelectionPatternDto } from './selection-pattern-dto';


export interface OptionDto { 
    /**
     * Id of the option.
     */
    id: number;
    /**
     * System name of the option.
     */
    sysName: string;
    /**
     * Dictionary (associative array) of names where key is the language.
     */
    _name: { [key: string]: string; };
    /**
     * Dictionary (associative array) of descriptions where key is the language.
     */
    _description: { [key: string]: string; };
    /**
     * Type of the option.   Values:   NONE = 0,   FREE_PRODUCT_OPTIONAL = 1,   FREE_PRODUCT = 2,   PAID_PRODUCT = 3,   INGREDIENT_PICKUP = 4,   INGREDIENT_REMOVE = 5,   INGREDIENT_RESTORE = 6,   INGREDIENT_FULL_PICKUP = 7,   INGREDIENT_EXTRA_PICKUP = 8
     */
    optionType: number;
    /**
     * Filter for the delivery mode.   Values (DeliveryModeMask):   NONE = 0,   DELIVERY_LOCAL = 2 ** DeliveryModeEnum.DELIVERY_LOCAL,   DELIVERY_TAKE_OVER = 2 ** DeliveryModeEnum.DELIVERY_TAKE_OVER
     */
    deliveryFilter?: number;
    /**
     * URL encoded string arguments.
     */
    stringArgs?: string;
    /**
     * Patterns for the selection of elements for the option.
     */
    optionContent: Array<SelectionPatternDto>;
    /**
     * Patterns for the selection of items the option applies to.
     */
    applicableToPattern: Array<SelectionPatternDto>;
    /**
     * Flag indicating if the option is associated to a campaign.
     */
    campaignOption: boolean;
}

