import {
	ConfigurationGlobalDto,
	FeatureConfigurationDto,
	FidelityDto,
	FiscalDto,
	FoodCategoryDto,
	LanguagesDto,
	LegalDto,
	OrdersDto,
	RestaurantDto,
	WebordersDto,
} from '@rap/api-client';
import { clone } from 'ramda';

export class GlobalConfigStoreModel {
	loading: boolean = true;
	data: AppConfigurationGlobal | undefined = undefined;

	constructor(init?: Partial<GlobalConfigStoreModel>) {
		Object.assign(this, clone(init));
	}
}

// TODO: create front app types for used values and map them to backend types in global-configuration.service
export type AppConfigurationGlobal = {
	brand: ConfigurationGlobalDto.BrandEnum;
	environment: ConfigurationGlobalDto.EnvironmentEnum;
	restaurant: RestaurantDto;
	legal?: LegalDto;
	fiscal: FiscalDto;
	fidelity?: FidelityDto;
	features: AppConfigGlobalFeatures;
	languages: LanguagesDto;
	orders: OrdersDto;
	weborders: WebordersDto;
	productList: AppConfigGlobalProductList;
};

export type AppConfigGlobalFeatures = {
	configurations: Array<FeatureConfigurationDto>;
	isCombinedRestaurantPrice: boolean;
};

export type AppConfigGlobalProductList = {
	foodCategories: Array<AppConfigGlobalFoodCategory>;
	productFamilies: Array<AppConfigGlobalProductFamily>;
};

export type AppConfigGlobalFoodCategory = {
	name: string;
	productFamilyIds: Array<number>;
	color?: string;
	icon: FoodCategoryDto.IconEnum;
}

export type AppConfigGlobalProductFamily = {
	id: number;
	name: string;
}