import { BKMenuType, IBKItemBase, IBKItemInOrderBase, IBKPublishedOrderData, IBKSubItemInOrderBaseWithFreeItems } from '@bk/jscommondatas';
import { APPLICATION_NAME, BrandName, isBk, isQuick, ORBp } from '@merim/utils';
import { IncrementLetterGenerator } from '../classes';
import { DEFAULT_KIDS_MENU_SUFFIXES } from '../enums/default-kids-menu-suffixes';
import { KidsMenuSuffixes } from '../types/kids-menu-suffixes';

export type PatchedIBKItemBase = IBKItemBase & {
	isPatchedWithSuffix?: boolean;
};

type HELPER_TYPE = {
	filteredMenuSelection: IBKItemInOrderBase[]
};

/**
 * This is 2, because it means "burger and toys" (2 items).
 */
const MENU_FILTERED_SIZE = 2;


export type PatchKidsMenuOptions = {
	brandName: BrandName;
	/**
	 * Which application is calling patchKidsMenu()
	 */
	targetApplication: APPLICATION_NAME;
	suffixesOption?: KidsMenuSuffixes;
}

/**
 * Adds suffix according to menu type
 * @param order Expected to be a `BKOrder` or `IBKPublishedOrderData`
 * @param suffixesOption Change suffixes for KJ and KJ+
 */
export function patchKidsMenu(order: IBKPublishedOrderData, options: PatchKidsMenuOptions): void {
	//order means BKOrder, but we dont want bk/js-lib here
	const kidsMenus: IBKItemInOrderBase[] = order.orderContent.filter((orderContent) => {
		return orderContent._kidsMenu;
	});

	if (kidsMenus.length === 0) {
		return;
	}

	const {brandName, suffixesOption, targetApplication} = options;

	const KJMenus = filterKidsMenu(kidsMenus, BKMenuType.KING_JUNIOR, options);
	const KJMenusPlus = filterKidsMenu(kidsMenus, BKMenuType.KING_JUNIOR_PLUS, options);

	// Explanation
	// Both BurgerKing and Quick wants to add suffixes to distinguish kids menu and sort them to proper paperboxes.
	// But BK does so only based on Burgers-and-toys, while Quick on whole Menu content.
	const shouldSuffixBeAdded = KJMenus.length !== 0 || KJMenusPlus.length !== 0;
	const sameToyOrBurgerKJ = isBk(brandName)
		? sameToyOrBurgerInMenus(KJMenus)
		: areIdenticalMenus(KJMenus);
	const sameToyOrBurgerKJPlus = isBk(brandName)
		? sameToyOrBurgerInMenus(KJMenusPlus)
		: areIdenticalMenus(KJMenusPlus);

	const defaultKidsMenuSuffixEnum = DEFAULT_KIDS_MENU_SUFFIXES;
	const kjSuffix = suffixesOption?.kjSuffix ? suffixesOption.kjSuffix : defaultKidsMenuSuffixEnum.KJ;
	const kjPlusSuffix = suffixesOption?.kjPlusSuffix ? suffixesOption.kjPlusSuffix : defaultKidsMenuSuffixEnum.KJ_PlUS;

	// BurgerKing: If the toy and burger are not the same, we are adding suffix letters.
	addSuffixAndLetter(KJMenus, shouldSuffixBeAdded, !sameToyOrBurgerKJ, kjSuffix, targetApplication);
	addSuffixAndLetter(KJMenusPlus, shouldSuffixBeAdded, !sameToyOrBurgerKJPlus, kjPlusSuffix, targetApplication);
}


/**
 * Different behaviour for BK and Quick.
 * For BK it returns subset of KidsMenu (only burgers and toys).
 * For other brands it returns the original KidsMenu.
 *
 * Menus which are not for Kids are excluded.
 */
// kidsMenus = BKMenuInOrder but we do not want to rely on bk/js-lib here...
function filterKidsMenu(kidsMenu: IBKItemInOrderBase[], menuType: BKMenuType, options: PatchKidsMenuOptions): HELPER_TYPE[] {
	const {brandName} = options;

	return kidsMenu
		.filter((item: IBKItemInOrderBase) => {
			return item._boMenuType === menuType && item.selection?.length > 0;
		})
		.map((item: IBKItemInOrderBase) => {
			const selection = item.selection;
			const filteredMenuSelection = isQuick(brandName)
				? selection
				: selectBurgersAndToys(selection);

			return {
				filteredMenuSelection
			};
		});
}

function selectBurgersAndToys(selection: IBKSubItemInOrderBaseWithFreeItems[]): IBKSubItemInOrderBaseWithFreeItems[] {
	// For BKFR: Take only Burgers and Toys.
	// Explanation:
	// Purpose of this filtering is to extract only Burger [position 0] and Toys [position last]
	// because those are the only BKFR Products which should have the suffix.
	// The disadvantage is that the position is dependent on POS Layout (defined in Global3),
	// so if/when layout changes, then the "filter by position" will break. (Acceptable for now.)
	const filteredMenuSelection = selection.filter((_item, index) => index === 0 || index === selection.length - 1);
	return filteredMenuSelection;
}

function sameToyOrBurgerInMenus(menus: HELPER_TYPE[]): boolean {
	const burgersSet = new Set();
	const toysSet = new Set();

	menus.forEach((item) => {
		// size of the filtered menu should always be two
		if (item.filteredMenuSelection.length === MENU_FILTERED_SIZE) {
			// Burger is on 1st position, and toy on last position (this is brand-specific).
			burgersSet.add(item.filteredMenuSelection[0].id);
			toysSet.add(item.filteredMenuSelection[item.filteredMenuSelection.length - 1].id);
		}
	});

	return burgersSet.size === 1 || toysSet.size === 1;
}

/**
 * Two menus are considered identical, when they have the same Products in them.
 * And those Products have the same customization (or none).
 */
function areIdenticalMenus(menus: HELPER_TYPE[]): boolean {
	if (menus.length === 0) {
		return true;
	}

	const productIdsPerMenu = menus.map((menu) => {
		const productIds = menu.filteredMenuSelection.map(prod => prod.id);
		return productIds.sort();
	});

	// Now check if every KidsMenu has the same Products.
	// This is for Quick, and Quick does not use product customization, so we can simplz compare product ids.
	let areSame = true;
	let tempProductIds = productIdsPerMenu[0].join(',');
	productIdsPerMenu.forEach(productIds => {
		const stringifiedIds = productIds.join(',');
		const sameAsPrevious = stringifiedIds === tempProductIds;
		if (sameAsPrevious === false) {
			areSame = false;
		}

		// Update variable for next iteration
		tempProductIds = stringifiedIds;
	})

	return areSame;
}

function addSuffixAndLetter(
	filteredKidsMenu: HELPER_TYPE[],
	shouldSuffixBeAdded: boolean,
	addSuffixLetter: boolean,
	suffix: DEFAULT_KIDS_MENU_SUFFIXES | string,
	targetApplication: APPLICATION_NAME): void {
	const suffixOrLetter: boolean = shouldSuffixBeAdded || addSuffixLetter;
	const letterSuffixGenerator = new IncrementLetterGenerator();

	filteredKidsMenu.forEach((menu: HELPER_TYPE) => {
		let menuSuffix = suffixOrLetter ? ` ${suffix}` : '';

		if (addSuffixLetter && menuSuffix) {
			menuSuffix += ` (${letterSuffixGenerator.next()})`;
		}

		if (targetApplication === ORBp) {
			// Render the suffix in bold
			menuSuffix = `<strong>${menuSuffix}</strong>`;
		}

		menu.filteredMenuSelection.forEach((selection) => {
			selection.shortName += menuSuffix;
			(<PatchedIBKItemBase>selection).isPatchedWithSuffix = true;
		});
	});
}
