import { SalesChannelOption } from '@bk/price-management-common';
import { MenuSize } from '../enums/menu-size';
import { ProductPriceType } from '../enums/product-price-type';
import { RestaurantPriceLevel } from './restaurant-price-level';
import { ThirdPartyPriceLevel } from './third-party-price-level';

/**
 * Represent the simplest data structure in Price Definition Schema:
 * The cell contains the Price,
 * and is identified by SalesChannelOption, PriceLevel and RowIdentifier
 */
// And is already defined in this Python MR https://gitlab.reboot-qsr.com/reboot3/rb3/-/merge_requests/1231/diffs (search for class PriceDefinition)

export type PriceDefinitionCell = {
	salesChannelOption: SalesChannelOption;
	/** Can be either `MenuSize` or `ProductPriceType`. Identifies the Row in UI */
	rowIdentifier: MenuSize | ProductPriceType;
	/** Identifies the Column in UI */
	priceLevel: RestaurantPriceLevel | ThirdPartyPriceLevel;
	withPrice: boolean;
	price: number;
	isDisabled: boolean;
};
