/**
 * DKI-BE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DiscountReportItem } from './discount-report-item';
import { DiscountStats } from './discount-stats';


export interface DiscountsReport {
	by_discount_type: Array<DiscountReportItem>;
	total: DiscountStats;
}

