<mat-card>

	@if (additionalActions.length >= 1) {
		<span class="material-icons dots" [matMenuTriggerFor]="actionsMenu">more_horiz</span>
		<mat-menu class="navigation-tile-menu" #actionsMenu="matMenu">
			@for (action of additionalActions; track action) {
				<p mat-menu-item
				   (click)="emitAction(action)"
				   [translate]="action.text"></p>
			}
		</mat-menu>
	}

	<mat-card-title [style.backgroundColor]="headerIcon.backgroundColor">
		<span class="material-icons">{{ headerIcon.class }}</span>
	</mat-card-title>

	<mat-card-subtitle>
		<span [translate]="title"></span>
	</mat-card-subtitle>

	<mat-card-content [matTooltip]="description | translate" matTooltipPosition="above">
		<span [translate]="description"></span>
	</mat-card-content>

	<mat-card-actions>
		@if (mainAction) {
			<button (click)="emitAction(mainAction)" [disabled]="mainAction.disabled">
				@if (mainAction.action === actionTypes.Refresh) {
					<span class="material-icons">refresh</span>
				}
				<ng-content select="[buttonIcon]"></ng-content>
				{{ mainAction.text | translate }}
			</button>
		}
	</mat-card-actions>

</mat-card>
