/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface MonitoringRestaurantDetailWebsockets { 
    /**
     * Number of configured websockets that should be connected.
     */
    configured: number;
    /**
     * Number of websockets that actually are connected.
     */
    connected: number;
    /**
     * Number of websockets that are not connected.
     */
    disconnected: number;
}

