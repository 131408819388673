/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SelectionPatternDto } from './selection-pattern-dto';


export interface NavigationScreenDataDto { 
    /**
     * Id of the item reference in the DBn
     */
    id: number;
    /**
     * The System Name
     */
    sysName: string;
    /**
     * Name displayed on the navigation screen
     */
    name: object;
    /**
     * Display Color
     */
    color1: string;
    /**
     * Display Color
     */
    color2: string;
    /**
     * Layout of the navigation screen
     */
    layout: number;
    /**
     * Element to display in area0
     */
    area0: Array<SelectionPatternDto>;
    /**
     * Element to display in area1
     */
    area1: Array<SelectionPatternDto>;
    /**
     * Element to display in area2
     */
    area2: Array<SelectionPatternDto>;
    /**
     * Element to display in area3
     */
    area3: Array<SelectionPatternDto>;
    /**
     * Element to display in area4
     */
    area4: Array<SelectionPatternDto>;
    _hasImageOn?: boolean;
    _hasImageOff?: boolean;
    _hasImagePromote?: boolean;
    _hasImageAlt?: boolean;
    /**
     * Array containing the ids of the tags associated with specific entries
     */
    xtag: Array<number>;
    campaignScreen?: boolean;
    extraFilter?: number;
}

