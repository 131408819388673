import { SalesChannel } from "../../enums";
import { OrbpDetail } from "../../types";
import { isActiveMaster, isDependent } from "../is-role";
import { getAssignedDevicesInMasters } from "./get-assigned-devices-in-masters";
import { getORBpsMatchingAllDevices } from "./get-orbp-matching-all-devices";

/**
 * Dependent ORBp can only selects from those Devices which are assigned to his Master ORBps.
 */
export function getDevicesAvailableForDependentORBp(
	salesChannel: SalesChannel,
	dependenOrbpId: string,
	allOrbps: OrbpDetail[],
	allAvailableDevices) {
	const orbpDetail = allOrbps.find(o => o.id === dependenOrbpId);

	// Sanity Check
	if (!isDependent(orbpDetail)) {
		const errMessage = `getDevicesAvailableForDependentORBp() - ORBp ${dependenOrbpId} has role ${orbpDetail.role}.`;
		console.error(errMessage);
		throw new Error(errMessage);
	}

	const activeMasters = allOrbps.filter(x =>
		isActiveMaster(x)
		&& orbpDetail.masterIds.includes(x.id));

	const orbpsWthAllDevices = getORBpsMatchingAllDevices(salesChannel, activeMasters);
	if (orbpsWthAllDevices.length > 0) {
		return allAvailableDevices;
	}

	const assigedDevices: string[] = getAssignedDevicesInMasters(salesChannel, activeMasters);
	return assigedDevices;
}
