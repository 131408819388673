/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { WeborderChannel } from './weborder-channel';
import { SalesChannelOption } from './sales-channel-option';


export interface ConfigurationRestaurantWeborderChannel { 
    name: string;
    channel: WeborderChannel;
    /**
     * Id of payment method for Plutus environments, otherwise payment method\'s MDM id.
     */
    paymentMethodId: number;
    /**
     * URL to get logo for weborder channel, if set.
     */
    logoUrl?: string;
    salesChannelOption?: SalesChannelOption;
}

