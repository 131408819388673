import { EnumMap } from '@merim/utils';

import { OrbpRole } from '../enums';
import { SalesChannelConfiguration } from '../types';

/**
 * Basic information for Load Balancing for ORBp which Mirrors a Master
 */
export type MirroredMasterConfiguration = {
	role: OrbpRole;
	masterIds: string[];
	salesChannelConfigurations?: SalesChannelConfiguration[];
	foodCategoryConfiguration: EnumMap;
};
