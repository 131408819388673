import { LoadBalancingConfigurations, OrbpDetail } from '../types';

/**
 * ORBp device configuration.
 * Consumed by ORBp device.
 *
 * Matches the OrbpConfiguration.ts in RAP-BE
 *
 */
export type OrbpConfiguration = OrbpDetail & {
	printTicketsEnabled: boolean;

	/**
	 * Can be undefined for LoadDistribution.EMPTY,
	 * because LoadDistribution.EMPTY accepts all Orders.
	 */
	loadBalancingConfigurations?: LoadBalancingConfigurations | undefined;
};
