import { POS_ROLE } from "@merim/utils";
import { BKOrderSourceEnum } from "./BKOrderSourceEnum";
import { BKOrderState } from "./BKOrderState";
import { BKOrderStateDetail } from "./BKOrderStateDetail";
import { ItemToSell } from "./models";
import { BKItemBaseImpl, BKPublishedOrderDataImpl } from "./myBKCommonClasses.generated";
import { IBKFidelityCODUserData, IBKKingdomRebootCoupon, IBKMachine, IBKMessageBase, IBKOrderEventData, IBKPublishedOrderData, IBKPrivilegeItem } from "./myBKCommonDatas";
import { BKPickUpTypeEnum } from "./myBKCommonEnums";
import { IBKTableAllocationAssignment } from "./myBKCommonPatterns";
import { PMS_DATA_REQUEST_TYPE, PMS_OPERATION_MODE } from "./pms";

export enum MESSAGE_TOPICS {
	FROM_BK_GROUP = 'frombkgroup',
	TO_BK_GROUP = 'tobkgroup',
	SCAN_MESSAGES = 'scan_messages'
}

export interface IRingMessage<T> extends IBKMessageBase {
	topic?: MESSAGE_TOPICS;
	bktype: string;
	content: T;
}

/**
 * For Legacy ORBp load balancing
 **/
export interface IBKORBpMetrics {
	logisticWeight: number;
	numberOrders: number;
	updateUUID?: string;
}

// import { BKOrderSourceEnum, BKPickUpTypeEnum, IBKMachine, IBKMessageBase, IBKPublishedOrderData, IBKTableAllocationAssignment } from '@bk/jscommondatas';

export interface IBKMessageBasketStateUpdate extends IBKMessageBase {
	orderUUID: string;
	state: BKOrderState;
	timestamp?: number;
}

export interface IBKMessageLiveBasketUpdate extends IBKMessageBase {
	type: BKMessageLiveBasketUpdateType,
	orderUuid: string,
	/**
	 * ORB number serves to identify the Order to Customer
	 */
	orbNumber: number,
	orderSource: number,
	orderDeliveryMode: number,
	orderEventMetadata: IBKOrderEventData[],
	content: IBKMessageLiveBasketUpdateContent[]
}

export enum BKMessageLiveBasketUpdateType {
	add = 'add',
	remove = 'remove'
}

export interface IBKMessageLiveBasketUpdateContent {
	id: number,
	quantity: number,
	menuId?: number,
}

export interface IBKMessageKDSFinished extends IBKMessageBase {
	orderUUID: string;
	kdsIndex: number;
}

export interface IBKMessageSessionLockConnecting extends IBKMessageBase {
	machine: IBKMachine;
	id: string;
}

export interface IBKMessageSessionLockAlreadyConnected extends IBKMessageBase {
	machine: IBKMachine;
	id: string;
}

export interface IBKMessageORBInfos {
	orb: number;
	alienId: string | null;
	tableAlloc: IBKTableAllocationAssignment | null;
	orderUUID: string;
	orderSource: BKOrderSourceEnum;
	orderState: BKOrderState;
	orbpSource: number;
	orderDate: number;
	orderStateDetail: BKOrderStateDetail;
	pickUpType?: BKPickUpTypeEnum;
}

export interface IBKMessageORBPrepare extends IBKMessageBase {
	orders: Array<IBKMessageORBInfos>;
}

export interface IBKMessageORBFinished extends IBKMessageBase {
	infos: IBKMessageORBInfos;
}

export interface IBKMessageORBClear extends IBKMessageBase {
	infos: IBKMessageORBInfos;
}

export interface IBKMessageDriveMode extends IBKMessageBase {
	enable: boolean;
}

export interface IBKMessageORBDisplay extends IBKMessageBase {
	orders: Array<IBKMessageORBInfos>;
	orbIndex: number;
}

export interface IBKMessageMenuOrderHelperCOD extends IBKMessageBase {
	isReEditing: boolean;
	menuId: number;
	/**
	 * Actually expects BKOrder which is defined in bk-js-lib, and has the same properties, but some methods added.
	 * But those methods would get stripped off during message serialization anyway.
	 */
	order: BKPublishedOrderDataImpl | null;
	drivecoidx?: number;
}

export interface IBKMessageCancelOrderCOD extends IBKMessageBase {
	orderUUID: string;
	drivecoidx?: number;
}

export interface IBKMessageSmartCODDriveCoActivity extends IBKMessageBase {
	isActive: boolean;
}

export interface IBKMessageOrderAssignedToOrbp extends IBKMessageBase {
	assignedToOrbpId: number;
	orderId: string;
}

// ABprod sync
export interface IBKMessageORBpSlavePages extends IBKMessageBase {
	pages: any;
	parentIndex: number;
	metrics?: IBKORBpMetrics;
}

// new Merim sync
export interface IChangedLineMessage extends IBKMessageBase {
	originatedFromOrbpId: number;
	changedLine: any; // ChangedLineInfo - defined in ORBp
}

// new Merim sync
export interface IBumpedOrderMessage extends IBKMessageBase {
	originatedFromOrbpId: number;
	bumpedOrder: any; // BumpedOrderInfo - defined in ORBp
}

// new Merim sync
export interface IParkedOrderMessage extends IBKMessageBase {
	originatedFromOrbpId: number;
	parkedOrder: any; // ParkedOrderInfo - defined in ORBp
}

export interface IBKMessageORBdSlavePages extends IBKMessageBase {
	pages: any;
	parentIndex: number;
}

export interface IBKMessageIMachineStart extends IBKMessageBase {
	iMachine: IBKMachine;
}

export interface IBKMessageKDSStart extends IBKMessageBase {
	orders: IBKPublishedOrderData[];
	ip: string;
}

// Request for ORBs initial sync data
export interface IBKMessageORBsRequestForInitialSynchronization extends IBKMessageBase {
	targetORBpIds: number[];
	originatedFromORBsId: number;
}

// Response for ORBs initial sync data
export interface IBKMessageORBsResponseForInitialSynchronization extends IBKMessageBase {
	targetORBsId: number;
	originatedFromORBpId: number;
	orders: IBKMessageORBInfos[];
}

// Data sent from ORBp to ORBs on configuration change
export interface IBKMessageORBpCOnfigChangeNotification extends IBKMessageBase {
	originatedFromORBpId: number;
}

export interface IBKMessageBasket extends IBKMessageBase {
	order: IBKPublishedOrderData;
}

export interface IBKMessageBasketPreview extends IBKMessageBase {
	order: IBKPublishedOrderData;
}

export interface IBKMessagePatchPaidDate extends IBKMessageBase {
	orderUuid: string;
	patchedPaidDate: number;
}

export interface IBKMessageCodProductSelection extends IBKMessageBase {
	items: ItemToSell[];
	selectionName: string;
	/** tag values for associated medias which can be played in SmartCOD second screen */
	selectionTags?: number[];
	selectionId: number;
}

/** ORBp - Printing in DSS Mode */
export interface IBKMessageOrbpPrintOrder extends IBKMessageBase {
	orbpId: number;
	order: IBKPublishedOrderData;
}

export interface IBKMessagePOSUnattended extends IBKMessageBase {
	isUnattended: boolean;
}

export interface IBKMessageSmartCODProbe extends IBKMessageBase {
	posId: number;
	posRole: POS_ROLE;
}

export interface IBKMessagePOSSelectedPrivilegeIds extends IBKMessageBase {
	/** idEcomm */
	ids: string[];
}

export interface IBKMessagePOSSelectedAvailableItemsPrivilegeIds extends IBKMessageBase {
	ids: number[];
}

export interface IBKMessagePOSAvailableItemsPrivilegeIds extends IBKMessageBase {
	/** BKMenu or BKProduct */
	availableItems: IBKPrivilegeItem[];
}

export interface IBKMessagePOSCoupons extends IBKMessageBase {
	coupons: IBKKingdomRebootCoupon[];
}

export interface IBKMessagePOSSelectedClassicPrivilegeId extends IBKMessageBase {
	/** idEcomm */
	id: string;
}

export interface IBKMessageFidelityCODData extends IBKMessageBase {
	data: IBKFidelityCODUserData;
}

export interface IBKMessagePMSModeChange extends  IBKMessageBase {
	mode: PMS_OPERATION_MODE
}

export interface IBKMessagePMSDataRequest extends IBKMessageBase {
	reason: PMS_DATA_REQUEST_TYPE
}

export interface IBKMessagePMSProgramChange extends  IBKMessageBase {
	programId: string;
	scheduledNextProgramId: string;
}

// TODO: fill in all the other IBKRingMessages, that extends IBKMessageBase
export type IBKRingMessageType =
	IBKMessageBasket
	| IBKMessagePOSUnattended
	| IBKMessageBasketPreview
	| IBKMessageOrbpPrintOrder
	| IBKMessageORBpCOnfigChangeNotification
	| IBKMessageORBsResponseForInitialSynchronization
	| IBKMessageORBsRequestForInitialSynchronization
	| IBKMessageKDSStart
	| IBKMessageIMachineStart
	| IBKMessageORBdSlavePages
	| IParkedOrderMessage
	| IBumpedOrderMessage
	| IChangedLineMessage
	| IBKMessageORBpSlavePages
	| IBKMessageOrderAssignedToOrbp
	| IBKMessageCancelOrderCOD
	| IBKMessageMenuOrderHelperCOD
	| IBKMessageORBDisplay
	| IBKMessageDriveMode
	| IBKMessageORBFinished
	| IBKMessageORBClear
	| IBKMessageORBPrepare
	| IBKMessageORBInfos
	| IBKMessageSessionLockAlreadyConnected
	| IBKMessageSessionLockConnecting
	| IBKMessageCodProductSelection;

export interface IBKMessageScan extends IBKMessageBase {
	code: string;
}

