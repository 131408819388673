export interface IBKTechShar3dInfos {
	version: string;
	osname: string;
	osarch: string;
	osversion: string;
	tasks: { [taskName: string]: IBKTechShar3dTaskInfo };
}

export interface IBKTechShar3dTaskInfo {
	lastStartTime: number;
	lastEndTime: number;
	startCount: number;
	stats?: IBKTechShar3dTaskStats;
	vars: { [key: string]: string };
}

export interface IBKTechShar3dTaskStats {
	messagesFromAppCounter?: number;
	messagesToAppCounter?: number;
	functionCallCounter?: number;
}
