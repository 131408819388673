/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SalesChannelConfigurationDto } from './sales-channel-configuration-dto';


export interface OrbpDetailBaseDto { 
    /**
     * Index of the ORBp (stringified integer).
     */
    id: string;
    /**
     * Current ORBp status. Critial property. See ORBP_CRITICAL_PROPERTIES.
     */
    status?: OrbpDetailBaseDto.StatusEnum;
    /**
     * Name for the ORBp configuration. Acts as a nickname.
     */
    name: string;
    /**
     * Current ORBp role. Critial property. See ORBP_CRITICAL_PROPERTIES.
     */
    role: OrbpDetailBaseDto.RoleEnum;
    /**
     * Ids of ORBp devices acting as a Master to this ORBp.          Points to another OrbpDetail.id, from which the Dependent or Slave Orb inherits the settings.      Dependent may have multiple Master.      Slave only 1.      Master does not have any.         Critial property. See ORBP_CRITICAL_PROPERTIES.
     */
    masterIds: Array<string>;
    /**
     * Configuration of sales channels and sales channel options for filtering. Mirror has the same value as his Master. Critial property. See ORBP_CRITICAL_PROPERTIES.
     */
    salesChannelConfigurations?: Array<SalesChannelConfigurationDto>;
    /**
     * Id of the ORBp printer assigned to the ORBp. One printer can only be assigned to one ORBp.
     */
    assignedOrbpPrinterId?: string;
    /**
     * Configuration of printer type. See [DEV-1267](https://reboot-qsr.atlassian.net/browse/DEV-1267).
     */
    assignedOrbpPrinterType?: OrbpDetailBaseDto.AssignedOrbpPrinterTypeEnum;
    /**
     * Flag indicating if orders can be parked.
     */
    canParkOrders?: boolean;
    /**
     * Flag indicating order simplification configuration.
     */
    simplificationOrders?: boolean;
}
export namespace OrbpDetailBaseDto {
    export type StatusEnum = 'Unknown' | 'Off' | 'ShuttingDown' | 'On';
    export const StatusEnum = {
        Unknown: 'Unknown' as StatusEnum,
        Off: 'Off' as StatusEnum,
        ShuttingDown: 'ShuttingDown' as StatusEnum,
        On: 'On' as StatusEnum
    };
    export type RoleEnum = 'Unknown' | 'Master' | 'Dependent' | 'Mirror';
    export const RoleEnum = {
        Unknown: 'Unknown' as RoleEnum,
        Master: 'Master' as RoleEnum,
        Dependent: 'Dependent' as RoleEnum,
        Mirror: 'Mirror' as RoleEnum
    };
    export type AssignedOrbpPrinterTypeEnum = 'grouped' | 'separated' | 'simplified';
    export const AssignedOrbpPrinterTypeEnum = {
        Grouped: 'grouped' as AssignedOrbpPrinterTypeEnum,
        Separated: 'separated' as AssignedOrbpPrinterTypeEnum,
        Simplified: 'simplified' as AssignedOrbpPrinterTypeEnum
    };
}


