import { NgModule } from '@angular/core';
import { ConfigurationsApiClient } from '@libs/shared-codebase/api/rap';
import { cacheInterceptorProviders } from '@libs/shared/interceptors';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ConfigurationEffects } from './state/effects';
import { ConfigurationFacade } from './state/facade';
import { reducer } from './state/reducer';

@NgModule({
	imports: [StoreModule.forFeature('configuration', reducer), EffectsModule.forFeature([ConfigurationEffects])],
	providers: [ConfigurationFacade, cacheInterceptorProviders, ConfigurationsApiClient],
})
export class StateConfigurationModule {}
