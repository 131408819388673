/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SalesChannelConfigurationDto } from './sales-channel-configuration-dto';
import { OrbpSplitRowConfigurationDto } from './orbp-split-row-configuration-dto';


export interface OrbpDetailDto { 
    /**
     * Index of the ORBp (stringified integer).
     */
    id: string;
    /**
     * Current ORBp status. Critial property. See ORBP_CRITICAL_PROPERTIES.
     */
    status?: OrbpDetailDto.StatusEnum;
    /**
     * Name for the ORBp configuration. Acts as a nickname.
     */
    name: string;
    /**
     * Current ORBp role. Critial property. See ORBP_CRITICAL_PROPERTIES.
     */
    role: OrbpDetailDto.RoleEnum;
    /**
     * Ids of ORBp devices acting as a Master to this ORBp.          Points to another OrbpDetail.id, from which the Dependent or Slave Orb inherits the settings.      Dependent may have multiple Master.      Slave only 1.      Master does not have any.         Critial property. See ORBP_CRITICAL_PROPERTIES.
     */
    masterIds: Array<string>;
    /**
     * Configuration of sales channels and sales channel options for filtering. Mirror has the same value as his Master. Critial property. See ORBP_CRITICAL_PROPERTIES.
     */
    salesChannelConfigurations?: Array<SalesChannelConfigurationDto>;
    /**
     * Id of the ORBp printer assigned to the ORBp. One printer can only be assigned to one ORBp.
     */
    assignedOrbpPrinterId?: string;
    /**
     * Configuration of printer type. See [DEV-1267](https://reboot-qsr.atlassian.net/browse/DEV-1267).
     */
    assignedOrbpPrinterType?: OrbpDetailDto.AssignedOrbpPrinterTypeEnum;
    /**
     * Flag indicating if orders can be parked.
     */
    canParkOrders?: boolean;
    /**
     * Flag indicating order simplification configuration.
     */
    simplificationOrders?: boolean;
    /**
     * Flag indicating if this ORBp acts as a default ORBp for selected SalesChannel.         If no other ORBp/Printer is configured to display that SalesChannel, it will be displayed on this ORBp/Printer.         Only for Master ORBp.         Only 1 ORBp can be set as default.
     */
    isDefaultForSalesChannel: boolean;
    /**
     * Configuration of food categories for filtering. Mirror has the same value as his Master. Keys are enum values (enum.value.toString()).
     */
    foodCategoryConfiguration: { [key: string]: boolean; };
    /**
     * Current load distribution configuration. Mirror has the same value as his Master. Critial property. See ORBP_CRITICAL_PROPERTIES.
     */
    loadDistribution: OrbpDetailDto.LoadDistributionEnum;
    /**
     * Assigned SortingOrdersAlgorithm by which the ORBp should sort the Orders. Master can have any valid value. Dependent has the \'default value\', and Mirror has whatever value has its Master.
     */
    sortingOrdersAlgorithm?: OrbpDetailDto.SortingOrdersAlgorithmEnum;
    /**
     * Number of minutes before alert starts on ORBp unfinished order. Usual value is 5.
     */
    alertTime: number;
    /**
     * Ids of ORBs screens connected to the ORBp.
     */
    clientScreenOrbIds: Array<string>;
    /**
     * Flag indicating if bump bar is enabled.
     */
    bumpbarEnabled: boolean;
    /**
     * Flag indicating if confirmation should be required from the user on first bump (order ready) when all items in the order are not checked on ORBp.
     */
    requireConfirmationOfIncompleteOrder: boolean;
    /**
     * Dependants may have split screens for each of the master(s), indicating position of the order on the screen. This settings is for dependents only.
     */
    splitRowsConfiguration?: Array<OrbpSplitRowConfigurationDto>;
}
export namespace OrbpDetailDto {
    export type StatusEnum = 'Unknown' | 'Off' | 'ShuttingDown' | 'On';
    export const StatusEnum = {
        Unknown: 'Unknown' as StatusEnum,
        Off: 'Off' as StatusEnum,
        ShuttingDown: 'ShuttingDown' as StatusEnum,
        On: 'On' as StatusEnum
    };
    export type RoleEnum = 'Unknown' | 'Master' | 'Dependent' | 'Mirror';
    export const RoleEnum = {
        Unknown: 'Unknown' as RoleEnum,
        Master: 'Master' as RoleEnum,
        Dependent: 'Dependent' as RoleEnum,
        Mirror: 'Mirror' as RoleEnum
    };
    export type AssignedOrbpPrinterTypeEnum = 'grouped' | 'separated' | 'simplified';
    export const AssignedOrbpPrinterTypeEnum = {
        Grouped: 'grouped' as AssignedOrbpPrinterTypeEnum,
        Separated: 'separated' as AssignedOrbpPrinterTypeEnum,
        Simplified: 'simplified' as AssignedOrbpPrinterTypeEnum
    };
    export type LoadDistributionEnum = 'Unknown' | 'EMPTY' | 'ROUND_ROBIN' | 'DYNAMIC_DISTRIBUTION' | 'PRODUCT_DYNAMIC_DISTRIBUTION';
    export const LoadDistributionEnum = {
        Unknown: 'Unknown' as LoadDistributionEnum,
        Empty: 'EMPTY' as LoadDistributionEnum,
        RoundRobin: 'ROUND_ROBIN' as LoadDistributionEnum,
        DynamicDistribution: 'DYNAMIC_DISTRIBUTION' as LoadDistributionEnum,
        ProductDynamicDistribution: 'PRODUCT_DYNAMIC_DISTRIBUTION' as LoadDistributionEnum
    };
    export type SortingOrdersAlgorithmEnum = 'UNKNOWN' | 'BY_VALIDATION_TIME' | 'BY_PAID_TIME' | 'BY_CREATION_TIME';
    export const SortingOrdersAlgorithmEnum = {
        Unknown: 'UNKNOWN' as SortingOrdersAlgorithmEnum,
        ByValidationTime: 'BY_VALIDATION_TIME' as SortingOrdersAlgorithmEnum,
        ByPaidTime: 'BY_PAID_TIME' as SortingOrdersAlgorithmEnum,
        ByCreationTime: 'BY_CREATION_TIME' as SortingOrdersAlgorithmEnum
    };
}


