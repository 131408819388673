import { EnumMap, flattenArray, getUniqueValues } from '@merim/utils';

import { OrbpDetail } from '../types/orbp-detail';

/**
 * If any of the ORBps has FoodCategory enabled, then it will enabled.
 * Otherwise false.
 */
export function getMergedFoodCategories(orbps: OrbpDetail[]): EnumMap {
	const result: EnumMap = {};

	if (!orbps.length) {
		return result;
	}

	const allFoodCategoryConfigs: EnumMap[] = orbps.map((o) => o.foodCategoryConfiguration);
	const allFoodCategories: string[] = getUniqueValues(flattenArray(allFoodCategoryConfigs.map((fc) => Object.keys(fc))));

	allFoodCategories.forEach((fc) => {
		const isEnabled = allFoodCategoryConfigs.some((fcc) => fcc[fc] === true);
		result[fc] = isEnabled;
	});

	return result;
}
