import { 
	AssignedOrbpPrinterType, 
	LoadDistribution, 
	OrbpRole, 
	OrbStatus, 
	ProgramOrbpConfiguration 
} from "@bk/fullstack-common";
import { DssProgramOrbpConfigurationDto } from "@rap/api-client/models";

import { SalesChannel, SortingOrdersAlgorithm } from "../enums";

export function mapProgramOrbpConfigurationFromDto(dto: DssProgramOrbpConfigurationDto): ProgramOrbpConfiguration {
	return {
		...dto,
		orbps: dto.orbps.map((orbp) => {
			return {
				...orbp,
				status: orbp.status as OrbStatus,
				role: orbp.role as OrbpRole,
				salesChannelConfigurations: orbp.salesChannelConfigurations.map((configuration) => {
					return {
						...configuration,
						salesChannel: configuration.salesChannel as SalesChannel
					}
				}),
				loadDistribution: orbp.loadDistribution as LoadDistribution,
				sortingOrdersAlgorithm: orbp.sortingOrdersAlgorithm as SortingOrdersAlgorithm,
				assignedOrbpPrinterType: orbp.assignedOrbpPrinterType as AssignedOrbpPrinterType,
				splitRowsConfiguration: orbp.splitRowsConfiguration
			}
		}),
		programId: dto.programId || ''
	}
}
