import { InlineResponse200, LiveBasketOrderContentsItems, LiveBasketOrdersItems } from '@libs/shared-codebase/api/clerkmaster';

export class LiveBasketDto {
	orders: LiveBasketOrdersItemsDto[];

	constructor(init?: Partial<InlineResponse200>) {
		this.orders = init?.orders.map((item) => new LiveBasketOrdersItemsDto(item));
	}
}

export class LiveBasketOrdersItemsDto {
	orderUuid: string;
	orderContent: LiveBasketOrderContentItemsDto[];

	constructor(init?: Partial<LiveBasketOrdersItems>) {
		this.orderUuid = init?.orderUuid;
		this.orderContent = init?.orderContent.map((item) => new LiveBasketOrderContentItemsDto(item));
	}
}

export class LiveBasketOrderContentItemsDto {
	id: number;
	qty: number;

	constructor(init?: Partial<LiveBasketOrderContentsItems>) {
		this.id = init?.id;
		this.qty = init?.qty;
	}
}
