/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BrandConfigurationOwnerManagement } from './brand-configuration-owner-management';
import { BrandConfigurationPriceManagement } from './brand-configuration-price-management';
import { BrandConfigurationMediaManagement } from './brand-configuration-media-management';
import { BrandConfigurationWebhookManagement } from './brand-configuration-webhook-management';
import { BrandConfigurationFidelity } from './brand-configuration-fidelity';
import { BrandConfigurationRestaurantManagement } from './brand-configuration-restaurant-management';
import { BrandConfigurationLanguages } from './brand-configuration-languages';
import { BrandConfigurationOrders } from './brand-configuration-orders';
import { BrandConfigurationTagManagement } from './brand-configuration-tag-management';
import { Brand } from './brand';
import { BrandConfigurationWeborderChannelManagement } from './brand-configuration-weborder-channel-management';
import { Environment } from './environment';
import { BrandConfigurationMonitoring } from './brand-configuration-monitoring';
import { BrandConfigurationProductListManagement } from './brand-configuration-product-list-management';
import { BrandConfigurationUserManagement } from './brand-configuration-user-management';
import { BrandConfigurationFiscalManagement } from './brand-configuration-fiscal-management';
import { BrandConfigurationUrls } from './brand-configuration-urls';


export interface BrandConfiguration { 
    brand: Brand;
    environment?: Environment;
    /**
     * Flag indicating if logic of Zelty environment should be utilized.
     */
    is_zelty_environment?: boolean;
    fidelity?: BrandConfigurationFidelity;
    fiscal_management?: BrandConfigurationFiscalManagement;
    languages?: BrandConfigurationLanguages;
    media_management?: BrandConfigurationMediaManagement;
    monitoring?: BrandConfigurationMonitoring;
    orders?: BrandConfigurationOrders;
    owner_management?: BrandConfigurationOwnerManagement;
    price_management?: BrandConfigurationPriceManagement;
    product_list_management?: BrandConfigurationProductListManagement;
    restaurant_management?: BrandConfigurationRestaurantManagement;
    tag_management?: BrandConfigurationTagManagement;
    user_management?: BrandConfigurationUserManagement;
    urls?: BrandConfigurationUrls;
    webhook_management?: BrandConfigurationWebhookManagement;
    weborder_channel_management?: BrandConfigurationWeborderChannelManagement;
}

