import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { NavigationTileActionType } from './enums';
import { NavigationTileAction, NavigationTileHeaderIcon } from './interfaces';

@Component({
	selector: 'merim-navigation-tile',
	templateUrl: 'navigation-tile.component.html',
	styleUrls: ['navigation-tile.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationTileComponent implements OnChanges {
	public actionTypes = NavigationTileActionType;
	public mainAction: NavigationTileAction;
	public additionalActions: NavigationTileAction[] = [];

	@Input() headerIcon: NavigationTileHeaderIcon;
	@Input() title: string;
	@Input() description: string;
	@Input() actions: NavigationTileAction[];
	@Output() onAction = new EventEmitter<NavigationTileAction>();

	ngOnChanges() {
		this.mapActionsOnChange();
	}

	public emitAction(action: NavigationTileAction) {
		this.onAction.emit(action);
	}

	private mapActionsOnChange() {
		this.mainAction = this.actions[0] ?? undefined;
		this.additionalActions = this.actions.slice(1, this.actions.length);
	}
}
