import { Shar3dUtils } from './shar3d-utils';

/**
 * First-in first-out queue with a fixed size that replaces its oldest element if full.
 */
export class Shar3dUtilsFifoQueue<T> {
	/**
	 * Queue
	 */
	private $queue: Array<T> = [];

	/**
	 * Constructor
	 */
	constructor(private $maxSize: number) {
		if (!Shar3dUtils.isNumber(this.$maxSize)) {
			throw new Error('A max size is required for the queue');
		}
	}

	/**
	 * Gets the maximum size of the collection
	 */
	public maxSize(): number {
		return this.$maxSize;
	}

	/**
	 * Returns the number of elements stored in the queue
	 */
	public size(): number {
		return this.$queue.length;
	}

	/**
	 * Adds the given element to this queue
	 */
	public add(element: T): void {
		if (element === null || Shar3dUtils.isUndefined(element)) {
			return;
		}
		if (this.isFull()) {
			this.$queue.shift();
		}
		this.$queue.push(element);
	}

	/**
	 * Clears this queue
	 */
	public clear(): void {
		this.$queue.splice(0, this.$queue.length);
	}

	/**
	 * Returns the first index where the predicate truth test passes; otherwise returns -1.
	 */
	public findIndex(compareFun: (element: T, index?: number) => boolean): number {
		if (compareFun) {
			for (let i = 0, max = this.size(); i < max; i++) {
				if (compareFun(this.get(i)!, i)) return i;
			}
		}
		return -1;
	}

	/**
	 * Removes an element from the queue
	 * Returns the removed element or null if no element has been removed
	 * @param number start
	 *   Index at which to start changing the array (with origin 0). If greater than the length of the array, actual starting index will be set to the length of the array.
	 *   If negative, will begin that many elements from the end of the array (with origin -1) and will be set to 0 if absolute value is greater than the length of the array.
	 */
	public remove(start: number): T | null {
		if (!Shar3dUtils.isNumber(start)) {
			return null;
		}
		const removedElement: Array<T> = this.$queue.splice(start, 1); // Remove the element from the queue
		return removedElement[0] || null;
	}

	/**
	 * Returns the element at the specified position in this queue
	 */
	public get(index: number): T | null {
		return this.$queue[index] || null;
	}

	/**
	 * Returns true if the capacity limit of this queue has been reached
	 */
	public isFull(): boolean {
		return this.size() >= this.maxSize();
	}

	/**
	 * Returns true if this queue is empty; false otherwise
	 */
	public isEmpty(): boolean {
		return this.size() <= 0;
	}

	/**
	 * Returns true if the element is present in the queue; false otherwise
	 */
	public contains(compareFun: (element: T, index?: number) => boolean): boolean {
		if (!compareFun) {
			return false;
		}
		return this.$queue.some((e, i) => compareFun(e, i));
	}
}
