/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface BrandConfigurationMonitoring { 
    /**
     * Toggle for module in GAP.
     */
    enabled?: boolean;
    /**
     * Flag indicating if GAP-BE should collect monitoring data from restaurants.
     */
    collect_monitoring_data?: boolean;
    /**
     * Show \'MANAGER\' button in monitoring tab.
     */
    manager_link?: boolean;
    /**
     * Show \'WEBREPORTING\' button in monitoring tab.
     */
    webreporting_link?: boolean;
    /**
     * Show \'GUACAMOLE\' button in monitoring tab.
     */
    guacamole_link?: boolean;
    /**
     * Show \'LOGS\' button in monitoring tab.
     */
    logs_link?: boolean;
    /**
     * Show \'WEB ORDERS\' button in monitoring tab.
     */
    weborders_link?: boolean;
    /**
     * Show \'DATA UPDATE\' button in monitoring tab.
     */
    data_update_link?: boolean;
    /**
     * Show \'APP UPDATE\' button in monitoring tab.
     */
    app_update_link?: boolean;
    /**
     * Show \'VPN Address\' in restaurants\' list.
     */
    restaurant_vpn_ip_address?: boolean;
    /**
     * Show \'Plutus\' button in monitoring tab.
     */
    plutus_fiscal_link?: boolean;
}

