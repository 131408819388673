/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PmsDeviceDto } from './pms-device-dto';
import { TimerDto } from './timer-dto';


export interface PmsProgramsInputDto { 
    /**
     * Number of available devices
     */
    availableDevices: number;
    /**
     * Configuration mode
     */
    mode: PmsProgramsInputDto.ModeEnum;
    /**
     * Unique name of the configuration
     */
    name: string;
    /**
     * Configurable timers for PMS
     */
    timers: TimerDto;
    /**
     * Device detailed description
     */
    devices: Array<PmsDeviceDto>;
}
export namespace PmsProgramsInputDto {
    export type ModeEnum = 'assisted' | 'free';
    export const ModeEnum = {
        Assisted: 'assisted' as ModeEnum,
        Free: 'free' as ModeEnum
    };
}


