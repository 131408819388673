import { OpeningTimes } from './opening-times';
import { ServiceTimeThresholds } from './service-time-thresholds';

export interface Restaurant {
	id: string;
	name: string;
	address: string;
	city: string;
	country: string;
	opening_times?: OpeningTimes;
	line_thresholds?: Array<number>;
	service_time_thresholds?: ServiceTimeThresholds;
	last_sync?: string;
}
