import { Pipe, PipeTransform } from '@angular/core';
import { PERMISSIONS } from '../constants';

@Pipe({
	name: 'viewEdit'
})
export class ViewEditPipe implements PipeTransform {
	transform(permissions: PERMISSIONS | string): any {
		const icon = permissions === PERMISSIONS.READ ? 'visibility' : 'edit';
		return `<span>${icon}</span>`;
	}
}
