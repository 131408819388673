import { BrandName, bubbleSort } from '@merim/utils';
import { LOCALE_COMPARE_STR } from '../models';
import { ProductFamilyInfo } from "../types";
import {
	BChef_ProductOrderByAssemblyArea,
	BurgerKing_ProductOrderByAssemblyArea,
	DefaultProductOrderByAssemblyArea,
	Quick_ProductOrderByAssemblyArea
} from './product-families-sorting-order-maps';

export function sortItemsBasedOnProdFamilyOrder<T extends { assemblyAreaId?: number, shortName: string }>(
	products: T[],
	brandName:BrandName,
	productFamilyInfo: ProductFamilyInfo[]
): T[] {
	return bubbleSort(
		products,
		brandName,
		(a, b) => compareItemsBasedOnProdFamilyOrder(a, b, brandName, productFamilyInfo)
	);
}

function compareItemsBasedOnProdFamilyOrder<T extends {assemblyAreaId?: number, shortName: string}>(
	a:T,
	b:T,
	brandName:BrandName,
	productFamilyInfo: ProductFamilyInfo[]
): number {

	let prodFamilyA: number = undefined;
	let prodFamilyB: number = undefined;

	if (!a && !b) {
		return 0;
	}

	if (a && !b) {
		return -1;
	}

	if (b && !a) {
		return 1;
	}

	// legacy logic is kept for bk_re and cm_re brands
	// because sorting based on product families indexes was not specified for these brands
	if (brandName === BrandName.CapMerchant_Reunion || brandName === BrandName.BurgerKing_Reunion) {
		prodFamilyA = getProductFamilyId(a.assemblyAreaId, brandName);
		prodFamilyB = getProductFamilyId(b.assemblyAreaId, brandName);
	} else {
		prodFamilyA = productFamilyInfo?.findIndex((productFamily: ProductFamilyInfo) => productFamily.id === a.assemblyAreaId) || 0;
		prodFamilyB = productFamilyInfo?.findIndex((productFamily: ProductFamilyInfo) => productFamily.id === b.assemblyAreaId) || 0;
	}

	if (prodFamilyA - prodFamilyB > 0) {
		return 1;
	}

	if (prodFamilyA - prodFamilyB < 0) {
		return -1;
	}

	const nameA: string = a.shortName ?? '';
	const nameB: string = b.shortName ?? '';

	if (nameA && !nameB) {
		return -1;
	} else if (!nameA && nameB) {
		return 1;
	}

	return nameA.localeCompare(nameB, LOCALE_COMPARE_STR, {ignorePunctuation: true});
}

function getProductFamilyId(assemblyAreaId: number, brandName: BrandName): number {
	switch (brandName) {
		case BrandName.Quick:
		case BrandName.CapMerchant_Reunion:
			return Quick_ProductOrderByAssemblyArea.assemblyOrder(assemblyAreaId);
		case BrandName.BChef_France:
			return BChef_ProductOrderByAssemblyArea.assemblyOrder(assemblyAreaId);
		case BrandName.BurgerKing_France:
		case BrandName.BurgerKing_Reunion:
			return BurgerKing_ProductOrderByAssemblyArea.assemblyOrder(assemblyAreaId);
		default:
			return DefaultProductOrderByAssemblyArea;
	}
}