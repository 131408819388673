import { Ingredient } from '@libs/shared/models';
import { IProductSummary } from './product-summary.interface';

export interface IProductPersonalization {
	product: IProductSummary;
	productInMenuLineuuid?: string;
}

export interface ISectionAndIngredient {
	section: string;
	expanded?: boolean;
	ingredients: IIngredientAndAlternatives[];
}

export interface IIngredientAndAlternatives {
	alternatives: Ingredient[];
	ingredient: Ingredient;
}

export interface IIngredientsDifferences {
	id: number;
	name: string;
	qty: number;
	extraPrice: number;
}

export interface IIngredientsAddedRemoved {
	added: IIngredientsDifferences[];
	removed: IIngredientsDifferences[];
}
