/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FidelityProvider } from './fidelity-provider';


export interface BrandConfigurationFidelity { 
    /**
     * Fidelity management module in GAP. If active, appends RestaurantFeature.FIDELITY to restaurant_management.features list.
     */
    enabled?: boolean;
    provider?: FidelityProvider;
    customer_code_length_min?: number;
    customer_code_length_max?: number;
}

