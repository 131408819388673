import { IBKPublishedOrderData } from "@bk/jscommondatas";

export function numberOfProducts(order: IBKPublishedOrderData): number {
	let count = 0;
	order?.orderContent.forEach((item) => {
		// get total number of products in menu + free items for each product
		if (item.selection) {
			const currentProductCount = item.qty * item.selection.reduce((a, b) => {
				let freeItemsAmount = 0;
				// free items for each of the menu's items products
				if (b.freeItems) {
					freeItemsAmount = b.freeItems.reduce((c, d) => c + d.qty, 0);
				}
				return a + b.qty + freeItemsAmount;
			}, 0);

			// Free items for menu
			const mainFreeItemsCount = item.qty * item.freeItems.reduce((e, f) => e + f.qty, 0);
			count = currentProductCount + count + mainFreeItemsCount;
		} else {
			//get number of products + free items
			count += item.qty;
			if (item.freeItems) {
				const freeItemsCount = item.qty * item.freeItems.reduce((a, b) => a + b.qty, 0);
				count = freeItemsCount + count;
			}
		}
	})

	return count;
}
