import { BKTypePreparationDelivery } from '@merim/utils';

import { IBKDeliveryOptions } from '../models/ibk-delivery-options';

export function getDeliveryType(deliveryOptions: IBKDeliveryOptions | undefined): BKTypePreparationDelivery {
	let deliveryType: BKTypePreparationDelivery = BKTypePreparationDelivery.UNKNOWN;

	if (!deliveryOptions || !deliveryOptions.webordersource) {
		return BKTypePreparationDelivery.NO_DELIVERY;
	}

	switch (deliveryOptions.webordersource.toLowerCase()) {
		case 'deliveroo':
			deliveryType = BKTypePreparationDelivery.DELIVEROO;
			break;
		case 'ubereats':
			deliveryType = BKTypePreparationDelivery.UBER_EATS;
			break;
		case 'justeat':
			deliveryType = BKTypePreparationDelivery.JUST_EAT;
			break;
		case 'ecocea':
			deliveryType = BKTypePreparationDelivery.ECOCEA;
			break;
		case 'deliverybk':
			deliveryType = BKTypePreparationDelivery.BK_DELIVERY;
	}
	return deliveryType;
}
