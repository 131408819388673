/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DssProgramInputDto { 
    /**
     * Name of the DSS program.
     */
    name: string;
    /**
     * Description of the DSS program.
     */
    description: string;
    /**
     * Color schema for DSS program.
     */
    color: string;
    /**
     * Id of the DSS program the new program is base on.
     */
    basedOnProgramId?: string;
    /**
     * Start time of the DSS program during the day.
     */
    fromTime?: string;
    /**
     * End time of the DSS program during the day.
     */
    toTime?: string;
}

