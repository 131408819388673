/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 */
export type RestaurantFeature = 'DEFERRED_PAYMENT' | 'FIDELITY_PROGRAM' | 'GR_CARD_PAYMENT' | 'ICOUPON_PAYMENT' | 'KIOSK_MULTIPAYMENT' | 'ORB_SUBSCRIPTION' | 'WAIT_AT_THE_TABLE' | 'LOCAL_PRICE_CHANGE' | 'LOCAL_PRICE_CHANGE_DELIVERY' | 'TABLE_SERVICE' | 'TABLE_SERVICE_TERRACE' | 'KIOSK_EAT_IN' | 'WALK_BY_WINDOW';

export const RestaurantFeature = {
    DeferredPayment: 'DEFERRED_PAYMENT' as RestaurantFeature,
    FidelityProgram: 'FIDELITY_PROGRAM' as RestaurantFeature,
    GrCardPayment: 'GR_CARD_PAYMENT' as RestaurantFeature,
    IcouponPayment: 'ICOUPON_PAYMENT' as RestaurantFeature,
    KioskMultipayment: 'KIOSK_MULTIPAYMENT' as RestaurantFeature,
    OrbSubscription: 'ORB_SUBSCRIPTION' as RestaurantFeature,
    WaitAtTheTable: 'WAIT_AT_THE_TABLE' as RestaurantFeature,
    LocalPriceChange: 'LOCAL_PRICE_CHANGE' as RestaurantFeature,
    LocalPriceChangeDelivery: 'LOCAL_PRICE_CHANGE_DELIVERY' as RestaurantFeature,
    TableService: 'TABLE_SERVICE' as RestaurantFeature,
    TableServiceTerrace: 'TABLE_SERVICE_TERRACE' as RestaurantFeature,
    KioskEatIn: 'KIOSK_EAT_IN' as RestaurantFeature,
    WalkByWindow: 'WALK_BY_WINDOW' as RestaurantFeature
};

