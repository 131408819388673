/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ConfigurationRestaurantFeatureConfiguration } from './configuration-restaurant-feature-configuration';
import { RestaurantFeature } from './restaurant-feature';


export interface ConfigurationRestaurantFeatures { 
    /**
     * Deprecated, use \'configurations\' instead, will not be populated, kept for backwards compatibility with RAP-BE validator. Features that are toggled only on global level and if present, are already enabled.
     */
    enabledGlobally?: Array<RestaurantFeature>;
    /**
     * Deprecated, use \'configurations\' instead, will not be populated, kept for backwards compatibility with RAP-BE validator. Features that require to be enabled on both global and local level. Are disabled unless activated in restaurant.
     */
    enabledLocally?: Array<RestaurantFeature>;
    /**
     * All the features available in the restaurant. Some are enabled by default, some need to be additionally enabled in restaurant, some may be modified in restaurant only.
     */
    configurations: Array<ConfigurationRestaurantFeatureConfiguration>;
    /**
     * Flag indicating if \'EAT_IN\' and \'TAKE_AWAY\' sales channel options should be merged, i.e. should have the same prices set.
     */
    mergeEatInAndTakeAwaySalesChannelOptions: boolean;
}

