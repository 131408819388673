/**
 * DKI-BE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrdersByChannelWithCumulativeValue } from './orders-by-channel-with-cumulative-value';


export interface HourlySales {
	/**
	 * Order statistics by 15 minute intervals
	 */
	by_time: { [key: string]: OrdersByChannelWithCumulativeValue; };
	/**
	 * Total order statistics
	 */
	total: OrdersByChannelWithCumulativeValue;
}

