/**
 * DKI-BE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SosDttReport {
	/**
	 * Part of the day (morning, lunch, afternoon, ...)
	 */
	day_part: string;
	/**
	 * Business day or NULL if the report is aggregated for all days
	 */
	businessday: string;
	/**
	 * Restaurant ID or NULL if the report is aggregated for all restaurants
	 */
	restaurant_id: string;
	/**
	 * Mean value of sos.waittime, excluding zero values
	 */
	mean_sos_waittime: number;
	/**
	 * Mean value of sos.ordertime, excluding zero values
	 */
	mean_sos_ordertime: number;
	/**
	 * Mean value of sos.linetime, excluding zero values
	 */
	mean_sos_linetime: number;
	/**
	 * Mean value of sos.paytime, excluding zero values
	 */
	mean_sos_paytime: number;
	/**
	 * Mean value of sos.deliverytime, excluding zero values
	 */
	mean_sos_deliverytime: number;
	/**
	 * Mean value of sos.hardservetime, excluding zero values
	 */
	mean_sos_hardservetime: number;
	/**
	 * Mean value of sos.servetime, excluding zero values
	 */
	mean_sos_servetime: number;
	/**
	 * Mean value of sos.parktime, excluding zero values
	 */
	mean_sos_parktime: number;
	/**
	 * Mean value of sos.harddeparturetime - sos.endtime, excluding zero values
	 */
	mean_sos_departtime: number;
	/**
	 * Number of parked cars
	 */
	parked_cars: number;
	/**
	 * Number of transactions per day_part
	 */
	count: number;
	/**
	 * Number of transactions done within the same car
	 */
	sos_samecar: number;
	/**
	 * Total revenue of transactions
	 */
	total_revenue: number;
}

