/**
 * DKI-BE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MonitoringTaskInfo } from './monitoring-task-info';


export interface MonitoringDeviceInfo {
	/**
	 * Name of the device. Typically consisting of service pack version (OSMDM), device role (POS) and device index (1) => OSMDM-POS1.
	 */
	name: string;
	/**
	 * Role of the device. Only configured device roles will be processed. See /v1/monitoring/device-roles.
	 */
	role: string;
	/**
	 * Flag indicating if the device is active based on the report of Shar3dClient.
	 */
	is_active: boolean;
	/**
	 * Flag indicating if attention should be paid to the device. Device with warning is determined by having at least one task with \'is_warning\' property as set to \'true\' (see task \'is_warning\' property description for calculation logic).
	 */
	is_warning: boolean;
	/**
	 * Address in the VPN network.
	 */
	vpn_address?: string;
	/**
	 * Address in the local network which can be used for access from restaurant\'s local server when OpenVPN is not running on the device.
	 */
	local_address?: string;
	/**
	 * List of applications running on device.
	 */
	tasks?: Array<MonitoringTaskInfo>;
}

