import { HTTP, HTTPS, MAX_PROTOCOL_NUMBER } from '../constants';
import { isNumber } from './string-utils';

export function isIPAddress(urlAddress: string): boolean {
	// aaa.bbb.ccc.ddd
	const ipFormat = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

	const isIp = ipFormat.test(urlAddress);
	return isIp;
}

export function removePort(urlAddress: string): string {
	if (urlAddress.includes(':')) {
		return urlAddress.split(':')[0];
	} else {
		return urlAddress;
	}
}

export function hasAddressPort(urlAddress: string): boolean {
	return urlAddress.includes(':') && isNumber(urlAddress.split(':')[1]);
}

export function getPort(urlAddress: string): string {
	if (urlAddress.includes(':')) {
		return urlAddress.split(':')[1];
	} else {
		return null;
	}
}

/**
 * creates address from bo=<addr> in url params
 * used in POS e.g.
 * @param addr address to connect to
 * @param port port
 * return port
 */
export function createRemoteAddressWithPort(addr: string, port: number): string {
	// if it does not start with protocol, we add it manually
	const locationPrefix = addr.startsWith(`${HTTP}://`) || addr.startsWith(`${HTTPS}://`) ? '' : window.location.protocol + '//';

	const addrWithoutPortArr = addr.split(':');
	if (addrWithoutPortArr.length && +addrWithoutPortArr[addrWithoutPortArr.length - 1] < MAX_PROTOCOL_NUMBER) {
		addrWithoutPortArr.pop();
	}

	if (!port) {
		console.warn('Port not selected');
		return `${locationPrefix}${addrWithoutPortArr.join(':')}`;
	}

	return `${locationPrefix}${addrWithoutPortArr.join(':')}:${port}`;
}

export const addHttpIfNotPresent = (url: string): string => {
	if (url) {
		return url.startsWith('http://') || url.startsWith('https://') ? url : `${window.location.protocol}//${url}`;
	}

	return '';
};

export const iFrameDetection = () => (window === window.parent) ? false : true;

export const getPortFromUrl = (urlStr: string): string | undefined => {
	try {
		const url = new URL(urlStr);
		return url.port;
	} catch {
		return undefined;
	}
}
