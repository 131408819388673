/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CouponStatusDto { 
    /**
     * Number of coupons currently managed by RAP-BE.
     */
    managedCoupons: number;
    /**
     * Number of coupons that are available in buffer.
     */
    availableCoupons: number;
    /**
     * Number of coupons that weren\'t synchronized.
     */
    pendingSynchonizations?: number;
    /**
     * How many coupons should be available according to the configuration.
     */
    configuredBufferSize?: number;
}

