import { MenuSize } from "../enums";

export type MenuSizeInfo = {
	currentSize: MenuSize;

	/**
	 * Flag indicating if the current edited menu exists in L/Large
	 */
	existsInL: boolean;
	/**
	 * Flag indicating if the current edited menu exists in XL/ExtraLarge
	 */
	existsInXL: boolean;

	/**
	 * How much does it cost to increase menu to next higer size
	 */
	extraPrice: number | undefined;
}
