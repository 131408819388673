import { IBKPublishedOrderData } from '@bk/jscommondatas';
import { flattenArray, getKeyWithMinimalValueFromObject, getUniqueValues } from '@merim/utils';
import { isEqual } from 'lodash-es';

import { LoadDistribution, OrbpRole, OrbStatus } from '../enums';
import { numberOfProducts } from '../functions';
import { DssProgramPrintServer, LoadBalancingDistributionModel, OrbpDetail } from '../types';
import { isOrderSalesChannelAllowedInOrbp } from './is-order-sales-channel-allowed-in-orbp';

export class LoadBalancingCalculator {
	configuration: DssProgramPrintServer = null;
	distributions: LoadBalancingDistributionModel[] = [];

	constructor(configuration: DssProgramPrintServer) {
		this.configuration = configuration;
	}

	clean(): void {
		if (this.configuration) {
			this.distributions = this.configuration.orbpConfigurations
				.filter((item) => this.fitsDistributionConfiguration(item))
				.map((item) => new LoadBalancingDistributionModel({orbpId: item.id, receivedOrders: {}, oldestOrderBumpTime: undefined}));
		}
	}

	fitsDistributionConfiguration(item: OrbpDetail): boolean {
		return (
			item.status === OrbStatus.On &&
			item.role === OrbpRole.Master &&
			[LoadDistribution.DYNAMIC_DISTRIBUTION, LoadDistribution.ROUND_ROBIN, LoadDistribution.PRODUCT_DYNAMIC_DISTRIBUTION].includes(item.loadDistribution)
		);
	}

	updateOrderPrinted(orbpId: string, order: IBKPublishedOrderData, printed: boolean): void {
		const distr = this.distributions.find((item) => item.orbpId === orbpId);
		const distrOrder = distr?.receivedOrders[order.orderUUID];
		if (distrOrder) {
			distrOrder.printed = printed;
			distrOrder.numberOfProducts = numberOfProducts(order);
		}
	}

	removeOrder(orderUuid: string): void {
		this.distributions.forEach((distribution) => {
			if (distribution.receivedOrders[orderUuid]) {
				if (distribution.oldestOrderBumpTime) {
					distribution.oldestOrderBumpTime = Math.max(distribution.receivedOrders[orderUuid].lastUpdatedOn, distribution.oldestOrderBumpTime);
				} else {
					distribution.oldestOrderBumpTime = distribution.receivedOrders[orderUuid].lastUpdatedOn;
				}

				delete distribution.receivedOrders[orderUuid];
			}

		});
	}

	updateConfiguration(newConfiguration: DssProgramPrintServer): void {
		if (!newConfiguration) {
			return;
		}

		if (newConfiguration.id !== this.configuration?.id) {
			this.distributions = [];
		}

		if (!isEqual(this.configuration, newConfiguration)) {
			this.distributions = newConfiguration.orbpConfigurations
				.filter((item) => this.fitsDistributionConfiguration(item))
				.map((item) => new LoadBalancingDistributionModel({orbpId: item.id, receivedOrders: {}, oldestOrderBumpTime: undefined}));
		}

		this.configuration = newConfiguration;
	}

	findDistributionById(orbpId: string): LoadBalancingDistributionModel {
		return this.distributions.find((item) => item.orbpId === orbpId);
	}

	updateDistribution(orbpId: string, uuid: string, isParked: boolean = false, numberOfProducts: number = 0, lastUpdatedOn: number = 0): void {
		const distribution = this.distributions.find((item) => item.orbpId === orbpId);

		if (!distribution) {
			return;
		}

		distribution.receivedOrders[uuid] = {
			uuid,
			isParked: distribution.receivedOrders?.[uuid]?.isParked || isParked,
			printed: distribution.receivedOrders?.[uuid]?.printed || false,
			numberOfProducts: distribution.receivedOrders?.[uuid]?.numberOfProducts || numberOfProducts,
			lastUpdatedOn: lastUpdatedOn || new Date().getTime()
		};
	}

	/**
	 * Used in round robin mode - tourniquet
	 * @param fittingOrbps
	 */
	getOldestReceivedOn(fittingOrbps: string[]): LoadBalancingDistributionModel {
		const distributions = this.distributions.filter((item) => fittingOrbps.includes(item.orbpId));
		const getAllUnused = distributions
			.filter((item) => {
				const hasNeverBeenUsed = Object.values(item.receivedOrders).length === 0 && !item.oldestOrderBumpTime;
				const allParkedAndNotUsed = Object.values(item.receivedOrders).every((order) => order.isParked) && !item.oldestOrderBumpTime;
				return hasNeverBeenUsed || allParkedAndNotUsed;
			})
			.sort((a, b) => {
				return +a.orbpId - +b.orbpId;
			});

		if (getAllUnused.length > 0) {
			return getAllUnused[0];
		}

		const distributionsWithLastTime: Record<string, number> = distributions.reduce((a, b) => {
			const time = Object.values(b.receivedOrders).filter((order => !order.isParked)).map((order) => order.lastUpdatedOn);
			if (time.length > 0) {
				const receivedOrderTime = Math.max.apply(
					null,
					time
				);

				a[b.orbpId] = Math.max(receivedOrderTime, b.oldestOrderBumpTime || 0);
			} else if (b.oldestOrderBumpTime) {
				a[b.orbpId] = b.oldestOrderBumpTime;
			}

			return a;
		}, {});

		const orbpKey = getKeyWithMinimalValueFromObject(distributionsWithLastTime);
		return distributions.find((item) => item.orbpId === orbpKey);
	}

	/**
	 * Used in dynamic distribution
	 * take only orders that are not parked
	 * @param fittingOrbpIds
	 */
	getLeastUsed(fittingOrbpIds: string[]): LoadBalancingDistributionModel {
		const distributions = this.distributions.filter((item) => fittingOrbpIds.includes(item.orbpId));
		const minOrders = Math.min.apply(
			null,
			distributions.map((item) => Object.keys(item.receivedOrders).filter((uuid) => !item.receivedOrders[uuid].isParked).length)
		);

		const getLeastUsedOrbps = distributions
			.filter((item) => Object.keys(item.receivedOrders).filter((uuid) => !item.receivedOrders[uuid].isParked).length === minOrders)
			.sort((a, b) => {
				return +a.orbpId - +b.orbpId;
			});

		return getLeastUsedOrbps[0];
	}

	/**
	 * Used in product dynamic distribution
	 * @param fittingOrbpIds
	 */
	getLeastProducts(fittingOrbpIds: string[]): LoadBalancingDistributionModel {
		const getAmount = (item: LoadBalancingDistributionModel) => {
			return Object.values(item.receivedOrders).filter((orderData) => !orderData.isParked).reduce((a, order) => a + order.numberOfProducts, 0);
		};

		const distributions = this.distributions.filter((item) => fittingOrbpIds.includes(item.orbpId));
		const minProducts = Math.min.apply(
			null,
			distributions.map((item) => getAmount(item))
		);

		const getLeastUsedOrbps = distributions
			.filter((item) => getAmount(item) === minProducts)
			.sort((a, b) => {
				return +a.orbpId - +b.orbpId;
			});

		return getLeastUsedOrbps[0];
	}

	orbpsFittingInTheConfiguration(order: IBKPublishedOrderData): string[] {
		const orbpsOn = this.configuration.orbpConfigurations.filter((item) => item.status === OrbStatus.On);

		const fittingOrbps = orbpsOn.filter((item) => {
			const hasMatchingSalesChannel = isOrderSalesChannelAllowedInOrbp(order, item.salesChannelConfigurations);

			return hasMatchingSalesChannel;
		});

		return fittingOrbps.map((item) => item.id);
	}

	getOrderAssignedDistribution(uuid: string): LoadBalancingDistributionModel {
		return this.distributions.find((item) => item.receivedOrders[uuid]);
	}

	getMasterIds(orbpIds: string[]): string[] {
		const mappedIds = orbpIds.map((orbpId) => {
			const dist = this.configuration.orbpConfigurations.find((orbp) => orbp.id === orbpId);
			if (dist.role !== OrbpRole.Dependent && dist.role !== OrbpRole.Mirror) {
				return [dist.id];
			} else if (orbpId === dist.id) {
				return this.getMasterIds(dist.masterIds);
			} else {
				return [];
			}
		});

		const result = getUniqueValues(flattenArray(mappedIds));
		return result;
	}

	updateParkedOrderInDistribution(uuid: string, isParked: boolean) {
		const distribution = this.getOrderAssignedDistribution(uuid);
		if (!distribution) {
			console.warn(`[UUID]=${uuid} No matching distribution for such an order found - Ignoring parking assignment`)
			return;
		}
		distribution.receivedOrders[uuid].isParked = isParked;
	}
}
