/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Coupon } from './coupon';
import { Discount } from './discount';
import { CampaignResponse } from './campaign-response';
import { ItemType } from './item-type';


export interface MediaData { 
    products: Array<ItemType>;
    ingredients: Array<ItemType>;
    menus: Array<ItemType>;
    discounts: Array<Discount>;
    coupons: Array<Coupon>;
    campaigns: Array<CampaignResponse>;
}

