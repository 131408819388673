import { Injectable } from '@angular/core';
import { OrderEventsUtils } from '@bk/fullstack-common';
import { BKOrderEventArgumentKeys, BKOrderEventType } from '@bk/jscommondatas';
import { IAllergensState } from '@libs/shared/store/allergens';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { keys } from 'ramda';
import { tap } from 'rxjs';
import { AddOrderEvents } from '../../../../order/src/lib/state/actions';
import { CleanAllergensState, UpdateUsedAllergensState } from './actions';

@Injectable()
export class AllergensEffects {
	constructor(
		private actions$: Actions,
		private store: Store<IAllergensState>
	) {}

	onAllergensAdd = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(UpdateUsedAllergensState),
				tap((action) => {
					this.store.dispatch(
						AddOrderEvents({
							data: [
								OrderEventsUtils.createEvent(BKOrderEventType.ALLERGEN_ADD, {
									[BKOrderEventArgumentKeys.IDS]: keys(action.allergens).join('|') || '-',
								}),
							],
						})
					);
				})
			);
		},
		{ dispatch: false }
	);

	onAllergensClean = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(CleanAllergensState),
				tap((action) => {
					this.store.dispatch(
						AddOrderEvents({ data: [OrderEventsUtils.createEvent(BKOrderEventType.ALLERGEN_ADD, { [BKOrderEventArgumentKeys.IDS]: '-' })] })
					);
				})
			);
		},
		{ dispatch: false }
	);
}
