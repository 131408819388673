import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfigurationsApiClient } from '@rap/api-client';
import { keys, values } from 'ramda';
import { catchError, combineLatest, Observable, of, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { BIG_DATA, ConfigurationUrlsType, IBKBigData, RAPBE, URL_TYPE_ENUM } from '../models';
import { ConfigurationService } from './configuration.service';

const DEFAULT_BIG_DATA_ENDPOINT = '/api/configurations/big-data';

@Injectable({ providedIn: 'root' })
export class BigDataService implements OnDestroy {
	private url: ConfigurationUrlsType = { boUrl: '', directUrl: '', rapBeUrl: '' };
	private readonly ngUnsubscribe$: Subject<void> = new Subject<void>();

	constructor(
		private readonly httpClient: HttpClient,
		private readonly configurationService: ConfigurationService<any>,
		private readonly route: ActivatedRoute,
		private readonly configurationApiService: ConfigurationsApiClient
	) {
		const configValues$ = this.configurationService.config$;
		const queryParams = this.route.queryParams;

		combineLatest([configValues$, queryParams])
			.pipe(
				takeUntil(this.ngUnsubscribe$),
				filter(([configData, queryParams]) => configData?.['API']?.[RAPBE] || configData?.['API']?.[BIG_DATA] || queryParams?.bo)
			)
			.subscribe(([configData, queryParams]) => {
				const boUrl = queryParams?.bo ? queryParams.bo + DEFAULT_BIG_DATA_ENDPOINT : '';
				this.url = {
					boUrl: boUrl,
					rapBeUrl:
						configData?.['API']?.[RAPBE]?.url ||
						`${window.location.protocol}//${window.location.hostname}:${configData?.['API']?.[RAPBE]?.port || 3333}`,
					directUrl: configData?.['API']?.[BIG_DATA]?.url || '',
				};

				if (this.getUrlPrecedency) {
					console.log(`Big data service: Big data url updated to: ${values(this.getUrlPrecedency)?.[0]}`);
				}
			});
	}

	/**
	 * Precedence of big data URL is:
	 * Take it from bo query parameter
	 * Take it from direct link to big data url defined in config file
	 * Take if from rap link defined in config file
	 */
	get getUrlPrecedency(): Record<string, string> | undefined {
		if (this.url.boUrl) {
			return { [URL_TYPE_ENUM.BO_URL]: this.url.boUrl };
		} else if (this.url.directUrl) {
			return { [URL_TYPE_ENUM.DIRECT_URL]: this.url.directUrl };
		} else if (this.url.rapBeUrl) {
			return { [URL_TYPE_ENUM.RAP_BE_URL]: this.url.rapBeUrl };
		}

		return undefined;
	}

	download(): Observable<IBKBigData> {
		const url = values(this.getUrlPrecedency)?.[0];
		const queryType = keys(this.getUrlPrecedency)?.[0];
		const dataEndpoint = queryType === URL_TYPE_ENUM.RAP_BE_URL ? this.configurationApiService.getBigDataJson() : this.httpClient.get(url);

		return dataEndpoint.pipe(
			map((data) => data as IBKBigData),
			catchError((err) => {
				console.error('[ERROR] downloading big datas.', err);
				return of(null);
			})
		);
	}

	ngOnDestroy() {
		this.ngUnsubscribe$.next();
		this.ngUnsubscribe$.complete();
	}
}
