import { getLocaleFirstDayOfWeek } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';

@Injectable()
export class LocalizedDateAdapter extends NativeDateAdapter {
	constructor(@Inject(LOCALE_ID) public locale) {
		super(locale);
	}

	getFirstDayOfWeek() {
		return getLocaleFirstDayOfWeek(this.locale);
	}
}
