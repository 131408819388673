import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { ValidationMessage } from '@merim/utils';

const invalidClass = 'ng-invalid';
const validClass = 'ng-valid';

@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: '[field-validation]'
})
/**
 * Marks the form control with valid or invalid class
 * depending on the input ValidationMessages
 */
export class FieldValidationDirective implements OnChanges {
	constructor(private readonly hostElement: ElementRef, private readonly renderer: Renderer2) {
	}

	// TODO - Consider adding abstractControl.setValid()
	@Input('field-validation') validationMessages: ValidationMessage[] = [];

	/**
	 * Optional.
	 * Use when the "propertyName" in "validationMessages" differs from "formControlName".
	 */
	@Input('property-name') propertyName: string | undefined = undefined;

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['validationMessages']) {
			// SetTimeout is needed, otherwise the addClass is reset by native form validation
			setTimeout(() => {
				const element = this.hostElement.nativeElement;
				const propertyName = this.getPropertyName();

				const isValid = this.validationMessages === undefined
					|| this.validationMessages.filter(vm => vm.propertyName === propertyName).length === 0;
				const classToAdd = isValid ? validClass : invalidClass;
				const classToRemove = isValid ? invalidClass : validClass;
				this.renderer.addClass(element, classToAdd);
				this.renderer.removeClass(element, classToRemove);
			});
		}
	}

	private getPropertyName(): string {
		return this.propertyName || this.hostElement.nativeElement.getAttribute('formControlName');
	}
}
