/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AllergenDto { 
    /**
     * Id of the allergen.
     */
    id: number;
    /**
     * Dictionary (associative array) of names where key is the language.
     */
    _name: { [key: string]: string; };
    /**
     * Dictionary (associative array) of descriptions where key is the language.
     */
    _description: { [key: string]: string; };
    /**
     * If the allergen has main image.
     */
    _hasImageMain?: boolean;
    /**
     * If the allergen has alternative image.
     */
    _hasImageAlt?: boolean;
}

