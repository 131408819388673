/**
 * DKI-BE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrdersWithCumulativeValue } from './orders-with-cumulative-value';


export interface OrdersByChannelWithCumulativeValue {
	/**
	 * Order statistics by sales channel
	 */
	by_channel: { [key: string]: OrdersWithCumulativeValue; };
	/**
	 * Order statistics for all sales channels in total
	 */
	total: OrdersWithCumulativeValue;
}

