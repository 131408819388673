import {
	EnumMap,
	flattenArray,
	getTruthyEnumMapKeys,
	getUniqueValues
} from '@merim/utils';

import { FoodCategoryMappingModel } from '../models';

/**
 * @param dssFoodConfiguration - new RAP DSS FoodCategories configuration
 * Used in ORBp, Cash and PrintServer
 */
export function getAllowedProductFamilyIds(
	/**
	 * Which FoodCategories are currently allowed by admin in RAP
	 */
	dssFoodConfiguration: EnumMap,

	/**
	 * Mapping between FoodCategories and ProductFamilies.
	 */
	foodCategoriesMapping: FoodCategoryMappingModel[]
): string[] {
	const allowedFoodCategories = getTruthyEnumMapKeys(dssFoodConfiguration);
	const allowedProductFamilyIds: string[] = flattenArray(allowedFoodCategories
		.map(fc => {
			const match = foodCategoriesMapping.find(mapping => mapping.name === fc);
			if (match) {
				return match.productFamilyIds;
			} else {
				return undefined;
			}
		})
		// sanity check - remove food categories for which there is no definition in RAP-BE/GAP-BE configuration
		.filter(x => !!x))
		.map(x => x.toString());

	return getUniqueValues(allowedProductFamilyIds);
}
