/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DeviceDto { 
    /**
     * Name of the device including role and index.
     */
    name: string;
    /**
     * Role of the device.
     */
    role: DeviceDto.RoleEnum;
    /**
     * Index of the device.
     */
    idx: number;
    /**
     * IP address of the device.
     */
    ip: string;
}
export namespace DeviceDto {
    export type RoleEnum = 'BO' | 'COD' | 'CSI' | 'DMB' | 'DRIVECO' | 'DRIVEPAY' | 'DRIVEPAX' | 'DRIVETABLET' | 'KIOSK' | 'KIOSKACRELEC' | 'KIOSKV' | 'KDS' | 'ORBD' | 'ORBP' | 'ORB' | 'PBD' | 'PC' | 'POS' | 'POSPAX' | 'POSTABLET' | 'PRINTER' | 'PRINTERDRIVEPAY' | 'PRINTERKDS' | 'PRINTERORB' | 'SOS' | 'TPE' | 'TPEDRIVEPAY' | 'WEB' | 'XMB';
    export const RoleEnum = {
        Bo: 'BO' as RoleEnum,
        Cod: 'COD' as RoleEnum,
        Csi: 'CSI' as RoleEnum,
        Dmb: 'DMB' as RoleEnum,
        Driveco: 'DRIVECO' as RoleEnum,
        Drivepay: 'DRIVEPAY' as RoleEnum,
        Drivepax: 'DRIVEPAX' as RoleEnum,
        Drivetablet: 'DRIVETABLET' as RoleEnum,
        Kiosk: 'KIOSK' as RoleEnum,
        Kioskacrelec: 'KIOSKACRELEC' as RoleEnum,
        Kioskv: 'KIOSKV' as RoleEnum,
        Kds: 'KDS' as RoleEnum,
        Orbd: 'ORBD' as RoleEnum,
        Orbp: 'ORBP' as RoleEnum,
        Orb: 'ORB' as RoleEnum,
        Pbd: 'PBD' as RoleEnum,
        Pc: 'PC' as RoleEnum,
        Pos: 'POS' as RoleEnum,
        Pospax: 'POSPAX' as RoleEnum,
        Postablet: 'POSTABLET' as RoleEnum,
        Printer: 'PRINTER' as RoleEnum,
        Printerdrivepay: 'PRINTERDRIVEPAY' as RoleEnum,
        Printerkds: 'PRINTERKDS' as RoleEnum,
        Printerorb: 'PRINTERORB' as RoleEnum,
        Sos: 'SOS' as RoleEnum,
        Tpe: 'TPE' as RoleEnum,
        Tpedrivepay: 'TPEDRIVEPAY' as RoleEnum,
        Web: 'WEB' as RoleEnum,
        Xmb: 'XMB' as RoleEnum
    };
}


