export function saveToLocalStorage(key: string, value: any): void {
	try {
		localStorage.setItem(key, JSON.stringify(value));
	} catch (e) {
		return;
	}
}

export function readFromLocalStorage<T>(key: string): T {
	try {
		return JSON.parse(localStorage.getItem(key));
	} catch (e) {
		return null;
	}
}

export function removeFromLocalStorage<T>(key: string): void {
	try {
		localStorage.removeItem(key);
	} catch (e) {
		return;
	}
}
