/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PaymentMethod { 
    /**
     * Name of the payment method.
     */
    label: string;
    /**
     * Flag inficating if larger payment than ticket total is acceptable for the payment method.
     */
    allow_overpayment: boolean;
    /**
     * Flag indicating if overpayment may be returned to the customer.
     */
    allow_change: boolean;
    /**
     * Id of the payment method to be utilized to give change back to the customer.
     */
    change_id?: number;
    /**
     * Maximal value that can be paid with the payment method.
     */
    max_amount?: number;
    /**
     * Either we allow restaurant to define require_payment_method
     */
    enable_manager_authorization?: boolean;
    /**
     * Addition metadata for the payment method.
     */
    metadata?: object;
    /**
     * Internal (database) id of the payment method.
     */
    id: number;
}

