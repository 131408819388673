import { OrbpRole } from '../../enums';
import { OrbpDetail } from '../../types';
import { areAllSalesChannelsAssignedToSomeMaster } from './are-all-sales-channels-assigned-to-some-master';

// The "Default ORBp" is only required when some SalesChannel is not assigned to any Master
export function isValidDefaultORBpForSalesChannels(activeMasterOrbps: OrbpDetail[]): boolean {
	const canProgramHaveNoDefaultORBp = areAllSalesChannelsAssignedToSomeMaster(activeMasterOrbps);
	const defaultOrbps = activeMasterOrbps.filter((o) => o.role === OrbpRole.Master && o.isDefaultForSalesChannel);

	if (canProgramHaveNoDefaultORBp) {
		return defaultOrbps.length <= 1;
	} else {
		return defaultOrbps.length === 1;
	}
}

export function hasMissingDefaultORBpForSalesChannels(activeMasterOrbps: OrbpDetail[]): boolean {
	// If all SalesChannels are assigned, then there is no need for "Default Master ORBp"
	// Otherwise check if there is at least one "Default ORBp"
	const isProgramValidWithoutDefaultORBp = areAllSalesChannelsAssignedToSomeMaster(activeMasterOrbps);
	const defaultOrbps = activeMasterOrbps.filter((o) => o.role === OrbpRole.Master && o.isDefaultForSalesChannel);

	return isProgramValidWithoutDefaultORBp ? false : defaultOrbps.length === 0;
}
