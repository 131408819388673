/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OpeningTimes } from './opening-times';
import { RestaurantContactInfo } from './restaurant-contact-info';
import { RestaurantFiscalInfo } from './restaurant-fiscal-info';
import { Zelty } from './zelty';
import { RestaurantRemoteIdentificationsInfo } from './restaurant-remote-identifications-info';
import { TagInfo } from './tag-info';
import { RestaurantLegalInfo } from './restaurant-legal-info';
import { ServiceTimeThresholds } from './service-time-thresholds';
import { RestaurantState } from './restaurant-state';
import { RestaurantFeature } from './restaurant-feature';
import { WeborderChannel } from './weborder-channel';
import { RestaurantPriceLevel } from './restaurant-price-level';


/**
 * Restaurant information from MySQL.
 */
export interface Restaurant { 
    opening_times?: OpeningTimes;
    id: string;
    name: string;
    address: string;
    city: string;
    country: string;
    remote_identifications: RestaurantRemoteIdentificationsInfo;
    state: RestaurantState;
    features: Array<RestaurantFeature>;
    vat_group: number;
    tags: Array<TagInfo>;
    dtt_group_primary: number;
    dtt_group_secondary: number;
    contact: RestaurantContactInfo;
    publication: boolean;
    fiscal: RestaurantFiscalInfo;
    legal?: RestaurantLegalInfo;
    platform_id: number;
    created_at: string;
    modified_at: string;
    latitude: number;
    longitude: number;
    zelty: Zelty;
    address_extra?: string;
    zip_code?: number;
    last_dash_transaction_at: string;
    weborder_channels: Array<WeborderChannel>;
    price_levels: Array<RestaurantPriceLevel>;
    line_thresholds?: Array<number>;
    service_time_thresholds?: ServiceTimeThresholds;
    last_sync?: string;
    is_connected?: boolean;
    is_verified?: boolean;
    last_connection?: string;
    diagnostics_enabled?: boolean;
    vpn_address?: string;
    /**
     * Currently enabled features in a restaurant. Combination of features enabled per brand, on global level and in restaurant locally.
     */
    enabled_features?: Array<RestaurantFeature>;
    /**
     * Currently enabled weborder channels in a restaurant. Combination of weborder channels enabled per brand, on global level and not disabled in restaurant locally.
     */
    enabled_weborder_channels?: Array<WeborderChannel>;
}

