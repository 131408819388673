/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BrandConfigurationFoodCategory } from './brand-configuration-food-category';


export interface BrandConfigurationProductListManagement { 
    /**
     * Toggle for module in GAP.
     */
    enabled?: boolean;
    /**
     * Configuration of food categories utilized by DSS.
     */
    food_categories?: Array<BrandConfigurationFoodCategory>;
    /**
     * Sorting order of product families.
     */
    product_families_order?: Array<number>;
}

