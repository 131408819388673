/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SelectionPatternDto } from './selection-pattern-dto';


export interface ProductIngredientDto { 
    /**
     * Ingredient selection pattern.
     */
    ingredientPattern: Array<SelectionPatternDto>;
    /**
     * Id of pre-selected (default) ingredient.
     */
    ingredientDefault: number;
    /**
     * Flag indicating if the ingredient can be modified or not.
     */
    canModify: boolean;
    /**
     * Initial quantity of the ingredient in the product.
     */
    initialQty: number;
    /**
     * Minimal allowed quantity of the ingredient in the product.
     */
    minQty: number;
    /**
     * Maximal allowed quantity of the ingredient in the product.
     */
    maxQty: number;
    /**
     * Amount type for the ingredient. Indicates how the ingredient is added when increasing quantity of it in the product. Most likely deprecated, always 0 in the system.   Values:   SINGLE_ONE = 0,   NO_LIGHT_NORMAL_HEAVY = 1 (possibly deprecated)
     */
    amountType: number;
    /**
     * Initial amount of the ingredient. Most likely deprecated, always 0 in the system. There was enum (BKIngredientAmountEnum) connected to it with values:    ON = 0,    LIGHT = 1,    NORMAL = 2,    HEAVY = 3
     */
    initialAmount: number;
    /**
     * System name for the product ingredient.
     */
    sysName: string;
}

