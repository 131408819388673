export interface TaskInfos {
	version: string;
	osname: string;
	osarch: string;
	osversion: string;
	tasks: { [taskName: string]: TaskInfo };
}

export interface TaskInfo {
	lastStartTime: number;
	lastEndTime: number;
	startCount: number;
	stats?: TaskStats;
	vars: { [key: string]: string };
}

export interface TaskStats {
	messagesFromAppCounter?: number;
	messagesToAppCounter?: number;
	functionCallCounter?: number;
}
