import { AfterViewInit, Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';

@Component({
	selector: 'data-table',
	templateUrl: './data-table.component.html',
	styleUrls: ['./data-table.component.scss'],
})
export class DataTableComponent implements AfterViewInit {
	@ViewChild('secondLabel') secondlabel: ElementRef<HTMLElement>;
	@ViewChild('cell') cell: ElementRef<HTMLElement>;

	@Input() data: Record<
		keyof typeof this.labels,
		{ depart_time: number; preparation_time: number; start: number; end: number; count: number }
	>;

	labels = {
		morning: 'Matinée',
		launch: 'Déjeuner',
		afternoon: 'Après-midi',
		dinner: 'Dîner',
		evening: 'Soir',
		night: 'Nuit',
		peak_night: 'Nuit de pointe',
	};

	ngAfterViewInit(): void {
		this.positionLabel();
	}

	@HostListener('window:resize', ['$event'])
	onResize() {
		this.positionLabel();
	}

	positionLabel() {
		const referenceRect = this.cell.nativeElement.getBoundingClientRect();
		const targetStyle = this.secondlabel.nativeElement.style;
		targetStyle.position = 'absolute';
		targetStyle.marginLeft = '-6ch';
		targetStyle.left = `${referenceRect.left}px`;
		targetStyle.height = `${referenceRect.height}px`;
	}

	formatHoraire(value) {
		let _start = value.start.toString().length === 1 ? '0' + value.start.toString() : value.start.toString();
		let _end = value.end.toString().length === 1 ? '0' + value.end.toString() : value.end.toString();
		return `${_start}h à ${_end}h`;
	}

	originalOrder = (a, b): number => {
		return 0;
	};

	total(key?: string) {
		let _total = 0;
		if (key) {
			Object.values(this.data).forEach((e) => {
				_total += parseInt(e[key]);
			});
		} else {
			Object.values(this.data).forEach((e) => {
				_total += Number(e.depart_time) + Number(e.preparation_time);
			});
		}
		return _total;
	}

	obj(v: number, s: string) {
		if (!v) return;
		if (s === 'total') {
			return v > 120;
		} else {
			return v > 60;
		}
	}
}
