import { computed, inject, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { OrderEventsUtils } from '@bk/fullstack-common';
import {
	BKCommonCopyUtilities,
	BKDiscountInOrderDataImpl,
	BKKingdomInOrderEventKey,
	BKOrderEventArgumentKeys,
	BKOrderEventType,
	IBKItemInOrderBase,
	IBKKingdomRebootCoupon,
	IBKMenuBase,
	IBKProductBase,
	IBKSubItemInOrderBaseWithFreeItems,
} from '@bk/jscommondatas';
import { mapDeliveryModeToSalesChannelOption, MenuSize, ProductPriceType, SalesChannelOption } from '@bk/price-management-common';
import { LanguageService, TranslateService } from '@libs/shared/modules/i18n';
import { ConfigurationFacade } from '@libs/shared/store/configuration';
import { OrderFacade } from '@libs/shared/store/order';
import { Shar3dUtils } from '@shar3d/shar3d-utils';
import { clone } from 'ramda';
import { AVAILABLE_JSON, IBKBigData, MENU_SIZE, ProductFamilies, UPDATE_TYPE } from '../models';
import { getMenuStepProducts, isMenuStepItemPriceValid, menuToMenuInOrder, productToProductInOrder } from '../utils';
import { FidelityAbstractService } from './fidelity';
import { PriceService } from './price-service';

export const ASK_MENU_SIZE_STEP_INDEX = -1;

export type MenuServiceBreadCrumbs = {
	text: string;
	modified: boolean;
};

export type MenuFidelityService = {
	originalCoupon?: IBKKingdomRebootCoupon;
	useCouponAfterMenu?: IBKKingdomRebootCoupon;
};

@Injectable({ providedIn: 'root' })
export class MenuTunnelService {
	private bigDataSig: Signal<IBKBigData>;
	private availabilitySig: Signal<AVAILABLE_JSON>;
	private isMenuTunnelActiveSig: WritableSignal<boolean> = signal(false);
	public tempMenuInOrder: IBKItemInOrderBase;
	private menu: IBKMenuBase;
	private editedMenuInOrder: IBKItemInOrderBase;
	private productsInSteps: Record<MENU_SIZE, IBKProductBase[]>[] = [];
	private currentStepSig: WritableSignal<number> = signal(undefined);
	private salesChannelSig: Signal<SalesChannelOption>;
	private currentBreadcrumbs: MenuServiceBreadCrumbs[] = [];
	private deliveryModeSig: Signal<number>;
	private _originalCoupon: IBKKingdomRebootCoupon;
	private _useCouponAfterMenu: IBKKingdomRebootCoupon;
	private readonly fidelityService = inject(FidelityAbstractService);

	private readonly configurationFacade: ConfigurationFacade = inject(ConfigurationFacade);
	private readonly languageService: LanguageService = inject(LanguageService);
	private readonly translateService: TranslateService = inject(TranslateService);
	private readonly orderFacade: OrderFacade = inject(OrderFacade);
	private readonly priceService: PriceService = inject(PriceService);

	constructor() {
		this.bigDataSig = toSignal(this.configurationFacade.getBigData());
		this.availabilitySig = toSignal(this.configurationFacade.getAvailability());
		this.deliveryModeSig = toSignal(this.orderFacade.getOrderDeliveryMode());
		this.salesChannelSig = computed(() => mapDeliveryModeToSalesChannelOption(this.deliveryModeSig()));
	}

	set currentStep(step: number) {
		if (this.menu && typeof step === 'number') {
			this.orderFacade.addOrderEvents([
				OrderEventsUtils.createEvent(BKOrderEventType.MENU_VALID_STEP, {
					[BKOrderEventArgumentKeys.STEP_INDEX]: step + 1,
					[BKOrderEventArgumentKeys.MENU]: this.menu.id,
				}),
			]);
		}
		this.currentStepSig.set(step);
		this.setBreadcrumbs();
	}

	get currentStep(): Signal<number> {
		return this.currentStepSig;
	}

	get originalCoupon(): IBKKingdomRebootCoupon {
		return this._originalCoupon;
	}

	get useCouponAfterMenu(): IBKKingdomRebootCoupon {
		return this._useCouponAfterMenu;
	}

	set useCouponAfterMenu(coupon: IBKKingdomRebootCoupon) {
		this._useCouponAfterMenu = coupon;
	}

	startMenuTunnel(menu: IBKMenuBase, quantity: number = 1): void {
		this._originalCoupon = undefined;
		this.useCouponAfterMenu = undefined;
		const tempMenuInOrder = menuToMenuInOrder(menu, quantity);
		this.initMenuTunel(menu, tempMenuInOrder);
	}

	startMenuTunnelFromFidelity(menu: IBKMenuBase, coupons: MenuFidelityService, quantity: number = 1): void {
		this._originalCoupon = coupons.originalCoupon;
		this.useCouponAfterMenu = coupons.useCouponAfterMenu;
		const tempMenuInOrder = menuToMenuInOrder(menu, quantity);
		if (this._originalCoupon) {
			const discount = this.bigDataSig().discounts[this._originalCoupon.idReboot];

			const discountInOrder: BKDiscountInOrderDataImpl = new BKDiscountInOrderDataImpl(null);
			BKCommonCopyUtilities.copyIBKDiscountData(discountInOrder, discount);
			discountInOrder.uuid = Shar3dUtils.generateUUID();
			discountInOrder.appliedValue = {
				ht: 0,
				tva: 0,
				ttc: 0,
				pc: 0,
			};
			tempMenuInOrder.itemAppliedDiscount = [discountInOrder];
		}
		this.initMenuTunel(menu, tempMenuInOrder);
	}

	startMenuTuneEdit(editedMenuInOrder: IBKItemInOrderBase) {
		this._originalCoupon = undefined;
		this.useCouponAfterMenu = undefined;
		this.editedMenuInOrder = editedMenuInOrder;
		const menu = this.bigDataSig().menus[editedMenuInOrder.id];
		const menuInOrder = clone(editedMenuInOrder);
		this.initMenuTunel(menu, menuInOrder);
	}

	private initMenuTunel(menu: IBKMenuBase, tempMenuInOrder: IBKItemInOrderBase) {
		const orderSalesChannel: SalesChannelOption = mapDeliveryModeToSalesChannelOption(this.deliveryModeSig());
		this.orderFacade.addOrderEvents([
			OrderEventsUtils.createEvent(BKOrderEventType.MENU_TUNNEL_START, {
				[BKOrderEventArgumentKeys.MENU]: menu.id,
			}),
		]);
		const productFamilies = new ProductFamilies(this.bigDataSig().productFamilies);
		productFamilies.addProductsToFamily(this.bigDataSig().products);
		menu._steps.forEach((step) => {
			const menuStepProducts = getMenuStepProducts(step, menu, this.bigDataSig(), this.availabilitySig());
			const validMenuStepProducts: Record<MENU_SIZE, IBKProductBase[]> = {
				[MENU_SIZE.MEDIUM]: menuStepProducts.MENU_SIZE_MEDIUM.filter((item) => isMenuStepItemPriceValid(item, orderSalesChannel)),
				[MENU_SIZE.LARGE]: menuStepProducts.MENU_SIZE_LARGE.filter((item) => isMenuStepItemPriceValid(item, orderSalesChannel)),
			};
			this.productsInSteps.push(validMenuStepProducts);
		});
		this.menu = menu;
		this.tempMenuInOrder = tempMenuInOrder;
		this.isMenuTunnelActiveSig.set(true);
	}

	getMenuName(): string {
		return this.menu._name[this.languageService.currentLangCodeShort];
	}

	finishMenuTunnel(): void {
		if (this.isMenuEdit) {
			this.orderFacade.updateProductInOrder({
				lineUuid: this.editedMenuInOrder.lineuuid,
				data: this.tempMenuInOrder,
			});
		} else {
			if (this._originalCoupon) {
				this.orderFacade.updateFidelityRewards(this._originalCoupon, UPDATE_TYPE.ADD);
				const discount = this.tempMenuInOrder.itemAppliedDiscount[0];
				this.orderFacade.addOrderEvents([
					{
						timestamp: Shar3dUtils.now(),
						eventType: BKOrderEventType.KINGDOM_DISCOUNT_IN_ORDER,
						arg: Shar3dUtils.object2URLEncoded({
							[BKKingdomInOrderEventKey.DISCOUNT_UUID]: discount.uuid,
							[BKKingdomInOrderEventKey.DISCOUNT_ID]: discount.id,
							[BKKingdomInOrderEventKey.CLIENT_ID]: this.fidelityService.loggedUser.id,
							[BKKingdomInOrderEventKey.PRIVILEGE_ID]: this._originalCoupon.idEcomm,
							[BKKingdomInOrderEventKey.POINTS_USED]: this._originalCoupon.points,
						}),
					},
				]);
			}
			this.orderFacade.addMenu(this.tempMenuInOrder);
		}

		this.closeMenuTunnel(true);
	}

	selectProduct(productInOrder: IBKSubItemInOrderBaseWithFreeItems, step: number): void {
		this.tempMenuInOrder.selection[step] = productInOrder;
		this.setBreadcrumbs();
	}

	getStepName(step: number): string {
		if (step === ASK_MENU_SIZE_STEP_INDEX) {
			return this.translateService.instant('ASK_MENU_SIZE_TITLE');
		}
		const currentLang = this.languageService.currentLangCodeShort;
		return this.menu._steps[step]._name[currentLang];
	}

	getMenuExtraPrice(size: MENU_SIZE): number {
		if (size === MENU_SIZE.LARGE) {
			const menuM: number = this.priceService.getMenuPrice(this.menu, MenuSize.M) || 0;
			const menuL: number = this.priceService.getMenuPrice(this.menu, MenuSize.L) || 0;
			return menuL - menuM;
		}
		return 0;
	}

	closeMenuTunnel(finished: boolean = false): void {
		// make sure we are logging it only if we are actually manipulating menu
		if (this.menu) {
			const eventType = finished ? BKOrderEventType.MENU_VALID_TUNNEL : BKOrderEventType.MENU_CANCEL_TUNNEL;
			this.orderFacade.addOrderEvents([
				OrderEventsUtils.createEvent(eventType, {
					[BKOrderEventArgumentKeys.MENU]: this.menu.id,
				}),
			]);
		}

		this.currentStep = undefined;
		this.tempMenuInOrder = undefined;
		this.productsInSteps = [];
		this.editedMenuInOrder = undefined;
		this.currentBreadcrumbs = [];
		this.isMenuTunnelActiveSig.set(false);
	}

	get menuSizes(): MENU_SIZE[] {
		const menuSizes = [MENU_SIZE.MEDIUM];
		if (this.menu._existsInL) {
			menuSizes.push(MENU_SIZE.LARGE);
		}
		return menuSizes;
	}

	get menuStepsLength(): number {
		return this.menu._steps.length;
	}

	get menuTunnelStatus(): Signal<boolean> {
		return this.isMenuTunnelActiveSig;
	}

	get isMenuEdit(): boolean {
		return !!this.editedMenuInOrder;
	}

	get breadcrumbs(): MenuServiceBreadCrumbs[] {
		return this.currentBreadcrumbs;
	}

	setBreadcrumbs(): void {
		if (!this.tempMenuInOrder) {
			return;
		}

		if (this.currentStep() === 0) {
			this.currentBreadcrumbs = [];
		}

		const currentLang = this.languageService.currentLangCodeShort;
		this.currentBreadcrumbs = this.tempMenuInOrder.selection
			.map((product) => {
				const modified = product.recipe.length > 0;
				return { modified, text: product.name[currentLang] };
			})
			.slice(0, this.currentStep());
	}

	getProductsInStep(step: number): IBKProductBase[] {
		const isLarge = this.tempMenuInOrder.large;
		const menuSize = isLarge ? MENU_SIZE.LARGE : MENU_SIZE.MEDIUM;
		return this.productsInSteps[step][menuSize];
	}

	setMenuSize(size: MENU_SIZE): void {
		const large = size === MENU_SIZE.LARGE;
		this.tempMenuInOrder.large = large;
		this.tempMenuInOrder.price = large ? this.menu._priceL : this.menu._priceM;
		this.tempMenuInOrder.aLaCartePrice = large ? this.menu._priceL : this.menu._priceM;
		this.tempMenuInOrder.ref = large ? this.menu._refL : this.menu._refL;
	}

	/**
	 * Primary used by suggestion type PUSH_EXTRA_MENU_PRODUCT
	 */
	pushExtraProductToMenu(product: IBKProductBase): void {
		const productInOrder = productToProductInOrder(product, 1, ProductPriceType.InSuggestion);
		this.tempMenuInOrder.selection.push(productInOrder);
	}

	get tempMenuPrice(): number {
		const size = this.tempMenuInOrder.large ? MenuSize.L : MenuSize.M;
		let price = this.priceService.getMenuPrice(this.menu, size);
		this.tempMenuInOrder.selection.forEach((item) => {
			const product = this.bigDataSig().products[item.id];
			price += this.priceService.getMenuSuplementPrice(product) || 0;
		});
		return price;
	}

	get tempMenuBasePrice(): number {
		const size = this.tempMenuInOrder.large ? MenuSize.L : MenuSize.M;
		return this.priceService.getMenuPrice(this.menu, size);
	}
}
