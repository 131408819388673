/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface LegalDto { 
    /**
     * Official registered entity name.
     */
    name: string;
    /**
     * Extra addition to the entity name to be printed on the ticket (unofficial information about the structure, internal identification of the branch etc.).
     */
    nameExtra?: string;
    /**
     * Official entity identificator *[FR: SIREN (Système d\'identification du répertoire des entreprises), CZ: IČO (identifikační číslo osoby)]*.
     */
    entityId: string;
    /**
     * Official identificator of entity\'s establishment *[FR: SIRET (Système d\'identification du répertoire des établissements), CZ: IČP (identifikační číslo provozovny)]*.
     */
    establishmentId?: string;
    /**
     * Official entity identificator for VAT purposes *[FR: Numéro de TVA, CZ: DIČ (daňové identifikační číslo)]*.
     */
    vatNumber: string;
    /**
     * Official code of the business the entity is engaged in *[FR: NAF Code, CZ: kód živnosti]*.
     */
    businessCode?: string;
    /**
     * Address of an official registered headquarters of the entity (primary address line).
     */
    address: string;
    /**
     * Address of an official registered headquarters of the entity (secondary address line).
     */
    addressExtra?: string;
    /**
     * Address of an official registered headquarters of the entity (city).
     */
    city: string;
    /**
     * Address of an official registered headquarters of the entity (ZIP code).
     */
    zipCode: string;
    /**
     * Address of an official registered headquarters of the entity (country).
     */
    country: string;
}

