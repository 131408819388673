import { Component, Inject } from '@angular/core';
import * as api from '@dki/api-client';
import { getLocale } from '@libs/dash/core';
import jsPDF from 'jspdf';
import { DateTime } from 'luxon';
import { combineLatest, filter, tap } from 'rxjs';
import { SOS_REPORT_FACADE } from '../facade/speed-of-service-facade.injection.token';
import { SOSReportServiceProvider } from '../facade/speed-of-service-facade.provider.interface';

@Component({
	selector: 'dash-speed-of-service',
	templateUrl: './speed-of-service.component.html',
	styleUrls: ['./speed-of-service.component.scss'],
})
export class SpeedOfServiceComponent {
	constructor(@Inject(SOS_REPORT_FACADE) private readonly sosProvider: SOSReportServiceProvider) {}

	myRestaurants$ = this.sosProvider.myRestaurants$;

	selectedRestaurants$ = this.sosProvider.selectedRestaurants$;

	sosData$ = this.sosProvider.sosData$;

	sosDataByRestaurants$ = this.sosProvider.sosDataByRestaurant$;

	initDate = this.sosProvider.range;

	totals = { depart_time: 0, preparation_time: 0, total: 0, count: 0 };

	viewData$ = combineLatest([this.myRestaurants$, this.sosData$, this.sosDataByRestaurants$]).pipe(
		filter((a) => {
			return a[0].length > 0;
		}),
		tap((a) => {
			if (a[1]) {
				this.totals = { depart_time: 0, preparation_time: 0, total: 0, count: 0 };
				Object.values(a[1]).forEach((e) => {
					this.totals.count += e['count'];
					this.totals.depart_time += Number(e['depart_time']);
					this.totals.preparation_time += Number(e['preparation_time']);
				});
				this.totals.total = this.totals.depart_time + this.totals.preparation_time;
			}
		})
	);

	onRestaurantSelection(restaurant: api.Restaurant[]) {
		this.sosProvider.setRestaurant(restaurant);
		this.sosProvider.fetch();
	}

	onDateSelection(date: { from: DateTime; to: DateTime }) {
		this.sosProvider.fetch(date.from, date.to);
	}

	export() {
		const source = document.getElementById('data-table');
		const doc = new jsPDF({ orientation: 'l', putOnlyUsedFonts: true });
		const header = 'SOS';
		doc.html(source, {
			html2canvas: {
				scale: 0.28,
				letterRendering: false,
				ignoreElements: (e) =>
					e.classList.contains('arrow') || e.classList.contains('ignore') || e.classList.contains('mat-mdc-button-touch-target'),
			},
			margin: [30, 5, 5, 5],
			windowWidth: 1000,
			width: 900,
			fontFaces: [
				{
					family: 'Roboto',
					src: [
						{
							url: '/assets/fonts/roboto.ttf',
							format: 'truetype',
						},
					],
				},
			],
			callback: (doc) => {
				doc.setFontSize(16);
				doc.setFont(undefined, 'bold');
				doc.text(header, 20, 15);
				const from = this.sosProvider.range.from.setLocale(getLocale()).toFormat('yyyy LLL dd'),
					to = this.sosProvider.range.to.setLocale(getLocale()).toFormat('yyyy LLL dd');
				const date = from === to ? from : `${from} - ${to}`;
				doc.setFontSize(12);
				doc.setFont(undefined, 'light');
				// doc.text(`${footer}`, 20, 200, { align: 'left' });
				doc.text(`${date}`, 20, 20);
				// doc.text(`${source_type}`, 285, 20, { align: 'right' });
				doc.save('Sos Report ' + this.sosProvider.range.from.toISODate() + '-' + this.sosProvider.range.to.toISODate());
			},
		});
	}
}
