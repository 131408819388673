/**
 * DKI-BE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OpeningTimes } from './opening-times';
import { RestaurantContactInfo } from './restaurant-contact-info';
import { RestaurantFeature } from './restaurant-feature';
import { RestaurantFiscalInfo } from './restaurant-fiscal-info';
import { RestaurantLegalInfo } from './restaurant-legal-info';
import { RestaurantRemoteIdentificationsInfo } from './restaurant-remote-identifications-info';
import { RestaurantSalesChannels } from './restaurant-sales-channels';
import { RestaurantState } from './restaurant-state';
import { TagInfo } from './tag-info';
import { Zelty } from './zelty';


/**
 * Restaurant information from MySQL.
 */
export interface RestaurantInfo {
	opening_times?: OpeningTimes;
	id: string;
	name: string;
	address: string;
	city: string;
	country: string;
	remote_identifications: RestaurantRemoteIdentificationsInfo;
	state: RestaurantState;
	sales_channels: RestaurantSalesChannels;
	features: Array<RestaurantFeature>;
	vat_group: number;
	tags: Array<TagInfo>;
	dtt_group_primary: number;
	dtt_group_secondary: number;
	contact: RestaurantContactInfo;
	publication: boolean;
	fiscal: RestaurantFiscalInfo;
	legal?: RestaurantLegalInfo;
	platform_id: number;
	created_at: string;
	modified_at: string;
	latitude?: number;
	longitude?: number;
	zelty?: Zelty;
}

