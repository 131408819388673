export * from './allergens-api-client.service';
import { AllergensApiClient } from './allergens-api-client.service';
export * from './auth-api-client.service';
import { AuthApiClient } from './auth-api-client.service';
export * from './configuration-api-client.service';
import { ConfigurationApiClient } from './configuration-api-client.service';
export * from './gap-api-client.service';
import { GAPApiClient } from './gap-api-client.service';
export * from './global3-api-client.service';
import { Global3ApiClient } from './global3-api-client.service';
export * from './medias-api-client.service';
import { MediasApiClient } from './medias-api-client.service';
export * from './monitoring-api-client.service';
import { MonitoringApiClient } from './monitoring-api-client.service';
export * from './my-api-client.service';
import { MyApiClient } from './my-api-client.service';
export * from './owners-api-client.service';
import { OwnersApiClient } from './owners-api-client.service';
export * from './payment-methods-api-client.service';
import { PaymentMethodsApiClient } from './payment-methods-api-client.service';
export * from './prices-api-client.service';
import { PricesApiClient } from './prices-api-client.service';
export * from './reboot-api-api-client.service';
import { RebootApiApiClient } from './reboot-api-api-client.service';
export * from './restaurants-api-client.service';
import { RestaurantsApiClient } from './restaurants-api-client.service';
export * from './tags-api-client.service';
import { TagsApiClient } from './tags-api-client.service';
export * from './users-api-client.service';
import { UsersApiClient } from './users-api-client.service';
export * from './vat-tariffs-api-client.service';
import { VatTariffsApiClient } from './vat-tariffs-api-client.service';
export * from './weborder-channels-api-client.service';
import { WeborderChannelsApiClient } from './weborder-channels-api-client.service';
export const APIS = [AllergensApiClient, AuthApiClient, ConfigurationApiClient, GAPApiClient, Global3ApiClient, MediasApiClient, MonitoringApiClient, MyApiClient, OwnersApiClient, PaymentMethodsApiClient, PricesApiClient, RebootApiApiClient, RestaurantsApiClient, TagsApiClient, UsersApiClient, VatTariffsApiClient, WeborderChannelsApiClient];
