import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { HotkeyModule } from 'angular2-hotkeys';
import { ErrorMessageComponent } from "./components/error-message/error-message.component";
import { InfoIconComponent } from "./components/info-icon/info-icon.component";
import { PulsatingCircleComponent } from "./components/pulsating-circle/pulsating-circle.component";

import { CtrlClickDirective, DecimalNumberFieldDirective, FieldValidationDirective, UniqueValidatorDirective } from './directives';
import { ApiPermissionGuard } from './guards';
import { HighlightPipe, PermissionsPipe, ViewEditPipe } from './pipes';
import { ManifestService } from './services';

const directives = [
	CtrlClickDirective,
	DecimalNumberFieldDirective,
	FieldValidationDirective,
	UniqueValidatorDirective
];
const pipes = [HighlightPipe, ViewEditPipe, PermissionsPipe];
const services = [ManifestService];
const guards = [ApiPermissionGuard];
const components = [ErrorMessageComponent, PulsatingCircleComponent, InfoIconComponent];

// Only those NG MAT modules which are essential for Frontend-Commong
const angularMaterialModules = [MatSelectModule, MatIconModule, MatCardModule, MatButtonModule, MatTooltipModule, MatInputModule, MatSidenavModule, MatTabsModule];

@NgModule({
	imports: [CommonModule, HotkeyModule.forRoot(), ...angularMaterialModules, RouterModule, FormsModule, ReactiveFormsModule],
	declarations: [...pipes, ...directives, ...components],
	exports: [...pipes, ...directives, ...components],
	providers: [...services, ...guards]
})
export class FrontendCommonModule {
}
