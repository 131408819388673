/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrbpConfigurationBaseDto } from './orbp-configuration-base-dto';
import { RestaurantSettingsDto } from './restaurant-settings-dto';
import { OrbpConfigurationProgramDto } from './orbp-configuration-program-dto';
import { OrbpConfigurationValidityDto } from './orbp-configuration-validity-dto';


export interface OrbpConfigurationDto { 
    /**
     * Actual ORBp configuration.
     */
    configuration?: OrbpConfigurationBaseDto;
    /**
     * Information about the program.
     */
    program?: OrbpConfigurationProgramDto;
    /**
     * Information about the ORBp configuration validity.
     */
    validity: OrbpConfigurationValidityDto;
    /**
     * Optional settings from Local Restaurant affecting behaviour of ORBp. Based on restosetting.json and possibly other config values.
     */
    restaurantSettings?: RestaurantSettingsDto;
}

