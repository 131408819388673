import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { ServiceTimeThresholds } from '@libs/dash/core/entity';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
	selector: 'dk-service-time-tresholds',
	templateUrl: './service-time-tresholds.component.html',
	styleUrls: ['./service-time-tresholds.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceTimeTresholdsComponent implements OnChanges, OnDestroy {
	private _unsubscribeAll = new Subject();
	serviceTimeTresholdsForm = new FormGroup({
		good: new FormControl(0),
		poor: new FormControl(0),
		critical: new FormControl(0),
	});

	@Input() serviceTimeTresholds: ServiceTimeThresholds;

	// TO DO: PROVIDE INTERFACE
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	@Input() texts: { [key: string]: any };

	@Output() serviceTimeTresholdsChange = new EventEmitter<ServiceTimeThresholds>();

	constructor() {
		this.serviceTimeTresholdsForm.valueChanges
			.pipe(
				map((serviceTimeTresholdsValue) => {
					this.serviceTimeTresholdsChange.emit(serviceTimeTresholdsValue);
				}),
				takeUntil(this._unsubscribeAll)
			)
			.subscribe();
	}

	ngOnChanges(): void {
		if (this.serviceTimeTresholds) {
			Object.keys(this.serviceTimeTresholds).forEach((serviceTimeTresholdsKeys) => {
				if (this.serviceTimeTresholds[serviceTimeTresholdsKeys]) {
					this.serviceTimeTresholdsForm.controls[serviceTimeTresholdsKeys].setValue(this.serviceTimeTresholds[serviceTimeTresholdsKeys]);
				}
			});
		}
	}

	ngOnDestroy(): void {
		this._unsubscribeAll.next(true);
		this._unsubscribeAll.complete();
	}
}
