/**
 * DKI-BE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SosKioskByTime {
	/**
	 * Start of the hour
	 */
	timestamp: string;
	/**
	 * Mean value of sos.ordertime, excluding zero values
	 */
	mean_sos_ordertime: number;
	/**
	 * Mean value of sos.waittime, excluding zero values
	 */
	mean_sos_waittime: number;
	/**
	 * Mean value of sos.linetime, excluding zero values
	 */
	mean_sos_linetime: number;
	/**
	 * Mean value of sos.paytime, excluding zero values
	 */
	mean_sos_paytime: number;
	/**
	 * Mean value of sos.servetime, excluding zero values
	 */
	mean_sos_servetime: number;
	/**
	 * Mean value of sos.parktime, excluding zero values
	 */
	mean_sos_parktime: number;
	/**
	 * Mean value of (sos.orderreadytime - sos.creationtime) - sos.ordertime - sos.linetime - sos.paytime
	 */
	mean_sos_preparationtime: number;
}

