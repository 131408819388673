/**
 * DKI-BE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface RestaurantLegalInfo {
	/**
	 * Internal id (database) of the restaurant\'s owner.
	 */
	owner: number;
	/**
	 * Extra (unofficial) name information to be propagated on fiscal documents.
	 */
	name_extra?: string;
	/**
	 * Legal registered id of premises where business is conducted.
	 */
	establishment_id?: string;
}

