// ABPRod custom implementation of base64 encoding/decoding
// Originaly part of bk-js-lib
// This works fine with special French chars like ô (o in Lancôme)

/**
 *
 *  Base64 encode / decode
 *  http://www.webtoolkit.info/javascript_base64.html
 *
 * From the original description in website:
 *
 * This javascript code is used to encode / decode data using base64 (this encoding is designed to make binary data survive transport through transport layers that are not 8-bit clean). Script is fully compatible with UTF-8 encoding. You can use base64 encoded data as simple encryption mechanism.

If you plan using UTF-8 encoding in your project don’t forget to set the page encoding to UTF-8 (Content-Type meta tag).
 *
 **/
export class Base64 {
	// private property
	private static _keyStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';

	/**
	 * Avoid special chars miss-encoding
	 */
	public static encode(input: string): string {
		let output = '';
		let i = 0;

		input = Base64._utf8_encode(input);
		while (i < input.length) {
			const chr1 = input.charCodeAt(i++);
			const chr2 = input.charCodeAt(i++);
			const chr3 = input.charCodeAt(i++);

			const enc1 = chr1 >> 2;
			const enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
			let enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
			let enc4 = chr3 & 63;

			if (isNaN(chr2)) {
				enc3 = enc4 = 64;
			} else if (isNaN(chr3)) {
				enc4 = 64;
			}

			output = output + this._keyStr.charAt(enc1) + this._keyStr.charAt(enc2) + this._keyStr.charAt(enc3) + this._keyStr.charAt(enc4);
		}
		return output;
	}

	// public method for decoding
	public static decode(input: string): string {
		let output = '';
		let i = 0;

		// eslint-disable-next-line no-useless-escape
		input = input.replace(/[^A-Za-z0-9\+\/\=]/g, '');

		while (i < input.length) {
			const enc1 = this._keyStr.indexOf(input.charAt(i++));
			const enc2 = this._keyStr.indexOf(input.charAt(i++));
			const enc3 = this._keyStr.indexOf(input.charAt(i++));
			const enc4 = this._keyStr.indexOf(input.charAt(i++));

			const chr1 = (enc1 << 2) | (enc2 >> 4);
			const chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
			const chr3 = ((enc3 & 3) << 6) | enc4;

			output = output + String.fromCharCode(chr1);

			if (enc3 != 64) {
				output = output + String.fromCharCode(chr2);
			}
			if (enc4 != 64) {
				output = output + String.fromCharCode(chr3);
			}
		}

		output = Base64._utf8_decode(output);

		return output;
	}

	/**
	 * Checks if the string is a valid Base64 string.
	 */
	public static isValid(input: string): boolean {
		const regExp = /^[a-zA-Z0-9/\r\n+]*={0,2}$/g;
		return regExp.test(input);
	}

	// private method for UTF-8 encoding
	private static _utf8_encode(s: string): string {
		s = s.replace(/\r\n/g, '\n');
		let utftext = '';

		for (let n = 0; n < s.length; n++) {
			const c = s.charCodeAt(n);
			if (c < 128) {
				utftext += String.fromCharCode(c);
			} else if (c > 127 && c < 2048) {
				utftext += String.fromCharCode((c >> 6) | 192);
				utftext += String.fromCharCode((c & 63) | 128);
			} else {
				utftext += String.fromCharCode((c >> 12) | 224);
				utftext += String.fromCharCode(((c >> 6) & 63) | 128);
				utftext += String.fromCharCode((c & 63) | 128);
			}
		}

		return utftext;
	}

	// private method for UTF-8 decoding
	private static _utf8_decode(utftext: string): string {
		let s = '';
		let i = 0;
		while (i < utftext.length) {
			const c = utftext.charCodeAt(i);
			if (c < 128) {
				s += String.fromCharCode(c);
				i++;
			} else if (c > 191 && c < 224) {
				const c2 = utftext.charCodeAt(i + 1);
				s += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
				i += 2;
			} else {
				const c2 = utftext.charCodeAt(i + 1);
				const c3 = utftext.charCodeAt(i + 2);
				s += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
				i += 3;
			}
		}
		return s;
	}
}
