// Based on https://stackoverflow.com/a/2091331/552194
export function getQueryVariable(variableName: string, queryString: string): string | null {
	if (!queryString) {
		return null;
	}

	// normalize string
	if (queryString.startsWith('?')) {
		queryString = queryString.substring(1);
	}

	const vars = queryString.split('&');
	for (let i = 0; i < vars.length; i++) {
		const pair = vars[i].split('=');
		if (decodeURIComponent(pair[0]) == variableName) {
			return decodeURIComponent(pair[1]);
		}
	}

	return null;
}
