export const HTTP = 'http';
export const HTTPS = 'https';
export const MAX_PROTOCOL_NUMBER = 65535;

export const DELAY_50 = 50;
export const DELAY_100 = 100;
export const DELAY_200 = 200;

export const DELAY_250 = 250;
export const DELAY_1000 = 1000;
export const DELAY_2000 = 2000;
export const DELAY_5000 = 5000;

export const PASSWORD_ERRORS = {
	REQUIRED: 'required',
	NOT_SAME: 'notSame',
	DUPLICATE: 'duplicate',
	NOT_STRONG: 'notStrong'
};

export const PASSWORD_TYPES = {
	NEW: 'new',
	CONFIRM: 'confirm',
	CURRENT: 'current'
} as const;

export const FORM_STATUS_CHANGE = {
	INVALID: 'INVALID',
	VALID: 'VALID',
	PENDING: 'PENDING',
	DISABLED: 'DISABLED'
};

export const MIN_PASSWORD_SCORE = 3;

export const HTTP_RETURN_CODES = {
	UNPROCESSABLE_ENTITY: 422,
	BAD_REQUEST: 400
};

// Animation closing timeout in ms
export const CLOSING_TIMEOUT = 250;

// constants needed for auth token usage
export const ACCESS_TOKEN_BEARER = 'bearer';
export const ACCESS_TOKEN_EXPIRE_TIME_DEFAULT = 86400;
// should be same as LOCAL_STORAGE_KEYS.ACCESS_TOKEN in ts/libs/shared/models/enums.ts
export const ACCESS_TOKEN_STORAGE_KEY = 'access_token';
export const ACCESS_TOKEN_QUERY_KEY = 'access_token';

// http header names
export const AUTHORIZATION = 'Authorization';
