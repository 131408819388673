import { DRIVE_CO, DRIVE_PAY, POS_ROLE } from "../models";

export function extractPosRole(str: string): POS_ROLE | undefined {
	str = str.toLowerCase();

	if (str.includes('drive_co') || str.includes('driveco')) {
		return DRIVE_CO;
	}

	if (str.includes('drive_pay') || str.includes('drivepay')) {
		return DRIVE_PAY;
	}

	return undefined;
}
