/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PropertyType } from './property-type';


export interface PaymentMethodMetadataConfiguration { 
    /**
     * Name of the metadata key.
     */
    property_key: string;
    /**
     * Flag indicating if the metadata key must be set.
     */
    required: boolean;
    /**
     * Enumeration indicating what is the type of expected metadata value.
     */
    property_type: PropertyType;
}

