/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type RestaurantStateLocal = 'first-init' | 'open-day' | 'close-day' | 'pre-raz' | 'raz' | 'post-raz';

export const RestaurantStateLocal = {
    FirstInit: 'first-init' as RestaurantStateLocal,
    OpenDay: 'open-day' as RestaurantStateLocal,
    CloseDay: 'close-day' as RestaurantStateLocal,
    PreRaz: 'pre-raz' as RestaurantStateLocal,
    Raz: 'raz' as RestaurantStateLocal,
    PostRaz: 'post-raz' as RestaurantStateLocal
};

