/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ConfigurationGlobalDto } from './configuration-global-dto';


export interface DataUpdateResponseDto { 
    /**
     * Latest downloaded version of configuration-global file.
     */
    configurationGlobal?: ConfigurationGlobalDto;
}

