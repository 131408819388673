/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface RestoConfigRestaurantDto { 
    /**
     * id of the restaurant
     */
    id: number;
    /**
     * Name of the restaurant.
     */
    name: string;
    /**
     * Main restaurant\'s identifier - UUIDv4.
     */
    uuid: string;
    /**
     * Restaurant number of POS
     */
    nbPOS: number;
    /**
     * Restaurant number of DMB
     */
    nbDMB: number;
    /**
     * Restaurant number of KDS
     */
    nbKDS: number;
    /**
     * Restaurant number of KIOSK
     */
    nbKIOSK: number;
    /**
     * Restaurant number of ORBp
     */
    nbORBp: number;
    /**
     * Restaurant number of ORBs
     */
    nbORBs: number;
    /**
     * Restaurant number of Drive
     */
    nbDrive: number;
    /**
     * Global state of the restaurant different from RestoSettingsDto -> restostate.         Values:             UNKNOWN = 0,             CONFIG = 1,             INSTALL = 2,             WHITE = 3,             PRODUCTION = 4
     */
    restoState?: number;
    /**
     * Restaurant FR Number
     */
    number_fr?: string;
    /**
     * Restaurant BK Number
     */
    number_bk?: number;
}

