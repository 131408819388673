import { EnumKeyValuePair } from '../types/enum-key-value-pair';
import { EnumMap } from '../types/enum-map';

export type EnumType = { [key: string]: string | number };

/**
 * Expects that key and value are equal
 */
export function enumToList<T extends {}>(enumObj: T) {
	return Object.keys(enumObj).map((k) => [k as string]);
}

/**
 * To be used when key and value are different
 */
export function getEnumValues(enumObj: EnumType): string[] {
	return Object.keys(enumObj).map((k) => {
		const value = enumObj[k].toString();
		return value;
	});
}

/*
 * Used to obtain number values from enum
 */
export function getEnumNumberValues<T extends number>(enumObj: { [key: string]: string | number }) {
	return Object.keys(enumObj)
		.map((k) => enumObj[k])
		.filter((v) => typeof v === 'number') as T[];
}

export function enumMapToKeyValuePairs(obj: EnumMap): EnumKeyValuePair[] {
	const result: EnumKeyValuePair[] = [];
	Object.keys(obj).forEach((key) => {
		result.push({key, value: obj[key]});
	});
	return result;
}

/**
 * Returns keys from EnumMap where value is true
 */
export function getTruthyEnumMapKeys(obj: EnumMap): string[] {
	const pairs = enumMapToKeyValuePairs(obj);
	const truthy = pairs.filter((p) => p.value === true);
	return truthy.map((x) => x.key);
}

/**
 * Returns keys from EnumMap where value is false
 */
export function getFalsyEnumMapKeys(obj: EnumMap): string[] {
	const pairs = enumMapToKeyValuePairs(obj);
	const truthy = pairs.filter((p) => p.value === false);
	return truthy.map((x) => x.key);
}

// Inspired by https://www.petermorlion.com/iterating-a-typescript-enum/
/**
 * Returns list of keys of given Enum
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function enumKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
	return Object.keys(obj).filter((k) => Number.isNaN(+k)) as K[];
}

export function arrayToEnumMap(keys: string[]): EnumMap {
	const result: EnumMap = keys.reduce((acc, key) => {
		return {...acc, ...{[key]: true}};
	}, {});

	return result;
}

export function arrayToFalsyEnumMap(keys: string[]): EnumMap {
	const result: EnumMap = keys.reduce((acc, key) => {
		return {...acc, ...{[key]: false}};
	}, {});

	return result;
}

export function setAllKeysToFalse(enumMap: EnumMap): EnumMap {
	const keys = Object.keys(enumMap);
	return arrayToFalsyEnumMap(keys);
}

export function setAllKeysToTrue(enumMap: EnumMap): EnumMap {
	const keys = Object.keys(enumMap);
	return arrayToEnumMap(keys);
}

/**
 * Converts string value (or enum) to different enum by comparing values.
 * @param targetEnum
 * @param value
 * @param defaultResult
 * @returns
 */
export function enumFromStringValue<T>(targetEnum: { [s: string]: T }, value?: string, defaultResult?: T): T | undefined {
	if (!value) {
		return undefined;
	}
	return (Object.values(targetEnum) as unknown as string[]).includes(value)
		? value as unknown as T
		: defaultResult ? defaultResult : undefined;
}
