import { Injectable } from '@angular/core';
import { IPaymentInterface } from '@libs/shared/interfaces';

import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IClerkRootState } from '../interfaces';
import { ClerkPaymentLoad } from './actions';
import { getPayments, getPaymentsLoading } from './selectors';

@Injectable()
export class ClerkFacade {
	readonly ngUnsubscribe$: Subject<void> = new Subject<void>();

	constructor(private store: Store<IClerkRootState>) {}

	loadPaymentInfo() {
		this.store.dispatch(ClerkPaymentLoad());
	}

	getPaymentsLoading$(): Observable<boolean> {
		return this.store.pipe(select(getPaymentsLoading), takeUntil(this.ngUnsubscribe$));
	}

	getPayments$(): Observable<IPaymentInterface[]> {
		return this.store.pipe(select(getPayments), takeUntil(this.ngUnsubscribe$));
	}

	unsubscribe(): void {
		this.ngUnsubscribe$.next();
		this.ngUnsubscribe$.complete();
	}
}
