import { IBKCampaignLocalPricesData } from '@bk/jscommondatas';
import { LocalPriceDefinitionSchema } from '@bk/price-management-common';
import { clone } from 'ramda';
import { AvailabilityMap, PriceMap } from '../fullstack-common';
import { AVAILABLE_JSON } from '../remote-configs';

export class AvailabilityStoreModel {
	loading: boolean = true;
	data: AvailabilityModel = new AvailabilityModel();

	constructor(init?: Partial<AvailabilityStoreModel>) {
		Object.assign(this, clone(init));
	}
}

export class AvailabilityModel implements AVAILABLE_JSON {
	timestamp: number;
	availability: AvailabilityMap;
	productActive: AvailabilityMap;
	price: {
		products: PriceMap;
		menus: PriceMap;
		menusL: PriceMap;
		ingredients: PriceMap;
	};
	priceDefinitions: LocalPriceDefinitionSchema;
	campaignAvailability: AvailabilityMap;
	ingredientAvailability: AvailabilityMap;
	ingredientActive: AvailabilityMap;
	kitchenRedirection: any;
	menuExtraPrices: PriceMap;
	suggestionMenuPrices: any;
	daypartPrices: Record<string, IBKCampaignLocalPricesData>;
	campaignFilters: any;
	campaignMediasFilters: any;

	constructor(init?: Partial<AvailabilityModel>) {
		Object.assign(this, clone(init));
	}
}
