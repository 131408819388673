import { RawValidationMessage, ValidationMessageSeverityEnum } from "@merim/utils";
import { getDefaultDssProgramValidation, RawDssProgramValidation } from "../../models";
import { OrbpDetail } from "../../types";
import { getAssignedDevicesInMasters, getForbiddenDevicesForAllSalesChannels, getORBpsMatchingAllDevices } from "../device-assignment";
import { isActiveDependent, isActiveMaster } from "../is-role";
import { SalesChannelsWithDeviceSpecificConfiguration } from "../sales-channels";
import { AvailableDevicesPerSalesChannel } from "./orbp-validator-options";

/**
 * Specific Kiosks (or any other device) can be assigned to specific ORBp, and then only that ORBp would display Orders from those Kiosks.
 * By default ORBp would display Orders from ANY Kiosk.
 * But for user it can be tricky to verify if Orders from all Kiosks are indeed displayed on some ORBp.
 * Otherwise they would not be displayed anywhere, and that would be very bad.
 */
export class DeviceAssignmentValidator {
	validate(allProgramOrbps: OrbpDetail[], availableDevices: AvailableDevicesPerSalesChannel): RawDssProgramValidation {
		const masterOrbps = allProgramOrbps.filter((orbp) => isActiveMaster(orbp));
		const dependentOrbps = allProgramOrbps.filter((orbp) => isActiveDependent(orbp));
		const defaultOrbp = masterOrbps.find(x => x.isDefaultForSalesChannel);

		const validationResult: RawDssProgramValidation = getDefaultDssProgramValidation();

		if (masterOrbps.length === 0) {
			return validationResult;
		}

		// TODO: Add unit tests

		// Check that all available devices for given salesChannel are assigned to some Master ORBp.
		// There should be either:
		// - default ORBp as a catch-all
		// - or ORBp without device-specific configuration for that SalesChannel (eg: Kiosk)
		// - or all (Kiosk) devices are assigned to some ORBp
		SalesChannelsWithDeviceSpecificConfiguration.forEach(salesChannel => {
			// if we dont have device, we should not check specific configuration of such device...
			if(!availableDevices[salesChannel]) {
				return;
			}
			const orbsWithAllDevices = getORBpsMatchingAllDevices(salesChannel, masterOrbps);
			if (orbsWithAllDevices.length) {
				// There is at least 1 ORBp capable of displaying Orders from any Kiosk
				// So the Orders wont be lost
				return;
			}

			const availableDevicesForThisSalesChannel: string[] = availableDevices[salesChannel];
			const assignedDevices: string[] = getAssignedDevicesInMasters(salesChannel, masterOrbps);
			const unassignedDevices = availableDevicesForThisSalesChannel.filter(deviceName => assignedDevices.includes(deviceName) === false);

			const areAllDevicesAssigned = unassignedDevices.length === 0;

			// Some devices are not assigned, and there is no Default ORBp.
			// That means that Orders coming from those orphaned devices would not appear anywhere. User has to be notified.
			if (areAllDevicesAssigned === false && !defaultOrbp) {
				const unassignedDevicesStr = unassignedDevices.join(', ');
				translationKeys.deviceNotAssigned.forEach(key => {
					const validationMessage: RawValidationMessage = {
						severity: ValidationMessageSeverityEnum.WARNING,
						translationKey: key,
						additionalData: {
							unassignedDevices: unassignedDevicesStr
						},
						propertyName: undefined
					};
					validationResult.general.push(validationMessage);
				});
			}
		});

		// Check that all Dependent ORBp are not having "forbidden devices".
		// For example "kiosk 1" is not allowed on Dependent, when his Master only has "kiosk 2" and "kiosk 3" allowed.
		dependentOrbps.forEach(depOrbp => {
			const forbiddenDevices: string[] = getForbiddenDevicesForAllSalesChannels(depOrbp, masterOrbps);
			if (forbiddenDevices.length > 0) {

				const validationMessage: RawValidationMessage = {
					severity: ValidationMessageSeverityEnum.ERROR,
					translationKey: translationKeys.forbiddenDevices,
					additionalData: {
						forbiddenDevices: forbiddenDevices.join(', ')
					},
					propertyName: undefined
				}

				validationResult.orbp[depOrbp.id] = [validationMessage];

				// WORKAROUND (Waiting for better validation layout by Jan Ptacnik)
				// ORBp-specific validation message without propertyName are not displayed.
				// So I push them to general array instead.
				validationResult.general.push(validationMessage);
			}
		})

		return validationResult;
	}
}

const translationKeys = {
	deviceNotAssigned: [
		'THESE-DEVICES-ARE-NOT-ASSIGNED-TO-ANY-ORBP--LONG-MESSAGE1',
		'THESE-DEVICES-ARE-NOT-ASSIGNED-TO-ANY-ORBP--LONG-MESSAGE2',
		'THESE-DEVICES-ARE-NOT-ASSIGNED-TO-ANY-ORBP--LONG-MESSAGE3'
	],
	forbiddenDevices: 'THESE-DEVICES-ARE-NOT-ALLOWED'
  }