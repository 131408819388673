import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@libs/shared/modules/i18n';
import { NavigationTileComponent } from './navigation-tile.component';

const materialModules = [MatCardModule, MatButtonModule, MatMenuModule, MatTooltipModule];
const components = [NavigationTileComponent];
const pipes = [];
const directives = [];

@NgModule({
	imports: [CommonModule, TranslateModule, ...materialModules],
	declarations: [...components, ...pipes, ...directives],
	exports: [...components, ...pipes],
})
export class NavigationTileModule {}
