import { OrbStatus } from "../enums";
import { OrbpRole } from "../enums/orbp-role";
import { OrbpDetail } from "../types/orbp-detail";

export function isMaster(orbp: OrbpDetail): boolean {
	return orbp.role === OrbpRole.Master;
}

export function isActiveMaster(orbp: OrbpDetail): boolean {
	return isMaster(orbp) && orbp.status === OrbStatus.On;
}

export function isDependent(orbp: OrbpDetail): boolean {
	return orbp.role === OrbpRole.Dependent;
}

export function isActiveDependent(orbp: OrbpDetail): boolean {
	return isDependent(orbp) && orbp.status === OrbStatus.On;
}

export function isMirror(orbp: OrbpDetail): boolean {
	return orbp.role === OrbpRole.Mirror;
}
