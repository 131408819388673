import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { fromFetch } from 'rxjs/fetch';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { ManifestFile } from '../types/manifest-file';

@Injectable()
export class ManifestService {
	readManifest(path = './assets/configs/manifest.json'): Observable<ManifestFile> {
		return fromFetch(path).pipe(
			mergeMap((x) => {
				return x.json();
			}),
			catchError((err) => {
				console.error(err);
				console.warn(`manifest.json at path ${path} was malformed or not existing. Using fake manifest instead.`);
				const fakeManifest: ManifestFile = {
					version: 'N/A',
					id: 'N/A',
					buildTimeStamp: 'N/A',
					name: 'N/A'
				};
				return of(fakeManifest);
			}),
			map((x) => {
				return x as ManifestFile;
			})
		);
	}
}
