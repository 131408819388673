import { APPLICATION_NAME, APPLICATION_NAME_PROPERTY } from '../models';
import { ORBp } from '../models/index';

/**
 * Used when signing Ring Messages
 */
export function registerApplicationName(name: APPLICATION_NAME): void {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(<any>globalThis)[APPLICATION_NAME_PROPERTY] = name;
}

export function getApplicationName(): APPLICATION_NAME {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const name = (<any>globalThis)[APPLICATION_NAME_PROPERTY];
	return name;
}

export function isORBp(): boolean {
	const applicationName = getApplicationName();
	return applicationName === ORBp;
}
