import { Injectable } from '@angular/core';
import * as api from '@dki/api-client';
import {
	MyRestaurantsActions,
	MyRestaurantsSelectors,
	RootStoreState,
	TodaysStatisticsActions,
	TodaysStatisticsSelectors,
} from '@libs/dash/store';
import { select, Store } from '@ngrx/store';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { SOSReportServiceProvider } from './speed-of-service-facade.provider.interface';

@Injectable()
export class SOSReportFacade implements SOSReportServiceProvider {
	myRestaurants$ = this._store.pipe(select(MyRestaurantsSelectors.getListOfMyRestaurants));
	selectedRestaurants$ = this._store.pipe(select(MyRestaurantsSelectors.getSelectedRestaurants));
	isLoadingSavedRestaurant$ = this._store.pipe(select(MyRestaurantsSelectors.getIsLoading));
	isLoading$: Observable<boolean>;
	range = { from: DateTime.now().startOf('week'), to: DateTime.now().endOf('week') };

	constructor(private _store: Store<RootStoreState>) {}

	setRestaurant(restaurants: api.Restaurant[]) {
		this._store.dispatch(MyRestaurantsActions.setMultiRestaurants({ mySelectedRestaurants: restaurants }));
	}

	sosData$ = this._store.pipe(select(TodaysStatisticsSelectors.sosKIOSK));
	sosDataByRestaurant$ = this._store.pipe(select(TodaysStatisticsSelectors.sosKIOSKByRestaurant));

	fetch(from?: DateTime<boolean>, to?: DateTime<boolean>) {
		if (from && to) {
			this.range.from = from;
			this.range.to = to;
		}
		this._store.dispatch(
			TodaysStatisticsActions.getKioskSosByTime({
				from: this.range.from,
				to: this.range.to,
			})
		);
		this._store.dispatch(
			TodaysStatisticsActions.getSosByRestaurant({
				aggregation: null,
				weekdays: null,
				from: this.range.from,
				to: this.range.to,
			})
		);
	}
}
