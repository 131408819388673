/**
 * DKI-BE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 */
export type OrderSource = 0 | 1 | 2 | 3 | 4 | 5 | 6 | -1;

export const OrderSource = {
    NUMBER_0: 0 as OrderSource,
    NUMBER_1: 1 as OrderSource,
    NUMBER_2: 2 as OrderSource,
    NUMBER_3: 3 as OrderSource,
    NUMBER_4: 4 as OrderSource,
    NUMBER_5: 5 as OrderSource,
    NUMBER_6: 6 as OrderSource,
    NUMBER_MINUS_1: -1 as OrderSource
};

