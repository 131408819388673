@if (viewData$ | async; as viewData) {
	<div class="header">
		<h2>Temps de service</h2>
		<app-date-picker (dateSelection)="onDateSelection($event)" [initDate]="initDate"></app-date-picker>
	</div>
	<p class="objective-label">Objectifs Service :</p>
	<div class="indicators">
		<indicator-card label="Préparation" [objective]="1" [percent]="totals.depart_time / totals.count / 60"></indicator-card>
		<indicator-card label="Départ" [objective]="1" [percent]="totals.preparation_time / totals.count / 60"></indicator-card>
		<indicator-card label="Total" [objective]="2" [percent]="totals.total / totals.count / 120"></indicator-card>
	</div>
	<div class="restaurant-options">
		<app-restaurant-picker
			[restaurants]="viewData[0]"
			(restaurantSelection)="onRestaurantSelection($event)"
			[initialRestaurant]="selectedRestaurants$ | async"
		></app-restaurant-picker>
		<app-sales-channel-selector></app-sales-channel-selector>
		<app-export-button (click)="export()"></app-export-button>
	</div>
	<app-days-selector></app-days-selector>
	<div id="data-table">
		<data-table [data]="viewData[1]"></data-table>
		<data-table-cumul [data]="viewData[2]" [restaurants]="viewData[0]"></data-table-cumul>
	</div>
} @else {
	<p>Loading..</p>
}
