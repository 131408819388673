import {
	IBKAllergenData,
	IBKAnnotationData,
	IBKCampaignData,
	IBKCouponServiceData,
	IBKDiscountData,
	IBKGameData,
	IBKIngredientData,
	IBKIngredientFamilyData,
	IBKIngredientFilterData,
	IBKItemOptionData,
	IBKMediaData,
	IBKMediaPlaylistData,
	IBKMenuBase,
	IBKMenuStepProductGroupData,
	IBKNutritionNutrientData,
	IBKProductBase,
	IBKProductFamilyData,
	IBKProductGroupData,
	IBKSuggestionData,
} from '@bk/jscommondatas';

export interface IBKBigData {
	productFamilies: { [id: string]: IBKProductFamilyData };
	products: { [id: string]: IBKProductBase };
	allergens: { [id: string]: IBKAllergenData };
	menus: { [id: string]: IBKMenuBase };
	ingredientFamilies: { [id: string]: IBKIngredientFamilyData };
	ingredients: { [id: string]: IBKIngredientData };
	campaigns: { [id: string]: IBKCampaignData };
	medias: { [id: string]: IBKMediaData };
	mediasPlayList: { [id: string]: IBKMediaPlaylistData };
	discounts: { [id: string]: IBKDiscountData };
	options: { [id: string]: IBKItemOptionData };
	annotations: { [id: string]: IBKAnnotationData };
	suggestions: { [id: string]: IBKSuggestionData };
	productGroups: { [id: string]: IBKProductGroupData };
	ingredientFilters?: { [id: string]: IBKIngredientFilterData };
	nutritionNutrients?: { [id: string]: IBKNutritionNutrientData };
	games?: { [id: string]: IBKGameData };
	coupons?: { [id: string]: IBKCouponServiceData };
	menuStepProductGroups?: { [id: string]: IBKMenuStepProductGroupData };
}
