/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FoodCategoryDto } from './food-category-dto';
import { ConfigurationProductFamilyDto } from './configuration-product-family-dto';


export interface ProductsConfigurationDto { 
    /**
     * Corresponds to fullstack-common/src/lib/enums/food-category.ts
     */
    foodCategories: Array<FoodCategoryDto>;
    /**
     * ProductFamilies available for current Brand
     */
    productFamilies: Array<ConfigurationProductFamilyDto>;
}

