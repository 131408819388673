/**
 * DKI-BE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { WeatherOverallStatus } from './weather-overall-status';


export interface WeatherDailyStatus {
	/**
	 * Average daily air temperature at two meters above ground level. [C]
	 */
	air_temp_avg: number;
	/**
	 * Minimum daily air temperature at two meters above ground level. [C]
	 */
	air_temp_min: number;
	/**
	 * Maximum daily air temperature at two meters above ground level. [C]
	 */
	air_temp_max: number;
	/**
	 * Average cloud cover data. Cloud cover refers to the percentage of the sky that is covered by clouds. [%]
	 */
	cloud_cover: number;
	/**
	 * Best estimate of liquid-equivalent precipitation. Matches the precip_acc_period_adjusted value if available, or the precip_acc_period_raw value if the adjusted value is not available. [mm]
	 */
	precip_acc: number;
	/**
	 * Hourly liquid accumulation data. [mm]
	 */
	liquid_precip_acc: number;
	/**
	 * Hourly snow accumulation data. [mm]
	 */
	snow_precip_acc: number;
	/**
	 * Average daily wind speed at ten meters above ground level. [m/s]
	 */
	wind_speed: number;
	/**
	 * Overall Weather Status: clear_sky, few_clouds, scattered_clouds, broken_clouds, overcast_clouds, shower_rain, light_rain, rain, thunderstorm, snow
	 */
	over_all_status: WeatherOverallStatus;
}

