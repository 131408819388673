import { EnumKeyValuePair, EnumMap, enumMapToKeyValuePairs } from '@merim/utils';
import { ConfigurationOfSalesChannelOptionsDependency } from './configuration-of-sales-channel-options-dependency';

export function normalizeSalesChannelOptions(inputConfiguration: EnumMap): EnumMap {
	const result: EnumMap = {...inputConfiguration};
	const allValues: EnumKeyValuePair[] = enumMapToKeyValuePairs(inputConfiguration);

	allValues.forEach((keyValuePair) => {
		const matchingDependency = ConfigurationOfSalesChannelOptionsDependency.find(x => x.optionName === keyValuePair.key);

		if (!matchingDependency) {
			// nothing to do, keep the value as it is
			return;
		}

		// Check if the "master option" is enabled
		const isMasterEnabled: boolean = inputConfiguration[matchingDependency.dependsOn];
		if (isMasterEnabled === false) {
			// Disable the dependent option
			result[keyValuePair.key] = false;
		}
	})

	return result;
}
