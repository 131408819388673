/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BrandConfigurationFeatureConfiguration } from './brand-configuration-feature-configuration';
import { BrandConfigurationRestaurantRemoteIdentificationConfiguration } from './brand-configuration-restaurant-remote-identification-configuration';
import { RestaurantFeature } from './restaurant-feature';


export interface BrandConfigurationRestaurantManagement { 
    /**
     * Toggle for module in GAP.
     */
    enabled?: boolean;
    /**
     * Configuration of allowed weborder channels per restaurant.
     */
    configure_weborder_channels?: boolean;
    /**
     * Configuration of tags per restaurant.
     */
    configure_tags?: boolean;
    /**
     * Configuration of opening times per restaurant.
     */
    configure_opening_times?: boolean;
    /**
     * Deprecated, use \'features_configuration\' instead. Restaurant features active for brand.
     */
    features?: Array<RestaurantFeature>;
    /**
     * Configuration of features and where they may be modified. Designed to completely replace \'features\' property.
     */
    features_configuration?: Array<BrandConfigurationFeatureConfiguration>;
    /**
     * Default latitude to use when selecting restaurant\'s coordinates on map.
     */
    default_latitude?: number;
    /**
     * Default longitude to use when selecting restaurant\'s coordinates on map.
     */
    default_longitude?: number;
    /**
     * Configuration of remote indentificators.
     */
    remote_identifications_configuration?: BrandConfigurationRestaurantRemoteIdentificationConfiguration;
}

