/**
 * DKI-BE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Prediction {
	/**
	 * Prediction itself
	 */
	prediction: number;
	/**
	 * Lower Bound Prediction
	 */
	prediction_lower_bound: number;
	/**
	 * Upprer Bound Prediction
	 */
	prediction_upper_bound: number;
}

