/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PredictionWeatherDto { 
    /**
     * Air temperature at two meters above ground level. Units dependent on the unitcode setting. [°C]
     */
    airTemperature: number;
    /**
     * Minimum daily air temperature at two meters above ground level. [°C] Included in daily prediction only.
     */
    airTemperatureMin?: number;
    /**
     * Maximum daily air temperature at two meters above ground level. [°C]. Included in daily prediction only.
     */
    airTemperatureMax?: number;
    /**
     * Cloud cover data. Cloud cover refers to the percentage of the sky that is covered by clouds. [%]
     */
    cloudCover: number;
    /**
     * Hourly liquid-equivalent precipitation accumulation data. [mm]
     */
    precipitation: number;
    /**
     * Hourly liquid accumulation data. [mm]
     */
    liquidPrecipitation: number;
    /**
     * Hourly snow accumulation data. [mm]
     */
    snowPrecipitation: number;
    /**
     * Wind speed at ten meters above ground level. Units dependent on the unitcode setting. [m/s]
     */
    windSpeed: number;
    /**
     * The overall weather status for the whole hour.
     */
    overallStatus: PredictionWeatherDto.OverallStatusEnum;
}
export namespace PredictionWeatherDto {
    export type OverallStatusEnum = 'ClearSky' | 'FewClouds' | 'ScatteredClouds' | 'BrokenClouds' | 'OvercastClouds' | 'ShowerRain' | 'LightRain' | 'Rain' | 'Thunderstorm' | 'Snow';
    export const OverallStatusEnum = {
        ClearSky: 'ClearSky' as OverallStatusEnum,
        FewClouds: 'FewClouds' as OverallStatusEnum,
        ScatteredClouds: 'ScatteredClouds' as OverallStatusEnum,
        BrokenClouds: 'BrokenClouds' as OverallStatusEnum,
        OvercastClouds: 'OvercastClouds' as OverallStatusEnum,
        ShowerRain: 'ShowerRain' as OverallStatusEnum,
        LightRain: 'LightRain' as OverallStatusEnum,
        Rain: 'Rain' as OverallStatusEnum,
        Thunderstorm: 'Thunderstorm' as OverallStatusEnum,
        Snow: 'Snow' as OverallStatusEnum
    };
}


