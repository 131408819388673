/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PredictionDetailDto } from './prediction-detail-dto';


export interface PredictionDetailEntity { 
    /**
     * Start of the interval for given prediction.
     */
    timestamp: string;
    /**
     * Date of the prediction.
     */
    date: string;
    /**
     * Prediction detail.
     */
    detail: PredictionDetailDto;
}

