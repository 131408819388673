import { Injectable } from '@angular/core';
import { FoodCategoryLegacy } from "@bk/fullstack-common";
import {
	AppConfigGlobalFoodCategory,
	AppConfigGlobalProductFamily,
	AppConfigGlobalProductList,
	AppConfigurationGlobal
} from "@libs/shared/models";
import {
	ConfigurationGlobalDto,
	ConfigurationProductFamilyDto,
	ConfigurationsApiClient,
	FoodCategoryDto,
	ProductListDto
} from '@rap/api-client';
import { map, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class GlobalConfigurationService {
	url: string;

	constructor(private readonly configurationApiService: ConfigurationsApiClient) {}

	getGlobalConfiguration(): Observable<AppConfigurationGlobal> {
		return this.configurationApiService.getGlobalConfigurationJson().pipe(
			map((configGlobalDto) => {
				return this.mapAppConfigurationGlobalFromApiDtos(configGlobalDto);
			})
		);
	}

	private mapAppConfigurationGlobalFromApiDtos(configGlobalDto: ConfigurationGlobalDto): AppConfigurationGlobal {
		const appConfigGlobal: AppConfigurationGlobal = {
			brand: configGlobalDto.brand,
			environment: configGlobalDto.environment,
			restaurant: configGlobalDto.restaurant,
			legal: configGlobalDto?.legal,
			fiscal: configGlobalDto.fiscal,
			fidelity: configGlobalDto?.fidelity,
			features: {
				configurations: configGlobalDto.features.configurations,
				isCombinedRestaurantPrice: configGlobalDto.features.mergeEatInAndTakeAwaySalesChannelOptions,
			},
			languages: configGlobalDto.languages,
			orders: configGlobalDto.orders,
			weborders: configGlobalDto.weborders,
			productList: this.mapProductListFromApiDtos(configGlobalDto.productList),
		};

		return appConfigGlobal;
	}

	/** ProductList mapping section **/

	private mapProductListFromApiDtos(productList: ProductListDto ): AppConfigGlobalProductList {
		const foodCategories: Array<AppConfigGlobalFoodCategory> = this.mapFoodCategoriesFromApiDtos(productList.foodCategories);
		const productFamilies: Array<AppConfigGlobalProductFamily> = this.mapProductFamiliesFromApiDtos(productList.productFamilies);
		return {
			foodCategories,
			productFamilies
		}
	}

	private mapFoodCategoriesFromApiDtos(foodCategories: Array<FoodCategoryDto> ): Array<AppConfigGlobalFoodCategory> {
		return foodCategories.map((foodCategory) => {
			return {
				name: foodCategory.name,
				productFamilyIds: foodCategory.productFamilyIds,
				color: foodCategory.color,
				icon: this.getFoodCategoryIcon(foodCategory),
			};
		})
	}

	private getFoodCategoryIcon(foodCategoryDto: FoodCategoryDto): FoodCategoryDto.IconEnum {

		if (foodCategoryDto.icon !== FoodCategoryDto.IconEnum.Unknown) {
			return foodCategoryDto.icon;
		} else if (!FoodCategoryLegacy?.[foodCategoryDto.name]) {
			return foodCategoryDto.icon;
		}
		// legacy foodCategory mapping to icons
		switch (FoodCategoryLegacy[foodCategoryDto.name]) {
			case FoodCategoryLegacy.HOT_DRINKS:
				return FoodCategoryDto.IconEnum.HotDrinks;
			case FoodCategoryLegacy.COLD_DRINKS:
			case FoodCategoryLegacy.ALCOHOLS:
				return FoodCategoryDto.IconEnum.ColdDrinks;
			case FoodCategoryLegacy.TAP_DRINKS:
				return FoodCategoryDto.IconEnum.RefrigeratedDrinks;
			case FoodCategoryLegacy.BURGERS:
				return FoodCategoryDto.IconEnum.Burgers;
			case FoodCategoryLegacy.FRIED_PRODUCTS:
				return FoodCategoryDto.IconEnum.FriedProducts;
			case FoodCategoryLegacy.FRIES:
				return FoodCategoryDto.IconEnum.Fries;
			case FoodCategoryLegacy.HOT_DISH:
				return FoodCategoryDto.IconEnum.HotDishBowl;
			case FoodCategoryLegacy.HOT_SALADS:
				return FoodCategoryDto.IconEnum.HotSaladBowl;
			case FoodCategoryLegacy.COLD_SALADS:
			case FoodCategoryLegacy.SALADS:
				return FoodCategoryDto.IconEnum.ColdSaladBowl;
			case FoodCategoryLegacy.KITCHEN_COUNTER:
				return FoodCategoryDto.IconEnum.HotDesserts;
			case FoodCategoryLegacy.FRIDGE_DESSERTS:
				return FoodCategoryDto.IconEnum.ColdDesserts;
			case FoodCategoryLegacy.GOURMET_BAG_DESSERTS:
				return FoodCategoryDto.IconEnum.DessertInGlass;
			case FoodCategoryLegacy.ICE_CREAMS:
				return FoodCategoryDto.IconEnum.IceCreams;
			case FoodCategoryLegacy.PASTRY_KITCHEN:
			case FoodCategoryLegacy.DESSERTS:
				return FoodCategoryDto.IconEnum.Donut;
			case FoodCategoryLegacy.FRUITS:
				return FoodCategoryDto.IconEnum.Fruits;
			case FoodCategoryLegacy.BREAKFAST:
				return FoodCategoryDto.IconEnum.Pastries;
			case FoodCategoryLegacy.GOURMET_HOT_DRINKS:
				return FoodCategoryDto.IconEnum.HotCake;
			case FoodCategoryLegacy.GOURMET_COLD_DRINKS:
				return FoodCategoryDto.IconEnum.MilkShake;
			case FoodCategoryLegacy.SALAD_DRESSINGS:
				return FoodCategoryDto.IconEnum.Dressings;
			case FoodCategoryLegacy.SAUCES:
			case FoodCategoryLegacy.SNACK_SAUCES:
				return FoodCategoryDto.IconEnum.Sauces;
			case FoodCategoryLegacy.TOYS:
				return FoodCategoryDto.IconEnum.Toys;
			case FoodCategoryLegacy.OTHER_STUFF:
				return FoodCategoryDto.IconEnum.Tickets;
			default:
				return FoodCategoryDto.IconEnum.Unknown;
		}
	}

	private mapProductFamiliesFromApiDtos(productFamilies: Array<ConfigurationProductFamilyDto> ): Array<AppConfigGlobalProductFamily> {
		return productFamilies.map((productFamily) => {
			return {
				id: productFamily.id,
				name: productFamily.name,
			};
		})
	}

	/** End of product mapping section **/

}
