import { SalesChannelOption } from '@bk/price-management-common';
import { MenuSize, ProductPriceType } from '../enums';

/**
 * New PriceDefinition defined in GAP as loaded from bigDatas.json
 */
export type GlobalPriceDefinition = {
	salesChannelOption: SalesChannelOption;
	isDisabled: boolean;
	price: number;
	/**
	 * Generic string identifier. Uses specific values for different categories.
	 * For example:
	 * - Solo and MenuSuggestion for Products
	 * - Large or Small for Menus etc
	 */
	// The type is actually { MenuSize, ProductPriceType } from '@bk/price-management-common' but I can't use it because of circular dependency
	rowIdentifier: MenuSize | ProductPriceType;
};
