export enum ADJUSTEMENT_TYPES {
	ADD= 'add',
	REMOVE = 'remove',
	SET_BIN_COUNT = 'correct',
	ORDERED = 'ordered',
	ORDER_CANCELLED = 'cancelledOrder'
}



export enum PmsCurrentStatus {
	PRODUCTION_FINISHED = 'productionFinished',
	PRODUCTION_CORRECTION = 'productionCorrection',
	PRODUCTION_UNATTENDED = 'productionUnattended'
}


export interface IPMSDisplayedProducts {
	id: string;
	ordered: number;
	predictions: IProductPredictions;
	production: number;
	currentStatus: IPMSDisplayedCurrentStatus;
	lastCommand?: IPMSDisplayedProductsWithMetadata<ADJUSTEMENT_TYPES>;
	timestamps: Record<PRODUCT_TIMESTAMPS, number>;
}

export interface IPMSDisplayedProductsWithMetadata<T> {
	id: number;
	quantity: number;
	action: T;
	metadata: IPreproductionMessageMetadata | null;
	synchronizeOnChange?: boolean;
}

export interface IPreproductionMessageMetadata {
	orderId?: string,
	shortName?: string,
	sysName?: string,
	initiatingDeviceId?: number
}
export interface IPMSDisplayedCurrentStatus {
	status: string;
	amount: number;
	initiatingDeviceId?: number;
}

export enum PMS_OPERATION_MODE {
	ASSISTED = 'assisted',
	FREE = 'free'
}

export enum PMS_DATA_REQUEST_TYPE {
	BOOT = 'boot',
	MANUAL_REQUEST = 'manual_request',
	DEVICE_BACK_ONLINE = 'device_back_online'
}

export enum PRODUCT_TIMESTAMPS {
	LAST_ORDERED = 'lastOrdered',
	LAST_PRODUCED = 'lastProduced',
	LAST_UPDATE = 'lastUpdate'
}

export interface IPredictions {
	current: number;
	next: number;
}

export interface IProductPredictions extends IPredictions {
	reachedPrediction: boolean;
	producedInThisInterval: number;
}
