/**
 * DKI-BE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 */
export type Channel =
	'DRIVE_THROUGH'
	| 'KIOSK_EAT_IN'
	| 'KIOSK_TAKE_OUT'
	| 'DELIVERY'
	| 'EAT_IN'
	| 'TAKE_OUT'
	| 'SALLE'
	| 'PARKING'
	| 'PICKUP'
	| 'EXTERIEUR';

export const Channel = {
	DriveThrough: 'DRIVE_THROUGH' as Channel,
	KioskEatIn: 'KIOSK_EAT_IN' as Channel,
	KioskTakeOut: 'KIOSK_TAKE_OUT' as Channel,
	Delivery: 'DELIVERY' as Channel,
	EatIn: 'EAT_IN' as Channel,
	TakeOut: 'TAKE_OUT' as Channel,
	Salle: 'SALLE' as Channel,
	Parking: 'PARKING' as Channel,
	Pickup: 'PICKUP' as Channel,
	Exterieur: 'EXTERIEUR' as Channel
};

