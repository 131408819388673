/**
 * DKI-BE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Annotation } from './annotation';
import { Channel } from './channel';
import { Discount } from './discount';
import { Event } from './event';
import { FreeItem } from './free-item';
import { FreeMenuItem } from './free-menu-item';
import { Ingredient } from './ingredient';
import { Item } from './item';
import { Menu } from './menu';
import { MenuItem } from './menu-item';
import { Payment } from './payment';
import { Person } from './person';
import { Role } from './role';
import { Sos } from './sos';
import { Source } from './source';
import { State } from './state';
import { VatRate } from './vat-rate';


export interface Transaction {
	id: string;
	restaurant_id: string;
	source: Source;
	state: State;
	role: Role;
	delivery: number;
	businessday: string;
	timestamp: string;
	modification: string;
	created_at: string;
	state_changed_at: string;
	channel: Channel;
	is_valid?: boolean;
	menus: Array<Menu>;
	menu_items: Array<MenuItem>;
	solo_items: Array<Item>;
	free_items: Array<FreeItem>;
	free_menu_items: Array<FreeMenuItem>;
	ingredients?: Array<Ingredient>;
	events: Array<Event>;
	annotations: Array<Annotation>;
	payments: Array<Payment>;
	sos?: Sos;
	discount: number;
	total: number;
	total_excluding_vat: number;
	total_vat: number;
	discounts: Array<Discount>;
	vat_rates: Array<VatRate>;
	employee?: Person;
	ticket: number;
	user_id: number;
	manager_id: number;
	order_ip: string;
	payment_ip: string;
	voided_by_user_id?: number;
	orb: number;
	localisation?: Channel;
	version?: number;
}

