/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MonitoringRestaurantDetailWebsockets } from './monitoring-restaurant-detail-websockets';
import { RestaurantStateLocal } from './restaurant-state-local';
import { WeborderChannel } from './weborder-channel';
import { MonitoringRestaurantDetailFeatures } from './monitoring-restaurant-detail-features';


export interface MonitoringRestaurantDetailInput { 
    /**
     * Current state of the restaurant.
     */
    localState: RestaurantStateLocal;
    /**
     * Features locally modified in restaurant. If some feature is completely missing, it was not changed from global state.
     */
    features: MonitoringRestaurantDetailFeatures;
    /**
     * State of the websockets connecting BO-LOCAL-MANAGER and BO-GLOBAL-MANAGER.
     */
    websockets: MonitoringRestaurantDetailWebsockets;
    /**
     * Weborder channels that were disabled in the restaurant.
     */
    disabledWeborderChannels: Array<WeborderChannel>;
    /**
     * Local IP address of a device currently used as a WebPOS to fiscalize weborders.
     */
    webposIp: string;
}

