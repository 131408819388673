import { clone } from 'ramda';
import { IBKBigData } from '../remote-configs';

export class BigDataStoreModel {
	loading: boolean = true;
	data: IBKBigData | undefined = undefined;

	constructor(init?: Partial<BigDataStoreModel>) {
		Object.assign(this, clone(init));
	}
}
