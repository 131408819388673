/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MonitoringDeviceInput } from './monitoring-device-input';
import { MonitoringRestaurantDetailInput } from './monitoring-restaurant-detail-input';


export interface MonitoringRestaurantInput { 
    /**
     * UUID of the restaurant.
     */
    uuid: string;
    /**
     * List of devices in the restaurant.
     */
    peers: Array<MonitoringDeviceInput>;
    restaurant?: MonitoringRestaurantDetailInput;
}

