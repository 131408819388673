/**
 * DKI-BE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OpeningTimes } from './opening-times';
import { RestaurantContactInfo } from './restaurant-contact-info';
import { RestaurantFeature } from './restaurant-feature';
import { RestaurantFiscalInfo } from './restaurant-fiscal-info';
import { RestaurantLegalInfoUpdateInput } from './restaurant-legal-info-update-input';
import { RestaurantRemoteIdentificationsUpdateInput } from './restaurant-remote-identifications-update-input';
import { RestaurantSalesChannelsInput } from './restaurant-sales-channels-input';
import { RestaurantState } from './restaurant-state';
import { ZeltyUpdateInput } from './zelty-update-input';


export interface UpdateRestaurantInfo {
	name?: string;
	address?: string;
	city?: string;
	country?: string;
	opening_times?: OpeningTimes;
	remote_identifications?: RestaurantRemoteIdentificationsUpdateInput;
	/**
	 * Must reference existing vat_group ID.
	 */
	vat_group?: number;
	publication?: boolean;
	state?: RestaurantState;
	sales_channels?: RestaurantSalesChannelsInput;
	features?: Array<RestaurantFeature>;
	tags?: Array<number>;
	dtt_group_primary?: number;
	dtt_group_secondary?: number;
	contact?: RestaurantContactInfo;
	platform_id?: number;
	fiscal?: RestaurantFiscalInfo;
	legal?: RestaurantLegalInfoUpdateInput;
	latitude?: number;
	longitude?: number;
	zelty?: ZeltyUpdateInput;
}

