export const SHAR3D_CLIENT_ROOT = 'Shar3dRemoting';
export const SHAR3D_CLIENT_COMMAND = 'Command';
export const SHAR3D_CLIENT_ACTION = 'action';
export const SHAR3D_CLIENT_TASK = 'task';
export const SHAR3D_CLIENT_TIMEOUT = 'timeout';
export const SHAR3D_CLIENT_PAYLOAD = 'payload';
export const SHAR3D_CLIENT_ERROR = 'error_result';

export const SHAR3D_CLIENT_ACTIONS_URLS = {
	TASK_INFOS: 'tasks_infos',
	SIMPLE_CALLS: 'task_simplecall',
};

export const RING_APP = 'bkring';
/** Name of the variable holding the name of the host   **/
export const NAME_VARIABLE = 's3d.info.extra.bk.name';
/** Name of the variable holding the role of this host  **/
export const ROLE_VARIABLE = 's3d.info.extra.bk.role';
/** Name of the variable holding the ip of the host **/
export const ADDRESS_VARIABLE = 's3d.info.extra.bk.address';
/** Name of the variable used to hold the app state **/
export const APP_STATE_VARIABLE = 's3d.info.extra.bk.appstate';
/** Name of the variable used to net interface **/
export const APP_NET_INTERFACE = 's3d.info.base.net.interface';
/** Name of the variable containing the name of the app **/
export const APP_NAME_VARIABLE = 's3d.info.base.name';
/** Name of the variable containing the peers**/
export const APP_PEERS_VARIABLE = 's3d.info.extra.bk.peers';
/** Name of the variable used to hold the kiosk state **/
export const KIOSK_STATE_VARIABLE = 's3d.info.extra.kiosk.state';
