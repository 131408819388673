import { Injectable } from '@angular/core';
import { IBKAllergenData } from '@bk/jscommondatas';

import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IAllergensState } from '../interfaces';
import { CleanAllergensState, UpdateAvailableAllergensState, UpdateUsedAllergensState } from './actions';
import { getAvailableAllergens, getUsedAllergens } from './selectors';

@Injectable()
export class AllergensFacade {
	readonly ngUnsubscribe$: Subject<void> = new Subject<void>();

	constructor(private store: Store<IAllergensState>) {}

	cleanAllergens(): void {
		this.store.dispatch(CleanAllergensState());
	}

	updateAvailableAllergens(allergens: { [id: string]: IBKAllergenData }): void {
		this.store.dispatch(UpdateAvailableAllergensState({ allergens }));
	}

	updateUsedAllergens(allergens: { [id: string]: IBKAllergenData }): void {
		this.store.dispatch(UpdateUsedAllergensState({ allergens }));
	}

	getCurrentlyUsedAllergens(): Observable<{ [id: string]: IBKAllergenData }> {
		return this.store.pipe(select(getUsedAllergens), takeUntil(this.ngUnsubscribe$));
	}

	getAllAvailableAllergens$(): Observable<{ [id: string]: IBKAllergenData }> {
		return this.store.pipe(select(getAvailableAllergens), takeUntil(this.ngUnsubscribe$));
	}

	unsubscribe(): void {
		this.ngUnsubscribe$.next();
		this.ngUnsubscribe$.complete();
	}
}
