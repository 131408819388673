/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MediaType } from './media-type';
import { Artifact } from './artifact';


export interface Media { 
    id: number;
    name: string;
    url: string;
    fallback_image_url?: string;
    type: MediaType;
    original_file_name?: string;
    zip_url?: string;
    duration_in_s?: number;
    original_resolution?: string;
    creation_date: string;
    modification_date: string;
    artifacts?: Array<Artifact>;
}

