/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PredictionKitchenSalesMapDto } from './prediction-kitchen-sales-map-dto';


export interface PredictionKitchenDetailDto { 
    /**
     * Start of the predicted interval in ISO-8601 dateTime string format.
     */
    datetime: string;
    /**
     * Length of the interval for prediction in milliseconds.
     */
    timeInterval: number;
    /**
     * Items with predictions. Ordered by priority (to be added).
     */
    salesMap: Array<PredictionKitchenSalesMapDto>;
}

