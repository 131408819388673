import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { inject, Injectable, Injector } from '@angular/core';
import { readFromLocalStorage, removeFromLocalStorage } from '@bk/frontend-common';
import { IStoreErrorData } from '@libs/shared/interfaces';
import { ERROR_STORE_ORIGINATORS, ERROR_STORE_SEVERITY_TYPES, HttpStatusCodes, LOCAL_STORAGE_KEYS } from '@libs/shared/models';
import { LoginService } from '@libs/shared/services';
import { ErrorFacade } from '@libs/shared/store/error';
import { generateUUID } from '@libs/shared/utils';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

const LOGGED_IN_URL = 'bksales/isLogged';

@Injectable()
export class ClerkAuthHandler extends HttpHandler {
	private readonly _next = inject(HttpHandler);
	private readonly _errorFacade = inject(ErrorFacade);
	private readonly _injector = inject(Injector);

	constructor() {
		super();
	}

	override handle(req: HttpRequest<unknown>): Observable<HttpEvent<unknown>> {
		const jwt = readFromLocalStorage<string>(LOCAL_STORAGE_KEYS.CLERK_JWT);
		const update = jwt ? { headers: req.headers.set('Authorization', `Bearer ${jwt}`) } : {};

		const request = req.clone(update);

		// handling of isLogged request
		// This request is called upon start of device, so we need to handle it differently
		if (req.url.endsWith(LOGGED_IN_URL)) {
			return this._next.handle(request);
		}

		return this._next.handle(request).pipe(
			catchError((error: HttpErrorResponse) => {
				if (error.status !== HttpStatusCodes.UNAUTHORIZED) {
					return throwError(() => error);
				}

				return this._injector
					.get(LoginService)
					.login()
					.pipe(
						switchMap((loginResponse) => {
							const jwt = loginResponse?.jwt;
							if (!jwt) {
								return throwError(() => new Error('[ClerkInterceptor]: No JWT received after login'));
							}

							const newReq = request.clone({
								setHeaders: {
									Authorization: `Bearer ${jwt}`,
								},
							});

							return this._next.handle(newReq);
						})
					);
			}),
			tap({
				error: (err: HttpErrorResponse) => {
					switch (err.status) {
						case HttpStatusCodes.UNAUTHORIZED:
							removeFromLocalStorage(LOCAL_STORAGE_KEYS.CLERK_JWT);
							break;

						case HttpStatusCodes.INTERNAL_SERVER_ERROR:
							if (this._errorFacade) {
								const newError: IStoreErrorData = {
									id: generateUUID(),
									seen: false,
									originator: ERROR_STORE_ORIGINATORS.CLERK,
									data: err,
									message: 'INTERNAL_SERVER_ERROR',
									severity: ERROR_STORE_SEVERITY_TYPES.HIGHEST,
								};
								this._errorFacade.insertNewError(newError);
							}
							break;
					}

					return throwError(() => err);
				},
			})
		);
	}
}
