/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SelectionPatternDto } from './selection-pattern-dto';


export interface CampaignDaypartSelectionDto { 
    /**
     * If the daypart campaign should display only selected items.
     */
    showOnlySelected: boolean;
    /**
     * Flag indicating if the \"priceAdjust\" property is in percentage (default) or in set value.
     */
    priceAdjustInValue: boolean;
    /**
     * Value of the discount (in percentage or set value based on \"priceAdjustInValue\" property).
     */
    priceAdjust: number;
    /**
     * Patterns of the items included in daypart selection.
     */
    patterns: Array<SelectionPatternDto>;
}

