/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DMBConfigDto } from './dmb-config-dto';
import { DriveConfigDataDto } from './drive-config-data-dto';
import { CODConfigDataDto } from './cod-config-data-dto';
import { XMBConfigDto } from './xmb-config-dto';
import { KioskConfigurationDataDto } from './kiosk-configuration-data-dto';
import { PosConfigurationDataDto } from './pos-configuration-data-dto';
import { KitchenConfigsDto } from './kitchen-configs-dto';
import { NavigationScreenDataDto } from './navigation-screen-data-dto';
import { ORBConfigDataDto } from './orb-config-data-dto';
import { AssemblyAreaDataDto } from './assembly-area-data-dto';


export interface RestoConfigDto { 
    /**
     * Name of the restaurant.
     */
    name?: string;
    /**
     * uuid of the restaurant.
     */
    uuid?: string;
    /**
     * Is the multipayment is enabled on kiosk
     */
    enableMultipayOnKiosk?: boolean;
    /**
     * Is the kingdom is enabled
     */
    enableKingdom?: boolean;
    /**
     * Is the ORBDigital enabled
     */
    enableORBDigital?: boolean;
    /**
     * Is the defferedPay enabled
     */
    enableDefferedPay?: boolean;
    /**
     * Is the ICouponPay enabled
     */
    enableICouponPay?: boolean;
    /**
     * List of kitchen configurations.
     */
    kitchenConfigs: { [key: string]: KitchenConfigsDto; };
    /**
     * Default Kitchen Configuration
     */
    kitchenConfigsDefault: number;
    /**
     * List of Orb configurations.
     */
    orbConfigs: { [key: string]: ORBConfigDataDto; };
    /**
     * Default orb Configuration
     */
    orbConfigsDefault: number;
    /**
     * List of drive configurations.
     */
    driveConfigs?: { [key: string]: DriveConfigDataDto; };
    /**
     * List of cod configurations.
     */
    codConfigs?: { [key: string]: CODConfigDataDto; };
    /**
     * Default Drive Configuration
     */
    driveConfigsDefault?: number;
    /**
     * List of assembly Areas.
     */
    assemblyAreas: { [key: string]: AssemblyAreaDataDto; };
    /**
     * List of dmb configurations.
     */
    dmbConfig?: DMBConfigDto;
    /**
     * List of xmb configurations.
     */
    xmbConfig?: XMBConfigDto;
    /**
     * List of Kiosk Screens.
     */
    kioskScreens: { [key: string]: NavigationScreenDataDto; };
    /**
     * List of kiosk configurations.
     */
    kioskConfigs: { [key: string]: KioskConfigurationDataDto; };
    /**
     * Default kiosk Configuration
     */
    kioskConfigsDefault: number;
    /**
     * List of Pos Screens.
     */
    posScreens: { [key: string]: NavigationScreenDataDto; };
    /**
     * List of pos configurations.
     */
    posConfigs: { [key: string]: PosConfigurationDataDto; };
    /**
     * Default Pos Configuration
     */
    posConfigsDefault: number;
    /**
     * id of the restaurant.
     */
    id?: number;
    /**
     * Information about restaurant.
     */
    restaurant?: object;
    /**
     * enabledSources.
     */
    enabledSources?: number;
    /**
     * features of the restaurant.
     */
    features?: string;
    /**
     * Is the paymentCarteGR enabled
     */
    enablePaymentCarteGR?: boolean;
}

