/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CODConfigDriveCoDataDto } from './cod-config-drive-co-data-dto';
import { CODConfigDrivePayDataDto } from './cod-config-drive-pay-data-dto';
import { SelectionPatternDto } from './selection-pattern-dto';


export interface CODConfigDataDto { 
    /**
     * Id of the item reference in the DB
     */
    id: number;
    /**
     * Name of the config
     */
    name: string;
    /**
     * description of the config
     */
    description: string;
    /**
     * List of the driveco configs
     */
    driveCoConfigs: Array<CODConfigDriveCoDataDto>;
    /**
     * List of the drivepay configs
     */
    drivePayConfigs: Array<CODConfigDrivePayDataDto>;
    /**
     * Pattern selecting to which restaurant this config is applicable to
     */
    applicableToPattern: Array<SelectionPatternDto>;
}

