import { MenuSize, ProductPriceType } from "../enums";
import { GlobalPriceDefinition, LocalPriceDefinitionPerSalesChannel } from "../types";

export function convertGlobalPriceToLocalPrice(globalPriceDef: GlobalPriceDefinition, localPrice?: number): LocalPriceDefinitionPerSalesChannel {
	return {
		salesChannelOption: globalPriceDef.salesChannelOption,
		rowIdentifier: globalPriceDef.rowIdentifier as MenuSize | ProductPriceType,
		isDisabled: globalPriceDef.isDisabled,
		referencePrice: globalPriceDef.price || 0,
		localPrice
	};
}