/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MediaPlaylistDto } from './media-playlist-dto';
import { GameDto } from './game-dto';
import { IngredientDto } from './ingredient-dto';
import { IngredientFamilyDto } from './ingredient-family-dto';
import { MenuStepProductGroupDto } from './menu-step-product-group-dto';
import { AnnotationDto } from './annotation-dto';
import { SuggestionDto } from './suggestion-dto';
import { CouponDto } from './coupon-dto';
import { NutrientDto } from './nutrient-dto';
import { ProductGroupDto } from './product-group-dto';
import { DiscountDto } from './discount-dto';
import { CampaignDto } from './campaign-dto';
import { MediaDto } from './media-dto';
import { AllergenDto } from './allergen-dto';
import { IngredientFilterDto } from './ingredient-filter-dto';
import { OptionDto } from './option-dto';
import { MenuDto } from './menu-dto';
import { ProductDto } from './product-dto';
import { ProductFamilyDto } from './product-family-dto';


export interface BigDataDto { 
    /**
     * Dictionary of active allergens by ID.
     */
    allergens: { [key: string]: AllergenDto; };
    /**
     * Dictionary of active campaigns by ID.
     */
    annotations: { [key: string]: AnnotationDto; };
    /**
     * Dictionary of active campaigns by ID.
     */
    campaigns: { [key: string]: CampaignDto; };
    /**
     * Dictionary of active coupons by ID.
     */
    coupons?: { [key: string]: CouponDto; };
    /**
     * Dictionary of active discounts by ID.
     */
    discounts: { [key: string]: DiscountDto; };
    /**
     * Dictionary of active games by ID.
     */
    games?: { [key: string]: GameDto; };
    /**
     * Dictionary of active ingredients by ID.
     */
    ingredients: { [key: string]: IngredientDto; };
    /**
     * Dictionary of active ingredient families by ID.
     */
    ingredientFamilies: { [key: string]: IngredientFamilyDto; };
    /**
     * Dictionary of active games by ID.
     */
    ingredientFilters?: { [key: string]: IngredientFilterDto; };
    /**
     * Dictionary of active medias by ID.
     */
    medias: { [key: string]: MediaDto; };
    /**
     * Dictionary of active medias playlists by ID.
     */
    mediasPlayList: { [key: string]: MediaPlaylistDto; };
    /**
     * Dictionary of active menus by ID.
     */
    menus: { [key: string]: MenuDto; };
    /**
     * Dictionary of active product groups for menu steps by ID.
     */
    menuStepProductGroups?: { [key: string]: MenuStepProductGroupDto; };
    /**
     * Dictionary of active nutrients by ID.
     */
    nutritionNutrients?: { [key: string]: NutrientDto; };
    /**
     * Dictionary of active options by ID.
     */
    options: { [key: string]: OptionDto; };
    /**
     * Dictionary of active product families by ID.
     */
    productFamilies: { [key: string]: ProductFamilyDto; };
    /**
     * Dictionary of active product groups by ID.
     */
    productGroups: { [key: string]: ProductGroupDto; };
    /**
     * Dictionary of active products by ID.
     */
    products: { [key: string]: ProductDto; };
    /**
     * Dictionary of active suggestions by ID.
     */
    suggestions: { [key: string]: SuggestionDto; };
}

