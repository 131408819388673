/**
 * Legacy PriceDefinition for Product or Menu.
 *
 * All values are "undefined" when the item is disabled.
 */

export class ItemPrice {
	/**
	 * Price without any tax (for SalesChannelOption.EatIn)
	 */
	ht: number | undefined;

	/**
	 * Price without any tax for take-out option (for SalesChannelOption.TakeAway)
	 */
	htto?: number | undefined;

	/** Total cost with VAT included */
	ttc: number | undefined;

	/** TVA means VAT - "Value added tax" */
	tva: number | undefined;

	/**
	 * Percent of default VAT (for SalesChannelOption.EatIn)
	 */
	pc: number | undefined;

	/**
	 * Percent of "take-out" VAT (for SalesChannelOption.TakeAway)
	 */
	pcto?: number | undefined;

	// TODO DEV-2029 - consider adding property isDisabled
}
