import { BKDiscountTypeEnum, BKPatternUtilities, IBKBigData, IBKDiscountInOrderData, IBKItemInOrderBase, IBKProductBase, IBKProductFamilyDataWithContent } from "@bk/jscommondatas";

export interface IExcludedDiscountItems {
	excludedProductIds: (string | number)[];
	excludedMenuIds: (string | number)[];
}

export const getExcludedItemsFromGlobalDiscounts = <
	T extends IBKProductBase,
	F extends IBKProductFamilyDataWithContent<T, F>
>(
	bigData: IBKBigData,
	productFamilies: { [id: number]: F },
	orderContent: IBKItemInOrderBase[],
	orderDiscount: IBKDiscountInOrderData[]
): Record<string, IExcludedDiscountItems> => {
	let excludedLines: Record<string, IExcludedDiscountItems> = {};

	const allDiscounts = orderDiscount.filter((discount) => discount.discountType === BKDiscountTypeEnum.ORDER_DISCOUNT);
	if (!allDiscounts.length) {
		return excludedLines;
	}

	const difference = (arr1: (string | number)[], arr2: (string | number)[]): (string | number)[] => arr1.filter(x => !arr2.includes(x));

	const productIds = new Set();
	const menuIds = new Set();
	orderContent.forEach((item) => {
		if (item.selection) {
			menuIds.add(item.id);
			item.selection.forEach((menuItem) => {
				menuItem.freeItems?.forEach((freeItem) => productIds.add(freeItem.id));
				productIds.add(menuItem.id);
			});
		} else {
			item.freeItems?.forEach((freeItem) => productIds.add(freeItem.id));
			productIds.add(item.id);
		}
	});

	excludedLines = allDiscounts.reduce((discountObj, singleDiscount: IBKDiscountInOrderData) => {
		if (!singleDiscount._discountApplicableToPattern || singleDiscount._discountApplicableToPattern.length === 0) {
			return discountObj;
		}
		const discountApplicableProductIds = new Set(
			BKPatternUtilities.filterProductsMulti(singleDiscount._discountApplicableToPattern, Object.values(bigData.products), bigData.products, productFamilies)?.map((item) => item.id)
		);
		const discountApplicableMenuIds = new Set(
			BKPatternUtilities.filterMenusMulti(singleDiscount._discountApplicableToPattern, Object.values(bigData.menus), bigData.menus)?.map((item) => item.id)
		);

		const differentProductIds = difference(Array.from(productIds) as number[], Array.from(discountApplicableProductIds));
		const differentMenuIds = difference(Array.from(menuIds) as number[], Array.from(discountApplicableMenuIds));

		if(differentProductIds.length === 0 && differentMenuIds.length === 0) {
			return discountObj;
		}

		discountObj[singleDiscount.id] = {
			excludedProductIds: differentProductIds.length > 0 ? differentProductIds : [],
			excludedMenuIds: differentMenuIds.length > 0 ? differentMenuIds : []
		}

		return discountObj;
	}, {} as Record<string, IExcludedDiscountItems>);

	return excludedLines;
};
