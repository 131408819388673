/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { WeatherIntervalDto } from './weather-interval-dto';


export interface WeatherDto { 
    /**
     * Interval for the weather predictions.
     */
    interval: WeatherDto.IntervalEnum;
    /**
     * Detailed weather prediction by set interval.
     */
    weather: Array<WeatherIntervalDto>;
}
export namespace WeatherDto {
    export type IntervalEnum = 'daily' | 'hourly';
    export const IntervalEnum = {
        Daily: 'daily' as IntervalEnum,
        Hourly: 'hourly' as IntervalEnum
    };
}


