import { OrbpConfiguration } from '../models';
import { DssProgramPrintServer } from './dss-program';
import { Printer } from './printer';

export type PrinterConfiguration = {
	/**
	 * ID of the Printer.
	 * Same as in the printer.id
	 */
	id: string;

	/**
	 * Detail of the Printer
	 */
	printer: Printer;

	/**
	 * configuration of the matching ORBp
	 */
	orbpConfiguration: OrbpConfiguration;
};

export type PrinterResponseConfiguration = {
	configuration: PrinterConfiguration[];
	program: DssProgramPrintServer[];
};
