import { _BK, BKDiscountInOrderDataImpl, BKDiscountTypeEnum, BKMenuSizeEnum, IBKDiscountData, IBKItemInOrderBase } from "@bk/jscommondatas";
import { Shar3dUtils } from "@shar3d/shar3d-utils";
import { IOrderCompleteData } from "@shared/models";
import { BKDiscountsCompatibilityEnum, IDiscountResult } from "../models";

export const isDiscountForProductsCheck = (discount: IBKDiscountData): boolean => {
	switch (discount.discountType) {
		case BKDiscountTypeEnum.PRODUCT_DISCOUNT:
		case BKDiscountTypeEnum.PRODUCT_SINGLE_PRICE:
		case BKDiscountTypeEnum.PRODUCT_AND_EXTRA_DISCOUNT:
		case BKDiscountTypeEnum.PRODUCT_EXCLUSIVE:
			return true;
		default:
			return false;
	}
};

/**
 * Check for if a discount should be for menus
 */
export const isDiscountForMenusCheck = (discount: IBKDiscountData): boolean => {
	switch (discount.discountType) {
		case BKDiscountTypeEnum.MENU_DISCOUNT:
		case BKDiscountTypeEnum.MENU_SINGLE_PRICE:
		case BKDiscountTypeEnum.MENU_AND_EXTRA_DISCOUNT:
		case BKDiscountTypeEnum.MENU_EXCLUSIVE:
			return true;
		default:
			return false;
	}
};

/**
 * Check if a discount should be for orders
 */
export const isDiscountForOrderCheck = (discount: IBKDiscountData): boolean => {
	switch (discount.discountType) {
		case BKDiscountTypeEnum.ORDER_DISCOUNT:
			return true;
		default:
			return false;
	}
};

export const hasOrderDiscountCheck = (allDiscounts: IBKDiscountData[]): boolean => {
	return Shar3dUtils.some(allDiscounts || [], (d: IBKDiscountData) => isDiscountForOrderCheck(d));
};

/**
 * Check if this a discount list has product discount
 */
export const hasProductDiscountCheck = (allDiscounts: IBKDiscountData[]): boolean => {
	return Shar3dUtils.some(allDiscounts || [], (d: IBKDiscountData) => isDiscountForProductsCheck(d));
};

export const logDiscountRejectionIssue = (orderUuid: string, discount: IBKDiscountData, reason: BKDiscountsCompatibilityEnum): void => {
	switch (reason) {
		case BKDiscountsCompatibilityEnum.DISCOUNT_IN_ORDER_ERROR:
			//eslint-disable-next-line no-restricted-syntax
			console.info(`[UUID]: ${orderUuid} - Order already contains order discount. Not adding ${discount.id}`);
			break;
		case BKDiscountsCompatibilityEnum.DISCOUNT_IN_MENU_ERROR:
			//eslint-disable-next-line no-restricted-syntax
			console.info(
				`[UUID]: ${orderUuid} - Order already contains menu discount that is not combinable with other menu discounts. Not adding ${discount.id}`
			);
			break;
		case BKDiscountsCompatibilityEnum.DISCOUNT_IN_PRODUCT_ERROR:
			//eslint-disable-next-line no-restricted-syntax
			console.info(
				`[UUID]: ${orderUuid} - Order already contains product discount that is not combinable with other product discounts. Not adding ${discount.id}`
			);
			break;
		default:
			//eslint-disable-next-line no-restricted-syntax
			console.info(`[UUID]: ${orderUuid} - Unknown reason. Not adding ${discount.id}`);
	}
};

/**
 * Check if this a discount list has menu discount
 */
export const hasMenuDiscountCheck = (allDiscounts: IBKDiscountData[]): boolean => {
	return Shar3dUtils.some(allDiscounts || [], (d: IBKDiscountData) => isDiscountForMenusCheck(d));
};

export const getAllDiscounts = (order: IOrderCompleteData): BKDiscountInOrderDataImpl[] => {
	const discounts: BKDiscountInOrderDataImpl[] = [];
	if (!order) {
		return [];
	}

	//  Browse order discounts
	for (let i = 0; i < order.orderDiscount.length; i++) {
		//  Add to result
		discounts.push(order.orderDiscount[i] as BKDiscountInOrderDataImpl);
	}

	//  Browse order content
	for (const oc of order.orderContent) {
		//  Check is an item applied discount is defined
		if (_BK.isDefined(oc.itemAppliedDiscount)) {
			//  Browse discounts
			for (let i = 0; i < oc.itemAppliedDiscount.length; i++) {
				//  Add to result
				discounts.push(oc.itemAppliedDiscount[i] as BKDiscountInOrderDataImpl);
			}
		}
	}

	return discounts;
};

export const canDiscountBeAdded = (discount: IBKDiscountData, order: IOrderCompleteData): IDiscountResult => {
	const allDiscounts: BKDiscountInOrderDataImpl[] = getAllDiscounts(order);
	const usedDiscountsCombination = allDiscounts.reduce(
		(a, b) => {
			if (isDiscountForOrderCheck(b)) {
				a.orderDiscounts.count += 1;
			} else if (isDiscountForProductsCheck(b)) {
				a.productDiscounts.count += 1;
			} else if (isDiscountForMenusCheck(b)) {
				a.menuDiscounts.count += 1;
			}
			a.orderDiscounts.canFurtherBeAdded = a.orderDiscounts.canFurtherBeAdded && b._canBeAddedWithOtherOrderDiscount;
			a.productDiscounts.canFurtherBeAdded = a.productDiscounts.canFurtherBeAdded && b._canBeAddedWithOtherProductDiscount;
			a.menuDiscounts.canFurtherBeAdded = a.menuDiscounts.canFurtherBeAdded && b._canBeAddedWithOtherMenuDiscount;

			return a;
		},
		{
			productDiscounts: { count: 0, canFurtherBeAdded: true },
			menuDiscounts: { count: 0, canFurtherBeAdded: true },
			orderDiscounts: { count: 0, canFurtherBeAdded: true },
		}
	);

	if (!discount._canBeAddedWithOtherMenuDiscount && usedDiscountsCombination.menuDiscounts.count) {
		logDiscountRejectionIssue(order.orderUUID, discount, BKDiscountsCompatibilityEnum.DISCOUNT_IN_MENU_ERROR);
		return { result: false, reason: BKDiscountsCompatibilityEnum.DISCOUNT_IN_MENU_ERROR };
	}

	if (!discount._canBeAddedWithOtherOrderDiscount && usedDiscountsCombination.orderDiscounts.count) {
		logDiscountRejectionIssue(order.orderUUID, discount, BKDiscountsCompatibilityEnum.DISCOUNT_IN_ORDER_ERROR);
		return { result: false, reason: BKDiscountsCompatibilityEnum.DISCOUNT_IN_ORDER_ERROR };
	}

	if (!discount._canBeAddedWithOtherProductDiscount && usedDiscountsCombination.productDiscounts.count) {
		logDiscountRejectionIssue(order.orderUUID, discount, BKDiscountsCompatibilityEnum.DISCOUNT_IN_PRODUCT_ERROR);
		return { result: false, reason: BKDiscountsCompatibilityEnum.DISCOUNT_IN_PRODUCT_ERROR };
	}


	if (isDiscountForOrderCheck(discount) && !usedDiscountsCombination.orderDiscounts.canFurtherBeAdded) {
		logDiscountRejectionIssue(order.orderUUID, discount, BKDiscountsCompatibilityEnum.DISCOUNT_IN_ORDER_ERROR);
		return { result: false, reason: BKDiscountsCompatibilityEnum.DISCOUNT_IN_ORDER_ERROR };
	}

	if (isDiscountForMenusCheck(discount) && !usedDiscountsCombination.menuDiscounts.canFurtherBeAdded) {
		logDiscountRejectionIssue(order.orderUUID, discount, BKDiscountsCompatibilityEnum.DISCOUNT_IN_MENU_ERROR);
		return { result: false, reason: BKDiscountsCompatibilityEnum.DISCOUNT_IN_MENU_ERROR };
	}

	if (isDiscountForProductsCheck(discount) && !usedDiscountsCombination.productDiscounts.canFurtherBeAdded) {
		logDiscountRejectionIssue(order.orderUUID, discount, BKDiscountsCompatibilityEnum.DISCOUNT_IN_PRODUCT_ERROR);
		return { result: false, reason: BKDiscountsCompatibilityEnum.DISCOUNT_IN_PRODUCT_ERROR };
	}

	return { result: true, reason: BKDiscountsCompatibilityEnum.OK };
};

export const isValidMenuSize = (item: IBKItemInOrderBase, menuSizeMask: number): boolean => {
	if (!item) {
		return false;
	}

	const getMenuSize = (item: IBKItemInOrderBase) => {
		if (!item || !item.selection) {
			return BKMenuSizeEnum.NONE;
		}
		return item.large ? BKMenuSizeEnum.LARGE : BKMenuSizeEnum.MEDIUM;
	};
	const menuSizeValueOfItem: BKMenuSizeEnum = getMenuSize(item);
	if (menuSizeValueOfItem === BKMenuSizeEnum.NONE) {
		return true;
	}
	return Shar3dUtils.contains(getMenuSizesAvailableFromMaskCheck(menuSizeMask), menuSizeValueOfItem);
};

export const getMenuSizesAvailableFromMaskCheck = (menuSizeMask: number): BKMenuSizeEnum[] => {
	const allMenuSize: BKMenuSizeEnum[] = [BKMenuSizeEnum.MEDIUM, BKMenuSizeEnum.LARGE];
	const filter: BKMenuSizeEnum[] = allMenuSize.filter((menuSizeValue) => _BK.valueInMaskTest(menuSizeValue, menuSizeMask));
	return filter.length <= 0 ? allMenuSize : filter;
};
