/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface LocalFeaturesDto { 
    /**
     * Features that are listed in **configuration-global.json -> features** and are flagged with \'enabled: false\' and \'modifiable: true\' are by default disabled and this is a list of such features that were locally enabled.
     */
    enabled: Array<LocalFeaturesDto.EnabledEnum>;
    /**
     * Features that are listed in **configuration-global.json -> features** and are flagged with \'enabled: true\' and \'modifiable: true\' are by default enabled and this is a list of such features that were locally disabled.
     */
    disabled: Array<LocalFeaturesDto.DisabledEnum>;
}
export namespace LocalFeaturesDto {
    export type EnabledEnum = 'DEFERRED_PAYMENT' | 'FIDELITY_PROGRAM' | 'GR_CARD_PAYMENT' | 'ICOUPON_PAYMENT' | 'KIOSK_MULTIPAYMENT' | 'ORB_SUBSCRIPTION' | 'WAIT_AT_THE_TABLE' | 'LOCAL_PRICE_CHANGE' | 'LOCAL_PRICE_CHANGE_DELIVERY' | 'TABLE_SERVICE' | 'TABLE_SERVICE_TERRACE' | 'KIOSK_EAT_IN' | 'WALK_BY_WINDOW';
    export const EnabledEnum = {
        DeferredPayment: 'DEFERRED_PAYMENT' as EnabledEnum,
        FidelityProgram: 'FIDELITY_PROGRAM' as EnabledEnum,
        GrCardPayment: 'GR_CARD_PAYMENT' as EnabledEnum,
        IcouponPayment: 'ICOUPON_PAYMENT' as EnabledEnum,
        KioskMultipayment: 'KIOSK_MULTIPAYMENT' as EnabledEnum,
        OrbSubscription: 'ORB_SUBSCRIPTION' as EnabledEnum,
        WaitAtTheTable: 'WAIT_AT_THE_TABLE' as EnabledEnum,
        LocalPriceChange: 'LOCAL_PRICE_CHANGE' as EnabledEnum,
        LocalPriceChangeDelivery: 'LOCAL_PRICE_CHANGE_DELIVERY' as EnabledEnum,
        TableService: 'TABLE_SERVICE' as EnabledEnum,
        TableServiceTerrace: 'TABLE_SERVICE_TERRACE' as EnabledEnum,
        KioskEatIn: 'KIOSK_EAT_IN' as EnabledEnum,
        WalkByWindow: 'WALK_BY_WINDOW' as EnabledEnum
    };
    export type DisabledEnum = 'DEFERRED_PAYMENT' | 'FIDELITY_PROGRAM' | 'GR_CARD_PAYMENT' | 'ICOUPON_PAYMENT' | 'KIOSK_MULTIPAYMENT' | 'ORB_SUBSCRIPTION' | 'WAIT_AT_THE_TABLE' | 'LOCAL_PRICE_CHANGE' | 'LOCAL_PRICE_CHANGE_DELIVERY' | 'TABLE_SERVICE' | 'TABLE_SERVICE_TERRACE' | 'KIOSK_EAT_IN' | 'WALK_BY_WINDOW';
    export const DisabledEnum = {
        DeferredPayment: 'DEFERRED_PAYMENT' as DisabledEnum,
        FidelityProgram: 'FIDELITY_PROGRAM' as DisabledEnum,
        GrCardPayment: 'GR_CARD_PAYMENT' as DisabledEnum,
        IcouponPayment: 'ICOUPON_PAYMENT' as DisabledEnum,
        KioskMultipayment: 'KIOSK_MULTIPAYMENT' as DisabledEnum,
        OrbSubscription: 'ORB_SUBSCRIPTION' as DisabledEnum,
        WaitAtTheTable: 'WAIT_AT_THE_TABLE' as DisabledEnum,
        LocalPriceChange: 'LOCAL_PRICE_CHANGE' as DisabledEnum,
        LocalPriceChangeDelivery: 'LOCAL_PRICE_CHANGE_DELIVERY' as DisabledEnum,
        TableService: 'TABLE_SERVICE' as DisabledEnum,
        TableServiceTerrace: 'TABLE_SERVICE_TERRACE' as DisabledEnum,
        KioskEatIn: 'KIOSK_EAT_IN' as DisabledEnum,
        WalkByWindow: 'WALK_BY_WINDOW' as DisabledEnum
    };
}


