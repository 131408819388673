import { APPLICATION_NAME, BK_CASH, DeviceInfo, DRIVE_CO, DRIVE_PAY, POS_ROLE, UNKNOWN } from "../models";
import { extractPosRole } from "./extract-pos-role";
import { extractTrailingNumber } from "./extract-trailing-number";

export function parseDeviceInfo(deviceInfoStr: string): DeviceInfo {
	// Device index is part of its name, eg POS1 has idx 1
	const idx = extractTrailingNumber(deviceInfoStr);
	const posRole: POS_ROLE | undefined = extractPosRole(deviceInfoStr);

	const posRoles = [DRIVE_CO, DRIVE_PAY];
	const appName: APPLICATION_NAME | undefined = posRole && posRoles.includes(posRole)
		? BK_CASH
		: UNKNOWN;

	// Sanity check
	if (idx === undefined) {
		throw new Error(`parseDeviceInfo(): Could not extract device id.`);
	}

	const result: DeviceInfo = {
		appName,
		idx,
		role: posRole
	}

	return result;
}
