import { OrbpDetail } from './orbp-detail';

export type DssProgram = {
	id: string;
	name: string;
	description: string;

	/** Background CSS color name or HEX code */
	color: string;

	/**
	 * Contrasting text color is calculated. Based on background color.
	 */
	textColor?: string;

	/**
	 * From what time in hh:mm the Program is running
	 */
	fromTime: string;

	/**
	 * Until what time in hh:mm the Program is running
	 */
	toTime: string;
	isActive: boolean;
	orbpConfigurations?: Array<OrbpDetail>;

	/*
	 This attribute determines if the Program can be deleted or only reset to predefine settings.
	 */
	canBeResetToFactoryDefault: boolean;

	/**
	 * Set by RAP-BE server, when client asks for data
	 */
	isValid?: boolean;
};

export type DssProgramPrintServer = {
	id: string;
	name: string;
	description: string;
	/**
	 * From what time in hh:mm the Program is running
	 */
	fromTime: string;
	/**
	 * Until what time in hh:mm the Program is running
	 */
	toTime: string;
	isActive: boolean;
	orbpConfigurations?: Array<OrbpDetail>;

	/**
	 * Set by RAP-BE server, when client asks for data
	 */
	isValid?: boolean;
};
