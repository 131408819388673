/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ItemPriceDto } from './item-price-dto';
import { GlobalPriceDefinitionDto } from './global-price-definition-dto';
import { VatValueDto } from './vat-value-dto';
import { ItemReferenceDto } from './item-reference-dto';
import { ProductIngredientDto } from './product-ingredient-dto';
import { ProductNutritionDto } from './product-nutrition-dto';
import { ItemCrownPriceDto } from './item-crown-price-dto';


export interface ProductDto { 
    /**
     * Id of the item.
     */
    id: number;
    /**
     * System name of the item.
     */
    sysName: string;
    /**
     * Short name used for employee facing displays.
     */
    shortName: string;
    /**
     * Dictionary (associative array) of names where key is the language.
     */
    _name: { [key: string]: string; };
    /**
     * Dictionary (associative array) of descriptions where key is the language.
     */
    _description: { [key: string]: string; };
    /**
     * Ids of tags associated with the item.
     */
    xtag: Array<number>;
    /**
     * Flag indicating if the item is temporary and must be referenced in an active campaign to be enabled.
     */
    campaignItem: boolean;
    /**
     * Flag indicating if the item is in an active campaign.
     */
    inActiveCampaign?: boolean;
    /**
     * Id of the export group the item belongs to.
     */
    _groupId?: number;
    /**
     * Flag indicating if the item is active and can be displayed to customers. Used for \"soft delete\". Everything included in BigData must be presumed active, because initial filtering of active items is performed when BigData are generated on the global level.
     */
    active?: boolean;
    /**
     * New price definitions for price management.
     */
    globalPriceDefinitions?: Array<GlobalPriceDefinitionDto>;
    /**
     * New VAT values for price management.
     */
    vatValues?: Array<VatValueDto>;
    /**
     * Alternative name to be printed on the ticket.
     */
    _altName?: string;
    /**
     * Flag indicating if the item is available or not. Should not be used, availability is determined by local \"available.json\" file (raw availability).
     */
    available?: boolean;
    /**
     * Flag indicating if the item is not to be displayed on kiosk.
     */
    noKiosk: boolean;
    /**
     * Flag indicating if the item is not to be displayed on web.
     */
    noWeb: boolean;
    /**
     * Flag indicating if the item is not a food item.
     */
    nonFood?: boolean;
    /**
     * Flag indicating if the item is only available to members of fidelity program.
     */
    exclusiveToFidelity?: boolean;
    /**
     * Flag indicating if the item requires manager validation prior to being added to the order.
     */
    requireValidation?: boolean;
    /**
     * Weight of the item (for logictic purposes).
     */
    _weight?: number;
    /**
     * Index on the POS/DRIVEPAY/DRIVECO.
     */
    _indexPOS: number;
    /**
     * Index on the KIOSK.
     */
    _indexKiosk: number;
    /**
     * If the item has main image.
     */
    _hasImageMain?: boolean;
    /**
     * If the item has system image.
     */
    _hasImageSys?: boolean;
    /**
     * If the item should be excluded from order discount.
     */
    _excludeFromGlobalDiscount?: boolean;
    /**
     * Id of the product family the product belongs to.
     */
    _familyId: number;
    /**
     * Id of the upper size product.
     */
    _upperSizeProduct: number;
    /**
     * Ids of allergens associated with the product.
     */
    _xallergen: Array<number>;
    /**
     * Flag indicating if the product can be personalized.
     */
    _configurable: boolean;
    /**
     * Flag indicating if the product must be displayed on KDS.
     */
    _displayInKitchen: boolean;
    /**
     * Price information of the MEDIUM regular size product (you may notice M in the property name which suggests there is also L size, but it is not the case, every product has just one size and if there is upper size product, it is referenced in \"_upperSizeProduct\" property).
     */
    _priceM?: ItemPriceDto;
    /**
     * Reference information of the product (you may notice M in the property name which suggests there is also L size, but it is not the case, every product has just one size and if there is upper size product, it is referenced in \"_upperSizeProduct\" property).
     */
    _refM: ItemReferenceDto;
    /**
     * Crown information about the product (when the product is ordered internally - by employees).
     */
    _crown: ItemCrownPriceDto;
    /**
     * Flag indicating if the product can be delivered later. If such option is selected in the order, the customer will receive 2 tickets, one for initial products and the other for \"delivery later\" products and those products are then given to the customer after he is finished with initial products. This is typical for hot bevarages or ice cream so it would not cool down or melt while the customer is eating burger.
     */
    _canDeliverLater: boolean;
    /**
     * List of the ingredients in the product with some additional options in case the product may be modified.
     */
    _ingredients: Array<ProductIngredientDto>;
    /**
     * Flag indicating if the product has alternative kiosk image.
     */
    _hasKioskImage2?: boolean;
    /**
     * Flag indicating if the product typically contains ice, so the customer can select \"no ice\" option.
     */
    _hasIce: boolean;
    /**
     * Flag indicating that the customer should not be suggested to get upper size product.
     */
    _preventUpsize?: boolean;
    /**
     * Flag indicating that the customer should not be suggested to get menu instead of the product.
     */
    _preventMenuSuggestion?: boolean;
    /**
     * Ids of allergens which may be containd in the product with just trace amount.
     */
    _xtraces?: Array<number>;
    /**
     * Mask for additional flags that may be applied on the product.   Values (ProductFlagMask):   PRODUCT_FLAG_NONE = 0,   PRODUCT_FLAG_THAWED = 2 ** 0
     */
    _flagMask?: number;
    /**
     * Price difference (taxes included) which is applied on the price of a menu when this product is added to it. Typical situation is when you select some menu item which is not basic, so you pay for example extra 0,50 € (_priceExtraMenuTTC) for the upgrade on top of the basic menu price.
     */
    _priceExtraMenuTTC?: number;
    /**
     * Typically discounted price of the product (taxes included) which is applied when the product is added to the menu as an extra step from the suggestion popup. Typical situation is that you want menu, select burger, fries and drink which is the full selection for the complete menu, but then you are shown suggestion popup that for this better price (_priceSuggestionMenuTTC) you can add dessert to the menu as an extra step.
     */
    _priceSuggestionMenuTTC?: number;
    /**
     * Id of the linked product which must be disabled in case this product is also disabled. Typical for uppersize products.
     */
    _linkedProduct?: number;
    /**
     * Flag indicating if the product is intended for students.
     */
    _forStudents?: boolean;
    /**
     * Flag indicating if the product must not be sold to minors. Customer ID should be checked.
     */
    _requireProofOfAdulthood?: boolean;
    /**
     * Header for the \"value1\" property in \"_nutrition\" array.   Values:   NONE = 0,   PER_PORTION = 1,   PER_100G = 2,   PER_100ML = 3
     */
    _nutritionHeader1?: number;
    /**
     * Header for the \"value2\" property in \"_nutrition\" array.   Values:   NONE = 0,   PER_PORTION = 1,   PER_100G = 2,   PER_100ML = 3
     */
    _nutritionHeader2?: number;
    /**
     * List of the nutrition in the product. Properties \"value1\" and \"value2\" refer to the nutrition header type set in properties \"_nutritionHeader1\" and \"_nutritionHeader2\".
     */
    _nutrition?: Array<ProductNutritionDto>;
    /**
     * Code of the color to modify product detail background in the KIOSK.
     */
    codeColor?: string;
    /**
     * Flag indicating if product is composable, i.e. should be built like a menu.
     */
    isComposable?: boolean;
    /**
     * Flag indicating if product may be used as part of XL menu.
     */
    _eligibleForMenuXL?: boolean;
}

