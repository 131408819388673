/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DeviceDto } from './device-dto';


export interface RestoSettingsNestedDto { 
    /**
     * Indicates which KIOSK UI is to be used from those configured in RestoConfig. Selected in BK Manager \"Configuration des écrans\" menu.
     */
    currentKioskMenu: number;
    /**
     * Indicates which POS UI is to be used from those configured in RestoConfig. Selected in BK Manager \"Configuration des écrans\" menu.
     */
    currentCashMenu: number;
    /**
     * Indicates which ORBp configuration is to be used from those configured in RestoConfig. Selected in BK Manager \"Configuration des écrans\" menu. Deprecated by DSS.
     */
    currentOrbConfig: number;
    /**
     * Indicates which KDS configuration is to be used from those configured in RestoConfig. Selected in BK Manager \"Configuration des écrans\" menu.
     */
    currentKdsConfig: number;
    /**
     * Indicates which COD configuration is to be used from those configured in RestoConfig. Selected in BK Manager \"Configuration des écrans\" menu.
     */
    currentCodConfig?: number;
    /**
     * Indicates which COD configuration is to be used from those configured in RestoConfig. Selected in BK Manager \"Configuration des écrans\" menu. And YES, this is very likely duplicate of \"currentCodConfig\" property but I am not sure which one is used.
     */
    currentCODConfig?: number;
    /**
     * Indicates which DRIVEPAY/DRIVECO configuration is to be used from those configured in RestoConfig. Selected in BK Manager \"Configuration des écrans\" menu.
     */
    currentDriveConfig?: number;
    /**
     * Current active DRIVEPAY device information.
     */
    currentDrivePay?: DeviceDto;
    /**
     * Current active DRIVECO/COD 1 device information.
     */
    currentDriveCOD1?: DeviceDto;
    /**
     * Current active DRIVECO/COD 2 device information.
     */
    currentDriveCOD2?: DeviceDto;
    /**
     * Dictionary (associative array) of KDS printers where key is the KDS index. Describes which priter is to be used with which KDS
     */
    printerKds?: { [key: string]: string; };
}

