/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CashConfigurationDto { 
    /**
     * Label for the cash configuration describing amount of cash.
     */
    label: string;
    /**
     * Total fiscal value of this cash configuration.
     */
    value: number;
}

