/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ValidationMessageDto } from './validation-message-dto';


export interface OrbpConfigurationValidityDto { 
    /**
     * Flag indicating if the configuration is valid.
     */
    isValid: boolean;
    /**
     * Messages describing why the configuration is not valid.
     */
    validationMessages: Array<ValidationMessageDto>;
}

