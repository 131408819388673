import { IBKAllergenForInfoItem, IBKBigData, IBKIngredientData, IBKProductBase, IBKProductIngredientData, IBKProductIngredientSelectedData } from "@bk/jscommondatas";
import { Shar3dUtils } from "@shar3d/shar3d-utils";

interface IProcessedIngredience {
	[key: string]: { ingredient: IBKIngredientData, id: number, qty: number };
}
export function getAllergenListForProduct(bigData: IBKBigData, product: IBKProductBase, recipe: IBKProductIngredientSelectedData[], ingredients?: IBKIngredientData[]): IBKAllergenForInfoItem[] {
	const ingredientsFromRecipe = composeIngredientsListFromProductRecipe(bigData, product, recipe, ingredients);
	const allergensFromRecipe = convertIngredientsIntoAllergensInfo(ingredientsFromRecipe);

	const baseAllergensFromProduct = getAllergenInfosWithList(product._xallergen, product._xtraces);

	return sortAllergenInfosByTrace(mergeAllergenInfos(allergensFromRecipe, baseAllergensFromProduct));
}

export function getAllergenInfosWithList(allergenIds: number[], traceIds: number[]): IBKAllergenForInfoItem[] {
	// Init allergens list
	const allergenList: IBKAllergenForInfoItem[] = [];
	// Set allergens list from bacic allergens
	(allergenIds || []).forEach((allergenId) => allergenList.push({ aId: allergenId, trace: false }));
	// Set traces
	(traceIds || []).forEach((traceId) => {
		// Check if allergen exists in list
		const index: number = Shar3dUtils.findIndex(allergenList, (a: IBKAllergenForInfoItem) => a.aId === traceId);
		if (allergenList[index]) {
			// Allergen already exists
			allergenList[index].trace = true;
		} else {
			// Allergen does not already exist, add it
			allergenList.push({ aId: traceId, trace: true });
		}
	});
	return allergenList;
}

export function sortAllergenInfosByTrace(allergensInfo: IBKAllergenForInfoItem[]): IBKAllergenForInfoItem[] {
	return Shar3dUtils.sortBy(allergensInfo || [], (a: IBKAllergenForInfoItem) => a.trace);
}

export function mergeAllergenInfos(...allergensInfosLists: IBKAllergenForInfoItem[][]): IBKAllergenForInfoItem[] {
	const response: IBKAllergenForInfoItem[] = [];
	for (const allInfos of allergensInfosLists) {
		for (const info of allInfos) {
			// Check if allergen exists in list
			const index: number = Shar3dUtils.findIndex(response, (a: IBKAllergenForInfoItem) => a.aId === info.aId);
			if (response[index]) {
				// Allergen already exists
				// Check if "trace" flag need to be updated
				if (info.trace === false && response[index].trace) {
					response[index].trace = false;
				}
			} else {
				// Allergen does not already exist, add it
				response.push(info);
			}
		}
	}
	return response;
}

function composeIngredientsListFromProductRecipe(bigData: IBKBigData, product: IBKProductBase, recipe: IBKProductIngredientSelectedData[], ingredients: IBKIngredientData[]): IBKIngredientData[] {
	if (!product || !ingredients) {
		return [];
	}

	const isRecipeModified = recipe.some(item => {
		return item.selected !== item.initial || item.qty !== item.initQty || item.amount !== item.initAmount;
	});
	if (isRecipeModified) {
		return getIngredientsFromProductSpecificRecipe(recipe, ingredients);
	}
	return getIngredientsFromProductBasicRecipe(product, bigData);
}

function getIngredientsFromProductSpecificRecipe(recipe: IBKProductIngredientSelectedData[], ingredients: IBKIngredientData[]): IBKIngredientData[] {
	const ingredientsSelected = recipe.reduce((a, line) => {
		if (line.qty > 0 && !a[line.selected]) {
			const ingredient = ingredients.find(ingredient => ingredient.id === line.selected);
			if (ingredient) {
				a[line.selected] = ingredient;
			}
		}
		return a;
	}, {})

	return Object.values(ingredientsSelected);
}

function getIngredientsFromProductBasicRecipe(product: IBKProductBase, bigData: IBKBigData): IBKIngredientData[] {
	const ingredientsWithQties = product._ingredients.reduce((a: IProcessedIngredience, ingredient: IBKProductIngredientData) => {
		const ingredientData = bigData.ingredients[ingredient.ingredientDefault];
		if (ingredient?.initialQty > 0 && !a[ingredient.ingredientDefault] && ingredientData) {
			a[ingredient.ingredientDefault] = {
				ingredient: ingredientData,
				qty: ingredient.initialQty,
				id: ingredient.ingredientDefault
			};
		}
		return a;
	}, {});
	const res = Object.values(ingredientsWithQties).map((item) => item.ingredient);
	return res;
}

function convertIngredientsIntoAllergensInfo(ingredients: IBKIngredientData[]): IBKAllergenForInfoItem[] {
	const allAllergensInfo = ingredients.map(i => {
		if (!i) {
			return [];
		}
		return getAllergenInfosWithList(i.xallergen, i._xtraces);
	})
	return sortAllergenInfosByTrace(mergeAllergenInfos(...allAllergensInfo));
}