import { DefaultSortingOrdersAlgorithm, SalesChannel, SortingOrdersAlgorithm } from "../../enums/index";
import { SalesChannelConfiguration } from "../../types";
import { OrbpDetail } from "../../types/orbp-detail";
import { isMaster } from "../is-role";
import { isSalesChannelOn } from "../sales-channels/is-sales-channel-on";

export type SortingOrdersValidResult = {
	orbpId: string;
	isValid: true;
};
export type SortingOrdersInvalidResult = {
	orbpId: string;
	isValid: false;
	errorTranslationKey: string;
};

export type SortingOrdersValidationResult = SortingOrdersValidResult | SortingOrdersInvalidResult;

// Important note: These validation keys have to be defined in application which consumes this shared library.
// In this case they are defined in RAP app.
export enum SortingOrdersValidationValidationKeys {
	ALGORITHM_NOT_SELECTED = 'SORTING-ORDERS-ALGORITHM-MASTER-ORBP__ALGORITHM_NOT_SELECTED',
	NON_DRIVE_SALES_CHANNEL_WITH_DRIVE_SORTING_ALGO = 'SORTING-ORDERS-ALGORITHM-MASTER-ORBP__DRIVE_ALGO_BUT_NO_DRIVE_SALES_CHANNELS',
	DRIVE_SALES_CHANNEL_WITH_DEFAULT_SORTING_ALGO = 'SORTING-ORDERS-ALGORITHM-MASTER-ORBP__DRIVE_CHANNEL_WITH_DEFAULT_SORTING_ALGO',
	MIXED_DRIVE_AND_NON_DRIVE = 'SORTING-ORDERS-ALGORITHM-MASTER-ORBP__MIXED_DRIVE_AND_NON_DRIVE',
}

export function isSortingOrdersAlgorithmValidOnMasterOrbp(masterOrbp: OrbpDetail): SortingOrdersValidationResult {
	// sanity check
	if (isMaster(masterOrbp) === false) {
		return {
			orbpId: masterOrbp.id,
			isValid: true
		};
	}

	if (!masterOrbp.sortingOrdersAlgorithm || masterOrbp.sortingOrdersAlgorithm === SortingOrdersAlgorithm.UNKNOWN) {
		return {
			isValid: false,
			orbpId: masterOrbp.id,
			errorTranslationKey: SortingOrdersValidationValidationKeys.ALGORITHM_NOT_SELECTED
		};
	}

	// Now check if there is a "drive sales channel", which requires either BY_CREATION_TIME or BY_PAID_TIME.
	// If there are only "non drive sales channels" then the BY_VALIDATION_TIME is required.
	// Described fully here: https://reboot-qsr.atlassian.net/wiki/spaces/RV/pages/511737857/Different+sorting+orders+algorithms
	const salesChannelsWithDriveWindow = [
		SalesChannel.DRIVE_WINDOW,
		SalesChannel.CLICK_AND_COLLECT,
		SalesChannel.THIRD_PARTY_DELIVERY
	];

	const salesChannelsWithoutDriveWindow = [
		SalesChannel.CASH_MACHINE,
		SalesChannel.KIOSK,
		SalesChannel.WALK
	];

	const isDriveSalesChannelOn = salesChannelsWithDriveWindow.some(sc => {
		const matchingSc: SalesChannelConfiguration | undefined = masterOrbp.salesChannelConfigurations.find(scc => scc.salesChannel === sc);
		return !!matchingSc && isSalesChannelOn(matchingSc);
	});

	const isNonDriveSalesChannelOn = salesChannelsWithoutDriveWindow.some(sc => {
		const matchingSc: SalesChannelConfiguration | undefined = masterOrbp.salesChannelConfigurations.find(scc => scc.salesChannel === sc);
		return !!matchingSc && isSalesChannelOn(matchingSc);
	});

	// Mixture of drive and non-drive salesChannels is invalid when they use non-default sorting algorithm.
	if (isNonDriveSalesChannelOn && isDriveSalesChannelOn && masterOrbp.sortingOrdersAlgorithm !== DefaultSortingOrdersAlgorithm) {
		return {
			isValid: false,
			orbpId: masterOrbp.id,
			errorTranslationKey: SortingOrdersValidationValidationKeys.MIXED_DRIVE_AND_NON_DRIVE
		};
	}

	// Only DriveChannels but with default sorting algo
	if (isDriveSalesChannelOn && isNonDriveSalesChannelOn === false && masterOrbp.sortingOrdersAlgorithm === DefaultSortingOrdersAlgorithm) {
		return {
			isValid: false,
			orbpId: masterOrbp.id,
			errorTranslationKey: SortingOrdersValidationValidationKeys.DRIVE_SALES_CHANNEL_WITH_DEFAULT_SORTING_ALGO
		};
	}

	// Non-DriveChannels should have default sorting algo
	if (isDriveSalesChannelOn === false && isNonDriveSalesChannelOn && masterOrbp.sortingOrdersAlgorithm !== DefaultSortingOrdersAlgorithm) {
		return {
			isValid: false,
			orbpId: masterOrbp.id,
			errorTranslationKey: SortingOrdersValidationValidationKeys.NON_DRIVE_SALES_CHANNEL_WITH_DRIVE_SORTING_ALGO
		};
	}

	return {
		isValid: true,
		orbpId: masterOrbp.id
	};
}
