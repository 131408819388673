import { BKOrderSourceEnum } from '@bk/jscommondatas';
import { getTruthyEnumMapKeys } from '@merim/utils';
import { SalesChannel } from '../enums';
import { SalesChannelConfiguration } from '../types/index';

import { mapOrderSourceToSalesChannel } from './sales-channels';


/**
 * Is Order Source allowed to be displayed in this ORBp?
 */
export function isOrderSourceAllowed(orderSource: BKOrderSourceEnum, allowedSalesChannels: SalesChannel[]): boolean {
	const salesChannel = mapOrderSourceToSalesChannel(orderSource);
	return allowedSalesChannels.includes(salesChannel);
}

/**
 * Get SalesChannel which have at least 1 SalesChannelOption enabled
 */
export function getAllowedSalesChannels(salesChannelConfigurations: SalesChannelConfiguration[]): SalesChannel[] {
	return salesChannelConfigurations.filter((sc) => getTruthyEnumMapKeys(sc.configuration).length > 0).map((sc) => sc.salesChannel);
}
