/**
 * Frombkgroup = inner process communication - for example print server and rap-be can share frombkgroup topic as both of them are on the same machine
 * Tobkgrou = send message to ring to other devices - for example rap-be sends broadcasted message to ORBps via this topic
 */
export enum RingTopic {
	BumpBar = 'bumpbar',
	BkMessages = 'bk_messages',
	FromGroup = 'frombkgroup',
	ToGroup = 'tobkgroup',
	SendNFCMessages = 'sendnfcmessages',
	ScanMessages = 'scan_messages',

	// Hopefully will be never
	Unknown = 'UNKNOW',
}
