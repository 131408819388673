import { BKOrderSourceEnum } from '@bk/jscommondatas';

import { IBKDeliveryOptions } from '.';

/**
 * Subset of BKORBOrder used to avoid dependency on bk-js-lib
 */
export interface BKOrbRapOrder {
	source: BKOrderSourceEnum;
	deliveryOptions: IBKDeliveryOptions | undefined;
	orb: number;
	orderUUID: string;
}
