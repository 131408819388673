/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type Brand = 'bkfr' | 'bkre' | 'qrfr' | 'bcfr' | 'hdfr' | 'cofr' | 'pmfr' | 'nffr' | 'cmre' | 'mqsr';

export const Brand = {
    Bkfr: 'bkfr' as Brand,
    Bkre: 'bkre' as Brand,
    Qrfr: 'qrfr' as Brand,
    Bcfr: 'bcfr' as Brand,
    Hdfr: 'hdfr' as Brand,
    Cofr: 'cofr' as Brand,
    Pmfr: 'pmfr' as Brand,
    Nffr: 'nffr' as Brand,
    Cmre: 'cmre' as Brand,
    Mqsr: 'mqsr' as Brand
};

