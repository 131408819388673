import { getTruthyEnumMapKeys } from '@merim/utils';

import { SalesChannelConfiguration } from '../../types/sales-channel-configuration';

export function hasSameSalesChannelOptions(orbpDetailConfig: SalesChannelConfiguration, masterOrbpConfig: SalesChannelConfiguration): boolean {
	// sanity check
	if (orbpDetailConfig.salesChannel !== masterOrbpConfig.salesChannel) {
		return false;
	}

	const orpChannelOptions = getTruthyEnumMapKeys(orbpDetailConfig.configuration).sort().join();
	const masterChannelOptions = getTruthyEnumMapKeys(masterOrbpConfig.configuration).sort().join();

	return orpChannelOptions === masterChannelOptions;
}
