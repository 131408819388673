/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type MediaExtension = 'jpg' | 'jpeg' | 'png' | 'mp4' | 'html' | 'zip';

export const MediaExtension = {
    Jpg: 'jpg' as MediaExtension,
    Jpeg: 'jpeg' as MediaExtension,
    Png: 'png' as MediaExtension,
    Mp4: 'mp4' as MediaExtension,
    Html: 'html' as MediaExtension,
    Zip: 'zip' as MediaExtension
};

