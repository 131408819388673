import { clone } from 'ramda';
import { IUserBasic } from '../interfaces/devices';
import { DEVICES_USER_ROLES } from './enums';

export class UsersModel implements IUserBasic {
	id: number;
	login: string;

	name: string;
	surname: string;
	manager: boolean;
	roles: DEVICES_USER_ROLES;

	// do we really need these params?
	rightHanded?: boolean;
	_iCard?: string;
	rhId?: string;
	code?: string;
	schoolMode?: boolean;

	constructor(init?: Partial<UsersModel>) {
		Object.assign(this, clone(init));
	}
}
