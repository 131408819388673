// Based on https://stackoverflow.com/a/61779339/552194
// eslint-disable-next-line @typescript-eslint/ban-types
export function toQueryString(obj: Object): string {
	return Object.entries(obj).reduce((acc, [key, val]) => {
		if (Array.isArray(val)) {
			val.forEach((e) => (acc += (acc ? '&' : '') + key + '=' + e));
		} else {
			acc += (acc ? '&' : '') + key + '=' + val;
		}
		return acc;
	}, '');
}
