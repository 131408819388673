import {
	BKDeliveryModeEnum,
	BKPatternUtilities,
	IBKItemInOrderBase,
	IBKProductBase,
	IBKProductFamilyData,
	ICsiMenuInOrder,
	ICsiProductInOrder,
	ICsiProductProcessedIngredient,
} from '@bk/jscommondatas';
import { LocalPriceDefinition, ProductPriceType } from '@bk/price-management-common';
import { CONFIGURATION_DOWNLOAD_TYPES, IBKBigData, RemoteConfigs } from '@libs/shared/models';
import { getNewPriceManagementPriceInfo, isGlobalPriceDefinitionValid } from '@libs/shared/utils';

export function mapMenuToCsiMenuInOrder(data: IBKItemInOrderBase, bigData: IBKBigData): ICsiMenuInOrder {
	const bigDataMenu = bigData.menus?.[data.id];

	if (!bigDataMenu) {
		return null;
	}

	const csiMenuInOrder: ICsiMenuInOrder = {
		sysName: data.sysName,
		shortName: data.shortName,
		campaignItem: data.campaignItem,
		name: data.name,
		available: data.available,
		id: data.id,
		xtag: data.xtag,
		noKiosk: data.noKiosk,
		noWeb: data.noWeb,
		line: data.line,
		lineuuid: data.lineuuid,
		timestamp: data.timestamp,
		freeItems: data.freeItems,
		selection: data.selection,
		itemAppliedDiscount: data.itemAppliedDiscount,
		itemInvolvedDiscount: data.itemInvolvedDiscount,
		ref: data.ref,
		qty: data.qty,
		price: data.price,
		aLaCartePrice: data.aLaCartePrice,
		extraPrice: data.extraPrice,

		large: data?.large,
		relatedGame: data?.relatedGame,
		assemblyAreaId: data?.assemblyAreaId,
		_kidsMenu: data._kidsMenu,
		_boMenuType: data._boMenuType,
		priceExtraMenuTTC: data?.priceExtraMenuTTC,
		productGroupName: data?.productGroupName,
		user: data?.user,
		manager: data?.manager,
		machine: data?.machine,
		_altName: data?._altName,
		inActiveCampaign: data?.inActiveCampaign,
		nonFood: data?.nonFood,
		exclusiveToFidelity: data?.exclusiveToFidelity,
		requireValidation: data?.requireValidation,
		_weight: data?._weight,

		_isBundle: bigDataMenu._isBundle,
		_existsInL: bigDataMenu._existsInL,
		_refM: { ...bigDataMenu._refM },
		_refL: { ...bigDataMenu._refL },
		_steps: bigDataMenu._steps,
		_name: bigDataMenu._name,
		_description: bigDataMenu._description,
		_indexPOS: bigDataMenu._indexPOS,
		_indexKiosk: bigDataMenu._indexKiosk,

		_productGroup: bigData.productGroups?.[bigDataMenu?._groupId] || null,
		_productGroupL: bigData.productGroups?.[bigDataMenu?._groupIdL] || null,
		_crown: {
			crownEnable: false,
			crown: 0,
			crownFamily: 0,
		},
		globalPriceDefinitions: bigDataMenu.globalPriceDefinitions,
	};
	return csiMenuInOrder;
}

export function mapProductToCsiProductInOrder(
	data: IBKItemInOrderBase,
	deliveryMode: BKDeliveryModeEnum,
	remoteConfigs: RemoteConfigs
): ICsiProductInOrder {
	const bigDataProduct: IBKProductBase = remoteConfigs?.[CONFIGURATION_DOWNLOAD_TYPES.BIG_DATA]?.products?.[data.id];
	const localProductPrice: LocalPriceDefinition =
		remoteConfigs?.[CONFIGURATION_DOWNLOAD_TYPES.AVAILABILITY]?.priceDefinitions.product?.[data.id];

	if (!remoteConfigs?.[CONFIGURATION_DOWNLOAD_TYPES.BIG_DATA]) {
		return null;
	}

	const csiProductInOrder: ICsiProductInOrder = {
		sysName: data.sysName,
		shortName: data.shortName,
		campaignItem: data.campaignItem,
		name: data.name,
		available: data.available,
		id: data.id,
		xtag: data.xtag,
		noKiosk: data.noKiosk,
		noWeb: data.noWeb,
		line: data.line,
		lineuuid: data.lineuuid,
		timestamp: data.timestamp,
		freeItems: data.freeItems,
		itemAppliedDiscount: data.itemAppliedDiscount,
		itemInvolvedDiscount: data.itemInvolvedDiscount,
		ref: data.ref,
		qty: data.qty,
		price: data.price,
		aLaCartePrice: data.aLaCartePrice,
		extraPrice: data.extraPrice,
		large: data?.large,
		relatedGame: data?.relatedGame,
		assemblyAreaId: data?.assemblyAreaId,
		familyId: data?.familyId,
		productGroupName: data?.productGroupName,
		user: data?.user,
		manager: data?.manager,
		machine: data?.machine,
		_altName: data?._altName,
		inActiveCampaign: data?.inActiveCampaign,
		nonFood: data?.nonFood,
		exclusiveToFidelity: data?.exclusiveToFidelity,
		requireValidation: data?.requireValidation,
		_weight: data?._weight,
		noIce: data?.noIce,
		later: data?.later,
		recipe: data?.recipe,
		removedRecipeModificatorsBeforeKitchenScreen: data?.removedRecipeModificatorsBeforeKitchenScreen,
		removedRecipeModificatorsAfterSubtotal: data?.removedRecipeModificatorsAfterSubtotal,
		removedRecipeModificatorsAfterKitchenScreen: data?.removedRecipeModificatorsAfterKitchenScreen,
		_familyId: data.familyId,

		priceExtraMenuTTC: data?.priceExtraMenuTTC ?? bigDataProduct?._priceExtraMenuTTC,
		priceSuggestionMenuTTC: data?.priceSuggestionMenuTTC ?? bigDataProduct?._priceSuggestionMenuTTC,
		_xallergen: bigDataProduct?._xallergen || [],
		_upperSizeProduct: bigDataProduct?._upperSizeProduct || 0,
		_configurable: bigDataProduct?._configurable || false,
		_displayInKitchen: bigDataProduct?._displayInKitchen || false,
		_refM: bigDataProduct._refM,
		_crown: {
			crownEnable: false,
			crown: 0,
			crownFamily: 0,
		},
		_canDeliverLater: bigDataProduct._canDeliverLater,
		_ingredients: bigDataProduct._ingredients,
		_hasIce: bigDataProduct._hasIce,
		_name: bigDataProduct._name,
		_description: bigDataProduct._description,
		_indexPOS: bigDataProduct._indexPOS,
		_indexKiosk: bigDataProduct._indexKiosk,

		_productGroup: remoteConfigs?.[CONFIGURATION_DOWNLOAD_TYPES.BIG_DATA]?.productGroups?.[bigDataProduct?._groupId] || null,
		_productFamily: null,
		_productSubFamily: null,
		_processedIngredients: getProcessedIngredients(bigDataProduct, remoteConfigs?.[CONFIGURATION_DOWNLOAD_TYPES.BIG_DATA]),
		_excludeFromGlobalDiscount: bigDataProduct._excludeFromGlobalDiscount,
		globalPriceDefinitions: bigDataProduct.globalPriceDefinitions,
	};

	// assign product family values
	const familyProductData: IBKProductFamilyData =
		remoteConfigs?.[CONFIGURATION_DOWNLOAD_TYPES.BIG_DATA]?.productFamilies?.[bigDataProduct?._familyId] || null;
	if (familyProductData) {
		if (familyProductData?.parentFamilyId <= 0) {
			csiProductInOrder._productFamily = familyProductData;
			csiProductInOrder._productSubFamily = null;
		} else {
			csiProductInOrder._productSubFamily = familyProductData;
			csiProductInOrder._productFamily = getRootFamily(familyProductData, remoteConfigs?.[CONFIGURATION_DOWNLOAD_TYPES.BIG_DATA]);
		}
	}

	return csiProductInOrder;
}

// helper functions
//TODO: create test for getRootFamily function
function getRootFamily(prodFamilyData: IBKProductFamilyData, bigData: IBKBigData): IBKProductFamilyData {
	if (!prodFamilyData) {
		return null;
	}

	let p: IBKProductFamilyData = prodFamilyData;
	while (p.parentFamilyId >= 0) {
		p = bigData?.productFamilies?.[p.parentFamilyId] || null;
		if (p === null) {
			return null;
		}
	}
	return p;
}

// get processed ingredients for a product
// TODO: create test for getProcessedIngredients
function getProcessedIngredients(bigDataProduct: IBKProductBase, bigData: IBKBigData): ICsiProductProcessedIngredient[] {
	if (!bigData) {
		return [];
	}

	return bigDataProduct._ingredients.map((pioIngredient): ICsiProductProcessedIngredient => {
		const processedIngredient: ICsiProductProcessedIngredient = {
			_ingredient: pioIngredient,
			_defaultIngredient: null,
			_availableIngredients: [],
		};

		if (processedIngredient._ingredient.ingredientDefault > 0) {
			const bigDataIngredient = bigData.ingredients?.[processedIngredient._ingredient.ingredientDefault];
			processedIngredient._defaultIngredient = {
				...bigDataIngredient,
				_crown: {
					crownEnable: false,
					crown: 0,
					crownFamily: 0,
				},
				_productGroup: bigData?.productGroups?.[bigDataIngredient?._groupId] || null,
				_xtraces: bigDataIngredient?._xtraces || [],
				globalPriceDefinitions: bigDataIngredient?.globalPriceDefinitions || [],
				vatValues: bigDataIngredient?.vatValues || [],
			};
		}

		if (processedIngredient._ingredient.ingredientPattern?.length > 0) {
			BKPatternUtilities.filterIngredientsMulti(
				processedIngredient._ingredient.ingredientPattern,
				Object.values(bigData.ingredients),
				bigData.ingredients
			).forEach((availableIngredient) => {
				processedIngredient._availableIngredients.push({
					...availableIngredient,
					_crown: {
						crownEnable: false,
						crown: 0,
						crownFamily: 0,
					},
					_productGroup: bigData?.productGroups?.[availableIngredient?._groupId] || null,
				});
			});
		} else {
			processedIngredient._availableIngredients = [processedIngredient._defaultIngredient];
		}

		return processedIngredient;
	});
}
