/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ConfigurationRestaurantLanguage } from './configuration-restaurant-language';


export interface ConfigurationRestaurantLanguages { 
    /**
     * Default language code that should be used as first.
     */
    _default?: string;
    /**
     * List of supported languages in the order of importance to serve as a fallback in case of missing localizations.
     */
    orderedList?: Array<ConfigurationRestaurantLanguage>;
}

