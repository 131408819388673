import { OrbpRole } from '../enums';
import { LoadBalancingConfiguration, LoadBalancingConfigurations } from '../types';
import { LoadBalancingRole } from './load-balancing-role';

export function getLoadBalancingRole(orbpId: string, orbpConfigurations: LoadBalancingConfigurations): LoadBalancingRole {
	const configuration: LoadBalancingConfiguration = orbpConfigurations[orbpId];

	switch (configuration.role) {
		case OrbpRole.Master:
			return 'Master';
		case OrbpRole.Dependent:
			return 'Dependent';
		case OrbpRole.Mirror: {
			const masterConfiguration = orbpConfigurations[configuration.masterIds[0]];
			const masterRole = masterConfiguration.role || OrbpRole.Unknown;
			switch (masterRole) {
				case OrbpRole.Master:
					return 'MirrorOfMaster';
				case OrbpRole.Dependent:
					return 'MirrorOfDependent';
				default: {
					const errMessage = `getLoadBalancingRole(): Unexpected value of mirroredMastersRole: ${masterRole}`;
					console.error(errMessage);
					throw new Error(errMessage);
				}
			}
		}

		default: {
			const errMessage = `getLoadBalancingRole(): Unexpected value of role: ${configuration.role}`;
			console.error(errMessage);
			throw new Error(errMessage);
		}
	}
}
