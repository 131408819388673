/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface FidelityDto { 
    provider: FidelityDto.ProviderEnum;
    /**
     * Minimal length of a customer\'s fidelity code to be input on POS/KIOSK.
     */
    customerCodeLengthMin: number;
    /**
     * Maximal length of a customer\'s fidelity code to be input on POS/KIOSK.
     */
    customerCodeLengthMax: number;
}
export namespace FidelityDto {
    export type ProviderEnum = 'eneance' | 'splio';
    export const ProviderEnum = {
        Eneance: 'eneance' as ProviderEnum,
        Splio: 'splio' as ProviderEnum
    };
}


