/**
 * DKI-BE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DkiBeClickhouseAnalyticsDailySalesReportOrders } from './dki-be-clickhouse-analytics-daily-sales-report-orders';


export interface DkiBeClickhouseAnalyticsDailySalesReportOrdersByChannel {
	/**
	 * Order statistics by sales channel
	 */
	by_channel: { [key: string]: DkiBeClickhouseAnalyticsDailySalesReportOrders; };
	/**
	 * Order statistics for all sales channels in total
	 */
	total: DkiBeClickhouseAnalyticsDailySalesReportOrders;
}

