/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrdersDto } from './orders-dto';
import { ProductsConfigurationDto } from './products-configuration-dto';
import { FiscalDto } from './fiscal-dto';
import { ProductListDto } from './product-list-dto';
import { FidelityDto } from './fidelity-dto';
import { RestaurantDto } from './restaurant-dto';
import { WebordersDto } from './weborders-dto';
import { LegalDto } from './legal-dto';
import { FeaturesDto } from './features-dto';
import { LanguagesDto } from './languages-dto';


export interface ConfigurationGlobalDto { 
    brand: ConfigurationGlobalDto.BrandEnum;
    environment: ConfigurationGlobalDto.EnvironmentEnum;
    /**
     * Basic information about restaurant.
     */
    restaurant: RestaurantDto;
    /**
     * All the company\'s legal information. Required when Plutus fiscal core is in use.
     */
    legal?: LegalDto;
    /**
     * All the company\'s fiscal information.
     */
    fiscal: FiscalDto;
    /**
     * Restaurant\'s fidelity configuration.
     */
    fidelity?: FidelityDto;
    /**
     * Details on features in restaurant.
     */
    features: FeaturesDto;
    /**
     * Languages available for customer facing applications.
     */
    languages: LanguagesDto;
    /**
     * Configuration of orders.
     */
    orders: OrdersDto;
    /**
     * Configuration for weborders.
     */
    weborders: WebordersDto;
    /**
     * Configuration for product list (ingredients, product, menus, categories).
     */
    productList: ProductListDto;
    /**
     * Deprecated, use dynamic \'productList\' instead.
     */
    products?: ProductsConfigurationDto;
}
export namespace ConfigurationGlobalDto {
    export type BrandEnum = 'bkfr' | 'bkre' | 'qrfr' | 'bcfr' | 'hdfr' | 'cofr' | 'pmfr' | 'nffr' | 'cmre' | 'mqsr';
    export const BrandEnum = {
        Bkfr: 'bkfr' as BrandEnum,
        Bkre: 'bkre' as BrandEnum,
        Qrfr: 'qrfr' as BrandEnum,
        Bcfr: 'bcfr' as BrandEnum,
        Hdfr: 'hdfr' as BrandEnum,
        Cofr: 'cofr' as BrandEnum,
        Pmfr: 'pmfr' as BrandEnum,
        Nffr: 'nffr' as BrandEnum,
        Cmre: 'cmre' as BrandEnum,
        Mqsr: 'mqsr' as BrandEnum
    };
    export type EnvironmentEnum = 'local' | 'dev' | 'test' | 'prod';
    export const EnvironmentEnum = {
        Local: 'local' as EnvironmentEnum,
        Dev: 'dev' as EnvironmentEnum,
        Test: 'test' as EnvironmentEnum,
        Prod: 'prod' as EnvironmentEnum
    };
}


