/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ApiAccessTokenDto { 
    /**
     * JWT access token.
     */
    accessToken: string;
    /**
     * Access token validity in seconds.
     */
    expiresIn: number;
    /**
     * Access token type.
     */
    tokenType: string;
}

