import { getTruthyEnumMapKeys } from '@merim/utils';

import { SalesChannelConfiguration } from '../../types/sales-channel-configuration';

/**
 * SalesChannel is considered turned ON, when it has at least 1 SalesChannelOptions turned ON
 */
export function isSalesChannelOn(config: SalesChannelConfiguration): boolean {
	const {configuration} = config;
	return getTruthyEnumMapKeys(configuration).length > 0;
}
