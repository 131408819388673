/**
 * Stupid name.
 * This actually means "Delivery Company"
 * The "type of delivery" is the same - some driver will deliver package to customer
 */
export enum BKTypePreparationDelivery {
	UNKNOWN = 0,
	DELIVEROO = 1,
	UBER_EATS = 2,
	JUST_EAT = 3,
	ECOCEA = 4,
	BK_DELIVERY = 5,
	NO_DELIVERY = 6,
}
