/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type VatRateName = 'Normal' | 'Alcohol' | 'Reduced' | 'NoVAT';

export const VatRateName = {
    Normal: 'Normal' as VatRateName,
    Alcohol: 'Alcohol' as VatRateName,
    Reduced: 'Reduced' as VatRateName,
    NoVat: 'NoVAT' as VatRateName
};

