import { SalesChannelOption } from '@bk/price-management-common';
import { EnumMap } from '@merim/utils';
import { ConfigurationOfSalesChannelOptionsDependency } from './configuration-of-sales-channel-options-dependency';

// Validator function
export function canEnableSalesChannelOption(option: SalesChannelOption, currentConfiguration: EnumMap): boolean {
	const matchingDependency = ConfigurationOfSalesChannelOptionsDependency.find(x => x.optionName === option);

	if (!matchingDependency) {
		// nothing to do, keep the value as it is
		return true;
	}

	// User can only enable the option if the "master option" is enabled
	const isMasterEnabled: boolean = currentConfiguration[matchingDependency.dependsOn];
	return isMasterEnabled;
}
