/**
 * DKI-BE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Channel } from './channel';


export interface DkiBeClickhouseAnalyticsChannelOrders {
	/**
	 * Sales channel
	 */
	channel: Channel;
	/**
	 * Total count of orders.
	 */
	count: number;
	/**
	 * Total value of orders for this channel (EUR).
	 */
	value: number;
	/**
	 * Estimated mean difference between the creation of order and its fulfillment (s).
	 */
	mean_service_time: number;
}

