import { inject, Injectable, InjectionToken } from '@angular/core';
import { CanActivate, RouterStateSnapshot } from '@angular/router';
import { iFrameDetection } from '../functions';
import { PermissionGuardDataModel } from '../models';

@Injectable({
	providedIn: 'root'
})
// tslint:disable-next-line:naming-convention
export class ApiPermissionGuard {

	static canActivateWithRoles(authService: any) {
		return new InjectionToken<CanActivate>('AccessGuardWithPermissions', {
			providedIn: 'root',
			factory: () => {
				const authorizationService = inject(authService) as any;

				return {
					canActivate(next: PermissionGuardDataModel, state: RouterStateSnapshot) {
						const minimalPermissionsRequired = next.data.minimalPermissionsRequired;
						const resource = next.data.resource;

						if (!authorizationService.hasPermission(resource, minimalPermissionsRequired)) {
							if (authorizationService.showUnauthorizedMessage) {
								authorizationService.showUnauthorizedMessage();
							}

							if (!iFrameDetection()) {
								return authorizationService.toDefaultRoute();
							} else {
								return authorizationService.toUnauthorizedRoute();
							}

						}

						return true;
					}
				};
			}
		});
	}
}
