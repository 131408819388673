import { computed, effect, Injectable, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
	BKDeliveryModeEnum,
	BKOrderSourceEnum,
	IBKCampaignAllPrices,
	IBKIngredientData,
	IBKMenuBase,
	IBKProductBase,
} from '@bk/jscommondatas';
import { mapDeliveryModeToSalesChannelOption, MenuSize, ProductPriceType, SalesChannelOption } from '@bk/price-management-common';
import { AVAILABLE_JSON, IBKBigData, NAV_SCREEN_ITEM_TYPES } from '../models';
import { ConfigurationFacade } from '../store/configuration/src/public_api';
import { OrderFacade } from '../store/order/src/public_api';
import {
	getActiveCampaignsContents,
	getGlobalPriceDefinitionPrice,
	getIngredientPrice,
	getItemDayPartPrice,
	getLocalPriceDefinitionPrice,
	getMenuPrice,
	getProductPrice,
} from '../utils';

@Injectable({ providedIn: 'root' })
export class PriceService {
	private deliveryModeSig: Signal<BKDeliveryModeEnum>;
	private orderSourceSig: Signal<BKOrderSourceEnum>;
	private salesChannelSig: Signal<SalesChannelOption>;
	private availabilitySig: Signal<AVAILABLE_JSON>;
	private bigDataSig: Signal<IBKBigData>;

	private campaignDayPartPrices: IBKCampaignAllPrices;

	constructor(
		private readonly orderFacade: OrderFacade,
		private readonly configurationFacade: ConfigurationFacade
	) {
		this.deliveryModeSig = toSignal(this.orderFacade.getOrderDeliveryMode());
		this.orderSourceSig = toSignal(this.orderFacade.getOrderSource());
		this.salesChannelSig = computed(() => mapDeliveryModeToSalesChannelOption(this.deliveryModeSig()));
		this.availabilitySig = toSignal(this.configurationFacade.getAvailability());
		this.bigDataSig = toSignal(this.configurationFacade.getBigData());

		effect(() => {
			if (this.bigDataSig() && this.availabilitySig() && this.orderSourceSig()) {
				this.campaignDayPartPrices = getActiveCampaignsContents(
					this.bigDataSig(),
					this.availabilitySig(),
					this.orderSourceSig()
				)?.daypartPrices;
			}
		});
	}

	getProductPrice(product: IBKProductBase): number {
		const productPrice: number = getProductPrice(product, this.salesChannelSig(), this.availabilitySig());
		const productDayPartPrice: number = getItemDayPartPrice(
			product.id,
			productPrice,
			this.campaignDayPartPrices.active,
			this.campaignDayPartPrices.products
		);
		return productDayPartPrice ?? productPrice;
	}

	getMenuPrice(menu: IBKMenuBase, menuSize: MenuSize): number {
		const menuPrice: number = getMenuPrice(menu, this.salesChannelSig(), menuSize, this.availabilitySig());
		const menuDayPartPrice: number = getItemDayPartPrice(
			menu.id,
			menuPrice,
			this.campaignDayPartPrices.active,
			this.campaignDayPartPrices.menus,
			menuSize === MenuSize.L
		);
		return menuDayPartPrice ?? menuPrice;
	}

	getIngredientPrice(item: IBKIngredientData): number {
		if (!item) {
			return 0;
		}

		const ingredientPrice: number = getIngredientPrice(item, this.salesChannelSig(), this.availabilitySig());
		const ingredientDayPartPrice: number = getItemDayPartPrice(
			item.id,
			ingredientPrice,
			this.campaignDayPartPrices.active,
			this.campaignDayPartPrices.ingredients
		);

		return ingredientDayPartPrice || ingredientPrice || 0;
	}

	getMenuSuggestionPrice(item: IBKProductBase): number {
		const localPrice: number = getLocalPriceDefinitionPrice(
			item,
			this.deliveryModeSig(),
			ProductPriceType.InSuggestion,
			this.availabilitySig()
		);
		const suggestionPrice: number = getGlobalPriceDefinitionPrice(item, this.deliveryModeSig(), ProductPriceType.InSuggestion);
		return localPrice ?? suggestionPrice ?? (item._priceSuggestionMenuTTC || undefined);
	}

	getMenuSuplementPrice(item: IBKProductBase): number {
		const localPrice: number = getLocalPriceDefinitionPrice(
			item,
			this.deliveryModeSig(),
			ProductPriceType.SupplementMenu,
			this.availabilitySig()
		);
		const supplementPrice: number = getGlobalPriceDefinitionPrice(item, this.deliveryModeSig(), ProductPriceType.SupplementMenu);
		const itemsDayPartSupplementPrice: number = getItemDayPartPrice(
			item.id,
			localPrice ?? supplementPrice ?? (item._priceExtraMenuTTC || undefined),
			this.campaignDayPartPrices.active,
			this.campaignDayPartPrices.productsExtras
		);

		return itemsDayPartSupplementPrice ?? localPrice ?? supplementPrice ?? (item._priceExtraMenuTTC || undefined);
	}

	getNavScreenItemPrice(item: IBKProductBase | IBKMenuBase, itemType: NAV_SCREEN_ITEM_TYPES): number {
		const defaultPriceValue = item._priceM.ttc ?? 0;

		let globalPrice: number = item?.globalPriceDefinitions?.find((channel) => {
			return (
				channel.salesChannelOption === this.salesChannelSig() &&
				channel?.price &&
				!channel.isDisabled &&
				(channel.rowIdentifier === ProductPriceType.Solo || channel.rowIdentifier === MenuSize.M)
			);
		})?.price;

		let localPrice: number = this.getNavScreenItemLocalPrice(item, itemType);

		let dayPartPrice: number = this.getNavScreenDayPartPrice(item, localPrice ?? globalPrice, itemType);

		if (globalPrice < 0) {
			console.log(
				`[APP] Price calculation: Sales channel price for item ${item.sysName} (id: ${item.id}) is not available. Default price will be displayed.`
			);
			return defaultPriceValue;
		}

		return dayPartPrice ?? localPrice ?? globalPrice;
	}

	private getNavScreenItemLocalPrice(item: IBKProductBase | IBKMenuBase, itemType: NAV_SCREEN_ITEM_TYPES): number {
		const localItemPriceDefinition = getLocalPriceDefinitionPrice(
			item,
			this.deliveryModeSig(),
			itemType === NAV_SCREEN_ITEM_TYPES.MENU ? MenuSize.M : ProductPriceType.Solo,
			this.availabilitySig()
		);
		return localItemPriceDefinition && localItemPriceDefinition >= 0 ? localItemPriceDefinition : undefined;
	}

	private getNavScreenDayPartPrice(item: IBKProductBase | IBKMenuBase, currentItemPrice: number, itemType: NAV_SCREEN_ITEM_TYPES): number {
		let dayPartPrice: number = undefined;

		switch (itemType) {
			case NAV_SCREEN_ITEM_TYPES.PRODUCT:
				dayPartPrice = getItemDayPartPrice(
					item.id,
					currentItemPrice,
					this.campaignDayPartPrices.active,
					this.campaignDayPartPrices.products
				);
				break;
			case NAV_SCREEN_ITEM_TYPES.MENU:
				dayPartPrice = getItemDayPartPrice(item.id, currentItemPrice, this.campaignDayPartPrices.active, this.campaignDayPartPrices.menus);
				break;
		}
		return dayPartPrice && dayPartPrice >= 0 ? dayPartPrice : undefined;
	}
}
