/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DssTableConfigurationDto { 
    /**
     * List of allowed sales channels.
     */
    allowedSalesChannels: Array<DssTableConfigurationDto.AllowedSalesChannelsEnum>;
    /**
     * List of allowed food categories.
     */
    allowedFoodCategories: Array<string>;
    /**
     * Configuration for RAP (FE) to disable form fields with matching property names. Beware. Renamed properties will not be automatically updated.
     */
    disabledOrbpProperties: Array<string>;
}
export namespace DssTableConfigurationDto {
    export type AllowedSalesChannelsEnum = 'UNKNOWN' | 'CASH_MACHINE' | 'KIOSK' | 'DRIVE_WINDOW' | 'WALK' | 'CLICK_AND_COLLECT' | 'HOME_DELIVERY' | 'THIRD_PARTY_DELIVERY';
    export const AllowedSalesChannelsEnum = {
        Unknown: 'UNKNOWN' as AllowedSalesChannelsEnum,
        CashMachine: 'CASH_MACHINE' as AllowedSalesChannelsEnum,
        Kiosk: 'KIOSK' as AllowedSalesChannelsEnum,
        DriveWindow: 'DRIVE_WINDOW' as AllowedSalesChannelsEnum,
        Walk: 'WALK' as AllowedSalesChannelsEnum,
        ClickAndCollect: 'CLICK_AND_COLLECT' as AllowedSalesChannelsEnum,
        HomeDelivery: 'HOME_DELIVERY' as AllowedSalesChannelsEnum,
        ThirdPartyDelivery: 'THIRD_PARTY_DELIVERY' as AllowedSalesChannelsEnum
    };
}


