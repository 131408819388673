/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { KitchenScreenConfigDataDto } from './kitchen-screen-config-data-dto';
import { SelectionPatternDto } from './selection-pattern-dto';


export interface KitchenConfigsDto { 
    /**
     * Id of the item reference in the db.
     */
    id: number;
    /**
     * Name of the config.
     */
    name: string;
    /**
     * Description of this config.
     */
    description: string;
    /**
     * Color.
     */
    colorordersource_kiosk: string;
    /**
     * Color.
     */
    colorordersource_cash_machine: string;
    /**
     * Color.
     */
    colorordersource_drive: string;
    /**
     * Color.
     */
    colorordersource_website: string;
    /**
     * If true, show the right column.
     */
    showRightColumn: boolean;
    /**
     * Time at wich an alert is raised in ms
     */
    alerttimer: number;
    /**
     * number of info to sumup
     */
    infostosumup: number;
    /**
     * Ingredient selection pattern
     */
    screens: Array<KitchenScreenConfigDataDto>;
    /**
     * Pattern selecting to which restaurant this config is applicable to
     */
    applicableToPattern: Array<SelectionPatternDto>;
}

