import { GlobalPriceDefinition, VATValue } from '@bk/price-management-common';

import { ItemPrice } from './item-price';

export type Menu = {
	sysName: string;
	shortName: string;
	_altName: string;
	_familyId: number;
	_name: {
		en: string;
		fr: string;
	};
	_description: {
		en: string;
		fr: string;
	};
	id: number;
	_refM: {
		plu: string;
		bkpn: string;
		bkcName: string;
	};
	_refL: {
		plu: string;
		bkpn: string;
		bkcName: string;
	};
	// Legacy
	_priceM: ItemPrice;
	// Legacy
	_priceL: ItemPrice;

	/**
	 * Used in conjuction with the new priceManagement in GAP/RAP.
	 * Menu is disabled when the price is disabled for currently selected SalesChannelOption and
	 * other options such as "Large" or "Medium".
	 */
	isDisabled: boolean;

	/**
	 * Present only when the new PriceManagement is enabled. Otherwise is undefined.
	 */
	globalPriceDefinitions: GlobalPriceDefinition[];
	/**
	 * Present only when the new PriceManagement is enabled. Otherwise is undefined.
	 */
	vatValues: VATValue[];
};
