// see http://192.168.100.200:8080/server/bkGetAvailability.php?bo=192.168.100.200:81 for more details

// BEWARE OF NAMING INCONSISTENCY. THE FILE IS CALLED available.json, BUT THE API IS bkGetAvailability
// What a wonderful world.

import { AvailabilityMap } from '../availability-map';
import { PriceMap } from '../price-map';

/**
 * Type for available.json
 *
 * FYI: There is also legacy type definition BKProductAvailability.ts in bk-js-lib, with a lot of additional functions and inheritance baked in.
 */
export class AvailableJsonPrice {
	products: PriceMap;

	menus: PriceMap;

	menusL: PriceMap;

	ingredients: PriceMap;
}

export class AvailableJson {
	timestamp: number;

	availability: AvailabilityMap;

	productActive: AvailabilityMap;

	/** Legacy prices */
	price: AvailableJsonPrice;

	/** New PriceManagement schema */
		// TODO update price definition type

	priceDefinitions: any;

	campaignAvailability: AvailabilityMap;

	// I am not sure what this is. It was empty in our Lab Restaurant

	ingredientAvailability: AvailabilityMap;

	ingredientActive: AvailabilityMap;

	// These properties are not yet used in RAP, so I just kept them as 'any'

	kitchenRedirection: any;

	menuExtraPrices: PriceMap;

	suggestionMenuPrices: any; // was empty. Most likely this is PriceMap

	daypartPrices: any;

	campaignFilters: any;

	campaignMediasFilters: any;
}

export const availableJsonWithDefaultValues = {
	timestamp: 0,
	availability: {},
	productActive: {},
	price: {
		products: {},
		menus: {},
		menusL: {},
		ingredients: {}
	},
	priceDefinitions: {
		product: {},
		menu: {},
		ingredient: {}
	},
	campaignAvailability: {},
	ingredientAvailability: {},
	ingredientActive: {},
	kitchenRedirection: undefined,
	menuExtraPrices: {},
	suggestionMenuPrices: undefined,
	daypartPrices: undefined,
	campaignFilters: undefined,
	campaignMediasFilters: undefined
} as AvailableJson;
