// see http://192.168.100.200:8080/server/bkGetAvailability.php?bo=192.168.100.200:81 for more details

// BEWARE OF NAMING INCONSISTENCY. THE FILE IS CALLED available.json, BUT THE API IS bkGetAvailability
// What a wonderful world.

import { IBKCampaignLocalPricesData } from '@bk/jscommondatas';
import { LocalPriceDefinitionSchema } from '@bk/price-management-common';
import { AvailabilityMap, PriceMap } from '../fullstack-common';

/**
 * Type for available.json
 *
 * FYI: There is also legacy type definition BKProductAvailability.ts in bk-js-lib, with a lot of additional functions and inheritance baked in.
 */
export type AVAILABLE_JSON = {
	timestamp: number;
	availability: AvailabilityMap;
	productActive: AvailabilityMap;

	/** Legacy prices */
	price: {
		products: PriceMap;
		menus: PriceMap;
		menusL: PriceMap;
		ingredients: PriceMap;
	};

	/** New PriceManagement schema */
	priceDefinitions: LocalPriceDefinitionSchema;

	campaignAvailability: AvailabilityMap;
	// I am not sure what this is. It was empty in our Lab Restaurant
	ingredientAvailability: AvailabilityMap;
	ingredientActive: AvailabilityMap;

	// These properties are not yet used in RAP, so I just kept them as 'any'
	kitchenRedirection: any;
	menuExtraPrices: PriceMap;
	suggestionMenuPrices: any; // was empty. Most likely this is PriceMap
	daypartPrices: Record<string, IBKCampaignLocalPricesData>;
	campaignFilters: any;
	campaignMediasFilters: any;
};
