import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DateTime } from 'luxon';

@Component({
	selector: 'app-date-picker',
	templateUrl: './date-picker.component.html',
	styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent {
	@Output() dateSelection = new EventEmitter<{ from: DateTime; to: DateTime }>();
	@Input() initDate: { from: DateTime; to: DateTime } = { from: DateTime.now().startOf('week'), to: DateTime.now().endOf('week') };

	labels = [
		{ label: "Aujourd'hui", selected: false },
		{ label: 'Semaine', selected: false },
		{ label: 'Mois', selected: false },
		{ label: 'Année', selected: false },
	];

	range = new FormGroup({
		from: new FormControl(this.initDate.from.toJSDate()),
		to: new FormControl(this.initDate.to.toJSDate()),
	});

	dateSelected = false;

	myDate = this.getFormattedDateRange(this.initDate.from.toJSDate(), this.initDate.to.toJSDate());

	constructor() {}

	updateAndEmitDate(values) {
		this.dateSelected = true;
		this.myDate = this.getFormattedDateRange(values.from, values.to);
		this.dateSelection.emit({ from: DateTime.fromJSDate(values.from), to: DateTime.fromJSDate(values.to) });
	}

	toggleLabel(i) {
		if (!this.labels[i].selected) {
			this.labels[i].selected = !this.labels[i].selected;
			this.labels.forEach((element) => {
				if (element != this.labels[i]) element.selected = false;
			});
			this.changeDateSelection(this.labels[i].label);
		}
	}

	getFormattedDateRange(startDate, endDate) {
		const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
		const startDateFormatted = startDate ? startDate.toLocaleDateString('fr-FR', options) : '';
		const endDateFormatted = endDate ? endDate.toLocaleDateString('fr-FR', options) : '';
		if (startDateFormatted === endDateFormatted) return `${startDateFormatted}`;
		return `${startDateFormatted} - ${endDateFormatted}`;
	}

	changeDateSelection(label: string) {
		if (label === this.labels[0].label) {
			this.range.setValue({
				from: new Date(),
				to: new Date(),
			});
		} else if (label === this.labels[1].label) {
			this.range.setValue({
				from: DateTime.now().startOf('week').toJSDate(),
				to: DateTime.now().endOf('week').toJSDate(),
			});
		} else if (label === this.labels[2].label) {
			this.range.setValue({
				from: DateTime.now().startOf('month').toJSDate(),
				to: DateTime.now().endOf('month').toJSDate(),
			});
		} else if (label === this.labels[3].label) {
			this.range.setValue({
				from: DateTime.now().startOf('year').toJSDate(),
				to: DateTime.now().endOf('year').toJSDate(),
			});
		}
		this.updateAndEmitDate({
			from: this.range.value.from,
			to: this.range.value.to,
		});
	}

	openPicker(picker) {
		picker.open();
	}

	eraseSelectionAndApply(from, to) {
		console.log(from, to);
		this.labels.forEach((el) => (el.selected = false));
		this.updateAndEmitDate({
			from: DateTime.fromFormat(from, 'dd/mm/yyyy').toJSDate(),
			to: DateTime.fromFormat(to, 'dd/mm/yyyy').toJSDate(),
		});
	}
}
