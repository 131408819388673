import { Injectable, OnDestroy } from '@angular/core';
import { BKMediaTypeEnum, IBKIngredientData, IBKMediaData, IBKMenuBase, IBKNavigationScreenData, IBKProductBase } from '@bk/jscommondatas';
import { IMAGE_STORAGE, NavigationScreen } from '@libs/shared/models';
import { ConfigurationService } from 'libs/shared/services';
import { filter, Subject, takeUntil } from 'rxjs';

@Injectable()
export class FileStorageService implements OnDestroy {
	private storageUrl: string;

	private readonly ngUnsubscribe$: Subject<void> = new Subject<void>();

	constructor(private readonly configurationService: ConfigurationService<any>) {
		const configValues$ = this.configurationService.config$;
		configValues$
			.pipe(
				takeUntil(this.ngUnsubscribe$),
				filter((configData) => configData?.['API']?.[IMAGE_STORAGE])
			)
			.subscribe((configData) => {
				this.storageUrl = configData['API'][IMAGE_STORAGE].url;
				console.log(`FileStorageService: storageUrl updated to: ${this.storageUrl}`);
			});
	}

	getProductImage(product: IBKProductBase): string {
		if (!product._hasImageMain) {
			return '';
		}
		return `${this.storageUrl}/product/${product.id}/product_${product.id}.png`;
	}

	getMenuImage(menu: IBKMenuBase): string {
		if (!menu._hasImageMain) {
			return '';
		}
		return `${this.storageUrl}/menu/${menu.id}/menu_${menu.id}.png`;
	}

	getIngredientAltImage(ingredient: IBKIngredientData): string {
		if (!ingredient._hasImageAlt) {
			return '';
		}
		return `${this.storageUrl}/ingredient/${ingredient.id}/ingredientalt_${ingredient.id}.png`;
	}

	getKioskNavigationScreenImage(screen: IBKNavigationScreenData | NavigationScreen) {
		if (!screen._hasImageOn) {
			return '';
		}
		return `${this.storageUrl}/kiosknavigationscreen/${screen.id}/kiosknavigationscreenon_${screen.id}.png`;
	}

	getKioskNavigationScreenAltImage(screen: IBKNavigationScreenData | NavigationScreen) {
		if (!screen._hasImageAlt) {
			return '';
		}
		return `${this.storageUrl}/kiosknavigationscreen/${screen.id}/kiosknavigationscreenalt_${screen.id}.png`;
	}

	getMedia(media: IBKMediaData): string {
		const { id, mediaType } = media;

		switch (mediaType) {
			case BKMediaTypeEnum.IMAGE:
			case BKMediaTypeEnum.VIDEO:
				return `${this.storageUrl}/medias/${id}/media_${id}.bin`;

			case BKMediaTypeEnum.HTML5:
				return `${this.storageUrl}/medias/${id}/media_${id}/content.html`;

			case BKMediaTypeEnum.NONE:
			default:
				return '';
		}
	}

	/** @param url alternative source for medias */
	getMediaFromCache(media: IBKMediaData, url?: string): string {
		const { id, mediaType } = media;
		const urlBase = url ?? '';

		switch (mediaType) {
			case BKMediaTypeEnum.IMAGE:
			case BKMediaTypeEnum.VIDEO:
				return `${urlBase}/cache/images/common/medias/${id}/media_${id}.bin`;

			case BKMediaTypeEnum.HTML5:
				return `${urlBase}/cache/images/common/medias/${id}/media_${id}/content.html`;

			case BKMediaTypeEnum.NONE:
			default:
				return '';
		}
	}

	ngOnDestroy() {
		this.ngUnsubscribe$.next();
		this.ngUnsubscribe$.complete();
	}
}
