import { Signal } from '@angular/core';

export const PROGRAM_ACTIVATION_INTERVAL_SECONDS = 900;

export function getDateRange(dateOne: Date, dateTwo: Date): string[] {
	dateOne.setHours(0, 0, 0, 0);
	dateTwo.setHours(23, 59, 59, 59);
	return [dateOne.toISOString(), dateTwo.toISOString()];
}

export function getDateInCurrentYear(month: number, day: number) {
	const february = 2;
	const leapDay = 29;
	const currentYear = new Date().getFullYear();
	if (month && day) {
		let dateWithCurrentYear = new Date(currentYear, month - 1, day);
		// Handle February 29th in leap years
		const isLeapYear = new Date(currentYear, 1, 29).getDate() === 29;
		if (isLeapYear && month === february && day === leapDay) {
			dateWithCurrentYear = new Date(currentYear, 1, 28);
		}
		return dateWithCurrentYear.toISOString();
	} else {
		return new Date().toISOString();
	}
}

export function calculateSecondsUntilNextQuarterHour(): number {
	const currentDate = new Date();
	const minutes = currentDate.getMinutes();
	const seconds = currentDate.getSeconds();

	const passedSeconds = minutes * 60 + seconds;
	const remainingSeconds = PROGRAM_ACTIVATION_INTERVAL_SECONDS - (passedSeconds % PROGRAM_ACTIVATION_INTERVAL_SECONDS);

	return remainingSeconds < 0 ? remainingSeconds + PROGRAM_ACTIVATION_INTERVAL_SECONDS : remainingSeconds;
}

export function calculateTimeUntilNextQuarterHour(remainingSeconds: Signal<number>): string {
	const minutesString = Math.floor(remainingSeconds() / 60)
		.toString()
		.padStart(2, '0');
	const secondsString = (remainingSeconds() % 60).toString().padStart(2, '0');

	return `${minutesString}:${secondsString}`;
}
