import { Shar3dUtils } from './shar3d-utils';

/**
 * This class is used in order to both list and index
 */
export class Shar3dUtilsMappedArray<U> {
	private $list: U[] = [];

	/**
	 * List of the object
	 */
	public get list(): U[] {
		return this.$list;
	}

	private $map: Record<string | number, U> = {};

	/**
	 * Map of the object
	 */
	public get map(): { [id: string]: U } {
		return this.$map;
	}

	constructor() {
	}

	/**
	 * Create the map and list based on an object
	 */
	public static fromObject<U, V>(
		src: { [id: string]: V },
		newFunction: (v: V) => U,
		processFunction: ((u: U) => void) | null = null
	): Shar3dUtilsMappedArray<U> {
		const ma: Shar3dUtilsMappedArray<U> = new Shar3dUtilsMappedArray<U>();
		return ma.updateFromObject(src, newFunction, processFunction);
	}

	/**
	 * Create the map and list based on an array
	 * @param src
	 * @param newFunction
	 * @param keyer
	 * @param processFunction
	 */
	public static fromArray<U, V>(
		src: V[],
		newFunction: (v: V) => U,
		keyer: (u: U) => string | number,
		processFunction: ((u: U) => void) | null = null
	): Shar3dUtilsMappedArray<U> {
		const ma: Shar3dUtilsMappedArray<U> = new Shar3dUtilsMappedArray<U>();
		return ma.updateFromArray(src, newFunction, keyer, processFunction);
	}

	/**
	 * Shortcut for processing the list
	 */
	public forEach(cb: (u: U) => void) {
		this.$list.forEach(cb);
	}

	/**
	 * Unmap indexes
	 * @param keys
	 */
	public unmap(keys: string[] | number[]): U[] {
		const a: U[] = [];
		for (const key of keys) {
			if (Shar3dUtils.isDefined(this.$map[key])) {
				a.push(this.$map[key]);
			}
		}
		return a;
	}

	/**
	 * Update the map and list based on an object
	 */
	public updateFromObject<V>(src: { [id: string]: V }, newFunction: (v: V) => U, processFunction: ((u: U) => void) | null = null): Shar3dUtilsMappedArray<U> {
		this.$list = [];
		this.$map = {};
		//  Check for something to process
		if (Shar3dUtils.isUndefined(src) || src === null) {
			return this;
		}
		//  First the ingredients
		for (const iId in src) {
			const item = src[iId];
			const u: U = newFunction(item);
			//  Register the ingredients
			this.$map[iId] = u;
			this.$list.push(u);
			//  Check for a process
			if (processFunction !== null) {
				processFunction(u);
			}
		}
		return this;
	}

	/**
	 * Clean and update the internal content based on the provided array
	 * @param src
	 * @param newFunction
	 * @param keyer
	 * @param processFunction
	 */
	public updateFromArray<V>(
		src: V[],
		newFunction: (v: V) => U,
		keyer: (u: U) => string | number,
		processFunction: ((u: U) => void) | null = null
	): Shar3dUtilsMappedArray<U> {
		this.$list = [];
		this.$map = {};
		//  Check for something to process
		if (Shar3dUtils.isUndefined(src) || src === null) {
			return this;
		}
		src.map(newFunction).forEach((u) => {
			const key = keyer(u);
			if (Shar3dUtils.isUndefined(this.$map[key])) {
				this.$list.push(u);
				this.$map[key] = u;
				//  Check for a process function
				if (processFunction !== null) {
					processFunction(u);
				}
			}
		});
		//  For easy chaining
		return this;
	}

	/**
	 * Clean the collection from any reference
	 */
	public clean(): void {
		this.$list = [];
		this.$map = {};
	}

	/**
	 * Add an element
	 * @param id
	 * @param u
	 */
	public add(id: string | number, u: U): void {
		if (Shar3dUtils.isDefined(this.$map[id])) {
			this.removeById(id);
		}
		this.$map[id] = u;
		this.$list.push(u);
	}

	/**
	 * Add or replace an entry
	 * @param id
	 * @param u
	 */
	public addOrReplace(id: string | number, u: U): void {
		//  Check for existing
		if (Shar3dUtils.isUndefined(this.$map[id])) {
			this.add(id, u);
			return;
		}
		//  Existing so replace
		const existing = this.$map[id];
		//  Replace in the map
		this.$map[id] = u;
		//  Lookup in the array
		const idx = this.$list.findIndex((v) => v === existing);
		if (idx >= 0) {
			this.$list[idx] = u;
		}
		//  Finished
		return;
	}

	/**
	 * Filter-like util
	 */
	public filter(filterFn: (value: U, key?: string | number) => boolean | undefined): this {
		const toRemove: string[] = [];
		for (const id in this.$map) {
			const v = this.$map[id];
			if (false === filterFn(v, id)) {
				toRemove.push(id);
			}
		}

		for (const id of toRemove) {
			this.removeById(id);
		}

		return this;
	}

	/**
	 * Remove an object based on its id
	 * @param id
	 */
	public removeById(id: string | number): void {
		if (Shar3dUtils.isUndefined(this.$map[id])) {
			return;
		}
		const u: U = this.$map[id];
		this.$list = Shar3dUtils.without(this.$list, u);
		delete this.$map[id];
		//  Finished
		return;
	}

	/**
	 * Check for this collection to contain a specific id
	 * @param id
	 */
	public containsById(id: string): boolean {
		return Shar3dUtils.isDefined(this.$map[id]);
	}
}

/**
 * Collection manipulation functions
 */
export class Shar3dUtilsCollection {
	/**
	 * Remap an existing map converting the entries
	 */
	public static remapAndInit<U, V>(
		src: { [key: string]: U },
		initFunction: (u: U) => V,
		processFunction: ((v: V) => void) | null = null
	): { [key: string]: V } {
		const r: { [key: string]: V } = {};
		for (const key in src) {
			const v: V = initFunction(src[key]);
			r[key] = v;
			if (processFunction !== null) processFunction(v);
		}
		return r;
	}

	/**
	 * Process the map and convert it to an array
	 */
	public static remapAndInitToArray<U, V>(src: { [key: string]: U }, initFunction: (u: U) => V, processFunction: ((v: V) => void) | null = null): V[] {
		const r: V[] = [];
		for (const key in src) {
			const v: V = initFunction(src[key]);
			r.push(v);
			if (processFunction !== null) processFunction(v);
		}
		return r;
	}

	/**
	 * Convert a key list into a list of value from a map
	 * Get ride of undefined values
	 */
	public static keysToValuesFromMap<T>(keys: (string | number)[], map: Record<string, T>): T[] {
		const r: T[] = [];
		for (const key of keys) {
			const value: T | undefined = map[key];
			if (Shar3dUtils.isDefined(value)) {
				r.push(value);
			}
		}
		return r;
	}
}
