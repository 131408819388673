/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SelectionPatternDto } from './selection-pattern-dto';


export interface SuggestionDto { 
    /**
     * Id of the suggestion.
     */
    id: number;
    /**
     * System name of the suggestion.
     */
    sysName: string;
    /**
     * Dictionary (associative array) of suggestions where key is the language.
     */
    _text: { [key: string]: string; };
    /**
     * Dictionary (associative array) of secondary suggestion\'s texts where key is the language.
     */
    textSecondary?: { [key: string]: string; };
    /**
     * Type of the suggestion. Determines how the suggestion will appear.   Values:   DIALOG = 0,   BUBBLE = 1,   NAV_MENU_LIKE = 2,   SUGGESTION_IN_TUNNEL = 3,   SUGGESTION_LARGE_MENU_SIZE = 4,   PUSH_EXTRA_MENU_PRODUCT = 5,   PUSH_EXTRA_MENU_PRODUCT_THIRD_PARTY = 6
     */
    suggestionType: number;
    /**
     * Patterns for the selection of elements for the suggestion (which items should be suggested).
     */
    suggestionContent: Array<SelectionPatternDto>;
    /**
     * Patterns for the selection of items that activate this suggestion.
     */
    applicableToPattern: Array<SelectionPatternDto>;
    /**
     * Flag indicating if the suggestion applies only to large menus.
     */
    appliesOnlyToLargeMenus?: boolean;
    /**
     * Flag indicating if the suggestion can only be displayed with active campaign.
     */
    campaignSuggestion: boolean;
    /**
     * Mask for the display methods for the suggestion.   Values (SuggestionDisplayMethodsMask):   SUGGESTION_METHOD_NONE = 0,   SUGGESTION_METHOD_DISPLAY_ONLY_ONCE = 2 ** 0,   SUGGESTION_METHOD_DISPLAY_ONLY_IF_NO_ITEM_ORDERED = 2 ** 1,   SUGGESTION_METHOD_DISPLAY_BEFORE_PAY = 2 ** 2,   SUGGESTION_METHOD_DISPLAY_ONLY_IF_NO_ITEM_FROM_APPLICATION_ORDERED = 2 ** 3,   SUGGESTION_METHOD_DISPLAY_ON_PRODUCT_IN_MENU = 2 ** 4
     */
    _displayMethods?: number;
    /**
     * Filter for the delivery mode.   Values (DeliveryModeMask):   NONE = 0,   DELIVERY_LOCAL = 2 ** DeliveryModeEnum.DELIVERY_LOCAL,   DELIVERY_TAKE_OVER = 2 ** DeliveryModeEnum.DELIVERY_TAKE_OVER
     */
    deliveryFilter?: number;
    /**
     * URL encoded string arguments.
     */
    stringArgs?: string;
    /**
     * Flag indicating if the suggestion should allow background image to be changed.
     */
    enableBackgroundImage?: boolean;
    /**
     * Vertical positioning of suggestion\'s background.   Values:   NONE = 0,   TOP = 1,   CENTER = 2,   BOTTOM = 3
     */
    backgroundPositionVertical?: number;
    /**
     * Horizontal positioning of suggestion\'s background.   Values:   NONE = 0,   LEFT = 1,   CENTER = 2,   RIGHT = 3
     */
    backgroundPositionHorizontal?: number;
    /**
     * Flag indicating if the suggestion has background image.
     */
    _hasBackgroundImage?: boolean;
}

