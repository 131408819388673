/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MonitoringTaskInput } from './monitoring-task-input';


export interface MonitoringDeviceInput { 
    /**
     * IP address in local network.
     */
    address: string;
    name: string;
    role: string;
    /**
     * Tasks (apps) running on the device.
     */
    tasks: Array<MonitoringTaskInput>;
    /**
     * IP address in the global VPN network.
     */
    vpn: string;
}

