/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ProductNutritionDto { 
    /**
     * Id of the nutrient.
     */
    nutrientId: number;
    /**
     * Amount of the nutrient for the specified amount type. One value is typically per portion and the second one per 100 grams or milliliters. This is set in product\'s \"_nutritionHeader1\" and \"_nutritionHeader2\".
     */
    value1: string;
    /**
     * Amount of the nutrient for the specified amount type. One value is typically per portion and the second one per 100 grams or milliliters. This is set in product\'s \"_nutritionHeader1\" and \"_nutritionHeader2\".
     */
    value2: string;
}

