/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { WeborderChannel } from './weborder-channel';
import { SalesChannelOption } from './sales-channel-option';


export interface WeborderChannelInfo { 
    /**
     * Name of the channel that will be displayed on FE apps.
     */
    name: string;
    /**
     * Enum value that will be utilized for weborder channel. Main property that determines functionality. Every value may be utilized only once. Allowed values are configured in brand configuration.
     */
    channel: WeborderChannel;
    /**
     * Id of payment method when payment methods are configured on GAP (Plutus), otherwise MDM ID of payment method (CSI).
     */
    payment_method_id: number;
    /**
     * URL of a logo that should be displayed on FE apps.
     */
    logo_url?: string;
    sales_channel_option?: SalesChannelOption;
    id: number;
}

