import { GlobalPriceDefinition, VATValue } from '@bk/price-management-common';

// Some properties are still missing, check http://192.168.100.200:8080/server/bkGetBigDatas.php?bo=192.168.100.200:81 if you need to add them
// Will replace legacy BKIngredient.ts
export type Ingredient = {
	sysName: string;
	shortName: string;
	_familyId: number;
	_name: {
		en: string;
		fr: string;
	};
	_description: {
		en: string;
		fr: string;
	};
	withPrice: boolean;
	id: number;
	active: boolean;
	// Used for ProductPriceType.Solo
	priceTTC: number | undefined;

	/**
	 * Used in conjuction with the new priceManagement in GAP/RAP.
	 * Ingredient is disabled when the price is disabled for currently selected SalesChannelOption.
	 * (No other options are used, because ingredient has always single option "Solo")
	 */
	isDisabled: boolean;

	/**
	 * Present only when the new PriceManagement is enabled. Otherwise is undefined.
	 */
	globalPriceDefinitions: GlobalPriceDefinition[];
	/**
	 * Present only when the new PriceManagement is enabled. Otherwise is undefined.
	 */
	vatValues: VATValue[];
};
