import { OrbpRole, OrbStatus } from '../../enums';
import { OrbpDetail } from '../../types';

/**
 * Dependent ORBp is valid when all his Masters are Active
 *
 * (works also for single-master Dependent)
 */
export function isDependentOnActiveMasters(dependentOrbp: OrbpDetail, allProgramOrbps: OrbpDetail[]): boolean {
	// Sanity check
	if (dependentOrbp.role !== OrbpRole.Dependent) {
		const errMessage = `isDependentOnActiveMasters() - ORBp must be in Role Dependent, but was ${dependentOrbp.role}`;
		console.error(errMessage);
		throw new Error(errMessage);
	}

	const masters = allProgramOrbps.filter((o) => dependentOrbp.masterIds.includes(o.id) && o.id !== dependentOrbp.id);

	return masters.every((master) => master.status === OrbStatus.On);
}
