/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface LanguageConfigurationDto { 
    /**
     * Language code in ISO 639-1 format including country code.
     */
    code: string;
    /**
     * Flag indicating if the feature may be modified in restaurant.
     */
    name: string;
}

