/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PredictionWeatherDto } from './prediction-weather-dto';
import { IntervalPredictionDto } from './interval-prediction-dto';


export interface PredictionDetailDto { 
    /**
     * Start of prediction interval in ISO-8601 dateTime string.
     */
    datetime: string;
    /**
     * Length of an interval for prediction in minutes.
     */
    timeInterval: number;
    /**
     * Associative array of item predictions. Item IDs are the keys. Contains item of products to be produced in the interval, not the predicted revenue.
     */
    salesMap: { [key: string]: IntervalPredictionDto; };
    /**
     * Predicted revenue.
     */
    revenue: IntervalPredictionDto;
    /**
     * Weather during the interval.
     */
    weather: PredictionWeatherDto;
    /**
     * Calculated peak intensity index of the interval.
     */
    peakIntensityIndex: number;
    /**
     * Modified peak intensity index of the interval.
     */
    modifiedPeakIntensityIndex?: number;
}

