import { SalesChannelConfiguration } from "../../types/index";
import { normalizeSalesChannelOptions } from "./normalize-sales-channel-options";

// Utility wrapper around normalizeSalesChannelOptions()
export function normalizeSalesChannels(salesChannelsConfig: SalesChannelConfiguration[]): SalesChannelConfiguration[] {
	const result: SalesChannelConfiguration[] = salesChannelsConfig.map(scc => {
		const semiResult: SalesChannelConfiguration = {...scc};
		semiResult.configuration = normalizeSalesChannelOptions(semiResult.configuration);

		return semiResult;
	});

	return result;
}
