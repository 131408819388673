import { Shar3dUtils } from './shar3d-utils';

export class Shar3dUtilsValidator {
	/**
	 * This function can be used in order to validate an object to ensure it is s string map
	 */
	public static validateStringMap(a: any): { [key: string]: string } | null {
		//  At first check for the argument to be an object
		if (!Shar3dUtils.isObject<{ [key: string]: string }>(a)) {
			return null;
		}
		//  Now loop against all properties
		for (const key in a) {
			if (!Shar3dUtils.isString(key) || !Shar3dUtils.isString(a[key])) {
				return null;
			}
		}
		//  Validation is correct
		return <{ [key: string]: string }>a;
	}
}
