/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ArtifactDisplayCondition } from './artifact-display-condition';
import { MediaHighlightRelation } from './media-highlight-relation';
import { MediaEditorArtifactDisplayCondition } from './media-editor-artifact-display-condition';
import { ArtifactType } from './artifact-type';
import { MediaEditorArtifactValue } from './media-editor-artifact-value';


export interface ArtifactUpdateInput { 
    type?: ArtifactType;
    value?: MediaEditorArtifactValue;
    display_condition_logic_operator?: ArtifactDisplayCondition;
    display_conditions?: Array<MediaEditorArtifactDisplayCondition>;
    highlights?: Array<MediaHighlightRelation>;
    horizontal_position?: number;
    vertical_position?: number;
    size?: number;
    font?: string;
    color?: string;
    content?: string;
    original_file_name?: string;
    orientation?: number;
}

