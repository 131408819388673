import { hasValue } from '@merim/utils';

import { LocalPriceDefinitionPerSalesChannel } from '../types';

export function isLocalPriceCheaper(priceDefinition: LocalPriceDefinitionPerSalesChannel): boolean {
	return (
		hasValue(priceDefinition.localPrice) &&
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		priceDefinition.localPrice! < priceDefinition.referencePrice
	);
}

export function isLocalPriceMoreExpensive(priceDefinition: LocalPriceDefinitionPerSalesChannel): boolean {
	return (
		hasValue(priceDefinition.localPrice) &&
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		priceDefinition.localPrice! > priceDefinition.referencePrice
	);
}

export function isLocalPriceDuplicated(priceDefinition: LocalPriceDefinitionPerSalesChannel): boolean {
	return hasValue(priceDefinition.localPrice) && priceDefinition.localPrice === priceDefinition.referencePrice;
}
