/**
 * RAP-BE
 * RAP-BE API documentation
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UpdateMultipleSalesChannelDto { 
    /**
     * Sales channel to be updated.
     */
    salesChannel: UpdateMultipleSalesChannelDto.SalesChannelEnum;
    /**
     * New configuration for the sales channel.
     */
    configuration?: { [key: string]: boolean; };
    /**
     * Optional additional configuration for splitting the workload per specific devices.
     */
    deviceSpecificConfiguration?: { [key: string]: boolean; };
}
export namespace UpdateMultipleSalesChannelDto {
    export type SalesChannelEnum = 'UNKNOWN' | 'CASH_MACHINE' | 'KIOSK' | 'DRIVE_WINDOW' | 'WALK' | 'CLICK_AND_COLLECT' | 'HOME_DELIVERY' | 'THIRD_PARTY_DELIVERY';
    export const SalesChannelEnum = {
        Unknown: 'UNKNOWN' as SalesChannelEnum,
        CashMachine: 'CASH_MACHINE' as SalesChannelEnum,
        Kiosk: 'KIOSK' as SalesChannelEnum,
        DriveWindow: 'DRIVE_WINDOW' as SalesChannelEnum,
        Walk: 'WALK' as SalesChannelEnum,
        ClickAndCollect: 'CLICK_AND_COLLECT' as SalesChannelEnum,
        HomeDelivery: 'HOME_DELIVERY' as SalesChannelEnum,
        ThirdPartyDelivery: 'THIRD_PARTY_DELIVERY' as SalesChannelEnum
    };
}


