import { Injectable, OnDestroy } from '@angular/core';
import { IRingMessage } from '@bk/jscommondatas';
import { MESSAGE_TOPICS } from '@libs/shared/models';
import { Base64 } from '@libs/shared/utils';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import type { IShar3dWindow } from '../interfaces';
import { RingConnectorType } from '../models';

@Injectable()
export class RingConnectorService implements OnDestroy {
	private ringMessages$: Subject<RingConnectorType> = new ReplaySubject();
	private parsedRingMessages$: Subject<IRingMessage<any>> = new ReplaySubject();
	readonly ngUnsubscribe$: Subject<void> = new Subject<void>();
	_hostWindow: IShar3dWindow;

	constructor() {}

	set hostWindow(window: IShar3dWindow) {
		this._hostWindow = window;
	}

	get hostWindow(): IShar3dWindow {
		return this._hostWindow;
	}

	get isHostShared(): boolean {
		return this.hostWindow?.shar3dbridge !== undefined;
	}

	sendMessage(topic: MESSAGE_TOPICS, message: Record<any, any> | string): void {
		console.log('sending ring message: ', message);
		if (typeof message === 'string') {
			try {
				// if its base64, i can decode it without error, thus send stomp message as base 64
				const base64Message = Base64.decode(message);
				this.hostWindow?.shar3dbridge?.sendSTOMPMessage(topic, message);
			} catch (e) {
				//if message was not in base64, i need to encode it and send it encoded
				this.hostWindow?.shar3dbridge?.sendSTOMPMessage(topic, Base64.encode(message));
			}
		} else if (typeof message === 'object' && message !== null) {
			this.hostWindow?.shar3dbridge?.sendSTOMPMessage(topic, Base64.encode(JSON.stringify(message)));
		}
	}

	startReadingMessages() {
		if (this.hostWindow.onStompMessage) {
			console.warn('Reading stomp messages has already been initialized...');
			return;
		}
		this.startProvidingRingMessages();
		this.hostWindow.onStompMessage = (topic: MESSAGE_TOPICS, payload) => {
			this.ringMessages$.next({ topic, data: payload });
		};
	}

	startProvidingRingMessages() {
		if (this.isHostShared) {
			this.ringMessages$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((item: RingConnectorType) => {
				let data = {} as any;
				try {
					const topic = item.topic?.replace('/topic/', '') as MESSAGE_TOPICS;
					if (topic === MESSAGE_TOPICS.SCAN_MESSAGES) {
						this.parsedRingMessages$.next({
							topic: topic,
							bktype: topic, //adding bktype to be consistent
							content: item.data,
						});
						return;
					} else {
						data = JSON.parse(Base64.decode(item.data));
					}
				} catch (e) {
					console.warn(`Ring message was not properly formatted: ${item} . Error: ${e}`);
					return;
				}
				const { bktype, ...content } = data;

				const topicName = item.topic?.replace('/topic/', '') as MESSAGE_TOPICS;
				this.parsedRingMessages$.next({
					topic: topicName,
					bktype,
					...content,
				});
			});
		}
	}

	/**
	 * Used in localhost environment when desktop-backend is used.
	 */
	handleRingMessage(item: IRingMessage<any>): void {
		this.parsedRingMessages$.next(item);
	}

	provideRingMessage$(): Subject<IRingMessage<any>> {
		return this.parsedRingMessages$;
	}

	ngOnDestroy() {
		this.ngUnsubscribe$.next();
		this.ngUnsubscribe$.complete();
	}
}
