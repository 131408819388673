import { LANGUAGES, LanguageService } from '@libs/shared/modules/i18n';
import { WINDOW_EVENTS } from '@shared/models';

export function addIframeLanguageListener(languageService: LanguageService): void {
	window.addEventListener('message', (event) => {
		if (event?.data?.msgType === WINDOW_EVENTS.CHANGE_LANGUAGE) {
			const targetLanguage = event.data.params.language as LANGUAGES;

			if (!Object.values(LANGUAGES).includes(targetLanguage)) {
				return;
			}

			languageService.currentLanguage = targetLanguage;
		}
	});
}
