/**
 * DKI-BE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SafeEntry } from './safe-entry';
import { SafePerson } from './safe-person';


export interface SafeRecord {
	version: number;
	id: string;
	restaurantId: string;
	businessDate: number;
	validationTime: number;
	manager: SafePerson;
	entries: Array<SafeEntry>;
}

