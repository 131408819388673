/**
 * DKI-BE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AccountingReportByPaymentMethodRecord } from './accounting-report-by-payment-method-record';
import { AccountingReportByProductGroupRecord } from './accounting-report-by-product-group-record';


export interface AccountingReportResponse {
	id: number;
	business_day: string;
	confirmed_by?: number;
	confirmed_at?: string;
	records_by_product_group: Array<AccountingReportByProductGroupRecord>;
	records_by_payment_method: Array<AccountingReportByPaymentMethodRecord>;
}

