import { getTruthyEnumMapKeys } from '@merim/utils';

import { SalesChannelConfiguration } from '../../types/sales-channel-configuration';

export function hasSubsetOfSalesChannelOptions(orbpDetailConfig: SalesChannelConfiguration, masterOrbpConfig: SalesChannelConfiguration): boolean {
	// sanity check
	if (orbpDetailConfig.salesChannel !== masterOrbpConfig.salesChannel) {
		return false;
	}

	const orpChannelOptions = getTruthyEnumMapKeys(orbpDetailConfig.configuration);
	const masterChannelOptions = getTruthyEnumMapKeys(masterOrbpConfig.configuration);

	return orpChannelOptions.every((o) => masterChannelOptions.includes(o));
}
