export type DssValidatorOptions = {
	/**
	 * It is generally recommend to validate Default ORBp.
	 * However BKFR did not want that ...
	 */
	validateDefaultORBp: boolean;
	// Currently validated only on RAP frontend.
	validateSortingOrdersAlgorithm: boolean;
	// Currently validated only on RAP frontend.
	validateDeviceSpecificConfiguration: boolean;

	// Used only when validateDeviceSpecificConfiguration is true, otherwise ignored.
	availableDevices?: AvailableDevicesPerSalesChannel
};

/**
 * List of available devices in given restaurant environment, grouped by deviceType/salesChannel.
 *
 * Example:
		{
			KIOSK: ['KIOSK1', 'KIOSK2', 'KIOSK3'],
			POS: ['POS1', 'POS3'], (maybe in future)
	 }
 */
export type AvailableDevicesPerSalesChannel = { [deviceType: string]: string[] };
