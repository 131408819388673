/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 */
export type PermissionLevelApplyDefine = 'apply' | 'define';

export const PermissionLevelApplyDefine = {
    Apply: 'apply' as PermissionLevelApplyDefine,
    Define: 'define' as PermissionLevelApplyDefine
};

