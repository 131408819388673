import { SocialEventType } from '../enums';

export type SocialEvent = {
	id: string;
	name: string;
	description?: string;
	fromDate: Date;
	toDate: Date;
	type: SocialEventType;
};
