/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 */
export type RestaurantState = 'CONFIG' | 'INSTALL' | 'WHITE' | 'PRODUCTION' | 'UNKNOWN';

export const RestaurantState = {
    Config: 'CONFIG' as RestaurantState,
    Install: 'INSTALL' as RestaurantState,
    White: 'WHITE' as RestaurantState,
    Production: 'PRODUCTION' as RestaurantState,
    Unknown: 'UNKNOWN' as RestaurantState
};

