export enum WeatherType {
	ClearSky = 'ClearSky',
	FewClouds = 'FewClouds',
	ScatteredClouds = 'ScatteredClouds',
	BrokenClouds = 'BrokenClouds',
	OvercastClouds = 'OvercastClouds',
	ShowerRain = 'ShowerRain',
	LightRain = 'LightRain',
	Rain = 'Rain',
	Thunderstorm = 'Thunderstorm',
	Snow = 'Snow',
	UNKNOWN = 'Unknown'
}
