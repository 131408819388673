import {
	IBKDiscountData,
	IBKItemInOrderBase,
	IBKProductIngredientSelectedData,
	IBKSubItemInOrderBaseWithFreeItems,
} from '@bk/jscommondatas';

export interface IProductSummary {
	id: number;
	name: Record<string, string>;
	qty: number;
	recipe: IBKProductIngredientSelectedData[];
	hasIceOption: boolean;
	noIce: boolean;
	canBeDeliveredLater: boolean;
	later: boolean;
	idx: number;
	familyId: number;
	price: number;
	lineuuid: string;
	selection: IBKSubItemInOrderBaseWithFreeItems[];
	freeItems: IBKItemInOrderBase[];
	discounts?: IBKDiscountData[];
	canChangeQuantity: boolean;
}

export interface IProductSummaryCategories {
	familyId: number;
	familyName: string;
	content: IProductSummary[];
	expanded?: boolean;
}
