import { SalesChannel } from '@bk/fullstack-common';

export type DssTableConfiguration = {
	allowedSalesChannels: SalesChannel[];
	allowedFoodCategories: string[];

	/**
	 * Configuration for RAP (Frontend) to disable form fields with matching property names.
	 *
	 * Beware. Renamed properties will not be automatically updated.
	 */
	disabledOrbpProperties: string[];
};
